import React, { useEffect, useState } from "react";
import { inputTypes } from "../../libs/react-mpk/config/constant";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import { inject, observer } from "mobx-react";
// import service from './Bp.service'
import { autorun, toJS } from "mobx";
// import UtilsService from '../../../services/utilsService'
import { Table, TableBody, TableRow, TableCell } from "react-md";
import service from "./LawanTransaksi.service";
import { useParams } from "react-router";
import {
  format,
  formatRupiah,
} from "../../libs/react-mpk/services/number.service";
import utilsService from "../../services/utilsService";

const LawanTransaksiNilaiTagihanPiutang = ({
  authStore,
  envStore,
  temporaryStore,
  match,
  history,
  navigationStore,
  ...props
}) => {
  const [data, setData] = useState({ loading: true, content: {} });
  const [allData, setAllData] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const { id, akunId } = useParams();
  let tahunSobatPajak = sessionStorage.getItem("tahunSobatBuku");
  const baseName = `bp`;
  const basePath = `bp`;
  const npwpHeadId = match.params.npwpHeadId;
  var totallBeban = 0;
  var totallPenjualan = 0;
  var totallPajakSetor = 0;
  var totall = 0;
  var actions = [
    // new TableWrapper.action(
    //   `${t.translate('words.add')}`,
    //   'mdi mdi-plus',
    //   () => {
    //     setSelectedItem(null)
    //     setShowForm(true)
    //   },
    //   true)
  ];

  return (
    <>
      <TableWrapper
        baseId="table-lawan-transaksi-tagihan"
        onFetchData={(query) => {
          return new Promise(async (resolve, reject) => {
            try {
              // var kategori = { 'kategori.equals': 1 }
              query = {
                // ...search,
                // ...kategori,
                ...query,
              };
              // let getPage = await service.get(query)
              let getPage = await service.getAkunTagihanCustomer(
                id,
                akunId,
                query
              );
              setData(getPage.data);
              setAllData(getPage.data);
              getPage.headers["x-pagination-count"] =
                getPage.headers["x-total-count"];
              resolve(getPage);
            } catch (e) {
              resolve();
              // ErrorService(e)
            }
          });
        }}
        backTo={`/${envStore.env.applicationType}/npwp/${npwpHeadId}/lawan-transaksi/${id}/akunTagihan`}
        title={t.translate("words.nilaiTagihan")}
        hintIconClassName="mdi mdi-information"
        defaultData={[]}
        defaultSortBy="tanggalDibuat"
        defaultCollapse={true}
        useCriteria={false}
        selectable={false}
        useQueryHistory={false}
        columns={[
          {
            label: "Nomor Transaksi",
            searchable: true,
            sortable: false,
            key: "noTransaksi",
            render: (item) => utilsService.beautifyNoTransaksi(item.noInvoice),
          },
          {
            label: "Akun",
            searchable: true,
            sortable: false,
            key: "akun",
            render: (item) =>
              `${item.akunPiutang.kode} - ${item.akunPiutang.nama}`,
          },
          {
            label: "Total",
            searchable: true,
            sortable: false,
            key: "total",
            render: (item) => formatRupiah(item.pembayaran.sisaBayar),
          },
          // {
          //   label: 'Total Penjualan',
          //   searchable: true,
          //   sortable: false,
          //   key: 'totalPenjualan',
          //   render: item => (formatRupiah(item.totalPenjualan))
          // },
          // {
          //   label: 'Total Pajak Setor',
          //   searchable: true,
          //   sortable: false,
          //   key: 'totalPajakSetor',
          //   render: item => (formatRupiah(item.totalPajakSetor))
          // }
        ]}
        actions={actions}
        itemActions={[
          new TableWrapper.action(
            "Detail",
            "mdi mdi-import",
            (item) => {
              if (item.noInvoice.includes("PJ")) {
                navigationStore.redirectTo(
                  `/internal/npwp/${npwpHeadId}/penjualan/${item.noInvoice}/detail/non-ppn`
                );
              } else if (item.referensi.includes("PB")) {
                sessionStorage.setItem("noInvoicePembelian", item.noTransaksi);
                navigationStore.redirectTo(
                  `/internal/npwp/${npwpHeadId}/pembelian/${item.id}/detail/non-ppn`
                );
              } else if (item.noInvoice.includes("KK")) {
                navigationStore.redirectTo(
                  `/internal/kas-dan-bank-detail-pengeluaran-non-ppn`
                );
              } else if (item.noInvoice.includes("PK")) {
                navigationStore.redirectTo(
                  `/internal/kas-dan-bank-detail-penerimaan-non-ppn`
                );
              } else if (item.noInvoice.includes("TF")) {
                navigationStore.redirectTo(
                  `/internal/kas-dan-bank-detail-transfer-antar-bank/${item.id}`
                );
              } else if (item.noInvoice.includes("BY")) {
                navigationStore.redirectTo(
                  `/internal/npwp/${npwpHeadId}/biaya/${item.noInvoice}/non-ppn`
                );
              } else if (item.noInvoice.includes("JU")) {
                navigationStore.redirectTo(
                  `/internal/npwp/${npwpHeadId}/jurnal-umum/${item.noInvoice}`
                );
              }
            },
            true
          ),
        ]}
        showFilterPeriod={false}
        useFilter={false}
        usePagination={false}
        tableFooter={
          <Table>
            <TableBody>
              {allData.map((d) => {
                // totallBeban += d.totalBeban
                // totallPajakSetor += d.totalPajakSetor
                // totallPenjualan += d.totalPenjualan
                // totall = totallPenjualan + totallBeban + totallPajakSetor
                totall += d.pembayaran.sisaBayar;
              })}
              <TableRow>
                <TableCell style={{ width: "150px" }}>
                  <b>{t.translate("words.total")}</b>
                </TableCell>
                <TableCell style={{ width: "10px" }}>:</TableCell>
                <TableCell>
                  <b style={{ float: "right" }}>{formatRupiah(totall)}</b>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        }
      />
    </>
  );
};

export default inject(
  "authStore",
  "envStore",
  "temporaryStore",
  "navigationStore"
)(observer(LawanTransaksiNilaiTagihanPiutang));
