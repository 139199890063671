import React, { useEffect, useState } from "react";
import { FormWrapper } from "../../../libs/react-mpk/wrapper";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import { useParams } from "react-router";
import service from "./InfoAkun.service";
import AreaService from "../../../services/Area.service";
import { toast } from "../../../libs/react-mpk/services";
import moment from "moment";

const InfoAkun = ({ envStore, authStore, match, history, ...props }) => {
  const { id } = useParams();
  let [dataSubmit, setDataSubmit] = useState(null);
  let [item, setItem] = useState({
    alamat: "",
    email: "",
    klu: null,
    id: null,
    npwpHeadId: null,
    dataAwalId: null,
    isAkunGenerated: false,
    area: null,
    kodepos: "",
    namaNpwp: "",
    noKtp: "",
    noTelepon: "",
    npwp: "",
    npwpOp: false,
    npwpOpInt: "1",
    omzetSudahDihitung: 0,
    pegawai: false,
    pekerjaBebas: false,
    pemilikUsaha: false,
    pkp: false,
    tanggalMulai: null,
    tanggalPkp: null,
    kenaPphFinal: false,
  });
  let [loading, setLoading] = useState(true);
  let [queryKlu, setQueryKlu] = useState("");
  const [dataKlu, setDataKlu] = useState([]);
  const [loadingKlu, setLoadingKlu] = useState(false);

  useEffect(() => {
    let selectedItem = item;
    async function initData() {
      try {
        setLoading(true);
        let res = await service.getOneById(id);
        let { data } = res;
        setDataSubmit(data);
        selectedItem.id = data.id;
        selectedItem.npwpHeadId = data.npwpHead.id;
        selectedItem.dataAwalId = data.dataAwal.id;
        selectedItem.isAkunGenerated = data.isAkunGenerated;
        selectedItem.npwpOp = data.npwpHead.npwpOp;
        selectedItem.npwpOpInt = selectedItem.npwpOp ? "0" : "1";
        selectedItem.noKtp = data.npwpHead.noKtp;
        selectedItem.npwp = data.npwpHead.npwp;
        selectedItem.namaNpwp = data.npwpHead.namaNpwp;
        selectedItem.noTelepon = data.npwpHead.noTelepon;
        selectedItem.email = data.npwpHead.email;
        selectedItem.alamat = data.npwpHead.alamat;
        selectedItem.kodepos = data.npwpHead.area.kodepos;
        selectedItem.area = data.npwpHead.area;
        selectedItem.omzetSudahDihitung = data.dataAwal.omzetSudahDihitung;
        selectedItem.klu = data.npwpHead.klu?.id ;
        selectedItem.pegawai = data.pegawai;
        selectedItem.pemilikUsaha = data.pemilikUsaha;
        selectedItem.pekerjaBebas = data.pekerjaBebas;
        selectedItem.pkp = data.npwpHead.pkp;
        selectedItem.tanggalPkp = data.dataAwal.tanggalPkp;
        selectedItem.tanggalMulai = data.dataAwal.tanggalMulai;
        selectedItem.jumlahTanggungan = data.jumlahTanggungan
          ? data.jumlahTanggungan.toString()
          : "0";
        selectedItem.npwpPasangan = data.npwpPasangan;
        selectedItem.pisahHarta = data.pisahHarta;
        selectedItem.statusNikah = data.statusNikah;
        selectedItem.statusNikahStr =
          data.statusNikah == 2
            ? "Belum Menikah"
            : data.statusNikahStr == 3
            ? "Cerai"
            : 1
            ? "Menikah"
            : null;
        selectedItem.tahun = data.tahun;
        setItem(selectedItem);
        setQueryKlu(data.npwpHead.klu?.nama);
        setLoading(false);
      } catch (e) {
        toast.errorRequest(e);
        setLoading(false);
      }
    }
    initData();
  }, []);

  useEffect(() => {
    setLoadingKlu(true);
    const timeOutId = setTimeout(() => getDataKlu(queryKlu), 500);
    return () => clearTimeout(timeOutId);
  }, [queryKlu]);

  const getDataKlu = async (queryNama) => {
    try {
      setLoadingKlu(true);
      let klu = await service.getKlu(queryNama);
      var klsAsset = [];
      klu.data.map((d) => {
        klsAsset.push({
          label: `${d.kode} - ${d.nama}`,
          value: d.id,
        });
      });
      setDataKlu(klsAsset);
      setLoadingKlu(false);
    } catch (error) {
      toast.errorRequest(error);
      setLoadingKlu(false);
    }
  };

  const getKodepos = async (kodepos) => {
    try {
      let res = await AreaService.getByKodePos(kodepos);

      let { data } = res;

      return data;
    } catch (e) {
      return e.message;
    }
  };
  return (
    <FormWrapper
      showBackTo={false}
      baseId="info-akun"
      title={`Info Akun`}
      loading={loading}
      defaultData={item}
      submitLabel={"Ubah"}
      definitions={[
        {
          render: (
            <div style={{ width: "100%" }}>
              <p style={{ marginLeft: "10px" }}>
                <b>{t.translate(`words.opOrBadan`)}</b>
              </p>
              <br />
            </div>
          ),
        },
        {
          inputType: FormWrapper.inputTypes.RADIO,
          label: t.translate(`words.op`),
          key: "npwpOpInt",
          className: "mpk-padding-N padding-right",
          name: "npwpOpInt",
          value: "0",
          style: {
            width: "50%",
          },
          disabled: true,
        },
        {
          inputType: FormWrapper.inputTypes.RADIO,
          label: t.translate(`words.badan`),
          key: "npwpOpInt",
          name: "npwpOpInt",
          width: "100%",
          value: "1",
          style: {
            width: "50%",
          },
          disabled: true,
        },
        {
          inputType: FormWrapper.inputTypes.INPUT_MASK_NUMBER,
          label: t.translate(`words.identityNik`),
          key: "noKtp",
          type: "text",
          mask: "################",
          maskChar: "_",
          maskValue: "string",
          disabled: true,
          show: (d) => {
            return d.npwpOpInt == "0";
          },
        },
        {
          inputType: FormWrapper.inputTypes.INPUT_MASK_NUMBER,
          label: t.translate(`words.identityNpwp`),
          key: "npwp",
          type: "text",
          mask: "##.###.###.#-###.###",
          maskChar: "_",
          maskValue: "string",
          disabled: true,
        },
        {
          inputType: FormWrapper.inputTypes.INPUT,
          label: `Nama`,
          key: "namaNpwp",
          type: "text",
          disabled: true,
        },
        {
          inputType: FormWrapper.inputTypes.INPUT_MASK_NUMBER,
          label: t.translate("words.phone"),
          key: "noTelepon",
          type: "text",
          validation: "required",
          required: true,
          isNumericString: true,
        },
        {
          inputType: FormWrapper.inputTypes.INPUT,
          label: t.translate("words.email"),
          key: "email",
          type: "text",
        },
        {
          inputType: FormWrapper.inputTypes.INPUT,
          label: t.translate("words.address"),
          key: "alamat",
        },
        {
          inputType: FormWrapper.inputTypes.INPUT_MASK_NUMBER,
          label: t.translate(`words.kodepos`),
          key: "kodepos",
          type: "text",
          mask: "#####",
          maskChar: "_",
          maskValue: "string",
        },
        {
          inputType: FormWrapper.inputTypes.REACT_SELECT,
          label: t.translate(`words.kodeKlasifikasiLapanganUsaha`),
          tooltip:'Jika Kode KLU tidak ditemukan,<br/>silahkan ketik nama KLU yang ingin<br/>dicari pada kolom KLU.',
          key: "klu",
          validation: props.itemId && "required",
          labelKey: "label",
          valueKey: "value",
          options: dataKlu,
          isLoading: loadingKlu,
          onInputChange: (value) => {
            if (value) setQueryKlu(value);
          },
        },
        {
          inputType: FormWrapper.inputTypes.DATEPICKER,
          label: `Tanggal Mulai Pencatatan`,
          key: "tanggalMulai",
          disabled: true,
        },
        {
          inputType: FormWrapper.inputTypes.INPUT_MASK_NUMBER,
          label: "Omzet Diluar Aplikasi",
          key: "omzetSudahDihitung",
          type: "text",
          thousandSeparator: ".",
          decimalSeparator: ",",
          prefix:'Rp.',
          isNumericString: true,
          width: "100%",
          disabled: true,
        },
        {
          render: (
            <div>
              <p style={{ marginLeft: "10px", marginBottom: "-15px" }}>
                <b>Jenis Pekerjaan</b>
              </p>
              <br />
            </div>
          ),
        },
        {
          inputType: FormWrapper.inputTypes.CHECKBOX,
          label: t.translate(`words.pegawai`),
          key: "pegawai",
          name: "pegawai",

          show: (d) => {
            return d.npwpOp == true;
          },
        },
        {
          inputType: FormWrapper.inputTypes.CHECKBOX,
          label: t.translate(`words.pekerjaBebas`),
          key: "pekerjaBebas",
          name: "pekerjaBebas",

          show: (d) => {
            return d.npwpOp == true;
          },
        },
        {
          inputType: FormWrapper.inputTypes.CHECKBOX,
          label: t.translate(`words.pemilikUsaha`),
          key: "pemilikUsaha",
          name: "pemilikUsaha",
        },

        // {
        //   inputType: FormWrapper.inputTypes.CHECKBOX,
        //   label: t.translate(`words.terdaftarSebagaiPkp`),
        //   key: "pkp",
        //   name: "pkp",
        // },
        // {
        //   inputType: FormWrapper.inputTypes.DATEPICKER,
        //   label: t.translate(`words.tanggalDitetapkanPkp`),
        //   key: "tanggalPkp",
        //   show: (d) => {
        //     return d.pkp == true;
        //   },
        // },
      ]}
      onChange={async (formData, key, value) => {
        if (key == "kodepos") {
          if (value.length === 5) {
            var res = await getKodepos(value);
            if (typeof res === "object") {
              formData.area = res;
            } else {
              alert(res);
              formData.area = null;
            }
          }
        }
        setItem(formData);
        return formData;
      }}
      onSubmit={async (data, callback) => {
        let Selected = dataSubmit;

        Selected.id = data.id;
        Selected.npwpHeadid = data.npwpHeadId;
        Selected.npwpHead.npwpOp = data.npwpOpInt == "0" ? true : false;
        Selected.npwpHead.npwpOp = data.npwpOp;
        Selected.npwpHead.namaNpwp = data.namaNpwp;
        Selected.npwpHead.npwp = dataSubmit.npwpHead.npwp == ""?"000000000000000":data.npwp;
        Selected.npwpHead.alamat = data.alamat;
        Selected.npwpHead.area = data.area;
        Selected.npwpHead.email = data.email;
        Selected.npwpHead.klu={id: data.klu};
        Selected.npwpHead.noKtp = data.npwpOpInt == "0" ? data.noKtp : "";
        Selected.npwpHead.noTelepon = data.noTelepon;
        Selected.npwpHead.pkp = data.pkp;
        Selected.npwpHead.tanggalPkp =
          data.pkp == true
            ? moment(new Date(item.tanggalPkp)).format("YYYY-MM-DD hh:mm:ss")
            : null;
        Selected.pegawai = data.pegawai;
        Selected.pekerjaBebas = data.pekerjaBebas;
        Selected.pemilikUsaha = data.pemilikUsaha;
        Selected.dataAwal.tanggalMulai = moment(
          new Date(item.tanggalMulai)
        ).format("yyyy-MM-DD hh:mm:ss");
        Selected.dataAwal.kenaPphFinal = data.kenaPphFinal;

        if (dataSubmit.npwpHead.npwp == "") {
          callback("Npwp Tidak Boleh Kosong", true, false);
        }  else if (dataSubmit.npwpHead.namaNpwp == "") {
          callback("Nama Npwp Tidak Boleh Kosong", true, false);
        } else if (dataSubmit.npwpHead.noTelepon == "") {
          callback("No Telepon Tidak Boleh Kosong", true, false);
        } else if (dataSubmit.npwpHead.email == "") {
          callback("Email Tidak Boleh Kosong", true, false);
        } else if (dataSubmit.npwpHead.alamat == "") {
          callback("alamat Tidak Boleh Kosong", true, false);
        } else if (dataSubmit.npwpHead.area.kodepos == null) {
          callback("Kode Pos Tidak Boleh Kosong", true, false);
        } else if (dataSubmit.npwpHead.klu.id == null) {
          callback("Klu Tidak Boleh Kosong", true, false);
        } else if (data.kodepos.length < 5) {
          callback("Format Kode Pos Salah", true, false);
        } else if (
          dataSubmit.pegawai == false &&
          dataSubmit.pekerjaBebas == false &&
          dataSubmit.pemilikUsaha == false
        ) {
          callback("harus Memilih Role", true, false);
        } else {
          try {
            await service.update(dataSubmit);
            callback("Berhasil Update Data", false);
          } catch (error) {
            callback(error, true, false);
          }
        }
      }}
    />
  );
};

export default inject("envStore")(observer(InfoAkun));
