import React, { useEffect, useState } from "react";
import { DataForm, Hint } from "../../libs/react-mpk/components";
import t from "counterpart";
import { FormWrapper } from "../../libs/react-mpk/wrapper";
import {
  Table,
  TableBody,
  TableHeader,
  TableRow,
  TableCell,
  Button,
  FontIcon,
  TableFooter,
  CircularProgress,
  LinearProgress,
} from "react-md";
import service from "./Produk.service";
import { inject, observer } from "mobx-react";
import { cloneDeep } from "lodash";
import { disable } from "react-md";
import { values } from "mobx";
import KategoriForm from "../KategoriUnit/KategoriForm";
import UnitForm from "../KategoriUnit/UnitForm";
import { toast } from "../../libs/react-mpk/services";

const ProdukForm = ({
  visible = false,
  authStore,
  envStore,
  modalStore,
  match,
  history,
  onRequestClose = () => {},
  ...props
}) => {
  const { inputTypes, definition } = FormWrapper;
  //JENIS KATEGORI ASET
  let JENIS_BARANG = 1;
  let JENIS_JASA = 2;
  let defaultData = {
    id: null,
    gambar: null,
    kode: "",
    nama: "",
    jenisId: "",
    jenis: "1",
    deskripsi: "",
    kategoriId: null,
    kategori: {
      id: null,
    },
    unitId: null,
    unit: {
      id: null,
    },
    beliProduk: false,
    akunPembelianId: null,
    akunPembelian: {
      id: null,
    },
    hargaBeli: 0,
    jualProduk: false,
    akunPenjualanId: null,
    akunPenjualan: {
      id: null,
    },
    hargaJual: 0,
    monitoringPersediaan: false,
    akunPersediaanId: null,
    akunPersediaan: {
      id: null,
    },
    batasStokMinimum: 0,
  };
  let [dataSubmit, setDataSubmit] = useState({
    produk: {
      id: null,
      kode: "",
      nama: "",
      jenis: 1,
      deskripsi: "",
      kategori: null,
      unit: null,
      pengaturan: {
        beliProduk: false,
        akunPembelian: null,
        hargaBeli: 0,
        jualProduk: false,
        akunPenjualan: null,
        hargaJual: 0,
        monitoringPersediaan: false,
        akunPersediaan: null,
        batasStokMinimum: 0,
      },
      npwpHead: {
        id: sessionStorage.getItem("npwpHeadId"),
      },
    },
    produkGambarList: [],
    produkGambarHapusList: [],
  });
  let [item, setItem] = useState(defaultData);
  let [ready, setReady] = useState(true);
  let [showFormKategori, setShowFormKategori] = useState(false);
  let [showFormUnit, setShowFormUnit] = useState(false);
  let [isLoadingGambar, setIsLoadingGambar] = useState(false);
  let [isLoadingAll, setIsLoadingAll] = useState(true);
  let [isLoading, setIsLoading] = useState(true);
  let [isLoadingKategori, setIsLoadingKategori] = useState(false);
  let [isLoadingUnit, setIsLoadingUnit] = useState(false);
  let [queryKategori, setQueryKategori] = useState("");
  let [queryUnit, setQueryUnit] = useState("");
  let [gambarList, setGambarList] = useState([]);
  let [gambarHapusList, setGambarHapusList] = useState([]);
  let [jenisProdukList, setJenisProdukList] = useState([
    {
      value: JENIS_BARANG,
      label: `Barang`,
    },
    {
      value: JENIS_JASA,
      label: `Jasa`,
    },
  ]);
  const [kategoriList, setKategoriList] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [showFormGambar, setShowFormGambar] = useState(false);

  useEffect(() => {
    async function initData() {
      setIsLoading(true);
      setGambarList([]);
      setGambarHapusList([]);
      if (visible) {
        let selectedItem = item;

        if (props.itemId) {
          try {
            let res = await service.getOneById(props.itemId);

            let { data } = res;

            setDataSubmit(data);

            selectedItem.id = data.produk.id;
            selectedItem.gambar = data.produkGambarList[0];
            selectedItem.kode = data.produk.kode;
            selectedItem.nama = data.produk.nama;
            selectedItem.jenisId =
              data.produk.jenis == JENIS_BARANG ? "Barang" : "Jasa";
            selectedItem.jenis = data.produk.jenis;
            selectedItem.deskripsi = data.produk.deskripsi;
            selectedItem.kategoriId = data.produk.kategori.id;
            selectedItem.kategori.id = data.produk.kategori.id;
            selectedItem.unitId =  data.produk.unit.id;
            selectedItem.unit.id = data.produk.unit.id;
            setGambarList(data.produkGambarList ? data.produkGambarList : []);
            setGambarHapusList(
              data.produkGambarHapusList ? data.produkGambarHapusList : []
            );
          } catch (e) {}
        } else {
        }
        setItem(selectedItem);
      } else {
        setItem(defaultData);
      }
      setReady(visible);
    }
    initData();
    setIsLoading(false);
  }, [visible]);

  useEffect(() => {
    setIsLoadingKategori(true);
    const timeOutId = setTimeout(() => getKategori(queryKategori), 500);
    return () => clearTimeout(timeOutId);
  }, [queryKategori]);
  useEffect(() => {
    setIsLoadingUnit(true);
    const timeOutId = setTimeout(() => getUnit(queryUnit), 500);
    return () => clearTimeout(timeOutId);
  }, [queryUnit]);


  async function getKategori() {
    try {
      setIsLoadingKategori(true);
      let res = await service.getListKategori();
      var kategoris = [
        {
          value: 0,
          label: "-",
        },
        {
        value: " + Tambah Kategori",
        label: " + Tambah Kategori",
      },
    ];
    res.data.map((d) => {
      kategoris.push({
        value: d.id,
        label: `${d.kode} - ${d.nama}`,
      });
    });
    setKategoriList(kategoris);
    setIsLoadingKategori(false);
  } catch (error) {
    toast.errorRequest(error)
    setIsLoadingKategori(false);
    }
    
    setIsLoadingKategori(false);
  }

  async function getUnit() {
    try {
      setIsLoadingUnit(true);
      let res = await service.getListUnit();
      var units = [
        {
          value: 0,
          label: "-",
        },
        {
          value: " + Tambah Unit",
          label: " + Tambah Unit",
        },
      ];
      res.data.map((d) => {
        units.push({
          value: d.id,
          label: `${d.kode} - ${d.nama}`,
        });
      });
      setUnitList(units);
      setIsLoadingUnit(false);
    } catch (error) {
      toast.errorRequest(error)
      setIsLoadingUnit(false);
    }
  }

  async function deleteGambar(index) {
    modalStore.showConfirm({
      title: t.translate("words.delete"),
      children: t.translate("words.confirmHapus"),
      onSubmit: (callback) => {
        var gambarListNew = gambarList;

        //tambah di list gambar hapus
        var gambarHapusListNew = gambarHapusList;
        gambarHapusListNew.push(gambarListNew[index]);
        setGambarHapusList(gambarHapusListNew);

        //hapus di list gambar
        gambarListNew.splice(index, 1);
        setGambarList(gambarListNew);
        callback();
      },
    });
  }

  const onSubmit = async (data, callback) => {
    let reqData = dataSubmit;
    reqData.produk.id = data.id;
    reqData.produk.kode = data.kode;
    reqData.produk.nama = data.nama;
    reqData.produk.jenis = data.jenis;
    reqData.produk.deskripsi = data.deskripsi;
    reqData.produk.kategori = data.kategori;
    reqData.produk.unit = data.unit;
    reqData.produkGambarList = gambarList.length > 0 ? gambarList : [];
    reqData.produkGambarHapusList =
      gambarHapusList.length > 0 ? gambarHapusList : null;
    reqData.produk.npwpHead.id = sessionStorage.getItem("npwpHeadId");
    if (data.kategoriId == 0) {
      callback("Kategori Harus di isi", true, false);
    } else if (data.unitId == 0) {
      callback("Unit Harus di isi", true, false);
    } else {
      if (reqData.produk.id) {
        try {
          const res = await service.update(reqData);
          let { data, status } = res;
          callback("success", false);
        } catch (err) {
          // console.log(err, 'err')
          callback(err, true, false);
        }
      } else {
        try {
          const res = await service.create(reqData);
          callback("success", false);
        } catch (err) {
          callback(err, true, false);
        }
      }
    }
  };
  useEffect(() => {
    setIsLoadingAll(isLoading);
    return () => {};
  }, [isLoading]);
  return (
    ready && (
      <>
        <DataForm
          baseId="akun-coa-form"
          title={t.translate(`modules.produk.form`)}
          visible={visible}
          // data={item}
          onRequestClose={onRequestClose}
          asDialog={true}
          defaultData={item}
          definitions={[
            //Loading pas open modal
            {
              render: <CircularProgress id="circular-progress" />,
              show: isLoadingAll,
            },
            {
              inputType: inputTypes.DIVIDER,
              label: t.translate("words.deskripsi"),
              show: !isLoadingAll,
            },
            //Loading Untuk Upload Gambar
            {
              render: <LinearProgress id="linear-progress" />,
              show: !isLoadingAll && isLoadingGambar,
            },
            {
              inputType: inputTypes.FILE_INPUT,
              label: t.translate("words.gambar"),
              key: "gambar",
              type: "file",
              accept: ".jpg,.jpeg,.png",
              show: showFormGambar && !isLoadingAll,
            },
            {
              label: "List Gambar",
              key: "listGambar",
              render: (
                <div style={{ width: "100%" }}>
                  {gambarList.length < 3 && (
                    <Button
                      theme="primary"
                      themeType="contained"
                      style={{ margin: 15 }}
                      onClick={() => {
                        setShowFormGambar(true);
                      }}
                    >
                      <FontIcon iconClassName="mdi mdi-plus" />
                      {t.translate("words.add")}
                    </Button>
                  )}
                  <Table style={{ width: "100%", marginBottom: "25px" }}>
                    <TableHeader>
                      <TableRow>
                        <TableCell style={{ width: "10%" }}>
                          {t.translate("words.non")}
                        </TableCell>
                        <TableCell style={{ width: "90%" }}>
                          {t.translate("words.namaFile")}
                        </TableCell>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {gambarList.map((d, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{d.nama}</TableCell>
                            <TableCell>
                              {/* <Button onClick={()=> console.log("Masuk")}>{t.translate("words.edit")}</Button> */}
                              <Button onClick={() => deleteGambar(index)}>
                                {t.translate("words.delete")}
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </div>
              ),
              show: !isLoadingAll,
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.namaProduk`),
              key: "nama",
              required: true,
              show: !isLoadingAll,
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.kodeProduk`),
              key: "kode",
              show: !isLoadingAll,
            },
            {
              inputType: DataForm.inputTypes.SELECT,
              label: t.translate(`words.jenisProduk`),
              key: "jenisId",
              validation: "required",
              labelKey: "label",
              valueKey: "label",
              type: "search",
              required: true,
              options: jenisProdukList,
              show: !isLoadingAll,
            },
            {
              inputType: DataForm.inputTypes.REACT_SELECT,
            isClearable:true,
              label: t.translate(`words.kategori`),
              key: "kategoriId",
              validation: "required",
              labelKey: "label",
              valueKey: "value",
              options: kategoriList,
              isLoading: isLoadingKategori,
              onInputChange: (value) => {
                if (value) setQueryKategori(value);
              },
              required: true,
              show: !isLoadingAll,
            },
            {
              inputType: DataForm.inputTypes.REACT_SELECT,
            isClearable:true,
              label: t.translate(`words.unit`),
              key: "unitId",
              validation: "required",
              labelKey: "label",
              valueKey: "value",
              options: unitList,
              isLoading: isLoadingUnit,
              onInputChange: (value) => {
                if (value) setQueryUnit(value);
              },
              required: true,
              type: "search",
              show: !isLoadingAll,
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.deskripsi`),
              key: "deskripsi",
              show: !isLoadingAll,
            },
          ]}
          onChange={async (formData, key, value) => {
            if (key == "gambar") {
              setIsLoadingGambar(true);
              setShowFormGambar(false);
              let res = await service.uploadGambar(value);
              if (res.status == 200) {
                let newGambarList = gambarList;
                newGambarList.push(res.data);
                setGambarList(newGambarList);
                formData["gambar"] = null;
              }

              setIsLoadingGambar(false);
            }

            if (key == "jenisId") {
              formData["jenis"] = value == "Barang" ? JENIS_BARANG : JENIS_JASA;
            }

            if (key == "kategoriId") {
              if (value == " + Tambah Kategori") {
                setShowFormKategori(true);
                formData.kategoriId = 0
              } else {
                kategoriList.map((d) => {
                  if (d.value == value) {
                    console.log(value)
                    formData["kategori"]["id"] = d.value;
                  }
                });
              }
            }

            if (key == "unitId") {
              if (value == " + Tambah Unit") {
                setShowFormUnit(true);
                formData.unitId = 0
              } else {
                unitList.map((d) => {
                  if (d.value == value) {
                    formData["unit"]["id"] = d.value;
                  }
                });
              }
            }

            setItem(formData);
            return formData;
          }}
          onSubmit={!isLoadingAll ? onSubmit : null}
        />
        <KategoriForm
          visible={showFormKategori}
          onRequestClose={() => {
            setShowFormKategori(false);
            getKategori();
          }}
        />
        <UnitForm
          visible={showFormUnit}
          onRequestClose={() => {
            setShowFormUnit(false);
            getUnit();
          }}
        />
      </>
    )
  );
};

export default inject(
  "navigationStore",
  "envStore",
  "modalStore"
)(observer(ProdukForm));
