import React, { useEffect, useState } from "react";
import { TableWrapper, TabsWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import service from "./LawanTransaksi.service";
import moment from "moment";
import 'moment/locale/id';
import LawanTransaksiForm from "./LawanTransaksiForm";
import { inject, observer } from "mobx-react";
import iziToast from "izitoast";
import { toast } from "../../libs/react-mpk/services";

const LawanTransaksi = ({
  className = "",
  history,
  modalStore,
  navigationStore,
  showCommandbar = true,
  match,
}) => {
  const [showForm, setShowForm] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [data, setData] = useState([]);
  const basePath = `lawan-transaksi`;
  var npwpHeadId = match.params.npwpHeadId;

  async function hapus(item) {
    modalStore.showConfirm({
      title: "Delete",
      children: "Apakah yakin?",
      onSubmit: async (callback) => {
        try {
          await service.delete(item.id);
          TableWrapper.reload("lawan-transaksi-table");
          iziToast.success({ message: "Success" });
          callback();
        } catch (e) {
          toast.errorRequest(e)

          callback();
        }
      },
    });
  }

  var actions = [
    new TableWrapper.action(
      `${t.translate("words.add")}`,
      "mdi mdi-plus",
      () => {
        setSelectedItemId(null);
        setShowForm(true);
      },
      true
    ),
  ];

  return (
    <>
      <TabsWrapper
        showCommandbar={false}
        baseId="mod-rincian-setor-sendiri-sample"
        title={t.translate("modules.lawanTransaksi.supplier.title")}
        tabs={[
          {
            label: t.translate("modules.lawanTransaksi.supplier.title"),
            render: (
              <>
                <TableWrapper
                  baseId="lawan-transaksi-table"
                  title="Pelaku Transaksi Supplier"
                  className={className}
                  defaultData={[]}
                  defaultSortBy="tanggalDibuat"
                  isShowFilter={false}
                  showActionColumn={true}
                  useFilter={false}
                  defaultCollapse={true}
                  useCriteria={true}
                  selectable={false}
                  useQueryHistory={false}
                  onFetchData={(query) => {
                    return new Promise(async (resolve, reject) => {
                      try {
                        var kategori = { "kategori.equals": 2 };
                        query = {
                          // ...search,
                          ...query,
                          ...kategori,
                        };
                        // let getPage = await service.get(query)
                        let getPage = await service.getAll(query);
                        setData(getPage.data);
                        getPage.headers["x-pagination-count"] =
                          getPage.headers["x-total-count"];
                        resolve(getPage);
                      } catch (e) {
                        resolve();
                        // ErrorService(e)
                      }
                    });
                  }}
                  columns={[
                    {
                      label: "Nama",
                      searchable: true,
                      sortable: false,
                      key: "nama",
                      render: (item) => item.nama,
                    },
                    {
                      label: "Jenis Usaha",
                      searchable: true,
                      sortable: false,
                      key: "jenisUsaha",
                      render: (item) =>
                        item.jenisUsaha == 1 ? "Badan" : "Orang Pribadi",
                    },
                    {
                      label: "Telepon",
                      searchable: true,
                      sortable: false,
                      key: "telepon",
                      render: (item) => item.noTelepon ||item.noTeleponPic||"-",
                    },
                    {
                      label: "Alamat",
                      searchable: true,
                      sortable: false,
                      key: "alamat",
                      render: (item) => item.alamat,
                    },
                  ]}
                  actions={actions}
                  itemActions={[
                    new TableWrapper.action(
                      `Detail`,
                      "mdi mdi-import",
                      (item) => {
                        navigationStore.redirectTo(
                          `/internal/npwp/${npwpHeadId}/lawan-transaksi/${item.id}/lawanTransaksi`
                        );
                      }
                    ),
                    new TableWrapper.action(
                      "Edit",
                      "mdi mdi-pencil",
                      (item) => {
                        setSelectedItemId(item.id);
                        setShowForm(true);
                      },
                      true
                    ),
                    new TableWrapper.action(
                      "Delete",
                      "mdi mdi-delete",
                      (item) => hapus(item),
                      true
                    ),
                  ]}
                  // {
                  //   async query => {
                  //     query['page'] = query['page']-1;
                  //     console.log(query, 'query')
                  //     console.log(search, 'search')
                  //     let params = {
                  //       ...search,
                  //       ...query
                  //     }
                  //     console.log(params, 'params')
                  //     return service.get(params)
                  //   }
                  // }
                  showCommandbar={showCommandbar}
                  showFilterPeriod={false}
                />
                <LawanTransaksiForm
                  visible={showForm}
                  onRequestClose={() => {
                    setShowForm(false);
                    setSelectedItemId(null);
                    document.getElementsByClassName("mdi-reload")[0].click();
                  }}
                  item={selectedItemId}
                />
              </>
            ),
          },
          {
            label: t.translate("modules.lawanTransaksi.customer.title"),
            render: (
              <>
                <TableWrapper
                  baseId="lawan-transaksi-table"
                  title="Pelaku Transaksi Customer"
                  className={className}
                  
                  defaultData={[]}
                  defaultSortBy="tanggalDibuat"
                  isShowFilter={false}
                  showActionColumn={true}
                  useFilter={false}
                  defaultCollapse={true}
                  useCriteria={true}
                  selectable={false}
                  useQueryHistory={false}
                  onFetchData={(query) => {
                    return new Promise(async (resolve, reject) => {
                      try {
                        var kategori = { "kategori.equals": 1 };
                        query = {
                          // ...search,
                          ...query,
                          ...kategori,
                        };
                        // let getPage = await service.get(query)
                        let getPage = await service.getAll(query);
                        setData(getPage.data);
                        getPage.headers["x-pagination-count"] =
                          getPage.headers["x-total-count"];
                        resolve(getPage);
                      } catch (e) {
                        resolve();
                        // ErrorService(e)
                      }
                    });
                  }}
                  columns={[
                    {
                      label: "Nama",
                      searchable: true,
                      sortable: false,
                      key: "nama",
                      render: (item) => item.nama,
                    },
                    {
                      label: "Jenis Usaha",
                      searchable: true,
                      sortable: false,
                      key: "jenisUsaha",
                      render: (item) =>
                        item.jenisUsaha == 1 ? "Badan" : "Orang Pribadi",
                    },
                    {
                      label: "Telepon",
                      searchable: true,
                      sortable: false,
                      key: "telepon",
                      render: (item) => item.noTelepon ||item.noTeleponPic||"-",
                    },
                    {
                      label: "Alamat",
                      searchable: true,
                      sortable: false,
                      key: "alamat",
                      render: (item) => item.alamat || " - ",
                    },
                  ]}
                  actions={actions}
                  itemActions={[
                    new TableWrapper.action(
                      `Detail`,
                      "mdi mdi-import",
                      (item) =>  navigationStore.redirectTo(
                        `/internal/npwp/${npwpHeadId}/lawan-transaksi/${item.id}/lawanTransaksi`
                      ),
                      (item) => {
                        return true;
                      }
                    ),
                    new TableWrapper.action(
                      "Edit",
                      "mdi mdi-pencil",
                      (item) => {
                        setSelectedItemId(item.id);
                        setShowForm(true);
                      },
                      true
                    ),
                    new TableWrapper.action(
                      "Delete",
                      "mdi mdi-delete",
                      (item) => {
                        modalStore.showConfirm({
                          title: "Delete",
                          children: "Apakah Yakin?",
                          onSubmit: async (callback) => {
                            try {
                              await service.delete(item.id);
                              TableWrapper.reload("lawan-transaksi-table");
                              callback("Success");
                            } catch (error) {
                              callback(error);
                            }
                          },
                        });
                      },
                      true
                    ),
                  ]}
                  // {
                  //   async query => {
                  //     query['page'] = query['page']-1;
                  //     console.log(query, 'query')
                  //     console.log(search, 'search')
                  //     let params = {
                  //       ...search,
                  //       ...query
                  //     }
                  //     console.log(params, 'params')
                  //     return service.get(params)
                  //   }
                  // }
                  showCommandbar={showCommandbar}
                  showFilterPeriod={false}
                />
                <LawanTransaksiForm
                  visible={showForm}
                  onRequestClose={() => {
                    setShowForm(false);
                    setSelectedItem(null);
                    document.getElementsByClassName('mdi-reload')[0].click()
                  }}
                  item={selectedItemId}
                />
              </>
            ),
          },
        ]}
      />
    </>
  );
};

export default inject(
  "modalStore",
  "navigationStore"
)(observer(LawanTransaksi));
