import React, { useEffect, useState } from 'react'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { sum } from 'lodash'
import { FormWrapper, TableWrapper } from '../../../libs/react-mpk/wrapper'
import { DataTable } from '../../../libs/react-mpk/components'
import FormTanggal from '../FormTanggal'
import { useHistory, useLocation, useParams } from "react-router-dom";
import iziToast from 'izitoast'
import service from "./ReportNeraca.service";
import moment from 'moment'
import { format, formatRupiah } from '../../../libs/react-mpk/services/number.service'
import utilsService from '../../../services/utilsService'

const defaultData = () => ({
    data:[],
    namaAkun: '',
    saldoAwal: 0,
    saldoAkhir: 0,
    totalDebit: 0,
    totalKredit: 0
})

const ReportTransaksiNeraca = ({
    navigationStore,
    modalStore,
    match
}) => {
    let history = useHistory()
    const { state: { tahun } = {} } = useLocation();
    const [data, setData] = useState([])
    
    let npwpHeadId = match.params.npwpHeadId
    let kategoriAkunId = match.params.kategoriAkunId
    let akunId = match.params.akunId
    
    const [ready, setReady] = useState(false)

    async function hapusExc(item) {
        try {
            var r = await service.deleteById(item.id)

            if (r.data.message == 'sukses') {
                iziToast.success({
                    message: r.data.message
                })
            } else {
                iziToast.info({
                    message: r.data.message
                })
            }
            document.getElementsByClassName('mdi-reload')[0].click()
        } catch (e) {
            // errorService(e)
        }
    }

    async function hapus(item) {
        modalStore.showConfirm({
            title: t.translate('words.delete'),
            children: t.translate('words.confirmHapus'),
            onSubmit: (callback) => {
                hapusExc(item)
                callback()
            }
        })
    }

    async function fetchData() {
        let selectedItem = defaultData()
        try {
            let query = {
                "tahun": tahun?tahun: sessionStorage.getItem('tahunSobatBuku'),
                'akunId': match.params.akunId
            }
            let res = await service.getListTransaksiNeraca(query)
            let { data } = res
            selectedItem.data = data ? data : []
            selectedItem.namaAkun = `${sessionStorage.getItem('akunNama')} - ${sessionStorage.getItem('akunKode')}`
            selectedItem.saldoAwal =  Math.abs(data[0].saldoAwal)

            let totalDebit = 0, totalKredit = 0;
            data.map(d => {
                totalDebit += d.totalDebit
                totalKredit += d.totalKredit
            })
            // selectedItem.saldoAkhir =  Math.abs(selectedItem.saldoAwal+totalDebit-totalKredit)
            selectedItem.saldoAkhir =  Math.abs(data[data.length -1].saldoAkhir)

            setData(selectedItem)

        } catch (error) {

        }
        setReady(true)
    }
    
    useEffect(() => {
        fetchData()
    }, [])
    
    useEffect(() => { 


    },[data])

    return ready && (
        <>
            <FormWrapper
                baseId="transaksi-neraca"
                showCommandbar={true}
                style={{
                    maxWidth: '100%'
                }}
                title={t.translate(`modules.report.transaksiNeraca`)}
                // backTo={`/internal/npwp/${npwpHeadId}/report/detail-laporan-neraca/${kategoriAkunId}`}
                defaultData={data}
                definitions={[
                    {
                        inputType: FormWrapper.inputTypes.INPUT,
                        label: t.translate('words.namaAkun'),
                        key: 'namaAkun',
                        disabled: true,
                        style: {
                            marginLeft: '10px',
                            marginRight: '1%'
                        },
                        width: '32%'
                    },
                    {
                        inputType: FormWrapper.inputTypes.INPUT_MASK_NUMBER,
                        label: t.translate('words.saldoAwal'),
                        key: 'saldoAwal',
                        disabled: true,
                        width: '32%',
                        thousandSeparator: '.',
                        decimalSeparator: ',',
                        prefix:'Rp.',
                        style: {
                            marginRight: '1%'
                        },
                        isNumericString: true,
                    },
                    {
                        inputType: FormWrapper.inputTypes.INPUT_MASK_NUMBER,
                        label: t.translate('words.saldoAkhir'),
                        key: 'saldoAkhir',
                        disabled: true,
                        width: '32%',
                        thousandSeparator: '.',
                        decimalSeparator: ',',
                        prefix:'Rp.',
                        isNumericString: true,
                    },
                    {
                        render: (
                            <DataTable
                                baseId="mod-table-laporan-neraca-harta-lancar"
                                fit={false}
                                loading={false}
                                data={data.data}
                                defaultSortBy="date"
                                columns={[
                                    {
                                        label: t.translate(`words.kategoriAkun`),
                                        searchable: true,
                                        key: 'bulan',
                                        type: TableWrapper.dataTypes.STRING,
                                        render: item =>(utilsService.beautifyBulan(item.bulan) + " " + item.tahun)
                                    },
                                    {
                                        label: t.translate(`words.debit`),
                                        searchable: true,
                                        key: 'totalDebit',
                                        type: TableWrapper.dataTypes.STRING,
                                        render: item => (formatRupiah(item.totalDebit))
                                    },
                                    {
                                        label: t.translate(`words.kredit`),
                                        searchable: true,
                                        key: 'totalKredit',
                                        type: TableWrapper.dataTypes.STRING,
                                        render: item => (formatRupiah(item.totalKredit))
                                    },
                                    {
                                        label: t.translate(`words.saldo`),
                                        searchable: true,
                                        key: 'saldoAkhir',
                                        type: TableWrapper.dataTypes.STRING,
                                        render: item => (formatRupiah(item.saldoAkhir))
                                    },
                                ]}
                                onChangeQuery={async (formData, key, value) => {
                                    if (formData === "total") {
                                        formData.total = sum(formData.nominal)
                                    }
                                }}
                                actions={[]}
                                itemActions={[

                                    new TableWrapper.action(
                                        `${t.translate('words.selengkapnya')}`,
                                        'mdi mdi-import',
                                        (item) => {
                                            history.push({
                                                pathname: `/internal/npwp/${npwpHeadId}/report/detail-laporan-neraca/${kategoriAkunId}/transaksi-neraca/${akunId}/detail-list-transaksi-neraca/${item.bulan}`,
                                                state: { tahun: tahun },
                                              });
                                            // navigationStore.redirectTo(
                                            //     `/internal/npwp/${npwpHeadId}/report/detail-laporan-neraca/${kategoriAkunId}/transaksi-neraca/${akunId}/detail-list-transaksi-neraca/${item.bulan}`
                                            // )
                                            sessionStorage.setItem('saldoAwal', item.saldoAwal)
                                            sessionStorage.setItem('saldoAkhir', item.saldoAkhir)
                                        }
                                    )
                                ]}
                                showFilterPeriod={true}
                                useCriteria={false}
                            />
                        )
                    }
                ]}
            />
        </>
    )
}

export default inject("navigationStore", 'envStore', 'modalStore')(observer(ReportTransaksiNeraca))