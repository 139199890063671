import React, { useEffect, useState } from "react";
import { inputTypes } from "../../libs/react-mpk/config/constant";
import { FormWrapper, TabsWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import { DataForm, Hint } from "../../libs/react-mpk/components";
import { inject, observer } from "mobx-react";
import { find } from "lodash";
import {
  ExpansionList,
  ExpansionPanel,
  Table,
  TableBody,
  TableHeader,
  TableRow,
  TableCell,
  Card,
  Button,
  FontIcon,
  TableFooter,
} from "react-md";

import service from "./Biaya.service";
import LawanTransaksiForm from "../LawanTransaksi/LawanTransaksiForm";

const BiayaCocokkanData = ({
  visible = false,
  authStore,
  envStore,
  match,
  history,
  onRequestClose = () => {},
  ...props
}) => {
  let [item, setItem] = useState(null);
  let [ready, setReady] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [lawanTransaksiList, setLawanTransaksiList] = useState([]);

  useEffect(() => {
    getLawanTransaksiList();
  }, []);

  async function getLawanTransaksiList() {
    setTimeout(async () => {
      let res = await service.getLawanTransaksiSupplier();
      setLawanTransaksiList(res.data);
      // console.log(lawanTransaksiList, "lawanTransaksi");
    }, 500);
  }

  return (<>
    <DataForm
      baseId="biaya-cocokkan-data-form"
      title="Cocokkan Data"
      visible={visible}
      onRequestClose={onRequestClose}
      asDialog={true}
      defaultData={[]}
      definitions={[
        {
          render: (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginBottom: 20,
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Button
                  themeType="outline"
                  style={{
                    borderWidth: 2,
                    borderColor: "#218c74",
                    color: "#218c74",
                    fontWeight: "bold",
                  }}
                  onClick={() => {
                    setShowForm(true);
                  }}
                >
                  <FontIcon iconClassName="mdi mdi-plus" />
                  {t.translate("words.add")} Lawan Transaksi
                </Button>
              </div>
            </div>
          ),
        },
        {
          render: (
            <div style={{ width: "100%" }}>
              <Table style={{ width: "100%" }}>
                <TableHeader>
                  <TableRow>
                    <TableCell style={{ width: "10%" }}>
                      {t.translate("words.non")}
                    </TableCell>
                    <TableCell style={{ width: "25%" }}>
                      {t.translate("words.nama")}
                    </TableCell>
                    <TableCell style={{ width: "25%" }}>
                      {t.translate("words.npwp")}
                    </TableCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {lawanTransaksiList.map((d, index) => {
                    // console.log(lawanTransaksiList, "lawantransaksilist");
                    return (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{d.nama}</TableCell>
                        <TableCell>{d.npwp}</TableCell>
                        <TableCell>
                          <Button
                            theme="primary"
                            themeType="contained"
                            onClick={() => {
                              // console.log(d,"d")
                              onRequestClose(d);
                            }}
                          >
                            Pilih
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          ),
        },
      ]}
    />
     <LawanTransaksiForm
                  visible={showForm}
                  onRequestClose={() => {
                    setShowForm(false);
                    getLawanTransaksiList()
                  }}
                  item={null}
                />
  </>
  );
};

export default BiayaCocokkanData;
