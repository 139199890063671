import React, { useEffect, useState } from "react";
import { inputTypes } from "../../libs/react-mpk/config/constant";
import { FormWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import { find } from "lodash";
import {
  ExpansionList,
  ExpansionPanel,
  Table,
  TableBody,
  TableHeader,
  TableRow,
  TableCell,
  Button,
  FontIcon,
  Text,
  Divider,
  TextContainer,
} from "react-md";
import InputProdukPembelian from "./InputProdukPembelian";
import service from "./Pembelian.service";
import moment from "moment";
import "moment/locale/id";
import LawanTransaksiForm from "../LawanTransaksi/LawanTransaksiForm";
import utilsService from "../../services/utilsService";
import { toast } from "../../libs/react-mpk/services";
import {
  format,
  formatRupiah,
} from "../../libs/react-mpk/services/number.service";

const KATEGORI_PEMBELIAN = 1;

const KATEGORI_TRANSAKSI_NORMAL = "1";

const STAT_BELUM_BAYAR = 1;
const STAT_LUNAS = 3;

const statusBayar = [
  { value: STAT_LUNAS, name: "Lunas" },
  { value: STAT_BELUM_BAYAR, name: "Belum bayar" },
];

const PembelianFormNonPkp = ({
  envStore,
  match,
  modalStore,
  navigationStore,
  ...props
}) => {
  const defaultData = {
    lawanTransaksiId: "",
    lawanTransaksiNama: "",
    lawanTransaksiNik: "",
    lawanTransaksiNpwp: "",
    lawanTransaksiAlamat: "",
    lawanTransaksi: {
      id: null,
    },
    kategori: KATEGORI_PEMBELIAN.toString(),
    kategoriTransaksi: KATEGORI_TRANSAKSI_NORMAL.toString(),
    tanggalTransaksi: "",
    pembayaranStatus: STAT_BELUM_BAYAR.toString(),
    pembayaranAkunId: null,
    pembayaranAkun: {
      id: null,
    },
    keterangan: null,
    fileLampiran: null,
    lampiran: {
      fileLampiran: null,
    },
    periodeJatuhTempo: 0,
    tanggalJatuhTempo: "",
    subtotal: 0,
    diskon: 0,
    ppn: 0,
    total: 0,
    totalPotongPajak: 0,
    totalPotongPajakDitanggung: 0,
    totalBeban: 0,
    akunUtang: null,
  };
  const [showForm, setShowForm] = useState(false);
  let [item, setItem] = useState(defaultData);
  let [idAkun, setIdAkun] = useState(null);
  const [panel1, setPanel1] = useState(true);
  const [panel2, setPanel2] = useState(false);
  const [panel3, setPanel3] = useState(false);
  const [loadingAll, setLoadingAll] = useState(true);
  const [loading, setLoading] = useState(true);
  const [loadingLawanTransaksi, setLoadingLawanTransaksi] = useState(false);

  let [dataSubmit, setDataSubmit] = useState({
    fm: {
      lawanTransaksi: {
        id: null,
      },
      kategori: KATEGORI_PEMBELIAN,
      kategoriTransaksi: KATEGORI_TRANSAKSI_NORMAL,
      tanggalTransaksi: "",
      pembayaran: {
        status: STAT_BELUM_BAYAR,
        akun: null,
      },
      periodeJatuhTempo: 0,
      tanggalJatuhTempo: "",
      subtotal: 0,
      total: 0,
      totalPotongPajak: 0,
      totalPotongPajakDitanggung: 0,
      totalBeban: 0,
      npwpHead: {
        id: sessionStorage.getItem("npwpHeadId"),
      },
      akunUtang: {
        id: null,
      },
      isPpn: false,
      keterangan: "",

      fileLampiran: null,
    },
    fmAkunPembelianList: [],
    fmAkunBebanList: [],
    fmAkunPembelianHapusList: [],
    fmAkunBebanHapusList: [],
  });

  const [dialogPembelianProdukForm, setDialogPembelianProdukForm] =
    useState(false);
  const [dialogData, setDialogData] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [dataPembelian, setDataPembelian] = useState([]);
  // Lawan Transaksi
  const [lawanTransaksiList, setLawanTransaksiList] = useState([]);
  const [loadinglawanTransaksi, setLoadinglawanTransaksi] = useState(false);
  let [queryLawanTransaksi, setQueryLawanTransaksi] = useState("");
  // akun kas dan bank
  const [akunKasBankList, setAkunKasBankList] = useState([]);
  let [queryAkunKasBank, setQueryAkunKasBank] = useState("");
  const [loadingakunKasBank, setLoadingAkunKasBank] = useState(true);
  //akun utang
  const [akunUtangList, setAkunUtangList] = useState([]);
  let [queryAkunUtang, setQueryAkunUtang] = useState("");
  const [loadingakunUtang, setLoadingakunUtang] = useState(false);
  useEffect(() => {
    setLoading(true);
    initData();
    getListAkunUtang("Utang Usaha");
    getListAkunPembelian();
    setLoading(false);
  }, []);

  async function initData() {
    let selectedItem = item;

    if (match.params.noTransaksi != "new") {
      setDisabled(true);
      try {
        let res = await service.getOneByNoTransaksi(match.params.noTransaksi);

        let { data } = res;

        setDataSubmit(data);

        selectedItem.id = data.id;
        selectedItem.keterangan = data.keterangan;

        selectedItem.lawanTransaksiId = `${data.lawanTransaksi.npwp} - ${data.lawanTransaksi.nama}`;
        selectedItem.lawanTransaksiNama = data.lawanTransaksi.nama;
        selectedItem.lawanTransaksiNik = data.lawanTransaksi.nik;
        selectedItem.lawanTransaksiNpwp = data.lawanTransaksi.npwp;
        selectedItem.lawanTransaksiAlamat = data.lawanTransaksi.alamat;
        selectedItem.lawanTransaksi.id = data.lawanTransaksi.id;
        selectedItem.kategori = KATEGORI_PEMBELIAN;
        selectedItem.kategoriTransaksi = KATEGORI_TRANSAKSI_NORMAL;
        selectedItem.tanggalTransaksi = data.tanggalTransaksi;
        if (data.pembayaran) {
          selectedItem.pembayaranStatus = data.pembayaran.status.toString();
          if (data.pembayaran.akun) {
            selectedItem.pembayaranAkunId = `${data.pembayaran.akun.kode} - ${data.pembayaran.akun.nama}`;
            selectedItem.pembayaranAkun.id = data.pembayaran.akun.id;
          }
        }
        selectedItem.periodeJatuhTempo = data.periodeJatuhTempo;
        selectedItem.tanggalJatuhTempo = data.tanggalJatuhTempo;
        selectedItem.subtotal = data.subtotal;
        selectedItem.totalPotongPajak = data.totalPotongPajak;
        selectedItem.totalPotongPajakDitanggung =
          data.totalPotongPajakDitanggung;
        selectedItem.totalBeban = data.totalBeban;
        if (data.akunUtang) {
          selectedItem.akunUtang.id = data.akunUtang.id;
        }
      } catch (e) {}
    } else {
      setDisabled(false);
    }
    setItem(selectedItem);
  }

  //Lawan Transaksi
  useEffect(() => {
    setLoadinglawanTransaksi(true);
    const timeOutId = setTimeout(
      () => getLawanTransaksi(queryLawanTransaksi),
      queryLawanTransaksi ? 500 : 0
    );
    return () => clearTimeout(timeOutId);
  }, [queryLawanTransaksi]);
  //Akun Kas Bank
  useEffect(() => {
    setLoadingAkunKasBank(true);
    const timeOutId = setTimeout(
      () => getListAkunKasBank(queryAkunKasBank),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [queryAkunKasBank]);

  async function getLawanTransaksi(queryNama) {
    try {
      setLoadinglawanTransaksi(true);
      let res = await service.getListLawanTransaksi(queryNama);
      var lawanTransaksis = [];
      res.data.map((d) => {
        lawanTransaksis.push({
          value: d.id,
          label: d.nama,
          nama: d.nama,
          nik: d.nik,
          npwp: d.npwp,
          alamat: d.alamat,
          noTelepon: d.noTelepon,
        });
      });
      // if (queryNama) {
      //   let cektr = find(lawanTransaksis, {
      //     label: queryNama,
      //   });
      //   if (!cektr) {
      //     lawanTransaksis.push({
      //       value: 0,
      //       label: queryNama,
      //       nama: queryNama,
      //       nik: "0000000000000000",
      //       npwp: "000000000000000",
      //       alamat: "-",
      //     });
      //   }
      // }
      setLawanTransaksiList(lawanTransaksis);
      setLoadinglawanTransaksi(false);
    } catch (error) {
      toast.errorRequest(error);
      setLoadinglawanTransaksi(false);
    }
  }

  async function getListAkunKasBank(queryNama) {
    try {
      setLoadingAkunKasBank(true);
      let res = await service.getListAkunKasBank(queryNama);
      var akuns = [];
      res.data.map((d) => {
        akuns.push({
          value: d.id,
          label: `${d.kode} - ${d.nama}`,
        });
      });
      setAkunKasBankList(akuns);
      setLoadingAkunKasBank(false);
    } catch (error) {
      toast.errorRequest(error);
      setLoadingAkunKasBank(false);
    }
  }

  async function getListAkunUtang(queryNama) {
    try {
      let res = await service.getListAkunUtang(queryNama);
      setItem({
        ...item,
        akunUtang: res.data[0].id,
        akunUtangNama: `${res.data[0].kode} - ${res.data[0].nama}`,
      });
    } catch (error) {
      toast.errorRequest(error);
    }
  }
  async function getListAkunPembelian() {
    setTimeout(async () => {
      let res = await service.getListAkunPembelian("Persediaan Barang Dagang");
      res.data.map((d) => setIdAkun(d.id));
    }, 500);
  }

  const activePanel1 = () => {
    setPanel1(true);
    setPanel2(false);
    setPanel3(false);
  };

  const activePanel2 = () => {
    setPanel1(false);
    setPanel2(true);
    setPanel3(false);
  };

  const activePanel3 = () => {
    setPanel1(false);
    setPanel2(false);
    setPanel3(true);
  };

  var onSubmitIdentitas = async (data, callback) => {
    setItem(data);
    activePanel2();
  };

  var onSubmitTransaksi = async (data, callback) => {
    setItem(data);
    activePanel3();
  };

  var onSubmitProduk = async (data, callback) => {
    let resGambar;
    if (data.fileLampiran != null) {
      resGambar = await service.upload(data.fileLampiran);
    }
    let reqData = dataSubmit;
    reqData.fm.id = data.id;

    if (match.params.isPpn === "true") {
      reqData.fm.isPpn = true;
    } else {
      reqData.fm.isPpn = false;
    }
    reqData.fm.lawanTransaksi = data.lawanTransaksi;
    reqData.fm.kategori = data.kategori;
    reqData.fm.kategoriTransaksi = data.kategoriTransaksi;
    reqData.fm.tanggalTransaksi = moment(data.tanggalTransaksi).format(
      "yyyy-MM-DD hh:mm:ss"
    );
    if (data.pembayaranStatus == STAT_BELUM_BAYAR) {
      reqData.fm.akunUtang.id = item.akunUtang;

      reqData.fm.pembayaran.status = STAT_BELUM_BAYAR;
      reqData.fm.pembayaran.akun = null;

      reqData.fm.periodeJatuhTempo = data.periodeJatuhTempo;
      reqData.fm.tanggalJatuhTempo = moment(data.tanggalJatuhTempo).format(
        "yyyy-MM-DD hh:mm:ss"
      );
    } else {
      reqData.fm.akunUtang = null;

      reqData.fm.pembayaran.status = STAT_LUNAS;
      reqData.fm.pembayaran.akun = data.pembayaranAkun;

      reqData.fm.periodeJatuhTempo = 0;
      reqData.fm.tanggalJatuhTempo = null;
    }

    reqData.fm.keterangan = data.keterangan;
    reqData.fm.fileLampiran =
      data.fileLampiran != null ? resGambar.data.namaFileAlias : null;
    reqData.fm.totalPotongPajakDitanggung = 0;
    reqData.fm.totalPotongPajak = 0;
    reqData.fm.totalBeban = 0;
    reqData.fm.total = dataPembelian
      .map((d) => d.total)
      .reduce((total, datas) => {
        return total + datas;
      }, 0);
    reqData.fm.subtotal = reqData.fm.total;
    reqData.fm.npwpHead.id = sessionStorage.getItem("npwpHeadId");
    reqData.fmAkunPembelianList = dataPembelian;
    reqData.fmAkunBebanList = [];
    reqData.fmAkunPembelianHapusList = [];
    reqData.fmAkunBebanHapusList = [];

    if (reqData.fm.id) {
      try {
        const res = await service.update(reqData);
        let { data, status } = res;
        if (status == 200) {
          callback("success", false);
          navigationStore.redirectTo(backTo);
          // window.location.href = `${basePath}/${item.id}/history`
        }
      } catch (err) {
        callback(err, true, false);
      }
    } else {
      try {
        const res = await service.createNonPkp(reqData);
        let { data, status } = res;
        if (status == 200) {
          if (reqData.fm.isPpn === false) {
            callback("success", false);
            navigationStore.redirectTo(backTo);
          } else {
            callback("success", false);
            navigationStore.redirectTo(`/internal/npwp/${npwpHeadId}/ppn`);
          }
          // window.location.href = `${basePath}/${item.id}/history`
        }
      } catch (err) {
        callback(err, true, false);
      }
    }
  };

  const baseName = `pembelian`;
  const basePath = `pembelian`;
  var npwpHeadId = match.params.npwpHeadId;
  var backTo = `/internal/npwp/${npwpHeadId}/pembelian`;

  // setDokumens(dokumensData);
  useEffect(() => {
    setLoadingAll(loadingLawanTransaksi || loading);
  }, [loadingLawanTransaksi, loading]);

  async function akunPembelian() {
    var akunPembelianForm = JSON.parse(
      localStorage.getItem("produkPembelianForm")
    );
    if (akunPembelianForm) {
      var dataPembelianNew = dataPembelian;
      dataPembelianNew.push(akunPembelianForm);
      setDataPembelian(dataPembelianNew);
      localStorage.removeItem("produkPembelianForm");
    }
  }
  async function hapusPembelian(index) {
    modalStore.showConfirm({
      title: t.translate("words.delete"),
      children: t.translate("words.confirmHapus"),
      onSubmit: (callback) => {
        var deleteIndex = dataPembelian;
        deleteIndex.splice(index, 1);
        setDataPembelian(deleteIndex);
        callback();
      },
    });
  }
  function addItem() {
    setDialogPembelianProdukForm(true);
    setDialogData({});
  }

  return (
    <>
      <FormWrapper
        loading={loadingAll}
        backTo={backTo}
        showCommandbar={true}
        baseId={`mod-form-${baseName}`}
        title={t.translate(`modules.pembelian.form.nonPkp.title`)}
        style={{
          maxWidth: "100%",
        }}
        definitions={[
          {
            render: (
              <ExpansionList style={{ width: "100%" }}>
                {/* FORM IDENTITAS */}
                <ExpansionPanel
                  expanded={panel1}
                  onClick={() => activePanel1()}
                  header={t.translate("words.identity")}
                >
                  <FormWrapper
                    submitLabel={t.translate("words.next")}
                    loading={loadingAll}
                    showCommandbar={false}
                    baseId={`mod-form-${baseName}`}
                    style={{
                      maxWidth: "100%",
                    }}
                    defaultData={item}
                    definitions={[
                      {
                        render: (
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              marginBottom: 20,
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <b style={{ marginTop: "5%" }}>
                                {t.translate(`words.dataLawanTransaksi`)}
                              </b>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Button
                                themeType="outline"
                                style={{
                                  borderWidth: 2,
                                  borderColor: "#218c74",
                                  color: "#218c74",
                                  fontWeight: "bold",
                                }}
                                onClick={() => {
                                  setShowForm(true);
                                }}
                              >
                                <FontIcon iconClassName="mdi mdi-plus" />
                                {t.translate("words.add")} Lawan Transaksi
                              </Button>
                            </div>
                          </div>
                        ),
                      },
                      {
                        inputType: inputTypes.REACT_SELECT,
                        isClearable: true,
                        label: t.translate(`words.lawanTransaksi`),
                        key: "lawanTransaksiId",
                        validation: "required",
                        labelKey: "label",
                        valueKey: "value",
                        options: lawanTransaksiList,
                        isLoading: loadinglawanTransaksi,
                        onInputChange: (value) => {
                          if (value) setQueryLawanTransaksi(value);
                        },
                        disabled: disabled,
                      },
                      {
                        render: (
                          <div style={{ width: "100%", marginBottom: 30 }}>
                            <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                              {t.translate(`words.dataLawanTransaksi`)}
                              <Divider />
                            </Text>
                          </div>
                        ),
                      },
                      {
                        inputType: inputTypes.INPUT,
                        label: "Nama",
                        key: "lawanTransaksiNama",
                        required: true,
                        disabled: true,
                      },
                      {
                        inputType: inputTypes.INPUT_MASK_NUMBER,
                        className: "mpk-padding-N padding-right",
                        label: "NIK/Paspor",
                        key: "lawanTransaksiNik",
                        mask: "################",
                        maskChar: "_",
                        required: true,
                        width: "50%",
                        disabled: true,
                      },
                      // -----
                      {
                        inputType: inputTypes.INPUT_MASK_NUMBER,
                        className: "mpk-padding-N padding-left",
                        label: "NPWP",
                        mask: "##.###.###.#-###.###",
                        maskChar: "_",
                        key: "lawanTransaksiNpwp",
                        width: "50%",
                        required: true,
                        disabled: true,
                      },
                      {
                        inputType: inputTypes.INPUT_MASK_NUMBER,
                        className: "mpk-padding-N padding-right",
                        label: "Nomor Telepon",
                        key: "lawanTransaksiNoTelepon",
                        type: "text",
                        disabled: true,
                        width: "50%",
                        required: true,
                      },
                      {
                        inputType: inputTypes.INPUT,
                        className: "mpk-padding-N padding-left",
                        label: "Alamat",
                        key: "lawanTransaksiAlamat",
                        width: "50%",
                        disabled: true,
                        required: true,
                      },
                    ]}
                    onChange={(formData, key, value) => {
                      if (key == "lawanTransaksiId") {
                        lawanTransaksiList.map((d) => {
                          if (d.value == value) {
                            console.log(d, "d");
                            formData["lawanTransaksi"]["id"] = d.value;
                            formData["lawanTransaksi"]["nama"] = d.nama;
                            formData["lawanTransaksiNama"] = d.nama;
                            formData["lawanTransaksiNik"] = d.nik;
                            formData["lawanTransaksiNpwp"] = d.npwp;
                            formData["lawanTransaksiAlamat"] = d.alamat;
                            formData["lawanTransaksiNoTelepon"] = d.noTelepon;
                          }
                        });
                      }
                      setItem(formData);
                      return formData;
                    }}
                    onSubmit={onSubmitIdentitas}
                  />
                </ExpansionPanel>

                <br />

                {/* FORM TRANSAKSI */}
                <ExpansionPanel
                  expanded={panel2}
                  onClick={() => activePanel2()}
                  header={t.translate("words.transaksi")}
                >
                  <FormWrapper
                    submitLabel={t.translate("words.next")}
                    loading={loadingAll}
                    showCommandbar={false}
                    baseId={`mod-form-${baseName}`}
                    style={{
                      maxWidth: "100%",
                    }}
                    defaultData={item}
                    definitions={[
                      {
                        render: (
                          <div style={{ width: "100%", marginBottom: 30 }}>
                            <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                              Data Pembelian
                              <Divider />
                            </Text>
                          </div>
                        ),
                      },
                      // -----
                      {
                        inputType: inputTypes.DATEPICKER,
                        className: "mpk-padding-N padding-right",
                        label: "Tanggal Transaksi",
                        key: "tanggalTransaksi",
                        width: "50%",
                        required: true,
                        disabled: disabled,
                        maxDate: new Date(),
                        style: { zIndex: 2 },
                      },
                      {
                        inputType: inputTypes.SELECT,
                        label: "Status Pembayaran",
                        key: "pembayaranStatus",
                        width: "50%",
                        required: true,
                        options: statusBayar.map((d) => ({
                          label: d.name,
                          value: d.value,
                        })),
                        disabled: disabled,
                      },
                      {
                        inputType: inputTypes.REACT_SELECT,
                        isClearable: true,
                        label: t.translate(`words.transferDari`),
                        key: "pembayaranAkunId",
                        labelKey: "label",
                        valueKey: "value",
                        options: akunKasBankList,
                        isLoading: loadingakunKasBank,
                        onInputChange: (value) => {
                          if (value) setQueryAkunKasBank(value);
                        },
                        style: { zIndex: 1 },
                        show: (d) => {
                          return d.pembayaranStatus == STAT_LUNAS;
                        },
                        disabled: disabled,
                      },
                      {
                        inputType: inputTypes.INPUT,
                        label: t.translate(`words.akunUtang`),
                        key: "akunUtangNama",
                        style: {
                          zIndex: 1,
                          backgroundColor: "rgb(242, 242, 242)",
                        },
                        show: (d) => {
                          return d.pembayaranStatus == STAT_BELUM_BAYAR;
                        },
                        disabled: true,
                      },
                      {
                        inputType: inputTypes.INPUT_MASK_NUMBER,
                        thousandSeparator: ".",
                        decimalSeparator: ",",
                        isNumericString: true,
                        className: "mpk-padding-N padding-right",
                        label: "Periode Jatuh Tempo (Hari)",
                        key: "periodeJatuhTempo",
                        width: "50%",
                        show: (d) => {
                          return d.pembayaranStatus == STAT_BELUM_BAYAR;
                        },
                        disabled: disabled,
                      },
                      {
                        inputType: inputTypes.DATEPICKER,
                        label: "Tanggal Jatuh Tempo",
                        key: "tanggalJatuhTempo",
                        width: "50%",
                        required: true,
                        disabled: true,
                        show: (d) => {
                          return d.pembayaranStatus == STAT_BELUM_BAYAR;
                        },
                        disabled: true,
                      },
                    ]}
                    onChange={(formData, key, value) => {
                      if (key == "pembayaranAkunId") {
                        akunKasBankList.map((d) => {
                          if (d.value == value) {
                            formData["pembayaranAkun"]["id"] = d.value;
                          }
                        });
                      }

                      if (key == "tanggalTransaksi") {
                        let tanggalTransaksi = new Date(
                          formData["tanggalTransaksi"]
                        );
                        tanggalTransaksi.setDate(
                          tanggalTransaksi.getDate() + formData["jatuhTempo"]
                        );
                        formData["tanggalJatuhTempo"] = tanggalTransaksi;
                      }

                      if (key == "periodeJatuhTempo") {
                        let tanggalTransaksi = new Date(
                          formData["tanggalTransaksi"]
                        );
                        tanggalTransaksi.setDate(
                          tanggalTransaksi.getDate() + value
                        );
                        formData["tanggalJatuhTempo"] = tanggalTransaksi;
                      }
                      console.log(formData);
                    }}
                    onSubmit={onSubmitTransaksi}
                  />
                </ExpansionPanel>

                <br />

                {/* FORM PRODUK */}
                <ExpansionPanel
                  expanded={panel3}
                  onClick={() => activePanel3()}
                  header={t.translate("words.produk")}
                >
                  <InputProdukPembelian
                    visible={dialogPembelianProdukForm}
                    onRequestClose={() => {
                      akunPembelian();
                      setDialogPembelianProdukForm(false);
                      setDialogData(null);
                    }}
                    item={dialogData}
                    isPpn={false}
                  />
                  <FormWrapper
                    submitLabel={t.translate("words.submit")}
                    loading={loadingAll}
                    showCommandbar={false}
                    baseId={`mod-form-${baseName}`}
                    style={{
                      maxWidth: "100%",
                    }}
                    defaultData={item}
                    definitions={[
                      {
                        render: (
                          <div style={{ width: "100%" }}>
                            <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                              Produk Pembelian
                              {dataPembelian.length <= 0 && (
                                <Button
                                  theme="primary"
                                  themeType="contained"
                                  style={{ marginBottom: 5, float: "right" }}
                                  onClick={() => addItem()}
                                  disabled={disabled}
                                >
                                  <FontIcon iconClassName="mdi mdi-plus" />
                                  {t.translate("words.add")}
                                </Button>
                              )}
                              <Divider />
                            </Text>
                            {dataPembelian.length > 0 ? (
                              <>
                                <Table style={{ width: "100%" }}>
                                  <TableHeader>
                                    <TableRow>
                                      <TableCell style={{ width: "5%" }}>
                                        No
                                      </TableCell>
                                      <TableCell style={{ width: "5%" }}>
                                        Kode
                                      </TableCell>
                                      <TableCell style={{ width: "15%" }}>
                                        Nama
                                      </TableCell>
                                      <TableCell style={{ width: "5%" }}>
                                        Kuantitas
                                      </TableCell>
                                      <TableCell style={{ width: "10%" }}>
                                        Harga Satuan
                                      </TableCell>
                                      <TableCell style={{ width: "10%" }}>
                                        Diskon (%)
                                      </TableCell>
                                      <TableCell style={{ width: "10%" }}>
                                        PPN
                                      </TableCell>
                                      <TableCell style={{ width: "15%" }}>
                                        Jumlah
                                      </TableCell>
                                      <TableCell style={{ width: "25%" }}>
                                        Keterangan
                                      </TableCell>
                                    </TableRow>
                                  </TableHeader>
                                  <TableBody>
                                    {dataPembelian.map((d, index) => {
                                      return (
                                        <TableRow key={index}>
                                          <TableCell>{index + 1}</TableCell>
                                          <TableCell>
                                            {d.produk?.kode || `PBD`}
                                          </TableCell>
                                          <TableCell>
                                            {d.produk?.nama ||
                                              `Persediaan Barang Dagang`}
                                          </TableCell>
                                          <TableCell>
                                            {d.jumlahBarang || ` - `}
                                          </TableCell>
                                          <TableCell>
                                            {formatRupiah(
                                              d.hargaSatuan || d.jumlah
                                            )}
                                          </TableCell>
                                          <TableCell>
                                            {format(d.isDiskon)} %
                                          </TableCell>
                                          <TableCell>
                                            {formatRupiah(d.ppn)}
                                          </TableCell>
                                          <TableCell>
                                            {formatRupiah(d.jumlah)}
                                          </TableCell>
                                          <TableCell>
                                            {d.keterangan
                                              ? d.keterangan.substring(0, 50)
                                              : "-"}
                                          </TableCell>
                                          <TableCell>
                                            <Button
                                              style={{
                                                backgroundColor: "#34495e",
                                                color: "white",
                                              }}
                                              onClick={() =>
                                                hapusPembelian(index)
                                              }
                                            >
                                              {t.translate("words.delete")}
                                            </Button>
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })}
                                  </TableBody>
                                </Table>
                              </>
                            ) : (
                              <TextContainer style={{ textAlign: "center" }}>
                                <Text
                                  type="headline-6"
                                  style={{ color: "#7f8c8d" }}
                                >
                                  Tidak ada data
                                </Text>
                              </TextContainer>
                            )}
                          </div>
                        ),
                      },
                      {
                        render: (
                          <Divider
                            style={{ margin: 10, width: "100%" }}
                          ></Divider>
                        ),
                      },
                      {
                        inputType: inputTypes.INPUT,
                        label: t.translate(`words.keterangan`),
                        key: "keterangan",
                        type: "text",
                        width: "40%",
                        style: { marginRight: "30%" },
                      },
                      {
                        inputType: inputTypes.INPUT_MASK_NUMBER,
                        label: "SubTotal",
                        key: "subtotal",
                        thousandSeparator: ".",
                        decimalSeparator: ",",
                        isNumericString: true,
                        prefix: "Rp.",
                        type: "text",
                        width: "30%",
                        value: dataPembelian
                          .map((d) => d.subtotal)
                          .reduce((subtotal, datas) => {
                            return subtotal + datas;
                          }, 0),
                        disabled: true,
                      },
                      {
                        inputType: inputTypes.FILE_INPUT,
                        label: t.translate("words.file"),
                        key: "fileLampiran",
                        type: "file",
                        accept: "application/pdf, image/*",

                        style: { width: "40%", marginRight: "30%" },
                      },
                      {
                        inputType: inputTypes.INPUT_MASK_NUMBER,
                        label: "Diskon",
                        key: "diskon",
                        thousandSeparator: ".",
                        decimalSeparator: ",",
                        isNumericString: true,
                        prefix: "Rp.",
                        type: "text",
                        width: "30%",
                        value: dataPembelian
                          .map((d) => d.diskon)
                          .reduce((diskon, datas) => {
                            return diskon + datas;
                          }, 0),
                        disabled: true,
                      },
                      {
                        inputType: inputTypes.INPUT_MASK_NUMBER,
                        label: "PPn",
                        key: "ppn",
                        thousandSeparator: ".",
                        decimalSeparator: ",",
                        isNumericString: true,
                        type: "text",
                        prefix: "Rp.",
                        width: "30%",
                        style: { marginLeft: "70%" },
                        value: dataPembelian
                          .map((d) => d.ppn)
                          .reduce((ppn, datas) => {
                            return ppn + datas;
                          }, 0),
                        disabled: true,
                      },
                      {
                        inputType: inputTypes.INPUT_MASK_NUMBER,
                        label: "Total",
                        key: "total",
                        thousandSeparator: ".",
                        decimalSeparator: ",",
                        isNumericString: true,
                        prefix: "Rp.",
                        type: "text",
                        width: "30%",
                        style: { marginLeft: "70%" },
                        value: dataPembelian
                          .map((d) => d.total)
                          .reduce((total, datas) => {
                            return total + datas;
                          }, 0),
                        disabled: true,
                      },
                      {
                        render: <div style={{ margin: 10 }}></div>,
                      },
                    ]}
                    onChange={async (formData, key, value) => {
                      return formData;
                    }}
                    onSubmit={onSubmitProduk}
                  />
                </ExpansionPanel>
              </ExpansionList>
            ),
          },
        ]}
        // onSubmit={async (data, callback) => {

        // }}
      />

      <LawanTransaksiForm
        visible={showForm}
        onRequestClose={() => {
          setShowForm(false);
          getLawanTransaksi();
        }}
        item={null}
      />
    </>
  );
};

export default inject(
  "envStore",
  "modalStore",
  "navigationStore"
)(observer(PembelianFormNonPkp));
