import { crud, http } from '../../libs/react-mpk/services';

let service = null;
let serviceUrl = '/ppn'
let npwpHeadIdObject = {
    npwpHeadId: sessionStorage.getItem('npwpHeadId')
}
let npwpHeadId = sessionStorage.getItem('npwpHeadId')
service = new crud(`/ppn`)

service.getAllFk = async (query) => {
    query = {
        ...query,
        ...npwpHeadIdObject
    }
    return http.request({
        method: http.methods.GET,
        url: `${serviceUrl}/fk`,
        query
    })
}

service.batalkanFk = async (id) => {
    let query = {
        'npwpHeadId': npwpHeadId
    }
    return http.request({
        method: http.methods.PUT,
        url: `/penjualan/${id}/batalpkp`,
        query
    })
}

service.batalkanFm = async (id) => {
    let query = {
        'npwpHeadId': npwpHeadId
    }
    return http.request({
        method: http.methods.PUT,
        url: `/fm/${id}/batal`,
        query
    })
}

service.deletePkp = async (id) => {
    let query = {
        'npwpHeadId': npwpHeadId
    }
    return http.request({
        method: http.methods.DELETE,
        url: `/penjualan/${id}/deletepkp`,
        query
    })
}

service.deleteFm = async (id) => {
    let query = {
        'npwpHeadId': npwpHeadId
    }
    return http.request({
        method: http.methods.DELETE,
        url: `/fm/${id}/deletepkp`,
        query
    })
}

service.getHistoryFk = async (id) => {
    let query = {
        'npwpHeadId': npwpHeadId,
        'fkId.equals': id
    }
    return http.request({
        method: http.methods.GET,
        url: `${serviceUrl}/fk/riwayat_transaksi`,
        query
    })
}

service.getFkById = async (id) => {
    let query = {
        'npwpHead': npwpHeadId
    }
    return http.request({
        method: http.methods.GET,
        url: `${serviceUrl}/fk/${id}`,
        query
    })
}

service.getFmById = async (id) => {
    let query = {
        'npwpHead': npwpHeadId
    }
    return http.request({
        method: http.methods.GET,
        url: `${serviceUrl}/fm/${id}`,
        query
    })
}

service.getHistoryFm = async (id) => {
    let query = {
        'npwpHeadId': npwpHeadId,
        'fmId.equals': id
    }
    return http.request({
        method: http.methods.GET,
        url: `${serviceUrl}/fm/riwayat_transaksi`,
        query
    })
}

service.getAllFm = async (query) => {
    query = {
        ...query,
        ...npwpHeadIdObject
    }
    return http.request({
        method: http.methods.GET,
        url: `${serviceUrl}/fm`,
        query
    })
}
service.getAkun = async (query) => {
    query = {
      ...query,
      ...npwpHeadIdObject,
      npwpHeadId: npwpHeadId,
      "size": 9999
    };
    return http.request({
      method: http.methods.GET,
      url: `/akun`,
      query,
    });
  };

service.getJurnalEntryByNoTransaksi = async (noTransaksi, debitKredit) => {
    let query = {
        "page": 0,
        "size": 9999,
        "sort": "tanggalDibuat,DESC",
        "npwpHeadId": sessionStorage.getItem('npwpHeadId'),
        "noTransaksi": noTransaksi,
        "debitKredit": debitKredit
    }
    return http.request({
        method: http.methods.GET,
        url: `/jurnal_entry/get_by_no_transaksi`,
        query
    })
}
export default service;