import React, { useState } from 'react';
import t from 'counterpart'
import { FormWrapper } from '../../../libs/react-mpk/wrapper';
import { DataForm } from '../../../libs/react-mpk/components';

const KasDanBankTambahEditKepemilikanModal = ({
	visible = false,
	authStore,
	envStore,
	match,
	history,
	onRequestClose = () => { },
	...props
}) => {
	const { inputTypes, definition } = FormWrapper
	const [ready, setReady] = useState(true)

	return ready && (
		<DataForm
			baseId="kas-dan-bank-tambah-edit-kepemilikan-modal"
			title={t.translate(`modules.KasDanBank.tambahEditKepemilikanModal`)}
			visible={visible}
			onRequestClose={onRequestClose}
			asDialog={true}
            submitLabel={t.translate(`words.submit`)}
			defaultData={
                {
                    name:"Anton",
                    persentase:"50",
                    jumlahKepemilikanModal:'500.000.000',
                    npwp:"90.123.123.1-100.000",
                    nikPaspor:" 1375011704960001",
                    email:"anton@gmail.com",
                    address:"Jl.Kemanggisan Utara Raya No. J4, Jakarta Barat, DKI Jakarta"
                }
            }
			definitions={[
				{
					inputType: DataForm.inputTypes.INPUT,
					label: t.translate(`words.name`),
					key: 'name',
                },
                {
                    inputType: inputTypes.INPUT,
                    label: t.translate(`words.jumlahKepemilikanModal`),
                    key: 'jumlahKepemilikanModal',
                    width: '49%',
                    style: {
                        marginRight: "2%"
                    }
                },
                {
                    inputType: inputTypes.INPUT_MASK_NUMBER,
                    label: t.translate(`words.persentase`),
                    key: 'persentase',
                    width: '49%',
                },
                {
                    inputType: inputTypes.DIVIDER,
                    label: t.translate(`words.jenisUsahaLawanTransaksi`)
                },
                {
                    inputType: inputTypes.RADIO,
                    label: "Orang Pribadi/Perseorangan",
                    key: 'pribadi',
                    value:'pribadi',
                    style: {
                        width: "50%"
                    }
                },
                {
                    inputType: inputTypes.RADIO,
                    label: "Badan",
                    key: 'badan',
                    value:'badan',
                    style: {
                        width: "50%"
                    }
                },
                {
                    inputType: inputTypes.INPUT_MASK_NUMBER,
                    label: t.translate(`words.npwp`),
                    key: 'npwp',
                    mask: '##.###.###.# - ###.###',
                    maskChar: '_',
                    maskValue:'string',
                    width: '49%',
                    style: {
                        marginRight: "2%"
                    }
                },
                {
                    inputType: inputTypes.INPUT,
                    label: t.translate(`words.nikPaspor`),
                    key: 'nikPaspor',
                    width: '49%'
                },
                {
                    inputType: inputTypes.INPUT,
                    label: t.translate(`words.email`),
                    key: 'email',
                    width: '49%',
                    style: {
                        marginRight: "2%"
                    }
                },
                {
                    inputType: inputTypes.TEXTAREA,
                    label: t.translate(`words.address`),
                    key: 'address',
                    width: '49%',
                }
			]}
			onSubmit={(data, callback) => {
                // console.log(data);
                setTimeout(callback, 2000)
            }}
		/>
	)
}

export default KasDanBankTambahEditKepemilikanModal