
import React, { useEffect, useState } from "react";
import { Button } from "react-md";
import { DataForm } from "../../libs/react-mpk/components";
import service from "./PPh.service";

const MpnBayar = ({
  visible = false,
  authStore,
  envStore,
  onRequestClose = () => {},
  ...props
}) => {
  let [ready, setReady] = useState(true);
  const [Url, setUrl] = useState('')

  useEffect(() => {
    async function initData() {
        if (props.idBilling) {
            let res = await service.generateMpn(props.idBilling);
            setUrl(res.data.accessKey)
          }
      setReady(visible);
    }
    initData();
  }, [visible]);

  return (
    ready && (
      <DataForm
        baseId="detail-mpn-bayar-form"
        // title="Detail "
        visible={visible}
        onRequestClose={onRequestClose}
        asDialog={true}
        defaultData={[]}
        definitions={[
          {
            render: (
              <iframe width={700} height={600} src={Url}></iframe>
            ),
          },
        ]}
      />
    )
  );
};


export default MpnBayar;