import React, { useEffect, useState } from "react";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import { FormWrapper } from "../../../libs/react-mpk/wrapper";
import {
  Button,
  Card,
  Divider,
  FontIcon,
  List,
  ListItem,
  Text,
} from "react-md";
import service from "./ReportLabaRugiKomersial.service";
import {
  format,
  formatRupiah,
} from "../../../libs/react-mpk/services/number.service";
import { useHistory, useLocation } from "react-router-dom";
import AsyncButton from "../../../libs/react-mpk/components/AsyncButton/AsyncButton";
import { toast } from "../../../libs/react-mpk/services";
import ReportLabaRugiKomersialExpansion from "./ReportLabaRugiKomersialExpansion";
import { LoaderInfo } from "../../../libs/react-mpk/components";

const ReportLabaRugiKomersial = ({ match }) => {
  let history = useHistory();
  const datas = match?.url;
  const datass = datas?.includes("report-laba-rugi");
  const { state: { tahun } = {} } = useLocation();
  let tahunSobatPajak = sessionStorage.getItem("tahunSobatBuku");
  let npwpHeadId = sessionStorage.getItem("npwpHeadId");
  const [dataPenjualan, setDataPenjualan] = useState([]);
  const [dataHPP, setDataHPP] = useState([]);
  const [dataBebanOperasional, setDataBebanOperasional] = useState([]);
  const [dataPendapatanLainnya, setDataPendapatanLainnya] = useState([]);
  const [dataBebanLainnya, setDataBebanLainnya] = useState([]);
  const [dataDetail, setDataDetail] = useState({
    dataPenjualan: [],
    dataHPP: [],
    dataBebanOperasional: [],
    dataPendapatanLainnya: [],
    dataBebanLainnya: [],
  });
  const [panelPenjualan, setPanelPenjualan] = useState(false);
  const [panelHPP, setPanelHPP] = useState(false);
  const [panelBebanOperasional, setPanelBebanOperasional] = useState(false);
  const [panelPendapatanLainnya, setPanelPendapatanLainnya] = useState(false);
  const [panelBebanLainnya, setPanelBebanLainnya] = useState(false);
  const [data, setData] = useState(null);
  const [load, setload] = useState(false);
  const [loadDataPenjualan, setLoadDataPenjualan] = useState(false);
  const [loadDataHPP, setLoadDataHPP] = useState(false);
  const [loadDataBebanOperasional, setLoadDataBebanOperasional] =
    useState(false);
  const [loadDataPendapatanLainnya, setLoadDataPendapatanLainnya] =
    useState(false);
  const [loadDataBebanLainnya, setLoadDataBebanLainnya] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);

  const fetchData = async () => {
    setload(true);
    try {
      let query = {
        tahun: tahun ? tahun : tahunSobatPajak,
      };
      let res = await service.getAllLabaRugiKomersial(query);
      setDataPenjualan(
        res.data.kategoriAkunSaldoList.filter((item) =>
          item.kategoriAkun.kode.slice(0, 1).includes("4")
        )
      );
      setDataHPP(
        res.data.kategoriAkunSaldoList.filter((item) =>
          item.kategoriAkun.kode.slice(0, 1).includes("5")
        )
      );
      setDataBebanOperasional(
        res.data.kategoriAkunSaldoList.filter((item) =>
          item.kategoriAkun.kode.slice(0, 1).includes("6")
        )
      );
      setDataPendapatanLainnya(
        res.data.kategoriAkunSaldoList.filter((item) =>
          item.kategoriAkun.kode.slice(0, 1).includes("7")
        )
      );
      setDataBebanLainnya(
        res.data.kategoriAkunSaldoList.filter((item) =>
          item.kategoriAkun.kode.slice(0, 1).includes("8")
        )
      );

      setData(res.data);
      setload(false);
    } catch (error) {
      toast.errorRequest(error);
      setload(false);
    }
  };
  const activePanelPenjualan = async () => {
    setPanelPenjualan(!panelPenjualan);
    if (!panelPenjualan) {
      try {
        setLoadDataPenjualan(true);
        let listKategoriAkunId = [];
        dataPenjualan.forEach((data) => {
          listKategoriAkunId.push(data.kategoriAkun.kategori);
        });
        let query = {
          tahun: tahun ? tahun : sessionStorage.getItem("tahunSobatBuku"),
          kategori: listKategoriAkunId.toString(),
        };
        let res = await service.getAllLabaRugiKomersialShowDetail(query);
        setDataDetail({ ...dataDetail, dataPenjualan: res.data });
        setLoadDataPenjualan(false);
      } catch (error) {
        toast.errorRequest(error);
        setLoadDataPenjualan(false);
      }
    }
  };
  const activePanelHPP = async () => {
    setPanelHPP(!panelHPP);
    if (!panelHPP) {
      try {
        setLoadDataHPP(true);
        let listKategoriAkunId = [];
        dataHPP.forEach((data) => {
          listKategoriAkunId.push(data.kategoriAkun.kategori);
        });
        let query = {
          tahun: tahun ? tahun : sessionStorage.getItem("tahunSobatBuku"),
          kategori: listKategoriAkunId.toString(),
        };
        let res = await service.getAllLabaRugiKomersialShowDetail(query);
        setDataDetail({ ...dataDetail, dataHPP: res.data });
        setLoadDataHPP(false);
      } catch (error) {
        toast.errorRequest(error);
        setLoadDataHPP(false);
      }
    }
  };
  const activePanelBebanOperasional = async () => {
    setPanelBebanOperasional(!panelBebanOperasional);
    if (!panelBebanOperasional) {
      try {
        setLoadDataBebanOperasional(true);
        let listKategoriAkunId = [];
        dataBebanOperasional.forEach((data) => {
          listKategoriAkunId.push(data.kategoriAkun.kategori);
        });
        let query = {
          tahun: tahun ? tahun : sessionStorage.getItem("tahunSobatBuku"),
          kategori: listKategoriAkunId.toString(),
        };
        let res = await service.getAllLabaRugiKomersialShowDetail(query);
        setDataDetail({ ...dataDetail, dataBebanOperasional: res.data });
        setLoadDataBebanOperasional(false);
      } catch (error) {
        toast.errorRequest(error);
        setLoadDataBebanOperasional(false);
      }
    }
  };
  const activePanelPendapatanLainnya = async () => {
    setPanelPendapatanLainnya(!panelPendapatanLainnya);
    if (!panelPendapatanLainnya) {
      try {
        setLoadDataPendapatanLainnya(true);
        let listKategoriAkunId = [];
        dataPendapatanLainnya.forEach((data) => {
          listKategoriAkunId.push(data.kategoriAkun.kategori);
        });
        let query = {
          tahun: tahun ? tahun : sessionStorage.getItem("tahunSobatBuku"),
          kategori: listKategoriAkunId.toString(),
        };

        let res = await service.getAllLabaRugiKomersialShowDetail(query);
        setDataDetail({ ...dataDetail, dataPendapatanLainnya: res.data });
        setLoadDataPendapatanLainnya(false);
      } catch (error) {
        toast.errorRequest(error);
        setLoadDataPendapatanLainnya(false);
      }
    }
  };
  const activePanelBebanLainnya = async () => {
    setPanelBebanLainnya(!panelBebanLainnya);
    if (!panelBebanLainnya) {
      try {
        setLoadDataBebanLainnya(true);
        let listKategoriAkunId = [];
        dataBebanLainnya.forEach((data) => {
          listKategoriAkunId.push(data.kategoriAkun.kategori);
        });
        let query = {
          tahun: tahun ? tahun : sessionStorage.getItem("tahunSobatBuku"),
          kategori: listKategoriAkunId.toString(),
        };

        let res = await service.getAllLabaRugiKomersialShowDetail(query);
        setDataDetail({ ...dataDetail, dataBebanLainnya: res.data });
        setLoadDataBebanLainnya(false);
      } catch (error) {
        toast.errorRequest(error);
        setLoadDataBebanLainnya(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  async function DownloadLaporanLabaRugi() {
    try {
      setLoadingDownload(true);
      await service.DownloadLaporanLabaRugi(tahun ? tahun : tahunSobatPajak);
      setLoadingDownload(false);
    } catch (e) {
      toast.errorRequest(e);
      setLoadingDownload(false);
    }
  }
  return (
    <>
      <>
        <FormWrapper
          loading={load}
          showBackTo={datass == true ? true : false}
          style={{
            maxWidth: "100%",
          }}
          title={t.translate(`modules.report.labaRugiKomersial`)}
          showCommandbar={datass == true ? true : false}
          baseId="mod-table-laporan-laba-rugi-komersial"
          definitions={[
            {
              render: (
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <div style={{ float: "right" }}>
                    {" "}
                    <AsyncButton
                      themeType="flat"
                      loading={loadingDownload}
                      labelButton={"Download Laporan"}
                      showIcon={true}
                      onClickDownload={() => {
                        DownloadLaporanLabaRugi();
                      }}
                    />
                  </div>
                </div>
              ),
            },
            {
              render: (
                <>
                  {dataPenjualan && (
                    <ReportLabaRugiKomersialExpansion
                      panel={panelPenjualan}
                      onClick={() => activePanelPenjualan()}
                      data={dataPenjualan}
                      label={dataPenjualan[0]?.kategoriAkun.nama}
                      value={Math.abs(
                        dataPenjualan[0]?.saldoAkhir -
                          dataPenjualan[1]?.saldoAkhir
                      )}
                      children={
                        loadDataPenjualan ? (
                          <LoaderInfo
                            children={"Loading . . . "}
                            style={{ justifyContent: "center" }}
                          />
                        ) : (
                          <List>
                            {dataDetail?.dataPenjualan?.map((d, index) => {
                              return (
                                <ListItem
                                  style={{ marginLeft: 35 }}
                                  onClick={() => {
                                    history.push({
                                      pathname: `/internal/npwp/${npwpHeadId}/report-laba-rugi/${d.akun.id}`,
                                      state: { tahun: tahun },
                                    });
                                    sessionStorage.setItem(
                                      "namaAkun",
                                      d.akun.nama
                                    );
                                    sessionStorage.setItem(
                                      "kodeAkun",
                                      d.akun.kode
                                    );
                                    sessionStorage.setItem(
                                      "saldoAwallabaRugi",
                                      d.saldoAwal
                                    );
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      width: "100%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "15%",
                                      }}
                                    >
                                      <Text>{d.akun.kode}</Text>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "50%",
                                      }}
                                    >
                                      <Text>{d.akun.nama}</Text>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "30%",
                                      }}
                                    >
                                      <Text>{formatRupiah(d.saldoAkhir)}</Text>
                                    </div>

                                    <Button
                                      theme="primary"
                                      onClick={() => {
                                        history.push({
                                          pathname: `/internal/npwp/${npwpHeadId}/report-laba-rugi/${d.akun.id}`,
                                          state: { tahun: tahun },
                                        });
                                        sessionStorage.setItem(
                                          "namaAkun",
                                          d.akun.nama
                                        );
                                        sessionStorage.setItem(
                                          "kodeAkun",
                                          d.akun.kode
                                        );
                                        sessionStorage.setItem(
                                          "saldoAwallabaRugi",
                                          d.saldoAwal
                                        );
                                      }}
                                    >
                                      <FontIcon iconClassName="mdi mdi-import" />
                                    </Button>
                                  </div>
                                  <Divider />
                                </ListItem>
                              );
                            })}
                          </List>
                        )
                      }
                    />
                  )}
                  {dataHPP && (
                    <ReportLabaRugiKomersialExpansion
                      panel={panelHPP}
                      onClick={() => activePanelHPP()}
                      label={dataHPP[0]?.kategoriAkun.nama}
                      value={Math.abs(
                        dataHPP[0]?.saldoAkhir - dataHPP[1]?.saldoAkhir
                      )}
                      children={
                        loadDataHPP ? (
                          <LoaderInfo
                            children={"Loading . . . "}
                            style={{ justifyContent: "center" }}
                          />
                        ) : (
                          <List>
                            {dataDetail?.dataHPP?.map((d, index) => {
                              return (
                                <ListItem
                                  style={{ marginLeft: 35 }}
                                  onClick={() => {
                                    history.push({
                                      pathname: `/internal/npwp/${npwpHeadId}/report-laba-rugi/${d.akun.id}`,
                                      state: { tahun: tahun },
                                    });
                                    sessionStorage.setItem(
                                      "namaAkun",
                                      d.akun.nama
                                    );
                                    sessionStorage.setItem(
                                      "kodeAkun",
                                      d.akun.kode
                                    );
                                    sessionStorage.setItem(
                                      "saldoAwallabaRugi",
                                      d.saldoAwal
                                    );
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      width: "100%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "15%",
                                      }}
                                    >
                                      <Text>{d.akun.kode}</Text>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "50%",
                                      }}
                                    >
                                      <Text>{d.akun.nama}</Text>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "30%",
                                      }}
                                    >
                                      <Text>{formatRupiah(d.saldoAkhir)}</Text>
                                    </div>

                                    <Button
                                      theme="primary"
                                      onClick={() => {
                                        history.push({
                                          pathname: `/internal/npwp/${npwpHeadId}/report-laba-rugi/${d.akun.id}`,
                                          state: { tahun: tahun },
                                        });
                                        sessionStorage.setItem(
                                          "namaAkun",
                                          d.akun.nama
                                        );
                                        sessionStorage.setItem(
                                          "kodeAkun",
                                          d.akun.kode
                                        );
                                        sessionStorage.setItem(
                                          "saldoAwallabaRugi",
                                          d.saldoAwal
                                        );
                                      }}
                                    >
                                      <FontIcon iconClassName="mdi mdi-import" />
                                    </Button>
                                  </div>
                                  <Divider />
                                </ListItem>
                              );
                            })}
                          </List>
                        )
                      }
                    />
                  )}
                  <Card
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      padding: 15,
                      backgroundColor: "#d8b9ff",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        fontWeight: "bold",
                      }}
                    >
                      Laba Kotor
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        fontWeight: "bold",
                      }}
                    >
                      {formatRupiah(data?.labaKotor)}
                    </div>
                  </Card>
                  {dataBebanOperasional && (
                    <ReportLabaRugiKomersialExpansion
                      panel={panelBebanOperasional}
                      onClick={() => activePanelBebanOperasional()}
                      data={dataBebanOperasional}
                      label={dataBebanOperasional[0]?.kategoriAkun.nama}
                      value={Math.abs(dataBebanOperasional[0]?.saldoAkhir)}
                      children={
                        loadDataBebanOperasional ? (
                          <LoaderInfo
                            children={"Loading . . . "}
                            style={{ justifyContent: "center" }}
                          />
                        ) : (
                          <List>
                            {dataDetail?.dataBebanOperasional?.map(
                              (d, index) => {
                                return (
                                  <ListItem
                                    style={{ marginLeft: 35 }}
                                    onClick={() => {
                                      history.push({
                                        pathname: `/internal/npwp/${npwpHeadId}/report-laba-rugi/${d.akun.id}`,
                                        state: { tahun: tahun },
                                      });
                                      sessionStorage.setItem(
                                        "namaAkun",
                                        d.akun.nama
                                      );
                                      sessionStorage.setItem(
                                        "kodeAkun",
                                        d.akun.kode
                                      );
                                      sessionStorage.setItem(
                                        "saldoAwallabaRugi",
                                        d.saldoAwal
                                      );
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        width: "100%",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          width: "15%",
                                        }}
                                      >
                                        <Text>{d.akun.kode}</Text>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          width: "50%",
                                        }}
                                      >
                                        <Text>{d.akun.nama}</Text>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          width: "30%",
                                        }}
                                      >
                                        <Text>
                                          {formatRupiah(d.saldoAkhir)}
                                        </Text>
                                      </div>

                                      <Button
                                        theme="primary"
                                        onClick={() => {
                                          history.push({
                                            pathname: `/internal/npwp/${npwpHeadId}/report-laba-rugi/${d.akun.id}`,
                                            state: { tahun: tahun },
                                          });
                                          sessionStorage.setItem(
                                            "namaAkun",
                                            d.akun.nama
                                          );
                                          sessionStorage.setItem(
                                            "kodeAkun",
                                            d.akun.kode
                                          );
                                          sessionStorage.setItem(
                                            "saldoAwallabaRugi",
                                            d.saldoAwal
                                          );
                                        }}
                                      >
                                        <FontIcon iconClassName="mdi mdi-import" />
                                      </Button>
                                    </div>
                                    <Divider />
                                  </ListItem>
                                );
                              }
                            )}
                          </List>
                        )
                      }
                    />
                  )}
                  <Card
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      padding: 15,
                      backgroundColor: "#d8b9ff",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        fontWeight: "bold",
                      }}
                    >
                      Laba Usaha
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        fontWeight: "bold",
                      }}
                    >
                      {formatRupiah(data?.labaUsaha)}
                    </div>
                  </Card>
                  {dataPendapatanLainnya && (
                    <ReportLabaRugiKomersialExpansion
                      panel={panelPendapatanLainnya}
                      onClick={() => activePanelPendapatanLainnya()}
                      data={dataPendapatanLainnya}
                      label={dataPendapatanLainnya[0]?.kategoriAkun.nama}
                      value={Math.abs(dataPendapatanLainnya[0]?.saldoAkhir)}
                      children={
                        loadDataPendapatanLainnya ? (
                          <LoaderInfo
                            children={"Loading . . . "}
                            style={{ justifyContent: "center" }}
                          />
                        ) : (
                          <List>
                            {dataDetail?.dataPendapatanLainnya?.map(
                              (d, index) => {
                                return (
                                  <ListItem
                                    style={{ marginLeft: 35 }}
                                    onClick={() => {
                                      history.push({
                                        pathname: `/internal/npwp/${npwpHeadId}/report-laba-rugi/${d.akun.id}`,
                                        state: { tahun: tahun },
                                      });
                                      sessionStorage.setItem(
                                        "namaAkun",
                                        d.akun.nama
                                      );
                                      sessionStorage.setItem(
                                        "kodeAkun",
                                        d.akun.kode
                                      );
                                      sessionStorage.setItem(
                                        "saldoAwallabaRugi",
                                        d.saldoAwal
                                      );
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        width: "100%",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          width: "15%",
                                        }}
                                      >
                                        <Text>{d.akun.kode}</Text>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          width: "50%",
                                        }}
                                      >
                                        <Text>{d.akun.nama}</Text>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          width: "30%",
                                        }}
                                      >
                                        <Text>
                                          {formatRupiah(d.saldoAkhir)}
                                        </Text>
                                      </div>

                                      <Button
                                        theme="primary"
                                        onClick={() => {
                                          history.push({
                                            pathname: `/internal/npwp/${npwpHeadId}/report-laba-rugi/${d.akun.id}`,
                                            state: { tahun: tahun },
                                          });
                                          sessionStorage.setItem(
                                            "namaAkun",
                                            d.akun.nama
                                          );
                                          sessionStorage.setItem(
                                            "kodeAkun",
                                            d.akun.kode
                                          );
                                          sessionStorage.setItem(
                                            "saldoAwallabaRugi",
                                            d.saldoAwal
                                          );
                                        }}
                                      >
                                        <FontIcon iconClassName="mdi mdi-import" />
                                      </Button>
                                    </div>
                                    <Divider />
                                  </ListItem>
                                );
                              }
                            )}
                          </List>
                        )
                      }
                    />
                  )}
                  {dataBebanLainnya && (
                    <ReportLabaRugiKomersialExpansion
                      panel={panelBebanLainnya}
                      onClick={() => activePanelBebanLainnya()}
                      data={dataBebanLainnya}
                      label={dataBebanLainnya[0]?.kategoriAkun.nama}
                      value={Math.abs(dataBebanLainnya[0]?.saldoAkhir)}
                      children={
                        loadDataBebanLainnya ? (
                          <LoaderInfo
                            children={"Loading . . . "}
                            style={{ justifyContent: "center" }}
                          />
                        ) : (
                          <List>
                            {dataDetail?.dataBebanLainnya?.map((d, index) => {
                              return (
                                <ListItem
                                  style={{ marginLeft: 35 }}
                                  onClick={() => {
                                    history.push({
                                      pathname: `/internal/npwp/${npwpHeadId}/report-laba-rugi/${d.akun.id}`,
                                      state: { tahun: tahun },
                                    });
                                    sessionStorage.setItem(
                                      "namaAkun",
                                      d.akun.nama
                                    );
                                    sessionStorage.setItem(
                                      "kodeAkun",
                                      d.akun.kode
                                    );
                                    sessionStorage.setItem(
                                      "saldoAwallabaRugi",
                                      d.saldoAwal
                                    );
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      width: "100%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "15%",
                                      }}
                                    >
                                      <Text>{d.akun.kode}</Text>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "50%",
                                      }}
                                    >
                                      <Text>{d.akun.nama}</Text>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "30%",
                                      }}
                                    >
                                      <Text>{formatRupiah(d.saldoAkhir)}</Text>
                                    </div>

                                    <Button
                                      theme="primary"
                                      onClick={() => {
                                        history.push({
                                          pathname: `/internal/npwp/${npwpHeadId}/report-laba-rugi/${d.akun.id}`,
                                          state: { tahun: tahun },
                                        });
                                        sessionStorage.setItem(
                                          "namaAkun",
                                          d.akun.nama
                                        );
                                        sessionStorage.setItem(
                                          "kodeAkun",
                                          d.akun.kode
                                        );
                                        sessionStorage.setItem(
                                          "saldoAwallabaRugi",
                                          d.saldoAwal
                                        );
                                      }}
                                    >
                                      <FontIcon iconClassName="mdi mdi-import" />
                                    </Button>
                                  </div>
                                  <Divider />
                                </ListItem>
                              );
                            })}
                          </List>
                        )
                      }
                    />
                  )}
                  <Card
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      padding: 15,
                      backgroundColor: "#d8b9ff",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        fontWeight: "bold",
                      }}
                    >
                      Laba Bersih
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        fontWeight: "bold",
                      }}
                    >
                      {formatRupiah(data?.labaBersih)}
                    </div>
                  </Card>
                </>
              ),
            },
          ]}
        />
      </>
    </>
  );
};

export default inject(
  "navigationStore",
  "envStore"
)(observer(ReportLabaRugiKomersial));
