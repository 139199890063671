import React, { useEffect, useState } from "react";
import { DashboardWrapper } from "../../libs/react-mpk/wrapper";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CircularProgress,
  FontIcon,
  Text,
} from "react-md";
import {
  Commandbar,
  DataForm,
  LoaderInfo,
} from "../../libs/react-mpk/components";
import { inputTypes } from "../../libs/react-mpk/config/constant";
import "moment/locale/id";
import { inject, observer } from "mobx-react";
import service from "./DashboardHomePage.service";
import { useHistory } from "react-router";
import { formatRupiah } from "../../libs/react-mpk/services/number.service";

const DashboardHomePage = ({ navigationStore }) => {
  let history = useHistory();
  var npwpHeadId = sessionStorage.getItem("npwpHeadId");
  var tahunSobatBuku = sessionStorage.getItem("tahunSobatBuku");
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tahunQuery,setTahunQuery] = useState(tahunSobatBuku)
  const [item, setItem] = useState([]);
  const [ResponseData, setResponseData] = useState(null);
  const [optionTahun, setOptionTahun] = useState([]);
  const months =new Date().getMonth()+1
  const nextMonths =new Date().getMonth()+2
  let [search, setSearch] = useState({
    masaAwal: months.toString(),
    masaAkhir: nextMonths.toString(),
    tahun: tahunSobatBuku || new Date().getFullYear(),
  });
  const bulan = [
    { value: 1, label: `Januari` },
    { value: 2, label: `Februari` },
    { value: 3, label: `Maret` },
    { value: 4, label: `April` },
    { value: 5, label: `Mei` },
    { value: 6, label: `Juni` },
    { value: 7, label: `Juli` },
    { value: 8, label: `Agustus` },
    { value: 9, label: `September` },
    { value: 10, label: `Oktober` },
    { value: 11, label: `November` },
    { value: 12, label: `Desember` },
  ];
  let query = {
    ...search,
    npwpHeadId: npwpHeadId,
  };
  const getOptionTahun = async () => {
    let listYears = [];
    let currentYear = new Date().getFullYear();

    if (tahunSobatBuku) {
      setSearch({ ...search, tahun: tahunSobatBuku });
      setTahunQuery(tahunSobatBuku)
    } else {
      sessionStorage.setItem("tahunSobatBuku", currentYear);
    }
    listYears.push(currentYear.toString());
    for (let i = 1; i < 5; i++) {
      listYears.push((currentYear - i).toString());
    }
    setOptionTahun(listYears);
  };
  useEffect(() => {
    async function initData() {
      if (showDialog) {
        getOptionTahun();
      }else{
        fetchData()
      }
    }
    initData();
  }, [showDialog]);
useEffect(() => {
  
}, [])



  const fetchData = () => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true);
        let res = await service.getDashboard(query);
        let arrayData = [];
        arrayData.push({ nama: "Penjualan", data: res.data.penjualan });
        arrayData.push({ nama: "Pembelian", data: res.data.pembelian });
        arrayData.push({ nama: "Biaya", data: res.data.biaya });
        arrayData.push({ nama: "Laba Rugi", data: res.data.labaRugi });
        setItem(arrayData);
        setResponseData(res)
        resolve(res);
        setLoading(false);
      } catch (error) {
        reject(error);
        setLoading(false);
      }
    });
  };

  return (
    <div style={{ width: "100%" }}>
      <Commandbar
        title={"Dashboard"}
        rightCorner={[
          <Button
            onClick={() => {
              setShowDialog(true);
            }}
          >
            <FontIcon iconClassName="mdi mdi-filter" style={{ fontSize: 30 }} />
          </Button>,
          <Button
            onClick={() => {
              fetchData();
            }}
          >
            <FontIcon iconClassName="mdi mdi-reload" style={{ fontSize: 30 }} />
          </Button>,
        ]}
      />
      {loading ? (<LoaderInfo children={<Text>mengambil data..</Text>} />) 
      : (
        item.map((d) => {
          return (
            <Card
              raiseable
              style={{ width: "49%", margin: "0.5%", height: "30vh" }}
              bordered
            >
              <CardHeader
                style={{ backgroundColor: "#b46ad3", color: "white" }}
              >
                <CardTitle>
                  {d.nama}
                  <Button
                    onClick={() => {
                      history.push({
                        pathname:  d.nama == "Penjualan"
                        ? `/internal/npwp/${npwpHeadId}/penjualan`
                        : d.nama == "Pembelian"
                        ? `/internal/npwp/${npwpHeadId}/pembelian`
                        : d.nama == "Biaya"
                        ? `/internal/npwp/${npwpHeadId}/biaya`
                        : `/internal/npwp/${npwpHeadId}/report/labaRugiKomersial`,
                        state: { tahun: tahunQuery },
                      });
                    }}
                    style={{
                      float: "right",
                      color: "#b46ad3",
                      backgroundColor: "white",
                    }}
                  >
                    Lihat Rincian
                  </Button>
                </CardTitle>
              </CardHeader>
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {d.nama == "Penjualan" && <Text>Sudah Diterima</Text>}
                    {d.nama == "Penjualan" && <Text>Belum Diterima</Text>}
                    {d.nama == "Pembelian" && <Text>Sudah Dibayar</Text>}
                    {d.nama == "Pembelian" && <Text>Belum Dibayar</Text>}
                    {d.nama == "Biaya" && <Text>Total</Text>}
                    {d.nama == "Laba Rugi" && <Text>Laba Bersih</Text>}
                    {d.nama == "Laba Rugi" && <Text>Laba Usaha</Text>}
                    {d.nama == "Laba Rugi" && <Text>Laba Kotor</Text>}
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {d.nama == "Penjualan" && (
                      <Text>{formatRupiah(d.data.sudahDiterima)}</Text>
                    )}
                    {d.nama == "Penjualan" && (
                      <Text>{formatRupiah(d.data.belumDiterima)}</Text>
                    )}
                    {d.nama == "Pembelian" && (
                      <Text>{formatRupiah(d.data.sudahDibayar)}</Text>
                    )}
                    {d.nama == "Pembelian" && (
                      <Text>{formatRupiah(d.data.belumDibayar)}</Text>
                    )}
                    {d.nama == "Biaya" && (
                      <Text>{formatRupiah(d.data.total)}</Text>
                    )}
                    {d.nama == "Laba Rugi" && (
                      <Text>{formatRupiah(d.data.labaBersih)}</Text>
                    )}
                    {d.nama == "Laba Rugi" && (
                      <Text>{formatRupiah(d.data.labaUsaha)}</Text>
                    )}
                    {d.nama == "Laba Rugi" && (
                      <Text>{formatRupiah(d.data.labaKotor)}</Text>
                    )}
                  </div>
                </div>
              </CardContent>
            </Card>
          );
        })
      )}

      {!loading &&
        <DashboardWrapper
        baseId="dashboard-id"
        title="Dashboard"
        className="mpk-full full-height full-width"
        usePeriod={false}
        useFilter={false}
        showCommandbar={false}
        definitions={[
          new DashboardWrapper.definition(
            null,
            [],
            [
              new DashboardWrapper.chart(
                "Laba Rugi ( Tahun Berjalan )",
                () =>  Promise.resolve(ResponseData),
                (data) =>
                  data.chartLabaRugiTahunBerjalan.map(
                    (d) =>
                      new DashboardWrapper.chartData({}, d.key, d.value)
                    
                  ),
                { type: "bar" },
                {}
              ),
              new DashboardWrapper.chart(
                "Peredaran Bruto ( Tahun Berjalan )",
                () =>  Promise.resolve(ResponseData),
                (data) =>
                  data.chartPredaranBruto.map(
                    (d) =>
                      new DashboardWrapper.chartData(d.category, d.key, d.value)
                  ),
                { type: "bar" },
                {}
              ),
              new DashboardWrapper.chart(
                "Biaya ( Tahun Berjalan )",
                () =>  Promise.resolve(ResponseData),
                (data) =>
                  data.chartBiayaTahunBerjalan.map(
                    (d) =>
                      new DashboardWrapper.chartData(d.category, d.key, d.value)
                  ),
                { type: "bar" },
                {}
              ),
            ]
          ),
        ]}
      />
      
      
    } 

      

      <DataForm
        visible={showDialog}
        defaultData={search}
        onRequestClose={() => setShowDialog(false)}
        asDialog={true}
        showCommandbar={false}
        baseId={"filter-dashboard-id"}
        submitLabel={"Cari"}
        submitIconClassName={null}
        definitions={[
          {
            inputType: inputTypes.SELECT,
            label: `Dari Bulan`,
            key: "masaAwal",
            type: "text",
            validation: "required",
            options: bulan,
          },
          {
            inputType: inputTypes.SELECT,
            label: `Sampai Bulan`,
            key: "masaAkhir",
            type: "text",
            validation: "required",
            options: bulan,
          },
          {
            inputType: inputTypes.SELECT,
            label: `Tahun`,
            key: "tahun",
            type: "text",
            validation: "required",
            options: optionTahun,
          },
        ]}
        onChange={async (formData, key, value) => {
          return formData;
        }}
        onSubmit={async (data, callback) => {
          setSearch(data);
          setTahunQuery(data.tahun)
          callback();
        }}
      />
    </div>
  );
};

export default inject(
  "envStore",
  "navigationStore"
)(observer(DashboardHomePage));
