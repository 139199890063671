import React, { useState, useEffect } from 'react';
import t from 'counterpart'
import { useParams } from 'react-router';
import { FormWrapper } from '../../libs/react-mpk/wrapper';
// import service from '../KasDanBank.service';

const KasDanBankDetailInputDetailAkunPenjualan = (props) => {
	const { inputTypes, definition } = FormWrapper
    const [dataModal, setdata] = useState(null)
	const {id} = useParams()
    // const fetchData = async() =>{
    //     console.log(id);
    //     try {
    //       let res = await service.getById(id)
    //       setdata(res.data)
    //       console.log(res.data);
	// 	  console.log(dataModal);
    //     } catch (error) {
    //       console.log(error);
    //     }
    // }
    // useEffect(() => {
    //     fetchData()
    // },[])
	return(
        <>
		<FormWrapper
			baseId="kas-dan-bank-detail-input-detail-akun-penjualan"
			title={t.translate(`modules.KasDanBank.detailInputDetailAkunPenjualan`)}
            backTo='/internal/kas-dan-bank-detail-penjualan/:id'
            submitLabel={t.translate(`words.submit`)}
			defaultData={{
                nilaiPenjualan: "30200000",
                namaAkun: "Penjualan",
                jenisPenghasilan: "Bagian Laba Anggota Perseorangan",
                transaksiTerutangPajak: "Ya",
                apakahSobatMenyetorkanSendiri: "Ya",
                pemotonganPajak: "PPh 4(2)",
                atasTransaksi: "P0001 Omset",
                tarifPajak:"0,5",
                perhitunganPajakBerdasarkan:"Nilai Penjualan",
                nominalPerhitunganPajak:"30200000",
                nominalPajak:"151000",
                total:"30200000",
                keterangan:"Test"
            }}
			showCommandbar={true}
			definitions={[
				{
					inputType: FormWrapper.inputTypes.DIVIDER,
					label: t.translate(`modules.KasDanBank.detailInputDetailAkunPenjualan`),
                },
				{
					inputType: FormWrapper.inputTypes.INPUT,
					label: t.translate(`words.namaAkun`),
					key: 'namaAkun',
                    width: '49%',
                    style: {
                        marginRight: "2%"
                    },
                    disabled:true
                },
				{
					inputType: FormWrapper.inputTypes.INPUT,
					label: t.translate(`words.nilaiPenjualan`),
					key: 'nilaiPenjualan',
                    width: '49%',
                    disabled:true

                },
                {
                    render: (
                        <div style={{ width: '100%'}}>
                            <p style={{margin: "10px"}}><b>{t.translate(`words.jenisPenghasilan`)}</b></p>
                        </div>
                    )
                },
				{
					inputType: FormWrapper.inputTypes.INPUT,
					label: t.translate(`words.jenisPenghasilan`),
					key: 'jenisPenghasilan',
                    width: '49%',
                    style: {
                        marginRight: "2%"
                    },
                    disabled:true

                },
				{
					inputType: FormWrapper.inputTypes.INPUT,
					label: t.translate(`words.transaksiTerutangPajak`),
					key: 'transaksiTerutangPajak',
                    width: '49%',
                    disabled:true
                },
                {
                    render: (
                        <div style={{ width: '100%'}}>
                            <p style={{margin: "10px"}}><b>{t.translate(`words.pajakDisetorSendiri`)}</b></p>
                        </div>
                    )
                },
				{
					inputType: FormWrapper.inputTypes.INPUT,
					label: t.translate(`words.apakahSobatMenyetorkanSendiri`),
					key: 'apakahSobatMenyetorkanSendiri',
                    disabled:true
                },
				{
					inputType: FormWrapper.inputTypes.INPUT,
					label: t.translate(`words.pemotonganPajak`),
					key: 'pemotonganPajak',
                    disabled:true
                },
				{
					inputType: FormWrapper.inputTypes.INPUT,
					label: t.translate(`words.atasTransaksi`),
					key: 'atasTransaksi',
                    width: '49%',
                    style: {
                        marginRight: "2%"
                    },
                    disabled:true
                },
                {
					inputType: FormWrapper.inputTypes.INPUT,
					label: t.translate(`words.tarifPajak`),
					key: 'tarifPajak',
                    disabled:true,
                    width: '49%',
                },
                {
					inputType: FormWrapper.inputTypes.INPUT,
					label: t.translate(`words.perhitunganPajakBerdasarkan`),
					key: 'perhitunganPajakBerdasarkan',
                    disabled:true,
                    width: '49%',
                    style: {
                        marginRight: "2%"
                    },
                },
                {
					inputType: FormWrapper.inputTypes.INPUT,
					label: t.translate(`words.nominalPerhitunganPajak`),
					key: 'nominalPerhitunganPajak',
                    width: '49%',
                    disabled:true
                },
                {
					inputType: FormWrapper.inputTypes.INPUT,
					label: t.translate(`words.nominalPajak`),
					key: 'nominalPajak',
                    width: '49%',
                    disabled:true,
                    style: {
                        marginRight: "2%"
                    },
                },
                {
					inputType: FormWrapper.inputTypes.INPUT,
					label: t.translate(`words.total`),
					key: 'total',
                    width: '49%',
                    disabled:true
                },
                {
                    render: (
                        <div style={{ width: '100%'}}>
                            <p style={{margin: "10px"}}><b>{t.translate(`words.informasiTambahan`)}</b></p>
                        </div>
                    )
                },
                {
					inputType: FormWrapper.inputTypes.INPUT,
					label: t.translate(`words.keterangan`),
					key: 'keterangan',
                    disabled:true
                },
			]}
		/>
        </>
	)
}

export default KasDanBankDetailInputDetailAkunPenjualan