import React, { useState, useEffect } from 'react';
import t from 'counterpart'
import { Table, TableBody, TableHeader, TableRow, TableCell, Button, FontIcon, TableFooter } from 'react-md'
import { useParams } from 'react-router';
import { FormWrapper } from '../../libs/react-mpk/wrapper';
// import service from '../KasDanBank.service';

const KasDanBankDetailInputDetailProduk = (props) => {
	const { inputTypes, definition } = FormWrapper
    const [dataModal, setdata] = useState(null)
	const {id} = useParams()
    // const fetchData = async() =>{
    //     console.log(id);
    //     try {
    //       let res = await service.getById(id)
    //       setdata(res.data)
    //       console.log(res.data);
	// 	  console.log(dataModal);
    //     } catch (error) {
    //       console.log(error);
    //     }
    // }
    // useEffect(() => {
    //     fetchData()
    // },[])
	return(
        <>
		<FormWrapper
			baseId="kas-dan-bank-detail-input-detail-produk"
			title={t.translate(`modules.KasDanBank.detailInputDetailProduk`)}
            backTo='/internal/kas-dan-bank-detail-penjualan/:id'
            submitLabel={t.translate(`words.submit`)}
			defaultData={{
                kode: "6.1.109",
                nama: "Beban Jasa",
                jumlahBarang: "10",
                nominal: "10000000",
                dpp: "10000000",
                ppn: "10000000",
                ppnBm: "10000000",
                hargaSatuan:"3500000",
                hargaTotal:"35000000",
                diskon:"5000000",
                subtotal:"30000000",
                apakahPpnBm:"Ya",
                tarifPpnBm:"10%"
            }}
			showCommandbar={true}
			definitions={[
				{
					inputType: FormWrapper.inputTypes.DIVIDER,
					label: t.translate(`modules.KasDanBank.detailInputDetailProduk`),
                },
				{
					inputType: FormWrapper.inputTypes.INPUT,
					label: t.translate(`words.kode`),
					key: 'kode',
                    width: '49%',
                    style: {
                        marginRight: "2%"
                    },
                    disabled:true
                },
				{
					inputType: FormWrapper.inputTypes.INPUT,
					label: t.translate(`words.nama`),
					key: 'nama',
                    width: '49%',
                    disabled:true

                },
				{
					inputType: FormWrapper.inputTypes.INPUT,
					label: t.translate(`words.hargaSatuan`),
					key: 'hargaSatuan',
                    width: '49%',
                    style: {
                        marginRight: "2%"
                    },
                    disabled:true

                },
				{
					inputType: FormWrapper.inputTypes.INPUT,
					label: t.translate(`words.jumlahBarang`),
					key: 'jumlahBarang',
                    width: '49%',
                    disabled:true
                },
				{
					inputType: FormWrapper.inputTypes.INPUT,
					label: t.translate(`words.hargaTotal`),
					key: 'hargaTotal',
                    width: '49%',
                    style: {
                        marginRight: "2%"
                    },
                    disabled:true
                },
				{
					inputType: FormWrapper.inputTypes.INPUT,
					label: t.translate(`words.diskon`),
					key: 'diskon',
                    width: '49%',
                    disabled:true
                },
				{
					inputType: FormWrapper.inputTypes.INPUT,
					label: t.translate(`words.subtotal`),
					key: 'subtotal',
                    width: '49%',
                    style: {
                        marginRight: "2%"
                    },
                    disabled:true
                },
				{
					inputType: FormWrapper.inputTypes.INPUT,
					label: t.translate(`words.dpp`),
					key: 'dpp',
                    width: '49%',
                    disabled:true
                },
                {
					inputType: FormWrapper.inputTypes.INPUT,
					label: t.translate(`words.ppn`),
					key: 'ppn',
                    disabled:true
                },
                {
					inputType: FormWrapper.inputTypes.INPUT,
					label: t.translate(`words.apakahPpnBm`),
					key: 'apakahPpnBm',
                    disabled:true
                },
                {
					inputType: FormWrapper.inputTypes.INPUT,
					label: t.translate(`words.tarifPpnBm`),
					key: 'tarifPpnBm',
                    width: '49%',
                    style: {
                        marginRight: "2%"
                    },
                    disabled:true
                },
                {
					inputType: FormWrapper.inputTypes.INPUT,
					label: t.translate(`words.ppnBm`),
					key: 'ppnBm',
                    width: '49%',
                    disabled:true
                },
			]}
		/>
        </>
	)
}

export default KasDanBankDetailInputDetailProduk