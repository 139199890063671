import React, { useState } from "react";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import { inputTypes } from "../../libs/react-mpk/config/constant";
import t from "counterpart";
import service from "./Penjualan.service";
import moment from "moment";
import "moment/locale/id";
import errorService from "../../services/errorService";
import { inject, observer } from "mobx-react";
import {
  format,
  formatRupiah,
} from "../../libs/react-mpk/services/number.service";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogContent,
  DialogTitle,
  DialogFooter,
  Text,
} from "react-md";
import { LoaderInfo } from "../../libs/react-mpk/components";
import { toast } from "../../libs/react-mpk/services";
import utilsService from "../../services/utilsService";
import iziToast from "izitoast";
import PenjualanTerimaPembayaran from "./PenjualanTerimaPembayaran";
import { useLocation } from "react-router";
import PreviewFile from "../PreviewFile/PreviewFile";

const STAT_BELUM_BAYAR = 1;
const STAT_BAYAR_SEBAGIAN = 2;
const STAT_LUNAS = 3;
const STAT_BATAL = 4;

const Penjualan = ({
  className = "",
  history,
  showCommandbar = true,
  navigationStore,
  match,
}) => {
  const { state: { tahun } = {} } = useLocation();
  const [showSelectCreate, setShowSelectCreate] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [showSendEmail, setShowSendEmail] = useState(false);
  const [showBatalkan, setShowBatalkan] = useState(false);
  const [showDownloadInvoiceBulk, setShowDownloadInvoiceBulk] = useState(false);
  const [showBayar, setShowBayar] = useState(false);
  const [dataBayar, setDataBayar] = useState(null);
  const [showUploadFakturPajak, setShowUploadFakturPajak] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showDialogPreviewInvoice, setShowDialogPreviewInvoice] =
    useState(false);
  const [search, setSearch] = useState({});
  const [data, setData] = useState([]);
  let [file, setFile] = useState(null);
  let [type, setType] = useState("pdf");
  let [itemInvoice, setItemInvoice] = useState(null);
  const baseId = `table-penjualan`;
  const basePath = `penjualan`;

  var npwpHeadId = match.params.npwpHeadId;
  var tahunSobatBuku = sessionStorage.getItem("tahunSobatBuku");
  var dataAkun = JSON.parse(sessionStorage.getItem("dataPenggunaNpwp"));

  function dialogBatalkan(item) {
    setSelectedItem(item);
    setShowBatalkan(true);
  }

  async function getNSFP() {
    try {
      let res = await service.getNSFP(npwpHeadId, tahunSobatBuku);
      sessionStorage.setItem("nsfp", res.data);
    } catch (error) {
      iziToast.error({
        message: error.message,
      });
      navigationStore.redirectTo(`/internal/npwp/${npwpHeadId}/penjualan`);
    }
  }

  async function downloadInvoice(id) {
    try {
      await service.downloadInvoice(id);
    } catch (e) {
      toast.errorRequest(e);
    }
  }

  async function downloadInvoiceBulk() {
    let selected = JSON.parse(TableWrapper.getSelected(baseId));
    let selectedData = [];
    selected.map((d) => selectedData.push(data[d]));
    let selectedDataId = selectedData.map((d) => d.id);
    // console.log(selectedData.length, "selectedData.length");
    if (selectedData.length <= 0) {
      alert("Silahkan pilih penjualan");
    } else {
      try {
        await service.downloadInvoiceBulk(selectedDataId);
      } catch (e) {
        toast.errorRequest(e);
      }
    }
    // console.log(selected, "selected");
    // console.log(selectedData, "selectedData");
    // console.log(selectedDataId, "selectedDataId");
  }

  var actions = [
    new TableWrapper.action(
      dataAkun.pkp == true ? "Penjualan PPN" : "Penjualan Non PPN",
      "mdi mdi-plus",
      () => {
        navigationStore.redirectTo(
          `/internal/npwp/${npwpHeadId}/penjualan/new/non-ppn`
        );
      },
      true
    ),
    new TableWrapper.action(
      "Download Invoice Bulk",
      "mdi mdi-download",
      () => {
        downloadInvoiceBulk();
      },
      true
    ),
  ];

  return (
    <>
      <TableWrapper
        baseId={baseId}
        title={t.translate("modules.penjualan.title")}
        className={className}
        hintMessage={t.translate("modules.penjualan.hint.message")}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate("modules.penjualan.hint.more")}
        defaultData={[]}
        backTo={tahun && `/internal/npwp/${npwpHeadId}/dashboard`}
        defaultSortBy="tanggalDibuat"
        showFilter={false}
        showActionColumn={true}
        useFilter={true}
        defaultCollapse={true}
        useCriteria={true}
        selectable={true}
        useQueryHistory={false}
        columns={[
          {
            label: "Nomor Invoice",
            searchable: true,
            sortable: false,
            key: "noInvoice",
            render: (item) => utilsService.beautifyNoTransaksi(item.noInvoice),
          },
          {
            label: "Nama",
            searchable: true,
            sortable: false,
            key: "namaLawanTransaksi",
            render: (item) => item.lawanTransaksi.nama,
          },
          {
            label: "Nominal",
            searchable: false,
            sortable: false,
            key: "nominal",
            render: (item) => formatRupiah(item.total),
          },
          {
            label: "Sisa Tagihan",
            searchable: false,
            sortable: false,
            key: "nominal",
            render: (item) =>
              item.pembayaran.sisaBayar !== 0
                ? formatRupiah(item.pembayaran.sisaBayar)
                : "-",
          },
          {
            label: "Tanggal Transaksi",
            type: TableWrapper.dataTypes.DATE,
            render: (item) =>
              moment(item.tanggalInvoice).locale("id").format("DD MMMM YYYY"),
          },
          {
            label: "Status Pembayaran",
            searchable: false,
            sortable: false,
            key: "statusPembayaran",
            render: (item) => (
              <TableWrapper.Status
                type={
                  item.pembayaran.status == STAT_LUNAS
                    ? TableWrapper.Status.types.SUCCESS
                    : item.pembayaran.status == STAT_BAYAR_SEBAGIAN
                    ? TableWrapper.Status.types.PROGRESS
                    : item.pembayaran.status == STAT_BELUM_BAYAR &&
                      item.tanggalJatuhTempo >
                        moment(new Date())
                          .locale("id")
                          .format("yyyy-MM-DD hh:mm:ss")
                    ? TableWrapper.Status.types.IDLE
                    : item.pembayaran.status == STAT_BELUM_BAYAR &&
                      item.tanggalJatuhTempo <
                        moment(new Date())
                          .locale("id")
                          .format("yyyy-MM-DD hh:mm:ss")
                    ? TableWrapper.Status.types.ERROR
                    : TableWrapper.Status.types.ERROR
                }
              >
                {item.pembayaran.status == STAT_BELUM_BAYAR &&
                item.tanggalJatuhTempo >
                  moment(new Date()).locale("id").format("yyyy-MM-DD hh:mm:ss")
                  ? "Belum Bayar"
                  : item.pembayaran.status == 2
                  ? "Bayar Sebagian"
                  : item.pembayaran.status == 3
                  ? "Lunas"
                  : item.pembayaran.status == STAT_BELUM_BAYAR &&
                    item.tanggalJatuhTempo <
                      moment(new Date())
                        .locale("id")
                        .format("yyyy-MM-DD hh:mm:ss")
                  ? "Jatuh Tempo"
                  : "Batal"}
              </TableWrapper.Status>
            ),
          },
          {
            label: "Tanggal Dibuat",
            type: TableWrapper.dataTypes.DATE,
            render: (item) =>
              moment(item.tanggalDibuat)
                .locale("id")
                .format("DD MMMM YYYY, HH:mm:ss"),
          },
        ]}
        actions={actions}
        itemActions={[
          new TableWrapper.action(
            "Terima Pembayaran",
            "mdi mdi-cash",
            (item) => {
              setShowBayar(true);
              setDataBayar(item);
            },
            (item) =>
              item.pembayaran.status == STAT_BELUM_BAYAR ||
              item.pembayaran.status == STAT_BAYAR_SEBAGIAN
                ? true
                : false
          ),
          new TableWrapper.action("Selengkapnya", "mdi mdi-import", (item) => {
            sessionStorage.setItem("noInvoice", item.noInvoice);
            sessionStorage.setItem("idPenjualan", item.id);
            history.push(`${basePath}/${item.id}/detail/non-ppn`);
          }),
          new TableWrapper.action(
            `Download Invoice`,
            "mdi mdi-download",
            (item) => {
              setItemInvoice(item);
              service.previewInvoice(item.id).then((response) => {
                const type = response.headers["content-type"];
                let blob = new Blob([response.data], {
                    type: type,
                  }),
                  datas = window.URL.createObjectURL(blob);
                setFile(datas);
                setShowDialogPreviewInvoice(true);
              });
            },
            true
          ),
          new TableWrapper.action(
            `${t.translate("words.history")}`,
            "mdi mdi-history",
            (item) => {
              localStorage.setItem("historyPenjualan", JSON.stringify(item));
              history.push(`${basePath}/${item.id}/history`);
            }
          ),
          new TableWrapper.action(
            `${t.translate("words.jurnalEntri")}`,
            "mdi mdi-application",
            (item) => {
              localStorage.setItem(
                "jurnalEntriPenjualan",
                JSON.stringify(item)
              );
              history.push(`${basePath}/${item.noInvoice}/jurnal-entri`);
            }
          ),
          new TableWrapper.action(
            `${t.translate("words.batalkan")}`,
            "mdi mdi-close",
            (item) => {
              dialogBatalkan(item);
            },
            (item) =>
              item.pembayaran.status == STAT_LUNAS ||
              item.pembayaran.status == STAT_BATAL ||
              item.pembayaran.status == STAT_BAYAR_SEBAGIAN
                ? false
                : true
          ),
        ]}
        onFetchData={(query) => {
          return new Promise(async (resolve, reject) => {
            try {
              query = {
                ...query,
                tahun: tahun ? tahun : tahunSobatBuku,
              };
              let getPage = await service.getAll(query);
              setData(getPage.data);
              getPage.headers["x-pagination-count"] =
                getPage.headers["x-total-count"];
              resolve(getPage);
            } catch (e) {
              resolve();
              // ErrorService(e)
            }
          });
        }}
        showCommandbar={showCommandbar}
        showFilterPeriod={false}
      />
      {/*TERIMA PEMBAYARAN*/}
      <PenjualanTerimaPembayaran
        visible={showBayar}
        onRequestClose={() => {
          setShowBayar(false);
          setDataBayar(null);
          document.getElementsByClassName("mdi-reload")[0].click();
        }}
        datas={dataBayar}
      />
      {/* DIALOG PILIH TAMBAH */}
      <Dialog
        visible={showSelectCreate}
        id="add-data-dialog"
        onRequestClose={() => {
          setShowSelectCreate(false);
        }}
      >
        <DialogHeader>
          <DialogTitle>{t.translate(`words.pilih`)}</DialogTitle>
        </DialogHeader>
        <DialogFooter>
          <Button
            onClick={() => {
              setShowSelectCreate(false);
              history.push(`${basePath}/new/non-ppn`);
            }}
          >
            Penjualan Non PPN
          </Button>

          <Button
            onClick={() => {
              getNSFP();
              setShowSelectCreate(false);
              history.push(`${basePath}/new/ppn`);
            }}
          >
            Penjualan PPN
          </Button>
        </DialogFooter>
      </Dialog>

      {/* DIALOG SEND EMAIL  */}
      <Dialog visible={showSendEmail}>
        <DialogContent>
          <Text>{t.translate(`words.confirmSendEmail`)}</Text>
          <Button
            id="penjualan-dialog-send-email-yes"
            onClick={() => {
              setShowSendEmail(false);
            }}
            theme="primary"
            themeType="contained"
            style={{ marginRight: "2%" }}
          >
            {t.translate(`words.yes`)}
          </Button>
          <Button
            id="dialog-close"
            onClick={() => {
              setShowSendEmail(false);
            }}
            theme="primary"
            themeType="contained"
          >
            {t.translate(`words.cancel`)}
          </Button>
        </DialogContent>
      </Dialog>

      {/* DIALOG BATALKAN  */}
      <Dialog visible={showBatalkan}>
        <DialogContent>
          {loadingCancel ? (
            <>
              <Text>Sedang Diproses . . .</Text>
              <LoaderInfo />
            </>
          ) : (
            <>
              <Text>{t.translate(`words.confirmBatalkan`)}</Text>
              <Button
                onClick={async () => {
                  setLoadingCancel(true);
                  try {
                    if (selectedItem.isPpn) {
                      let res = await service.batalkanPkp(selectedItem.id);
                    } else {
                      let res = await service.batalkan(selectedItem.id);
                    }
                    setShowBatalkan(false);
                    document.getElementsByClassName("mdi-reload")[0].click();
                    iziToast.success({
                      message: "sukses",
                    });
                    setLoadingCancel(false);
                  } catch (e) {
                    errorService(e);
                    setLoadingCancel(false);
                    // alert(error, true)
                  }
                }}
                theme="primary"
                themeType="contained"
                style={{ marginRight: "2%" }}
              >
                {t.translate(`words.yes`)}
              </Button>
              <Button
                onClick={() => {
                  setShowBatalkan(false);
                }}
                theme="primary"
                themeType="contained"
              >
                {t.translate(`words.cancel`)}
              </Button>
            </>
          )}
        </DialogContent>
      </Dialog>

      {/* DIALOG UPLOAD FAKTURR  */}
      <Dialog visible={showUploadFakturPajak}>
        <DialogContent>
          <Text>{t.translate(`words.confirmUploadFakturPajak`)}</Text>

          <Button
            onClick={() => {
              setShowUploadFakturPajak(false);
            }}
            theme="primary"
            themeType="contained"
          >
            {t.translate(`words.cancel`)}
          </Button>
          <Button
            onClick={async () => {
              try {
                await service.uploadFkPkp(selectedItem.id);
              } catch (error) {
                toast.errorRequest(error);
              }
            }}
            theme="primary"
            themeType="contained"
            style={{ marginRight: "2%" }}
          >
            {t.translate(`words.yes`)}
          </Button>
        </DialogContent>
      </Dialog>

      {/* DIALOG DOWNLOAD INVOICE BULK  */}
      <Dialog visible={showDownloadInvoiceBulk}>
        <DialogContent>
          <>
            <Text>Proses Download</Text>
            <LoaderInfo />
          </>
        </DialogContent>
      </Dialog>
      <PreviewFile
        visible={showDialogPreviewInvoice}
        onRequestClose={() => {
          setShowDialogPreviewInvoice(false);
          setItemInvoice(null);
        }}
        onClickDownload={() => {
          downloadInvoice(itemInvoice.id);
        }}
        file={file}
        type={type}
      />
    </>
  );
};

export default inject("modalStore", "navigationStore")(observer(Penjualan));
