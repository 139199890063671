import { crud, http } from "../../libs/react-mpk/services";

let service = null;
let serviceUrl = "/kas_bank";
let tahunSobatPajak = sessionStorage.getItem("tahunSobatBuku");
let tahunSobatBuku = sessionStorage.getItem("tahunSobatBuku");
let npwpHeadId = sessionStorage.getItem("npwpHeadId");
let npwpHeadIdObject = {
  npwpHeadId: sessionStorage.getItem("npwpHeadId"),
};
let AkunId = sessionStorage.getItem("idAkun");
service = new crud("/kas_bank");
let akunIdSelect = sessionStorage.getItem("akunIdSelect");
//PAGE TABLE -----------------------------------
//GET PAGE TABLE
service.getPage = async () => {
  let query = {
    // "page": 0,
    // "size": 10,
    // "kodeAkun.contains": "",
    // "namaAkun.contains": "",
    sort: "akun.kode,ASC",
    "tahun.equals": tahunSobatPajak,
    npwpHead: npwpHeadId,
    // "npwpHeadId" : npwpHeadId
  };

  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}`,
    config: { headers: { "content-type": "multipart/form-data" } },
    query,
  });
};
service.deletePenerimaan = async (noTransaksi) => {
  return http.request({
    method: http.methods.DELETE,
    url: `/kas_bank/penerimaan/delete/${npwpHeadId}/${noTransaksi}`,
  });
};
service.deletePengeluaran = async (noTransaksi) => {
  return http.request({
    method: http.methods.DELETE,
    url: `/kas_bank/pengeluaran/delete/${npwpHeadId}/${noTransaksi}`,
  });
};

service.getDetailByKasBank = async (id) => {
  let query = {
    npwpHead: npwpHeadId,
  };
  return http.get(`${serviceUrl}/pengeluaran/${id}`, query);
};
//DETAIL TABLE -----------------------------------
//GET PAGE DETAIL TABLE
service.getPageDetail = async (id, query) => {
  query = {
    ...query,
    tahun: tahunSobatPajak,
    npwpHead: npwpHeadId,
    "akunId.equals": id,
  };
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/detail`,
    query,
  });
};

//GET DETAIL PENERIMAAN BY NO TRANsaKSI
service.getPageDetailbyNoTransaksi = async (noTransaksi) => {
  let query = {
    npwpHead: npwpHeadId,
  };
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/penerimaan/get_one_by_no_transaksi/${noTransaksi}`,
    query,
  });
};

//GET DETAIL PENGELUARAN BY NO TRANSAKSI
service.getDetailPengeluaranbyNoTransaksi = async (noTransaksi) => {
  let query = {
    npwpHead: npwpHeadId,
  };
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/pengeluaran/get_one_by_no_transaksi/${noTransaksi}`,
    query,
  });
};

//GET DETAIL TRANSFER BY NO TRANSAKSI
service.getDetailTransferbyNoTransaksi = async (noTransaksi) => {
  let query = {
    npwpHead: npwpHeadId,
  };
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/transfer/get_one_by_no_transaksi/${noTransaksi}`,
    query,
  });
};

//TRANSFER ANTAR BANK -----------------------------------
//POST TRANSFER ANTAR BANK
service.createTransfer = async (data) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/transfer`,
    data,
  });
};
//POST TRANSFER ANTAR BANK DOKUMEN
service.uploadGambar = async (file) => {
  let data = {
    file: file,
    npwpHead: npwpHeadId,
  };
  // console.log(data, 'data')
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/transfer/upload`,
    data: http.objectToFormData(data),
    config: { headers: { "content-type": "multipart/form-data" } },
  });
};
service.DownloadLampiranTransfer = async (id) => {
  return http.download(`${serviceUrl}/transfer/download/${id}`);
};
service.getLampiranTransfer = async (id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/transfer/download/${id}`,
    config: {
      responseType: "arraybuffer",
    },
  });
};
//AKUN
service.getAkun = async (queryNama) => {
  let query = {
    ...npwpHeadIdObject,
    "kategoriAkunKode.in": "1.1.01,1.1.02",
    size: 10,
    "nama.contains": queryNama,
  };
  return http.request({
    method: http.methods.GET,
    url: `/akun`,
    query,
  });
};

//TAMBAH AKUN BANK -----------------------------------
//AKUN
service.getOneAkunById = async (id) => {
  return http.request({
    method: http.methods.GET,
    url: `/akun/${id}`,
  });
};
service.getListKategoriAkun = async (query) => {
  return http.request({
    method: http.methods.GET,
    url: `/kategori_akun`,
    query,
  });
};
service.getListKategoriAset = async () => {
  let query = {
    page: 0,
    size: 9999,
    sort: "tanggalDibuat,DESC",
    "kategori.equals": 1,
    "kode.contains": "",
    "nama.contains": "",
  };
  return http.request({
    method: http.methods.GET,
    url: `/kategori_aset`,
    query,
  });
};
service.getListKategoriHutang = async () => {
  let query = {
    page: 0,
    size: 9999,
    sort: "tanggalDibuat,DESC",
    "kategori.equals": 2,
    "kode.contains": "",
    "nama.contains": "",
  };
  return http.request({
    method: http.methods.GET,
    url: `/kategori_aset`,
    query,
  });
};
service.getListKoreksiFiskal = async () => {
  let query = {
    page: 0,
    size: 9999,
    sort: "tanggalDibuat,DESC",
    "kode.contains": "",
    "nama.contains": "",
  };
  return http.request({
    method: http.methods.GET,
    url: `/koreksi_fiskal`,
    query,
  });
};
service.generateKodeAkun = async (kategoriAkunId) => {
  let query = {
    kategoriAkunId: kategoriAkunId,
    npwpHeadId: npwpHeadId,
  };
  return http.request({
    method: http.methods.GET,
    url: `/akun/generate_kode_akun`,
    query,
  });
};
service.createAkun = async (data) => {
  return http.request({
    method: http.methods.POST,
    url: `/akun`,
    data,
  });
};

service.updateAKun = async (data) => {
  return http.request({
    method: http.methods.PUT,
    url: `/akun`,
    data,
  });
};
//PENERIMAAN NON PPN -----------------------------------
//POST PENERIMAAN
service.createPenerimaan = async (data) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/penerimaan`,
    data,
  });
};
//GET LAWAN TRANSAKSI
service.getLawanTransaksi = async () => {
  let query = {
    page: 0,
    size: 10,
    sort: "tanggalDibuat,DESC",
    "kategori.equals": 1,
    npwpHeadId: npwpHeadId,
  };
  return http.request({
    method: http.methods.GET,
    url: `/lawan_transaksi`,
    query,
  });
};

service.getLawanTransaksiSupplier = async () => {
  let query = {
    page: 0,
    size: 10,
    sort: "tanggalDibuat,DESC",
    "kategori.equals": 2,
    npwpHeadId: npwpHeadId,
  };
  return http.request({
    method: http.methods.GET,
    url: `/lawan_transaksi`,
    query,
  });
};

//GET AKUN PENERIMAAN
service.getAkunPenerimaan = async () => {
  let query = {
    "tahun.equals": tahunSobatPajak,
    npwpHead: npwpHeadId,
  };
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/penerimaan/akun`,
    query,
  });
};
//GET AKUN SUBTOTAL
service.getAkunSubtotal = async () => {
  let query = {
    "tahun.equals": tahunSobatPajak,
    npwpHead: npwpHeadId,
  };
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/penerimaan/akun/subtotal`,
    query,
  });
};

service.getAkunSubtotalPengeluaran = async () => {
  let query = {
    "tahun.equals": tahunSobatPajak,
    npwpHead: npwpHeadId,
  };
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/pengeluaran/akun/subtotal`,
    query,
  });
};
//GET PASAL
service.getPasal = async () => {
  return http.request({
    method: http.methods.GET,
    url: `/pasal_pemotongan`,
  });
};
//GET OBJEK PAJAK
service.getObjek = async () => {
  return http.request({
    method: http.methods.GET,
    url: `/objek_pajak`,
  });
};
//GET PENJUALAN
service.getPenjualanStatus = async (itemId, lawanTr) => {
  let query = {
    page: 0,
    size: 9999,
    sort: "tanggalDibuat,DESC",
    "akunPiutangId.equals": itemId,
    "statusPembayaran.in": "1,2",
    "lawanTransaksiId.equals": lawanTr,
    npwpHeadId: npwpHeadId,
    // tahun: tahunSobatBuku
  };
  return http.request({
    method: http.methods.GET,
    url: `/penjualan`,
    query,
  });
};
//GET PEMBELIAN
service.getPembelianStatus = async (itemId, lawanTrId) => {
  let query = {
    page: 0,
    size: 9999,
    sort: "tanggalDibuat,DESC",
    "akunUtangId.equals": itemId,
    "statusPembayaran.in": "1,2",
    "lawanTransaksiId.equals": lawanTrId,
    npwpHeadId: npwpHeadId,
    // tahun: tahunSobatBuku
  };
  return http.request({
    method: http.methods.GET,
    url: `/fm`,
    query,
  });
};
//GET PESANAN
service.getPesananStatus = async (itemId) => {
  let query = {
    page: 0,
    size: 10,
    sort: "tanggalDibuat,DESC",
    "akunUtangId.equals": itemId,
    "statusPembayaran.in": "1,2",
    npwpHeadId: npwpHeadId,
    // tahun: tahunSobatBuku
  };
  return http.request({
    method: http.methods.GET,
    url: `/pesanan`,
    query,
  });
};
//POST LAMPIRAN
service.upload = async (file) => {
  let data = {
    file: file,
    npwpHead: npwpHeadId,
  };
  // console.log(data, 'data')
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/penerimaan/upload`,
    data: http.objectToFormData(data),
    config: { headers: { "content-type": "multipart/form-data" } },
  });
};
//PENGELUARAN NON PPN -----------------------------------
//POST PENGELUARAN
service.createPengeluaran = async (data) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/pengeluaran`,
    data,
  });
};
//GET LAWAN TRANSAKSI
service.getLawanTransaksi = async () => {
  let query = {
    page: 0,
    size: 10,
    sort: "tanggalDibuat,DESC",
    "kategori.equals": 1,
    npwpHeadId: npwpHeadId,
  };
  return http.request({
    method: http.methods.GET,
    url: `/lawan_transaksi`,
    query,
  });
};
//GET AKUN PENERIMAAN
service.getAkunPengeluaran = async () => {
  let query = {
    "tahun.equals": tahunSobatPajak,
    npwpHead: npwpHeadId,
  };
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/pengeluaran/akun`,
    query,
  });
};
//GET PASAL
service.getPasal = async () => {
  return http.request({
    method: http.methods.GET,
    url: `/pasal_pemotongan`,
  });
};
//GET OBJEK PAJAK
service.getObjek = async () => {
  return http.request({
    method: http.methods.GET,
    url: `/objek_pajak`,
  });
};
//POST LAMPIRAN
service.uploadPengeluaran = async (file) => {
  let data = {
    file: file,
    npwpHead: npwpHeadId,
  };
  // console.log(data, 'data')
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/pengeluaran/upload`,
    data: http.objectToFormData(data),
    config: { headers: { "content-type": "multipart/form-data" } },
  });
};
//DETAIL TRANSFER
service.getPageTransferDetail = async (id) => {
  let query = {
    npwpHead: npwpHeadId,
  };
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/transfer/${id}`,
    query,
  });
};
//PENERIMAAN & PENGELUARAN -----------------------------------
service.DownloadLampiranPenerimaan = async (id) => {
  return http.download(`${serviceUrl}/penerimaan/download/${id}`);
};
service.getLampiranPenerimaan = async (id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/penerimaan/download/${id}`,
    config: {
      responseType: "arraybuffer",
    },
  });
};
service.DownloadLampiranPengeluaran = async (id) => {
  return http.download(`${serviceUrl}/pengeluaran/download/${id}`);
};
service.getLampiranPengeluaran = async (id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/pengeluaran/download/${id}`,
    config: {
      responseType: "arraybuffer",
    },
  });
};
service.getDownloadPenjualan = async (id) => {
  return http.download(`${serviceUrl}/lampiran/download/${id}`);
};

export default service;
