import React, { useEffect, useState } from "react";
import { DataForm } from "../../libs/react-mpk/components";
import t from "counterpart";
import service from "./Penjualan.service";
import utilsService from "../../services/utilsService";
import moment from "moment";
import "moment/locale/id";
import { toast } from "../../libs/react-mpk/services";
const PenjualanTerimaPembayaran = ({
  visible = false,
  authStore,
  envStore,
  onRequestClose = () => {},
  ...props
}) => {
  let [ready, setReady] = useState(true);
  const [akunList, setAkunList] = useState([]);
  let [queryAkun, setQueryAkun] = useState("");
  const [loadingAkun, setLoadingAkun] = useState(false);
  const defaultData = {
    akunPiutang: null,
    akunPiutangId: null,
    tanggalTransaksi: null,
    akunTransfer: null,
    akunTransferId: null,
    lawanTransaksiId: null,
    lawanTransaksi: null,
    noTransaksi: null,
    noTransaksi2: null,
    sisaTagihanAwal: 0,
    nilaiTagihanYangTelahDibayarkan: 0,
    sisaTagihanAkhir: 0,
    keterangan: null,
    fileLampiran: null,
    fkId: null,
    fileLampiran: null,
  };
  let [item, setItem] = useState(defaultData);

  useEffect(() => {
    async function initData() {
      if (visible) {
        let selectedItem = item;
        let datas = props.datas;
        selectedItem.akunPiutang = `${datas.akunPiutang.kode} - ${datas.akunPiutang.nama}`;
        selectedItem.akunPiutangId = datas.akunPiutang.id;
        selectedItem.lawanTransaksiId = datas.lawanTransaksi.id;
        selectedItem.lawanTransaksi = `${utilsService.parseNPWP(
          datas.lawanTransaksi.npwp
        )} - ${datas.lawanTransaksi.nama}`;
        selectedItem.noTransaksi = datas.noInvoice;
        selectedItem.noTransaksi2 = utilsService.beautifyNoTransaksi(
          datas.noInvoice
        );
        selectedItem.sisaTagihanAwal = datas.pembayaran.sisaBayar;
        selectedItem.sisaTagihanAkhir = datas.pembayaran.sisaBayar;
        selectedItem.fkId = datas.id;
        setItem(selectedItem);
      } else {
        setItem(defaultData);
      }
      setReady(visible);
    }
    initData();
  }, [visible]);

  useEffect(() => {
    setLoadingAkun(true);
    const timeOutId = setTimeout(() => getAkun(queryAkun), 500);
    return () => clearTimeout(timeOutId);
  }, [queryAkun]);

  async function getAkun(queryAkun) {
    try {
      setLoadingAkun(true);
    let res = await service.getAkunPenerimaan(queryAkun);
    var akuns = [];
    res.data.map((d) => {
      akuns.push({
        value: d.id,
        label: `${d.kode} - ${d.nama}`,
      });
    });
    setAkunList(akuns);
    setLoadingAkun(false);
  } catch (error) {
    toast.errorRequest(error);
    setLoadingAkun(false);
  }
  }
  return (
    ready && (
      <DataForm
        baseId="terima-pembayaran-form"
        title={"Terima Pembayaran"}
        visible={visible}
        onRequestClose={onRequestClose}
        asDialog={true}
        defaultData={item}
        definitions={[
          {
            inputType: DataForm.inputTypes.INPUT,
            label: "akun",
            key: "akunPiutang",
            style: { zIndex: 0 },
            disabled: true,
          },
          {
            inputType: DataForm.inputTypes.DATEPICKER,
            label: t.translate(`words.tglTransaksi`),
            key: "tanggalTransaksi",
            type: "date",
            validation: "required",
            maxDate: new Date(),
            style:{zIndex:2},
          },
          {
            inputType: DataForm.inputTypes.REACT_SELECT,
            isClearable:true,
            label: t.translate(`words.transferke`),
            key: "akunTransfer",
            labelKey: "label",
            valueKey: "value",
            options: akunList,
            isLoading: loadingAkun,
            onInputChange: (value) => {
              if (value) setQueryAkun(value);
            },
            style:{zIndex:1},
            validation: "required",
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: `Nama Lawan Transaksi`,
            key: "lawanTransaksi",
            style: { zIndex: 0 },
            disabled: true,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: `Nomor Transaksi`,
            key: "noTransaksi2",
            style: { zIndex: 0 },
            disabled: true,
          },
          {
            render: (
              <div style={{ width: "100%" }}>
                <p>
                  <b>Masukan nilai tagihan yang akan sobat bayarkan</b>
                </p>
                <br />
              </div>
            ),
          },
          {
            inputType: DataForm.inputTypes.INPUT_MASK_NUMBER,
            className: "mpk-padding-N padding-right",
            label: t.translate(`words.sisaTagihanAwal`),
            key: "sisaTagihanAwal",
            thousandSeparator: ".",
            decimalSeparator: ",",
            width: "50%",
            disabled: true,
          },
          {
            inputType: DataForm.inputTypes.INPUT_MASK_NUMBER,
            label: t.translate(`words.nilaiTagihanYangTelahDibayarkan`),
            key: "nilaiTagihanYangTelahDibayarkan",
            thousandSeparator: ".",
            decimalSeparator: ",",
            width: "50%",
            validation: "required",
            required: true,
          },
          {
            inputType: DataForm.inputTypes.INPUT_MASK_NUMBER,
            label: t.translate(`words.sisaTagihanAkhir`),
            key: "sisaTagihanAkhir",
            thousandSeparator: ".",
            decimalSeparator: ",",
            disabled: true,
          },
          {
            render: (
              <div style={{ width: "100%" }}>
                <p>
                  <b>{t.translate(`words.informasiTambahan`)}</b>
                </p>
              </div>
            ),
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate(`words.keterangan`),
            key: "keterangan",
            type: "text",
            style: {
              marginRight: "2%",
            },
          },
          {
            inputType: DataForm.inputTypes.FILE_INPUT,
            label: t.translate("words.file"),
            key: "fileLampiran",
            type: "file",
            accept: "application/pdf, image/*",
          },
        ]}
        onChange={async (data, key, value) => {
          if (key == "akunTransfer") {
            akunList.map((d) => {
              if (d.value == value) {
                data["akunTransferId"] = d.value;
              }
            });
          }
          if (key == "nilaiTagihanYangTelahDibayarkan") {
            data.nilaiTagihanYangTelahDibayarkan = data.sisaTagihanAwal <= value ? data.sisaTagihanAwal: value;
            data.sisaTagihanAkhir = data.sisaTagihanAwal - data.nilaiTagihanYangTelahDibayarkan;
          }
          setItem(data);
          return data;
        }}
        onSubmit={async (data, callback) => {
          let resGambar;
          if (data.fileLampiran != null) {
            resGambar = await service.uploadPenerimaan(data.fileLampiran);
          }
          let DataBody = {
            kasBank: {
              tanggalTransaksi: moment(data.tanggalTransaksi).format(
                "YYYY-MM-DD hh:mm:ss"
              ),
              lawanTransaksi: {
                id: data.lawanTransaksiId,
              },
              akun: {
                id: data.akunTransferId,
              },
              subTotal: data.nilaiTagihanYangTelahDibayarkan,
              totalPajakSetorSendiri: 0,
              nilaiDibayar: data.nilaiTagihanYangTelahDibayarkan,
              keterangan: data.keterangan,
              npwpHead: {
                id: sessionStorage.getItem("npwpHeadId"),
              },
              fileLampiran:
                data.fileLampiran != null ? resGambar.data.namaFileAlias : null,
              fileLampiranAlias:
                data.fileLampiran != null ? resGambar.data.namaFileAlias : null,
              fileLampiranInstance:
                data.fileLampiran != null ? resGambar.data.instanceId : null,
            },
            kasBankList: [
              {
                kasBankAkun: {
                  nilaiPiutangOrHutang: data.nilaiTagihanYangTelahDibayarkan,
                  isPajakSetorSendiriOrPotongPajak: false,
                  isPajakDitanggung: false,
                  nilaiPenjualanOrBeban: 0,
                  isNilaiLain2: false,
                  nominalPajak: 0,
                  nilaiTagihanOrPenerimaan:
                    data.nilaiTagihanYangTelahDibayarkan,
                  pctTarifPajak: 0,
                  akun: {
                    id: data.akunPiutangId,
                  },
                  jenisPenghasilan: {
                    id: 1,
                  },
                  pasalPemotongan: {
                    id: 1,
                  },
                  objekPajak: {
                    id: 1,
                  },
                },
                kasBankAkunFkList: [
                  {
                    nilaiTagihanDibayar: data.nilaiTagihanYangTelahDibayarkan,
                    keterangan: null,
                    fk: {
                      id: data.fkId,
                    },
                  },
                ],
              },
            ],
          };
          if (
            data.nilaiTagihanYangTelahDibayarkan == 0 ||
            data.nilaiTagihanYangTelahDibayarkan > data.sisaTagihanAwal
          ) {
            callback(
              "Periksa Lagi Nilai Tagihan yang telah dibayarkan",
              true,
              false
            );
          } else {
            try {
              const res = await service.createPenerimaan(DataBody);
              callback("success", false);
            } catch (err) {
              callback(err, true, false);
            }
          }
        }}
      />
    )
  );
};

export default PenjualanTerimaPembayaran;
