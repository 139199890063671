import React, { useEffect, useState } from "react";
import { DataForm } from "../../libs/react-mpk/components";
import t from "counterpart";
import { FormWrapper, TableWrapper } from "../../libs/react-mpk/wrapper";
import service from "./LawanTransaksi.service";
import { ExpansionList, ExpansionPanel, usePanels } from "react-md";

const defaultData = {
  // kode: "U-001",
  // nama: "Kaos"
};

const businessList = [
  { id: 2, name: "op" },
  { id: 1, name: "badan" },
];

const kategoriLawanTransaksi = [
  { id: 2, name: "supplierPemberiPinjaman" },
  { id: 1, name: "costumerPenerimaPinjaman" },
];

const LawanTransaksiForm = ({
  visible = false,
  authStore,
  envStore,
  match,
  history,
  onRequestClose = () => {},
  ...props
}) => {
  const { inputTypes, definition } = FormWrapper;
  let [item, setItem] = useState({
    kategori: 0,
    nama: "",
    jenisUsaha: 0,
    npwp: "",
    nik: "",
    alamat: "",
    alamatPic: "",
    namaPic: "",
    emailPic: "",
    alamatSama: false,
    noTeleponPic: "",
    noTelepon: "",
  });
  let [dataSubmit, setDataSubmit] = useState({
    id: "",
    kategori: 1,
    nama: "",
    jenisUsaha: 1,
    npwp: "",
    nik: "",
    alamat: "",
    namaPic: "",
    emailPic: "",
    noTeleponPic: "",
    noTelepon: "",
    alamatSama: false,
    alamatPic: "",
    npwpHead: { id: null },
  });
  const [isEdit, setIsEdit] = useState(false);
  let [ready, setReady] = useState(true);
  let [disabledNik, setDisabledNik] = useState(true);

  useEffect(() => {
    async function initData() {
      if (visible) {
        let selectedItem = item;
        if (props.item) {
          let res = await service.getById(props.item);
          let { data, status } = res;
          setDisabledNik(data.jenisUsaha == 2 ? true : false);
          setIsEdit(true);
          selectedItem.id = data.id;
          selectedItem.alamat = data.alamat;
          selectedItem.alamatPic = data.alamatPic;
          selectedItem.alamatSama = data.alamatSama;
          selectedItem.emailPic = data.emailPic;
          selectedItem.jenisUsaha = String(data.jenisUsaha);
          selectedItem.kategori = String(data.kategori);
          selectedItem.nama = data.nama;
          selectedItem.namaPic = data.namaPic;
          selectedItem.nik = data.nik;
          selectedItem.noTelepon = data.noTelepon;
          selectedItem.noTeleponPic = data.noTeleponPic;
          selectedItem.npwp = data.npwp;
          selectedItem.tanggalDibuat = data.tanggalDibuat;
          selectedItem.tanggalDiperbaharui = data.tanggalDiperbaharui;
          selectedItem.memilikiNpwp = data.npwp === "000000000000000" ? "tidak" : "ya";
        }
      } else {
        setIsEdit(false);
        setItem({
          kategori: 0,
          nama: "",
          jenisUsaha: 0,
          npwp: "",
          nik: "",
          alamat: "",
          alamatPic: "",
          namaPic: "",
          emailPic: "",
          alamatSama: false,
          noTeleponPic: "",
          noTelepon:"",
        });
      }
      setReady(visible);
    }
    initData();
  }, [visible]);

  const [panels, onKeyDown] = usePanels({
    idPrefix: 'simple-panels',
    count: 1,
  });

  const [panelProps] = panels;
  return (
    ready && (
      <FormWrapper
        baseId="akun-npwp-form"
        title={t.translate(`modules.lawanTransaksi.form`)}
        visible={visible}
        // data={item}
        onRequestClose={onRequestClose}
        asDialog={true}
        defaultData={item}
        definitions={[
          {
            render: (
              <div>
                <p style={{ marginLeft: "10px", marginBottom: "-15px" }}>
                  <b>Pelaku Transaksi</b>
                </p>
                <br />
              </div>
            ),
          },
          {
            inputType: DataForm.inputTypes.SELECT,
            label: `Pelaku Transaksi`,
            key: "kategori",
            theme: "filled",
            options: kategoriLawanTransaksi.map((d) => ({
              value: d.id,
              children: t.translate(`words.${d.name}`),
            })),
            labelKey: "children",
            valueKey: "value",
            disabled: isEdit,
          },
          {
            inputType: inputTypes.INPUT,
            label: t.translate("words.name"),
            key: "nama",
            required:true,
          },
          {
            render: (
              <div>
                <p style={{ marginLeft: "10px", marginBottom: "-15px" }}>
                  <b>Jenis Pelaku Transaksi</b>
                </p>
                <br />
              </div>
            ),
          },

          {
            inputType: DataForm.inputTypes.SELECT,
            label: `Jenis Pelaku Transaksi`,
            key: "jenisUsaha",
            theme: "filled",
            options: businessList.map((d) => ({
              value: d.id,
              children: t.translate(`words.${d.name}`),
            })),
            labelKey: "children",
            valueKey: "value",
            disabled: isEdit,
          },
          {
            inputType: DataForm.inputTypes.DIVIDER,
            label: `Data Pelaku Transaksi`,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate("words.address"),
            key: "alamat",
            validation: "required",
            required: true,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate("words.phone"),
            key: "noTelepon",
            validation: "required",
            required: true,
          },
          {
            render: (
              <ExpansionList style={{ width: "100%" }} onKeyDown={onKeyDown}>
                <ExpansionPanel
                 {...panelProps}
                  header={"Lainnya"}
                >
                  <FormWrapper
                  // loading={}
                  showCommandbar={false}
                  style={{
                    maxWidth: "100%",
                  }}
                  defaultData={item}
                  definitions={[
                    {
                      inputType: DataForm.inputTypes.INPUT_MASK_NUMBER,
                      label: t.translate(`words.identityNpwp`),
                      key: "npwp",
                      type: "text",
                      mask: "##.###.###.#-###.###",
                      maskChar: "_",
                      maskValue: "string",
                      disabled: isEdit,
                    },
                    {
                      inputType: DataForm.inputTypes.INPUT_MASK_NUMBER,
                      label:"NIK",
                      key: "nik",
                      type: "text",
                      mask: "################",
                      maskChar: "_",
                      maskValue: "string",
                      disabled: disabledNik,
                      disabled: isEdit,
                      show: (item) => {
                        return item.jenisUsaha == 2;
                      },
                    },
                    {
                      inputType: DataForm.inputTypes.DIVIDER,
                      label: t.translate("words.picPenagihan"),
                    },
                    {
                      inputType: DataForm.inputTypes.INPUT,
                      label: t.translate("words.namaPenanggungJawab"),
                      key: "namaPic",
                    },
                    {
                      inputType: DataForm.inputTypes.INPUT,
                      label: t.translate("words.email"),
                      key: "emailPic",
                    },
                    {
                      inputType: DataForm.inputTypes.INPUT,
                      label: t.translate("words.phone"),
                      key: "noTeleponPic",
                    },
                    {
                      inputType: DataForm.inputTypes.CHECKBOX,
                      label: `Alamat Sama Dengan Data Pelaku Transaksi`,
                      key: "alamatSama",
                      name: "alamatSama",
                      style: {
                        width: "100%",
                      },
                    },
                    {
                      inputType: DataForm.inputTypes.INPUT,
                      label: t.translate("words.address"),
                      key: "alamatPic",
                      show: (item) => item.alamatSama == false,
                    },
                  ]}
                  onChange={async (formData, key, value) => {
                    setItem(formData)
                    return formData
                  }}
                  />
                </ExpansionPanel>
              </ExpansionList>
            ),
          },
         
        ]}
        onChange={async (formData, key, value) => {
          if (key === "jenisUsaha") {
            if (value == "1") {
              setDisabledNik(true);
            } else {
              setDisabledNik(false);
            }
          }
          setItem(formData);
          return formData;
        }}
        submitLabel={"Simpan"}
        onSubmit={async (data, callback) => {
          let reqData = dataSubmit;
          reqData.id = data.id;
          reqData.kategori = data.kategori;
          reqData.nama = data.nama;
          reqData.jenisUsaha = data.jenisUsaha;
          reqData.npwp = data.npwp == "" ?"000000000000000":data.npwp;
          reqData.nik = data.jenisUsaha == "1" || data.nik == "" ||data.nik == "0000000000000000" ? "0000000000000000" : data.nik;
          reqData.alamat = data.alamat;
          reqData.namaPic = data.namaPic == ""?data.nama:data.namaPic;
          reqData.emailPic = data.emailPic;
          reqData.noTelepon = data.noTelepon;
          reqData.noTeleponPic = data.noTeleponPic== ""?data.noTelepon:data.noTeleponPic;
          reqData.alamatSama = data.alamatSama;
          reqData.alamatPic = data.alamatSama == false ? data.alamatPic : data.alamat;
          reqData.npwpHead.id = sessionStorage.getItem("npwpHeadId");
          if (data.jenisUsaha == "1" && data.npwp == "") {
            callback("NPWP Tidak Boleh Kosong", true, false);
          } else {
            if (data.id) {
              try {
                let res = await service.edit(reqData.id, reqData);
                callback("success", false);
              } catch (error) {
                callback(error, true, false);
              }
            } else {
              try {
                let res = await service.post(reqData);
                let { data, status } = res;
                callback("success", false);
              } catch (error) {
                callback(error, true, false);
              }
            }
          }
        }}
      />
    )
  );
};

export default LawanTransaksiForm;
