import { crud, http } from "../../libs/react-mpk/services";

let service = null;
let serviceUrl = "/produk";
let tahunSobatPajak = sessionStorage.getItem("tahunSobatBuku");
let npwpHeadId = sessionStorage.getItem("npwpHeadId");
let npwpHeadIdObject = {
  npwpHeadId: sessionStorage.getItem("npwpHeadId"),
};
service = new crud("/produk");

service.getPage = async (query) => {
  query = {
    ...query,
    ...npwpHeadIdObject,
  };
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}`,
    query,
  });
};

service.uploadGambar = async (file) => {
  let data = {
    file: file,
    npwpHeadId: npwpHeadId,
  };
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/gambar/upload`,
    data: http.objectToFormData(data),
    config: { headers: { "content-type": "multipart/form-data" } },
  });
};
service.downloadGambar = async (query) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/gambar/download`,
    query,
    config: {
      responseType: "arraybuffer",
    },
  });
};
service.downloadGambarFile = async (npwpHeadId,namaFile) => {
  return http.download(
      `${serviceUrl}/gambar/download?npwpHeadId=${npwpHeadId}&namaFile=${namaFile}`, 
  )
}


service.getOneById = async (id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/${id}`,
  });
};

service.getListKategori = async () => {
  let query = {
    page: 0,
    size: 9999,
    sort: "tanggalDibuat,DESC",
    "kode.contains": "",
    "nama.contains": "",
    npwpHeadId: npwpHeadId,
  };
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/kategori`,
    query,
  });
};

service.getListUnit = async () => {
  let query = {
    page: 0,
    size: 9999,
    sort: "tanggalDibuat,DESC",
    "kode.contains": "",
    "nama.contains": "",
    npwpHeadId: npwpHeadId,
  };
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/unit`,
    query,
  });
};

service.getListAkun = async () => {
  let query = {
    page: 0,
    size: 9999,
    sort: "tanggalDibuat,DESC",
    "kode.contains": "",
    "nama.contains": "",
    npwpHeadId: npwpHeadId,
  };
  return http.request({
    method: http.methods.GET,
    url: `/akun`,
    query,
  });
};

service.create = async (data) => {
  let query = {
    tahun: tahunSobatPajak,
  };
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}`,
    data,
    config: {
      params: {
        tahun: tahunSobatPajak,
      },
    },
  });
};

service.update = async (data) => {
  return http.request({
    method: http.methods.PUT,
    url: `${serviceUrl}/${data.produk.id}`,
    data,
    config: {
      params: {
        tahun: tahunSobatPajak,
      },
    },
  });
};

service.deleteById = async (id) => {
  return http.request({
    method: http.methods.DELETE,
    url: `${serviceUrl}/${id}`,
  });
};

export default service;
