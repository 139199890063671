import React, { useEffect, useState } from 'react';
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { find } from 'lodash'
import { Table, TableBody, TableHeader, TableRow, TableCell, Button, FontIcon, TableFooter, Text } from 'react-md'
import { KasDanBankTambahEditInputDetailAkunUang } from '..';
import { Hint } from '../../../libs/react-mpk/components';
import KasDanBankTambahEditInputDetailAkunBeban from './KasDanBankTambahEditInputDetailAkunBeban';
import service from '../KasDanBank.service';
import { toast } from '../../../libs/react-mpk/services';
import KasDanBankDetailList from '../KasDanBankDetailList/KasDanBankDetailList';
import { format } from '../../../libs/react-mpk/services/number.service';
import utilsService from '../../../services/utilsService';
import PreviewFile from '../../PreviewFile/PreviewFile';

const lawanTr = [
	{ name: "Fajar", id: "12.123.123.123.123.12" },
	{ name: "Nana", id: "13.111.123.123.123.12" }
]
const trf = [
	{ name: "Kas", id: "1.1.01.0001" },
	{ name: "Bank", id: "3.3.03.0003" }
]
const subtotal = [
	{ name: "Utang Usaha", id: "2.1.01.0001", nominal: "Rp. 30.000" },
	{ name: "Beban Jasa", id: "3.4.03.0003", nominal: "Rp 20.000" }
]

const defaultData = () => ({
	noTransaksi: null,
	tanggalTransaksi: '',
	subTotal: 0,
	totalPajakSetorSendiri: 0,
	nilaiDibayar: 0,
	totalNilaiDibayar:0,
	keterangan: '',
	fileLampiran: '',
	fileLampiranAlias: '',
	fileLampiranInstance: '',
	lawanTransaksi: {
		id: null
	},
	akun: {
		id: null
	},
	nominalTransfer: 0,
	subTotal:0,
	kasBankList: []
})

const KasDanBankDetailPengeluaranNonPPN = ({
	envStore,
	match,
	...props
}) => {
	const { inputTypes, definition } = FormWrapper
	const npwpHeadId = sessionStorage.getItem('npwpHead')
	const idAkun = sessionStorage.getItem('idAkun')
	const [dialogInputDetailAkun, setDialogInputDetailAkun] = useState(false)
	const [dialogInputDetailAkunBeban, setDialoginputDetailBeban] = useState(false)
	const [dialogDetail, setDialogDetail] = useState(false);
	const [dialogData, setDialogData] = useState(false);
	const [itemFile, setItem] = useState({})
	const [ready, setReady] = useState(null)
	const [showDialog, setDialog] = useState(false);
	let [file, setFile] = useState(null);
	let [type, setType] = useState(null);
	var produkData = [
		{
			namaAkun: "Utang Usaha",
			kodeAkun: "2.1.01.0001",
			nominal: "30000000",
		},
		{
			namaAkun: "Beban Jasa",
			kodeAkun: "6.1.01.0009",
			nominal: "20000000",
		}
	]
	function addItem() {
		setDialogInputDetailAkun(true)
	}
	function addItemBeban() {
		setDialoginputDetailBeban(true)
	}

	async function initData() {

		let selectedItem = defaultData()
		try {
			let res = await service.getDetailPengeluaranbyNoTransaksi(match.params.id)

			let { data } = res

			selectedItem.id = data.kasBank.id
			selectedItem.fileLampiran = data.kasBank.fileLampiran
			selectedItem.fileLampiranAlias = data.kasBank.fileLampiranAlias
			selectedItem.fileLampiranInstance = data.kasBank.fileLampiranInstance
			selectedItem.keterangan = data.kasBank.keterangan
			selectedItem.tanggalTransaksi = data.kasBank.tanggalTransaksi
			selectedItem.noTransaksi = utilsService.beautifyNoTransaksi(data.kasBank.noTransaksi)
			selectedItem.lawanTransaksi = `${utilsService.parseNPWP(data.kasBank.lawanTransaksi.npwp)} - ${data.kasBank.lawanTransaksi.nama}`
			selectedItem.akun = `${data.kasBank.akun.kode} - ${data.kasBank.akun.nama}`

			if (data.kasBank.totalPajakSetorSendiri) {
		
				selectedItem.nilaiDibayar = data.kasBank.nilaiDibayar - data.kasBank.totalPajakSetorSendiri
			} else {

				selectedItem.nilaiDibayar = data.kasBank.nilaiDibayar
			}

			
			selectedItem.subTotal = data.kasBank.nilaiDibayar
			
			selectedItem.totalPajakSetorSendiri = data.kasBank.totalPajakSetorSendiri
			selectedItem.kasBankList = data.kasBankList ? data.kasBankList : []
			selectedItem.kasBankList.map(d => {
				selectedItem.totalNilaiDibayar +=d.nilaiDibayar
			})
			setItem(selectedItem)
		} catch (error) {
		}
		setReady(true)
	}

	useEffect(() => {
		initData()
	}, [])

	
	return ready && (
		<>
			<FormWrapper
				// backTo={`/internal/npwp/${npwpHeadId}/kas-dan-bank-detail/${idAkun}/selengkapnya`}
				showCommandbar={true}
				baseId="mod-kas-dan-bank-pengeluaran-non-ppn"
				title={t.translate(`modules.KasDanBank.detailPengeluaranUang`)}
				style={{
					maxWidth: '100%'
				}}
				submitLabel={t.translate(`words.submit`)}
				defaultData={itemFile}
				definitions={[
					{
						inputType: inputTypes.INPUT,
						label: t.translate(`words.noTransaksi`),
						key: 'noTransaksi',
						width: '49%',
						disabled: true,
						style: {
							marginRight: "2%"
						}
					},
					{
						inputType: inputTypes.DATEPICKER,
						label: t.translate(`words.tglTransaksi`),
						key: 'tanggalTransaksi',
						width: '49%',
						disabled: true
					},
					{
						render: (
							<div style={{ width: '100%' }}>
								<p style={{ margin: "10px" }}><b>{t.translate(`words.lawanTransaksiDanSumberDana`)}</b></p>
							</div>
						)
					},
					{
						inputType: inputTypes.INPUT,
						label: t.translate(`words.lawanTransaksi`),
						key: 'lawanTransaksi',
						type: 'text',
						width: '49%',
						style: {
							marginRight: "2%"
						},
						disabled: true
					},
					{
						inputType: inputTypes.INPUT,
						label: t.translate(`words.transferDari`),
						key: 'akun',
						type: 'text',
						width: '49%',
						disabled: true
					},
					{
						render: (
							<div style={{ width: '100%' }}>
								<Hint
									className="mpk-margin-N margin-top margin-bottom"
									message="Akun Subtotal"
								/>
								<Table style={{ width: '100%' }}>
									<TableHeader>
										<TableRow>
											<TableCell style={{ width: '10%' }}>{t.translate('words.non')}</TableCell>
											<TableCell style={{ width: '25%' }}>{t.translate('words.namaAkun')}</TableCell>
											<TableCell style={{ width: '25%' }}>{t.translate('words.kodeAkun')}</TableCell>
											<TableCell style={{ width: '25%' }}>{t.translate('words.nominal')}</TableCell>
										</TableRow>
									</TableHeader>
									<TableBody>
										{itemFile && itemFile.kasBankList.map((d, index) => {
											return (
												<TableRow key={index}>
													<TableCell>{index + 1}</TableCell>
													<TableCell>{d.kasBankAkun.akun.nama}</TableCell>
													<TableCell>{d.kasBankAkun.akun.kode}</TableCell>
													<TableCell>Rp. {format(d.kasBankAkun.kasBank.nilaiDibayar)}</TableCell>
													<TableCell><Button
                                              style={{
                                                backgroundColor: "#34495e",
                                                color: "white",
                                              }}
                                              onClick={() => {
                                                setDialogDetail(true);
                                                setDialogData(d);
                                                // setDialogJudul(
                                                //   "Nilai Biaya (Sebelum Pajak)"
                                                // );
                                              }}
                                            >
                                              Detail
                                            </Button></TableCell>
												</TableRow>
											)
										})}
									</TableBody>
									<TableFooter>
										<TableRow>
											<TableCell><b>Total Nominal</b></TableCell>
											<TableCell></TableCell>
											<TableCell></TableCell>
											<TableCell>Rp. {format(itemFile && itemFile.subTotal)}</TableCell>
											
										</TableRow>
									</TableFooter>
								</Table>
								<br />
							</div>
						)
					},
					{
						inputType: inputTypes.INPUT_MASK_NUMBER,
						label: t.translate(`words.nilaiYangDibayar`),
						key: 'nilaiDibayar',
						disabled: true,
						type: 'text',
						thousandSeparator: '.',
						decimalSeparator: ',',
						isNumericString: true,
					},
					{
						render: (
							<div style={{ width: '100%' }}>
								<p style={{ margin: "10px" }}><b>{t.translate(`words.informasiTambahan`)}</b></p>
							</div>
						)
					},
					{
						inputType: inputTypes.INPUT,
						label: t.translate(`words.keterangan`),
						key: 'keterangan',
						type: 'text',
						disabled: true,
						show: () => {
							return itemFile.keterangan != null;
						  },
					},
					{
						inputType: inputTypes.INPUT,
						label: t.translate(`words.namaFile`),
						key: "fileLampiran",
						type: "text",
						width: "70%",
						disabled: true,
						show: () => {
						  return itemFile.fileLampiran != null;
						},
					  },
					  {
						render: (
						  <Button
							theme="primary"
							themeType="contained"
							style={{ margin: 10 }}
							onClick={async () => {
							  try {
								await service
								  .getLampiranPengeluaran(itemFile.id)
								  .then((response) => {
									const type = response.headers["content-type"];
									let blob = new Blob([response.data], {
										type: type,
									  }),
									  datas = window.URL.createObjectURL(blob);
									setFile(datas);
								  });
								if (itemFile.fileLampiran.includes(".pdf")) {
								  setType("pdf");
								} else {
								  setType("image");
								}
								setDialog(true);
							  } catch (error) {
								toast.errorRequest(error);
							  }
							}}
						  >
							Lihat File
						  </Button>
						),
						show: () => {
						  return itemFile.fileLampiran != null;
						},
					  },
				]}
				onChange={(data, key, value) => {
					if (key === 'lawanTransaksi') {
						let lawan = find(lawanTr, { name: value })
						if (lawan) data.lawanId = lawan.id
					}
					return data
				}}


			/>
			<KasDanBankTambahEditInputDetailAkunUang
				visible={dialogInputDetailAkun}
				onRequestClose={() => {
					setDialogInputDetailAkun(false)
				}}
			/>
			<KasDanBankTambahEditInputDetailAkunBeban
				visible={dialogInputDetailAkunBeban}
				onRequestClose={() => {
					setDialoginputDetailBeban(false)
				}}
			/>
			 <KasDanBankDetailList
                  visible={dialogDetail}
                  onRequestClose={() => {
                    setDialogDetail(false);
                    setDialogData(null);
                  }}
                  item={dialogData}
                />
				<PreviewFile
        visible={showDialog}
        onRequestClose={() => {
          setDialog(false);
        }}
        onClickDownload={() => {
          service.DownloadLampiranPengeluaran(itemFile.id);
        }}
        file={file}
        type={type}
      />
		</>
	)
}

export default inject('envStore')(observer(KasDanBankDetailPengeluaranNonPPN))