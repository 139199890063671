import React,{useState, useEffect} from 'react';
import { CustomWrapper, FormWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { inputTypes } from '../../../libs/react-mpk/config/constant';
import { usePanels } from '@react-md/expansion-panel';
import { Table, TableBody, TableHeader, TableRow, TableCell, Button, FontIcon, TableFooter } from 'react-md'
import service from '../KasDanBank.service';
import KasDanBankTambahEditKepemilikanModal from './KasDanBankTambahEditKepemilikanModal';

const KasDanBankTambahEditAkun = ({envStore, navigationStore}) => {
    const [panels, onKeyDown] = usePanels({
        idPrefix: 'kas-dan-bank-edit-akun',
        count: 5,
        defaultExpandedIndex: 0,
    });
    const [dialogKepemilikanModal, setDialogKepemilikanModal] = useState(false)
    const [viewList, setViewList] = useState([])
    const trf=[
        {name:"Kas",id:"1.1.01.0001"},
        {name:"Bank",id:"3.3.03.0003"}
    ]
    const kategori=[
        {name:"Uang Tunai",id:"11"},
        {name:"Uang Digital",id:"12"}
    ]
    const katAkun=[
        {name:"All Field Open",id:"all"},
        {name:"Kas",id:"kas"},
        {name:"Modal",id:"modal"},
        {name:"Penjualan",id:"penjualan"},
        {name:"Beban Penjualan",id:"bebanPenjualan"}
    ]
    var produkData = [
		{
			nama: "Anton",
			jumlah: "Rp. 10.000.000",
			presentase: "50%",
            id:'1',
            jenisUsaha:"Orang Pribadi/Perseorangan",
            npwp:"90.123.123.1-100.000",
            nik:"1375011704960001",
            alamat:"Jl. Kemanggisan Utama Raya No. J4, Jakarta Barat, DKI Jakarta",
            email:"anton@gmail.com"
		}
	]
    //funtion untuk set dialog
    function addItem(){
        setDialogKepemilikanModal(true)
    }
    const [currentDefinition, setCurrentDefinition]=useState('all')
    const options={
        all:{
            defaultData:{
                noTransaksi:"KK-2021/07/0001",
                tglTransaksi:"2021/07/26",
                kodeAkun:"1.1.01.0001",
                namaAkun:"All",
                saldoAwal:'0',
                defaultKoreksiFisikal:true
            },
            definitions:[
                {
                    inputType: inputTypes.INPUT,
                    label: t.translate(`words.kodeAkun`),
                    key: 'kodeAkun',
                    style: {
                        marginRight: "2%"
                    },
                    width: '49%',
                    disabled:true,
                },
                {
                    inputType: inputTypes.INPUT,
                    label: t.translate(`words.namaAkun`),
                    key: 'namaAkun',
                    width: '49%',
                },
                {
                    inputType: inputTypes.SELECT,
                    label: t.translate(`words.kategoriAset`),
                    key: 'kategoriAset',
                    type: 'text',
                    width: '49%',
                    options: kategori.map(d => ({
                        label:d.id + " - " + d.name,
                        value: d.id
                    })),
                    style: {
                        marginRight: "2%"
                    },
                    show:(data)=>{
                        return data.kategoriAkun === "All Field Open"
                    }
                },
                {
                    inputType: inputTypes.SELECT,
                    label: t.translate(`words.kategoriHutang`),
                    key: 'kategoriHutang',
                    width: '49%',
                    options:[
                        {label:"Utang Kartu Kredit", value:"kredit"},
                        {label:"Utang Lain", value:"lain"}
                    ]
                },
                {
                    inputType: inputTypes.CHECKBOX,
                    label: t.translate(`words.defaultKoreksiFisikal`),
                    key: 'defaultKoreksiFisikal',
                },
                {
                    inputType: inputTypes.SELECT,
                    label: t.translate(`words.koreksiFisikal`),
                    key: 'koreksiFisikal',
                    options:[
                        {label:"Penyesuaian Fiskal Negatif Lainnya", value:"penyesuaianFisikalNegatif"},
                        {label:"Penyesuaian Fiskal Positif Lainnya", value:"penyesuaianFisikalPositif"}
                    ]
                },
                {
                    inputType: inputTypes.MULTI_CHECKBOX,
                    label: t.translate(`words.kategoriMonitoring`),
                    key: 'kategoriMonitoring',
                    options:[
                        {label:"Monitoring Lawan Transaksi Dan Tagihan", value: true},
                        {label:"Input Daftar Nominatif Entertaiment", value: false},
                        {label:"Input Daftar Nominatif Promosi", value: false},
                    ]
                },
                {
                    inputType: inputTypes.INPUT,
                    label: t.translate(`words.saldoAwal`),
                    key: 'saldoAwal',
                    type: 'text',
                }
            ]
        },
        kas:{
            defaultData:{
                noTransaksi:"KK-2021/07/0001",
                tglTransaksi:"2021/07/26",
                kodeAkun:"2.1.01.0001",
                namaAkun:"Kas",
                saldoAwal:'0',
                defaultKoreksiFisikal:true
            },
            definitions:[
                {
                    inputType: inputTypes.INPUT,
                    label: t.translate(`words.kodeAkun`),
                    key: 'kodeAkun',
                    style: {
                        marginRight: "2%"
                    },
                    width: '49%',
                    disabled:true,
                },
                {
                    inputType: inputTypes.INPUT,
                    label: t.translate(`words.namaAkun`),
                    key: 'namaAkun',
                    width: '49%',
                },
                {
                    inputType: inputTypes.SELECT,
                    label: t.translate(`words.kategoriAset`),
                    key: 'kategoriAset',
                    type: 'text',
                    width: '49%',
                    options: kategori.map(d => ({
                        label:d.id + " - " + d.name,
                        value: d.id
                    })),
                    style: {
                        marginRight: "2%"
                    },
                    show:(data)=>{
                        return data.kategoriAkun === "All Field Open"
                    }
                },
                {
                    inputType: inputTypes.INPUT,
                    label: t.translate(`words.saldoAwal`),
                    key: 'saldoAwal',
                    type: 'text',
                }
            ]
        },
        modal:{
            defaultData:{
                noTransaksi:"KK-2021/07/0001",
                tglTransaksi:"2021/07/26",
                kodeAkun:"3.1.01.0001",
                namaAkun:"Modal",
                saldoAwal:'0',
                defaultKoreksiFisikal:true
            },
            definitions:[
                {
                    inputType: inputTypes.INPUT,
                    label: t.translate(`words.kodeAkun`),
                    key: 'kodeAkun',
                    style: {
                        marginRight: "2%"
                    },
                    width: '49%',
                    disabled:true,
                },
                {
                    inputType: inputTypes.INPUT,
                    label: t.translate(`words.namaAkun`),
                    key: 'namaAkun',
                    width: '49%',
                },
                {
                    inputType: inputTypes.INPUT_MASK_NUMBER,
                    label: t.translate(`words.saldoAwal`),
                    key: 'saldoAwal',
                    type: 'text',
                    mask: "Rp. "+'###.###.###.###',
                    maskChar: '_',
                    maskValue:'string',
                },
                {
                    render: (
                    <div style={{ width: '100%' }}>
                        <Button theme="primary" themeType="contained" style={{ margin: 15 }} onClick={()=> addItem()}><FontIcon iconClassName="mdi mdi-plus"/>{t.translate("words.add")}</Button>
                        <Table style={{ width: '100%' }}>
                        <TableHeader>
                            <TableRow>
                                <TableCell style={{width: '10%'}}>{t.translate('words.non')}</TableCell>
                                <TableCell style={{width: '25%'}}>{t.translate('words.name')}</TableCell>
                                <TableCell style={{width: '25%'}}>{t.translate('words.jumlah')}</TableCell>
                                <TableCell style={{width: '30%'}}>{t.translate('words.presentase')}</TableCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {produkData.map((d, index)=> {
                            return (  
                                <TableRow key={index}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell 
                                        onClick={()=>{
                                            // console.log("Masuk")
                                            navigationStore.redirectTo(
                                                `/internal/kas-dan-bank-detail-kepemilikan-modal/${d.id}`
                                            )
                                        }}
                                        style={{color:'purple', fontWeight:'bolder'}}
                                    >{d.nama}</TableCell>
                                    <TableCell>{d.jumlah}</TableCell>
                                    <TableCell>{d.presentase}</TableCell>
                                    <TableCell>
                                        {/* <Button onClick={()=> console.log("Masuk")}>{t.translate("words.edit")}</Button> */}
                                        <Button onClick={()=> console.log("Masuk")}>{t.translate("words.delete")}</Button>
                                    </TableCell>
                                </TableRow>
                            )
                            })}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell><b>Total Jumlah</b></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell><b>Rp.10.000.000</b></TableCell>
                            </TableRow>
                        </TableFooter>
                        </Table>
                    </div>
                    )
                }
            ]
        },
        penjualan:{
            defaultData:{
                noTransaksi:"KK-2021/07/0001",
                tglTransaksi:"2021/07/26",
                kodeAkun:"4.1.01.0001",
                namaAkun:"Penjualan",
                saldoAwal:'0',
                defaultKoreksiFisikal:true
            },
            definitions:[
                {
                    inputType: inputTypes.INPUT,
                    label: t.translate(`words.kodeAkun`),
                    key: 'kodeAkun',
                    style: {
                        marginRight: "2%"
                    },
                    width: '49%',
                    disabled:true,
                },
                {
                    inputType: inputTypes.INPUT,
                    label: t.translate(`words.namaAkun`),
                    key: 'namaAkun',
                    width: '49%',
                },
                {
                    inputType: inputTypes.CHECKBOX,
                    label: t.translate(`words.defaultKoreksiFisikal`),
                    key: 'defaultKoreksiFisikal',
                },
                {
                    inputType: inputTypes.SELECT,
                    label: t.translate(`words.koreksiFisikal`),
                    key: 'koreksiFisikal',
                    options:[
                        {label:"Penyesuaian Fiskal Negatif Lainnya", value:"penyesuaianFisikalNegatif"},
                        {label:"Penyesuaian Fiskal Positif Lainnya", value:"penyesuaianFisikalPositif"}
                    ]
                },
                {
                    inputType: inputTypes.INPUT_MASK_NUMBER,
                    label: t.translate(`words.saldoAwal`),
                    key: 'saldoAwal',
                    type: 'text',
                    mask: "Rp. "+'###.###.###.###',
                    maskChar: '_',
                    maskValue:'string',
                }
            ]
        },
        bebanPenjualan:{
            defaultData:{
                noTransaksi:"KK-2021/07/0001",
                tglTransaksi:"2021/07/26",
                kodeAkun:"1.1.01.0001",
                namaAkun:"All",
                saldoAwal:'0',
                defaultKoreksiFisikal:true
            },
            definitions:[
                {
                    inputType: inputTypes.INPUT,
                    label: t.translate(`words.kodeAkun`),
                    key: 'kodeAkun',
                    style: {
                        marginRight: "2%"
                    },
                    width: '49%',
                    disabled:true,
                },
                {
                    inputType: inputTypes.INPUT,
                    label: t.translate(`words.namaAkun`),
                    key: 'namaAkun',
                    width: '49%',
                },
                {
                    inputType: inputTypes.CHECKBOX,
                    label: t.translate(`words.defaultKoreksiFisikal`),
                    key: 'defaultKoreksiFisikal',
                },
                {
                    inputType: inputTypes.SELECT,
                    label: t.translate(`words.koreksiFisikal`),
                    key: 'koreksiFisikal',
                    options:[
                        {label:"Penyesuaian Fiskal Negatif Lainnya", value:"penyesuaianFisikalNegatif"},
                        {label:"Penyesuaian Fiskal Positif Lainnya", value:"penyesuaianFisikalPositif"}
                    ]
                },
                {
                    inputType: inputTypes.MULTI_CHECKBOX,
                    label: t.translate(`words.kategoriMonitoring`),
                    key: 'kategoriMonitoring',
                    options:[
                        {label:"Monitoring Lawan Transaksi Dan Tagihan", value: true},
                        {label:"Input Daftar Nominatif Entertaiment", value: false},
                        {label:"Input Daftar Nominatif Promosi", value: false},
                    ]
                },
                {
                    inputType: inputTypes.INPUT_MASK_NUMBER,
                    label: t.translate(`words.saldoAwal`),
                    key: 'saldoAwal',
                    type: 'text',
                    mask: "Rp. "+'###.###.###.###',
                    maskChar: '_',
                    maskValue:'string',
                }
            ]
        }
    }
	return (
        <CustomWrapper
            backTo="/internal/kas-dan-bank"
            title={t.translate(`words.tambahEditAkun`)}
        >
            <FormWrapper 
                showCommandbar={false}
                baseId="mod-kas-dan-bank-transfer-antar-bank-all"
                style={{
                    maxWidth: '100%'
                }}
                submitLabel={t.translate(`words.submit`)}
                defaultData={options[currentDefinition].defaultData}
                definitions={[
                    {
                        inputType: inputTypes.SELECT,
                        label: t.translate(`words.kategoriAkun`),
                        key: 'kategoriAkun',
                        options: katAkun.map(d => ({
                            label:d.id + " - " + d.name,
                            value: d.id
                        })),
                    },
                    ...options[currentDefinition].definitions,
                ]}
                onChange={(data, key, value)=>{
                    if(key === 'kategoriAkun') setCurrentDefinition(value);
		            return data
                }}
                onSubmit={(data, callback) => {
                    // console.log(data);
                    setTimeout(callback, 2000)
                }}
            />
            
            <KasDanBankTambahEditKepemilikanModal
                visible={dialogKepemilikanModal}
                onRequestClose={()=>{
                    setDialogKepemilikanModal(false)
                }}
            />
        </CustomWrapper>
		
	)
    function _closeDialog(){
        setDialogKepemilikanModal(false)
    }
}

export default inject("navigationStore",'envStore')(observer(KasDanBankTambahEditAkun))