import { crud, http } from '../../../libs/react-mpk/services';

let service = null
let serviceUrl = '/migrasi/saldo_awal'
let tahunSobatPajak = sessionStorage.getItem('tahunSobatBuku');
let npwpHeadId = sessionStorage.getItem('npwpHeadId');
service = new crud('/migrasi/saldo_awal');

service.getPage = async(query) => {

  let npwpHeadIdTahunObject = {
    npwpHeadId: npwpHeadId,
    tahun: tahunSobatPajak
  }
  
  query = {
      ...query,
      ...npwpHeadIdTahunObject
  }

  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}`,
    query
  })
}

service.create = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}`,
    data
  })
}

service.getOneById = async (id)=>{
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/${id}/${tahunSobatPajak}`,
  })
}

service.update = async(data)=> {
  return http.request({
    method: http.methods.PUT,
    url: `${serviceUrl}`,
    data
  })
}

service.deleteById = async (id)=>{
  return http.request({
    method: http.methods.DELETE,
    url: `${serviceUrl}/${id}/${tahunSobatPajak}`,
  })
}

export default service;