import React, { useState } from 'react';
import t from 'counterpart'
import { Table, TableBody, TableHeader, TableRow, TableCell, Button, FontIcon, TableFooter } from 'react-md'
import KasDanBankTambahEditTagihanPengeluaran from './KasDanBankTambahEditTagihanPengeluaran';
import { FormWrapper } from '../../../libs/react-mpk/wrapper';
import { DataForm, Hint } from '../../../libs/react-mpk/components';

const KasDanBankTambahEditInputDetailAkunBeban = ({
	visible = false,
	authStore,
	envStore,
	match,
	history,
	onRequestClose = () => { },
	...props
}) => {
	const { inputTypes, definition } = FormWrapper
	const [ready, setReady] = useState(true)
    const [dataTagihan, setDataTagihan] = useState([]);
    var produkData = [
		{
			nomorTransaksi: "PB-0001/01/2021",
            nominal: "20000000",
		}
	]
	return ready && (
        <>
		<DataForm
			baseId="kas-dan-bank-tambah-edit-input-akun-beban"
			title={t.translate(`modules.KasDanBank.tambahEditinputDeailAkunBeban`)}
			visible={visible}
			onRequestClose={onRequestClose}
			asDialog={true}
            submitLabel={t.translate(`words.submit`)}
			defaultData={
                {
                    namaAkun:"Pendapatan Bunga Bank",
                    nilaiBeban:"2000000",
                    jumlahKepemilikanModal:'500000000',
                    tarifPajak:"0.5",
                    nilaiLain:"3000000",
                    nominalPajak:"150000",
                    nilaiTagihan:"3000000",
                    PajakDitanggung:false,
                    nominalPajakDitanggung:0
                }
            }
			definitions={[
				{
					inputType: DataForm.inputTypes.INPUT,
					label: t.translate(`words.namaAkun`),
					key: 'namaAkun',
                    disabled:true
                },
                {
                    inputType: inputTypes.INPUT_MASK_NUMBER,
                    label: t.translate(`words.nilaiBeban`),
                    key: 'nilaiBeban',
                },
                {
					render: (
						<div style={{ width: '100%'}}>
							<p><b>{t.translate(`words.pajakDisetorSendiri`)}</b></p>
						</div>
					)
				},
                {
                    inputType: inputTypes.DIVIDER,
                    label: t.translate(`words.transaksiTerutangPajak`),
                },
                {
                    inputType: inputTypes.SELECT,
                    label: t.translate(`words.transaksiTerutangPajak`),
                    key: 'transaksiTerutangPajak',
                    options:[
                        {label:t.translate(`words.ya`), value:'ya'},
                        {label:t.translate(`words.tidak`), value:'tidak'}
                    ]
                },
                {
                    inputType: inputTypes.DIVIDER,
                    label: t.translate(`words.apakahSobatMenyetorkanSendiri`)
                },
                {
                    inputType: inputTypes.RADIO,
                    label: t.translate(`words.ya`),
                    key: 'ya1',
                    style: {
                        width: '50%'
                    },
                    value:'ya1'
                },
                {
                    inputType: inputTypes.RADIO,
                    label: t.translate(`words.tidak`),
                    key: 'tidak1',
                    style: {
                        width: '50%'
                    },
                    value:'tidak1'
                },
                {
                    inputType: inputTypes.SELECT,
                    label: t.translate(`words.pemotonganPajak`),
                    key: 'pemotonganPajak',
                    options:[
                        {label:"PPh 4(2)", value:"pph42"},
                        {label:"PPh 21", value:"pph21"},
                        {label:"PPh 22", value:"pph22"},
                        {label:"PPh 23", value:"pph23"},
                        {label:"PPh 15", value:"pph15"},
                    ],
                    show:(d)=>{
                        return d.transaksiTerutangPajak !="tidak"
                    }
                },
                {
                    inputType: inputTypes.SELECT,
                    label: t.translate(`words.atasTransaksi`),
                    key: 'atasTransaksi',
                    options:[
                        {label:"P0001 Omset", value:"p0001"},
                    ],
                    show:(d)=>{
                        return d.transaksiTerutangPajak !="tidak"
                    }
                },
                {
                    inputType: inputTypes.INPUT_MASK_NUMBER,
                    label: t.translate(`words.tarifPajak`),
                    key: 'tarifPajak',
                    disabled:true,
                    show:(d)=>{
                        return d.transaksiTerutangPajak !="tidak"
                    },
                },
                {
                    inputType: inputTypes.DIVIDER,
                    label: t.translate(`words.perhitunganPajakBerdasarkan`),
                    show:(d)=>{
                        return d.transaksiTerutangPajak !="tidak"
                    }
                },
                {
                    inputType: inputTypes.SELECT,
                    label: t.translate(`words.perhitunganPajakBerdasarkan`),
                    key: 'perhitunganPajakBerdasarkan',
                    options:[
                        {label:t.translate(`words.nilaiLain`), value:'lainlain'},
                        {label:t.translate(`words.nilaiPembelian`), value:'pembelian'}
                    ],
                    show:(d)=>{
                        return d.transaksiTerutangPajak !="tidak"
                    }
                },
                {
                    inputType: inputTypes.INPUT_MASK_NUMBER,
                    label: t.translate(`words.nilaiLain`),
                    key: 'nilaiLain',
                    show:(d)=>{
                        return d.transaksiTerutangPajak !="tidak"
                    },
                    mask: "Rp."+'###.###.###.###',
                    maskChar: '_',
                    maskValue:'string',
                },
                {
                    inputType: inputTypes.CHECKBOX,
                    label: t.translate(`words.PajakDitanggung`),
                    key: 'PajakDitanggung',
                    show:(d)=>{
                        return d.transaksiTerutangPajak !="tidak"
                    },
                    value:false
                },
                {
                    inputType: inputTypes.INPUT_MASK_NUMBER,
                    label: 'Pajak ditaggung',
                    key: 'nominalPajakDitanggung',
                    disabled: true,
                },
                {
                    inputType: inputTypes.INPUT_MASK_NUMBER,
                    label: t.translate(`words.nominalPajak`),
                    key: 'nominalPajak',
                    disabled:true,
                    width:"49%",
                    style: {
						marginRight: "2%"
					},
                    show:(d)=>{
                        return d.perhitunganPajakBerdasarkan ==="lainlain"
                    },
                },
                {
                    inputType: inputTypes.INPUT_MASK_NUMBER,
                    label: t.translate(`words.nilaiTagihan`),
                    key: 'nilaiTagihan',
                    disabled:true,
                },
                {
                    inputType: inputTypes.DIVIDER,
                    label: t.translate(`words.informasiTambahan`),
                },
                {
                    inputType: inputTypes.INPUT,
                    label: t.translate(`words.keterangan`),
                    key: 'keterangan',
                },
			]}
            onChange={async (formData, key, value) => {
                if (key === "nilaiBeban") {
                    formData.total = formData.nilaiBeban;
                    formData.nilaiTagihan = formData.nilaiBeban;
                  }
      
                  if (key == "nilaiLain") {
                    if (value === "false"){
                      formData.nominalPajak  = 0.005 * dataTagihan.sum("nilaiTagihanYangTelahDibayarkan")
                          formData.nilaiTagihanOrPenerimaan = dataTagihan.sum("nilaiTagihanYangTelahDibayarkan")
                    }
                    formData.nominalPajak = (0.5/99.5) * formData.nilaiLain;
                  }
                  if (key == "isNilaiLain2") {
                    if (value == "false") {
                      formData.nominalPajak =
                        (0.5 / 99.5) *
                        dataTagihan.sum("nilaiTagihanYangTelahDibayarkan");
                        // console.log(formData.nominalPajak, "nominal pajak")
                    } else {
                      formData.nominalPajak = (0.5/99.5) * formData.nilaiLain;
                    }
                  }

                if (formData.isPajakDitanggung == true) {
                    formData.nominalPajak = (0.5/99.5) * dataTagihan.sum("nilaiTagihanYangTelahDibayarkan")
                    formData.nilaiTagihanOrPenerimaan = dataTagihan.sum("nilaiTagihanYangTelahDibayarkan")
                }
                if (formData.isPajakDitanggung == false) {
                        formData.nominalPajak  = 0.005 * dataTagihan.sum("nilaiTagihanYangTelahDibayarkan")
                        formData.nilaiTagihanOrPenerimaan = dataTagihan.sum("nilaiTagihanYangTelahDibayarkan")
                }
            }}
			onSubmit={(data, callback) => {
                // console.log(data);
                setTimeout(callback, 2000)
            }}
		/>
        </>
	)
}

export default KasDanBankTambahEditInputDetailAkunBeban