import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { sum } from "lodash";
import { FormWrapper, TableWrapper } from "../../../libs/react-mpk/wrapper";
import {
  Table,
  TableBody,
  TableHeader,
  TableRow,
  TableCell,
  Text,
  Button,
  FontIcon,
} from "react-md";
import FormTanggal from "../FormTanggal";
import t from "counterpart";
import iziToast from "izitoast";
import service from "./ReportLabaRugiKomersial.service";
import { useHistory, useLocation, useParams } from "react-router";
import { cloneDeep } from "lodash";
import { format, formatRupiah } from "../../../libs/react-mpk/services/number.service";

const ReportTransaksiLabaRugiKomersial = ({
  navigationStore,
  modalStore,
  match,
}) => {
  let npwpHeadId = sessionStorage.getItem("npwpHeadId");
  let namaAkun = sessionStorage.getItem("namaAkun");
  let kodeAkun = sessionStorage.getItem("kodeAkun");
  let saldoAkhir = sessionStorage.getItem("saldoAwallabaRugi");
  let history = useHistory()
  const { state: { tahun } = {} } = useLocation();
  const [data, setData] = useState([]);
  const [dataNeracaSaldo, setDataNeracaSaldo] = useState([]);
  const [load, setload] = useState(true);
  const { id } = useParams();

  const fetchData = async () => {
    try {
      let query = {
        tahun: tahun ? tahun : sessionStorage.getItem("tahunSobatBuku"),
        akunId: id,
      };
      let res = await service.getAllLabaRugiKomersialTransaksi(query);
      setData(res.data);
      setload(false);
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <FormWrapper
        loading={load}
        baseId="report-transaksi-laba-rugi-komersial"
        showCommandbar={true}
        style={{
          maxWidth: "100%",
        }}
        title={t.translate(`modules.report.transaksiLabaRugiKomersial`)}
        backTo={tahun?`/internal/npwp/${npwpHeadId}/report-laba-rugi`:`/internal/npwp/${npwpHeadId}/report/labaRugiKomersial`}
        defaultData={{
          namaAkun: kodeAkun + " " + namaAkun,
          saldoAwal: `${formatRupiah(Math.abs(saldoAkhir))}`,
        }}
        definitions={[
          // {
          //     render: (
          //         <div style={{ width: '100%' }}><FormTanggal /></div>
          //     )
          // },
          {
            inputType: FormWrapper.inputTypes.INPUT,
            label: t.translate("words.namaAkun"),
            key: "namaAkun",
            disabled: true,
            style: {
              marginLeft: "10px",
              marginRight: "2%",
            },
            width: "48%",
          },
          {
            inputType: FormWrapper.inputTypes.INPUT,
            label: t.translate("words.saldoAwal"),
            key: "saldoAwal",
            disabled: true,
            width: "48%",
          },
          {
            render: (
              <div style={{ width: "100%" }}>
                <Table style={{ width: "100%" }}>
                  <TableHeader>
                    <TableRow>
                      <TableCell style={{ width: "10%" }}>
                        {t.translate("words.non")}
                      </TableCell>
                      <TableCell style={{ width: "10%" }}>
                        {t.translate("words.aksi")}
                      </TableCell>
                      <TableCell style={{ width: "18%" }}>
                        {t.translate("words.bulanDanTahun")}
                      </TableCell>
                      <TableCell style={{ width: "18%" }}>
                        {t.translate("words.debit")}
                      </TableCell>
                      <TableCell style={{ width: "18%" }}>
                        {t.translate("words.kredit")}
                      </TableCell>
                      <TableCell style={{ width: "18%" }}>
                        {t.translate("words.saldo")}
                      </TableCell>
                    </TableRow>
                  </TableHeader>

                  <TableBody>
                    {data.map((d, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>
                            <Button
                              theme="primary"
                              // themeType="contained"
                              // style={{ margin: 15 }}
                              onClick={() => {
                                history.push({
                                  pathname: `/internal/npwp/${npwpHeadId}/report-laba-rugi/detail/${d.akunId}/${d.bulan}`,
                                  state: { tahun: tahun },
                                });
                                sessionStorage.setItem(
                                  "saldoAwalTransaksi",
                                  d.saldoAwal
                                );
                                sessionStorage.setItem(
                                  "bulanTransaksi",
                                  d.bulan
                                );
                                sessionStorage.setItem(
                                  "tahunTransaksi",
                                  d.tahun
                                );
                              }}
                            >
                              <FontIcon iconClassName="mdi mdi-import" />
                              {/* {t.translate("words.add")} */}
                            </Button>
                          </TableCell>
                          <TableCell>
                            {d.bulan == 1
                              ? "Januari"
                              : d.bulan == 2
                              ? "Februari"
                              : d.bulan == 3
                              ? "Maret"
                              : d.bulan == 4
                              ? "April"
                              : d.bulan == 5
                              ? "Mei"
                              : d.bulan == 6
                              ? "Juni"
                              : d.bulan == 7
                              ? "Juli"
                              : d.bulan == 8
                              ? "Agustus"
                              : d.bulan == 9
                              ? "September"
                              : d.bulan == 10
                              ? "Oktober"
                              : d.bulan == 11
                              ? "November"
                              : d.bulan == 12
                              ? "Desember"
                              : ""}
                            {" " + d.tahun}
                          </TableCell>
                          <TableCell>{formatRupiah(Math.abs(d.totalDebit))}</TableCell>
                          <TableCell>{formatRupiah(Math.abs(d.totalKredit))}</TableCell>
                          <TableCell>{formatRupiah(Math.abs(d.saldoAkhir))}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  {/* <TableFooter>
                                        <TableRow>
                                            <TableCell><b>{t.translate(`words.total`)}</b></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell>{Array.prototype.sum = function (prop) {
                                                var total = 0
                                                for (var i = 0, _len = this.length; i < _len; i++) {
                                                    total += this[i][prop]
                                                }
                                                return total
                                            }}<b>{data.sum("nominal")}</b></TableCell>
                                        </TableRow>
                                    </TableFooter> */}
                </Table>
              </div>
            ),
          },
        ]}
      />
    </>
  );
};

export default inject(
  "navigationStore",
  "envStore",
  "modalStore"
)(observer(ReportTransaksiLabaRugiKomersial));
