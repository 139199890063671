import { crud, http } from "../../libs/react-mpk/services";

let service = null;
let serviceUrl = "/dashboard";
service = new crud("/dashboard");

service.getDashboard = async (query) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}`,
    query,
  });
};


export default service;
