import React, { useEffect, useState } from 'react'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { find } from 'lodash'
import { Hint } from '../../libs/react-mpk/components'
import { Button } from '@react-md/button'
import { FontIcon } from '@react-md/icon'
import { Table, TableBody, TableCell, TableHeader, TableRow } from '@react-md/table'
import Modal from '../../libs/react-mpk/components/Modal/Modal'
import InputDetailAkun from "./InputDetailAkunPembelian";
import InputDetailAkunBeban from './InputDetailAkunBeban'
import InputDetailAkunPembelian from './InputDetailAkunPembelian'

const cities = [
  { id: 1, name: 'Jakarta' },
  { id: 2, name: 'Bandung' },
  { id: 3, name: 'Semarang' },
  { id: 4, name: 'Yogyakarta' },
  { id: 5, name: 'Surabaya' }
]

const PembelianPPNProduk = ({
  envStore,
  match,
  ...props }) => {
  const baseName = 'pembelian'
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({ loading: true, content: {} })
  const [dialogPembelianProdukForm, setDialogPembelianProdukForm] = useState(false)
  const [dialogBebanForm, setDialogBebanForm] = useState(false)
  const [dialogData, setDialogData] = useState(false)
  const [showModal, setShowModal] = useState(false)

  function _closeDialog() {
    setDialogPembelianProdukForm(false)
  }

  var nilaiPembelian = [
    {
      kode: "5.1.01.0002",
      nama: "Pembelian",
      nominal: "10000000"
    }
  ];

  var pajakDitanggung = [
    {
      kode: "5.1.01.0002",
      nama: "Pembelian",
      nominal: "10000000"
    }
  ];

  var pemotonganPajak = [
    {
      kode: "5.1.01.0002",
      nama: "Pembelian",
      nominal: "10000000"
    }
  ];

  var konfirmasi = [
    {
      value: 'ya',
      label:'Iya'
    },
    {
      value: 'tidak',
      label:'Tidak'
    }
  ];

  var beban = [
    {
      kode: "6.1.01.0018",
      nama: "Beban Dinas",
      nominal: "10000000"
    }
  ];

  function addItem() {
    setDialogPembelianProdukForm(true)
    setDialogData({})
  }

  useEffect(() => {
    async function initData() {
      var pembelianForm = JSON.parse(localStorage.getItem("pembelianForm"))
      localStorage.setItem("pembelianForm", JSON.stringify({
        sptNpwp: "",
        sptMonth: "",
        sptYear: "",
        sptRev: "",
        signerName: "",
        signerNpwp: "",
        actAs: "",
        signerId: "",
        refs: [],
        address: "-",
        phone: "0",
        postalCode: "0",
        province: "-",
        city: "-",
        district: "-",
        subDistrict: "-",
        withoutIncome: true,
        bruto: "0",
        rates: "0",
        ratesValue: "0",
        ...pembelianForm
      }))
      setData({ content: {}, loading: false })
      // if(match.params.id == 'new') {
      //     var penjualanForm = JSON.parse(localStorage.getItem("penjualanForm"))
      //     localStorage.setItem("penjualanForm", JSON.stringify({
      //         sptNpwp: spt.npwp,
      //         sptMonth: spt.month + "",
      //         sptYear: spt.year + "",
      //         sptRev: spt.rev + "",
      //         signerName: getLawanTransaksi.name,
      //         signerNpwp: getLawanTransaksi.npwp,
      //         actAs: getLawanTransaksi.actAs + "",
      //         signerId: getLawanTransaksi.identity,
      //         refs: [],
      //         address: "-",
      //         phone: "0",
      //         postalCode: "0",
      //         province: "-",
      //         city: "-",
      //         district: "-",
      //         subDistrict: "-",
      //         withoutIncome: true,
      //         bruto: "0",
      //         rates: "0",
      //         ratesValue: "0",
      //         ...penjualanForm
      //     }))
      //     setData({ content: {}, loading: false })
      // } else {
      //     // alamat, telepon,propinsi,kota,kecamatan,kelurahan,dan kode post 
      //     const res = await service.getOne(match.params.id)
      //     res.data.signerId = res.data.identity
      //     res.data.bruto = res.data.bruto + ""
      //     res.data.pph   = res.data.pph + ""
      //     res.data.rates = res.data.rates + ""
      //     res.data.ratesValue = res.data.rates + ""
      //     res.data.identityAs = res.data.identityAs + ""
      //     res.data.actAs = res.data.signAs + ""
      //     res.data.signAggree = res.data.signAggree + ""
      //     res.data.form4valid = true
      //     res.data.form3valid = true
      //     res.data.form2valid = true
      //     res.data.form1valid = true
      //     localStorage.setItem("penjualanForm", JSON.stringify(res.data))
      //     setData({ content: {}, loading: false })
      // }
    }
    initData()
  }, [])

  return (
    <>
      <InputDetailAkunPembelian
        visible={dialogPembelianProdukForm}
        onRequestClose={() => {
          setDialogPembelianProdukForm(false)
          setDialogData(null)
        }}
        item={dialogData}
      />
      <InputDetailAkunBeban
        visible={dialogBebanForm}
        onRequestClose={() => {
          setDialogBebanForm(false)
          setDialogData(null)
        }}
        item={dialogData}
      />
      <FormWrapper
        submitLabel={t.translate('words.submit')}
        loading={data.loading}
        backTo={`/${envStore.env.applicationType}/pembelian`}
        title='Produk'
        showCommandbar={false}
        baseId={`mod-form-${baseName}`}
        style={{
          maxWidth: '100%'
        }}
        // defaultData={[]}
        definitions={[
          {
            render: (
              <div style={{ width: '100%' }}>
                <FormWrapper
                  loading={data.loading}
                  showCommandbar={false}
                  baseId={`mod-form-${baseName}`}
                  style={{
                    maxWidth: '100%'
                  }}
                  defaultData={data.content}
                  definitions={[
                    {
                      inputType: inputTypes.INPUT,
                      label: 'DPP',
                      key: 'dpp',
                      className: 'mpk-padding-N padding-right',
                      disabled: true,
                      width: '50%'
                    },
                    {
                      inputType: inputTypes.INPUT,
                      label: 'PPN',
                      key: 'ppn',
                      disabled: true,
                      width: '50%'
                    },
                    {
                      inputType: inputTypes.INPUT,
                      label: 'PPNBM',
                      key: 'ppnbm',
                      disabled: true,
                      width: '50%',
                      className: 'mpk-padding-N padding-right',
                    },
                    {
                      inputType: inputTypes.SELECT,
                      label: 'Apakah nilai DPP Pembelian diatas merupakan DPP nilai lain?',
                      key: 'konfirmasi',
                      width: '50%',
                      required: true,
                      options: konfirmasi.map(d => ({
                          label: d.label,
                          value: d.value
                      }))
                  }
                  ]}
                />
                <Hint
                  className="mpk-margin-N margin-top margin-bottom"
                  message="Daftar Nilai Pembelian"
                />

                <Button
                  theme="primary"
                  themeType="contained"
                  style={{ margin: 15 }}
                  onClick={() => {
                    console.log("Masuk")
                    setDialogPembelianProdukForm(true)
                    setDialogData({})
                  }}
                >
                  <FontIcon iconClassName="mdi mdi-plus" />{t.translate("words.add")}
                </Button>

                <Table style={{ width: '100%' }}>
                  <TableHeader>
                    <TableRow>
                      <TableCell style={{ width: '10%' }}>{t.translate('words.non')}</TableCell>
                      <TableCell style={{ width: '25%' }}>{t.translate('words.kode')}</TableCell>
                      <TableCell style={{ width: '25%' }}>{t.translate('words.nama')}</TableCell>
                      <TableCell style={{ width: '25%' }}>{t.translate('words.nominal')}</TableCell>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {nilaiPembelian.map((d, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{d.kode}</TableCell>
                          <TableCell>{d.nama}</TableCell>
                          <TableCell>{d.nominal}</TableCell>
                          <TableCell>
                            {/* <Button onClick={()=> console.log("Masuk")}>{t.translate("words.edit")}</Button> */}
                            <Button onClick={() => console.log("Masuk")}>{t.translate("words.delete")}</Button>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
                <FormWrapper
                  loading={data.loading}
                  showCommandbar={false}
                  baseId={`mod-form-${baseName}`}
                  style={{
                    maxWidth: '100%'
                  }}
                  defaultData={data.content}
                // definitions={[
                //   {
                //     inputType: inputTypes.INPUT,
                //     label: t.translate(`words.dpp`),
                //     key: 'dpp',
                //     type: 'text',
                //     disabled: true,
                //     width: '30%',
                //     value: "3000000",
                //     style: {
                //       marginRight: "3%"
                //     }
                //   },
                //   {
                //     inputType: inputTypes.INPUT,
                //     label: t.translate(`words.ppn`),
                //     key: 'ppn',
                //     type: 'text',
                //     disabled: true,
                //     width: '30%',
                //     value: "3000000",
                //     style: {
                //       marginRight: "3%"
                //     }
                //   },
                //   {
                //     inputType: inputTypes.INPUT,
                //     label: t.translate(`words.ppnBm`),
                //     key: 'ppnBm',
                //     type: 'text',
                //     disabled: true,
                //     width: '30%',
                //     value: "3000000",
                //     style: {
                //       marginRight: "3%"
                //     }
                //   },
                // ]}
                />

                <Hint
                  className="mpk-margin-N margin-top margin-bottom"
                  message="Daftar Pemotongan Pajak Ditanggung"
                />
                <Table style={{ width: '100%' }}>
                  <TableHeader>
                    <TableRow>
                      <TableCell style={{ width: '10%' }}>{t.translate('words.non')}</TableCell>
                      <TableCell style={{ width: '25%' }}>{t.translate('words.kode')}</TableCell>
                      <TableCell style={{ width: '25%' }}>{t.translate('words.nama')}</TableCell>
                      <TableCell style={{ width: '25%' }}>{t.translate('words.nominal')}</TableCell>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {pajakDitanggung.map((d, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{d.kode}</TableCell>
                          <TableCell>{d.nama}</TableCell>
                          <TableCell>{d.nominal}</TableCell>
                          <TableCell>
                            {/* <Button onClick={()=> console.log("Masuk")}>{t.translate("words.edit")}</Button> */}
                            <Button onClick={() => console.log("Masuk")}>{t.translate("words.delete")}</Button>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
                <FormWrapper
                  loading={data.loading}
                  showCommandbar={false}
                  baseId={`mod-form-${baseName}`}
                  style={{
                    maxWidth: '100%'
                  }}
                  defaultData={data.content}
                // definitions={[
                //   {
                //     inputType: inputTypes.INPUT,
                //     label: t.translate(`words.nilaiPenjualan`),
                //     key: 'nilaiPenjualan',
                //     type: 'text',
                //     disabled: true,
                //     width: '100%',
                //     value: "3000000",
                //   },
                // ]}
                />

                <Hint
                  className="mpk-margin-N margin-top margin-bottom"
                  message="Daftar Pemotongan Pajak"
                />
                <Table style={{ width: '100%' }}>
                  <TableHeader>
                    <TableRow>
                      <TableCell style={{ width: '10%' }}>{t.translate('words.non')}</TableCell>
                      <TableCell style={{ width: '25%' }}>{t.translate('words.kode')}</TableCell>
                      <TableCell style={{ width: '25%' }}>{t.translate('words.nama')}</TableCell>
                      <TableCell style={{ width: '25%' }}>{t.translate('words.nominal')}</TableCell>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {pemotonganPajak.map((d, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{d.kode}</TableCell>
                          <TableCell>{d.nama}</TableCell>
                          <TableCell>{d.nominal}</TableCell>
                          <TableCell>
                            {/* <Button onClick={()=> console.log("Masuk")}>{t.translate("words.edit")}</Button> */}
                            <Button onClick={() => console.log("Masuk")}>{t.translate("words.delete")}</Button>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
                <FormWrapper
                  loading={data.loading}
                  showCommandbar={false}
                  baseId={`mod-form-${baseName}`}
                  style={{
                    maxWidth: '100%'
                  }}
                  defaultData={data.content}
                // definitions={[
                //   {
                //     inputType: inputTypes.INPUT,
                //     label: t.translate(`words.pajakDisetorSendiri`),
                //     key: 'pajakDisetorSendiri',
                //     type: 'text',
                //     disabled: true,
                //     width: '100%',
                //     value: "3000000",
                //   },
                // ]}
                />

                <Hint
                  className="mpk-margin-N margin-top margin-bottom"
                  message="Daftar Beban/Pendapatan Lainnya"
                />
                {/* <Button theme="primary" themeType="contained" style={{ margin: 15 }} onClick={() => addItem()}><FontIcon iconClassName="mdi mdi-plus" />{t.translate("words.add")}</Button> */}

                <Button
                  theme="primary"
                  themeType="contained"
                  style={{ margin: 15 }}
                  onClick={() => {
                    console.log("Masuk")
                    setDialogBebanForm(true)
                    setDialogData({})
                  }}
                >
                  <FontIcon iconClassName="mdi mdi-plus" />{t.translate("words.add")}
                </Button>
                <Table style={{ width: '100%' }}>
                  <TableHeader>
                    <TableRow>
                      <TableCell style={{ width: '10%' }}>{t.translate('words.non')}</TableCell>
                      <TableCell style={{ width: '25%' }}>{t.translate('words.kode')}</TableCell>
                      <TableCell style={{ width: '25%' }}>{t.translate('words.nama')}</TableCell>
                      <TableCell style={{ width: '25%' }}>{t.translate('words.nominal')}</TableCell>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {beban.map((d, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{d.kode}</TableCell>
                          <TableCell>{d.nama}</TableCell>
                          <TableCell>{d.nominal}</TableCell>
                          <TableCell>
                            {/* <Button onClick={()=> console.log("Masuk")}>{t.translate("words.edit")}</Button> */}
                            <Button onClick={() => console.log("Masuk")}>{t.translate("words.delete")}</Button>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
                <FormWrapper
                  loading={data.loading}
                  showCommandbar={false}
                  baseId={`mod-form-${baseName}`}
                  style={{
                    maxWidth: '100%'
                  }}
                  defaultData={data.content}
                // definitions={[
                //   {
                //     inputType: inputTypes.INPUT,
                //     label: t.translate(`words.nilaiPenjualan`),
                //     key: 'nilaiPenjualan',
                //     type: 'text',
                //     disabled: true,
                //     width: '100%',
                //     value: "3000000",
                //   },
                // ]}
                />

                <Hint
                  className="mpk-margin-N margin-top margin-bottom"
                  message="Keterangan & Lampiran"
                />
                <FormWrapper
                  loading={data.loading}
                  showCommandbar={false}
                  baseId={`mod-form-${baseName}`}
                  style={{
                    maxWidth: '100%'
                  }}
                  defaultData={data.content}
                  definitions={[
                    {
                      inputType: inputTypes.FILE_INPUT,
                      label: t.translate('words.file'),
                      key: 'file',
                      type: 'file',
                      validation: 'required',
                      multiple: true,
                      multiline: true,
                      accept: '.csv'
                    },
                    {
                      inputType: inputTypes.INPUT,
                      label: t.translate(`words.keterangan`),
                      key: 'keterangan',
                      type: 'text',
                      value: "",
                      width: '100%'
                    },
                  ]}
                />
              </div>
            )
          },
          {
            render: (
              <div style={{ margin: 10 }}></div>
            )
          },
        ]}
        onSubmit={() => { window.location.href = `/${envStore.env.applicationType}/pembelian` }}
      />
    </>
  )
}

export default inject('envStore')(observer(PembelianPPNProduk))
