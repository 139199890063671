import React, { useEffect, useState } from "react";
import { DataForm } from "../../libs/react-mpk/components";
import t from "counterpart";
import { FormWrapper } from "../../libs/react-mpk/wrapper";

import { cloneDeep } from "lodash";

import service from "./Biaya.service";

const pemotongan = [
  { name: "PPH21", value: "pph21" },
  { name: "PPH22", value: "pph22" },
  { name: "PPH23", value: "pph23" },
  { name: "PPH15", value: "pph15" },
  { name: "PPH4(2)", value: "pph4a2" },
];

const atasTr = [{ name: "Omset", value: "P0001" }];

const perhitunganPajak = [
  { value: "Nilai Lain", name: "Nilai Lain" },
  { value: "Nilai Biaya", name: "Nilai Biaya" },
];

const pemotonganPajak = [
  { name: "Iya", value: 1 },
  { name: "Tidak", value: 2 },
];

const defaultData = () => ({
  akunId: "",
  akun: {
    id: null,
    kode: null,
    nama: null,
    kategoriAkunDebitKredit: null,
  },
  nilaiPembelian: 0,
  isPotongPajak: false,
  terdapatPemotonganPajak: 2,
  pasalPemotonganId: "PPh 4(2)",
  pasalPemotongan: {
    id: null,
  },
  objekPajakId: "P001 - Omset",
  objekPajak: {
    id: null,
  },
  ptsTarifPajak: 0.5,
  perhitunganPajak: "Nilai Pembelian",
  isNilaiPembelian: true,
  nominalHitungPajak: 0,
  isPajakDitanggung: false,
  nominalPajakDitanggung: 0,
  nominalPajak: 0,
  total: 0,
  keterangan: "",
});

const InputDetailAkunBiaya = ({
  visible = false,
  authStore,
  envStore,
  match,
  history,
  onRequestClose = () => {},
  ...props
}) => {
  const { inputTypes, definition } = FormWrapper;

  let [dataSubmit, setDataSubmit] = useState({
    id: null,
    akun: {
      id: null,
      kode: null,
      nama: null,
      kategoriAkunDebitKredit: null,
    },
    nilaiPembelian: 0,
    isPotongPajak: false,
    pasalPemotongan: {
      id: null,
    },
    objekPajak: {
      id: null,
    },
    ptsTarifPajak: 0,
    isNilaiPembelian: false,
    nominalHitungPajak: 0,
    isPajakDitanggung: false,
    nominalPajakDitanggung: 0,
    nominalPajak: 0,
    total: 0,
    keterangan: "",
  });
  let [item, setItem] = useState(null);
  let [ready, setReady] = useState(true);
  var thousandSeparator = ".";
  var decimalSeparator = ",";
  const [disabledAkunBiayaList, setDisabledAkunBiayaList] = useState(true);
  const [akunBiayaList, setAkunBiayaList] = useState([]);
  const [pasalPemotonganList, setPasalPemotonganList] = useState([]);
  const [objekPajakList, setObjekPajakList] = useState([]);

  useEffect(() => {
    initData();
  }, []);

  async function initData() {
    let selectedItem = defaultData();
    setItem(selectedItem);
    getListAkunBiaya();
    getListPasalPemotongan();
    getListObjekPajak();
    setReady(true);
  }

  async function getListAkunBiaya() {
	setDisabledAkunBiayaList(true)
      let res = await service.getListAkunBiaya();
      var akunBiayas = [];
      res.data.map((d) => {
        akunBiayas.push({
          value: d.id,
          label: `${d.kode} - ${d.nama}`,
          kode: `${d.kode}`,
          nama: `${d.nama}`,
          kategori: {
            debitKredit: `${d.kategoriAkun.debitKredit}`,
          },
        });
      });
      setAkunBiayaList(akunBiayas);
	  setDisabledAkunBiayaList(false)
  }

  async function getListPasalPemotongan() {
    setTimeout(async () => {
      let res = await service.getListPasalPemotongan();
      var pasalPemotongans = [];
      res.data.map((d) => {
        pasalPemotongans.push({
          value: d.id,
          label: `${d.nama}`,
        });
      });
      setPasalPemotonganList(pasalPemotongans);
    }, 500);
  }

  async function getListObjekPajak() {
    setTimeout(async () => {
      let res = await service.getListObjekPajak();
      var objekPajaks = [];
      res.data.map((d) => {
        objekPajaks.push({
          value: d.id,
          label: `${d.kode} - ${d.nama}`,
        });
      });
      setObjekPajakList(objekPajaks);
    }, 500);
  }

  const onSubmit = async (data, callback) => {
    if (data.nilaiPembelian == 0) {
      callback("Nilai Pembelian Tidak Boleh 0 ", true, false);
    } else {
      let reqData = dataSubmit;
      //ISI FIELD
      reqData.akun.kategoriAkunDebitKredit = parseInt(
        data.akun.kategoriAkunDebitKredit
      );
      reqData.akun = data.akun;
      reqData.nilaiPembelian = data.nilaiPembelian;
      reqData.isPotongPajak = data.terdapatPemotonganPajak == 1 ? true : false;
      pasalPemotonganList.map((d) => {
        if (d.label == data.pasalPemotonganId) {
          reqData.pasalPemotongan.id = d.value;
        }
      });
      objekPajakList.map((d) => {
        if (d.label == data.objekPajakId) {
          reqData.objekPajak.id = d.value;
        }
      });
      reqData.ptsTarifPajak = data.ptsTarifPajak;
      reqData.isNilaiPembelian =
        data.perhitunganPajak == "Nilai Pembelian" ? true : false;
      reqData.nominalHitungPajak = data.nominalHitungPajak;
      reqData.isPajakDitanggung = data.isPajakDitanggung;
      reqData.nominalPajakDitanggung = data.nominalPajakDitanggung;
      reqData.nominalPajak = data.nominalPajak;
      reqData.total = data.nilaiTagihan;
      reqData.keterangan = data.keterangan;

      // console.log(reqData)
      // console.log(data)

      localStorage.setItem("akunBiayaForm", JSON.stringify(reqData));
      callback("success", false);
      // callback('error', true, false);
    }
  };

  return (
    ready && (
      <DataForm
        baseId="akun-npwp-form"
        title={t.translate(`modules.biaya.inputAkunBiaya`)}
        visible={visible}
        // data={item}
        onRequestClose={onRequestClose}
        asDialog={true}
        defaultData={item ? cloneDeep(item) : defaultData()}
        definitions={[
          {
            inputType: inputTypes.AUTOCOMPLETE,
            label: t.translate(`words.akun`),
            key: "akunId",
            validation: "required",
            labelKey: "label",
            valueKey: "label",
            type: "search",
            data: akunBiayaList,
            required: true,
			disabled:disabledAkunBiayaList
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            label: "Nilai Pembelian",
            key: "nilaiPembelian",
            thousandSeparator: thousandSeparator,
            decimalSeparator: decimalSeparator,
            type: "text",
            width: "100%",
            required: true,
          },
          {
            inputType: inputTypes.DIVIDER,
            label: "Pemotongan Pajak",
          },
          {
            inputType: inputTypes.SELECT,
            label: "Terdapat Pemotongan Pajak?",
            key: "terdapatPemotonganPajak",
            options: pemotonganPajak.map((d) => ({
              label: d.name,
              value: d.value,
            })),
            required: true,
          },
          {
            inputType: inputTypes.AUTOCOMPLETE,
            label: "Atas Transaksi",
            key: "objekPajakId",
            validation: "required",
            labelKey: "label",
            valueKey: "label",
            type: "search",
            data: objekPajakList,
            show: (d) => {
              return d.terdapatPemotonganPajak === 1;
            },
            disabled: true,
          },
          {
            inputType: inputTypes.AUTOCOMPLETE,
            label: "Terdapat Pemotongan Pajak?",
            key: "pasalPemotonganId",
            validation: "required",
            labelKey: "label",
            valueKey: "label",
            type: "search",
            data: pasalPemotonganList,
            show: (d) => {
              return d.terdapatPemotonganPajak === 1;
            },
            disabled: true,
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            className: "mpk-padding-N padding-right",
            label: "Tarif Pajak (%)",
            key: "ptsTarifPajak",
            disabled: true,
            show: (d) => {
              return d.terdapatPemotonganPajak === 1;
            },
          },
          {
            render: (
              <div style={{ width: "100%" }}>
                <p style={{ margin: "10px" }}>
                  <b>Perhitungan Pajak Berdasarkan</b>
                </p>
              </div>
            ),
            show: (d) => {
              return d.terdapatPemotonganPajak === 1;
            },
            required: true,
          },
          {
            inputType: inputTypes.SELECT,
            label: "Perhitungan Pajak",
            className: "mpk-padding-N padding-right",
            key: "perhitunganPajak",
            options: perhitunganPajak.map((d) => ({
              label: d.name,
              value: d.value,
            })),
            width: "50%",
            show: (d) => {
              return d.terdapatPemotonganPajak === 1;
            },
            required: true,
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            label: "Nominal Hitung Pajak",
            key: "nominalHitungPajak",
            thousandSeparator: thousandSeparator,
            decimalSeparator: decimalSeparator,
            width: "50%",
            type: "text",
            show: (d) => {
              return (
                d.perhitunganPajak == "Nilai Lain" &&
                d.terdapatPemotonganPajak == 1
              );
            },
          },
          {
            inputType: inputTypes.CHECKBOX,
            label: "Pajak ditanggung",
            value: false,
            key: "isPajakDitanggung",
            show: (d) => {
              return d.terdapatPemotonganPajak == 1;
            },
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            label: "Pajak ditaggung",
            key: "nominalPajakDitanggung",
            thousandSeparator: thousandSeparator,
            decimalSeparator: decimalSeparator,
            disabled: true,
            show: (d) => {
              return d.terdapatPemotonganPajak == 1;
            },
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            label: "Nominal Pajak",
            className: "mpk-padding-N padding-right",
            key: "nominalPajak",
            thousandSeparator: thousandSeparator,
            decimalSeparator: decimalSeparator,
            width: "50%",
            disabled: true,
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            label: "Nilai Tagihan",
            key: "nilaiTagihan",
            thousandSeparator: thousandSeparator,
            decimalSeparator: decimalSeparator,
            width: "50%",
            disabled: true,
          },
          {
            render: (
              <div style={{ width: "100%" }}>
                <p style={{ margin: "10px" }}>
                  <b>Informasi Tambahan</b>
                </p>
              </div>
            ),
          },
          {
            inputType: inputTypes.TEXTAREA,
            label: "Keterangan",
            key: "keterangan",
          },
        ]}
        onChange={async (formData, key, value) => {
          if (key == "akunId") {
            akunBiayaList.map((d) => {
              if (d.label == value) {
                formData["akun"]["id"] = d.value;
                formData["akun"]["kode"] = d.kode;
                formData["akun"]["nama"] = d.nama;
                formData["akun"]["kategoriAkunDebitKredit"] = parseInt(
                  d.kategori.debitKredit
                );
              }
            });
          }

          if (key == "pasalPemotonganId") {
            pasalPemotonganList.map((d) => {
              if (d.label == value) {
                formData["pasalPemotongan"]["id"] = d.value;
              }
            });
          }

          if (key == "objekPajakId") {
            objekPajakList.map((d) => {
              if (d.label == value) {
                formData["objekPajak"]["id"] = d.value;
              }
            });
          }

          if (formData["terdapatPemotonganPajak"] == 1) {
            if (formData["perhitunganPajak"] == "Nilai Lain") {
              formData["nominalPajak"] =
                formData["nominalHitungPajak"] * (0.5 / 100);
              // formData['nilaiTagihan'] = formData['nilaiLainField']
            } else {
              formData["nominalHitungPajak"] = formData["nilaiPembelian"];
              formData["nominalPajak"] =
                formData["nominalHitungPajak"] * (0.5 / 100);
              // formData['nilaiTagihan'] = formData['nilaiPembelian']
              // formData['nominalPajak'] = formData['nilaiPembelian'] * (formData['tarifPajak'] / 100)
            }

            // PAJAK DITANGGUNG
            if (formData["isPajakDitanggung"]) {
              formData["nominalPajakDitanggung"] = formData["nominalPajak"];
              formData["nilaiTagihan"] = formData["nilaiPembelian"];
            } else {
              formData["nominalPajakDitanggung"] = 0;
              formData["nilaiTagihan"] =
                formData["nilaiPembelian"] - formData["nominalPajak"];
            }
          } else {
            formData["nominalPajak"] = 0;
            formData["nilaiTagihan"] = formData["nilaiPembelian"];
          }
          // return value
        }}
        // onBeforeChange={(key, value) => {
        // 	console.log(key, value)
        // 	return value
        // }}
        onSubmit={onSubmit}
      />
    )
  );
};

export default InputDetailAkunBiaya;
