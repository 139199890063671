import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import service from "./Biaya.service";
import { inputTypes } from "../../libs/react-mpk/config/constant";
import t from "counterpart";
import { FormWrapper } from "../../libs/react-mpk/wrapper";
import {
  Button,
  Divider,
  ExpansionList,
  ExpansionPanel,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Text,
  TextContainer,
  l,
  TableContainer,
} from "react-md";
import { defaultDataPembelianDetail } from "../defaultData";
import moment from "moment";
import 'moment/locale/id';
import { format, formatRupiah } from "../../libs/react-mpk/services/number.service";
import BiayaDetailList from "./BiayaDetailList";
import utilsService from "../../services/utilsService";
import PreviewFile from "../PreviewFile/PreviewFile";
import { ErrorPage } from "../../libs/react-mpk/components";

const BiayaDetail = ({ envStore, match, ...props }) => {
  let noInvoice = sessionStorage.getItem("noInvoiceBiaya");
  var backTo = `/internal/npwp/${match.params.npwpHeadId}/biaya`;
  const baseName = `biaya`;
  const [item, setItem] = useState(defaultDataPembelianDetail);
  const [listNilaiPembelian, setListNilaiPembelian] = useState([]);
  const [subtotal, setSubtotal] = useState([]);
  const [panel1, setPanel1] = useState(true);
  const [panel2, setPanel2] = useState(false);
  const [panel3, setPanel3] = useState(false);
  const [loadingAll, setLoadingAll] = useState(true);
  const [loading, setLoading] = useState(true);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [loadingDetailList, setLoadingDetailList] = useState(false);
  let [file, setFile] = useState(null);
  let [type, setType] = useState(null);
  const [showDialog, setDialog] = useState(false);

  const fetchData = async () => {
    try {
      setLoadingDetail(true)
      let res = await service
        .getOneByNoTransaksi(noInvoice == null ? match.params.id : noInvoice)
        .catch((e) => service.getOneById(match.params.npwpHeadId));
      setItem(res.data);
      setSubtotal(res.data.total);
      getDataListNilaiPembelian(res.data.id);
      setLoadingDetail(false)
    } catch (error) {
      setLoadingDetail(false)
    }
  };

  const getDataListNilaiPembelian = async (id) => {
    try {
      setLoadingDetailList(true)
      let res = await service.getListAkunBiayaById(id);
      setListNilaiPembelian(res.data);
      setLoadingDetailList(false)
    } catch (error) {
      setLoadingDetailList(false)
    }
  };

  useEffect(() => {
    setLoading(true)
    fetchData();
    setLoading(false)
  }, []);

  useEffect(() => {
setLoadingAll(loading||loadingDetail||loadingDetailList)
  }, [loading,loadingDetail,loadingDetailList])
  

  const activePanel1 = () => {
    setPanel1(true);
    setPanel2(false);
    setPanel3(false);
  };
  const activePanel2 = () => {
    setPanel1(false);
    setPanel2(true);
    setPanel3(false);
  };
  const activePanel3 = () => {
    setPanel1(false);
    setPanel2(false);
    setPanel3(true);
  };

  return (
    <>
      <FormWrapper
        showCommandbar={true}
        baseId={`mod-form-${baseName}`}
        loading={loadingAll}
        title={
          `Detail Biaya || ` +
          utilsService.beautifyNoTransaksi(noInvoice || match.params.id)
        }
        style={{
          maxWidth: "100%",
        }}
        defaultData={[]}
        definitions={[
          {
            render: (
              <ExpansionList style={{ width: "100%" }}>
                <ExpansionPanel
                  expanded={panel1}
                  onClick={() => activePanel1()}
                  header={t.translate("words.dataLawanTransaksi")}
                >
                  <FormWrapper
                  loading={loadingAll}
                    submitLabel={t.translate("words.next")}
                    showCommandbar={false}
                    baseId={`mod-form-${baseName}`}
                    style={{
                      maxWidth: "100%",
                    }}
                    defaultData={item.lawanTransaksi}
                    definitions={[
                      {
                        render: (
                          <div style={{ width: "100%", marginBottom: 30 }}>
                            <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                              {t.translate(`words.dataLawanTransaksi`)}
                              <Divider />
                            </Text>
                          </div>
                        ),
                      },
                      {
                        inputType: inputTypes.INPUT,
                        label: t.translate(`words.name`),
                        key: "nama",
                        disabled: true,
                        type: "text",
                        width: "100%",
                      },
                      {
                        inputType: inputTypes.INPUT_MASK_NUMBER,
                        label: t.translate(`words.npwp`),
                        key: "npwp",
                        mask: "##.###.###.#-###.###",
                        maskChar: "_",
                        maskValue: "string",
                        disabled: true,
                        width: "49%",
                        style: {
                          marginRight: "2%",
                        },
                      },
                      {
                        inputType: inputTypes.INPUT,
                        label: t.translate(`words.nikPaspor`),
                        key: "nik",
                        mask: "################",
                        maskChar: "_",
                        maskValue: "string",
                        disabled: true,
                        width: "49%",
                        show: item.lawanTransaksi.nik != "",
                      },
                      {
                        inputType: inputTypes.INPUT,
                        label: t.translate(`words.address`),
                        key: "alamat",
                        disabled: true,
                        type: "text",
                        width: "100%",
                      },
                    ]}
                  />
                </ExpansionPanel>
                <br />
                <ExpansionPanel
                  expanded={panel2}
                  onClick={() => activePanel2()}
                  header={t.translate("words.transaksi")}
                >
                  <FormWrapper
                  loading={loadingAll}
                    showCommandbar={false}
                    baseId={`mod-form-${baseName}`}
                    style={{
                      maxWidth: "100%",
                    }}
                    defaultData={item}
                    definitions={[
                      {
                        render: (
                          <div style={{ width: "100%", marginBottom: 30 }}>
                            <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                              {t.translate(`column.general`)}
                              <Divider />
                            </Text>
                          </div>
                        ),
                      },
                      {
                        inputType: inputTypes.INPUT,
                        label: t.translate(`words.status`),
                        disabled: true,
                        type: "text",
                        value:
                          item.pembayaran.status == 1
                            ? "Belum Bayar"
                            : item.pembayaran.status == 2
                            ? "Bayar Sebagian"
                            : item.pembayaran.status == 3
                            ? "Lunas"
                            : "Batal",
                        width: "49%",
                        style: {
                          marginRight: "2%",
                        },
                      },
                      {
                        inputType: inputTypes.INPUT,
                        label: "Status Pelunasan",
                        disabled: true,
                        type: "text",
                        value: "Normal",
                        width: "49%",
                      },
                      {
                        render: (
                          <div style={{ width: "100%", marginBottom: 30 }}>
                            <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                            Data Biaya
                              <Divider />
                            </Text>
                          </div>
                        ),
                      },
                      {
                        inputType: inputTypes.INPUT,
                        label: t.translate(`words.noTransaksi`),
                        value: utilsService.beautifyNoTransaksi(
                          noInvoice || match.params.id
                        ),
                        disabled: true,
                        key: "noTransaksi",
                        type: "text",
                        width: "49%",
                        style: {
                          marginRight: "2%",
                        },
                      },
                      {
                        inputType: inputTypes.INPUT,
                        label: t.translate(`words.tanggalTransaksi`),
                        disabled: true,
                        type: "text",
                        value: moment(item.tanggalTransaksi).locale('id').format(
                          "DD MMMM YYYY"
                        ),
                        width: "49%",
                      },
                      {
                        inputType: inputTypes.INPUT,
                        label: t.translate(`words.transferDari`),
                        value: `${item.pembayaran.akun?.kode} - ${item.pembayaran.akun?.nama}`,
                        disabled: true,
                        type: "text",
                        show: item.pembayaran.akun === null ? false : true,
                      },
                      {
                        inputType: inputTypes.INPUT,
                        label: t.translate(`words.akunUtang`),
                        value: `${item.akunUtang?.kode} - ${item.akunUtang?.nama}`,
                        disabled: true,
                        type: "text",
                        show: item.akunUtang === null ? false : true,
                      },
                      {
                        inputType: inputTypes.INPUT_MASK_NUMBER,
                        label: "Periode Jatuh Tempo",
                        disabled: true,
                        value: `${item.periodeJatuhTempo} Hari`,
                        maskValue: "string",
                        width: "49%",
                        style: {
                          marginRight: "2%",
                        },
                        show: item.periodeJatuhTempo != 0,
                      },
                      {
                        inputType: inputTypes.INPUT,
                        label: t.translate(`words.tanggalJatuhTempo`),
                        disabled: true,
                        type: "text",
                        value: moment(item.tanggalJatuhTempo).locale('id').format(
                          "DD MMMM YYYY, hh:mm"
                        ),
                        width: "49%",
                        show: item.tanggalJatuhTempo === null ? false : true,
                      },
                    ]}
                  />
                </ExpansionPanel>
                <br />
                <ExpansionPanel
                  expanded={panel3}
                  onClick={() => activePanel3()}
                  header={t.translate("words.biaya")}
                >
                  <FormWrapper
                  loading={loadingAll}
                    showCommandbar={false}
                    baseId={`mod-form-${baseName}`}
                    style={{
                      maxWidth: "100%",
                    }}
                    defaultData={item}
                    definitions={[
                      {
                        render: (
                          <div style={{ width: "100%" }}>
                            <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                              Nilai Biaya
                              <Divider />
                            </Text>
                            {listNilaiPembelian.length > 0 ? (
                              <>
                                <TableContainer style={{ maxHeight: "30vh" }}>
                                  <Table fullWidth={true}>
                                    <TableHeader>
                                      <TableRow>
                                        <TableCell style={{ width: "10%" }}>
                                          {t.translate("words.non")}
                                        </TableCell>
                                        <TableCell style={{ width: "30%" }}>
                                          {t.translate("words.kode")}
                                        </TableCell>
                                        <TableCell style={{ width: "30%" }}>
                                          {t.translate("words.akun")}
                                        </TableCell>
                                        <TableCell style={{ width: "30%" }}>
                                          {t.translate("words.nominal")}
                                        </TableCell>
                                        <TableCell style={{ width: "30%" }}>
                                          {t.translate("words.keterangan")}
                                        </TableCell>
                                      </TableRow>
                                    </TableHeader>
                                    <TableBody>
                                      {listNilaiPembelian.map((d, index) => {
                                        return (
                                          <TableRow key={index}>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>{d.akun.kode}</TableCell>
                                            <TableCell>{d.akun.nama}</TableCell>
                                            <TableCell>
                                               {formatRupiah(d.nilaiPembelian)}
                                            </TableCell>
                                            <TableCell>
                                              {d.keterangan || " - "}
                                            </TableCell>
                                            {/* <TableCell>
                                            <Button
                                              style={{
                                                backgroundColor: "#34495e",
                                                color: "white",
                                              }}
                                              onClick={() => {
                                                setDialogDetail(true);
                                                setDialogData(d);
                                                setDialogJudul(
                                                  "Nilai Biaya (Sebelum Pajak)"
                                                );
                                              }}
                                            >
                                              Detail
                                            </Button>
                                          </TableCell> */}
                                          </TableRow>
                                        );
                                      })}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    width: "100%",
                                    marginBottom: 50,
                                    marginTop: 30,
                                    backgroundColor: "#ecf0f1",
                                    padding: 5,
                                    borderRadius: 50,
                                  }}
                                >
                                  <TextContainer
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      flex: 1,
                                      padding: 5,
                                    }}
                                  >
                                    <Text style={{ fontSize: 16 }}>
                                      Total : <b>{formatRupiah(subtotal)}</b>
                                    </Text>
                                  </TextContainer>
                                </div>
                              </>
                            ) : (
                             
                              <TextContainer style={{ textAlign: "center" }}>
                                <Text
                                  type="headline-6"
                                  style={{ color: "#7f8c8d" }}
                                >
                                  Tidak ada data
                                </Text>
                              </TextContainer>
                            )}
                            {(item.keterangan != null ||
                              item.fileLampiran != null) && (
                              <Text
                                style={{ fontSize: 20, fontWeight: "bold" }}
                              >
                                Keterangan & Lampiran
                                <Divider style={{ marginBottom: 20 }} />
                              </Text>
                            )}
                          </div>
                        ),
                      },
                      {
                        inputType: inputTypes.INPUT,
                        label: t.translate(`words.keterangan`),
                        key: "keterangan",
                        type: "text",
                        width: "100%",
                        disabled: true,
                        show: () => {
                          return item.keterangan != null;
                        },
                      },
                      {
                        inputType: inputTypes.INPUT,
                        label: t.translate(`words.namaFile`),
                        key: "fileLampiran",
                        type: "text",
                        width: "70%",
                        disabled: true,
                        show: () => {
                          return item.fileLampiran != null;
                        },
                      },
                      {
                        render: (
                          <Button
                            theme="primary"
                            themeType="contained"
                            style={{ margin: 10 }}
                            onClick={async () => {
                              await service
                                .DownloadLampiranBiaya(item.id)
                                .then((response) => {
                                  const type = response.headers["content-type"];
                                  let blob = new Blob([response.data], {
                                      type: type,
                                    }),
                                    datas = window.URL.createObjectURL(blob);
                                  setFile(datas);
                                });
                              if (item.fileLampiran.includes(".pdf")) {
                                setType("pdf");
                              } else {
                                setType("image");
                              }
                              setDialog(true);
                            }}
                          >
                            Lihat Lampiran
                          </Button>
                        ),
                        show: () => {
                          return item.fileLampiran != null;
                        },
                      },
                    ]}
                  />
                </ExpansionPanel>
                {/* <BiayaDetailList
                  visible={dialogDetail}
                  onRequestClose={() => {
                    setDialogDetail(false);
                    setDialogData(null);
                  }}
                  item={dialogData}
                  judul={dialogJudul}
                /> */}
                <PreviewFile
                  visible={showDialog}
                  onRequestClose={() => {
                    setDialog(false);
                  }}
                  onClickDownload={() => {
                    service.getDownloadLampiranById(item.id);
                  }}
                  file={file}
                  type={type}
                />
              </ExpansionList>
            ),
          },
        ]}
      />
    </>
  );
};

export default inject("envStore")(observer(BiayaDetail));
