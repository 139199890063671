import React, { useEffect, useState } from 'react'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import { FormWrapper, TabsWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { find } from 'lodash'
import service from './Biaya.service'
import { useParams } from 'react-router'
import BiayaDetailTransaksi from './BiayaDetailTransaksi'
import BiayaDetailProduk from './BiayaDetailProduk'
import { toast } from '../../libs/react-mpk/services'

const lawanTr = [
  { id: '90.123.123.1-100.101', name: 'Yopie Latul', nik: '3216082606000006', adress: 'Kemanggisan' },
  { id: '90.123.123.1-100.102', name: 'Daniel Salueka', nik: '3216082310990006', adress: 'Tebet' },
  { id: '90.123.123.1-100.103', name: 'Mona Latumahina', nik: '3216081207000006', adress: 'Senayan' },
]

const BiayaDetailIdentitas = ({ envStore, route }) => {
  const { inputTypes, definition } = FormWrapper
  const [ data, setData ] = useState([])
  const { id } = useParams()

  const fetch = async () => {
    try {
      let res = await service.getById(id)
      setData(res.data)
    } catch (error) {
      toast.errorRequest(error)
    }
  }

  useEffect(() => {
    fetch()
  }, [])

  return (
    <TabsWrapper
      label='Pembelian Non PPN'
      baseId='mod-form-pembelian-non-ppn'
      tabs={[
        {
          label: 'Identitas',
          key: 'identitasNonPPN',
          render: (
            <FormWrapper
              actions={[
                {
                  label: 'Reload',
                  iconClassName: 'mdi mdi-reload'
                }
              ]}
              title='Identitas'
              backTo={`/${envStore.env.applicationType}/pembelian`}
              baseId="mod-form-non-ppn-identitas"
              defaultData={data}
              definitions={[
                {
                  inputType: inputTypes.DIVIDER,
                  label: "Data Lawan Transaksi"
                },
                {
                  inputType: inputTypes.INPUT,
                  className: 'mpk-padding-N padding-right',
                  label: 'Nama',
                  key: 'namaNpwp',
                  required: true,
                  width: '50%',
                  disabled: true
                },
                {
                  inputType: inputTypes.INPUT,
                  label: 'NIK/Paspor',
                  key: 'identity',
                  required: true,
                  width: '50%',
                  disabled: true

                },
                // -----
                {
                  inputType: inputTypes.INPUT,
                  className: 'mpk-padding-N padding-right',
                  label: 'NPWP',
                  key: 'npwp',
                  width: '50%',
                  required: true,
                  disabled: true
                },
                {
                  inputType: inputTypes.INPUT,
                  label: 'Alamat',
                  key: 'alamat',
                  width: '50%',
                  required: true,
                  disabled: true
                }
              ]}
              onChange={(data, key, value) => {
                if (key === 'lawanTransaksiId') {
                  data.lawanTransaksi = find(lawanTr, { id: data.lawanTransaksiId })
                  data.identity = data.lawanTransaksi.nik
                  data.npwp = data.lawanTransaksi.id
                  data.name = data.lawanTransaksi.name
                  data.alamat = data.lawanTransaksi.adress
                }
                return data
              }}
            />
          )
        },
        {
          label: 'Transaksi',
          key: 'transaksiNonPPN',
          render: (
            <BiayaDetailTransaksi
              showCommandBar={false}
            />
          )
        },
        {
          label: 'Produk',
          key: 'produkNonPPN',
          render: (
            <BiayaDetailProduk
              showCommandBar={false}
            />
          )
        }
      ]}
    >



    </TabsWrapper>
  )
}

export default inject('envStore')(observer(BiayaDetailIdentitas))
