import React from 'react'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { DataForm } from '../../../libs/react-mpk/components'
import { Button } from '@react-md/button'
const KasDanBankPilihJenisPengeluaran = ({
    visible = false,
    navigationStore,
    onRequestClose = () => { },
}) => {
    return (
        <DataForm
			baseId="kas-dan-bank-pilih-jenis-pengeluaran"
			title={t.translate(`modules.KasDanBank.jenisPembelian`)}
			visible={visible}
			onRequestClose={onRequestClose}
			asDialog={true}
            style={{
				maxWidth: '100%'
			}}
			defaultData={[]}
			definitions={[
            {
                inputType:inputTypes.SELECT,
                label:t.translate(`words.sobatMelakukanPembayaranAkun`),
                key: 'sobatMelakukanPembayaran',
                options:[
                    {label:"Kas", value:'1.1.01.0001'},
                    {label:"Bank", value:'1.1.02.0001'}
                ],
            },
            {
                inputType:inputTypes.DATEPICKER,
                label: t.translate(`words.sobatMelakukanPemabayaranTgl`),
                searchable: true,
                key:'sobatMelakukanPemabayaranTgl',
            },
            {
                inputType:inputTypes.DIVIDER,
                label: t.translate(`words.jenisPembelian`)
            },
            {
                inputType:inputTypes.SELECT,
                label: t.translate(`words.jenisPembelian`),
                key: 'jenisPembelian',
                options:[
                    {label:t.translate(`words.pembelianBarangDagang`), value:"pembelianBarangDagang"},
                    {label:t.translate(`words.biaya`), value:"biaya"}
                ],
                required:true
            },
        ]}
        onChange={async(formData, key, value)=>{
            // if(formData.jenisPembelian==="pembelianBarangDagang")
            // {
            //     navigationStore.redirectTo(
            //         `/internal/pembelian`
            //     )
            // }
            // else if(formData.jenisPembelian==="biaya")
            // {
            //     navigationStore.redirectTo(
            //         `/internal/biaya`
            //     )
            // }
        }}
        onSubmit={(data, callback) => {
            if(data.jenisPembelian==="pembelianBarangDagang")
            {
                navigationStore.redirectTo(
                    `/internal/pembelian`
                )
            }
            else if(data.jenisPembelian==="biaya")
            {
                navigationStore.redirectTo(
                    `/internal/biaya`
                )
            }

            setTimeout(callback, 2000)
        }}
        />
    )
}

export default inject("navigationStore",'envStore')(observer(KasDanBankPilihJenisPengeluaran))