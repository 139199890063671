import React, { useEffect, useState } from "react";
import { DataForm } from "../../libs/react-mpk/components";
import t from "counterpart";
import { FormWrapper, TableWrapper } from "../../libs/react-mpk/wrapper";
import service from "./PPh.service";
import { inject, observer } from "mobx-react";
import iziToast from "izitoast";
import { toast } from "../../libs/react-mpk/services";

const IDBillingForm = ({
  visible = false,
  authStore,
  envStore,
  match,
  navigationStore,
  history,
  onRequestClose = () => {},
  ...props
}) => {
  let [item, setItem] = useState({
    npwp: null,
    nama: null,
    alamat: null,
    kota: null,
    kodeJenisPajak: "",
    kodeJenisSetoran: "",
    masa: "1",
    tahun: "",
    jumlahSetor: 0,
    uraian: "",
    nop: "",
    noSk: "",
    setorNpwpLain: 1,
    npwpDisetor: "",
    namaDisetor: "",
    alamatDisetor: "",
    kotaDisetor: "",
    uraian: "",
  });
  let [ready, setReady] = useState(true);
  const [jenisPajakList, setJenisPajakList] = useState([]);
  let [queryPajak, setQueryPajak] = useState("");
  const [loadingPajak, setLoadingPajak] = useState(false);
  const [loadingSetoran, setLoadingSetoran] = useState(false);
  const [jenisSetoranList, setJenisSetoranList] = useState([]);
  const [isNop, setIsNop] = useState(false);
  const [isNoSk, setIsNoSk] = useState(false);
  const [isSetorNpwpLain, setIsSetorNpwpLain] = useState(false);
  const [isSetorNpwpLainshow, setIsSetorNpwpLainshow] = useState(false);
  let [dataSubmit, setDataSubmit] = useState({
    npwpPenyetor: "",
    namaPenyetor: "",
    alamatPenyetor: "",
    kotaPenyetor: "",
    kodeJenisPajak: "",
    kodeJenisSetoran: "",

    masaAwal: "",
    masaAkhir: "",
    tahun: new Date().getFullYear(),
    jumlahSetor: 0,
    nop: "",
    noSk: "",
    setorNpwpLain: 1,
    npwpDisetor: "",
    namaDisetor: "",
    alamatDisetor: "",
    kotaDisetor: "",
    uraian: "",
  });
  const [inputValidasiNpwp, setInputValidasiNpwp] = useState(
    "##.###.###.#-###.###"
  );
  const baseName = `detail`;
  const baseId = `mod-form-${baseName}`;
  const dataPenggunaNpwp = JSON.parse(
    sessionStorage.getItem("dataPenggunaNpwp")
  );
  const Pphmasa = sessionStorage.getItem("Pphmasa");
  const tahun = sessionStorage.getItem("tahunSobatBuku");
  const npwpHeadId = sessionStorage.getItem("npwpHeadId");
  const idPph = sessionStorage.getItem("idPph");
  var TotalPphFinal = sessionStorage.getItem("totalPphFinal");
  useEffect(() => {
    if (visible) {
      let selectedItem = item;
      selectedItem.npwp = dataPenggunaNpwp.npwp;
      selectedItem.nama = dataPenggunaNpwp.namaNpwp.substring(0, 30);
      selectedItem.alamat = dataPenggunaNpwp.alamat.substring(0, 50);
      selectedItem.kota =
        dataPenggunaNpwp.area.id != 1 ? dataPenggunaNpwp.area.kabupaten : "";
      selectedItem.tahun = tahun;
      selectedItem.masa = Pphmasa;
      selectedItem.kodeJenisPajak = "411128";
      selectedItem.kodeJenisSetoran = "420";
      selectedItem.jumlahSetor = TotalPphFinal;
      setItem(selectedItem);
      getJenisSetoran("411128");
    } else {
      setItem(item);
    }
    setReady(visible);
  }, [visible]);
  useEffect(() => {
    setLoadingPajak(true);
    const timeOutId = setTimeout(() => getJenisPajak(queryPajak), 500);
    return () => clearTimeout(timeOutId);
  }, [queryPajak]);
  async function getJenisPajak(queryNama) {
    try {
      setLoadingPajak(true);
      let res = await service.getJenisPajak(queryNama);
      var kategoris = [];
      res.data.map((d) => {
        kategoris.push({
          value: d.kode,
          label: `${d.kode} - ${d.nama}`,
          data: d,
        });
      });
      setJenisPajakList(kategoris);
      setLoadingPajak(false);
    } catch (error) {
      toast.errorRequest(error);
      setLoadingPajak(false);
    }
  }
  async function getJenisSetoran(kodeJenisPajak) {
    try {
      setLoadingSetoran(true);
      let res = await service.getJenisSetoran(kodeJenisPajak);
      var kategoris = [];
      res.data.map((d) => {
        kategoris.push({
          value: d.kode,
          label: `${d.kode} - ${d.nama}`,
          data: d,
        });
      });
      setJenisSetoranList(kategoris);
      setLoadingSetoran(false);
    } catch (error) {
      toast.errorRequest(error);
      setLoadingSetoran(false);
    }
  }

  const submitForm = async (data, callback) => {
    if (data.jumlahSetor == 0) {
      callback("Jumlah Setor Tidak Boleh 0 (Nol)", true, false);
    } else {
      let submit = dataSubmit;
      submit.npwpPenyetor = data.npwp;
      submit.namaPenyetor = data.nama;
      submit.alamatPenyetor =
        data.alamat.length >= 50 ? data.alamat.substring(0, 50) : data.alamat;
      submit.kotaPenyetor = data.kota;
      submit.kodeJenisPajak = data.kodeJenisPajak;
      submit.kodeJenisSetoran = data.kodeJenisSetoran;

      submit.masaAwal = data.masa;
      submit.masaAkhir = data.masa;
      submit.tahun = data.tahun;
      // submit.jumlahSetor = parseInt(data.jumlahSetor.replace(/\./g, ""));
      submit.jumlahSetor = parseInt(data.jumlahSetor);
      // console.log(submit.jumlahSetor, "submit.jumlahSetor");
      submit.nop = data.nop;
      submit.noSk = data.noSk;
      submit.setorNpwpLain = data.setorNpwpLain == 2 ? true : false;
      submit.npwpDisetor =
        data.setorNpwpLain == 1 ? data.npwp : data.npwpDisetor;
      submit.namaDisetor =
        data.setorNpwpLain == 1 ? data.nama : data.namaDisetor;
      submit.alamatDisetor =
        data.setorNpwpLain == 1
          ? data.alamat
          : data.alamatDisetor.length >= 50
          ? data.alamatDisetor.substring(0, 50)
          : data.alamatDisetor;
      submit.kotaDisetor =
        data.setorNpwpLain == 1 ? data.kota : data.kotaDisetor;
      submit.uraian = data.uraian;
      try {
        const res = await service.createIdBilling(submit);
        let { status } = res;
        if (status == 200) {
          callback("success", false);
          navigationStore.redirectTo(
            `/internal/npwp/${npwpHeadId}/pph/${1}/periode/${tahun}/${idPph}`
          );
        }
      } catch (err) {
        callback(err, true, false);
      }
    }
  };

  return (
    ready && (
      <DataForm
        baseId="id-billing-form"
        title={t.translate(`modules.pph.formIdBilling.title`)}
        visible={visible}
        // data={item}
        onRequestClose={onRequestClose}
        asDialog={true}
        defaultData={item}
        definitions={[
          {
            inputType: DataForm.inputTypes.INPUT_MASK_NUMBER,
            label: t.translate(`words.identityNpwp`),
            key: "npwp",
            type: "text",
            mask: inputValidasiNpwp,
            maskChar: "_",
            maskValue: "string",
            width: "100%",
            disabled: true,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate(`words.nama`),
            key: "nama",
            disabled: true,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate(`words.alamat`),
            key: "alamat",
            disabled: true,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate(`words.kota`),
            key: "kota",
          },
          {
            inputType: DataForm.inputTypes.REACT_SELECT,
            isClearable: true,
            label: t.translate(`words.jenisPajak`),
            key: "kodeJenisPajak",
            validation: "required",
            labelKey: "label",
            valueKey: "value",
            options: jenisPajakList,
            isLoading: loadingPajak,
            onInputChange: (value) => {
              if (value) setQueryPajak(value);
            },
          },
          {
            inputType: DataForm.inputTypes.REACT_SELECT,
            isClearable: true,
            label: t.translate(`words.jenisSetoran`),
            key: "kodeJenisSetoran",
            validation: "required",
            valueKey: "value",
            options: jenisSetoranList,
            isLoading: loadingSetoran,
            // onInputChange: (value) => {
            //   if (value) setQuerySetoran(value);
            // },
          },
          {
            inputType: DataForm.inputTypes.SELECT,
            label: "masa",
            key: "masa",
            type: "text",
            validation: "required",
            options: [
              "1",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
              "9",
              "10",
              "11",
              "12",
            ],
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate(`words.tahunPajak`),
            key: "tahun",
            disabled: true,
          },
          {
            inputType: DataForm.inputTypes.INPUT_MASK_NUMBER,
            label: t.translate(`words.jumlahSetor`),
            key: "jumlahSetor",
            thousandSeparator: ".",
            decimalSeparator: ",",
            prefix: "Rp.",
            type: "text",
            validation: "required",
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate(`words.uraian`),
            key: "uraian",
            type: "text",
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: "NOP",
            key: "nop",
            show: isNop,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: "No. SK",
            key: "noSk",
            type: "text",
            validation: isNoSk && "required",
            show: isNoSk,
          },

          {
            inputType: DataForm.inputTypes.SELECT,
            label: "NPWP yang disetor",
            key: "setorNpwpLain",
            options: [
              {
                value: 1,
                label: "NPWP Sendiri",
              },
              {
                value: 2,
                label: "NPWP Lain",
              },
            ],

            show: isSetorNpwpLain,
          },
          {
            inputType: DataForm.inputTypes.INPUT_MASK_NUMBER,
            label: "NPWP Disetor",

            key: "npwpDisetor",
            type: "text",
            mask: inputValidasiNpwp,
            maskChar: "_",
            maskValue: "string",
            width: "100%",
            show: isSetorNpwpLainshow,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: "Nama Disetor",

            key: "namaDisetor",
            show: isSetorNpwpLainshow,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: "Alamat Disetor",

            key: "alamatDisetor",
            show: isSetorNpwpLainshow,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: "Kota Disetor",

            key: "kotaDisetor",
            show: isSetorNpwpLainshow,
          },
        ]}
        onChange={(data, key, value) => {
          // console.log(data);
          if (key === "kodeJenisPajak") {
            setLoadingSetoran(true);
            const timeOutId = setTimeout(() => getJenisSetoran(value), 500);
            return () => clearTimeout(timeOutId);
          }
          if (key === "kodeJenisSetoran") {
            jenisSetoranList.map((d) => {
              if (d.value === value) {
                setIsNop(d.data.nopAktif);
                setIsNoSk(d.data.noskAktif);
                setIsSetorNpwpLain(d.data.subjekPajakAktif);
              }
            });
          }
          if (key === "setorNpwpLain") {
            setIsSetorNpwpLainshow(value == 2 ? true : false);
          }
        }}
        onSubmit={submitForm}
      />
    )
  );
};

export default inject(
  "envStore",
  "modalStore",
  "navigationStore"
)(observer(IDBillingForm));
