import React, { useState, useEffect } from 'react'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { FormWrapper } from '../../../libs/react-mpk/wrapper'
import FormTanggal from '../FormTanggal'
import service from './ReportNeracaSaldo.service'
import { Table, TableBody, TableHeader, TableRow, TableCell, Button, TableFooter, FontIcon } from 'react-md'
import moment from "moment";
import 'moment/locale/id';
import { useLocation, useParams } from 'react-router'
import utilsService from '../../../services/utilsService'
import { format, formatRupiah } from '../../../libs/react-mpk/services/number.service'

const ReportDetailListTransaksiNeracaSaldo = ({
    navigationStore
}) => {
    const { state: { tahun } = {} } = useLocation();
    let npwpHeadId = sessionStorage.getItem('npwpHeadId')
    let NamaAkun = sessionStorage.getItem('namaAkunTransaksi')
    let KodeAkun = sessionStorage.getItem('kodeAkunTransaksi')
    let SaldoAwal = sessionStorage.getItem('saldoAwalTransaksi')
    let BulanTransaksi = sessionStorage.getItem('bulanTransaksi')
    let TahunTransaksi = sessionStorage.getItem('tahunTransaksi')
    var jumlahKredit = 0
    var jumlahDebit = parseFloat(sessionStorage.getItem('saldoAwalTransaksi'))
    const { id, bulan, noTransaksi } = useParams()
    const [data, setData] = useState([])
    const fetchData = async () => {
        try {
            let query ={
                "tahun":tahun?tahun:sessionStorage.getItem('tahunSobatBuku'),
                "bulan":bulan,
                "akunId.equals":id,
                page: 0,
size: 9999
            }
            let res = await service.getDetailTransaksiByID(query)
            setData(res.data)
        } catch (error) {
           
        }
    }
    useEffect(() => {
        fetchData()
    }, [])
    return (
        <FormWrapper
            baseId="report-detail-list-transaksi-neraca-saldo"
            showCommandbar={true}
            style={{
                maxWidth: '100%'
            }}
            title={t.translate(`modules.report.detailListTransaksiNeracaSaldo`)}
            // backTo={`/internal/npwp/${npwpHeadId}/report-neraca-saldo/transaksi/${id}`}
            defaultData={
                {
                    namaAkun: KodeAkun + " " + NamaAkun,
                    saldoAwal: ` ${formatRupiah(Math.abs(SaldoAwal))}`,
                    bulanDanTahun: utilsService.beautifyBulan(BulanTransaksi) + " " + TahunTransaksi
                }
            }
            definitions={[
                // {
                //     render: (
                //         <div style={{ width: '100%' }}><FormTanggal /></div>
                //     )
                // },
                {
                    inputType: FormWrapper.inputTypes.INPUT,
                    label: t.translate('words.namaAkun'),
                    key: 'namaAkun',
                    disabled: true,
                    style: {
                        marginLeft: '10px',
                        marginRight: '2%'
                    },
                    width: '31%'
                },
                {
                    inputType: FormWrapper.inputTypes.INPUT,
                    label: t.translate('words.saldoAwal'),
                    key: 'saldoAwal',
                    disabled: true,
                    style: {
                        marginRight: '2%'
                    },
                    width: '31%'
                },
                {
                    inputType: FormWrapper.inputTypes.INPUT,
                    label: t.translate('words.bulanDanTahun'),
                    key: 'bulanDanTahun',
                    disabled: true,
                    width: '31%'
                },
                {
                    render: (
                        <div style={{ width: '100%' }}>
                            <Table style={{ width: '100%' }}>
                                <TableHeader>
                                    <TableRow>
                                        <TableCell style={{ width: '5%' }}>{t.translate('words.non')}</TableCell>
                                        <TableCell style={{ width: '5%' }}>{t.translate('words.aksi')}</TableCell>
                                        <TableCell style={{ width: '15%' }}>{t.translate('words.kategori')}</TableCell>
                                        <TableCell style={{ width: '20%' }}>{t.translate('words.tanggalTransaksi')}</TableCell>
                                        <TableCell style={{ width: '20%' }}>{t.translate('words.noTransaksi')}</TableCell>
                                        <TableCell style={{ width: '20%' }}>{t.translate('words.debit')}</TableCell>
                                        <TableCell style={{ width: '20%' }}>{t.translate('words.kredit')}</TableCell>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {data.map((d, index) => {
                                    
                                        return (
                                            <TableRow key={index}>
                                                <TableCell>{index + 1}</TableCell>
                                            
                                                <TableCell>
                                                    <Button
                                                        theme="primary"
                                                        onClick={() => {
                                                            if (d.referensi.includes("PJ")) {
                                                                navigationStore.redirectTo(
                                                                    `/internal/npwp/${npwpHeadId}/penjualan/${d.referensi}/detail/non-ppn`
                                                                )
                                                            }
                                                            else if (d.referensi.includes("PB")) {
                                                                sessionStorage.setItem("noInvoicePembelian", d.referensi);
                                                                navigationStore.redirectTo(
                                                                  `/internal/npwp/${npwpHeadId}/pembelian/${d.id}/detail/non-ppn`
                                                                );
                                                            }
                                                            else if (d.referensi.includes("KK")) {
                                                                navigationStore.redirectTo(
                                                                    `/internal/npwp/${npwpHeadId}/kas-dan-bank-detail-pengeluaran-non-ppn/${d.referensi}`
                                                                )
                                                            }
                                                            else if (d.referensi.includes("PK")) {
                                                                navigationStore.redirectTo(
                                                                    `/internal/npwp/${npwpHeadId}/kas-dan-bank-detail-penerimaan-non-ppn/${d.referensi}/detail/non-ppn`,
                                                                )
                                                            }
                                                            else if (d.referensi.includes("TF")) {
                                                                navigationStore.redirectTo(
                                                                    `/internal/npwp/${npwpHeadId}/kas-dan-bank-detail-transfer-antar-bank/${d.referensi}`
                                                                )
                                                            }
                                                            else if (d.referensi.includes("BY")) {
                                                                navigationStore.redirectTo(
                                                                    `/internal/npwp/${npwpHeadId}/biaya/${d.referensi}/detail/non-ppn`
                                                                )
                                                            }
                                                            else if (d.referensi.includes("JU")) {
                                                                navigationStore.redirectTo(
                                                                    `/internal/npwp/${npwpHeadId}/jurnal-umum/${d.referensi}/jurnal-entri`
                                                                )
                                                            }
                                                            if (d.referensi.includes("JU")) {
                                                                navigationStore.redirectTo(
                                                                    `/internal/npwp/${npwpHeadId}/jurnal-umum/${d.referensi}/jurnal-entri`,
                                                                )
                                                            }
                                                        }}
                                                    >
                                                        <FontIcon iconClassName="mdi mdi-import" />
                                                        {/* {t.translate("words.add")} */}
                                                    </Button>
                                                </TableCell>
                                                <TableCell>{d.referensi.includes("PJ")
                                                    ? "Penjualan"
                                                    : d.referensi.includes("BY")
                                                        ? "Biaya"
                                                        : d.referensi.includes("PP")
                                                            ? "Pesanan"
                                                            : d.referensi.includes("PB")
                                                                ? "Pembelian"
                                                                : d.referensi.includes("KK")
                                                                    ? "Pengeluaran"
                                                                    : d.referensi.includes("TF")
                                                                        ? "Transfer Antar Bank"
                                                                        : d.referensi.includes("JU")
                                                                            ? "Jurnal Umum"
                                                                            : d.referensi.includes("PK")
                                                                                ? "Penerimaan"
                                                                                : ""
                                                }</TableCell>
                                                <TableCell>{moment(d.tanggalTransaksi).locale('id').format('ll')}</TableCell>
                                                <TableCell>{utilsService.beautifyNoTransaksi(d.referensi)}</TableCell>
                                                <TableCell>{d.kategori == 1
                                                    ? `${formatRupiah(Math.abs(d.nominal))}`
                                                    : `${formatRupiah(0)}`
                                                }</TableCell>
                                                <TableCell>{d.kategori == 2
                                                    ? `${formatRupiah(Math.abs(d.nominal))}`
                                                    : `${formatRupiah(0)}`
                                                }</TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                                <TableFooter>
                                    {data.map((d, index) => {
                                        {
                                            d.kategori == 1
                                                ? jumlahDebit += d.nominal
                                                : jumlahKredit += d.nominal
                                        }
                                    })}
                                    <TableCell><b>{t.translate(`words.saldoAkhir`)}</b></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>
                                        <b>
                                            {jumlahDebit - jumlahKredit < 0
                                                ? formatRupiah(Math.abs(jumlahDebit - jumlahKredit)) + " " + "(" + t.translate(`words.kredit`) + ")"
                                                : formatRupiah(Math.abs(jumlahDebit - jumlahKredit)) + " " + "(" + t.translate(`words.debit`) + ")"
                                            }
                                        </b>
                                    </TableCell>
                                </TableFooter>
                            </Table>
                        </div>
                    )
                }
            ]}
        />
    )
}

export default inject("navigationStore", 'envStore')(observer(ReportDetailListTransaksiNeracaSaldo))