import React, { useEffect, useState } from "react";
import { DataForm } from "../../libs/react-mpk/components";
import t from "counterpart";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import service from "./PPh.service";

import { formatTypes, inputTypes } from "../../libs/react-mpk/config/constant";
import { toast } from "../../libs/react-mpk/services";

const BuktiBayarForm = ({
  visible = false,
  authStore,
  envStore,
  match,
  history,
  onRequestClose = () => {},
  ...props
}) => {
  let [item, setItem] = useState({
    omzetSudahDihitung: 0,
    jenisDokumen: 1,
  });

  const [listBank, setListBank] = useState([]);
  let [queryBank, setQueryBank] = useState("");
  const [loadingBank, setLoadingBank] = useState(false);
  const [listNonBank, setListNonBank] = useState([]);
  let [queryBank2, setQueryBank2] = useState("");
  const [loadingBank2, setLoadingBank2] = useState(false);

  let [ready, setReady] = useState(true);

  useEffect(() => {
    async function initData() {
      if (visible) {
        console.log(props.item)
        if (props.item) {
          let newItem = {
            idBilling: props.item.idBilling,
            jumlah: props.item.nominalBayar,
          };
          setItem(newItem);
        }
      } else {
        setItem(item);
      }
      setReady(visible);
    }
    initData();
  }, [visible]);
  useEffect(() => {
    setLoadingBank(true);
    const timeOutId = setTimeout(() => getListBank(queryBank), 500);
    return () => clearTimeout(timeOutId);
  }, [queryBank]);
  useEffect(() => {
    setLoadingBank2(true);
    const timeOutId = setTimeout(() => getListNonBank(queryBank2), 500);
    return () => clearTimeout(timeOutId);
  }, [queryBank2]);
  
  async function getListBank(queryNama) {
    let query = {
      "kategori.equals": 1,
      size:9999,
      "nama.contains":queryNama
    };
    try {
      setLoadingBank(true);
      let res = await service.LembagaPersepsi(query);
      var kategoriAkuns = [];
      res.data.map((d) => {
        kategoriAkuns.push({
          value: d.id,
          label: `${d.kode} - ${d.nama}`,
          kategoriAset: d.kategoriAset,
        });
      });
      setListBank(kategoriAkuns);
      setLoadingBank(false);
    } catch (error) {
      toast.errorRequest(error);
      setLoadingBank(false);
    }
  }
  async function getListNonBank(queryNama) {
    let query = {
      "kategori.equals": 2,
      size:9999,
      "nama.contains":queryNama
    };
    try {
      setLoadingBank2(true);
      let res = await service.LembagaPersepsi(query);
      var kategoriAkuns = [];
      res.data.map((d) => {
        kategoriAkuns.push({
          value: d.id,
          label: `${d.kode} - ${d.nama}`,
          kategoriAset: d.kategoriAset,
        });
      });
      setListNonBank(kategoriAkuns);
      setLoadingBank2(false);
    } catch (error) {
      toast.errorRequest(error);
      setLoadingBank2(false);
    }
  }

  return (
    ready && (
      <DataForm
        baseId="bukti-bayar-form"
        title={"Bukti Bayar"}
        visible={visible}
        onRequestClose={onRequestClose}
        asDialog={true}
        defaultData={item}
        style={{ width: 500 }}
        definitions={[
          {
            inputType: DataForm.inputTypes.SELECT,
            label: "Kategori Lembaga Persepsi",
            key: "kategoriLembaga",
            validation: "required",
            options: [
              { label: "Bank", value: 1 },
              { label: "Non Bank", value: 2 },
            ],
          },
          {
            inputType: DataForm.inputTypes.SELECT,
            label: "Jenis Dokumen",
            key: "jenisDokumen",
            options: [
              { label: "NTPN ", value: 1 },
              { label: "PBK", value: 2 },
            ],
            show: false,
          },
          {
            inputType: DataForm.inputTypes.AUTOCOMPLETE,
            label: "Bank",
            key: "bankId",
            labelKey: "label",
            valueKey: "label",
            type: "search",
            data: listBank,
            show: (d) => {
              return d.kategoriLembaga == 1;
            },
          },
          {
            inputType: DataForm.inputTypes.AUTOCOMPLETE,
            label: "Non Bank",
            key: "nonBankId",
            labelKey: "label",
            valueKey: "label",
            type: "search",
            data: listNonBank,
            show: (d) => {
              return d.kategoriLembaga == 2;
            },
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            label: "Id Billing",
            key: "idBilling",
            disabled: true,
          },
          {
            inputType: inputTypes.DATEPICKER,
            label: "tanggal NTPN",
            key: "tanggalNtpn",
            type: "date",
            validation: "required",
            maxDate: new Date(),
          },
          {
            inputType: inputTypes.INPUT,
            label: "NTPN",
            isNumericString: true,
            key: "ntpn",
            maxLength:16,
            minLength:16,

            validation: "required",
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            label: "Jumlah yang Disetor",
            thousandSeparator: ".",
            decimalSeparator: ",",
            prefix:'Rp.',
            type: "text",
            key: "jumlah",
            disabled: true,
          },
          {
            inputType: inputTypes.FILE_INPUT,
            label: t.translate("words.file"),
            key: "fileLampiran",
            type: "file",
            accept: ".jpg,.jpeg,.png",
            validation: 'required'
            
          },
        ]}
        onChange={async (formData, key, value) => {
          if (key == "bankId") {
            listBank.map((d) => {
              if (d.label == value) {
                formData["bank"] = d.value;
              }
            });
          }
          if (key == "nonBankId") {
            listNonBank.map((d) => {
              if (d.label == value) {
                formData["nonBank"] = d.value;
              }
            });
          }
        }}
        onSubmit={async (data, callback) => {
          try {
            const formData = new FormData();
            formData.append(
              "lembagaPersepsiId",
              data.kategoriLembaga == 1 ? data.bank : data.nonBank
            );
            formData.append("tanggalNtpn", data.tanggalNtpn);
            formData.append("ntpn", data.ntpn);
            formData.append("jumlahSetor", data.jumlah);
            formData.append("file", data.fileLampiran == null ? null:data.fileLampiran);
            formData.append("jenisDokumen", 1);
            formData.append("eBillingId", props.item.id);
            await service.createBuktiBayar(formData);
            callback("success", false);
            TableWrapper.reload("listIDBilling-belumdibayar");
          } catch (error) {
            callback(error, true, false);
          }
        }}
      />
    )
  );
};

export default BuktiBayarForm;