import { crud, http } from '../../libs/react-mpk/services';
import moment from "moment";
import 'moment/locale/id';

let service = null
let serviceUrl = '/jurnal_umum'
let tahunSobatPajak = sessionStorage.getItem('tahunSobatBuku');
let npwpHeadId = sessionStorage.getItem('npwpHeadId');
let npwpHeadIdObject = {
    npwpHeadId: sessionStorage.getItem('npwpHeadId')
}
service = new crud('/jurnal_umum');

service.getPage = async(query) => {
    query = {
        ...query,
        ...npwpHeadIdObject,
        tahun: tahunSobatPajak
    }
    return http.request({
        method: http.methods.GET,
        url: `${serviceUrl}`,
        query
    })
}

service.generateNoTransaksi = async(tanggalTransaksi) => {
    let data = {
        "tanggalTransaksi": moment(new Date(tanggalTransaksi)).format(
          "YYYY-MM-DD hh:mm:ss"
          ),
        "npwpHeadId": npwpHeadId
    }
    return http.request({
        method: http.methods.POST,
        url: `${serviceUrl}/generate_no_transaksi`,
        data
  })
}

service.getOneById = async(id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/${id}`
  })
}

service.getOneByNoTransaksi = async(noTransaksi) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/no_transaksi/${noTransaksi}`
  })
}

service.create = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}`,
    data
  })
}

service.update = async(data)=> {
  return http.request({
    method: http.methods.PUT,
    url: `${serviceUrl}/${data.id}`,
    data
  })
}

service.deleteById = async (id)=>{
  return http.request({
    method: http.methods.DELETE,
    url: `${serviceUrl}/${id}`,
  })
}

service.getJurnalEntryByNoTransaksi = async(noTransaksi, debitKredit) => {
  let query = {
      "page": 0,
      "size": 9999,
      "sort": "tanggalDibuat,DESC",
      "npwpHeadId" : sessionStorage.getItem('npwpHeadId'),
      "noTransaksi" : noTransaksi,
      "debitKredit" : debitKredit
  }
  return http.request({
      method: http.methods.GET,
      url: `/jurnal_entry/get_by_no_transaksi`,
      query
  })
}
export default service;