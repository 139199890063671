import React, { useEffect, useState } from "react";
import { inputTypes } from "../../libs/react-mpk/config/constant";
import { FormWrapper } from "../../libs/react-mpk/wrapper";
import { DataForm, Hint } from "../../libs/react-mpk/components";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import { find } from "lodash";
import service from "./JurnalUmum.service";
import moment from "moment";
import 'moment/locale/id';
import JurnalUmumFormDebitKreditForm from "./JurnalUmumFormDebitKreditForm";
import {
  ExpansionList,
  ExpansionPanel,
  Table,
  TableBody,
  TableHeader,
  TableRow,
  TableCell,
  Card,
  Button,
  FontIcon,
  TableFooter,
} from "react-md";
import Modal from "../../libs/react-mpk/components/Modal";
import cloneDeep from "lodash.clonedeep";
import { format, formatRupiah } from "../../libs/react-mpk/services/number.service";
import utilsService from "../../services/utilsService";

const defaultData = () => ({
  noTransaksi: "",
  noTransaksi1: "",
  tanggalTransaksi: null,
  keterangan: "",
  jurnalUmumDet: [],
});

const JurnalUmumForm = ({
  envStore,
  modalStore,
  navigationStore,
  match,
  ...props
}) => {
  var npwpHeadId = match.params.npwpHeadId;
  const [isReady, setIsReady] = useState(true);
  const [showFormDebitKredit, setShowFormDebitKredit] = useState(false);
  const [dialogData, setDialogData] = useState(false);
  const [dokumens, setDokumens] = useState([]);

  let [dataSubmit, setDataSubmit] = useState({
    noTransaksi: "",
    noTransaksi1: "",
    tanggalTransaksi: null,
    keterangan: "",
    npwpHead: {
      id: sessionStorage.getItem("npwpHeadId"),
    },
    jurnalUmumDet: [],
  });
  let [item, setItem] = useState(null);
  const [debitKreditList, setDebitKreditList] = useState([]);

  const baseName = `jurnal-umum-form`;
  const basePath = `/internal/npwp/${npwpHeadId}/jurnal-umum/new`;
  const backTo = `/internal/npwp/${npwpHeadId}/jurnal-umum`;

  const KATEGORI_DEBIT = "1";
  const KATEGORI_KREDIT = "2";

  // function addItem(){
  //   setDialogDokumen(true)
  //   setDialogData({})
  // }

  useEffect(() => {
    initData();
  }, []);

  async function initData() {
    let selectedItem = defaultData();

    if (match.params.noTransaksi != "new") {
      try {
        let res = await service.getOneByNoTransaksi(match.params.noTransaksi);

        let { data } = res;

        setDataSubmit(data);

        selectedItem.id = data.id;
        selectedItem.noTransaksi = data.noTransaksi;
        selectedItem.tanggalTransaksi = data.tanggalTransaksi;
        selectedItem.keterangan = data.keterangan;
        setDebitKreditList(data.jurnalUmumDet);
      } catch (e) {}
    } else {
    }
    setItem(selectedItem);
    setIsReady(false);
  }

  const onSubmit = async (data, callback) => {
    let reqData = dataSubmit;

    //ISI FIELD
    reqData.id = data.id;
    reqData.noTransaksi = data.noTransaksi1;
    reqData.tanggalTransaksi = moment(new Date(data.tanggalTransaksi)).format("YYYY-MM-DD hh:mm:ss");
    reqData.keterangan = data.keterangan;
    reqData.jurnalUmumDet = debitKreditList;

    reqData.npwpHead.id = sessionStorage.getItem("npwpHeadId");

    if (reqData.id) {
      try {
        const res = await service.update(reqData);
        let { data, status } = res;
        if (status == 200) {
          callback("success", false);
          navigationStore.redirectTo(backTo);
          // window.location.href = `${basePath}/${item.id}/history`
        }
      } catch (err) {
        callback(err, true, false);
      }
    } else {
      try {
        const res = await service.create(reqData);
        let { data, status } = res;
        if (status == 200) {
          callback("success", false);
          navigationStore.redirectTo(backTo);
          // window.location.href = `${basePath}/${item.id}/history`
        }
        // console.log(res, 'res')
      } catch (err) {
        callback(err, true, false);
      }
    }
  };

  async function addDebitKredit() {
    var debitKreditForm = JSON.parse(localStorage.getItem("debitKreditForm"));
    if (debitKreditForm) {
      var debitKreditListNew = debitKreditList;

      debitKreditListNew.push(debitKreditForm);

      setDebitKreditList(debitKreditListNew);

      localStorage.removeItem("debitKreditForm");
    }
  }

  async function deleteDebitKredit(index) {
    modalStore.showConfirm({
      title: t.translate("words.delete"),
      children: t.translate("words.confirmHapus"),
      onSubmit: (callback) => {
        var debitKreditListNew = debitKreditList;
        debitKreditListNew.splice(index, 1);
        setDebitKreditList(debitKreditListNew);
        callback();
      },
    });
  }

  // setDokumens(dokumensData);

  return (
    <>
      <FormWrapper
        // backTo={backTo}
        showBackTo={true}
        showCommandbar={true}
        baseId={`mod-form-${baseName}`}
        loading={isReady}
        title={t.translate(`modules.jurnalUmum.form`)}
        style={{
          maxWidth: "100%",
        }}
        defaultData={item}
        submitLabel="Create"
        onChange={async (formData, key, value) => {
          if (key == "tanggalTransaksi") {
            let res = await service.generateNoTransaksi(value);
            var a = res.data.message;
            var output = utilsService.beautifyNoTransaksi(a);
            formData["noTransaksi"] = output;
            formData["noTransaksi1"] = a;
          }
        }}
        definitions={[
          {
            inputType: inputTypes.DATEPICKER,
            label: t.translate(`words.tanggalTransaksi`),
            key: "tanggalTransaksi",
            width: "50%",
            type: "date",
            validation: "required",
          },
          {
            inputType: inputTypes.INPUT,
            label: t.translate(`words.noTransaksi`),
            key: "noTransaksi",
            mask: "#####.##-####/##/####",
            maskChar: "_",
            width: "50%",
            disabled: true,
          },
          {
            inputType: inputTypes.INPUT,
            label: t.translate(`words.keterangan`),
            key: "keterangan",
            type: "text",
            maskValue: "string",
            width: "100%",
          },
          {
            render: (
              <div style={{ width: "100%" }}>
                <Hint
                  className="mpk-margin-N margin-top margin-bottom"
                  message={t.translate("words.listDebit")}
                />
                <Button
                  theme="primary"
                  themeType="contained"
                  style={{ margin: 15 }}
                  onClick={() => {
                    setShowFormDebitKredit(true);
                  }}
                >
                  <FontIcon iconClassName="mdi mdi-plus" />
                  {t.translate("words.add")}
                </Button>
                <Table style={{ width: "100%" }}>
                  <TableHeader>
                    <TableRow>
                      <TableCell style={{ width: "25%" }}>
                        {t.translate("words.kode")}
                      </TableCell>
                      <TableCell style={{ width: "25%" }}>
                        {t.translate("words.nama")}
                      </TableCell>
                      <TableCell style={{ width: "30%" }}>
                        {t.translate("words.nominal")}
                      </TableCell>
                      <TableCell style={{ width: "10%" }}>
                        {t.translate("words.actions")}
                      </TableCell>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {debitKreditList.map((d, index) => {
                      if (d.kategori == KATEGORI_DEBIT) {
                        return (
                          <TableRow key={index}>
                            <TableCell>{d.akun.kode}</TableCell>
                            <TableCell>{d.akun.nama}</TableCell>
                            <TableCell>{formatRupiah(d.nominal)}</TableCell>
                            <TableCell>
                              <Button onClick={() => deleteDebitKredit(index)}>
                                {t.translate("words.delete")}
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      }
                    })}
                  </TableBody>
                  {/* <TableFooter>
									<TableRow>
										<TableCell></TableCell>
										<TableCell></TableCell>
										<TableCell>Total</TableCell>
										<TableCell>Rp. 10.000.000</TableCell>
										<TableCell></TableCell>
									</TableRow>
								</TableFooter> */}
                </Table>
              </div>
            ),
          },
          {
            render: (
              <div style={{ width: "100%" }}>
                <Hint
                  className="mpk-margin-N margin-top margin-bottom"
                  message={t.translate("words.listKredit")}
                />
                <Button
                  theme="primary"
                  themeType="contained"
                  style={{ margin: 15 }}
                  onClick={() => {
                    setShowFormDebitKredit(true);
                  }}
                >
                  <FontIcon iconClassName="mdi mdi-plus" />
                  {t.translate("words.add")}
                </Button>
                <Table style={{ width: "100%" }}>
                  <TableHeader>
                    <TableRow>
                      <TableCell style={{ width: "25%" }}>
                        {t.translate("words.kode")}
                      </TableCell>
                      <TableCell style={{ width: "25%" }}>
                        {t.translate("words.nama")}
                      </TableCell>
                      <TableCell style={{ width: "30%" }}>
                        {t.translate("words.nominal")}
                      </TableCell>
                      <TableCell style={{ width: "10%" }}>
                        {t.translate("words.actions")}
                      </TableCell>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {debitKreditList.map((d, index) => {
                      if (d.kategori == KATEGORI_KREDIT) {
                        return (
                          <TableRow key={index}>
                            <TableCell>{d.akun.kode}</TableCell>
                            <TableCell>{d.akun.nama}</TableCell>
                            <TableCell>{formatRupiah(d.nominal)}</TableCell>
                            <TableCell>
                              <Button onClick={() => deleteDebitKredit(index)}>
                                {t.translate("words.delete")}
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      }
                    })}
                  </TableBody>
                  {/* <TableFooter>
									<TableRow>
										<TableCell></TableCell>
										<TableCell></TableCell>
										<TableCell>Total</TableCell>
										<TableCell>Rp. 10.000.000</TableCell>
										<TableCell></TableCell>
									</TableRow>
								</TableFooter> */}
                </Table>
              </div>
            ),
          },
        ]}
        onSubmit={onSubmit}
      />
      <JurnalUmumFormDebitKreditForm
        visible={showFormDebitKredit}
        onRequestClose={() => {
          addDebitKredit();
          setShowFormDebitKredit(false);
        }}
        // item={selectedItem}
      />
    </>
  );
};

export default inject(
  "envStore",
  "modalStore",
  "navigationStore"
)(observer(JurnalUmumForm));
