import React, { useEffect, useState } from 'react';
import { DataForm, Hint } from '../../libs/react-mpk/components';
import t from 'counterpart'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import { Table, TableBody, TableHeader, TableRow, TableCell, Button, FontIcon, TableFooter } from 'react-md'
import service from './AkunCoa.service'
import { cloneDeep } from 'lodash'
import { disable } from 'react-md';
import { values } from 'mobx';



const defaultData = () => ({
	id: null,
	nama: null,
	nominalModal: "0",
	ptsModal: "0",
	npwpOpInt: "1",
	npwp: null,
	nik: null,
	email: null,
	alamat: null
  })

const AkunCoaFormPemilikModalForm = ({
	visible = false,
	authStore,
	envStore,
	match,
	history,
	onRequestClose = () => { },
	...props
}) => {
	const { inputTypes, definition } = FormWrapper
	const [data, setData] = useState({loading: true, content: {}})
	let [item, setItem] = useState(null)
	let [ready, setReady] = useState(true)

	// VALIDASI
	let [disabledNpwp, setDisabledNpwp] = useState(false)
	const [inputValidasiNpwp, setInputValidasiNpwp] = useState('##.###.###.#-###.###')
	let [disabledNik, setDisabledNik] = useState(false)
	const [inputValidasiNik, setInputValidasiNik] = useState('################')
	let [disabledTanggalPkp, setDisabledTanggalPkp] = useState(false)
	
	useEffect(() => {
		async function initData(){
			if (visible) {
				let selectedItem = defaultData();

				if(props.itemId){
					try {
						// let res = await service.getOneById(props.itemId)
						
						// let { data } = res;
						
						// setDataSubmit(data);

					} catch(e){
						// setMessageError(`${e}`)
						// setShowDialogError(true)
						onRequestClose()
					}
				}
				setItem(selectedItem);
			} else {
				setItem(defaultData())
			}
			setReady(visible)
		}
        initData()
	}, [visible])

	return ready && (
		<DataForm
			baseId="akun-coa-form-kepemilikan"
			title={"Kepemilikan Modal Form"}
			visible={visible}
			// data={item}
			onRequestClose={onRequestClose}
			asDialog={true}
			defaultData={item}
			definitions={[
				{
					inputType: DataForm.inputTypes.INPUT,
					label: t.translate(`words.nama`),
					key: 'nama',
					disable:true,
					validation: 'required'
				},
				{
					inputType: inputTypes.INPUT_MASK_NUMBER,
					thousandSeparator: ".",
					decimalSeparator: ",",
					isNumericString: true,
					label: t.translate(`words.nominalModal`),
					key: 'nominalModal',
					type: 'text',
					width: '100%',
					validation: 'required',
					allowNegative: false
				},
				{
					inputType: inputTypes.INPUT_MASK_NUMBER,
					thousandSeparator: ".",
					decimalSeparator: ",",
					isNumericString: true,
					label: t.translate(`words.persentase`),
					key: 'ptsModal',
					type: 'text',
					width: '100%',
					validation: 'required',
					allowNegative: false
				},
				{
					render: (
					<div>
						<p style={{marginLeft: "10px", marginBottom: "-15px"}}><b>{t.translate(`words.jenisUsahaLawanTransaksi`)}</b></p><br/>
					</div>
					)
				},
				{
					inputType: inputTypes.RADIO,
					label: t.translate(`words.op`),
					key: 'npwpOpInt',
					name: 'npwpOpInt',
					value: "0",
					style: {
						width: '100%'
					}
				},
				{
					inputType: inputTypes.RADIO,
					label: t.translate(`words.badan`),
					key: 'npwpOpInt',
					name: 'npwpOpInt',
					value: "1",
					style: {
						width: '100%'
					}
				},
				{
					inputType: inputTypes.INPUT_MASK_NUMBER,
					label: t.translate(`words.identityNpwp`),
					key: 'npwp',
					type: 'text',
					mask: inputValidasiNpwp,
					maskChar: '_',
					maskValue: 'string',
					width: '100%',
					validation: 'required'
				},
				{
					inputType: inputTypes.INPUT_MASK_NUMBER,
					label: t.translate(`words.identityNik`),
					key: 'nik',
					type: 'text',
					mask: inputValidasiNik,
					maskChar: '_',
					maskValue: 'string',
					width: '100%',
					validation: 'required'
				},
				{
				  inputType: inputTypes.INPUT,
				  label: t.translate(`words.email`),
				  key: 'email',
				  type: 'text'
				},
				{
				  inputType: inputTypes.INPUT,
				  label: t.translate(`words.alamat`),
				  key: 'alamat',
				  type: 'text'
				},
			]}
			onChange={async (formData, key, value)=> {
				if(key=='ptsModal'){
					if(parseInt(value)>100){
						formData['ptsModal'] = 100;
					}
				}
			}}
			// onBeforeChange={(key, value) => {
			// 	console.log(key, value)
			// 	return value
			// }}
			onSubmit={(data, callback) => {
                localStorage.setItem("kepemilikanModalForm", JSON.stringify(data))
				callback('success', false)
			}}
		/>
	)
}

export default AkunCoaFormPemilikModalForm