import React, { useState } from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './Pesanan.service'
import { useParams } from "react-router-dom";

const PesananHistory = ({
    className = '',
    envStore,
}) => {

    const basePath = `/internal/ppn`
    const [data, setData] = useState([])
    const [load, setload] = useState(true);
    const baseId = 'mod-daftar-ppn-history'
    let [search, setSearch] = useState({})
    const { id } = useParams();
	let npwpHeadId = sessionStorage.getItem('npwpHeadId')

    return (
        <>
            <TableWrapper
                baseId="pesanan-history"
                title={t.translate('modules.pesanan.history')}
                className={className}
                hintMessage={t.translate('modules.pesanan.hint.message')}
                hintIconClassName="mdi mdi-information"
                hintMore={t.translate('modules.pesanan.hint.more')}
                isShowFilter={false}
                showActionColumn={true}
                useFilter={false}
                defaultCollapse={true}
                useCriteria={true}
                selectable={false}
                useQueryHistory={false}
                defaultSortBy='tanggalDibuat'
                backTo={`/${envStore.env.applicationType}/npwp/${npwpHeadId}/pesanan`}
                defaultData={data}
                onFetchData={(query) => {
                    return (
                        new Promise(async (resolve, reject) => {
                            try {
                                // var kategori = { 'kategori.equals': 2 }
                                query = {
                                    ...search,
                                    ...query,
                                    // ...kategori

                                }
                                // let getPage = await service.get(query)
                                let getPage = await service.getProdukHistory(id)
                                setData(getPage.data)
                                getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
                                resolve(getPage)
                            } catch (e) {
                                resolve()
                                // ErrorService(e)
                            }
                        })
                    )
                }}
                columns={[
                    {
                        label: t.translate('words.tanggalDibuat'),
                        searchable: false,
                        key: 'tanggalDibuat',
                        render: (item) => (item.tanggalDibuat)
                    },
                    {
                        label: t.translate('words.status'),
                        searchable: true,
                        key: 'status',
                        render: item => (
                            <TableWrapper.Status
                                type={item.status == "1"
                                    ? TableWrapper.Status.types.IDLE
                                    : item.status == "2"
                                        ? TableWrapper.Status.types.PROGRESS
                                        : item.status == "3"
                                            ? TableWrapper.Status.types.PROGRESS
                                            : item.status == "4"
                                                ? TableWrapper.Status.types.SUCCESS
                                                : TableWrapper.Status.types.ERROR

                                }
                            >
                                {item.status == "1"
                                    ? 'Belum Diproses'
                                    : item.status == "2"
                                        ? 'Diproses'
                                        : item.status == "3"
                                            ? "Diproses"
                                            : item.status == "4"
                                                ? "Selesai"
                                                : "Batal"
                                }
                            </TableWrapper.Status>
                        )
                    }
                ]}
            />
        </>
    )
}

export default inject('temporaryStore', 'modalStore', 'envStore')(observer(PesananHistory))