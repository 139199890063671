import React, { useEffect, useState } from 'react';
import { inputTypes } from '../../libs/react-mpk/config/constant'
import { FormWrapper, TableWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { ExpansionList, ExpansionPanel, Table, TableBody, TableHeader, TableRow, TableCell, Card, Button, FontIcon, TableFooter } from 'react-md'
import service from './Pesanan.service'
import { DataForm } from '../../libs/react-mpk/components';
import cloneDeep from 'lodash.clonedeep';
import moment from 'moment';
import { find } from 'lodash';
import PesananProdukForm from './PesananProdukForm'
import Modal from '../../libs/react-mpk/components/Modal'
import { toast } from '../../libs/react-mpk/services';

const defaultData = () => ({
	lawanTransaksi: {
		id: null,
		nama: ""
	},
	produk: [],
	kurir: {},
	tanggalPesan: null
})
const PesananInvoiceNonPkp = ({
	envStore,
	modalStore,
	navigationStore,
	match,
	...props }) => {
	const [showModal, setShowModal] = useState(false)
	const [dataLawan, setDataLawan] = useState([])
	const [dataLawanAll, setDataLawanAll] = useState([])
	const [dataKurir, setDataKurir] = useState([])
	const [dataPesanan, setDataPesanan] = useState({})
	const [load, setLoad] = useState(true)
	const [dialogPesananProdukForm, setDialogPesananProdukForm] = useState(false)
	const [produkList, setProdukList] = useState([])
	let npwpHeadId = sessionStorage.getItem('npwpHeadId')

	let [item, setItem] = useState(null)
	const [isReady, setIsReady] = useState(true)

	const [getPesanan, setGetPesanan] = useState([])
	const [lawanTransaksiData, setLawanTransaksiData] = useState({})
	const baseName = `pesanan`
	const basePath = `pesanan`
	var backTo = `/internal/npwp/${npwpHeadId}/pesanan`

	let [dataSubmit, setDataSubmit] = useState({
		pesanan: {
			tanggalPesan: null,
			totalPesanan: 0,
			totalDpp: 0,
			totalPpn: 0,
			totalPpnbm: 0,
			npwpHead: {
				id: sessionStorage.getItem('npwpHeadId')
			},
			lawanTransaksi: {
				id: null,
				nama: ""
			},
			kurir: {
				id: null,
				nama: ""
			}
		},
		pesananProdukList: []
	})

	const [panel1, setPanel1] = useState(true)
	const [panel2, setPanel2] = useState(false)
	const [panel3, setPanel3] = useState(false)

	const fetchData = async () => {
		try {
			let resPesanan = await service.getAll()
			if (resPesanan.data.lawanTransaksi) {
				resPesanan.data.lawanTransaksiId = `${resPesanan.data.lawanTransaksi.label}`
			}
			if (resPesanan.data.kurir) {
				resPesanan.data.kurirId = `${resPesanan.data.kurir.label}`
			}
			let defaultData = resPesanan.data
			if (!defaultData.lawanTransaksi) {
				defaultData.lawanTransaksi = {
					id: null
				}
			}
			if (!defaultData.kurir) {
				defaultData.kurir = {
					id: null
				}
			}
			setDataPesanan(defaultData)
		

			let resLawan = await service.getLawanTransaksi()
			var lawanAsset = []
			resLawan.data.map((d) => {
				lawanAsset.push({
					label: d.nama,
					value: d.id,
					nama: d.nama
				})
			})
			setDataLawan(lawanAsset)
			setDataLawanAll(resLawan.data)


			let resKurir = await service.getKurir()
			var kurirAsset = []
			resKurir.data.map((d) => {
				kurirAsset.push({
					label: d.nama,
					value: d.id,
					nama: d.nama
				})
			})
			setDataKurir(kurirAsset)
			// console.log(kurirAsset);

			// let resProduk = await service.getProduk(id)
			// setDataProduk(resProduk.data)

			setLoad(false)
		} catch (error) {
		 toast.errorRequest(error)
		}
	}

	const activePanel1 = () => {
		setPanel1(true);
		setPanel2(false);
		setPanel3(false);
	}

	const activePanel2 = () => {
		setPanel1(false);
		setPanel2(true);
		setPanel3(false);
	}

	const activePanel3 = () => {
		setPanel1(false);
		setPanel2(false);
		setPanel3(true);
	}

	var onSubmitIdentitas = async (data, callback) => {
		setItem(data)
		activePanel2()
	}


	var onSubmitTransaksi = async (data, callback) => {
		setItem(data)
		activePanel3()
	}

	useEffect(() => {
		fetchData()
		initData()
	}, [])

	async function initData() {
		let selectedItem = defaultData();
		let resgetpesanan = await service.getAll()
		setGetPesanan(resgetpesanan.data)
		if (props.itemId) {
			try {
				let res = await service.getProduk(props.itemId)
				let { data } = res;
				setDataSubmit(data);

				selectedItem.id = data.id
				selectedItem.lawanTransaksi = data.lawanTransaksi
				selectedItem.tanggalPesan = data.tanggalPesan
				selectedItem.kurir = data.kurir
				setProdukList(data.produk)
			} catch (e) { }
		}
		else {

		}
		setItem(selectedItem);
		setIsReady(false);
	}
	async function addProdukForm() {
		var produkForm = JSON.parse(localStorage.getItem("produkForm"))
		if (produkForm) {

			var produkListNew = produkList;

			produkListNew.push(produkForm);

			setProdukList(produkListNew);

			localStorage.removeItem("produkForm")

		}
	}
	async function deleteProduk(index) {

		modalStore.showConfirm({
			title: t.translate('words.delete'),
			children: t.translate('words.confirmHapus'),
			onSubmit: (callback) => {
				var produkListNew = produkList;
				produkListNew.splice(index, 1);
				setProdukList(produkListNew);
				callback()
			}
		})

	}
	var onSubmitProduk = async (data, callback) => {
		let reqData = dataSubmit

		reqData.pesanan.tanggalPesan = moment(data.tanggalPesan).format('yyyy-MM-DD hh:mm:ss')
		reqData.pesanan.npwpHead.id = sessionStorage.getItem('npwpHeadId')
		reqData.pesanan.lawanTransaksi.id = data.lawanTransaksi.id
		reqData.pesanan.lawanTransaksi.nama = data.lawanTransaksi.nama
		reqData.pesanan.kurir.id = data.kurir.id
		reqData.pesanan.lawanTransaksi.nama = data.kurir.nama
		reqData.pesananProdukList = produkList

		reqData.pesanan.totalPesanan = 0
		reqData.pesanan.totalDpp = 0
		reqData.pesanan.totalPpn = 0
		reqData.pesanan.totalPpnbm = 0

		produkList.map((d) => {
			reqData.pesanan.totalPesanan += d.subtotal
			reqData.pesanan.totalDpp += d.dpp
			reqData.pesanan.totalPpn += d.ppn
			reqData.pesanan.totalPpnbm += d.ppnbm
		})

		try {
			let response = await service.post(reqData)
			let { status } = response
			if (status == 200) {
				callback('success', false)
				navigationStore.redirectTo(backTo)
				// window.location.href = `${basePath}/${item.id}/history`
			}
		}
		catch (error) {
			callback(error)
		}
	}
	return /*load ? "loading" :*/ (
		<FormWrapper
			loading={isReady}
			backTo={backTo}
			showCommandbar={true}
			baseId={`mod-form-${baseName}`}
			title={t.translate(`modules.pesanan.nonpkp`)}
			style={{
				maxWidth: '100%'
			}}
			// defaultData={item}
			definitions={[
				{
					render: (
						<ExpansionList style={{ width: '100%' }}>
							{/* FORM IDENTITAS */}
							<ExpansionPanel expanded={panel1} onClick={() => activePanel1()} header={t.translate('words.identity')}>
								<Modal
									disableFocusOnMount={true}
									id="lt-form"
									aria-label="lt-form"
									visible={showModal}
									onRequestClose={_closeDialog}
									style={{
										width: 1024,
										height: 600
									}}
								>
									{/* <LawanTransaksiTable match={match} onSearch={true} setShowModal={setShowModal} setData={setData} data={data} /> */}
								</Modal>
								{/* <Button theme="primary" themeType="contained" style={{ margin: 15, width: '25%' }} onClick={()=> setShowModal(true)} hidden={isHidden}><FontIcon iconClassName="mdi mdi-magnify" />
									{t.translate('words.cariLawanTransaksi')}
								</Button> */}
								<FormWrapper
									submitLabel={t.translate('words.submit')}
									showCommandbar={false}
									baseId={`mod-form-${baseName}`}
									style={{
										maxWidth: '100%'
									}}
									defaultData={item ? cloneDeep(item) : {
										lawanTransaksi: null
									}}
									watchDefaultData={true}
									definitions={[
										{
											render: (
												<div style={{ width: '100%' }}>
													<p><b>{t.translate(`words.identity`)}</b></p>
												</div>
											)
										},
										{
											inputType: inputTypes.DIVIDER,
											label: t.translate(`words.dataLawanTransaksi`),
										},
										{
											inputType: inputTypes.AUTOCOMPLETE,
											label: t.translate(`words.nama`),
											key: 'lawanTransaksiId',
											validation: 'required',
											labelKey: 'label',
											valueKey: 'label',
											type: 'search',
											data: dataLawan,
										},
										{
											inputType: inputTypes.INPUT_MASK_NUMBER,
											label: t.translate(`words.identityNpwp`),
											key: 'npwp',
											type: 'text',
											// mask: inputValidasiNpwp,
											maskChar: '_',
											maskValue: 'string',
											disabled: true,
											width: '49%',
											style: {
												marginRight: "2%"
											}
										},
										{
											inputType: inputTypes.INPUT_MASK_NUMBER,
											label: t.translate(`words.identityNik`),
											key: 'nik',
											type: 'text',
											// mask: inputValidasiNik,
											maskChar: '_',
											maskValue: 'string',
											disabled: true,
											width: '49%',
										},
										{
											inputType: inputTypes.INPUT,
											label: t.translate(`words.address`),
											key: 'alamat',
											disabled: true,
											type: 'text',
											width: '100%'
										},
										{
											inputType: inputTypes.DIVIDER,
											label: t.translate(`words.picPenagihan`),
										},
										{
											inputType: inputTypes.INPUT,
											label: t.translate(`words.namaPic`),
											key: 'namaPic',
											type: 'text',
											disabled: true,
											width: '49%',
											style: {
												marginRight: "2%"
											}
										},
										{
											inputType: inputTypes.INPUT,
											label: t.translate(`words.emailPic`),
											key: 'emailPic',
											type: 'text',
											disabled: true,
											width: '49%',
										},
										{
											inputType: inputTypes.INPUT_MASK_NUMBER,
											label: t.translate(`words.nomorTeleponPic`),
											key: 'noTeleponPic',
											type: 'text',
											disabled: true,
											width: '49%',
											style: {
												marginRight: "2%"
											}
										},
										{
											inputType: inputTypes.INPUT,
											label: t.translate(`words.alamatPic`),
											key: 'alamatPic',
											type: 'text',
											disabled: true,
											width: '49%',
										},
									]}
									onChange={async (data, key, value) => {
										if (key == 'lawanTransaksiId') {
											dataLawan.map((d) => {
												if (d.label == value) {
													data['lawanTransaksi']['id'] = d.value
													data['lawanTransaksi']['nama'] = d.nama
													let dataLawanTransaksi = find(dataLawanAll, { id: data.lawanTransaksi.id })
													data['npwp'] = dataLawanTransaksi ? dataLawanTransaksi.npwp : ""
													data['nik'] = dataLawanTransaksi ? dataLawanTransaksi.nik : ""
													data['alamat'] = dataLawanTransaksi ? dataLawanTransaksi.alamat : ""
													data['namaPic'] = dataLawanTransaksi ? dataLawanTransaksi.namaPic : ""
													data['emailPic'] = dataLawanTransaksi ? dataLawanTransaksi.emailPic : ""
													data['noTeleponPic'] = dataLawanTransaksi ? dataLawanTransaksi.noTeleponPic : ""
													data['alamatPic'] = dataLawanTransaksi ? dataLawanTransaksi.alamatPic : ""
												}
											})

										}
										return data
									}}
									onSubmit={onSubmitIdentitas}
								/>
							</ExpansionPanel>
							<br />
							{/* FORM TRANSAKSI */}
							<ExpansionPanel expanded={panel2} onClick={() => activePanel2()} header={t.translate('words.transaksi')}>
								<FormWrapper
									submitLabel={t.translate('words.next')}
									loading={isReady}
									showCommandbar={false}
									baseId={`mod-form-${baseName}`}
									style={{
										maxWidth: '100%'
									}}
									defaultData={item}
									definitions={[
										//------------------------------------------------------------
										{
											render: (
												<div style={{ width: '100%' }}>
													<p><b>{t.translate(`words.transaksi`)}</b></p>
												</div>
											)
										},
										{
											inputType: inputTypes.DIVIDER,
											label: t.translate(`words.dataPesanan`)
										},
										{
											inputType: inputTypes.DATEPICKER,
											label: t.translate(`words.tanggalPesanan`),
											key: 'tanggalPesan',
											validation: 'required',
										},
										{
											inputType: inputTypes.AUTOCOMPLETE,
											label: t.translate(`words.kurir`),
											key: 'kurirId',
											labelKey: 'label',
											valueKey: 'label',
											type: 'search',
											validation: 'required',
											data: dataKurir,
										},

									]}
									onChange={async (data, key, value) => {
										if (key == 'kurirId') {
											dataKurir.map((d) => {
												if (d.label == value) {
													data['kurir']['id'] = d.value
													data['kurir']['nama'] = d.nama
												}
											})
										}
										return data
									}}
									onSubmit={onSubmitTransaksi}
								/>
							</ExpansionPanel>

							<br />
							{/* FORM PRODUK */}
							<ExpansionPanel expanded={panel3} onClick={() => activePanel3()} header={t.translate('words.produk')}>
								<FormWrapper
									submitLabel={t.translate('words.submit')}
									loading={isReady}
									showCommandbar={false}
									baseId={`mod-form-${baseName}`}
									style={{
										maxWidth: '100%'
									}}
									defaultData={item}
									definitions={[
										//----------------------------------------------------------
										{
											render: (
												<div style={{ width: '100%' }}>
													<p><b>{t.translate(`words.produk`)}</b></p>
												</div>
											)
										},
										{
											render: (
												<div style={{ width: '100%' }}>
													<Button
														theme="primary"
														themeType="contained"
														style={{ margin: 15 }}
														onClick={() => {
															setDialogPesananProdukForm(true)
														}}>
														<FontIcon iconClassName="mdi mdi-plus" />{t.translate("words.add")}
													</Button>
													<Table style={{ width: '100%' }}>
														<TableHeader>
															<TableRow>
																<TableCell style={{ width: '10%' }}>{t.translate('words.non')}</TableCell>
																<TableCell style={{ width: '25%' }}>{t.translate('words.kode')}</TableCell>
																<TableCell style={{ width: '25%' }}>{t.translate('words.nama')}</TableCell>
																<TableCell style={{ width: '25%' }}>{t.translate('words.jumlah')}</TableCell>
																<TableCell style={{ width: '30%' }}>{t.translate('words.subtotal')}</TableCell>
																<TableCell style={{ width: '10%' }}>{t.translate('words.dpp')}</TableCell>
																<TableCell style={{ width: '10%' }}>{t.translate('words.ppn')}</TableCell>
																{/* <TableCell style={{ width: '10%' }}>{t.translate('words.ppnBm')}</TableCell> */}
															</TableRow>
														</TableHeader>
														<TableBody>
															{produkList.map((d, index) => {
																return (
																	<TableRow key={index}>
																		<TableCell>{index + 1}</TableCell>
																		<TableCell>{d.kode}</TableCell>
																		<TableCell>{d.nama}</TableCell>
																		<TableCell>{d.jumlahBarang}</TableCell>
																		<TableCell>{d.subtotal}</TableCell>
																		<TableCell>{d.dpp}</TableCell>
																		<TableCell>{d.ppn}</TableCell>
																		{/* <TableCell>{d.ppnbm}</TableCell> */}
																		<TableCell>
																			{/* <Button onClick={()=> console.log("Masuk")}>{t.translate("words.edit")}</Button> */}
																			<Button onClick={() => deleteProduk(index)}>{t.translate("words.delete")}</Button>
																		</TableCell>
																	</TableRow>
																)
															})}
														</TableBody>
														<TableFooter>
															<TableRow>
																<TableCell></TableCell>
																<TableCell><b>Total Pemesanan</b></TableCell>
																<TableCell></TableCell>
																<TableCell><b>Total DPP</b></TableCell>
																<TableCell></TableCell>
																<TableCell><b>Total PPn</b></TableCell>
																<TableCell></TableCell>
																{/* <TableCell><b>Total PPnBm</b></TableCell> */}
																<TableCell></TableCell>
															</TableRow>
															{/* {getPesanan.map((d) => { */}
															{/* return ( */}
															<TableRow>
																<TableCell></TableCell>
																<TableCell><b>{produkList.totalPesanan}</b></TableCell>
																<TableCell></TableCell>
																<TableCell><b>{produkList.totalDpp}</b></TableCell>
																<TableCell></TableCell>
																<TableCell><b>{produkList.totalPpn}</b></TableCell>
																<TableCell></TableCell>
																{/* <TableCell><b>{produkList.totalPpnbm}</b></TableCell> */}
																<TableCell></TableCell>
															</TableRow>
														</TableFooter>
													</Table>
												</div>
											)
										},
										{
											render: (
												<div style={{ margin: 10 }}></div>
											)
										},
									]}
									onSubmit={onSubmitProduk}
								/>
								<PesananProdukForm
									visible={dialogPesananProdukForm}
									onRequestClose={() => {
										addProdukForm();
										setDialogPesananProdukForm(false)
									}}
								/>
							</ExpansionPanel>
						</ExpansionList>
					)
				},
			]}
		/>
	)
	function _closeDialog() {
		setDialogPesananProdukForm(false)
	}
}
export default inject('envStore', 'modalStore', 'navigationStore')(observer(PesananInvoiceNonPkp))
