import { crud, http } from '../../libs/react-mpk/services';

let service = null;
let serviceUrl = '/produk'
let npwpHeadIdObject = {
    npwpHeadId: sessionStorage.getItem('npwpHeadId')
}
let npwpHeadId = sessionStorage.getItem('npwpHeadId')
service = new crud(`/produk`)

service.getAllKategori = async (query) => {
    query = {
        ...query,
        ... npwpHeadIdObject
    }
    return http.request({
        method: http.methods.GET,
        url: `${serviceUrl}/kategori`,
        query
    })
}
service.getAllUnit = async (query) => {
    query = {
        ...query,
        ... npwpHeadIdObject
    }
    return http.request({
        method: http.methods.GET,
        url: `${serviceUrl}/unit`,
        query
    })
}
service.getKategoriKode = async (query) => {
    query = {
        ...query,
        // ... npwpHeadIdObject
    }
    return http.request({
        method: http.methods.GET,
        url: `${serviceUrl}/kategori/generate_kode/${npwpHeadId}`,
        query
    })
}
service.getUnitKode = async (query) => {
    query = {
        ...query,
        // ... npwpHeadIdObject
    }
    return http.request({
        method: http.methods.GET,
        url: `${serviceUrl}/unit/generate_kode/${npwpHeadId}`,
        query
    })
}
service.postKategori = async (data) => {
    data = {
        ...data,
        npwpHead: {
            id: npwpHeadId
        }
    }
    return http.request({
        method: http.methods.POST,
        url: `${serviceUrl}/kategori`,
        data,
    })
}
service.postUnit = async (data) => {
    data = {
        ...data,
        npwpHead: {
            id: npwpHeadId
        }
    }
    return http.request({
        method: http.methods.POST,
        url: `${serviceUrl}/unit`,
        data,
    })
}
service.editKategori = async (id, data) => {
    return http.request({
        method: http.methods.PUT,
        url: `${serviceUrl}/kategori/${id}`,
        data,
        config: {
            params: {
                npwpHeadId: npwpHeadId
            }
        }
    })
}
service.editUnit = async (id, data) => {
    return http.request({
        method: http.methods.PUT,
        url: `${serviceUrl}/unit/${id}`,
        data,
        config: {
            params: {
                npwpHeadId: npwpHeadId
            }
        }
    })
}
service.deleteKategori = async (id) => {
    return http.request({
        method: http.methods.DELETE,
        url: `${serviceUrl}/kategori/${id}`
    })
}
service.deleteUnit = async (id) => {
    return http.request({
        method: http.methods.DELETE,
        url: `${serviceUrl}/unit/${id}`
    })
}
service.getOneByIdKategori = async(id) => {
    return http.request({
      method: http.methods.GET,
      url: `${serviceUrl}/kategori/${id}`
    })
}
service.getOneByIdUnit = async(id) => {
    return http.request({
      method: http.methods.GET,
      url: `${serviceUrl}/unit/${id}`
    })
}
export default service;