import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import service from "./Penjualan.service";
import { inputTypes } from "../../libs/react-mpk/config/constant";
import t from "counterpart";
import { FormWrapper } from "../../libs/react-mpk/wrapper";
import {
  Button,
  Divider,
  ExpansionList,
  ExpansionPanel,
  FontIcon,
  Label,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableRow,
  Text,
  TextContainer,
} from "react-md";
import { defaultDataPenjualanDetail } from "../defaultData";
import moment from "moment";
import "moment/locale/id";
import {
  format,
  formatRupiah,
} from "../../libs/react-mpk/services/number.service";
import utilsService from "../../services/utilsService";
import PreviewFile from "../PreviewFile/PreviewFile";
import { toast } from "../../libs/react-mpk/services";
const PenjualanDetail = ({ navigationStore, envStore, match, ...props }) => {
  var backTo = `/internal/npwp/${match.params.npwpHeadId}/penjualan`;
  const baseName = `penjualan`;
  const [item, setItem] = useState(defaultDataPenjualanDetail);
  const [noInvoice, setNoInvoice] = useState("");
  const [listProdukPenjualan, setListProdukPenjualan] = useState([]);
  const [listAkunPenjualan, setListAkunPenjualan] = useState([]);
  const [listBeban, setListBeban] = useState([]);
  const [pajakSetor, setPajakSetor] = useState(true);
  const [panel1, setPanel1] = useState(true);
  const [panel2, setPanel2] = useState(false);
  const [panel3, setPanel3] = useState(false);
  const [showDialog, setDialog] = useState(false);
  let [file, setFile] = useState(null);
  let [type, setType] = useState(null);
  const fetchData = async () => {
    try {
      let res = await service
        .getByTransactionId(match.params.id)
        .catch((e) => service.getById(match.params.id));
      setItem(res.data);
      setNoInvoice(res.data.noInvoice);
      getDataListProdukPenjualan(res.data.id);
      getListAkunPenjualanById(res.data.id);
      getListAkunBebanById(res.data.id);
    } catch (error) {
      toast.errorRequest(error);
    }
  };
  const getDataListProdukPenjualan = async (id) => {
    try {
      let res = await service.getListPenjualanById(id);
      setListProdukPenjualan(res.data);
    } catch (error) {
      toast.errorRequest(error);
    }
  };
  const getListAkunPenjualanById = async (id) => {
    try {
      let res = await service.getListAkunPenjualanById(id);
      let data = res.data
        .filter((d, i) => d.akun.nama === "Penjualan")
        .map((d) => d.pajakSetorSendiri);
      setPajakSetor(data[0]);
      setListAkunPenjualan(res.data);
    } catch (error) {
      toast.errorRequest(error);
    }
  };
  const getListAkunBebanById = async (id) => {
    try {
      let res = await service.getListAkunBebanById(id);
      setListBeban(res.data);
    } catch (error) {
      toast.errorRequest(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const activePanel1 = () => {
    setPanel1(true);
    setPanel2(false);
    setPanel3(false);
  };
  const activePanel2 = () => {
    setPanel1(false);
    setPanel2(true);
    setPanel3(false);
  };
  const activePanel3 = () => {
    setPanel1(false);
    setPanel2(false);
    setPanel3(true);
  };

  return (
    <>
      <FormWrapper
        showCommandbar={true}
        baseId={`mod-form-${baseName}`}
        title={
          t.translate(`words.detailPenjualan`) +
          ` || ` +
          utilsService.beautifyNoTransaksi(noInvoice)
        }
        style={{
          maxWidth: "100%",
        }}
        defaultData={[]}
        definitions={[
          {
            render: (
              <ExpansionList style={{ width: "100%" }}>
                <ExpansionPanel
                  expanded={panel1}
                  onClick={() => activePanel1()}
                  header={t.translate("words.identity")}
                >
                  <FormWrapper
                    submitLabel={t.translate("words.next")}
                    showCommandbar={false}
                    baseId={`mod-form-${baseName}`}
                    style={{
                      maxWidth: "100%",
                    }}
                    defaultData={item.lawanTransaksi}
                    definitions={[
                      {
                        render: (
                          <div style={{ width: "100%", marginBottom: 30 }}>
                            <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                              {t.translate(`words.dataLawanTransaksi`)}
                              <Divider />
                            </Text>
                          </div>
                        ),
                      },
                      {
                        inputType: inputTypes.INPUT,
                        label: t.translate(`words.name`),
                        key: "nama",
                        disabled: true,
                        type: "text",
                        width: "100%",
                      },
                      {
                        inputType: inputTypes.INPUT_MASK_NUMBER,
                        label: t.translate(`words.npwp`),
                        key: "npwp",
                        mask: "##.###.###.#-###.###",
                        maskChar: "_",
                        maskValue: "string",
                        disabled: true,
                        width: "49%",
                        style: {
                          marginRight: "2%",
                        },
                      },
                      {
                        inputType: inputTypes.INPUT,
                        label: t.translate(`words.nikPaspor`),
                        key: "nik",
                        mask: "################",
                        maskChar: "_",
                        maskValue: "string",
                        disabled: true,
                        width: "49%",
                        show: item.lawanTransaksi.nik == "" ? false : true,
                      },
                      {
                        inputType: inputTypes.INPUT,
                        label: t.translate(`words.address`),
                        key: "alamat",
                        disabled: true,
                        type: "text",
                        width: "100%",
                      },
                      {
                        render: (
                          <div style={{ width: "100%", marginBottom: 30 }}>
                            <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                              {t.translate(`words.picPenagihan`)}
                              <Divider />
                            </Text>
                          </div>
                        ),
                      },
                      {
                        inputType: inputTypes.INPUT,
                        label: t.translate(`words.namaPenanggungJawab`),
                        key: "namaPic",
                        disabled: true,
                        variant: "filled",
                        type: "text",
                        width: "49%",
                        style: {
                          marginRight: "2%",
                        },
                      },
                      {
                        inputType: inputTypes.INPUT,
                        label: t.translate(`words.emailPic`),
                        key: "emailPic",
                        disabled: true,
                        variant: "filled",
                        type: "text",

                        width: "49%",
                      },
                      {
                        inputType: inputTypes.INPUT,
                        label: t.translate(`words.nomorTeleponPic`),
                        key: "noTeleponPic",
                        disabled: true,
                        variant: "filled",
                        type: "text",
                        width: "49%",
                        style: {
                          marginRight: "2%",
                        },
                      },
                      {
                        inputType: inputTypes.INPUT,
                        label: t.translate(`words.alamatPic`),
                        key: "alamatPic",
                        disabled: true,
                        variant: "filled",
                        type: "text",
                        width: "49%",
                      },
                    ]}
                  />
                </ExpansionPanel>
                <br />
                <ExpansionPanel
                  expanded={panel2}
                  onClick={() => activePanel2()}
                  header={t.translate("words.transaksi")}
                >
                  <FormWrapper
                    showCommandbar={false}
                    baseId={`mod-form-${baseName}`}
                    style={{
                      maxWidth: "100%",
                    }}
                    defaultData={item}
                    definitions={[
                      {
                        render: (
                          <div style={{ width: "100%", marginBottom: 30 }}>
                            <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                              {t.translate(`column.general`)}
                              <Divider />
                            </Text>
                          </div>
                        ),
                      },
                      {
                        inputType: inputTypes.INPUT,
                        label: t.translate(`words.status`),
                        disabled: true,
                        type: "text",
                        value:
                          item.pembayaran.status == 1
                            ? "Belum Bayar"
                            : item.pembayaran.status == 2
                            ? "Bayar Sebagian"
                            : item.pembayaran.status == 3
                            ? "Lunas"
                            : "Batal",
                        width: "49%",
                        style: {
                          marginRight: "2%",
                        },
                      },
                      {
                        inputType: inputTypes.INPUT,
                        label: "Faktur Atas Transaksi",
                        disabled: true,
                        type: "text",
                        value: "Normal",
                        width: "49%",
                      },
                      {
                        render: (
                          <div style={{ width: "100%", marginBottom: 30 }}>
                            <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                              {t.translate(`words.dataInvoice`)}
                              <Divider />
                            </Text>
                          </div>
                        ),
                      },
                      {
                        inputType: inputTypes.INPUT,
                        label: t.translate(`words.noInvoice`),
                        disabled: true,
                        key: "noInvoice",
                        value: utilsService.beautifyNoTransaksi(noInvoice),
                        type: "text",
                        width: "49%",
                        style: {
                          marginRight: "2%",
                        },
                      },
                      {
                        inputType: inputTypes.INPUT,
                        label: t.translate(`words.tanggalInvoice`),
                        disabled: true,
                        type: "text",
                        value: moment(item.tanggalInvoice)
                          .locale("id")
                          .format("DD MMMM YYYY"),
                        width: "49%",
                      },
                      {
                        inputType: inputTypes.INPUT,
                        label: t.translate(`words.akunPiutang`),
                        value: `${item.akunPiutang?.kode} - ${item.akunPiutang?.nama}`,
                        disabled: true,
                        type: "text",
                        show: item.akunPiutang === null ? false : true,
                      },
                      {
                        inputType: inputTypes.INPUT_MASK_NUMBER,
                        label: "Periode Jatuh Tempo (Hari)",
                        disabled: true,
                        value: `${item.periodeJatuhTempo} Hari`,
                        maskValue: "string",
                        width: "49%",
                        style: {
                          marginRight: "2%",
                        },
                        show: item.periodeJatuhTempo != 0,
                      },
                      {
                        inputType: inputTypes.INPUT,
                        label: t.translate(`words.tanggalJatuhTempo`),
                        disabled: true,
                        type: "text",
                        value: moment(item.tanggalJatuhTempo)
                          .locale("id")
                          .format("DD MMMM YYYY"),
                        width: "49%",
                        show: item.tanggalJatuhTempo === null ? false : true,
                      },
                      {
                        inputType: inputTypes.CHECKBOX,
                        label: `PPh Omzet Dipungut Oleh Pihak Lain`,
                        key: "isPph",
                        name: "isPph",
                        style: {
                          width: "100%",
                        },
                        disabled: true,
                        value: pajakSetor ? false : true,
                      },
                    ]}
                  />
                </ExpansionPanel>
                <br />
                <ExpansionPanel
                  expanded={panel3}
                  onClick={() => activePanel3()}
                  header={t.translate("words.produk")}
                >
                  <FormWrapper
                    showCommandbar={false}
                    baseId={`mod-form-${baseName}`}
                    style={{
                      maxWidth: "100%",
                    }}
                    defaultData={item}
                    definitions={[
                      {
                        render: (
                          <div style={{ width: "100%" }}>
                            <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                              Produk Penjualan
                              <Divider />
                            </Text>
                            {listProdukPenjualan.length > 0 ? (
                              <>
                                <Table style={{ width: "100%" }}>
                                  <TableHeader>
                                    <TableRow>
                                      <TableCell style={{ width: "5%" }}>
                                        No
                                      </TableCell>
                                      <TableCell style={{ width: "5%" }}>
                                        Kode
                                      </TableCell>
                                      <TableCell style={{ width: "15%" }}>
                                        Nama
                                      </TableCell>
                                      <TableCell style={{ width: "5%" }}>
                                        Kuantitas
                                      </TableCell>
                                      <TableCell style={{ width: "10%" }}>
                                        Harga Satuan
                                      </TableCell>
                                      <TableCell style={{ width: "10%" }}>
                                        Diskon (%)
                                      </TableCell>
                                      <TableCell style={{ width: "10%" }}>
                                        PPN
                                      </TableCell>
                                      <TableCell style={{ width: "15%" }}>
                                        Jumlah
                                      </TableCell>
                                      <TableCell style={{ width: "25%" }}>
                                        Keterangan
                                      </TableCell>
                                    </TableRow>
                                  </TableHeader>
                                  <TableBody>
                                    {listProdukPenjualan.map((d, index) => {
                                      return (
                                        <TableRow key={index}>
                                          <TableCell>{index + 1}</TableCell>
                                          <TableCell>
                                            {d.produk?.kode}
                                          </TableCell>
                                          <TableCell>
                                            {d.produk?.nama}
                                          </TableCell>
                                          <TableCell>
                                            {d.jumlahBarang}
                                          </TableCell>
                                          <TableCell>
                                            {formatRupiah(d.hargaSatuan)}
                                          </TableCell>
                                          <TableCell>
                                            {format(
                                              (d.diskon /
                                                (d.hargaSatuan *
                                                  d.jumlahBarang)) *
                                                100
                                            )}{" "}
                                            %
                                          </TableCell>
                                          <TableCell>
                                            {formatRupiah(d.ppn)}
                                          </TableCell>
                                          <TableCell>
                                            {formatRupiah(d.subtotal)}
                                          </TableCell>
                                          <TableCell>
                                            {d.keterangan
                                              ? d.keterangan.substring(0, 50)
                                              : "-"}
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })}
                                  </TableBody>
                                </Table>
                              </>
                            ) : (
                              <TextContainer style={{ textAlign: "center" }}>
                                <Text
                                  type="headline-6"
                                  style={{ color: "#7f8c8d" }}
                                >
                                  Tidak ada data
                                </Text>
                              </TextContainer>
                            )}
                          </div>
                        ),
                      },
                      {
                        render: (
                          <Divider
                            style={{ margin: 10, width: "100%" }}
                          ></Divider>
                        ),
                      },
                      {
                        inputType: inputTypes.INPUT,
                        label: t.translate(`words.keterangan`),
                        key: "keterangan",
                        type: "text",
                        width: "40%",
                        style: { marginRight: "30%" },
                        value: item.keterangan,
                        disabled: true,
                      },
                      {
                        inputType: inputTypes.INPUT_MASK_NUMBER,
                        label: "SubTotal",
                        key: "hargaTotal",
                        thousandSeparator: ".",
                        decimalSeparator: ",",
                        isNumericString: true,
                        prefix: "Rp.",
                        type: "text",
                        width: "30%",
                        value: utilsService.beautifyNominal(
                          listProdukPenjualan
                            .map((d) => d.hargaSatuan * d.jumlahBarang)
                            .reduce((subtotal, datas) => {
                              return subtotal + datas;
                            }, 0)
                        ),
                        disabled: true,
                      },
                      item.fileLampiran != null && {
                        render: (
                          <div style={{ width: "40%", marginRight: "30%" }}>
                            {" "}
                            <Button
                              theme="primary"
                              themeType="outline"
                              style={{ width: "100%" }}
                              onClick={async () => {
                                service
                                  .DownloadLampiranPenjualan(item.id)
                                  .then((response) => {
                                    const type =
                                      response.headers["content-type"];
                                    let blob = new Blob([response.data], {
                                        type: type,
                                      }),
                                      datas = window.URL.createObjectURL(blob);
                                    setFile(datas);
                                  });
                                if (item.fileLampiran.includes(".pdf")) {
                                  setType("pdf");
                                } else {
                                  setType("image");
                                }
                                setDialog(true);
                              }}
                            >
                              <FontIcon iconClassName={`mdi mdi-file-find`} />
                              <Label>File Lampiran</Label>
                            </Button>
                          </div>
                        ),
                      },
                      {
                        inputType: inputTypes.INPUT_MASK_NUMBER,
                        label: "Diskon",
                        key: "diskon",
                        thousandSeparator: ".",
                        decimalSeparator: ",",
                        isNumericString: true,
                        prefix: "Rp.",
                        type: "text",
                        width: "30%",
                        value: utilsService.beautifyNominal(
                          listProdukPenjualan
                            .map((d) => d.diskon)
                            .reduce((diskon, datas) => {
                              return diskon + datas;
                            }, 0)
                        ),
                        disabled: true,
                        style: {
                          marginLeft: item.fileLampiran != null ? "0%" : "70%",
                        },
                      },
                      {
                        inputType: inputTypes.INPUT_MASK_NUMBER,
                        label: "PPn",
                        key: "ppn",
                        thousandSeparator: ".",
                        decimalSeparator: ",",
                        isNumericString: true,
                        type: "text",
                        prefix: "Rp.",
                        width: "30%",
                        value: utilsService.beautifyNominal(
                          listProdukPenjualan
                            .map((d) => d.ppn)
                            .reduce((ppn, datas) => {
                              return ppn + datas;
                            }, 0)
                        ),
                        disabled: true,
                        style: { marginLeft: "70%" },
                      },
                      {
                        inputType: inputTypes.INPUT_MASK_NUMBER,
                        label: "Total",
                        key: "subtotal",
                        thousandSeparator: ".",
                        decimalSeparator: ",",
                        isNumericString: true,
                        prefix: "Rp.",
                        type: "text",
                        width: "30%",
                        style: { marginLeft: "70%" },
                        value: utilsService.beautifyNominal(
                          listProdukPenjualan
                            .map((d) => d.subtotal)
                            .reduce((subtotal, datas) => {
                              return subtotal + datas;
                            }, 0)
                        ),
                        disabled: true,
                      },
                      {
                        render: <div style={{ margin: 10 }}></div>,
                      },
                    ]}
                  />
                </ExpansionPanel>
                <PreviewFile
                  visible={showDialog}
                  onRequestClose={() => {
                    setDialog(false);
                  }}
                  onClickDownload={() => {
                    service.getDownloadLampiranById(item.id);
                  }}
                  file={file}
                  type={type}
                />
              </ExpansionList>
            ),
          },
        ]}
      />
    </>
  );
};

export default inject("envStore")(observer(PenjualanDetail));
