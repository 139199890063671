import React, { useEffect, useState } from 'react';
import { Router, Switch, Route } from "react-router-dom";
import { sso } from '../libs/react-mpk/hocs';
import { PageWrapper } from '../libs/react-mpk/wrapper';
import { DataForm } from '../libs/react-mpk/components';
import { inputTypes } from '../libs/react-mpk/config/constant'
import t from 'counterpart'
import { AkunNpwp } from '../modules';
import { inject, observer } from 'mobx-react';

const MenuAkunNpwp = ({
  history,
  match,
  envStore,
  ...props
}) => {
  var npwpHeadId = match.params.npwpHeadId
  const [optionTahun, setOptionTahun] = useState([])
  const [defaultTahun, setDefaultTahun] = useState({ tahun: new Date().getFullYear().toString() })

  const basePath = `/internal/npwp`

  useEffect(() => {
    getOptionTahun();
    sessionStorage.removeItem('npwpHead')
  }, [])

  const getOptionTahun = async () => {

    let listYears = [];
    let currentYear = new Date().getFullYear();
    if (sessionStorage.getItem('tahunSobatBuku')) {
      setDefaultTahun({ tahun: sessionStorage.getItem('tahunSobatBuku') })
    }
    else {
      sessionStorage.setItem('tahunSobatBuku', currentYear);
    }

    listYears.push(currentYear.toString());
    for (let i = 1; i < 5; i++) {
      listYears.push((currentYear - i).toString());
    }
    setOptionTahun(listYears);

  }

  var sidebarMenuActive = [
    {
      "label":"Menu"
    },
    {
      "label": "Menu Utama",
      "children":[
        {
          "label":"Akun Npwp",
          "index":"akun, npwp, akunNpwp",
          "resourceUri":"",
          "iconClassName":"mdi mdi-table",
          "path":"/internal/npwp",
          "childPath":[]
        }
      ]
    }
  ]

  return (
    <>
      <PageWrapper
        inverseTheme={true}
        style={{background: '#D14C21'}}
        sidebarCollapsible={true}
        sidebarDefaultCollapse={false}
        sidebarHeader={(
          <DataForm
            baseId={`tahun`}
            defaultData={defaultTahun}
            onSubmit={null}
            definitions={[
              {
                render: (
                <div>
                  <p style={{marginLeft: "10px"}}>Version {envStore.env.appInfo.version}</p><br/>
                </div>
                )
              },
              {
                inputType: inputTypes.SELECT,
                label: t.translate(`words.tahun`),
                key: 'tahun',
                type: 'text',
                validation: 'required',
                options: optionTahun,
                // disabled: disabledKapKJS
              }
            ]}
            onChange={async (formData, key, value) => {
              sessionStorage.setItem('tahunSobatBuku', value);
              window.location.reload();
            }}
          />
        )}
        sidebarMenu={sidebarMenuActive}
      >
        <Router history={history}>
          <Switch>
            <Route
              path='/internal/npwp'
              render={props => (
                <AkunNpwp {...props} />
              )}
            />
          </Switch>
        </Router>
      </PageWrapper>
    </>
  )
}

export default sso({
  basePath: '/internal/npwp'
})(inject('envStore')(observer(MenuAkunNpwp)));