import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHeader,
  TableRow,
  Text,
} from "react-md";
import { DataForm } from "../../../libs/react-mpk/components";
import "./KasDanBankDetailList.scss";
import t from "counterpart";
import { format, formatRupiah } from "../../../libs/react-mpk/services/number.service";

const KasDanBankDetailList = ({
  visible = false,
  authStore,
  envStore,
  match,
  history,
  onRequestClose = () => {},
  ...props
}) => {
  let [ready, setReady] = useState(true);
  let [item, setItem] = useState({});

  useEffect(() => {
    setItem(props.item);
    setReady(visible);
  }, [visible]);

  return (
    ready && (
      <DataForm
        baseId="kasdanbank-detail-list"
        title={"Detail Akun"}
        visible={visible}
        onRequestClose={onRequestClose}
        asDialog={true}
        defaultData={[]}
        definitions={[
          {
            render: (
              <>
                <div className="Container">
                  <div className="Row">
                    <div className="Column">
                      <Text className="Title">Kode Akun</Text>
                      <Text className="SubTitle">
                        {item.kasBankAkun?.akun.kode}
                      </Text>
                    </div>
                    <div className="Column">
                      <Text className="Title">Nama Akun</Text>
                      <Text className="SubTitle">
                        {item.kasBankAkun?.akun.nama}
                      </Text>
                    </div>
                  </div>
                  {item?.kasBankAkunFkList?.length > 0 ||
                  item?.kasBankAkunFmList?.length > 0 ? (
                    <TableContainer style={{ maxHeight: "30vh" }}>
                      <Table fullWidth>
                        <TableHeader sticky>
                          <TableRow>
                            <TableCell style={{ width: "20%" }}>
                              {t.translate("words.non")}
                            </TableCell>
                            <TableCell style={{ width: "20%" }}>
                              {t.translate("words.nomorTransaksi")}
                            </TableCell>
                            <TableCell style={{ width: "20%" }}>
                              {t.translate("words.sisaTagihanAwal")}
                            </TableCell>
                            <TableCell style={{ width: "20%" }}>
                              {t.translate("words.sisaTagihanAkhir")}
                            </TableCell>
                            <TableCell style={{ width: "20%" }}>
                              {t.translate(
                                "words.nilaiTagihanOrPenerimaanYangTelahDibayarkan"
                              )}
                            </TableCell>
                          </TableRow>
                        </TableHeader>
                        <TableBody>
                          {item?.kasBankAkunFkList?.length > 0 &&
                            item?.kasBankAkunFkList?.map((d, index) => {
                              return (
                                <TableRow key={index}>
                                  <TableCell>{index + 1}</TableCell>
                                  <TableCell>{d.fk.noInvoice}</TableCell>
                                  <TableCell>
                                    {formatRupiah(d.fk.total)}
                                  </TableCell>
                                  <TableCell>
                                    
                                    {formatRupiah(d.fk.total - d.nilaiTagihanDibayar)}
                                  </TableCell>
                                  <TableCell>
                                     {formatRupiah(d.nilaiTagihanDibayar)}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          {item?.kasBankAkunFmList?.length > 0 &&
                            item?.kasBankAkunFmList?.map((d, index) => {
                              return (
                                <TableRow key={index}>
                                  <TableCell>{index + 1}</TableCell>
                                  <TableCell>{d.fm.noInvoice}</TableCell>
                                  <TableCell>
                                   {formatRupiah(d.fm.total)}
                                  </TableCell>
                                  <TableCell>
                                   
                                    {formatRupiah(d.fm.total - d.nilaiTagihanDibayar)}
                                  </TableCell>
                                  <TableCell>
                                   {formatRupiah(d.nilaiTagihanDibayar)}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TableCell>
                              <b>Total Tagihan</b>
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell>
                            
                              <b>
                                {formatRupiah(
                                  item?.kasBankAkunFkList?.length > 0
                                    ? item?.kasBankAkunFkList
                                        ?.map((d) => d.nilaiTagihanDibayar)
                                        .reduce((acc, total) => acc + total, 0)
                                    : item?.kasBankAkunFmList
                                        ?.map((d) => d.nilaiTagihanDibayar)
                                        .reduce((acc, total) => acc + total, 0)
                                )}
                              </b>
                            </TableCell>
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </TableContainer>
                  ) : (
                    <div className="Row">
                      <div className="Column">
                        <Text className="Title">Nilai Dibayar</Text>
                        <Text className="SubTitle">
                           {formatRupiah(item.kasBankAkun?.kasBank.nilaiDibayar)}
                        </Text>
                      </div>
                    </div>
                  )}
                </div>
              </>
            ),
          },
        ]}
      />
    )
  );
};

export default KasDanBankDetailList;
