import React, { useEffect, useState } from 'react'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import { FormWrapper, TableWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { find } from 'lodash'

const statusBayar = [
    { value: 'lunas', name: 'Lunas' },
    { value: 'belumDibayar', name: 'Belum Dibayar' }
]


const akun = [
    { value: '1.1.01.0001', name: 'Kas' },
    { value: '1.1.02.0001', name: 'Bank' }
]

const detailTransaksi = [
    { value: '07', name: 'Penyerahan yang PPN nya tidak dipungut' },
    { value: '08', name: 'Penyerahan yang PPN nya dipungut' },
    { value: '01', name: 'test' }
]

const masa = [
    { value: '1', name: '1' },
    { value: '2', name: '2' },
    { value: '3', name: '3' },
    { value: '4', name: '4' },
    { value: '5', name: '5' },
    { value: '6', name: '6' },
    { value: '7', name: '7' },
    { value: '8', name: '8' },
    { value: '9', name: '9' },
    { value: '10', name: '10' },
    { value: '11', name: '11' },
    { value: '12', name: '12' }
]

const tahun = [
    { value: '2014', name: '2014' },
    { value: '2015', name: '2015' },
    { value: '2016', name: '2016' },
    { value: '2017', name: '2017' },
    { value: '2018', name: '2018' },
    { value: '2019', name: '2019' },
    { value: '2020', name: '2020' },
    { value: '2021', name: '2021' }
]

const ketTambahan07 = [
    { value: '1', label: 'Kawasan Bebas' }
]

const ketTambahan08 = [
    { value: '1', label: 'Kawasan Test' }
]

const kredit = [
    { value: 'ya', label: 'Iya' },
    { value: 'tidak', label: 'Tidak' }
]

const PembelianPPNTransaksi = ({ envStore }) => {
    const { inputTypes, definition } = FormWrapper
    const [tempoDate, setTempoDate] = useState(null)
    const [disabled, setDisabled] = useState(true)
    const [hari, setHari] = useState(null)
    const [optionValue, setOptionValue] = useState([])
    const [data, setData] = useState(null)

    const fetchData = () => {
        let res = detailTransaksi
        setData(res.data)
    }

    useEffect(() => {
        fetchData()
    }, []);
    let showDataOption = (data) => {
        if (data.detailTransaksi == '07') {
            let dataOption = ketTambahan07.map(d => ({ label: d.label, value: d.value }))
            setOptionValue(dataOption)
        } else {
            let dataOption = ketTambahan08.map(d => ({ label: d.label, value: d.value }))
            setOptionValue(dataOption)
        }
    }


    return (
        <FormWrapper
            actions={[
                {
                    label: 'Reload',
                    iconClassName: 'mdi mdi-reload'
                }
            ]}
            backTo={`/${envStore.env.applicationType}/pembelianPPN/new`}
            baseId="mod-form-sample"
            title='Transaksi'
            hintShowIcon={false}
            defaultData={{
                noTransaksi: 'PB-2021/07/0001',
                jatuhTempo: 1,
            }}
            definitions={[
                {
                    inputType: inputTypes.DIVIDER,
                    label: 'Data Transaksi'
                },
                {
                    inputType: inputTypes.RADIO,
                    label: 'Pembelian Normal',
                    key: 'jnsTransaksi',
                    // name: 'Pembelian Normal',
                    value: 'normal',
                },
                {
                    inputType: inputTypes.DIVIDER,
                    label: 'Data Transaksi'
                },
                {
                    inputType: inputTypes.INPUT,
                    className: 'mpk-padding-N padding-right',
                    label: 'Nomor Transaksi',
                    key: 'noTransaksi',
                    required: true,
                    disabled: true,
                    width: '50%',
                },
                // -----
                {
                    inputType: inputTypes.DATE,
                    label: 'Tanggal Transaksi',
                    key: 'tanggalTransaksi',
                    width: '50%',
                    required: true,
                    disabled: disabled,
                    setTempoDate
                },
                {
                    inputType: inputTypes.SELECT,
                    className: 'mpk-padding-N padding-right',
                    label: 'Status Pembayaran',
                    key: 'statusPembayaran',
                    width: '50%',
                    required: true,
                    disabled: disabled,
                    options: statusBayar.map(d => ({
                        label: d.name,
                        value: d.value
                    }))
                },
                {
                    inputType: inputTypes.SELECT,
                    label: 'Akun Utang',
                    key: 'akunUtang',
                    width: '50%',
                    required: true,
                    disabled: disabled,
                    options: akun.map(d => ({
                        label: d.value + ' - ' + d.name,
                        value: d.value
                    })),
                    show: (d) => {
                        return d.statusPembayaran == 'belumDibayar'
                    }
                },
                {
                    inputType: inputTypes.INPUT_MASK_NUMBER,
                    type: TableWrapper.dataTypes.NUMBER,
                    className: 'mpk-padding-N padding-right',
                    label: 'Periode Jatuh Tempo (Hari)',
                    key: 'jatuhTempo',
                    required: true,
                    disabled: disabled,
                    width: '50%',
                    show: (d) => {
                        return d.statusPembayaran == 'belumDibayar'
                    }
                },
                {
                    inputType: inputTypes.DATE,
                    label: 'Tanggal Jatuh Tempo',
                    key: 'tanggalTempo',
                    width: '50%',
                    required: true,
                    disabled: true,
                    show: (d) => {
                        return d.statusPembayaran == 'belumDibayar'
                    }
                },
                {
                    inputType: inputTypes.SELECT,
                    className: 'mpk-padding-N padding-right',
                    label: 'Transfer Dari',
                    key: 'transfer',
                    width: '50%',
                    required: true,
                    disabled: disabled,
                    options: akun.map(d => ({
                        label: d.value + ' - ' + d.name,
                        value: d.value
                    })),
                    show: (d) => {
                        return d.statusPembayaran == 'lunas'
                    }
                },
                {
                    inputType: inputTypes.DIVIDER,
                    label: 'Data Faktur Pajak'
                },
                {
                    inputType: inputTypes.SELECT,
                    className: 'mpk-padding-N padding-right',
                    label: 'Detail Transaksi',
                    key: 'detailTransaksi',
                    width: '50%',
                    required: true,
                    disabled: disabled,
                    options: detailTransaksi.map(d => ({
                        label: d.value + ' - ' + d.name,
                        value: d.value
                    }))
                },
                {
                    inputType: inputTypes.SELECT,
                    label: 'Keterangan Tambahan',
                    key: 'ketTambahan',
                    options: optionValue,
                    // value == '07' ? ketTambahan07.map(d => ({ label: d.label, value: d.value })) : ketTambahan08.map(d => ({ label: d.label, value: d.value })),
                    width: '50%',
                    show: (d) => {
                        return d.detailTransaksi == "07" || d.detailTransaksi == '08'
                    },
                    required: true
                },
                {
                    inputType: inputTypes.SELECT,
                    className: 'mpk-padding-N padding-right',
                    label: 'Jenis Faktur',
                    key: 'jnsFaktur',
                    width: '50%',
                    required: true,
                    disabled: true,
                    options: detailTransaksi.map(d => ({
                        label: d.value + ' - ' + d.name,
                        value: d.value
                    }))
                },
                {
                    inputType: inputTypes.SELECT,
                    label: 'Nomor Seri Faktur Pajak',
                    className: 'mpk-padding-N padding-right',
                    key: 'noSeriFaktur',
                    width: '50%',
                    required: true,
                    disabled: true,
                    options: detailTransaksi.map(d => ({
                        label: d.value + ' - ' + d.name,
                        value: d.value
                    }))
                },
                {
                    inputType: inputTypes.SELECT,
                    className: 'mpk-padding-N padding-right',
                    label: 'Tanggal Faktur Pajak',
                    key: 'tglFakturPajak',
                    width: '50%',
                    required: true,
                    disabled: true,
                    options: detailTransaksi.map(d => ({
                        label: d.value + ' - ' + d.name,
                        value: d.value
                    }))
                },
                {
                    inputType: inputTypes.SELECT,
                    label: 'Masa',
                    key: 'masa',
                    className: 'mpk-padding-N padding-right',
                    width: '25%',
                    required: true,
                    disabled: true,
                    options: detailTransaksi.map(d => ({
                        label: d.value + ' - ' + d.name,
                        value: d.value
                    }))
                },
                {
                    inputType: inputTypes.SELECT,
                    label: 'Tahun',
                    key: 'tahun',
                    width: '25%',
                    required: true,
                    disabled: true,
                    options: detailTransaksi.map(d => ({
                        label: d.value + ' - ' + d.name,
                        value: d.value
                    }))
                },
                {
                    inputType: inputTypes.DIVIDER,
                    label: 'Pengkreditan'
                },
                {
                    inputType: inputTypes.SELECT,
                    className: 'mpk-padding-N padding-right',
                    label: 'Dapat Dikreditkan',
                    key: 'pengkreditan',
                    width: '50%',
                    required: true,
                    disabled: disabled,
                    options: kredit.map(d => ({
                        label: d.label,
                        value: d.value
                    }))
                },
                {
                    inputType: inputTypes.SELECT,
                    label: 'Masa',
                    key: 'masa',
                    className: 'mpk-padding-N padding-right',
                    width: '25%',
                    required: true,
                    disabled: disabled,
                    show: (d) => {
                        return d.pengkreditan == 'ya'
                    },
                    options: masa.map(d => ({
                        label: d.name,
                        value: d.value
                    }))
                },
                {
                    inputType: inputTypes.SELECT,
                    label: 'Tahun',
                    key: 'tahun',
                    width: '25%',
                    required: true,
                    disabled: disabled,
                    options: tahun.map(d => ({
                        label: d.name,
                        value: d.value
                    })),
                    show: (d) => {
                        return d.pengkreditan == 'ya'
                    },
                }
            ]}
            onChange={(data, key, value) => {
                if (key === 'statusPembayaran') {
                    let bayar = find(statusBayar, { name: value })
                    if (bayar) data.bayarId = bayar.value
                }
                if (key === 'akunUtang' && 'transfer') {
                    let utang = find(akun, { name: value })
                    if (utang) data.utangId = utang.value
                }
                if (key === 'tanggalTempo') {
                    let tempo = new Date()
                    tempo.setDate(tempo.getDate() + 1)
                }
                if (key === 'jnsTransaksi') {
                    setDisabled(false)
                }

                // if (key === 'detailTransaksi') {
                //     showDataOption()
                // }
                return data
            }}
        />
    )
}

export default inject('envStore')(observer(PembelianPPNTransaksi))