import React, { useState } from "react";
import { TableWrapper, TabsWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
// import service from './pph.service'
import moment from "moment";
import 'moment/locale/id';
// import PPhForm from './pphForm'
import { DataForm } from "../../libs/react-mpk/components";
import { FormWrapper } from "../../libs/react-mpk/wrapper";
import { format } from "../../libs/react-mpk/services/number.service";
import Modal from "../../libs/react-mpk/components/Modal";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogContent,
  DialogTitle,
  DialogFooter,
  Text,
} from "react-md";
import service from "./PPh.service";
import { defaultQueryParameter } from "../../libs/react-mpk/config/constant";
import { toast } from "../../libs/react-mpk/services";

const PPh = ({ className = "", history, showCommandbar = true }) => {
  const { inputTypes, definition } = FormWrapper;
  const [showForm, setShowForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [inputValidasiNpwp, setInputValidasiNpwp] = useState(
    "##.###.###.#-###.###"
  );
  const [dialogNilaiTagihan, setDialogNilaiTagihan] = useState(false);
  const [dialogData, setDialogData] = useState(false);
  const [dokumens, setDokumens] = useState([]);
  const [defaultData, setDefaultData] = useState([]);
  let tahunSobatPajak = sessionStorage.getItem("tahunSobatBuku");
  const basePath = `pph`;

  let defaultQuery = (query) => ({ ...defaultQueryParameter(), ...query });
  const [query, setQuery] = useState(
    defaultQuery({
      page: 0,
      size: 999,
      sort: "tanggalDibuat,DESC",
    })
  );

  function showDialogNilaiTagihan() {
    setDialogNilaiTagihan(true);
    setDialogData({});
  }

  var actions = [
    // new TableWrapper.action(
    //   `${t.translate('words.add')}`,
    //   'mdi mdi-plus',
    //   () => {
    //     setSelectedItem(null)
    //     setShowForm(true)
    //   },
    //   true)
  ];

  return (
    <>
      <TabsWrapper
        showCommandbar={false}
        tabs={[
          // MONITORING PEMOTONGAN PAJAK
          {
            label: t.translate("modules.pph.monitoringMenotongPajak.title"),
            render: (
              <>
                <TableWrapper
                  baseId="mod-table-sample"
                  title={t.translate("modules.pph.title")}
                  className={className}
                  hintMessage={t.translate("modules.pph.hint.message")}
                  hintIconClassName="mdi mdi-information"
                  hintMore={t.translate("modules.pph.hint.more")}
                  defaultData={[]}
                  onFetchData={(queryData) => {
                    return new Promise(async (resolve, reject) => {
                      // console.log(
                      //   'Masuk on fetch ---------------------------------------',
                      // );
                      try {
                        queryData = {
                          ...queryData,
                          ...query,
                          npwpHeadId: sessionStorage.getItem("npwpHeadId"),
                        };
                        let getPage = await service.getObjekPajak(queryData);
                        getPage.headers["x-pagination-count"] =
                          getPage.headers["x-total-count"];
                        resolve(getPage);
                      } catch (error) {
                        toast.errorRequest(error)
                        // setIsLoading(false);
                      }
                    });
                  }}
                  defaultSortBy="date"
                  defaultCollapse={true}
                  useCriteria={false}
                  selectable={false}
                  useQueryHistory={false}
                  // extraFilter={<div>Test</div>}
                  columns={[
                    {
                      label: "Pasal Pemotongan",
                      searchable: true,
                      sortable: false,
                      key: "nama",
                      render: (item) => item.pasalPemotongan.nama + ' - ' + item.nama,
                    },
                  ]}
                  actions={actions}
                  itemActions={[
                    new TableWrapper.action(
                      `${t.translate("words.periode")}`,
                      "mdi mdi-archive-arrow-up",
                      (item) => {
                        history.push(
                          `${basePath}/${item.id}/periode/${tahunSobatPajak}`
                        );
                      }
                    ),
                    // new TableWrapper.action(
                    //   `${t.translate('words.kirimEmail')}`,
                    //   'mdi mdi-email-send-outline',
                    //   (item) => {
                    //     // dialogKirimEmail(item);
                    //   }
                    // ),
                    // new TableWrapper.action(
                    //   'Delete',
                    //   'mdi mdi-delete',
                    //   (item) => {
                    //     console.log('request delete')
                    //   },
                    //   true
                    // )
                  ]}
                  // onFetchData={ async query => {
                  //   return service.get(query)
                  // }}
                  showCommandbar={showCommandbar}
                  showFilterPeriod={false}
                  useFilter={false}
                />
              </>
            ),
          },

          // MONITORING KREDIT PAJAK DAN BUKTI POTONG
          // {
          //   label: t.translate("modules.pph.monitoringKreditPajak.title"),
          //   render: (
          //     <>
          //       <TableWrapper
          //         baseId="mod-table-sample"
          //         className={className}
          //         hintMessage={t.translate(
          //           "modules.lawanTransaksi.detail.daftarAkunTagihan.hint.message"
          //         )}
          //         hintIconClassName="mdi mdi-information"
          //         hintMore={t.translate(
          //           "modules.lawanTransaksi.detail.daftarAkunTagihan.hint.more"
          //         )}
          //         defaultData={[
          //           {
          //             id: 123,
          //             kode: "1.707",
          //             nama: "Uang Muka Pembelian",
          //             nominal: "50000000",
          //           },
          //           {
          //             id: 123,
          //             kode: "1.101",
          //             nama: "Utang Usaha",
          //             nominal: "50000000",
          //           },
          //         ]}
          //         defaultSortBy="date"
          //         defaultCollapse={true}
          //         useCriteria={false}
          //         selectable={false}
          //         useQueryHistory={false}
          //         // extraFilter={<div>Test</div>}
          //         columns={[
          //           {
          //             label: "Kode",
          //             searchable: true,
          //             sortable: false,
          //             key: "kode",
          //             render: (item) => item.kode,
          //           },
          //           {
          //             label: "Nama",
          //             searchable: true,
          //             sortable: false,
          //             key: "nama",
          //             render: (item) => (
          //               <TableWrapper.Link to={`/internal/inline-form`}>
          //                 {item.nama}
          //               </TableWrapper.Link>
          //             ),
          //           },
          //           {
          //             label: "Nominal",
          //             searchable: true,
          //             sortable: false,
          //             key: "nominal",
          //             render: (item) => "Rp. " + format(item.nominal),
          //           },
          //         ]}
          //         actions={actions}
          //         itemActions={[
          //           new TableWrapper.action(
          //             "Detail",
          //             "mdi mdi-import",
          //             (item) => {
          //               showDialogNilaiTagihan();
          //             },
          //             true
          //           ),
          //           new TableWrapper.action(
          //             "Edit",
          //             "mdi mdi-pencil",
          //             (item) => {
          //               setSelectedItem(item);
          //               setShowForm(true);
          //             },
          //             true
          //           ),
          //           new TableWrapper.action(
          //             "Delete",
          //             "mdi mdi-delete",
          //             () => console.log("request delete"),
          //             true
          //           ),
          //         ]}
          //         // onFetchData={ async query => {
          //         //   return service.get(query)
          //         // }}
          //         showCommandbar={showCommandbar}
          //         showFilterPeriod={false}
          //         useFilter={false}
          //       />
          //     </>
          //   ),
          // },
        ]}
      />
    </>
  );

  function _closeDialog() {
    setDialogNilaiTagihan(false);
  }

  function _setDokumens(dokumens) {
    setDokumens(dokumens);
  }
};

export default PPh;
