import React, { useState } from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { DataForm } from '../../libs/react-mpk/components';
import { inputTypes } from '../../libs/react-mpk/config/constant'
import moment from 'moment'
import iziToast from 'izitoast'
import { inject, observer } from 'mobx-react'
import service from './JurnalUmum.service'
import JurnalUmumForm from './JurnalUmumForm'
import { format, formatRupiah } from './../../libs/react-mpk/services/number.service'
import utilsService from '../../services/utilsService';
import { toast } from '../../libs/react-mpk/services';

const JurnalUmum = ({
  className = '',
  showCommandbar = true,
  modalStore,
  match,
  navigationStore
}) => {
  var npwpHeadId = match.params.npwpHeadId
  let [search, setSearch] = useState({
  })
  const baseId = 'jurnal-umum'
  var actions = [
    new TableWrapper.action(
      `${t.translate('words.add')}`,
      'mdi mdi-plus',
      (item) => {
        navigationStore.redirectTo(
          `/internal/npwp/${npwpHeadId}/jurnal-umum/new`
        )
      },
      true),
  ]

  var itemActions = [
    new TableWrapper.action(
      `${t.translate('words.jurnalEntri')}`,
      'mdi mdi-application',
      (item) => {
        navigationStore.redirectTo(
          `/internal/npwp/${npwpHeadId}/jurnal-umum/${item.noTransaksi}/jurnal-entri`
        )
        sessionStorage.setItem('keterangan', item.keterangan)
      }
    ),
    new TableWrapper.action(
      'Delete',
      'mdi mdi-delete',
      (item) => {
        hapus(item)
      },
      true)
  ]

  async function hapusExc(item) {
    try {
      var r = await service.deleteById(item.id)

      if (r.data.message == 'sukses') {
        iziToast.success({
          message: r.data.message
        })
      } else {
        iziToast.info({
          message: r.data.message
        })
      }
      document.getElementsByClassName('mdi-reload')[0].click()
    } catch (e) {
      toast.errorRequest(e)

    }
  }

  async function hapus(item) {
    modalStore.showConfirm({
      title: t.translate('words.delete'),
      children: t.translate('words.confirmHapus'),
      onSubmit: (callback) => {
        hapusExc(item)
        callback()
      }
    })
  }

  return (
    <>
      <TableWrapper
        baseId={baseId}
        title={t.translate('modules.jurnalUmum.title')}
        className={className}
        hintMessage={t.translate('modules.jurnalUmum.hint.message')}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate('modules.jurnalUmum.hint.more')}
        defaultData={[]}
        defaultSortBy="tanggalDibuat"
        isShowFilter={false}
        showActionColumn={true}
        useFilter={true}
        defaultCollapse={true}
        useCriteria={true}
        selectable={false}
        useQueryHistory={false}
        onFetchData={(query) => {
          return (
            new Promise(async (resolve, reject) => {
              try {
                query = {
                  ...query
                }
                let getPage = await service.getPage(query)
                getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
                resolve(getPage)
              } catch (e) {
                resolve()
              }
            })
          )
        }}
        columns={[
          {
            label: 'Nomor Transaksi',
            searchable: true,
            sortable: false,
            key: 'noTransaksi',
            render: item => utilsService.beautifyNoTransaksi(item.noTransaksi)
          },
          {
            label: 'Debit',
            searchable: false,
            sortable: false,
            key: 'debit',
            render: item => (formatRupiah(item.totalDebit))
          },
          //tanggalTransaksiAwal
          {
            label: 'Kredit',
            searchable: false,
            sortable: false,
            key: 'kredit',
            render: item => (formatRupiah(item.totalKredit))
          },
          {
            label: 'Tanggal Transaksi',
            searchable: false,
            sortable: false,
            render: item => (moment(item.tanggalTransaksi).locale('id').format('DD MMMM YYYY'))
          },
          {
            label: 'Tanggal Dibuat',
            type: TableWrapper.dataTypes.DATE,
            render: item => (moment(item.tanggalDibuat).locale('id').format('DD MMMM YYYY, HH:mm:ss'))
          },
        ]}
        actions={actions}
        itemActions={itemActions}
        showCommandbar={showCommandbar}
        showFilterPeriod={false}
      />
      
    </>
  )
}

export default inject('modalStore', 'navigationStore')(observer(JurnalUmum))