import React, { useEffect, useState } from "react";
import { inputTypes } from "../../libs/react-mpk/config/constant";
import { FormWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import { find } from "lodash";
import {
  ExpansionList,
  ExpansionPanel,
  Table,
  TableBody,
  TableHeader,
  TableRow,
  TableCell,
  Button,
  FontIcon,
  Text,
  Divider,
  TextContainer,
} from "react-md";
import PenjualanProdukForm from "./PenjualanProdukForm";
import service from "./Penjualan.service";
import moment from "moment";
import "moment/locale/id";
import {
  format,
  formatRupiah,
} from "../../libs/react-mpk/services/number.service";
import LawanTransaksiForm from "../LawanTransaksi/LawanTransaksiForm";
import { toast } from "../../libs/react-mpk/services";
const KATEGORI_TRANSAKSI_NORMAL = "1";
const STAT_BELUM_BAYAR = 1;
const STAT_LUNAS = 3;
const statusBayar = [
  { value: STAT_LUNAS, name: "Lunas" },
  { value: STAT_BELUM_BAYAR, name: "Belum Dibayarkan" },
];
const dataPenggunaNpwp = JSON.parse(sessionStorage.getItem("dataPenggunaNpwp"));

const PenjualanFormNonPkp = ({
  envStore,
  modalStore,
  match,
  navigationStore,
  className = "",
  ...props
}) => {
  let [queryLawanTransaksi, setQueryLawanTransaksi] = useState("");
  let [queryAkunPiutang, setQueryAkunPiutang] = useState("");
  let [queryAkunKasBank, setQueryAkunKasBank] = useState("");
  let [item, setItem] = useState({
    isPph: false,
    lawanTransaksiId: "",
    lawanTransaksiNama: "",
    lawanTransaksiNik: "",
    lawanTransaksiNpwp: "",
    lawanTransaksiAlamat: "",
    lawanTransaksiNamaPic: "",
    lawanTransaksiEmailPic: "",
    lawanTransaksiNoTeleponPic: "",
    lawanTransaksiAlamatPic: "",
    lawanTransaksi: {
      id: null,
    },
    kategoriTransaksi: KATEGORI_TRANSAKSI_NORMAL,
    tanggalInvoice: "",
    statusPembayaran: "1",
    akunPiutang: {
      id: null,
    },
    pembayaranAkun: null,
    pembayaranAkunId: null,
    pembayaran: {
      status: null,
      akun: null,
    },
    periodeJatuhTempo: 0,
    tanggalJatuhTempo: "",
    subtotal: 0,
    subtotalAkun: 0,
    total: 0,
    totalPajakSetor: 0,
    totalBeban: 0,
    totalPenjualan: 0,
    fileLampiran: null,
    lampiran: {
      fileLampiranInstance: null,
      fileLampiran: null,
      fileLampiranAlias: null,
    },
    keterangan: null,
  });
  let [idAkun, setIdAkun] = useState(null);
  let [idAkun2, setIdAkun2] = useState(null);
  const [panel1, setPanel1] = useState(true);
  const [panel2, setPanel2] = useState(false);
  const [panel3, setPanel3] = useState(false);
  const [loadingAll, setLoadingAll] = useState(true);
  const [loading, setLoading] = useState(true);
  const [loadinglawanTransaksi, setLoadinglawanTransaksi] = useState(false);
  const [loadingakunPiutang, setLoadingakunPiutang] = useState(false);
  const [loadingakunKasBank, setLoadingakunKasBank] = useState(false);
  const [loadingakunPenjualan, setLoadingakunPenjualan] = useState(false);
  const [dialogPenjualanProdukForm, setDialogPenjualanProdukForm] =
    useState(false);
  const [dialogData, setDialogData] = useState(false);
  const [listAkunPiutang, setListAkunPiutang] = useState([]);
  const [listAkunKasBank, setListAkunKasBank] = useState([]);
  const [lawanTransaksiList, setLawanTransaksiList] = useState([]);
  const [dataTr, setDataTr] = useState([]);
  const [statusLunas, setStatusLunas] = useState(false);
  const [isLunas, setIsLunas] = useState(STAT_BELUM_BAYAR);
  const [dataPenjualan, setDataPenjualan] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [dataSubmit, setDataSubmit] = useState({
    fk: {
      kategoriTransaksi: KATEGORI_TRANSAKSI_NORMAL,
      tanggalInvoice: "",
      periodeJatuhTempo: 0,
      tanggalJatuhTempo: "",
      totalPenjualan: 0,
      subtotal: 0,
      subtotalAkun: 0,
      totalPajakSetor: 0,
      totalBeban: 0,
      total: 0,
      pembayaran: {
        status: "1",
        akun: {
          id: null,
        },
      },
      lawanTransaksi: {
        id: null,
      },
      npwpHead: {
        id: sessionStorage.getItem("npwpHeadId"),
      },
      akunPiutang: {
        id: null,
      },
      fileLampiranInstance: null,
      fileLampiran: null,
      fileLampiranAlias: null,
      isPpn: false,
      keterangan: null,
    },
    kategoriAkunDebitKredit: "",
    fkProdukList: [],
    fkAkunPenjualanList: [],
    fkAkunBebanList: [],
    fkProdukHapusList: [],
    fkAkunPenjualanHapusList: [],
    akunBebanHapusList: [],
  });
  async function getAkunPenerimaan() {
    setTimeout(async () => {
      let res = await service.getPenjualan("Penjualan", "4.1.01.0001");
      let res2 = await service.getPenjualan("Diskon Penjualan", "");

      res.data.map((d) => setIdAkun(d));
      res2.data.map((d) => setIdAkun2(d));
    }, 500);
  }

  useEffect(() => {
    setLoadinglawanTransaksi(true);
    console.log(queryLawanTransaksi, "queryLawanTransaksi");
    const timeOutId = setTimeout(
      () => lawanTransaksi(queryLawanTransaksi),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [queryLawanTransaksi]);

  //Akun Kas Bank
  useEffect(() => {
    setLoadingakunKasBank(true);
    const timeOutId = setTimeout(() => akunKasBank(queryAkunKasBank), 500);
    return () => clearTimeout(timeOutId);
  }, [queryAkunKasBank]);

  async function lawanTransaksi(queryNama) {
    setLoadinglawanTransaksi(true);
    try {
      let res = await service.getLawanTr(queryNama);
      var tr = [];
      res.data.map((d) => {
        // console.log(d, "d");
        tr.push({
          value: d.id,
          label: d.nama,
          nama: d.nama,
          nik: d.nik,
          npwp: d.npwp,
          alamat: d.alamat,

          alamatSama: d.alamatSama,
          namaPic: d.namaPic,
          emailPic: d.emailPic,
          noTeleponPic: d.noTeleponPic,
          alamatPic: d.alamatPic,
        });
      });
      // if (queryNama) {
      //   let cektr = find(tr, {
      //     label: queryNama,
      //   });
      //   if (!cektr) {
      //     tr.push({
      //       value: 0,
      //       label: queryNama,
      //       nama: queryNama,
      //       nik: "0000000000000000",
      //       npwp: "000000000000000",
      //       alamat: "-",
      //     });
      //   }
      // }
      setLawanTransaksiList(tr);
      setDataTr(res.data);

      // console.log(tr, "tr");

      setLoadinglawanTransaksi(false);
    } catch (error) {
      toast.errorRequest(error);
      setLoadinglawanTransaksi(false);
    }
  }

  async function akunPiutang(queryNama) {
    try {
      let res = await service.getPiutang(queryNama);
      setItem({
        ...item,
        akunPiutang: res.data[0].id,
        akunPiutangNama: `${res.data[0].kode} - ${res.data[0].nama}`,
      });
    } catch (error) {
      // toast.errorRequest(error);
    }
  }

  async function akunKasBank(queryNama) {
    try {
      setLoadingakunKasBank(true);
      let res = await service.getKasBank(queryNama);
      var kasBank = [];
      res.data.map((d) => {
        kasBank.push({
          value: d.id,
          label: d.nama,
        });
      });
      setListAkunKasBank(kasBank);
      setLoadingakunKasBank(false);
    } catch (error) {
      toast.errorRequest(error);
      setLoadingakunKasBank(false);
    }
  }

  useEffect(() => {
    setLoading(true);
    initData();
    akunPiutang("Piutang Usaha");
    getAkunPenerimaan();
    setLoading(false);
  }, []);
  async function initData() {
    let selectedItem = item;
    setItem(selectedItem);
  }
  function akunPenjualan() {
    setLoadingakunPenjualan(true);
    var akunPenjualanForm = JSON.parse(localStorage.getItem("produkForm"));
    // console.log(
    //   "🚀 ~ file: PenjualanFormNonPkp.js:246 ~ akunPenjualan ~ akunPenjualanForm:",
    //   akunPenjualanForm
    // );
    if (akunPenjualanForm) {
      var dataPenjualanNew = dataPenjualan;
      dataPenjualanNew.push(akunPenjualanForm);
      setDataPenjualan(dataPenjualanNew);

      localStorage.removeItem("produkForm");
    }
    setLoadingakunPenjualan(false);
  }
  async function hapusPenjualan(index) {
    modalStore.showConfirm({
      title: t.translate("words.delete"),
      children: t.translate("words.confirmHapus"),
      onSubmit: (callback) => {
        var deleteIndex = dataPenjualan;
        deleteIndex.splice(index, 1);
        setDataPenjualan(deleteIndex);
        callback();
      },
    });
  }

  function addItem() {
    setDialogPenjualanProdukForm(true);
    setDialogData({});
  }

  const activePanel1 = () => {
    setPanel1(true);
    setPanel2(false);
    setPanel3(false);
  };

  const activePanel2 = () => {
    setPanel1(false);
    setPanel2(true);
    setPanel3(false);
  };

  const activePanel3 = () => {
    setPanel1(false);
    setPanel2(false);
    setPanel3(true);
  };

  var onSubmitIdentitas = async (data, callback) => {
    setItem(data);
    activePanel2();
  };

  var onSubmitTransaksi = async (data, callback) => {
    setItem(data);
    activePanel3();
  };

  var onSubmitNonPkp = async (data, callback) => {
    let resGambar;
    var nilaiPenjualan = [];
    dataPenjualan.map((d) => {
      nilaiPenjualan.push(
        {
          akun: { id: idAkun.id, nama: idAkun.nama },
          isNilaiPenjualan: true,
          isTerutangPajak: true,
          pajakSetorSendiri: data.isPph ? false : true,
          ptsTarifPajak: 0.5,
          pasalPemotongan: { id: 1 },
          objekPajak: { id: 1 },
          nilaiPenjualan: d.hargaTotal,
          nominalHitungPajak: d.hargaTotal,
          nominalPajak: d.subtotal * (0.5 / 100),
          total: data.isPph
            ? d.hargaTotal - d.subtotal * (0.5 / 100)
            : d.hargaTotal,
        },
        {
          akun: { id: idAkun2.id, nama: idAkun2.nama },
          isNilaiPenjualan: true,
          isTerutangPajak: false,
          pajakSetorSendiri: false,
          ptsTarifPajak: 0.5,
          pasalPemotongan: { id: 1 },
          objekPajak: { id: 1 },
          nilaiPenjualan: d.diskon,
          nominalHitungPajak: d.diskon,
          nominalPajak: 0,
          total: d.diskon,
        }
      );
    });
    let totalPajakDisetor = nilaiPenjualan
      .filter((d) => d.pajakSetorSendiri === true)
      .map((d) => d.nominalPajak)
      .reduce((nominalPajak, datas) => {
        return nominalPajak + datas;
      }, 0);
    if (data.fileLampiran != null) {
      resGambar = await service.upload(data.fileLampiran);
    }
    let reqData = dataSubmit;
    //ISI FIELD
    reqData.fk.id = data.id;
    reqData.fk.lawanTransaksi = data.lawanTransaksi;
    reqData.fk.kategoriTransaksi = data.kategoriTransaksi;
    reqData.fk.tanggalInvoice = moment(data.tanggalInvoice).format(
      "yyyy-MM-DD hh:mm:ss"
    );
    if (data.statusPembayaran == STAT_BELUM_BAYAR) {
      reqData.fk.akunPiutang.id = data.akunPiutang;

      reqData.fk.pembayaran.status = STAT_BELUM_BAYAR;
      reqData.fk.pembayaran.akun = null;

      reqData.fk.periodeJatuhTempo = data.periodeJatuhTempo;
      reqData.fk.tanggalJatuhTempo = moment(data.tanggalJatuhTempo).format(
        "yyyy-MM-DD hh:mm:ss"
      );
    } else {
      reqData.fk.akunPiutang = null;

      reqData.fk.pembayaran.status = STAT_LUNAS;
      reqData.fk.pembayaran.akun.id = data.pembayaranAkunId;

      reqData.fk.periodeJatuhTempo = 0;
      reqData.fk.tanggalJatuhTempo = null;
    }
    reqData.fk.fileLampiran =
      data.fileLampiran != null ? resGambar.data.namaFileAlias : null;
    reqData.fk.fileLampiranAlias =
      data.fileLampiran != null ? resGambar.data.namaFileAlias : null;
    reqData.fk.fileLampiranInstance =
      data.fileLampiran != null ? resGambar.data.instanceId : null;
    reqData.fk.keterangan = data.keterangan;

    reqData.fk.subtotal =
      nilaiPenjualan
        .filter((d, i) => d.akun.nama === "Penjualan")
        .map((d) => d.total)
        .reduce((total, datas) => {
          return total + datas;
        }, 0) -
      nilaiPenjualan
        .filter((d, i) => d.akun.nama === "Diskon Penjualan")
        .map((d) => d.total)
        .reduce((total, datas) => {
          return total + datas;
        }, 0);
    reqData.fk.totalPenjualan = dataPenjualan
      .map((d) => d.subtotal)
      .reduce((subtotal, datas) => {
        return subtotal + datas;
      }, 0);
    reqData.fk.isPpn = dataPenjualan[0].isPpn;
    reqData.fk.subtotalAkun =
      nilaiPenjualan
        .filter((d, i) => d.akun.nama === "Penjualan")
        .map((d) => d.nilaiPenjualan)
        .reduce((nilaiPenjualan, datas) => {
          return nilaiPenjualan + datas;
        }, 0) -
      nilaiPenjualan
        .filter((d, i) => d.akun.nama === "Diskon Penjualan")
        .map((d) => d.nilaiPenjualan)
        .reduce((nilaiPenjualan, datas) => {
          return nilaiPenjualan + datas;
        }, 0);
    reqData.fk.totalPajakSetor = totalPajakDisetor;
    reqData.fk.totalBeban = 0;
    reqData.fk.total = reqData.fk.subtotal;
    reqData.fk.npwpHead.id = sessionStorage.getItem("npwpHeadId");

    reqData.fkAkunBebanList = [];
    reqData.fkProdukList = dataPenjualan;
    reqData.fkAkunPenjualanList = nilaiPenjualan;
    reqData.akunBebanHapusList = [];
    reqData.fkAkunPenjualanHapusList = [];
    reqData.fkProdukHapusList = [];

    try {
      // console.log(reqData)
      const res = await service.postNonPkp(reqData);
      let { data, status } = res;
      if (status == 200) {
        callback("success", false);
        navigationStore.redirectTo(backTo);
      }
    } catch (err) {
      callback(err, true, false);
    }
  };

  useEffect(() => {
    setLoadingAll(loadingakunPenjualan || loading);
  }, [loadingakunPenjualan, loading]);

  const baseName = `form-penjualan`;
  const basePath = `penjualan`;
  var npwpHeadId = match.params.npwpHeadId;
  var backTo = `/internal/npwp/${npwpHeadId}/penjualan`;
  let serviceUrl = "/penjualan";
  return (
    <FormWrapper
      loading={loadingAll}
      className={className}
      backTo={backTo}
      showCommandbar={true}
      baseId={`${baseName}`}
      title={t.translate(`modules.penjualan.form.nonPkp.title`)}
      style={{
        maxWidth: "100%",
      }}
      // defaultData={data.content}
      definitions={[
        {
          render: (
            <ExpansionList style={{ width: "100%" }}>
              {/* FORM IDENTITAS */}
              <ExpansionPanel
                expanded={panel1}
                onClick={() => activePanel1()}
                header={t.translate("words.identity")}
              >
                <FormWrapper
                  submitLabel={t.translate("words.next")}
                  loading={loadingAll}
                  showCommandbar={false}
                  baseId={`mod-form-${baseName}`}
                  style={{
                    maxWidth: "100%",
                  }}
                  defaultData={item}
                  definitions={[
                    {
                      render: (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginBottom: 20,
                          }}
                        >
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <b style={{ marginTop: "5%" }}>
                              {t.translate(`words.dataLawanTransaksi`)}
                            </b>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Button
                              themeType="outline"
                              style={{
                                borderWidth: 2,
                                borderColor: "#218c74",
                                color: "#218c74",
                                fontWeight: "bold",
                              }}
                              onClick={() => {
                                setShowForm(true);
                              }}
                            >
                              <FontIcon iconClassName="mdi mdi-plus" />
                              {t.translate("words.add")} Lawan Transaksi
                            </Button>
                          </div>
                        </div>
                      ),
                    },
                    {
                      inputType: inputTypes.REACT_SELECT,
                      isClearable: true,
                      label: t.translate(`words.nama`),
                      key: "lawanTransaksiId",
                      validation: "required",
                      labelKey: "label",
                      valueKey: "value",
                      options: lawanTransaksiList,
                      isLoading: loadinglawanTransaksi,
                      onInputChange: (value) => {
                        if (value) setQueryLawanTransaksi(value);
                      },
                    },
                    {
                      inputType: inputTypes.INPUT_MASK_NUMBER,
                      label: t.translate(`words.identityNpwp`),
                      key: "lawanTransaksiNpwp",
                      type: "text",
                      mask: "##.###.###.#-###.###",
                      maskChar: "_",
                      maskValue: "string",
                      disabled: true,
                      width: "48%",
                      style: {
                        marginRight: "2%",
                      },
                    },
                    {
                      inputType: inputTypes.INPUT_MASK_NUMBER,
                      label: t.translate(`words.identityNik`),
                      key: "lawanTransaksiNik",
                      type: "text",
                      mask: "################",
                      maskChar: "_",
                      maskValue: "string",
                      disabled: true,
                      width: "48%",
                      style: {
                        marginLeft: "2%",
                      },
                    },
                    {
                      inputType: inputTypes.INPUT,
                      label: t.translate(`words.address`),
                      key: "lawanTransaksiAlamat",
                      disabled: true,
                      type: "text",
                      width: "100%",
                    },
                    {
                      render: (
                        <div style={{ width: "100%" }}>
                          <p style={{ margin: "10px" }}>
                            <b>{t.translate(`words.picPenagihan`)}</b>
                          </p>
                        </div>
                      ),
                    },
                    {
                      inputType: inputTypes.INPUT,
                      label: t.translate(`words.namaPic`),
                      key: "lawanTransaksiNamaPic",
                      type: "text",
                      disabled: true,
                      width: "48%",
                      style: {
                        marginRight: "2%",
                      },
                    },
                    {
                      inputType: inputTypes.INPUT,
                      label: t.translate(`words.emailPic`),
                      key: "lawanTransaksiEmailPic",
                      type: "text",
                      disabled: true,
                      width: "48%",
                      style: {
                        marginLeft: "2%",
                      },
                    },
                    {
                      inputType: inputTypes.INPUT_MASK_NUMBER,
                      label: t.translate(`words.nomorTeleponPic`),
                      key: "lawanTransaksiNoTeleponPic",
                      type: "text",
                      disabled: true,
                      width: "48%",
                      style: {
                        marginRight: "2%",
                      },
                    },
                    {
                      inputType: inputTypes.INPUT,
                      label: t.translate(`words.alamatPic`),
                      key: "lawanTransaksiAlamatPic",
                      type: "text",
                      disabled: true,
                      width: "48%",
                      style: {
                        marginLeft: "2%",
                      },
                    },
                  ]}
                  onChange={(formData, key, value) => {
                    if (key === "lawanTransaksiId") {
                      lawanTransaksiList.map((d) => {
                        if (d.value == value) {
                          // console.log(d, "d");
                          formData["lawanTransaksi"]["id"] = d.value;
                          formData["lawanTransaksi"]["nama"] = d.nama;
                          formData["lawanTransaksiNama"] = d.nama;
                          formData["lawanTransaksiNik"] = d.nik;
                          formData["lawanTransaksiNpwp"] = d.npwp;
                          formData["lawanTransaksiAlamat"] = d.alamat;

                          formData["lawanTransaksiNamaPic"] = d.namaPic
                            ? d.namaPic
                            : "-";
                          formData["lawanTransaksiEmailPic"] = d.emailPic
                            ? d.emailPic
                            : "-";
                          formData["lawanTransaksiNoTeleponPic"] =
                            d.noTeleponPic ? d.noTeleponPic : "-";
                          formData["lawanTransaksiAlamatPic"] = d.alamatSama
                            ? d.alamat
                            : d.alamatPic
                            ? d.alamatSama
                            : "-";
                        }
                      });
                    }
                    setItem(formData);
                    return formData;
                  }}
                  onSubmit={onSubmitIdentitas}
                />
                <LawanTransaksiForm
                  visible={showForm}
                  onRequestClose={() => {
                    setShowForm(false);
                    lawanTransaksi(queryLawanTransaksi);
                  }}
                  item={null}
                />
              </ExpansionPanel>

              <br />

              {/* FORM TRANSAKSI */}
              <ExpansionPanel
                expanded={panel2}
                onClick={() => activePanel2()}
                header={t.translate("words.transaksi")}
              >
                <FormWrapper
                  submitLabel={t.translate("words.next")}
                  loading={loadingAll}
                  showCommandbar={false}
                  baseId={`mod-form-${baseName}`}
                  style={{
                    maxWidth: "100%",
                  }}
                  defaultData={item}
                  definitions={[
                    {
                      render: (
                        <div style={{ width: "100%", marginBottom: 30 }}>
                          <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                            {t.translate(`words.dataPenjualan`)}
                            <Divider />
                          </Text>
                        </div>
                      ),
                    },
                    {
                      inputType: inputTypes.DATEPICKER,
                      className: "mpk-padding-N padding-right",
                      label: t.translate(`words.tglTransaksi`),
                      key: "tanggalInvoice",
                      type: "date",
                      validation: "required",
                      width: "50%",
                      style: { zIndex: 11 },
                      maxDate: new Date(),
                    },
                    {
                      inputType: inputTypes.SELECT,
                      label: "Status Pembayaran",
                      key: "statusPembayaran",
                      width: "50%",
                      required: true,
                      options: statusBayar.map((d) => ({
                        label: d.name,
                        value: d.value,
                      })),
                    },
                    {
                      inputType: inputTypes.INPUT,
                      label: t.translate(`words.akunPiutang`),
                      key: "akunPiutangNama",
                      style: {
                        zIndex: 1,
                        backgroundColor: "rgb(242, 242, 242)",
                      },
                      show: !statusLunas,
                      disabled: true,
                    },
                    {
                      inputType: inputTypes.INPUT_MASK_NUMBER,
                      label: `Periode Jatuh Tempo (Hari)`,
                      thousandSeparator: ".",
                      decimalSeparator: ",",
                      isNumericString: true,
                      className: "mpk-padding-N padding-right",
                      key: "periodeJatuhTempo",
                      width: "50%",
                      validation: isLunas === "1" ? "required" : null,
                      show: !statusLunas,
                    },
                    {
                      inputType: inputTypes.DATEPICKER,
                      label: t.translate(`words.tanggalJatuhTempo`),
                      key: "tanggalJatuhTempo",
                      type: "date",
                      disable: true,
                      width: "50%",
                      validation: isLunas === "1" ? "required" : null,
                      show: !statusLunas,
                      disabled: true,
                    },
                    {
                      inputType: inputTypes.REACT_SELECT,
                      isClearable: true,
                      isClearable: true,
                      label: t.translate(`words.terimaDari`),
                      key: "pembayaranAkun",
                      labelKey: "label",
                      valueKey: "value",
                      options: listAkunKasBank,
                      isLoading: loadingakunKasBank,
                      style: { zIndex: 10 },
                      onInputChange: (value) => {
                        if (value) setQueryAkunKasBank(value);
                      },
                      validation: isLunas === "3" ? "required" : null,
                      show: statusLunas,
                    },
                    {
                      inputType: inputTypes.CHECKBOX,
                      label: `PPh Omzet Dipotong Oleh Pihak Lain`,
                      key: "isPph",
                      name: "isPph",
                      style: {
                        width: "100%",
                      },
                    },
                  ]}
                  onChange={(data, key, value) => {
                    if (key === "statusPembayaran") {
                      if (value === "1") {
                        setStatusLunas(false);
                      } else {
                        setStatusLunas(true);
                      }
                    }

                    if (key === "pembayaranAkun") {
                      listAkunKasBank.map((d) => {
                        if (d.value == value) {
                          // console.log(d.label, value);
                          data["pembayaranAkunId"] = d.value;
                        }
                      });
                    }

                    if (key == "tanggalInvoice") {
                      let tanggalInvoice = new Date(data["tanggalInvoice"]);
                      tanggalInvoice.setDate(
                        tanggalInvoice.getDate() + data["periodeJatuhTempo"]
                      );
                      data["tanggalJatuhTempo"] = tanggalInvoice;
                    }

                    if (key == "periodeJatuhTempo") {
                      let tanggalInvoice = new Date(data.tanggalInvoice);
                      tanggalInvoice.setDate(tanggalInvoice.getDate() + value);
                      data.tanggalJatuhTempo = tanggalInvoice;
                    }
                    if (key === "statusPembayaran") {
                      setIsLunas(value);
                    }
                    setItem(data);
                    return data;
                  }}
                  onSubmit={onSubmitTransaksi}
                />
              </ExpansionPanel>

              <br />

              {/* FORM PRODUK */}
              <ExpansionPanel
                expanded={panel3}
                onClick={() => activePanel3()}
                header={t.translate("words.produk")}
              >
                <PenjualanProdukForm
                  visible={dialogPenjualanProdukForm}
                  onRequestClose={() => {
                    akunPenjualan();
                    setDialogPenjualanProdukForm(false);
                    setDialogData(null);
                  }}
                  item={dialogData}
                />
                <FormWrapper
                  loading={loadingAll}
                  showCommandbar={false}
                  baseId={`mod-form-${baseName}`}
                  style={{
                    maxWidth: "100%",
                  }}
                  defaultData={item}
                  definitions={[
                    {
                      render: (
                        <div style={{ width: "100%" }}>
                          <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                            Produk Penjualan
                            <Button
                              theme="primary"
                              themeType="contained"
                              style={{ marginBottom: 5, float: "right" }}
                              onClick={() => addItem()}
                            >
                              <FontIcon iconClassName="mdi mdi-plus" />
                              {t.translate("words.add")}
                            </Button>
                            <Divider />
                          </Text>
                          {dataPenjualan.length > 0 ? (
                            <>
                              <Table style={{ width: "100%" }}>
                                <TableHeader>
                                  <TableRow>
                                    <TableCell style={{ width: "5%" }}>
                                      No
                                    </TableCell>
                                    <TableCell style={{ width: "5%" }}>
                                      Kode
                                    </TableCell>
                                    <TableCell style={{ width: "15%" }}>
                                      Nama
                                    </TableCell>
                                    <TableCell style={{ width: "5%" }}>
                                      Kuantitas
                                    </TableCell>
                                    <TableCell style={{ width: "10%" }}>
                                      Harga Satuan
                                    </TableCell>
                                    <TableCell style={{ width: "10%" }}>
                                      Diskon (%)
                                    </TableCell>
                                    <TableCell style={{ width: "10%" }}>
                                      PPN
                                    </TableCell>
                                    <TableCell style={{ width: "15%" }}>
                                      Jumlah
                                    </TableCell>
                                    <TableCell style={{ width: "25%" }}>
                                      Keterangan
                                    </TableCell>
                                  </TableRow>
                                </TableHeader>
                                <TableBody>
                                  {dataPenjualan.map((d, index) => {
                                    return (
                                      <TableRow key={index}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{d.produk?.kode}</TableCell>
                                        <TableCell>{d.produk?.nama}</TableCell>
                                        <TableCell>{d.jumlahBarang}</TableCell>
                                        <TableCell>
                                          {formatRupiah(d.hargaSatuan)}
                                        </TableCell>
                                        <TableCell>{d.isDiskon} %</TableCell>
                                        <TableCell>
                                          {formatRupiah(d.ppn)}
                                        </TableCell>
                                        <TableCell>
                                          {formatRupiah(d.jumlah)}
                                        </TableCell>
                                        <TableCell>
                                          {d.keterangan
                                            ? d.keterangan.substring(0, 50)
                                            : "-"}
                                        </TableCell>
                                        <TableCell>
                                          <Button
                                            style={{
                                              backgroundColor: "#34495e",
                                              color: "white",
                                            }}
                                            onClick={() =>
                                              hapusPenjualan(index)
                                            }
                                          >
                                            {t.translate("words.delete")}
                                          </Button>
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                                </TableBody>
                              </Table>
                            </>
                          ) : (
                            <TextContainer style={{ textAlign: "center" }}>
                              <Text
                                type="headline-6"
                                style={{ color: "#7f8c8d" }}
                              >
                                Tidak ada data
                              </Text>
                            </TextContainer>
                          )}
                        </div>
                      ),
                    },
                    {
                      render: (
                        <Divider
                          style={{ margin: 10, width: "100%" }}
                        ></Divider>
                      ),
                    },
                    {
                      inputType: inputTypes.INPUT,
                      label: t.translate(`words.keterangan`),
                      key: "keterangan",
                      type: "text",
                      width: "40%",
                      style: { marginRight: "30%" },
                    },
                    {
                      inputType: inputTypes.INPUT_MASK_NUMBER,
                      label: "SubTotal",
                      key: "hargaTotal",
                      thousandSeparator: ".",
                      decimalSeparator: ",",
                      isNumericString: true,
                      prefix: "Rp.",
                      type: "text",
                      width: "30%",
                      value:
                        dataPenggunaNpwp.pkp === false
                          ? dataPenjualan
                              .map((d) => d.hargaTotal)
                              .reduce((hargaTotal, datas) => {
                                return hargaTotal + datas;
                              }, 0)
                          : dataPenjualan
                              .map((d) => d.subtotal)
                              .reduce((subtotal, datas) => {
                                return subtotal + datas;
                              }, 0),
                      disabled: true,
                    },
                    {
                      inputType: inputTypes.FILE_INPUT,
                      label: t.translate("words.file"),
                      key: "fileLampiran",
                      type: "file",
                      accept: "application/pdf, image/*",

                      style: { width: "40%", marginRight: "30%" },
                    },
                    {
                      inputType: inputTypes.INPUT_MASK_NUMBER,
                      label: "Diskon",
                      key: "diskon",
                      thousandSeparator: ".",
                      decimalSeparator: ",",
                      isNumericString: true,
                      prefix: "Rp.",
                      type: "text",
                      width: "30%",
                      value: dataPenjualan
                        .map((d) => d.diskon)
                        .reduce((diskon, datas) => {
                          return diskon + datas;
                        }, 0),
                      disabled: true,
                    },
                    {
                      inputType: inputTypes.INPUT_MASK_NUMBER,
                      label: "PPn",
                      key: "ppn",
                      thousandSeparator: ".",
                      decimalSeparator: ",",
                      isNumericString: true,
                      type: "text",
                      prefix: "Rp.",
                      width: "30%",
                      value: Math.floor(
                        dataPenjualan
                          .map((d) => d.ppn)
                          .reduce((ppn, datas) => {
                            return ppn + datas;
                          }, 0)
                      ),
                      disabled: true,
                      style: { marginLeft: "70%" },
                    },
                    {
                      inputType: inputTypes.INPUT_MASK_NUMBER,
                      label: "Total",
                      key: "subtotal",
                      thousandSeparator: ".",
                      decimalSeparator: ",",
                      isNumericString: true,
                      prefix: "Rp.",
                      type: "text",
                      width: "30%",
                      style: { marginLeft: "70%" },
                      value:
                        dataPenggunaNpwp.pkp === false
                          ? dataPenjualan
                              .map((d) => d.jumlah)
                              .reduce((jumlah, datas) => {
                                return jumlah + datas;
                              }, 0)
                          : dataPenjualan
                              .map((d) => d.subtotal)
                              .reduce((subtotal, datas) => {
                                return subtotal + datas;
                              }, 0) -
                            dataPenjualan
                              .map((d) => d.diskon)
                              .reduce((diskon, datas) => {
                                return diskon + datas;
                              }, 0) +
                            dataPenjualan
                              .map((d) => d.ppn)
                              .reduce((ppn, datas) => {
                                return ppn + datas;
                              }, 0),

                      disabled: true,
                    },
                    {
                      render: <div style={{ margin: 10 }}></div>,
                    },
                  ]}
                  submitLabel={t.translate("words.submit")}
                  onChange={async (formData, key, value) => {
                    setItem(formData);
                    return formData;
                  }}
                  onSubmit={onSubmitNonPkp}
                />
              </ExpansionPanel>
            </ExpansionList>
          ),
        },
      ]}
      // onSubmit={async (data, callback) => {

      // }}
    />
  );
};

export default inject(
  "envStore",
  "modalStore",
  "navigationStore"
)(observer(PenjualanFormNonPkp));
