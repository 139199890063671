import { crud, http } from "../../libs/react-mpk/services";

let service = null;
let serviceUrl = "/akun";
let tahunSobatPajak = sessionStorage.getItem("tahunSobatBuku");
let npwpHeadId = sessionStorage.getItem("npwpHeadId");
let npwpHeadIdObject = {
  npwpHeadId: sessionStorage.getItem("npwpHeadId"),
};
let kategoriAkun =
  "1.1.01,1.1.02,1.1.03,1.1.04,1.1.05,1.1.06,1.1.07,1.1.08,1.1.09,2.1.01,2.1.02,2.1.03,2.1.04,2.1.05,2.2.01,3.1.01,1.1.10,1.1.11,1.2.01,3.1.02,3.1.03,4.1.01,5.1.01,5.2.01,6.1.01,7.1.01,8.1.01,4.2.01,5.2.01,1.2.02,1.2.03,1.2.04";
service = new crud("/akun");

service.getPage = async (query) => {
  query = {
    ...query,
    ...npwpHeadIdObject,
    npwpHeadId: npwpHeadId,
  };
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}`,
    query,
  });
};

service.getAkun = async (queryNama) => {
 let query = {
    ...npwpHeadIdObject,
    npwpHeadId: npwpHeadId,
    "kategoriAkunKode.in": kategoriAkun,
    size: 10,
    "nama.contains": queryNama,
    
  };
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}`,
    query,
  });
};

service.generateKodeAkun = async (kategoriAkunId) => {
  let query = {
    kategoriAkunId: kategoriAkunId,
    npwpHeadId: npwpHeadId,
  };
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/generate_kode_akun`,
    query,
  });
};

service.getOneById = async (id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/${id}`,
  });
};

service.create = async (data) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}`,
    data,
  });
};

service.update = async (data) => {
  return http.request({
    method: http.methods.PUT,
    url: `${serviceUrl}`,
    data,
  });
};

service.generateAkun = async () => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/generate`,
    config: {
      params: {
        npwpHeadId: npwpHeadId,
        tahun: tahunSobatPajak,
      },
    },
  });
};

service.getListKategoriAkun = async (queryNama) => {
  let query = {
    page: 0,
    size: 10,
    sort: "kode,ASC",
    "kode.contains": "",
    "nama.contains":queryNama,
  };
  return http.request({
    method: http.methods.GET,
    url: `/kategori_akun`,
    query,
  });
};

service.getListKategoriAset = async (queryNama) => {
  let query = {
    page: 0,
    size: 10,
    sort: "tanggalDibuat,DESC",
    "kategori.equals": 1,
    "kode.contains": "",
    "nama.contains": queryNama,
  };
  return http.request({
    method: http.methods.GET,
    url: `/kategori_aset`,
    query,
  });
};

service.getListKategoriHutang = async (queryNama) => {
  let query = {
    page: 0,
    size: 10,
    sort: "tanggalDibuat,DESC",
    "kategori.equals": 2,
    "kode.contains": "",
    "nama.contains": queryNama,
  };
  return http.request({
    method: http.methods.GET,
    url: `/kategori_aset`,
    query,
  });
};

service.getListKoreksiFiskal = async () => {
  let query = {
    page: 0,
    size: 9999,
    sort: "tanggalDibuat,DESC",
    "kode.contains": "",
    "nama.contains": "",
  };
  return http.request({
    method: http.methods.GET,
    url: `/koreksi_fiskal`,
    query,
  });
};

service.deleteById = async (id) => {
  return http.request({
    method: http.methods.DELETE,
    url: `${serviceUrl}/${id}`,
  });
};

export default service;
