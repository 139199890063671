import React, { useState } from "react";
import { TableWrapper } from "../../../libs/react-mpk/wrapper";
import t from "counterpart";
import { DataForm } from "../../../libs/react-mpk/components";
import { inputTypes } from "../../../libs/react-mpk/config/constant";
import iziToast from "izitoast";
import { inject, observer } from "mobx-react";
import service from "./TambahSaldoAwal.service";
import TambahSaldoAwalForm from "./TambahSaldoAwalForm";
import { format, formatRupiah } from "../../../libs/react-mpk/services/number.service";
import { toast } from "../../../libs/react-mpk/services";

const TambahSaldoAwal = ({
  className = "",
  showCommandbar = true,
  modalStore,
}) => {
  const [showForm, setShowForm] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  let [search, setSearch] = useState({ kategori: "0" });
  const baseId = "tambah-saldo-awal";

  var actions = [
    new TableWrapper.action(
      `${t.translate("words.add")}`,
      "mdi mdi-plus",
      () => {
        setSelectedItemId(null);
        setShowForm(true);
      },
      true
    ),
    new TableWrapper.action(
      `search`,
      "mdi mdi-magnify",
      () => {
        setShowFilter(!showFilter);
      },
      true
    ),
  ];

  var itemActions = [
    new TableWrapper.action(
      "Edit",
      "mdi mdi-pencil",
      (item) => {
        setSelectedItemId(item.akun.id);
        setShowForm(true);
      },
      true
    ),
    new TableWrapper.action(
      "Delete",
      "mdi mdi-delete",
      (item) => {
        hapus(item);
      },
      true
    ),
  ];

  async function hapusExc(item) {
    try {
      var r = await service.deleteById(item.akun.id);

      if (r.data.message == "sukses") {
        iziToast.success({
          message: r.data.message,
        });
      } else {
        iziToast.info({
          message: r.data.message,
        });
      }
      document.getElementsByClassName("mdi-reload")[0].click();
    } catch (e) {
      // errorService(e)
    }
  }

  async function hapus(item) {
    modalStore.showConfirm({
      title: t.translate("words.delete"),
      children: t.translate("words.confirmHapus"),
      onSubmit: (callback) => {
        hapusExc(item);
        callback();
      },
    });
  }

  return (
    <>
      <TableWrapper
        baseId={baseId}
        title={t.translate("modules.tambahSaldoAwal.title")}
        className={className}
        hintMessage={t.translate("modules.tambahSaldoAwal.hint.message")}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate("modules.tambahSaldoAwal.hint.more")}
        defaultData={[]}
        isShowFilter={false}
        showActionColumn={true}
        useFilter={false}
        defaultCollapse={true}
        useCriteria={true}
        selectable={false}
        useQueryHistory={false}
        onFetchData={(query) => {
          return new Promise(async (resolve, reject) => {
            try {
              query = {
                ...search,
                ...query,
              };
              let getPage = await service.getPage(query);
              let data = {
                data: getPage.data.data,
                headers: getPage.headers,
              };
              data.headers["x-pagination-count"] =
                data.headers["x-total-count"];
              resolve(data);
            } catch (e) {
              toast.errorRequest(e)
              resolve();
              // ErrorService(e)
            }
          });
        }}
        columns={[
          {
            label: t.translate(`words.nomorAkun`),
            searchable: true,
            sortable: false,
            key: "nomorAkun",
            render: (item) => item.akun.kode,
          },
          {
            label: t.translate(`words.name`),
            searchable: true,
            sortable: false,
            key: "name",
            render: (item) => item.akun.nama,
          },
          {
            label: t.translate(`words.kategoriAkun`),
            searchable: true,
            sortable: false,
            key: "kategoriAkun",
            render: (item) =>
              item.akun?.kategoriAkun?.debitKredit == 1 ? "Debit" : "Kredit",
          },
          {
            label: t.translate(`words.saldoAwal`),
            searchable: true,
            sortable: false,
            key: "saldoAwal",
            render: (item) => formatRupiah(Math.abs(item.saldoAwal)),
          },
        ]}
        actions={actions}
        itemActions={itemActions}
        showCommandbar={showCommandbar}
        showFilterPeriod={false}
        sideHeader={
          showFilter == true &&<DataForm
            baseId={`${baseId}-search`}
            defaultData={search}
            submitIconClassName="mdi mdi-magnify"
            submitLabel={`${t.translate("words.cari")}`}
            onSubmit={(values, callback) => {
              setSearch(values);
              setTimeout(() => {
                document.getElementsByClassName("mdi-reload")[0].click();
                callback("", false, false);
              }, 1000);
            }}
            definitions={[
              {
                inputType: inputTypes.SELECT,
                label: t.translate(`words.kategori`),
                className: "mpk-padding-N padding-right",
                key: "kategori",
                options: [
                  { label: "Semua", value: "0" },
                  { label: "Harta", value: "1" },
                  { label: "Utang", value: "2" },
                  { label: "Modal", value: "3" },
                ],
              },
            ]}
          />
        }
      />
      <TambahSaldoAwalForm
        visible={showForm}
        onRequestClose={() => {
          setShowForm(false);
          setSelectedItemId(null);
          document.getElementsByClassName("mdi-reload")[0].click();
        }}
        itemId={selectedItemId}
      />
    </>
  );
};

export default inject("modalStore")(observer(TambahSaldoAwal));
