import React, { useState, useEffect } from 'react'
import t from 'counterpart'
import service from './ReportNeraca.service'
import { inject, observer } from 'mobx-react'
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { Commandbar, DataForm } from '../../../libs/react-mpk/components'
import { Table, TableBody, TableHeader, TableRow, TableCell, Button, FontIcon, TableFooter } from 'react-md'
import FormTanggal from '../FormTanggal'
import { FormWrapper, TableWrapper } from '../../../libs/react-mpk/wrapper'
import { format, formatRupiah } from '../../../libs/react-mpk/services/number.service'
import { sum } from 'lodash'
import { useHistory, useLocation } from 'react-router-dom'
import { toast } from '../../../libs/react-mpk/services'
import AsyncButton from '../../../libs/react-mpk/components/AsyncButton/AsyncButton'

const ReportNeracaUtangDanModal = ({
    navigationStore,
    showCommandbar = true,
    match
}) => {
    let history = useHistory()
    const { state: { tahun } = {} } = useLocation();
    const [dataJangkaPendek, setDataJangkaPendek] = useState([])
    const [dataModal, setDataModal] = useState([])
    const [dataJangkaPanjang, setDataJangkaPanjang] = useState([])
    const [dataAll, setDataAll] = useState([])
    const [load, setload] = useState(true)
    const [loading, setLoading] = useState(false);
    let tahunSobatPajak = sessionStorage.getItem("tahunSobatBuku");
    var npwpHeadId = match.params.npwpHeadId;

    const fetchData = async () => {
        try {
            let query = {
                "tahun": tahun?tahun: sessionStorage.getItem('tahunSobatBuku')
            }
            let res = await service.getAllUtangModal(query);
            setDataModal(res.data.modalList);

            setDataJangkaPanjang(res.data.utangJangkaPanjangList);

            setDataJangkaPendek(res.data.utangJangkaPendekList);

            setDataAll(res.data)
            setload(false);
        } catch (error) {

        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    async function DownloadLaporanUtangModal() {
        try {
          setLoading(true);
          await service.DownloadLaporanNeraca(2,npwpHeadId,tahun ? tahun : tahunSobatPajak);
          setLoading(false);
        } catch (e) {
          toast.errorRequest(e);
          setLoading(false);
        }
      }
    return (
        <>
             <Commandbar
        title={t.translate(`words.neracaUtangModal`)}
        rightCorner={[
            <AsyncButton
            loading={loading}
            labelButton={'Download Laporan'}
            showIcon={true}
            onClickDownload={() => {
                DownloadLaporanUtangModal();
            }}
          />
        ]}
      />
            <FormWrapper
                loading={load}
                showBackTo={false}
                baseId="mod-table-laporan-neraca-utang-dan-modal-utang-jangka-pendek"
                showCommandbar={false}
                style={{
                    maxWidth: '100%'
                }}
                definitions={[
                    {
                        render: (
                            <div style={{ width: '100%' }}>
                                <p style={{ margin: "10px", color: 'purple' }}><b>{t.translate(`words.utangJangkaPendek`)}</b></p>
                            </div>
                        )
                    },
                    {
                        render: (
                            <div style={{ width: '100%' }}>
                                <Table style={{ width: '100%' }}>
                                    <TableHeader>
                                        <TableRow>
                                            <TableCell style={{ width: '10%' }}>{t.translate('words.non')}</TableCell>
                                            <TableCell style={{ width: '10%' }}>{t.translate('words.aksi')}</TableCell>
                                            <TableCell style={{ width: '45%' }}>{t.translate('words.kategoriAkun')}</TableCell>
                                            <TableCell style={{ width: '45%' }}>{t.translate('words.nominal')}</TableCell>
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody>
                                        {dataJangkaPendek.map((d, index) => {
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell>{index + 1}</TableCell>
                                                    <TableCell>
                                                       
                                                        <Button
                                                            theme="primary"
                                                            // themeType="contained"
                                                            // style={{ margin: 15 }}
                                                            onClick={() => {
                                                                history.push({
                                                                    pathname: `/internal/npwp/${npwpHeadId}/report/detail-laporan-neraca/${d.kategoriAkun.id}`,
                                                                    state: { tahun: tahun,utangModal:true,harta:false },
                                                                  });
                                                                // navigationStore.redirectTo(
                                                                //     `/internal/npwp/${npwpHeadId}/report/detail-laporan-neraca/${d.kategoriAkun.id}`
                                                                // )
                                                                sessionStorage.setItem('kategoriAkunNama', d.kategoriAkun.nama)
                                                                sessionStorage.setItem('kategoriAkunKode', d.kategoriAkun.kode)
                                                            }}
                                                        >
                                                            <FontIcon iconClassName="mdi mdi-import" />
                                                            {/* {t.translate("words.add")} */}
                                                        </Button>
                                                    </TableCell>
                                                    <TableCell>{d.kategoriAkun.kode + ' - ' + d.kategoriAkun.nama}</TableCell>
                                                    <TableCell>{formatRupiah(d.saldoAkhir)}</TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TableCell><b>{t.translate(`words.totalUtangJangkaPendek`)}</b></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell><b>{formatRupiah(dataAll.totalUtangJangkaPendek)}</b></TableCell>
                                        </TableRow>
                                        {/* <TableRow>
                                            <TableCell><b>{t.translate(`words.totalHarta`)}</b></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell><b>{formatRupiah(dataAll.totalUtangModal)}</b></TableCell>
                                        </TableRow> */}
                                    </TableFooter>
                                </Table>
                            </div>
                        )
                    },
                    {
                        render: (
                            <div style={{ width: '100%' }}>
                                <p style={{ margin: "10px", color: 'purple' }}><b>{t.translate(`words.utangJangkaPanjang`)}</b></p>
                            </div>
                        )
                    },
                    {
                        render: (
                            <div style={{ width: '100%' }}>
                                <Table style={{ width: '100%' }}>
                                    <TableHeader>
                                        <TableRow>
                                            <TableCell style={{ width: '10%' }}>{t.translate('words.non')}</TableCell>
                                            <TableCell style={{ width: '10%' }}>{t.translate('words.aksi')}</TableCell>
                                            <TableCell style={{ width: '45%' }}>{t.translate('words.kategoriAkun')}</TableCell>
                                            <TableCell style={{ width: '45%' }}>{t.translate('words.nominal')}</TableCell>
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody>
                                        {dataJangkaPanjang.map((d, index) => {
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell>{index + 1}</TableCell>
                                                    <TableCell>
                                                        <Button
                                                            theme="primary"
                                                            // themeType="contained"
                                                            // style={{ margin: 15 }}
                                                            onClick={() => {
                                                                navigationStore.redirectTo(
                                                                    `/internal/npwp/${npwpHeadId}/report/detail-laporan-neraca/${d.kategoriAkun.id}`
                                                                )
                                                                sessionStorage.setItem('kategoriAkunNama', d.kategoriAkun.nama)
                                                                sessionStorage.setItem('kategoriAkunKode', d.kategoriAkun.kode)
                                                            }}
                                                        >
                                                            <FontIcon iconClassName="mdi mdi-import" />
                                                            {/* {t.translate("words.add")} */}
                                                        </Button>
                                                    </TableCell>
                                                    <TableCell>{d.kategoriAkun.kode + ' - ' + d.kategoriAkun.nama}</TableCell>
                                                    <TableCell>{formatRupiah(d.saldoAkhir)}</TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TableCell><b>{t.translate(`words.totalUtangJangkaPanjang`)}</b></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell><b>{formatRupiah(dataAll.totalUtangJangkaPanjang)}</b></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><b>{t.translate(`words.totalUtang`)}</b></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell><b>{formatRupiah(dataAll.totalUtangJangkaPanjang+dataAll.totalUtangJangkaPendek)}</b></TableCell>
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </div>
                        )
                    },
                    {
                        render: (
                            <div style={{ width: '100%' }}>
                                <p style={{ margin: "10px", color: 'purple' }}><b>{t.translate(`words.modal`)}</b></p>
                            </div>
                        )
                    },
                    {
                        render: (
                            <div style={{ width: '100%' }}>
                                <Table style={{ width: '100%' }}>
                                    <TableHeader>
                                        <TableRow>
                                            <TableCell style={{ width: '10%' }}>{t.translate('words.non')}</TableCell>
                                            <TableCell style={{ width: '10%' }}>{t.translate('words.aksi')}</TableCell>
                                            <TableCell style={{ width: '45%' }}>{t.translate('words.kategoriAkun')}</TableCell>
                                            <TableCell style={{ width: '45%' }}>{t.translate('words.nominal')}</TableCell>
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody>
                                        {dataModal.map((d, index) => {
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell>{index + 1}</TableCell>
                                                    <TableCell>
                                                    {d.kategoriAkun.id != null &&
                                                        <Button
                                                            theme="primary"
                                                            // themeType="contained"
                                                            // style={{ margin: 15 }}
                                                            onClick={() => {
                                                                history.push({
                                                                    pathname: `/internal/npwp/${npwpHeadId}/report/detail-laporan-neraca/${d.kategoriAkun.id}`,
                                                                    state: { tahun: tahun },
                                                                  });
                                                                // navigationStore.redirectTo(
                                                                //     `/internal/npwp/${npwpHeadId}/report/detail-laporan-neraca/${d.kategoriAkun.id}`
                                                                // )
                                                                sessionStorage.setItem('kategoriAkunNama', d.kategoriAkun.nama)
                                                                sessionStorage.setItem('kategoriAkunKode', d.kategoriAkun.kode)
                                                            }}
                                                        >
                                                            <FontIcon iconClassName="mdi mdi-import" style={{ justifyContent: 'left' }} />
                                                            {/* {t.translate("words.add")} */}
                                                        </Button>}
                                                    </TableCell>
                                                    <TableCell>{d.kategoriAkun.kode != '' && d.kategoriAkun.kode + ' - '}{d.kategoriAkun.nama}</TableCell>
                                                    <TableCell>{formatRupiah(d.saldoAkhir)}</TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TableCell><b>{t.translate(`words.totalModal`)}</b></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell><b>{formatRupiah(dataAll.totalModal)}</b></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><b>{t.translate(`words.totalUtangModal`)}</b></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell><b>{formatRupiah(dataAll.totalModal+dataAll.totalUtangJangkaPanjang+dataAll.totalUtangJangkaPendek)}</b></TableCell>
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </div>
                        )
                    },
                ]}
                onChangeQuery={async (formData, key, value) => {
                    if (formData === "total") {
                        formData.total = sum(formData.nominal)
                    }
                }}
            />
        </>
    )
}

export default inject("navigationStore", 'envStore')(observer(ReportNeracaUtangDanModal))