import moment from "moment";
import "moment/locale/id";
import React, { useEffect, useState } from "react";
import { FontIcon } from "react-md";
import { DataForm, LoaderInfo } from "../../libs/react-mpk/components";
import service from "./PPh.service";

const PAY_NOTHING = 0,
  PAY_BELUM_BAYAR = 1,
  PAY_MENUNGGU_PEMBAYARAN = 2,
  PAY_MENUNGGU_REFUND = 3,
  PAY_PROSES_REFUND = 4,
  PAY_REFUND_SELESAI = 5,
  PAY_MENUNGGU_NTPN = 6,
  PAY_NTPN_TERSEDIA = 7,
  PAY_EXPIRED = 8,
  PAY_BATAL = 9,
  PAY_ERROR = 10;

const RiwayatTransaksiIDBiliing = ({
  visible = false,
  authStore,
  envStore,
  onRequestClose = () => {},
  ...props
}) => {
  let [loading, setLoading] = useState(true);
  let [item, setItem] = useState([]);

  useEffect(() => {
    async function initData() {
      if (visible) {
        if (props.itemId) {
          let query = {
            sort: "id,DESC",
            "eBillingId.equals": props.itemId,
          };
          let res = await service.getPageHistory(query);
          setItem(res.data);
          setLoading(false);
        }
      }
    }
    initData();
  }, [visible]);

  return (
    <DataForm
      baseId="history-transaksi-form"
      title="Riwayat Transaksi"
      visible={visible}
      onRequestClose={onRequestClose}
      asDialog={true}
      defaultData={[]}
      style={{ maxWidth: 700, maxHeight: 500 }}
      definitions={[
        {
          render: loading ? (
            <LoaderInfo children={"Loading . . . "} />
          ) : (
            <div style={{ width: "100%" }}>
              {item
                .sort((a, b) => b.id - a.id)
                .map((d, i) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: 20,
                      color: i == 0 ? "#1B1464" : "#a4b0be",
                      fontWeight: i == 0 ? "bold" : "",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        {i == 0 ? (
                          <FontIcon
                            iconClassName="mdi mdi-nut"
                            style={{ fontSize: 30, color: "#1B1464" }}
                          />
                        ) : (
                          <FontIcon
                            iconClassName="mdi mdi-checkbox-blank-circle-outline"
                            style={{ fontSize: 30, color: "#a4b0be" }}
                          />
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: 30,
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        {d.ebillingTransaksi.status == PAY_NOTHING
                          ? "Transaksi Dibuat"
                          : d.ebillingTransaksi.status == PAY_BELUM_BAYAR
                          ? "Belum Dibayar"
                          : d.ebillingTransaksi.status ==
                            PAY_MENUNGGU_PEMBAYARAN
                          ? "Menunggu Pembayaran"
                          : d.ebillingTransaksi.status == PAY_MENUNGGU_REFUND
                          ? "Menunggu Refund"
                          : d.ebillingTransaksi.status == PAY_PROSES_REFUND
                          ? "Dalam Proses Refund"
                          : d.ebillingTransaksi.status == PAY_REFUND_SELESAI
                          ? "Sudah Di-refund"
                          : d.ebillingTransaksi.status == PAY_MENUNGGU_NTPN
                          ? "Dalam Proses Generate NTPN"
                          : d.ebillingTransaksi.status == PAY_NTPN_TERSEDIA
                          ? "NTPN sudah Tersedia"
                          : d.ebillingTransaksi.status == PAY_EXPIRED
                          ? "Transaksi tidak Berlaku"
                          : d.ebillingTransaksi.status == PAY_BATAL
                          ? "Pembayaran Dibatalkan"
                          : "ERROR"}
                      </div>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        {moment(d.ebillingTransaksi.tanggalDibuat)
                          .locale("id")
                          .locale("id")
                          .format("DD MMMM YYYY, hh:mm")}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          ),
        },
      ]}
    />
  );
};

export default RiwayatTransaksiIDBiliing;
