import React, { useState } from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './Biaya.service'
import { useParams } from "react-router-dom";
import utilsService from '../../services/utilsService'
import { format, formatRupiah } from '../../libs/react-mpk/services/number.service'
import moment from 'moment'
import { toast } from '../../libs/react-mpk/services'

const BiayaHistory = ({
  className = '',
  match,
  envStore,
}) => {
  const [data, setData] = useState([])
  let npwpHeadId = sessionStorage.getItem('npwpHeadId')

  return (
    <>
      <TableWrapper
        baseId="biaya-history"
        title='Riwayat Biaya'
        className={className}
        hintMessage={t.translate('modules.pesanan.hint.message')}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate('modules.pesanan.hint.more')}
        isShowFilter={false}
        showActionColumn={true}
        useFilter={false}
        defaultCollapse={true}
        useCriteria={true}
        selectable={false}
        useQueryHistory={false}
        defaultSortBy='tanggalDibuat'
        backTo={`/${envStore.env.applicationType}/npwp/${npwpHeadId}/biaya`}
        defaultData={data}
        onFetchData={(query) => {
          return (
            new Promise(async (resolve, reject) => {
              try {
                let res = await service.getHistory(match.params.id)
                let { data } = res
                // console.log(data, 'data history');
                res.headers['x-pagination-count'] = res.headers['x-total-count']
                resolve(res)
              } catch (e) {
                resolve()
               toast.errorRequest(e)
              }
            })
          )
        }}
        columns={[
          {
            label: 'Nomor Transaksi',
            searchable: false,
            key: 'noTransaksi',
            render: (item) => utilsService.beautifyNoTransaksi(item.kasBankAkun.kasBank.noTransaksi)
          },
          {
            label: 'Nominal',
            searchable: true,
            key: 'nominal',
            render: (item) => {formatRupiah(item.nilaiTagihanDibayar)}
          },
          {
            label: t.translate('words.tglTransaksi'),
            searchable: true,
            sortable: true,
            key: 'tanggalDibuat',
            type: TableWrapper.dataTypes.STRING,
            render: (item) => moment(item.fm.tanggalTransaksi).format('DD MMMM YYYY - hh:mm')
          }
        ]}
      />
    </>
  )
}

export default inject('temporaryStore', 'modalStore', 'envStore')(observer(BiayaHistory))