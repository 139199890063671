import React, { useEffect, useState } from "react";
import { inputTypes } from "../../../libs/react-mpk/config/constant";
import { CustomWrapper, FormWrapper } from "../../../libs/react-mpk/wrapper";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import { find } from "lodash";
import { DataForm } from "../../../libs/react-mpk/components";
import service from "./PemetaanAkunJurnal.service";
import { useParams } from "react-router";
import cloneDeep from "lodash.clonedeep";
import { toast } from "../../../libs/react-mpk/services";

const PemetaanAkunJurnal = ({ envStore }) => {
  const { inputTypes, definition } = FormWrapper;
  const { id } = useParams();
  //VALIDASI
  const [item, setItem] = useState({
    gajiPokok: null,
    tunjanganUangTransport: null,
    tunjanganUangLembur: null,
    potonganKetidakhadiran: null,
    potonganPinjaman: null,
    potonganLainnya: null,
    pph21DibayarDimuka: null,
    bebanPph: null,
    pph23DibayarDimuka: null,
    utangPph4a2: null,
    labaDitahan: null,
    utangPph21: null,
  });
  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(false);
  let [query1, setQuery1] = useState("");
  let [query2, setQuery2] = useState("");
  let [query3, setQuery3] = useState("");
  let [query4, setQuery4] = useState("");
  let [query5, setQuery5] = useState("");
  let [query6, setQuery6] = useState("");
  let [query7, setQuery7] = useState("");
  let [query8, setQuery8] = useState("");
  let [query9, setQuery9] = useState("");
  let [query10, setQuery10] = useState("");
  let [query11, setQuery11] = useState("");
  let [query12, setQuery12] = useState("");
  let [akun1, setAkun1] = useState([]);
  let [akun2, setAkun2] = useState([]);
  let [akun3, setAkun3] = useState([]);
  let [akun4, setAkun4] = useState([]);
  let [akun5, setAkun5] = useState([]);
  let [akun6, setAkun6] = useState([]);
  let [akun7, setAkun7] = useState([]);
  let [akun8, setAkun8] = useState([]);
  let [akun9, setAkun9] = useState([]);
  let [akun10, setAkun10] = useState([]);
  let [akun11, setAkun11] = useState([]);
  let [akun12, setAkun12] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      let selectedItem = item;
      let res = await service.getAll();
      selectedItem.gajiPokok = res.data.gajiPokok?.id;
      setQuery1(res.data.gajiPokok?.nama);
      selectedItem.tunjanganUangTransport = res.data.tunjanganUangTransport?.id;
      setQuery2(res.data.tunjanganUangTransport?.nama);
      selectedItem.tunjanganUangLembur = res.data.tunjanganUangLembur?.id;
      setQuery3(res.data.tunjanganUangLembur?.nama);
      selectedItem.potonganKetidakhadiran = res.data.potonganKetidakhadiran?.id;
      setQuery4(res.data.potonganKetidakhadiran?.nama);
      selectedItem.potonganPinjaman = res.data.potonganPinjaman?.id;
      setQuery5(res.data.potonganPinjaman?.nama);
      selectedItem.potonganLainnya = res.data.potonganLainnya?.id;
      setQuery6(res.data.potonganLainnya?.nama);
      selectedItem.pph21DibayarDimuka = res.data.pph21DibayarDimuka?.id;
      setQuery7(res.data.pph21DibayarDimuka?.nama);
      selectedItem.pph23DibayarDimuka = res.data.pph23DibayarDimuka?.id;
      setQuery8(res.data.pph23DibayarDimuka?.nama);
      selectedItem.utangPph4a2 = res.data.utangPph4a2?.id;
      setQuery9(res.data.utangPph4a2?.nama);
      selectedItem.utangPph21 = res.data.utangPph21?.id;
      setQuery10(res.data.utangPph21?.nama);
      selectedItem.bebanPph = res.data.bebanPph?.id;
      setQuery11(res.data.bebanPph?.nama);
      selectedItem.labaDitahan = res.data.labaDitahan?.id;
      setQuery12(res.data.labaDitahan?.nama);
      setItem(selectedItem);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    const timeOutId = setTimeout(() => getAkun(query1, 1), 500);
    return () => clearTimeout(timeOutId);
  }, [query1]);
  useEffect(() => {
    const timeOutId = setTimeout(() => getAkun(query2, 2), 500);
    return () => clearTimeout(timeOutId);
  }, [query2]);
  useEffect(() => {
    const timeOutId = setTimeout(() => getAkun(query3, 3), 500);
    return () => clearTimeout(timeOutId);
  }, [query3]);
  useEffect(() => {
    const timeOutId = setTimeout(() => getAkun(query4, 4), 500);
    return () => clearTimeout(timeOutId);
  }, [query4]);
  useEffect(() => {
    const timeOutId = setTimeout(() => getAkun(query5, 5), 500);
    return () => clearTimeout(timeOutId);
  }, [query5]);
  useEffect(() => {
    const timeOutId = setTimeout(() => getAkun(query6, 6), 500);
    return () => clearTimeout(timeOutId);
  }, [query6]);
  useEffect(() => {
    const timeOutId = setTimeout(() => getAkun(query7, 7), 500);
    return () => clearTimeout(timeOutId);
  }, [query7]);
  useEffect(() => {
    const timeOutId = setTimeout(() => getAkun(query8, 8), 500);
    return () => clearTimeout(timeOutId);
  }, [query8]);
  useEffect(() => {
    const timeOutId = setTimeout(() => getAkun(query9, 9), 500);
    return () => clearTimeout(timeOutId);
  }, [query9]);
  useEffect(() => {
    const timeOutId = setTimeout(() => getAkun(query10, 10), 500);
    return () => clearTimeout(timeOutId);
  }, [query10]);
  useEffect(() => {
    const timeOutId = setTimeout(() => getAkun(query11, 11), 500);
    return () => clearTimeout(timeOutId);
  }, [query11]);
  useEffect(() => {
    const timeOutId = setTimeout(() => getAkun(query12, 12), 500);
    return () => clearTimeout(timeOutId);
  }, [query12]);

  const getAkun = async (query, no) => {
    try {
      setLoadingData(true);
      // let newQuery = { page: 1, size: 9999, ...query };
      let dataAkun = await service.getAkun(query);
      var akunAsset = [];
      dataAkun.data.map((d) => {
        akunAsset.push({
          label: `${d.kode} - ${d.nama}`,
          value: d.id,
        });
      });
      no == 1 && setAkun1(akunAsset);
      no == 2 && setAkun2(akunAsset);
      no == 3 && setAkun3(akunAsset);
      no == 4 && setAkun4(akunAsset);
      no == 5 && setAkun5(akunAsset);
      no == 6 && setAkun6(akunAsset);
      no == 7 && setAkun7(akunAsset);
      no == 8 && setAkun8(akunAsset);
      no == 9 && setAkun9(akunAsset);
      no == 10 && setAkun10(akunAsset);
      no == 11 && setAkun11(akunAsset);
      no == 12 && setAkun12(akunAsset);
      setLoadingData(false);
    } catch (error) {
      toast.errorRequest(error);
      setLoadingData(false);
    }
  };
  return (
    <FormWrapper
      showBackTo={false}
      baseId="pengaturan-pemetaan-akun-jurnal"
      title={t.translate("modules.pemetaanAkunJurnal.title")}
      hintShowIcon={false}
      loading={loading}
      watchDefaultData={true}
      defaultData={item}
      definitions={[
        {
          render: (
            <div style={{ textAlign: "center" }}>
              <p style={{ marginLeft: "10px" }}>
                <b>{t.translate(`words.akunJurnalGaji`)}</b>
              </p>
            </div>
          ),
        },
        {
          inputType: inputTypes.REACT_SELECT,
          isClearable: true,
          label: t.translate(`words.gajiPokok`),
          key: "gajiPokok",
          labelKey: "label",
          valueKey: "value",
          options: akun1,
          isLoading: loadingData,
          onInputChange: (value) => {
            if (value) setQuery1(value);
          },
        },
        {
          inputType: inputTypes.REACT_SELECT,
          isClearable: true,
          label: t.translate(`words.tunjanganUangTransport`),
          key: "tunjanganUangTransport",
          labelKey: "label",
          valueKey: "value",
          options: akun2,
          isLoading: loadingData,
          onInputChange: (value) => {
            if (value) setQuery2(value);
          },
        },
        {
          inputType: inputTypes.REACT_SELECT,
          isClearable: true,
          label: t.translate(`words.tunjanganUangLembur`),
          key: "tunjanganUangLembur",
          labelKey: "label",
          valueKey: "value",
          options: akun3,
          isLoading: loadingData,
          onInputChange: (value) => {
            if (value) setQuery3(value);
          },
        },
        {
          inputType: inputTypes.REACT_SELECT,
          isClearable: true,
          label: t.translate(`words.potonganKetidakhadiran`),
          key: "potonganKetidakhadiran",
          labelKey: "label",
          valueKey: "value",
          options: akun4,
          isLoading: loadingData,
          onInputChange: (value) => {
            if (value) setQuery4(value);
          },
        },
        {
          inputType: inputTypes.REACT_SELECT,
          isClearable: true,
          label: t.translate(`words.potonganPinjaman`),
          key: "potonganPinjaman",
          labelKey: "label",
          valueKey: "value",
          options: akun5,
          isLoading: loadingData,
          onInputChange: (value) => {
            if (value) setQuery5(value);
          },
        },
        {
          inputType: inputTypes.REACT_SELECT,
          isClearable: true,
          label: t.translate(`words.potonganLainnya`),
          key: "potonganLainnya",
          labelKey: "label",
          valueKey: "value",
          options: akun6,
          isLoading: loadingData,
          onInputChange: (value) => {
            if (value) setQuery6(value);
          },
        },
        {
          render: (
            <div style={{ textAlign: "center" }}>
              <p style={{ marginLeft: "10px" }}>
                <b>{t.translate(`words.akunPajak`)}</b>
              </p>
            </div>
          ),
        },
        {
          inputType: inputTypes.REACT_SELECT,
          isClearable: true,
          label: t.translate(`words.pph21DibayarDimuka`),
          key: "pph21DibayarDimuka",
          labelKey: "label",
          valueKey: "value",
          options: akun7,
          isLoading: loadingData,
          onInputChange: (value) => {
            if (value) setQuery7(value);
          },
        },
        {
          inputType: inputTypes.REACT_SELECT,
          isClearable: true,
          label: t.translate(`words.pph23DibayarDimuka`),
          key: "pph23DibayarDimuka",
          labelKey: "label",
          valueKey: "value",
          options: akun8,
          isLoading: loadingData,
          onInputChange: (value) => {
            if (value) setQuery8(value);
          },
        },
        {
          inputType: inputTypes.REACT_SELECT,
          isClearable: true,
          label: t.translate(`words.utangPph42`),
          key: "utangPph4a2",
          labelKey: "label",
          valueKey: "value",
          options: akun9,
          isLoading: loadingData,
          onInputChange: (value) => {
            if (value) setQuery9(value);
          },
        },
        {
          inputType: inputTypes.REACT_SELECT,
          isClearable: true,
          label: t.translate(`words.utangPph21`),
          key: "utangPph21",
          labelKey: "label",
          valueKey: "value",
          options: akun10,
          isLoading: loadingData,
          onInputChange: (value) => {
            if (value) setQuery10(value);
          },
        },
        {
          inputType: inputTypes.REACT_SELECT,
          isClearable: true,
          label: t.translate(`words.bebanPajakPph`),
          key: "bebanPph",
          labelKey: "label",
          valueKey: "value",
          options: akun11,
          isLoading: loadingData,
          onInputChange: (value) => {
            if (value) setQuery11(value);
          },
        },
        {
          inputType: inputTypes.REACT_SELECT,
          isClearable: true,
          label: t.translate(`words.labaDitahan`),
          key: "labaDitahan",
          labelKey: "label",
          valueKey: "value",
          options: akun12,
          isLoading: loadingData,
          onInputChange: (value) => {
            if (value) setQuery12(value);
          },
        },
      ]}
      onChange={async (data, key, value) => {
        setItem(data);
        return data;
      }}
      onSubmit={async (data, callback) => {
        var dataSubmit = {
          npwpHead: {
            id: sessionStorage.getItem("npwpHeadId"),
          },
          gajiPokok: { id: data.gajiPokok },
          tunjanganUangTransport: { id: data.tunjanganUangTransport },
          tunjanganUangLembur: { id: data.tunjanganUangLembur },
          potonganKetidakhadiran: { id: data.potonganKetidakhadiran },
          potonganPinjaman: { id: data.potonganPinjaman },
          potonganLainnya: { id: data.potonganLainnya },
          pph21DibayarDimuka: { id: data.pph21DibayarDimuka },
          bebanPph: { id: data.bebanPph },
          pph23DibayarDimuka: { id: data.pph23DibayarDimuka },
          utangPph4a2: { id: data.utangPph4a2 },
          labaDitahan: { id: data.labaDitahan },
          utangPph21: { id: data.utangPph21 },
        };

        try {
          await service.create(dataSubmit);
          callback("Data Berhasil ditambah", false);
          window.location.reload();
        } catch (error) {
          callback(error);
        }
      }}
    />
  );
};

export default inject("envStore")(observer(PemetaanAkunJurnal));
