import React, { useState, useEffect } from 'react';
import t from 'counterpart'
import { FormWrapper, TableWrapper } from '../../../libs/react-mpk/wrapper';
import { DataForm, Hint } from '../../../libs/react-mpk/components';
import moment from 'moment';
import service from '../KasDanBank.service';
import { inject, observer } from 'mobx-react'
import { Table, TableBody, TableHeader, TableRow, TableCell, Card, Button, FontIcon, TableFooter } from 'react-md'
import { toast } from '../../../libs/react-mpk/services';
import { KasDanBankPilihJenisPengeluaran } from '..';

const defaultData=()=>({
    tahunPajak:"2019"
})
var produkData = [
	{
		tanggalFaktur: "23/08/2021",
		masaTerbit: "08",
		tahunTerbit: "2021",
		noFaktur: "004-14.49389727",
		namaPenjual: "Anita",
		dpp: "10000000",
		ppn: "1000000",
		id:'1',
		status:"Lunas"
	}
];

const KasDanBankPengeluaranPPN = ({
	authStore,
	navigationStore,
	envStore,
	match,
	history,
	onRequestClose = () => { },
	...props
}) => {
	const { inputTypes, definition } = FormWrapper
	const [ready, setReady] = useState(true)
	const [dialogPilihJenisPengeluaran, setDialogPilihJenisPengeluaran] = useState(false)
    const [years, setYears] = useState([])
    const generateYears=()=>{
        let currentYear = moment(envStore.env.timestamp).year()
        for(let y = currentYear; y>=2019;y--)
        {
            years.push(String(y))
        }
        setYears(years)
    }
	function additem(){
		setDialogPilihJenisPengeluaran(true)
	}
    useEffect(()=>{
		generateYears()
		setTimeout(fetchData)
		return()=>{}
	},[])
	const fetchData = async()=>{
		try {
			produkData.filter(d=>{
				return d.status === "Lunas"
			})
		} catch (error) {
			toast.errorRequest(error)
		}
	}
	return ready && (
		<>
		<FormWrapper
			baseId="kas-dan-bank-tambah-faktur-masukan-pengeluaran"
			title={t.translate(`modules.KasDanBank.tambahFakturMasukanPengeluaran`)}
            // submitLabel={t.translate(`words.tarikData`)}
			backTo={`/internal/kas-dan-bank`}
			showCommandbar={true}
			style={{
				maxWidth: '100%'
			}}
			definitions={[
				{
					inputType: inputTypes.SELECT,
					label: t.translate(`words.masa`),
					key: 'masa',
					width: '38%',
					style: {
						marginRight: "2%"
					},
					options:[
						{label:'01', value:'1'},
						{label:'02', value:'1'},
						{label:'03', value:'1'},
						{label:'04', value:'1'},
						{label:'05', value:'1'},
						{label:'06', value:'1'},
						{label:'07', value:'1'},
						{label:'08', value:'1'},
						{label:'09', value:'1'},
						{label:'10', value:'1'},
						{label:'11', value:'1'},
						{label:'12', value:'1'},
						{label:'13', value:'1'},
						{label:'14', value:'1'},
						{label:'15', value:'1'},
						{label:'16', value:'1'},
						{label:'17', value:'1'},
						{label:'18', value:'1'},
						{label:'19', value:'1'},
						{label:'20', value:'1'},
						{label:'21', value:'1'},
						{label:'22', value:'1'},
						{label:'23', value:'1'},
						{label:'24', value:'1'},
						{label:'25', value:'1'},
						{label:'26', value:'1'},
						{label:'27', value:'1'},
						{label:'28', value:'1'},
						{label:'29', value:'1'},
						{label:'30', value:'1'},
						{label:'31', value:'1'}
					]
				},
				{
					inputType: inputTypes.SELECT,
					label: t.translate(`words.tahun`),
					key: 'thnPajak',
                    options:years,
					width: '38%',
					style: {
						marginRight: "2%"
					}
                },
				{
					render: (
						<div style={{ width: '20%'}}>
							<Button 
								style={{margin: "10px"}}
								theme='primary'
								themeType="contained"
								onSubmit={async(data, callback) => {
									try {
										let query = {
											"thnPajak.equals": data.thnPajak
										}
										let response = produkData
										TableWrapper.reload("mod-table-bukti-potong")
										callback("Data Berhasil disync", false)
									} catch (error) {
										callback(error)
									}
								}}
							>
								<b>{t.translate(`words.tarikData`)}</b>
							</Button>
						</div>
					)
				},
				{
					render:(
						<div style={{ width: '100%' }}>
							<Hint
								className="mpk-margin-N margin-top margin-bottom"
								message="Hasil Faktur Masukan Pengelauran"
							/>
							<Table style={{ width: '100%' }}>
								<TableHeader>
									<TableRow>
										<TableCell style={{width: '10%'}}>{t.translate('words.non')}</TableCell>
										<TableCell style={{width: '25%'}}>{t.translate('words.tanggalFaktur')}</TableCell>
										<TableCell style={{width: '25%'}}>{t.translate('words.masaTerbit')}</TableCell>
										<TableCell style={{width: '25%'}}>{t.translate('words.tahunTerbit')}</TableCell>
										<TableCell style={{width: '30%'}}>{t.translate('words.noFaktur')}</TableCell>
										<TableCell style={{width: '10%'}}>{t.translate('words.namaPenjual')}</TableCell>
										<TableCell style={{width: '10%'}}>{t.translate('words.dpp')}</TableCell>
										<TableCell style={{width: '10%'}}>{t.translate('words.ppn')}</TableCell>
										<TableCell style={{width: '10%'}}>{t.translate('words.status')}</TableCell>
									</TableRow>
								</TableHeader>
								<TableBody>
									{produkData.map((d, index)=> {
										return (
											<TableRow key={index}>
												<TableCell>{index + 1}</TableCell>
												<TableCell>{d.tanggalFaktur}</TableCell>
												<TableCell>{d.masaTerbit}</TableCell>
												<TableCell>{d.tahunTerbit}</TableCell>
												<TableCell>{d.noFaktur}</TableCell>
												<TableCell>{d.namaPenjual}</TableCell>
												<TableCell>{d.dpp}</TableCell>
												<TableCell>{d.ppn}</TableCell>
												<TableCell>{d.status}</TableCell>
												<TableCell>
													<Button 
														onClick={()=> additem()}
														style={{color:'green', backgroundColor:'lightgreen'}}
													>
														{t.translate("words.verifikasi")}
													</Button>
												</TableCell>
											</TableRow>
										)
									})}
								</TableBody>
							</Table>
						</div>
					)
				}
			]}
		/>
		<KasDanBankPilihJenisPengeluaran
			visible={dialogPilihJenisPengeluaran}
			onRequestClose={()=>{
				setDialogPilihJenisPengeluaran(false)
			}}
		/>
		</>
	)
}
export default inject('navigationStore','envStore')(observer(KasDanBankPengeluaranPPN))