import React, { useEffect, useState } from "react";
import { DataForm } from "../../libs/react-mpk/components";
import t from "counterpart";
import { FormWrapper } from "../../libs/react-mpk/wrapper";
import ErrorService from "../../services/errorService";
// import service from './Enofa.service'
import { cloneDeep, parseInt } from "lodash";
import { disable } from "react-md";
import service from "./Pembelian.service";

const pemotongan = [
  { name: "PPH21", value: "pph21" },
  { name: "PPH22", value: "pph22" },
  { name: "PPH23", value: "pph23" },
  { name: "PPH15", value: "pph15" },
  { name: "PPH4(2)", value: "pph4a2" },
];

const atasTr = [{ name: "Omset", value: "P0001" }];

const perhitunganPajak = [
  { value: "Nilai Lain", name: "Nilai Lain" },
  { value: "Nilai Pembelian", name: "Nilai Pembelian" },
];

const pemotonganPajak = [
  { name: "Iya", value: "Iya" },
  { name: "Tidak", value: "Tidak" },
];

const InputDetailAkunPembelian = ({
  visible = false,
  authStore,
  envStore,
  match,
  history,
  onRequestClose = () => {},
  ...props
}) => {
  const { inputTypes, definition } = FormWrapper;

  let [ready, setReady] = useState(true);
  var thousandSeparator = ".";
  var decimalSeparator = ",";
  const [akunPembelianList, setAkunPembelianList] = useState([]);
  const [loadingAkun, setLoadingAkun] = useState(false);
  let [queryAkun, setQueryAkun] = useState("");
  const [pasalPemotonganList, setPasalPemotonganList] = useState([]);
  const [objekPajakList, setObjekPajakList] = useState([]);

  useEffect(() => {
    initData();
  }, []);

  async function initData() {
    getListPasalPemotongan();
    getListObjekPajak();
    setReady(true);
  }

  async function getListAkunPembelian(queryAkun) {
    setTimeout(async () => {
      let res = await service.getListAkunPembelian(queryAkun);
      var akunPembelians = [];
      res.data.map((d) => {
        akunPembelians.push({
          value: d.id,
          label: `${d.kode} - ${d.nama}`,
          kode: `${d.kode}`,
          nama: `${d.nama}`,
          kategori: {
            debitKredit: `${d.kategoriAkun.debitKredit}`,
          },
        });
      });
      setAkunPembelianList(akunPembelians);
    }, 500);
  }
  useEffect(() => {
    setLoadingAkun(true);
    const timeOutId = setTimeout(() => getListAkunPembelian(queryAkun), 500);
    return () => clearTimeout(timeOutId);
  }, [queryAkun]);

  async function getListPasalPemotongan() {
    setTimeout(async () => {
      let res = await service.getListPasalPemotongan();
      var pasalPemotongans = [];
      res.data.map((d) => {
        pasalPemotongans.push({
          value: d.id,
          label: `${d.nama}`,
        });
      });
      setPasalPemotonganList(pasalPemotongans);
    }, 500);
  }

  async function getListObjekPajak() {
    setTimeout(async () => {
      let res = await service.getListObjekPajak();
      var objekPajaks = [];
      res.data.map((d) => {
        objekPajaks.push({
          value: d.id,
          label: `${d.kode} - ${d.nama}`,
        });
      });
      setObjekPajakList(objekPajaks);
    }, 500);
  }

  const onSubmit = async (data, callback) => {
    let datas = {
      id: null,
      akun: {
        id: data.akun.id,
        kode: data.akun.kode,
        nama: data.akun.nama,
        kategoriAkunDebitKredit: parseInt(data.akun.kategoriAkunDebitKredit),
      },
      nilaiPembelian: data.nilaiPembelian,
      isPotongPajak: data.terdapatPemotonganPajak == "Iya" ? true : false,
      pasalPemotongan: {
        id: 1,
      },

      objekPajak: {
        id: 1,
      },
      ptsTarifPajak:0.5,
      isNilaiPembelian:
        data.terdapatPemotonganPajak == "Iya" &&
        data.perhitunganPajak == "Nilai Pembelian"
          ? true
          : false,
      nominalHitungPajak:
        data.terdapatPemotonganPajak == "Iya" ? data.nominalHitungPajak:0,
      isPajakDitanggung:
        data.terdapatPemotonganPajak == "Iya" && data.isPajakDitanggung,
      nominalPajakDitanggung:
        data.terdapatPemotonganPajak == "Iya" ? data.nominalPajakDitanggung:0,
      nominalPajak: data.terdapatPemotonganPajak == "Iya" ? data.nominalPajak:0,

      total: data.nilaiTagihan || 0,
      keterangan: data.keterangan ,
    };
    localStorage.setItem("akunPembelianForm", JSON.stringify(datas));
    callback("success", false);
  };

  return (
    ready && (
      <DataForm
        baseId="akun-npwp-form"
        title={t.translate(`modules.pembelian.inputAkunPembelian`)}
        visible={visible}
        onRequestClose={onRequestClose}
        asDialog={true}
        defaultData={{
          akunId: null,
          akun: {
            id: null,
            kode: null,
            nama: null,
            kategoriAkunDebitKredit: null,
          },
          nilaiPembelian: 0,
          isPotongPajak: false,
          terdapatPemotonganPajak: "Tidak",
          pasalPemotonganId: "PPh 4(2)",
          pasalPemotongan: {
            id: 1,
          },
          objekPajakId: "P001 - Omset",
          objekPajak: {
            id: 1,
          },
          ptsTarifPajak: 0.5,
          perhitunganPajak: "Nilai Pembelian",
          isNilaiPembelian: true,
          nominalHitungPajak: 0,
          isPajakDitanggung: false,
          nominalPajakDitanggung: 0,
          nominalPajak: 0,
          total: 0,
          keterangan: "",
        }}
        definitions={[
          {
            inputType: inputTypes.REACT_SELECT,
                    isClearable:true,
            label: t.translate(`words.akun`),
            key: "akunId",
            validation: "required",
            labelKey: "label",
            valueKey: "value",
            options: akunPembelianList,
            isLoading: loadingAkun,
            onInputChange: (value) => {
              if (value) setQueryAkun(value);
            },
            required: true,
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            label: "Nilai Pembelian",
            key: "nilaiPembelian",
            thousandSeparator: thousandSeparator,
            decimalSeparator: decimalSeparator,
            type: "text",
            width: "100%",
            required: true,
          },
          {
            inputType: inputTypes.DIVIDER,
            label: "Pemotongan Pajak",
          },
          {
            inputType: inputTypes.SELECT,
            label: "Terdapat Pemotongan Pajak?",
            key: "terdapatPemotonganPajak",
            options: pemotonganPajak.map((d) => ({
              label: d.name,
              value: d.value,
            })),
            required: true,
          },
          {
            inputType: inputTypes.AUTOCOMPLETE,
            label: "Atas Transaksi",
            key: "objekPajakId",
            validation: "required",
            labelKey: "label",
            valueKey: "label",
            type: "search",
            data: objekPajakList,
            show: (d) => {
              return d.terdapatPemotonganPajak === "Iya";
            },
            disabled: true,
          },
          {
            inputType: inputTypes.AUTOCOMPLETE,
            label: "Terdapat Pemotongan Pajak?",
            key: "pasalPemotonganId",
            validation: "required",
            labelKey: "label",
            valueKey: "label",
            type: "search",
            data: pasalPemotonganList,
            show: (d) => {
              return d.terdapatPemotonganPajak === "Iya";
            },
            disabled: true,
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            className: "mpk-padding-N padding-right",
            label: "Tarif Pajak (%)",
            key: "ptsTarifPajak",
            disabled: true,
            show: (d) => {
              return d.terdapatPemotonganPajak === "Iya";
            },
          },
          {
            render: (
              <div style={{ width: "100%" }}>
                <p style={{ margin: "10px" }}>
                  <b>Perhitungan Pajak Berdasarkan</b>
                </p>
              </div>
            ),
            show: (d) => {
              return d.terdapatPemotonganPajak === "Iya";
            },
            required: true,
          },
          {
            inputType: inputTypes.SELECT,
            label: "Perhitungan Pajak",
            className: "mpk-padding-N padding-right",
            key: "perhitunganPajak",
            options: perhitunganPajak.map((d) => ({
              label: d.name,
              value: d.value,
            })),
            width: "50%",
            show: (d) => {
              return d.terdapatPemotonganPajak === "Iya";
            },
            required: true,
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            label: "Nominal Hitung Pajak",
            key: "nominalHitungPajak",
            thousandSeparator: thousandSeparator,
            decimalSeparator: decimalSeparator,
            width: "50%",
            type: "text",
            show: (d) => {
              return (
                d.perhitunganPajak == "Nilai Lain" &&
                d.terdapatPemotonganPajak == "Iya"
              );
            },
          },
          {
            inputType: inputTypes.CHECKBOX,
            label: "Pajak ditanggung",
            value: false,
            key: "isPajakDitanggung",
            show: (d) => {
              return d.terdapatPemotonganPajak == "Iya";
            },
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            label: "Pajak ditaggung",
            key: "nominalPajakDitanggung",
            thousandSeparator: thousandSeparator,
            decimalSeparator: decimalSeparator,
            disabled: true,
            show: (d) => {
              return d.terdapatPemotonganPajak == "Iya";
            },
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            label: "Nominal Pajak",
            className: "mpk-padding-N padding-right",
            key: "nominalPajak",
            thousandSeparator: thousandSeparator,
            decimalSeparator: decimalSeparator,
            width: "50%",
            disabled: true,
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            label: "Nilai Tagihan",
            key: "nilaiTagihan",
            thousandSeparator: thousandSeparator,
            decimalSeparator: decimalSeparator,
            width: "50%",
            disabled: true,
          },
          {
            render: (
              <div style={{ width: "100%" }}>
                <p style={{ margin: "10px" }}>
                  <b>Informasi Tambahan</b>
                </p>
              </div>
            ),
          },
          {
            inputType: inputTypes.TEXTAREA,
            label: "Keterangan",
            key: "keterangan",
          },
        ]}
        onChange={async (formData, key, value) => {
          if (key == "akunId") {
            akunPembelianList.map((d) => {
              if (d.value == value) {
                formData["akun"]["id"] = d.value;
                formData["akun"]["kode"] = d.kode;
                formData["akun"]["nama"] = d.nama;
                formData["akun"]["kategoriAkunDebitKredit"] = parseInt(
                  d.kategori.debitKredit
                );
              }
            });
          }

          if (key == "pasalPemotonganId") {
            pasalPemotonganList.map((d) => {
              if (d.label == value) {
                formData["pasalPemotongan"]["id"] = d.value;
              }
            });
          }

          if (key == "objekPajakId") {
            objekPajakList.map((d) => {
              if (d.label == value) {
                formData["objekPajak"]["id"] = d.value;
              }
            });
          }

          if (formData["terdapatPemotonganPajak"] === "Iya") {
            if (formData["perhitunganPajak"] == "Nilai Lain") {
              formData["nominalPajak"] =
                formData["nominalHitungPajak"] * (0.5 / 100);
              // formData['nilaiTagihan'] = formData['nilaiLainField']
            } else {
              formData["nominalHitungPajak"] = formData["nilaiPembelian"];
              formData["nominalPajak"] =
                formData["nominalHitungPajak"] * (0.5 / 100);
              // formData['nilaiTagihan'] = formData['nilaiPembelian']
              // formData['nominalPajak'] = formData['nilaiPembelian'] * (formData['tarifPajak'] / 100)
            }

            // PAJAK DITANGGUNG
            if (formData["isPajakDitanggung"]) {
              formData["nominalPajakDitanggung"] = formData["nominalPajak"];
              formData["nilaiTagihan"] = formData["nilaiPembelian"];
            } else {
              formData["nominalPajakDitanggung"] = 0;
              formData["nilaiTagihan"] =
                formData["nilaiPembelian"] - formData["nominalPajak"];
            }
          } else {
            formData["nominalPajak"] = 0;
            formData["nilaiTagihan"] = formData["nilaiPembelian"];
          }
          // return value
        }}
        onSubmit={onSubmit}
      />
    )
  );
};

export default InputDetailAkunPembelian;
