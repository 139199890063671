import { crud, http } from '../../../libs/react-mpk/services';

let service = null
let serviceUrl = '/pengaturan'
let tahunSobatPajak = sessionStorage.getItem('tahunSobatBuku');
let npwpHeadId = sessionStorage.getItem('npwpHeadId');
service = new crud('/pengaturan');

service.getOneById = async() => {
  return http.request({
    method: http.methods.GET,
    url: `/npwp/${npwpHeadId}/tahun/${tahunSobatPajak}`
  })
}
service.getKlu = async(queryNama) => {
  let query ={
    page:0,
    size:10,
    sort: "id,ASC",
    "nama.contains": queryNama
  }
  return http.request({
    method: http.methods.GET,
    url: `/klu`,
    query
  })
}
service.update = async(data)=> {
  return http.request({
    method: http.methods.PUT,
    url: `/npwp`,
    data
  })
}

export default service;