import React, { useState } from 'react'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { Button } from 'react-md'
import { FormWrapper, TableWrapper } from '../../libs/react-mpk/wrapper'
import { inputTypes } from '../../libs/react-mpk/config/constant'
const FormTanggal = () => {
    return (
        <>
            <FormWrapper
                baseId="kas-dan-bank-form-tanggal"
                showCommandbar={false}
                style={{
                    maxWidth: '100%'
                }}
                definitions={[
                    {
                        inputType: inputTypes.DATEPICKER,
                        label: t.translate(`words.sejakTanggal`),
                        key: 'sejakTanggal',
                        width: '28%',
                        style: {
                            marginRight: "2%"
                        },
                    },
                    {
                        inputType: inputTypes.DATEPICKER,
                        label: t.translate(`words.sampaiTanggal`),
                        key: 'sampaiTanggal',
                        width: '28%',
                        style: {
                            marginRight: "2%"
                        }
                    },
                    {
                        render: (
                            <div style={{ width: '20%'}}>
                                <Button 
                                    style={{margin: "10px"}}
                                    theme='primary'
                                    themeType="contained"
                                    onSubmit={async(data, callback) => {
                                       
                                        try {
                                            let query = {
                                                "thnPajak.equals": data.thnPajak
                                            }
                                            TableWrapper.reload("mod-table-bukti-potong")
                                            callback("Data Berhasil disync", false)
                                        } catch (error) {
                                            callback(error)
                                        }
                                    }}
                                >
                                    <b>{t.translate(`words.kirimKeEmail`)}</b>
                                </Button>
                            </div>
                        )
                    },
                    {
                        render: (
                            <div style={{ width: '20%'}}>
                                <Button 
                                    style={{margin: "10px"}}
                                    theme='warning'
                                    themeType="contained"
                                    onSubmit={async(data, callback) => {
                                     
                                        try {
                                            let query = {
                                                "thnPajak.equals": data.thnPajak
                                            }
                                            TableWrapper.reload("mod-table-bukti-potong")
                                            callback("Data Berhasil disync", false)
                                        } catch (error) {
                                            callback(error)
                                        }
                                    }}
                                >
                                    <b>{t.translate(`words.tampilkan`)}</b>
                                </Button>
                            </div>
                        )
                    },
                ]}
            />
        </>
    )
}

export default inject("navigationStore",'envStore')(observer(FormTanggal))