import React, { useState } from "react";
import { TableWrapper, TabsWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import service from "./Produk.service";
import moment from "moment";
import 'moment/locale/id';
import ProdukForm from "./ProdukForm";
import iziToast from "izitoast";
import { DataForm } from "../../libs/react-mpk/components";
import { inputTypes } from "../../libs/react-mpk/config/constant";
import { inject, observer } from "mobx-react";
import ProdukDetail from "./ProdukDetail";
import { toast } from "../../libs/react-mpk/services";
import ProdukTable from "./ProdukTable";

const Produk = ({
  className = "",
  showCommandbar = true,
  match,
  navigationStore,
  modalStore,
}) => {
  var npwpHeadId = match.params.npwpHeadId;
  const [showForm, setShowForm] = useState(false);
  let [search, setSearch] = useState({});
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [ShowDetail, setShowDetail] = useState(false);
  const [itemDetail, setItemDetail] = useState(null);
  const baseId = "produk";
  const basePath = `/internal/npwp/${npwpHeadId}/produk`;

  var actions = [
    new TableWrapper.action(
      `${t.translate("words.add")}`,
      "mdi mdi-plus",
      () => {
        setSelectedItemId(null);
        setShowForm(true);
      },
      true
    ),
  ];

  var itemActions = [
    new TableWrapper.action(`Detail`, "mdi mdi-import", (item) => {
      setItemDetail(item.id);
      setShowDetail(true);
    }),
    new TableWrapper.action(
      "Edit",
      "mdi mdi-pencil",
      (item) => {
        setSelectedItemId(item.id);
        setShowForm(true);
      },
      true
    ),
    new TableWrapper.action(
      "Delete",
      "mdi mdi-delete",
      (item) => {
        hapus(item);
      },
      true
    ),
  ];
  async function hapus(item) {
    modalStore.showConfirm({
      title: t.translate("words.delete"),
      children: t.translate("words.confirmHapus"),
      onSubmit: (callback) => {
        hapusExc(item);
        callback();
      },
    });
  }
  async function hapusExc(item) {
    try {
      var r = await service.deleteById(item.id);

      if (r.data.message == "sukses") {
        iziToast.success({
          message: r.data.message,
        });
      } else {
        iziToast.info({
          message: r.data.message,
        });
      }
      document.getElementsByClassName("mdi-reload")[0].click();
    } catch (e) {
      toast.errorRequest(e);
    }
  }

  return (
    <>
      <TabsWrapper
        showCommandbar={false}
        baseId="produk-tabs"
        title={t.translate("modules.produk.barang.title")}
        tabs={[
          {
            label: t.translate("modules.produk.barang.title"),
            render: (
              <>
                <ProdukTable
                  baseId={baseId}
                  className={className}
                  title={t.translate("modules.produk.barang.title")}
                  onFetchData={(query) => {
                    return new Promise(async (resolve, reject) => {
                      try {
                        let jenis = {
                          "jenis.equals": 1,
                        };
                        query = {
                          ...query,
                          ...jenis,
                        };
                        let getPage = await service.getPage(query);

                        getPage.headers["x-pagination-count"] =
                          getPage.headers["x-total-count"];
                        resolve(getPage);
                      } catch (e) {
                        resolve();
                        // ErrorService(e)
                      }
                    });
                  }}
                  actions={actions}
                  itemActions={itemActions}
                  showCommandbar={showCommandbar}
                />
              </>
            ),
          },
          {
            label: t.translate("modules.produk.jasa.title"),
            render: (
              <>
                <ProdukTable
                  baseId={baseId}
                  className={className}
                  title={t.translate("modules.produk.jasa.title")}
                  onFetchData={(query) => {
                    return new Promise(async (resolve, reject) => {
                      try {
                        let jenis = {
                          "jenis.equals": 2,
                        };
                        query = {
                          ...query,
                          ...jenis,
                        };
                        let getPage = await service.getPage(query);
                        getPage.headers["x-pagination-count"] =
                          getPage.headers["x-total-count"];
                        resolve(getPage);
                      } catch (e) {
                        resolve();
                      }
                    });
                  }}
                  actions={actions}
                  itemActions={itemActions}
                  showCommandbar={showCommandbar}
                />
              </>
            ),
          },
        ]}
      />
      <ProdukForm
        visible={showForm}
        onRequestClose={() => {
          setShowForm(false);
          setSelectedItemId(null);
          document.getElementsByClassName("mdi-reload")[0].click();
        }}
        itemId={selectedItemId}
      />
      <ProdukDetail
        visible={ShowDetail}
        onRequestClose={() => {
          setShowDetail(false);
          setItemDetail(null);
        }}
        itemId={itemDetail}
      />
    </>
  );
};
export default inject(
  "envStore",
  "modalStore",
  "navigationStore"
)(observer(Produk));
