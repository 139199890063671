import React, {useState} from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import service from './TableSample.service'
import moment from 'moment'
import TableSampleForm from './TableSampleForm'

const TableSample = ({
  className       = '',
  showCommandbar  = true
}) => {
  const [showForm, setShowForm] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  
  return (
    <>
      <TableWrapper
        baseId="mod-table-sample"
        title={t.translate('modules.table.title')}
        className={className}
        hintMessage={t.translate('modules.table.hint.message')}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate('modules.table.hint.more')}
        defaultData={[]}
        defaultSortBy="date"
        defaultCollapse={true}
        useCriteria={false}
        selectable={false}
        useQueryHistory={false}
        extraFilter={<div>Test</div>}
        columns={[
          {
            label: 'String',
            searchable: true,
            sortable: true,
            key:'string',
            render: item => (
              <TableWrapper.Link
                to={`/internal/inline-form`}
              >
                {item.string}
              </TableWrapper.Link>)
          },
          {
            label: 'Number',
            searchable: true,
            key: 'number',
            type: TableWrapper.dataTypes.NUMBER,
            render: item => (item.number)
          },
          {
            label: 'Boolean',
            type: TableWrapper.dataTypes.BOOLEAN,
            render: item => (
              <TableWrapper.Status 
                type={item.boolean 
                  ? TableWrapper.Status.types.SUCCESS
                  : TableWrapper.Status.types.ERROR
                }
              >
                {item.boolean ? 'TRUE' : 'FALSE'}
              </TableWrapper.Status>
            )
          },
          {
            label: 'Date',
            type: TableWrapper.dataTypes.DATE,
            render: item => (moment(item.date).locale('id').format('ll'))
          }
        ]}
        actions={[
          new TableWrapper.action(
            'Add', 
            'mdi mdi-plus', 
            () => {
              setSelectedItem(null)
              setShowForm(true)
            }, 
            true
          )
        ]}
        itemActions={[
          new TableWrapper.action(
            'Edit', 
            'mdi mdi-pencil', 
            (item) => {
              setSelectedItem(item)
              setShowForm(true)
            }, 
            true
          ),
          new TableWrapper.action('Delete', 'mdi mdi-delete', () => console.log('request delete'), true)
        ]}
        onFetchData={ async query => {
          return service.get(query)
        }}
        showCommandbar={showCommandbar}
        showFilterPeriod={true}
      />
      <TableSampleForm
        visible={showForm}
        onRequestClose={() => {
          setShowForm(false)
          setSelectedItem(null)
        }}
        item={selectedItem}
      />
    </>
  )
}

export default TableSample
