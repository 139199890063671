import React from "react";
import { TableWrapper } from "../../libs/react-mpk/wrapper";

export default function KategoriUnitTable({
  baseId = "",
  title = "",
  className = "",
  onFetchData = () => {},
  toDetail='',
  actions=[],
  itemActions=[],
  showCommandbar=true
}) {
  return (
    <TableWrapper
      baseId={baseId}
      title={title}
      className={className}
      defaultData={[]}
      defaultSortBy="tanggalDibuat"
      showFilter={false}
      showActionColumn={true}
      useFilter={true}
      defaultCollapse={true}
      useCriteria={true}
      selectable={false}
      useQueryHistory={false}
      onFetchData={onFetchData}
      columns={[
        {
          label: "Kode",
          searchable: true,
          sortable: false,
          key: "kode",
          render: (item) => (
            <TableWrapper.Link
              to={toDetail+item.id}
            >
              {item.kode}
            </TableWrapper.Link>
          ),
        },
        {
          label: "Nama",
          searchable: true,
          sortable: false,
          key: "nama",
          render: (item) => item.nama,
        },
      ]}
      actions={actions}
      itemActions={itemActions}
      showCommandbar={showCommandbar}
      showFilterPeriod={false}
    />
  );
}
