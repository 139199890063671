let utilsService = ({}) => {};

utilsService.parseNPWP = (str) => {
  if (str === null) return "";
  try {
    let s;
    let ss = "";

    if (str.length < 2) return str;
    s = str.substring(0, 2);
    ss += s;
    ss += ".";

    if (str.length < 5) {
      s = str.substring(2);
      if (s.length > 0) return ss + s;
      return ss.substring(0, ss.length - 1);
    }
    s = str.substring(2, 5);
    ss += s;
    ss += ".";

    if (str.length < 8) {
      s = str.substring(5);
      if (s.length > 0) return ss + s;
      return ss.substring(0, ss.length - 1);
    }
    s = str.substring(5, 8);
    ss += s;
    ss += ".";

    if (str.length < 9) {
      s = str.substring(8);
      if (s.length > 0) return ss + s;
      return ss.substring(0, ss.length - 1);
    }
    s = str.substring(8, 9);
    ss += s;
    ss += "-";

    if (str.length < 12) {
      s = str.substring(9);
      if (s.length > 0) return ss + s;
      return ss.substring(0, ss.length - 1);
    }
    s = str.substring(9, 12);
    ss += s;
    ss += ".";

    if (str.length < 15) {
      s = str.substring(12);
      if (s.length > 0) return ss + s;
      return ss.substring(0, ss.length - 1);
    }
    s = str.substring(12, 15);
    ss += s;
    return ss;
  } catch (e) {
    return str;
  }
};

utilsService.beautifyNoTransaksi = (str) => {
  if (str === null) return "-";
  try {
    let s;
    let ss = "";

    if (str.length < 12) return str;
    else if (str.length == 12) {
      s = str.substring(0, 2);
      ss += s;
      ss += "-";
      s = str.substring(2, 6);
      ss += s;
      ss += "/";
      s = str.substring(6, 8);
      ss += s;
      ss += "/";
      s = str.substring(8, 12);
      ss += s;
      return ss;
    } else if (str.length == 16) {
      s = str.substring(0, 4);
      ss += s;
      ss += ".";
      s = str.substring(4, 6);
      ss += s;
      ss += "-";
      s = str.substring(6, 10);
      ss += s;
      ss += "/";
      s = str.substring(10, 12);
      ss += s;
      ss += "/";
      s = str.substring(12, 16);
      ss += s;
      return ss;
    } else if (str.length == 17) {
      s = str.substring(0, 5);
      ss += s;
      ss += ".";
      s = str.substring(5, 7);
      ss += s;
      ss += "-";
      s = str.substring(7, 11);
      ss += s;
      ss += "/";
      s = str.substring(11, 13);
      ss += s;
      ss += "/";
      s = str.substring(13, 17);
      ss += s;
      return ss;
    } else if (str.length == 18) {
      s = str.substring(0, 6);
      ss += s;
      ss += ".";
      s = str.substring(6, 8);
      ss += s;
      ss += "-";
      s = str.substring(8, 12);
      ss += s;
      ss += "/";
      s = str.substring(12, 14);
      ss += s;
      ss += "/";
      s = str.substring(14, 18);
      ss += s;
      return ss;
    } else if (str.length == 19) {
      s = str.substring(0, 7);
      ss += s;
      ss += ".";
      s = str.substring(7, 9);
      ss += s;
      ss += "-";
      s = str.substring(9, 13);
      ss += s;
      ss += "/";
      s = str.substring(13, 15);
      ss += s;
      ss += "/";
      s = str.substring(15, 19);
      ss += s;
      return ss;
    } else if (str.length == 20) {
      s = str.substring(0, 8);
      ss += s;
      ss += ".";
      s = str.substring(9, 10);
      ss += s;
      ss += "-";
      s = str.substring(10, 14);
      ss += s;
      ss += "/";
      s = str.substring(14, 16);
      ss += s;
      ss += "/";
      s = str.substring(16, 20);
      ss += s;
      return ss;
    } else if (str.length == 21 && str.includes("PJPOS")) {
      s = str.substring(0, 6);
      ss += s;
      ss += ".";
      s = str.substring(6, 8);
      ss += s;
      ss += "-";
      s = str.substring(8, 11);
      ss += s;
      ss += "-";
      s = str.substring(11, 15);
      ss += s;
      ss += "/";
      s = str.substring(15, 17);
      ss += s;
      ss += "/";
      s = str.substring(17, 21);
      ss += s;
      return ss;
    }
    return str;
  } catch (e) {
    return str;
  }
};

utilsService.beautifyBulan = (str) => {
  if (str == null || str == "") return " - ";
  else if (str == 1) return "Januari";
  else if (str == 2) return "Februari";
  else if (str == 3) return "Maret";
  else if (str == 4) return "April";
  else if (str == 5) return "Mei";
  else if (str == 6) return "Juni";
  else if (str == 7) return "Juli";
  else if (str == 8) return "Agustus";
  else if (str == 9) return "September";
  else if (str == 10) return "Oktober";
  else if (str == 11) return "November";
  else if (str == 12) return "Desember";
};
utilsService.beautifyNominal = (str) => {
  const integerPart = Math.floor(str);
  const decimalPart = (str % 1).toFixed(2).split(".")[1];

  let formattedNumber = integerPart.toString();
  if (decimalPart !== "00") {
    formattedNumber += "." + decimalPart;
  }
  return str;
};

export default utilsService;
