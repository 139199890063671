import React, { useState } from "react";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import { useParams } from "react-router-dom"
import service from "./Biaya.service";
import { Button } from "@react-md/button";


const BiayaDebit = ({ envStore, className = "", showCommandbar = true, title, ...props }) => {
    let [showForm, setShowForm] = useState(false);
    let [selectedItem, setSelectedItem] = useState(null)

    return (
        <>
            <TableWrapper
                baseId="mod-table-pembelian-debit"
                title='Debit'
                className={className}
                backTo={`/${envStore.env.applicationType}/biaya`}
                // hintMessage={t.translate("modules.table.hint.message")}
                // hintIconClassName="mdi mdi-information"
                // hintMore={t.translate("modules.table.hint.more")}
                defaultData={[]}
                defaultSortBy="createdDate"
                useCriteria={false}
                columns={[
                    {
                        label: "Nama Akun",
                        // searchable: true,
                        key: "name",
                        render: item => (item.debit.namaAkun),
                    },
                    {
                        label: "Nominal",
                        // searchable: true,
                        key: "nominal",
                        render: (item) => item.debit.nominal,
                    },
                    {
                        label: "Posisi",
                        // searchable: true,
                        key: "posisi",
                        render: (item) => item.debit.posisi,
                    }
                ]}
                onFetchData={async query => {
                    return service.get(query);
                }}
                // showCommandbar={showCommandbar}
                showFilterPeriod={false}
            />
        </>
    );
};

export default inject("modalStore", "envStore")(observer(BiayaDebit));