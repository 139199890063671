import React, { useState } from "react";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import moment from "moment";
import "moment/locale/id";
import iziToast from "izitoast";
import { inject, observer } from "mobx-react";
import service from "./Pembelian.service";
// import PembelianForm from './PembelianForm'
import {
  format,
  formatRupiah,
} from "./../../libs/react-mpk/services/number.service";
import {
  Button,
  Dialog,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "react-md";
import utilsService from "../../services/utilsService";
import PembelianBayarTagihan from "./PembelianBayarTagihan";
import { toast } from "../../libs/react-mpk/services";
import { useLocation } from "react-router";

const STAT_BELUM_BAYAR = 1;
const STAT_BAYAR_SEBAGIAN = 2;
const STAT_LUNAS = 3;

const Pembelian = ({
  className = "",
  showCommandbar = true,
  modalStore,
  match,
  history,
  navigationStore,
}) => {
  const { state: { tahun } = {} } = useLocation();
  var tahunSobatBuku = sessionStorage.getItem("tahunSobatBuku");
  var npwpHeadId = match.params.npwpHeadId;
  var dataAkun = JSON.parse(sessionStorage.getItem("dataPenggunaNpwp"));
  const [showBayar, setShowBayar] = useState(false);
  const [dataBayar, setDataBayar] = useState(null);
  const baseId = "pembelian";
  const basePath = `/internal/npwp/${npwpHeadId}/pembelian`;
  const [showDialog, setDialog] = useState(false);
  const basePath2 = `pembelian`;

  let KATEGORI_PEMBELIAN = 1;

  var actions = [
    new TableWrapper.action(
      dataAkun.pkp == true ? "Pembelian PPN" : "Pembelian Non PPN",
      "mdi mdi-plus",
      () => {
        navigationStore.redirectTo(
          `/internal/npwp/${npwpHeadId}/pembelian/new/non-ppn`
        );
      },
      true
    ),
  ];
  var itemActions = [
    new TableWrapper.action(
      "Bayar Tagihan",
      "mdi mdi-cash",
      (item) => {
        setShowBayar(true);
        setDataBayar(item);
      },
      (item) =>
        item.pembayaran.status == STAT_BELUM_BAYAR ||
        item.pembayaran.status == STAT_BAYAR_SEBAGIAN
          ? true
          : false
    ),
    new TableWrapper.action("Riwayat", "mdi mdi-history", (item) => {
      history.push(`pembelian/${item.id}/history`);
    }),
    new TableWrapper.action("Selengkapnya", "mdi mdi-import", (item) => {
      localStorage.setItem("pembelianDetail", JSON.stringify(item));
      history.push(`${basePath2}/${item.id}/detail/non-ppn`);
      sessionStorage.setItem("noInvoicePembelian", item.noTransaksi);
      sessionStorage.setItem("idPembelian", item.id);
    }),
    new TableWrapper.action(
      `${t.translate("words.jurnalEntri")}`,
      "mdi mdi-application",
      (item) => {
        localStorage.setItem("jurnalEntriPembelian", JSON.stringify(item));
        history.push(`${basePath}/${item.noTransaksi}/jurnal-entri`);
      }
    ),
    new TableWrapper.action(
      `${t.translate("words.batalkan")}`,
      "mdi mdi-close",
      (item) => {
        batalkan(item);
      },
      (item) => {
        return item.pembayaran.status == 2 ||
          item.pembayaran.status == 3 ||
          item.pembayaran.status == 4
          ? false
          : true;
      }
    ),
  ];
  async function batalExc(item, callback) {
    try {
      var r = await service.batalkan(item.id);
      var { data, status } = r;
      if (status == 200) {
        iziToast.success({
          message: "Pembelian Dibatalkan",
        });
      } else {
        iziToast.info({
          message: data.message,
        });
      }
      callback();
      document.getElementsByClassName("mdi-reload")[0].click();
    } catch (e) {
      toast.errorRequest(e);

      callback();
    }
  }

  async function batalkan(item) {
    modalStore.showConfirm({
      title: "Batalkan?",
      children: "Apakah anda yakin akan membatalkan faktur pajak terpilih?",
      onSubmit: (callback) => {
        batalExc(item, callback);
      },
    });
  }

  return (
    <>
      <TableWrapper
        baseId={baseId}
        title={t.translate("modules.pembelian.title")}
        className={className}
        hintMessage={t.translate("modules.pembelian.hint.message")}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate("modules.pembelian.hint.more")}
        defaultData={[]}
        defaultSortBy="tanggalDibuat"
        showFilter={false}
        showActionColumn={true}
        useFilter={true}
        backTo={tahun && `/internal/npwp/${npwpHeadId}/dashboard`}
        defaultCollapse={true}
        useCriteria={true}
        selectable={false}
        useQueryHistory={false}
        onFetchData={(query) => {
          return new Promise(async (resolve, reject) => {
            try {
              query = {
                ...query,
                "kategori.equals": KATEGORI_PEMBELIAN,
                tahun: tahun ? tahun : tahunSobatBuku,
              };
              let getPage = await service.getPage(query);
              getPage.headers["x-pagination-count"] =
                getPage.headers["x-total-count"];
              resolve(getPage);
            } catch (e) {
              resolve();
              // ErrorService(e)
            }
          });
        }}
        columns={[
          {
            label: "Nomor Transaksi",
            searchable: true,
            sortable: false,
            key: "noTransaksi",
            render: (item) =>
              utilsService.beautifyNoTransaksi(item.noTransaksi),
          },
          {
            label: "Nama",
            searchable: true,
            sortable: false,
            key: "namaLawanTransaksi",
            render: (item) => item.lawanTransaksi.nama,
          },
          {
            label: "Nominal",
            searchable: false,
            sortable: false,
            key: "total",
            render: (item) => ` ${formatRupiah(item.total)}`,
          },
          {
            label: "Sisa Tagihan",
            searchable: false,
            sortable: false,
            key: "nominal",
            render: (item) =>
              item.pembayaran.sisaBayar !== 0
                ? " " + formatRupiah(item.pembayaran.sisaBayar)
                : "-",
          },
          {
            label: "Tanggal Transaksi",
            type: TableWrapper.dataTypes.DATE,
            render: (item) =>
              moment(item.tanggalTransaksi).locale("id").format("DD MMMM YYYY"),
          },

          {
            label: "Status Pembayaran",
            type: TableWrapper.dataTypes.BOOLEAN,
            render: (item) => (
              <TableWrapper.Status
                type={
                  item.pembayaran.status == STAT_LUNAS
                    ? TableWrapper.Status.types.SUCCESS
                    : item.pembayaran.status == STAT_BAYAR_SEBAGIAN
                    ? TableWrapper.Status.types.PROGRESS
                    : item.pembayaran.status == STAT_BELUM_BAYAR &&
                      item.tanggalJatuhTempo >
                        moment(new Date())
                          .locale("id")
                          .format("yyyy-MM-DD hh:mm:ss")
                    ? TableWrapper.Status.types.IDLE
                    : item.pembayaran.status == STAT_BELUM_BAYAR &&
                      item.tanggalJatuhTempo <
                        moment(new Date())
                          .locale("id")
                          .format("yyyy-MM-DD hh:mm:ss")
                    ? TableWrapper.Status.types.ERROR
                    : TableWrapper.Status.types.ERROR
                }
              >
                {item.pembayaran.status == STAT_BELUM_BAYAR &&
                item.tanggalJatuhTempo >
                  moment(new Date()).locale("id").format("yyyy-MM-DD hh:mm:ss")
                  ? "Belum Bayar"
                  : item.pembayaran.status == 2
                  ? "Bayar Sebagian"
                  : item.pembayaran.status == 3
                  ? "Lunas"
                  : item.pembayaran.status == STAT_BELUM_BAYAR &&
                    item.tanggalJatuhTempo <
                      moment(new Date())
                        .locale("id")
                        .format("yyyy-MM-DD hh:mm:ss")
                  ? "Jatuh Tempo"
                  : "Batal"}
              </TableWrapper.Status>
            ),
          },
          {
            label: "Kategori",
            searchable: false,
            sortable: false,
            key: "kategori",
            render: (item) => (item.isPPn ? "PPn" : "Non PPn"),
          },
          {
            label: "Tanggal Dibuat",
            type: TableWrapper.dataTypes.DATE,
            render: (item) =>
              moment(item.tanggalDibuat)
                .locale("id")
                .format("DD MMMM YYYY, HH:mm:ss"),
          },
        ]}
        actions={actions}
        itemActions={itemActions}
        showCommandbar={showCommandbar}
      />
      {/*TERIMA PEMBAYARAN*/}
      <PembelianBayarTagihan
        visible={showBayar}
        onRequestClose={() => {
          setShowBayar(false);
          setDataBayar(null);
          document.getElementsByClassName("mdi-reload")[0].click();
        }}
        datas={dataBayar}
      />
      <Dialog
        id="add-data-dialog"
        visible={showDialog}
        onRequestClose={() => {
          setDialog(false);
        }}
      >
        <DialogHeader>
          <DialogTitle>{t.translate(`words.add`)}</DialogTitle>
        </DialogHeader>
        <DialogFooter>
          {dataAkun.pkp == true ? (
            <Button
              onClick={() => {
                window.location.href = `pembelian/tarikdata/new`;
              }}
            >
              Pembelian PPN
            </Button>
          ) : (
            <Button
              onClick={() => {
                window.location.href = `pembelian/new/non-ppn`;
              }}
            >
              Pembelian Non PPN
            </Button>
          )}
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default inject("modalStore", "navigationStore")(observer(Pembelian));
