import React, { useEffect, useState } from "react";
import { DataForm, LoaderInfo, Modal } from "../../libs/react-mpk/components";
import t from "counterpart";
import { FormWrapper } from "../../libs/react-mpk/wrapper";
import service from "./AkunNpwp.service";
import AreaService from "../../services/Area.service";
import moment from "moment";
import "moment/locale/id";
import { defaultDataAkunNpwp, defaultDataAkunNpwpSubmit } from "../defaultData";
import "moment/locale/id";
import { inject, observer } from "mobx-react";
import { Button, FontIcon, Text, TextContainer, Tooltipped } from "react-md";
import { toast } from "../../libs/react-mpk/services";

const AkunNpwpForm = ({
  visible = false,
  authStore,
  envStore,
  match,
  history,
  modalStore,
  onRequestClose = () => {},
  ...props
}) => {
  const { inputTypes } = FormWrapper;
  const PENGURUSANEFIN_SUDAH_MEMILIKI_EFIN = 1;
  const PENGURUSANEFIN_EFIN_DIURUS_SENDIRI = 3;
  const STATUSPERNIKAHAN_MENIKAH_INT = 1;
  const STATUSPERNIKAHAN_BELUM_MENIKAH_INT = 2;
  const STATUSPERNIKAHAN_CERAI_INT = 3;
  const [dataKlu, setDataKlu] = useState([]);
  const [listKlu, setListKlu] = useState([]);
  const [loadingKlu, setLoadingKlu] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingById, setLoadingById] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingAll, setLoadingAll] = useState(true);
  let [dataSubmit, setDataSubmit] = useState(defaultDataAkunNpwpSubmit());
  let [item, setItem] = useState(defaultDataAkunNpwp());
  let [ready, setReady] = useState(true);
  let [isDisabled, setIsDisabled] = useState(false);
  let [pkp, setPkp] = useState(true);
  let [nikah, setNikah] = useState(true);
  let [editRole, setEditRole] = useState(true);
  let [isOp, setIsOp] = useState(true);
  let [isPunyaNpwp, setIsPunyaNpwp] = useState(true);
  let [isMenikah, setIsMenikah] = useState(false);
  let [isPisahHarta, setIsPisahHarta] = useState(false);
  let [disabledNik, setDisabledNik] = useState(false);
  let [disabledTanggalPkp, setDisabledTanggalPkp] = useState(false);
  let [queryKlu, setQueryKlu] = useState("");

  useEffect(() => {
    async function initData() {
      if (visible) {
        let selectedItem = item;
        // selectedItem.npwpOpInt = "0";
        setIsOp(true);
        setDisabledNik(false);
        setDisabledTanggalPkp(false);
        setIsDisabled(props.isDisabled);

        if (props.itemId != null) {
          try {
            setLoadingById(true);
            var tahunSobatBuku = sessionStorage.getItem("tahunSobatBuku");
            let res = await service.getOneById(props.itemId, tahunSobatBuku);
            let { data } = res;
            setDataSubmit(data);
            if (data.npwpHead.npwpOp == true) {
              setEditRole(true);
              setNikah(true);
              if (data.pkp == true) {
                setPkp(false);
              }
            }
            if (data.npwpHead.npwpOp == false) {
              setEditRole(false);
              setNikah(false);
              if (data.pkp == true) {
                setPkp(false);
              }
            }
            setIsOp(selectedItem.npwpOp);
            if (selectedItem.pkp) {
              setDisabledTanggalPkp(true);
            } else {
              setDisabledTanggalPkp(false);
            }
            selectedItem.npwpOp = data.npwpHead.npwpOp;
            selectedItem.npwpOpInt = selectedItem.npwpOp ? "0" : "1";
            selectedItem.noKtp = data.npwpHead.noKtp;
            selectedItem.npwp = data.npwpHead.npwp;
            selectedItem.memilikiNpwp = true;
            selectedItem.namaNpwp = data.npwpHead.namaNpwp;
            selectedItem.noTelepon = data.npwpHead.noTelepon;
            selectedItem.email = data.npwpHead.email;
            selectedItem.alamat = data.npwpHead.alamat;
            selectedItem.kodepos = data.npwpHead.area.kodepos;
            selectedItem.areaId = data.npwpHead.area.id;
            selectedItem.areaJenis = data.npwpHead.area.jenis;
            selectedItem.areaKabupaten = data.npwpHead.area.kabupaten;
            selectedItem.areaKecamatan = data.npwpHead.area.kecamatan;
            selectedItem.areaKelurahan = data.npwpHead.area.kelurahan;
            selectedItem.areaKodepos = data.npwpHead.area.kodepos;
            selectedItem.areaPropinsi = data.npwpHead.area.propinsi;
            selectedItem.omzetSudahDihitung = data.dataAwal.omzetSudahDihitung;
            selectedItem.noEfin = "0000000000";
            selectedItem.klu = data.npwpHead.klu?.id;
            selectedItem.kluNama = data.npwpHead.klu?.nama;

            selectedItem.pegawai = data.pegawai;
            selectedItem.pemilikUsaha = data.pemilikUsaha;
            selectedItem.pekerjaBebas = data.pekerjaBebas;
            selectedItem.pkp = data.npwpHead.pkp;
            selectedItem.tanggalPkp = data.npwpHead.tanggalPkp;
            selectedItem.jumlahTanggungan = data.jumlahTanggungan
              ? data.jumlahTanggungan.toString()
              : "0";
            selectedItem.npwpPasangan = data.npwpPasangan;
            selectedItem.pisahHarta = data.pisahHarta;
            selectedItem.statusNikah = data.statusNikah;
            selectedItem.statusNikahStr =
              data.statusNikah == STATUSPERNIKAHAN_BELUM_MENIKAH_INT
                ? "Belum Menikah"
                : data.statusNikahStr == STATUSPERNIKAHAN_CERAI_INT
                ? "Cerai"
                : STATUSPERNIKAHAN_MENIKAH_INT
                ? "Menikah"
                : null;
            selectedItem.tahun = data.tahun;
            setItem(selectedItem);
            setLoadingById(false);
          } catch (e) {
            alert(`${e}`);
            onRequestClose();
            setLoadingById(false);
            setLoadingAll(false);
            setReady(true);
          }
        }
        getDataKlu(selectedItem.kluNama);
        setItem(selectedItem);
      } else {
        getDataKlu("");
        // fetchDataKlu();
        setItem({
          npwpOpInt: "0",
          npwpOp: true,
          noKtp: "",
          memilikiNpwp: true,
          npwp: "",
          namaNpwp: "",
          noTelepon: "",
          email: "",
          alamat: "",
          kodepos: null,
          noEfin: "",
          kluId: null,
          klu: null,
          pegawai: false,
          pemilikUsaha: false,
          pekerjaBebas: false,
          pkp: false,
          tanggalPkp: null,
          jumlahTanggungan: "0",
          npwpPasangan: "",
          pisahHarta: false,
          statusNikah: 1,
          statusNikahStr: "Belum Menikah",
          tahun: new Date().getFullYear(),
          tanggalMulai: new Date(),
          kenaPphFinal: false,
          omzetSudahDihitung: 0,
        });
      }
      setReady(visible);
    }
    setLoading(true);
    initData();

    setLoading(false);
  }, [visible]);
  useEffect(() => {
    setLoadingAll(loading || loadingById || loadingSubmit);
  }, [loading, loadingById, loadingSubmit]);

  useEffect(() => {
    setLoadingKlu(true);
    const timeOutId = setTimeout(() => getDataKlu(queryKlu), 500);
    return () => clearTimeout(timeOutId);
  }, [queryKlu]);

  const getKodepos = async (kodepos) => {
    try {
      let res = await AreaService.getByKodePos(kodepos);

      let { data } = res;

      return data;
    } catch (e) {
      return e.message;
    }
  };

  const getDataKlu = async (queryNama) => {
    try {
      setLoadingKlu(true);
      let klu = await service.getKlu(queryNama);
      var klsAsset = [];
      klu.data.map((d) => {
        klsAsset.push({
          label: `${d.kode} - ${d.nama}`,
          value: d.id,
        });
      });
      setDataKlu(klsAsset);
      setListKlu(klu.data);
      setLoadingKlu(false);
    } catch (error) {
      toast.errorRequest(error);
      setLoadingKlu(false);
    }
  };

  const KonfirmasiTanggalMulai = async (dataSubmit) => {
    modalStore.showConfirm({
      title: `Perhatian !`,
      children: (
        <TextContainer>
          <Text margin="none">
            Apakah Tanggal Mulai Pencatatan pada{" "}
            {moment(dataSubmit.dataAwal.tanggalMulai)
              .locale("id")
              .format("DD MMMM YYYY")}{" "}
            sudah sesuai?
          </Text>
          <Text
            type="caption"
            color="theme-warning"
            margin="none"
            component="p"
          >
            Catatan : Tanggal Mulai Pencatatan ini tidak bisa diubah kembali.
          </Text>
        </TextContainer>
      ),
      onSubmit: async (callback) => {
        try {
          setLoadingSubmit(true);
          await service.create(dataSubmit);
          toast.success("berhasil");
          callback();
          onRequestClose();
          setLoadingSubmit(false);
        } catch (err) {
          toast.errorRequest(err);
          callback();
          setLoadingSubmit(false);
        }
      },
    });
  };
  const onSubmit = async () => {
    let reqData = dataSubmit;
    reqData.npwpHead.npwpOp = item.npwpOpInt == "0" ? true : false;
    reqData.npwpHead.namaNpwp = item.namaNpwp;
    reqData.npwpHead.npwp = item.memilikiNpwp ? item.npwp : "000000000000000";
    reqData.npwpHead.alamat = item.alamat;
    reqData.npwpHead.area.id = item.areaId;
    reqData.npwpHead.area.jenis = item.areaJenis;
    reqData.npwpHead.area.kabupaten = item.areaKabupaten;
    reqData.npwpHead.area.kecamatan = item.areaKecamatan;
    reqData.npwpHead.area.kelurahan = item.areaKelurahan;
    reqData.npwpHead.area.kodepos = item.areaKodepos;
    reqData.npwpHead.area.propinsi = item.areaPropinsi;
    reqData.npwpHead.email = item.email;
    reqData.npwpHead.pengurusanEfin = item.noEfin
      ? PENGURUSANEFIN_SUDAH_MEMILIKI_EFIN
      : PENGURUSANEFIN_EFIN_DIURUS_SENDIRI;
    reqData.npwpHead.noEfin = "0000000000";
    reqData.npwpHead.klu.id = item.klu;
    reqData.npwpHead.noKtp = item.npwpOpInt == "0" ? item.noKtp : "";
    reqData.npwpHead.noTelepon = item.noTelepon;
    reqData.npwpHead.noTeleponB = "";
    reqData.npwpHead.pkp = item.pkp;
    reqData.dataAwal.tanggalMulai = moment(new Date(item.tanggalMulai)).format(
      "yyyy-MM-DD hh:mm:ss"
    );
    reqData.dataAwal.kenaPphFinal = item.kenaPphFinal
      ? item.kenaPphFinal
      : false;
    reqData.dataAwal.omzetSudahDihitung = item.omzetSudahDihitung;
    if (item.tanggalPkp) {
      reqData.npwpHead.tanggalPkp = moment(new Date(item.tanggalPkp)).format(
        "YYYY-MM-DD hh:mm:ss"
      );
    }
    reqData.jumlahTanggungan = item.jumlahTanggungan;
    reqData.npwpPasangan = item.npwpPasangan;
    reqData.pegawai = item.pegawai;
    reqData.pekerjaBebas = item.pekerjaBebas;
    reqData.pemilikUsaha = item.pemilikUsaha;
    reqData.pisahHarta = item.pisahHarta;
    reqData.statusNikah =
      item.statusNikahStr == "Belum Menikah"
        ? STATUSPERNIKAHAN_BELUM_MENIKAH_INT
        : item.statusNikahStr == "Cerai"
        ? STATUSPERNIKAHAN_CERAI_INT
        : STATUSPERNIKAHAN_MENIKAH_INT;
    reqData.tahun = moment(new Date(item.tanggalMulai)).year();
    setDataSubmit(reqData);
    if (item.memilikiNpwp && dataSubmit.npwpHead.npwp == "") {
      toast.error("Npwp Tidak Boleh Kosong");
    } else if (dataSubmit.npwpHead.namaNpwp == "") {
      toast.error("Nama Npwp Tidak Boleh Kosong");
    } else if (dataSubmit.npwpHead.noTelepon == "") {
      toast.error("No Telepon Tidak Boleh Kosong");
    } else if (dataSubmit.npwpHead.email == "") {
      toast.error("Email Tidak Boleh Kosong");
    } else if (dataSubmit.npwpHead.alamat == "") {
      toast.error("alamat Tidak Boleh Kosong");
    } else if (dataSubmit.npwpHead.area.kodepos == null) {
      toast.error("Kode Pos Tidak Boleh Kosong");
    } else if (dataSubmit.npwpHead.klu.id == null) {
      toast.error("Klu Tidak Boleh Kosong");
    } else if (
      dataSubmit.pegawai == false &&
      dataSubmit.pekerjaBebas == false &&
      dataSubmit.pemilikUsaha == false
    ) {
      toast.error("harus Memilih Role");
    } else {
      if (dataSubmit.id) {
        try {
          setLoadingSubmit(true);
          await service.update(dataSubmit);

          toast.success("berhasil");
          onRequestClose();
          setLoadingSubmit(false);
        } catch (err) {
          toast.errorRequest(err);
          setLoadingSubmit(false);
        }
      } else {
        KonfirmasiTanggalMulai(dataSubmit);
      }
    }
  };

  const closeForm = () => {
    setEditRole(true);
    setNikah(true);
    setIsMenikah(false);
    setPkp(true);
    setDataSubmit(defaultDataAkunNpwpSubmit());
    onRequestClose();
  };

  return (
    ready && (
      <>
        <Modal
          title={t.translate(`modules.akunNpwp.form`)}
          onRequestClose={onRequestClose}
          visible={visible}
          children={
            <FormWrapper
              loading={loadingAll}
              baseId="akun-npwp-form"
              defaultData={item}
              showCommandbar={false}
              definitions={[
                {
                  render: (
                    <div>
                      <p style={{ marginLeft: "10px" }}>
                        <b>{t.translate(`words.opOrBadan`)}</b>
                      </p>
                      <br />
                    </div>
                  ),
                },
                {
                  inputType: inputTypes.RADIO,
                  label: t.translate(`words.op`),
                  key: "npwpOpInt",
                  name: "npwpOpInt",
                  value: "0",
                  style: {
                    width: "100%",
                  },
                  disabled: isDisabled || !isOp || props.itemId,
                },
                {
                  inputType: inputTypes.RADIO,
                  label: t.translate(`words.badan`),
                  key: "npwpOpInt",
                  name: "npwpOpInt",
                  width: "100%",
                  value: "1",
                  style: {
                    width: "50%",
                  },
                  disabled: isDisabled || !isOp || props.itemId,
                },
                {
                  inputType: inputTypes.INPUT_MASK_NUMBER,
                  label: t.translate(`words.identityNik`),
                  key: "noKtp",
                  type: "text",
                  mask: "################",
                  maskChar: "_",
                  maskValue: "string",
                  width: "100%",
                  disabled: isDisabled || disabledNik || props.itemId,
                  show: (d) => {
                    return d.npwpOpInt == "0";
                  },
                },
                {
                  inputType: inputTypes.CHECKBOX,
                  label: `Apakah Anda Memiliki NPWP?`,
                  key: "memilikiNpwp",
                  name: "Apakah Anda Memiliki NPWP?",
                  disabled: (item)=>item.npwpOpInt == "0" ?false : true || isDisabled,
                },
                {
                  inputType: inputTypes.INPUT_MASK_NUMBER,
                  label: t.translate(`words.identityNpwp`),
                  key: "npwp",
                  type: "text",
                  mask: "##.###.###.#-###.###",
                  maskChar: "_",
                  maskValue: "string",
                  width: "100%",
                  validation: "required",
                  disabled: (item)=> !item.memilikiNpwp || isDisabled || props.itemId ,
                },
                {
                  inputType: inputTypes.INPUT,
                  label: `Nama`,
                  key: "namaNpwp",
                  type: "text",
                  validation: "required",
                  disabled:isDisabled || props.itemId,
                },
                {
                  inputType: inputTypes.INPUT_MASK_NUMBER,
                  label: t.translate("words.phone"),
                  key: "noTelepon",
                  type: "text",
                  isNumericString: true,
                  validation: "required",
                  disabled: isDisabled,
                },
                {
                  inputType: inputTypes.INPUT,
                  label: t.translate("words.email"),
                  key: "email",
                  type: "text",
                  validation: "required",
                  disabled: isDisabled,
                },
                {
                  inputType: inputTypes.INPUT,
                  label: t.translate("words.address"),
                  key: "alamat",
                  type: "text",
                  validation: "required",
                  disabled: isDisabled,
                },
                {
                  inputType: inputTypes.INPUT_MASK_NUMBER,
                  label: t.translate(`words.kodepos`),
                  key: "kodepos",
                  type: "text",
                  mask: "#####",
                  maskChar: "_",
                  maskValue: "string",
                  width: "100%",
                  validation: "required",
                  disabled: isDisabled,
                },
                {
                  inputType: DataForm.inputTypes.REACT_SELECT,
                  isClearable: true,
                  label: t.translate(`words.kodeKlasifikasiLapanganUsaha`),
                  key: "klu",
                  tooltip:
                    "Jika Kode KLU tidak ditemukan,<br/>silahkan ketik nama KLU yang ingin<br/>dicari pada kolom KLU.",
                  validation: props.itemId && "required",
                  labelKey: "label",
                  valueKey: "value",
                  options: dataKlu,
                  isDisabled: isDisabled,
                  isLoading: loadingKlu,
                  onInputChange: (value) => {
                    if (value) setQueryKlu(value);
                  },
                },
                {
                  render: (
                    <div>
                      <p style={{ marginLeft: "10px", marginBottom: "-15px" }}>
                        <b>Jenis Pekerjaan</b>
                      </p>
                      <br />
                    </div>
                  ),
                },
                {
                  inputType: inputTypes.CHECKBOX,
                  label: t.translate(`words.pegawai`),
                  key: "pegawai",
                  name: "pegawai",
                  style: {
                    width: "100%",
                    marginBottom: "-5px",
                  },
                  disabled: isDisabled,
                  show: editRole,
                },
                {
                  inputType: inputTypes.CHECKBOX,
                  label: t.translate(`words.pekerjaBebas`),
                  key: "pekerjaBebas",
                  name: "pekerjaBebas",
                  style: {
                    width: "100%",
                    marginBottom: "-5px",
                  },
                  disabled: isDisabled,
                  show: editRole,
                },
                {
                  inputType: inputTypes.CHECKBOX,
                  label: t.translate(`words.pemilikUsaha`),
                  key: "pemilikUsaha",
                  name: "pemilikUsaha",
                  style: {
                    width: "100%",
                  },
                  disabled: isDisabled,
                },
                {
                  inputType: inputTypes.DATEPICKER,
                  label: t.translate(`words.tanggalMulaiPencatatan`),
                  key: "tanggalMulai",
                  width: "100%",
                  type: "date",
                  validation: "required",
                  show: props.itemId == null,
                },
                {
                  inputType: inputTypes.INPUT_MASK_NUMBER,
                  label: "Omzet Diluar Aplikasi",
                  key: "omzetSudahDihitung",
                  type: "text",
                  thousandSeparator: ".",
                  decimalSeparator: ",",
                  isNumericString: true,
                  width: "100%",
                  required: true,
                  disabled: isDisabled,
                },
                // {
                //   inputType: inputTypes.CHECKBOX,
                //   label: t.translate(`words.terdaftarSebagaiPkp`),
                //   key: "pkp",
                //   name: "pkp",
                //   style: {
                //     width: "100%",
                //   },
                //   disabled: isDisabled,
                // },
                // {
                //   inputType: inputTypes.DATEPICKER,
                //   label: t.translate(`words.tanggalDitetapkanPkp`),
                //   key: "tanggalPkp",
                //   width: "100%",
                //   type: "date",
                //   show: disabledTanggalPkp,
                //   disabled: isDisabled || pkp,
                // },
              ]}
              onChange={async (formData, key, value) => {
                if (key === "npwpOpInt") {
                  if (value === "0") {
                    setDisabledNik(false);
                    setNikah(true);
                    setEditRole(true);
                   
                  } else {
                    setDisabledNik(true);
                    setNikah(false);
                    setIsMenikah(false);
                    setEditRole(false);
                    formData.memilikiNpwp = true
                   }
                }
                if (key == "memilikiNpwp") {
                  setIsPunyaNpwp(value);
                }
                if (key == "kodepos") {
                  if (value.length === 5) {
                    var res = await getKodepos(value);
                    if (typeof res === "object") {
                      formData.area = res.kodepos;
                      formData.areaId = res.id;
                      formData.areaJenis = res.jenis;
                      formData.areaKabupaten = res.kabupaten;
                      formData.areaKecamatan = res.kecamatan;
                      formData.areaKelurahan = res.kelurahan;
                      formData.areaKodepos = res.kodepos;
                      formData.areaPropinsi = res.propinsi;
                    } else {
                      alert(res);
                      formData.area = null;
                      formData.areaId = null;
                      formData.areaJenis = null;
                      formData.areaKabupaten = null;
                      formData.areaKecamatan = null;
                      formData.areaKelurahan = null;
                      formData.areaKodepos = null;
                      formData.areaPropinsi = null;
                    }
                  }
                }

                if (key == "noTelepon") {
                  formData.noTelepon = "0" + value;
                }
                if (key == "pkp") {
                  if (value == "0") {
                    setDisabledTanggalPkp(false);
                    setPkp(false);
                  } else {
                    setDisabledTanggalPkp(true);
                    setPkp(false);
                  }
                }
                if (key == "statusNikahStr") {
                  if (value == "Menikah") setIsMenikah(true);
                  else setIsMenikah(false);
                }
                if (key == "pisahHarta") {
                  setIsPisahHarta(value);
                }

                if (key == "tanggalMulai") {
                  formData.tanggalMulai = value;
                }
                if (key == "klu") {
                }

                setItem(formData);
                return formData;
              }}
            />
          }
          footer={
            <>
              <Button onClick={onRequestClose}>Kembali</Button>
              {!isDisabled && (
                <Button
                  onClick={onSubmit}
                  theme="primary"
                  themeType="contained"
                >
                  Simpan
                </Button>
              )}
            </>
          }
        />
        <Modal
          disableFocusOnMount={false}
          visible={loadingAll}
          children={<LoaderInfo children={"Loading . . . "} />}
        />
      </>
    )
  );
};

export default inject(
  "envStore",
  "modalStore",
  "navigationStore"
)(observer(AkunNpwpForm));
