import React, { useState } from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import service from './Marketplace.service'
import MarketForm from './MarketForm'
import { inject, observer } from 'mobx-react'

const Marketplace = ({
  className = '',
  showCommandbar = true,
  match,
  navigationStore,
}) => {
  var npwpHeadId = match.params.npwpHeadId
  const [showForm, setShowForm] = useState(false)
  let [search, setSearch] = useState({
  })
  const [selectedItemId, setSelectedItemId] = useState(null)
  const baseId = 'marketplace-list'



  var actions = [
    new TableWrapper.action(
      `${t.translate('words.add')}`,
      'mdi mdi-plus',
      () => {
        setSelectedItemId(null)
        setShowForm(true)
      },
      true)
  ]

  var itemActions = [
    new TableWrapper.action(
      'Detail',
      'mdi mdi-import',
      (item) => {
        navigationStore.redirectTo(
          `/internal/npwp/${npwpHeadId}/marketplace/${item.id}`
      )
      },
      true
    ),
  ]

  return (
    <>
     <TableWrapper
                  baseId={baseId}
                  title={'Marketplace'}
                  className={className}
                  defaultData={[]}
                  defaultSortBy="tanggalDibuat"
                  showFilter={false}
                  useFilter={true}
                  onFetchData={(query) => {
                    return (
                      new Promise(async (resolve, reject) => {
                        try {
                          query = {
                            ...query
                          }
                          let getPage = await service.getUser(query)
                          getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
                          resolve(getPage)
                        } catch (e) {
                          resolve()
                          // ErrorService(e)
                        }
                      })
                    )
                  }}
                  columns={[
                    {
                      label: 'Nama',
                      searchable: true,
                      sortable: false,
                      key: 'nama',
                      render: item => (item.nama)
                    },
                    {
                      label: 'Kode',
                      searchable: true,
                      sortable: false,
                      key: 'kode',
                      render: item => (item.kode)
                    },
                  ]}
                  actions={actions}
                  showCommandbar={showCommandbar}
                  itemActions={itemActions}
                />
      <MarketForm
        visible={showForm}
        onRequestClose={() => {
          setShowForm(false)
          setSelectedItemId(null)
          document.getElementsByClassName('mdi-reload')[0].click()
        }}
        itemId={selectedItemId}
      />
    </>
  )
}

export default inject('modalStore', 'navigationStore')(observer(Marketplace))
