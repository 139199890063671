import { crud, http } from "../../../libs/react-mpk/services";

let service = null;
let serviceUrl = "/laporan";
let tahunSobatPajak = sessionStorage.getItem("tahunSobatBuku");
let npwpHeadId = sessionStorage.getItem("npwpHeadId");
let npwpHeadIdObject = {
  npwpHeadId: sessionStorage.getItem("npwpHeadId"),
};
// let kateogirHartaTetap = {
//     kategori : 1
// };
let KATEGORI_HARTA_LANCAR_TETAP = 1;
let KATEGORI_UTANG_MODAL = 2;
service = new crud(`/laporan`);

service.getAllHartaLancarTetap = async (query) => {
  query = {
    npwpHeadId: npwpHeadId,
    kategori: KATEGORI_HARTA_LANCAR_TETAP,
    ...query,
  };
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/neraca`,
    query,
  });
};
service.getAllUtangModal = async (query) => {
  query = {
    npwpHeadId: npwpHeadId,
    kategori: KATEGORI_UTANG_MODAL,
    ...query,
  };
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/neraca`,
    query,
  });
};

service.getDetailHarta = async (query) => {
  query = {
    npwpHeadId: npwpHeadId,
    ...query,
  };
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/neraca/akun`,
    query,
  });
};

service.getListTransaksiNeraca = async (query) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/neraca/rekap_akun`,
    query,
  });
};

service.getDetailListTransaksi = async (query) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/neraca/transaksi`,
    query,
  });
};

service.DownloadLaporanNeraca = async (kategori,npwpHeadId,tahun) => {
  return http.download(`${serviceUrl}/neraca/download_report_neraca?kategori=${kategori}&npwpHeadId=${npwpHeadId}&tahun=${tahun}`);
};
export default service;
