import React, { useEffect, useState } from 'react'
import { TableWrapper, TabsWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import moment from 'moment'
import { DataForm } from '../../libs/react-mpk/components';
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import { format } from './../../libs/react-mpk/services/number.service'
import Modal from '../../libs/react-mpk/components/Modal'
import { inject, observer } from 'mobx-react';
import { useParams } from 'react-router';
import service from './Enofa.service';

const EnofaDetail = ({
    className = '',
    envStore,
    navigationStore,
    showCommandbar = true
}) => {
    const { inputTypes, definition } = FormWrapper
    const [showForm, setShowForm] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)
    const [inputValidasiNpwp, setInputValidasiNpwp] = useState('##.###.###.#-###.###')
    const [dialogNilaiTagihan, setDialogNilaiTagihan] = useState(false)
    const [dialogData, setDialogData] = useState(false)
    const [dokumens, setDokumens] = useState([])
    const { id } = useParams()
    const [data, setData] = useState([])
    const [dataTagihan, setDataTagihan] = useState([])

    const basePath = `lawan-transaksi`

    const npwpHeadId = sessionStorage.getItem('npwpHead')

    function showDialogNilaiTagihan() {
        setDialogNilaiTagihan(true)
        setDialogData({})
    }

    const fetchData = async () => {
        let res = await service.getById(id)
        setData(res.data)
        // console.log(res.data);
    }
    useEffect(() => {
        fetchData()
    }, [])


    var actions = [
        // new TableWrapper.action(
        //   `${t.translate('words.add')}`,
        //   'mdi mdi-plus',
        //   () => {
        //     setSelectedItem(null)
        //     setShowForm(true)
        //   },
        //   true)
    ]

    return (
        <>
            <FormWrapper
                actions={[
                    {
                        label: 'Reload',
                        iconClassName: 'mdi mdi-reload'
                    }
                ]}
                backTo={`/${envStore.env.applicationType}/npwp/${npwpHeadId}/enofa`}
                baseId="mod-form-sample"
                title='Enofa Detail'
                hintMessage='Detail Data Enofa'
                hintShowIcon={false}
                defaultData={data}
                definitions={[
                    {
                        inputType: inputTypes.INPUT,
                        label: 'NSFP Dari',
                        key: 'nsfpDari',
                        disabled: true
                    },
                    {
                        inputType: inputTypes.INPUT,
                        label: 'NSFP Sampai',
                        key: 'nsfpSampai',
                        disabled: true
                    },
                    {
                        inputType: inputTypes.INPUT,
                        label: 'NSFP Sekarang',
                        key: 'nsfpSekarang',
                        disabled: true
                    },
                    {
                        inputType: inputTypes.INPUT_MASK_NUMBER,
                        label: 'NSFP Sisa',
                        key: 'nsfpSisa',
                        disabled: true
                    },
                    {
                        inputType: inputTypes.INPUT_MASK_NUMBER,
                        label: 'NSFP Terpakai',
                        key: 'nsfpTerpakai',
                        disabled: true
                    }
                ]}
                onChange={(data, tab, callback) => {
                    // console.log(data);
                    // if (data.kategori === 2) {
                    //   data.kategori = 'Supplier'
                    // } else {
                    //   data.kategori = 'Customer'
                    // }
                }}
            // submitLabel='Back'
            // onSubmit={(data, callback) => { window.location.href = `/${envStore.env.applicationType}/npwp/${data.npwpHead.id}/lawan-transaksi` }}
            />
        </>
    )
}

export default inject('envStore', 'navigationStore')(observer(EnofaDetail))