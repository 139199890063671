import React, { useEffect, useState } from 'react'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { sum } from 'lodash'
import { FormWrapper, TableWrapper } from '../../../libs/react-mpk/wrapper'
import FormTanggal from '../FormTanggal'
import { Table, TableBody, TableHeader, TableRow, TableCell, Button, FontIcon, TableFooter } from 'react-md'
import service from './ReportNeracaSaldo.service'
import { format, formatRupiah } from '../../../libs/react-mpk/services/number.service'
import { useHistory, useLocation } from 'react-router-dom'

const ReportNeracaSaldo = ({
    navigationStore,
    showCommandbar = true,
    match
}) => {
    const [data, setData] = useState([])
    const [load, setload] = useState(true)
    let history = useHistory()
    const { state: { tahun } = {} } = useLocation();
    let tahunSobatPajak = sessionStorage.getItem('tahunSobatBuku')
    let npwpHeadId = sessionStorage.getItem('npwpHeadId')

    const fetchData = async () => {
        let query = {
            "tahun": tahun?tahun: sessionStorage.getItem('tahunSobatBuku')
        }
        try {
            let res = await service.getAllNeracaSaldo(query)
            setData(res.data.list)
            setload(false);
        } catch (error) {
        }
    }
    useEffect(() => {
        fetchData()
    }, [])
    const datas = match?.url
    const datass = datas?.includes("report-neraca-saldo")
    let totalDebit = 0;
    let totalKredit = 0;
    return (
        <>
            <FormWrapper
                loading={load}
                title={t.translate(`words.tableNeracaSaldo`)}
                baseId="mod-table-laporan-neraca-saldo"
                style={{
                    maxWidth: '100%'
                }}
                // backTo={`/internal/npwp/${npwpHeadId}/tutup-buku`}
                showCommandbar={datass==true?true:false}
                definitions={[
                    // {
                    //     render:(
                    //         <div style={{width:'100%'}}>
                    //             <FormTanggal/>
                    //         </div>
                    //     )
                    // },
                    {
                        render: (
                            <div style={{ width: '100%' }}>
                                <Table style={{ width: '100%' }}>
                                    <TableHeader>
                                        <TableRow>
                                            <TableCell style={{ width: '10%' }}>{t.translate('words.non')}</TableCell>
                                            <TableCell style={{ width: '10%' }}>{t.translate('words.aksi')}</TableCell>
                                            <TableCell style={{ width: '45%' }}>{t.translate('words.kategoriAkun')}</TableCell>
                                            <TableCell style={{ width: '45%' }}>{t.translate('words.debit')}</TableCell>
                                            <TableCell style={{ width: '45%' }}>{t.translate('words.kredit')}</TableCell>
                                            {/* <TableCell style={{ width: '45%' }}>{t.translate('words.saldoAkhir')}</TableCell> */}
                                        </TableRow>
                                    </TableHeader>

                                    <TableBody>
                                        {data.map((d, index) => {
                                            totalDebit += d.saldoAkhir>0?d.saldoAkhir:0;
                                            totalKredit += d.saldoAkhir<0?d.saldoAkhir:0;
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell>{index + 1}</TableCell>
                                                    <TableCell>
                                                        <Button
                                                            theme="primary"
                                                            // themeType="contained"
                                                            // style={{ margin: 15 }}
                                                            onClick={() =>{
                                                                history.push({
                                                                    pathname: `/internal/npwp/${npwpHeadId}/report-neraca-saldo/transaksi/${d.akun.id}`,
                                                                    state: { tahun: tahun },
                                                                  });
                                                                // navigationStore.redirectTo(
                                                                //     `/internal/npwp/${npwpHeadId}/report-neraca-saldo/transaksi/${d.akun.id}`),
                                                                    sessionStorage.setItem('namaAkun', d.akun.nama)
                                                                    sessionStorage.setItem('kodeAkun', d.akun.kode)
                                                                    sessionStorage.setItem('saldoAwal', d.saldoAwal)
                                                                    sessionStorage.setItem('saldoAkhir', d.saldoAkhir)

                                                            }
                                                                
                                                                
                                                            }
                                                        >
                                                            <FontIcon iconClassName="mdi mdi-import"/>
                                                            {/* {t.translate("words.add")} */}
                                                        </Button>
                                                    </TableCell>
                                                    <TableCell>{d.akun.kode + ' - ' + d.akun.nama}</TableCell>
                                                    
                                                    <TableCell>{formatRupiah(Math.abs(d.saldoAkhir>0?d.saldoAkhir:0))}</TableCell>
                                                    <TableCell>{formatRupiah(Math.abs(d.saldoAkhir<0?d.saldoAkhir:0))}</TableCell>
                                                    {/* <TableCell>{formatRupiah(Math.abs(d.saldoAkhir))}</TableCell> */}
                                                    {/* <TableCell>{formatRupiah(d.saldoAkhir)}</TableCell> */}

                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TableCell><b>{t.translate(`words.total`)}</b></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell><b>{formatRupiah(Math.abs(totalDebit))}</b></TableCell>
                                            <TableCell><b>{formatRupiah(Math.abs(totalKredit))}</b></TableCell>
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </div>
                        )
                    },
                ]}
                onChangeQuery={async (formData, key, value) => {
                    if (formData === "total") {
                        formData.total = sum(formData.nominal)
                    }
                }}
                itemActions={[]}
                showFilterPeriod={true}
                useCriteria={false}
            />
        </>
    )
}

export default inject("navigationStore", 'envStore')(observer(ReportNeracaSaldo))