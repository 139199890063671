import React, { useEffect, useState } from "react";
import { DataForm, Hint } from "../../libs/react-mpk/components";
import t from "counterpart";
import { FormWrapper } from "../../libs/react-mpk/wrapper";
import AkunCoaService from "../AkunCoa/AkunCoa.service";

import { toast } from "../../libs/react-mpk/services";

const defaultData = () => ({
  kategori: "1",
  kategoriInt: "1",
  nominal: "",
  akun: {
    id: null,
    kode: "",
    nama: "",
  },
});

const JurnalUmumFormDebitKreditForm = ({
  visible = false,
  authStore,
  envStore,
  match,
  history,
  onRequestClose = () => {},
  ...props
}) => {
  const { inputTypes, definition } = FormWrapper;
  let [item, setItem] = useState(null);
  let [ready, setReady] = useState(true);
  var thousandSeparator = ".";
  var decimalSeparator = ",";
  const [akunList, setAkunList] = useState([]);
  let [queryAkun, setQueryAkun] = useState("");
  const [loadingAkun, setLoadingAkun] = useState(false);

  useEffect(() => {
    initData();
  }, [visible]);

  async function initData() {
    if (visible) {
      let selectedItem = defaultData();
      setItem(selectedItem);
    } else {
      setItem(defaultData());
    }
    setReady(visible);
  }
  useEffect(() => {
    setLoadingAkun(true);
    const timeOutId = setTimeout(() => getAkun(queryAkun), 500);
    return () => clearTimeout(timeOutId);
  }, [queryAkun]);
  async function getAkun(queryNama) {
	try {
		setLoadingAkun(true);
      let res = await AkunCoaService.getAkun(queryNama);
      var akuns = [];
      res.data.map((d) => {
        akuns.push({
          value: d.id,
          label: `${d.kode} - ${d.nama}`,
          kode: d.kode,
          nama: d.nama,
        });
      });
      setAkunList(akuns);
	  setLoadingAkun(false);
	} catch (error) {
		toast.errorRequest(error);
		setLoadingAkun(false);
	  }

  }

  return (
    ready && (
      <DataForm
        baseId="jurnal-umum-form-debit-kredi-form"
        title={"Pilih Akun Form"}
        visible={visible}
        onRequestClose={onRequestClose}
        asDialog={true}
        defaultData={item}
        definitions={[
          {
            inputType: DataForm.inputTypes.REACT_SELECT,
            isClearable:true,
            label: t.translate(`words.akun`),
            key: "akunId",
            validation: "required",
            labelKey: "label",
			valueKey: "value",
			options: akunList,
			isLoading: loadingAkun,
			onInputChange: (value) => {
			  if (value) setQueryAkun(value);
			},
          },
          {
            render: (
              <div>
                <p style={{ marginLeft: "10px", marginBottom: "-15px" }}>
                  <b>{t.translate(`words.posisi`)}</b>
                </p>
                <br />
              </div>
            ),
          },
          {
            inputType: inputTypes.RADIO,
            label: t.translate(`words.debit`),
            key: "kategoriInt",
            name: "kategoriInt",
            value: "1",
            style: {
              width: "100%",
            },
          },
          {
            inputType: inputTypes.RADIO,
            label: t.translate(`words.kredit`),
            key: "kategoriInt",
            name: "kategoriInt",
            value: "2",
            style: {
              width: "100%",
            },
          },
          {
            inputType: DataForm.inputTypes.INPUT_MASK_NUMBER,
            label: t.translate(`words.nominal`),
            thousandSeparator: thousandSeparator,
            decimalSeparator: decimalSeparator,
            key: "nominal",
            validation: "required",
          },
        ]}
        onChange={async (formData, key, value) => {
          if (key == "kategoriInt") {
            formData["kategori"] = parseInt(value);
          }

          if (key == "akunId") {
            akunList.map((d) => {
              if (d.value == value) {
                formData["akun"]["id"] = d.value;
                formData["akun"]["kode"] = d.kode;
                formData["akun"]["nama"] = d.nama;
              }
            });
          }
        }}
        // onBeforeChange={(key, value) => {
        // 	console.log(key, value)
        // 	return value
        // }}
        onSubmit={(data, callback) => {
          localStorage.setItem("debitKreditForm", JSON.stringify(data));
          callback("success", false);
        }}
      />
    )
  );
};

export default JurnalUmumFormDebitKreditForm;
