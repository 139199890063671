import { crud, http } from '../../../libs/react-mpk/services';

let service = null
let serviceUrl = '/migrasi/data_awal'
let tahunSobatPajak = sessionStorage.getItem('tahunSobatBuku');
let npwpHeadId = sessionStorage.getItem('npwpHeadId');
service = new crud('/migrasi/data_awal');

// service.getPage = async(query) => {
//   return http.request({
//     method: http.methods.GET,
//     url: `${serviceUrl}`,
//     query
//   })
// }

service.getDataAwal = async(tahun) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/npwp_head/${npwpHeadId}/${tahunSobatPajak}`
  })
}

service.createDataAwal = async(data) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}`,
    data,
    config: {
      params: {
        npwpHeadId: npwpHeadId
      }
    }
  })
}

service.updateDataAwal = async(id, data) => {
  return http.request({
    method: http.methods.PUT,
    url: `${serviceUrl}/${id}`,
    data,
    config: {
      params: {
        npwpHeadId: npwpHeadId
      }
    }
  })
}

export default service;