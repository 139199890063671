import React, { useState } from "react";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import service from "./Pesanan.service";
import moment from "moment";
import 'moment/locale/id';
import { format } from "./../../libs/react-mpk/services/number.service";
import { inject, observer } from "mobx-react";
import PesananInputResi from "./PesananInputResi";
import iziToast from "izitoast";
import { toast } from "../../libs/react-mpk/services";
import utilsService from "../../services/utilsService";

const Pesanan = ({
  className = "",
  showCommandbar = true,
  navigationStore,
  modalStore,
}) => {
  const baseId = "pesanan";
  const [selectedItem, setSelectedItem] = useState(null);
  const [showHistory, setShowHistory] = useState(false);
  let npwpHeadId = sessionStorage.getItem("npwpHeadId");
  const [data, setData] = useState([]);

  var actions = [
    new TableWrapper.action(
      `${t.translate("words.add")}`,
      "mdi mdi-plus",
      (item) => {
        navigationStore.redirectTo(`/internal/npwp/${npwpHeadId}/pesananForm`);
      },
      true
    ),
  ];

  //UPATE STATUS PESANAN
  async function updateStatusExc(item) {
    try {
      var r = await service.editStatusPesanan(item.id);
      r.data.status = 2;
      iziToast.success({ message: "Pesanan Diterima" });
      TableWrapper.reload(baseId);
    } catch (err) {
      toast.errorRequest(err);
    }
  }

  async function updateStatus(item) {
    modalStore.showConfirm({
      title: t.translate("words.terimaPesanan"),
      children: t.translate("words.confirmTerima"),
      onSubmit: (callback) => {
        updateStatusExc(item);
        callback();
      },
    });
  }

  //BATAL PESANAN
  async function batalkanPesanan(item) {
    try {
      var r = await service.editStatusPesananBatal(item.id);
      r.data.status = 4;
      iziToast.success({ message: "Pesanan Dibatalkan" });
      TableWrapper.reload(baseId);
    } catch (err) {
      toast.errorRequest(err);
    }
  }

  async function updateStatusBatal(item) {
    modalStore.showConfirm({
      title: t.translate("words.batalkan"),
      children: t.translate("words.confirmBatalkan"),
      onSubmit: (callback) => {
        batalkanPesanan(item);
        callback();
      },
    });
  }

  //PESANAN SELESAI
  async function pesananSelesai(item) {
    try {
      var r = await service.editStatusPesananSelesai(item.id);
      r.data.status = 3;
      iziToast.success({ message: "Pesanan Selesai" });
      TableWrapper.reload(baseId);
      document.getElementsByClassName("mdi-reload")[0].click();
    } catch (err) {
      toast.errorRequest(err);
    }
  }

  async function updateStatusSelesai(item) {
    modalStore.showConfirm({
      title: t.translate("words.selesai"),
      children: t.translate("words.confirmSelesai"),
      onSubmit: (callback) => {
        pesananSelesai(item);
        callback();
      },
    });
  }

  return (
    <>
      <TableWrapper
        baseId={baseId}
        title={t.translate("modules.pesanan.title")}
        className={className}
        hintMessage={t.translate("modules.pesanan.hint.message")}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate("modules.pesanan.hint.more")}
        showFilter={false}
        showActionColumn={true}
        useFilter={true}
        defaultCollapse={true}
        useCriteria={true}
        selectable={false}
        useQueryHistory={false}
        defaultData={[]}
        onFetchData={(query) => {
          return new Promise(async (resolve, reject) => {
            try {
              query = {
                ...query,
              };
              let getPage = await service.getAll(query);
              setData(getPage.data);
              getPage.headers["x-pagination-count"] =
                getPage.headers["x-total-count"];
              resolve(getPage);
            } catch (e) {
              resolve();
            }
          });
        }}
        columns={[
          {
            label: "Nomor Pesanan",
            searchable: true,
            sortable: false,
            key: "noPesanan",
            render: (item) => utilsService.beautifyNoTransaksi(item.noPesanan),
          },
          {
            label: "Nama",
            searchable: false,
            sortable: false,
            key: "namaLawanTransaksi",
            render: (item) => item.lawanTransaksi.nama,
          },
          {
            label: "Nominal",
            searchable: false,
            sortable: false,
            key: "nominal",
            render: (item) => "Rp. " + format(item.totalPesanan),
          },
          {
            label: "Tanggal Pesanan",
            type: TableWrapper.dataTypes.DATE,
            render: (item) => moment(item.tanggalPesan).locale('id').format("DD MMMM YYYY"),
          },
          {
            label: "Status Pesanan",
            type: TableWrapper.dataTypes.BOOLEAN,
            searchable: true,
            sortable: false,
            key: "status",
            definition: {
              inputType: TableWrapper.inputTypes.SELECT,
              criterias: ["in"],
              options: [
                { value: "1", label: "Belum Diproses" },
                { value: "2", label: "Diproses" },
                { value: "3", label: "Selesai" },
                { value: "4", label: "Batal" },
              ],
            },
            render: (item) => (
              <TableWrapper.Status
                type={
                  item.status.status == "1"
                    ? TableWrapper.Status.types.IDLE
                    : item.status.status == "2"
                    ? TableWrapper.Status.types.PROGRESS
                    : item.status.status == "3"
                    ? TableWrapper.Status.types.SUCCESS
                    : item.status.status == "4"
                    ? TableWrapper.Status.types.ERROR
                    : ""
                }
              >
                {item.status.status == "1"
                  ? "Belum Diproses"
                  : item.status.status == "2"
                  ? "Diproses"
                  : item.status.status == "3"
                  ? "Selesai"
                  : item.status.status == "4"
                  ? "Batal"
                  : ""}
              </TableWrapper.Status>
            ),
          },
          {
            label: "Status Pengiriman",
            searchable: false,
            sortable: false,
            key: "statusPengiriman",
            render: (item) => (
              <TableWrapper.Status
                type={
                  item.pengiriman.status == "1"
                    ? TableWrapper.Status.types.IDLE
                    : item.pengiriman.status == "2"
                    ? TableWrapper.Status.types.PROGRESS
                    : item.pengiriman.status == "3"
                    ? TableWrapper.Status.types.SUCCESS
                    : item.pengiriman.status == "4"
                    ? TableWrapper.Status.types.ERROR
                    : ""
                }
              >
                {item.pengiriman.status == "1"
                  ? "Belum Diproses"
                  : item.pengiriman.status == "2"
                  ? "Diproses"
                  : item.pengiriman.status == "3"
                  ? "Selesai"
                  : item.pengiriman.status == "4"
                  ? "Batal"
                  : ""}
              </TableWrapper.Status>
            ),
          },
          {
            label: "Kurir",
            searchable: false,
            sortable: false,
            key: "kurir",
            render: (item) => item.kurir.nama,
          },

          {
            label: "Tanggal Dibuat",
            type: TableWrapper.dataTypes.DATE,
            render: (item) =>
              moment(item.tanggalDibuat).locale('id').format("DD MMMM YYYY, HH:mm:ss"),
          },
        ]}
        actions={actions}
        itemActions={[
          new TableWrapper.action(
            `${t.translate("words.selengkapnya")}`,
            "mdi mdi-import",
            (item) => {
              navigationStore.redirectTo(
                `/internal/npwp/${npwpHeadId}/pesanan/${item.id}/produk/${null}`
              );
            },
            true
          ),
          new TableWrapper.action(
            `${t.translate("words.history")}`,
            "mdi mdi-history",
            (item) => {
              navigationStore.redirectTo(
                `/internal/npwp/${npwpHeadId}/pesanan/history/${item.id}`
              );
            },
            true
          ),
          new TableWrapper.action(
            `${t.translate("words.terimaPesanan")}`,
            "mdi mdi-import",
            (item) => {
              updateStatus(item);
            },
            (items) => {
              if (items.status.status == 1) {
                return true;
              } else {
                return false;
              }
            }
          ),
          new TableWrapper.action(
            `${t.translate("words.inputResi")}`,
            "mdi mdi-email-send-outline",
            (item) => {
              setSelectedItem(item);
              setShowHistory(true);
            },
            (items) => {
              if (items.status.status == 2 && items.pengiriman.status == 1) {
                return true;
              } else {
                return false;
              }
            }
          ),
          new TableWrapper.action(
            `${t.translate("words.selesaiKirim")}`,
            "mdi mdi-file-upload",
            (item) => {
              updateStatusSelesai(item);
            },
            (items) => {
              if (items.status.status == 2 && items.pengiriman.status == 2) {
                return true;
              } else {
                return false;
              }
            }
          ),
          new TableWrapper.action(
            `${t.translate("words.batalKirim")}`,
            "mdi mdi-close",
            (item) => {
              updateStatusBatal(item);
            },
            (items) => {
              if (
                (items.status.status == 2 && items.pengiriman.status == 2) ||
                (items.status.status == 1 && items.pengiriman.status == 1) ||
                (items.status.status == 2 && items.pengiriman.status == 1)
              ) {
                return true;
              } else {
                return false;
              }
            }
          ),
          new TableWrapper.action(
            `${t.translate("words.generateInvoice")}`,
            "mdi mdi-file",
            (item) => {
              if (item.npwpHead.pkp) {
                navigationStore.redirectTo(
                  `/internal/npwp/${npwpHeadId}/penjualan/new/ppn/${item.id}`
                );
              } else {
                navigationStore.redirectTo(
                  `/internal/npwp/${npwpHeadId}/penjualan/new/non-ppn/${item.id}`
                );
              }
            },
            (items) => {
              if (items.status.status == 3 && items.pengiriman.status == 3) {
                return true;
              } else {
                return false;
              }
            }
          ),
        ]}
        showCommandbar={showCommandbar}
        showFilterPeriod={false}
      />
      <PesananInputResi
        visible={showHistory}
        onRequestClose={() => {
          setShowHistory(false);
          setSelectedItem(null);
        }}
        item={selectedItem}
      />
    </>
  );
};

export default inject("navigationStore", "modalStore")(observer(Pesanan));
