import React, { useState } from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import service from './Enofa.service'
import moment from 'moment'
import EnofaForm from './EnofaForm'
import { inject, observer } from 'mobx-react'
import iziToast from 'izitoast'

const Enofa = ({
  className = '',
  modalStore,
  showCommandbar = true,
  match
}) => {
  const [showForm, setShowForm] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [data, setData] = useState([])
  var npwpHeadId = match.params.npwpHeadId
  async function hapus(item) {
    modalStore.showConfirm({
      title: 'Delete',
      children: 'Apakah yakin?',
      onSubmit: async (callback) => {
        try {
          await service.delete(item.id)
          TableWrapper.reload('enofa-table')
          iziToast.success({ message: 'Success' })
          callback()
        } catch (error) {
          iziToast.error({ message: error })
          callback()
        }
      }
    })
  }

  var actions = [
    new TableWrapper.action(
      `${t.translate('words.add')}`,
      'mdi mdi-plus',
      () => {
        setSelectedItem(null)
        setShowForm(true)
      },
      true)
  ]

  return (
    <>
      <TableWrapper
        baseId="efona-table"
        title={t.translate('modules.enofa.title')}
        className={className}
        hintMessage={t.translate('modules.enofa.hint.message')}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate('modules.enofa.hint.more')}
        defaultData={[]}
        defaultSortBy="tanggalDibuat"
        defaultCollapse={true}
        useCriteria={true}
        selectable={false}
        useQueryHistory={false}
        // extraFilter={<div>Test</div>}
        columns={[
          {
            label: 'Dari',
            searchable: true,
            sortable: false,
            key: 'nsfpDari',
            render: item => (
              <TableWrapper.Link
              to={`/internal/npwp/${npwpHeadId}/enofa/${item.id}`}
              >
                {item.nsfpDari}
              </TableWrapper.Link>
            )
          },
          {
            label: 'Sampai',
            searchable: true,
            sortable: false,
            key: 'nsfpSampai',
            render: item => (item.nsfpSampai)
          },
          {
            label: 'Sekarang',
            searchable: true,
            sortable: false,
            key: 'tanggalNsfp',
            render: item => (item.tanggalNsfp)
          },
          {
            label: 'Tanggal NSFP',
            type: TableWrapper.dataTypes.DATE,
            render: item => (moment(item.tanggalNsfp).locale('id').format('ll'))
          }
        ]}
        actions={actions}
        itemActions={[
          new TableWrapper.action(
            'Edit',
            'mdi mdi-pencil',
            (item) => {
              setSelectedItem(item)
              setShowForm(true)
            },
            true
          ),
          new TableWrapper.action('Delete', 'mdi mdi-delete',
            (item) => hapus(item),
            true
          )]}
        onFetchData={(query) => {
          return (
            new Promise(async (resolve, reject) => {
              try {
                query = {
                  ...query,
                  npwpHeadId: sessionStorage.getItem('npwpHeadId')
                }
                // let getPage = await service.get(query)
                let getPage = await service.getAll(query)
                setData(getPage.data)
                getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
                resolve(getPage)
              } catch (e) {
                resolve()
                // ErrorService(e)
              }
            })
          )
        }}
        showCommandbar={showCommandbar}
        showFilterPeriod={false}
        useFilter={false}
      />
      <EnofaForm
        visible={showForm}
        onRequestClose={() => {
          setShowForm(false)
          setSelectedItem(null)
        }}
        item={selectedItem}
      />
    </>
  )
}

export default inject('modalStore')(observer(Enofa))
