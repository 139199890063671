import React, { useEffect, useState } from "react";
import {
  FormWrapper,
  TableWrapper,
  TabsWrapper,
} from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import { useParams } from "react-router-dom";
import { inject, observer } from "mobx-react";
import service from "./JurnalUmum.service";
import {
  format,
  formatRupiah,
} from "../../libs/react-mpk/services/number.service";
import utilsService from "../../services/utilsService";
import { toast } from "../../libs/react-mpk/services";
import { LoaderInfo } from "../../libs/react-mpk/components";

const JurnalUmumJurnalEntri = ({ navigationStore, match, className = "" }) => {
  let [loading, setLoading] = useState(true);

  const baseName = `jurnal-entri`;
  const basePath = `jurnal-entri`;
  var npwpHeadId = match.params.npwpHeadId;
  const { noTransaksi } = useParams();
  // console.log(noTransaksi,"no transaksi");
  var backTo = `/internal/npwp/${npwpHeadId}/jurnal-umum`;
  const [keterangan, setKeterangan] = useState("");
  const [debitList, setDebitList] = useState(null);
  const [kreditList, setKreditList] = useState(null);
  const [totalDebit, setTotalDebit] = useState(0);
  const [totalKredit, setTotalKredit] = useState(0);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      let getPage = await service.getPage();
      let res = getPage.data.filter((d) => d.noTransaksi == noTransaksi);
      setKeterangan(res[0].keterangan);
      let resDebit = await service.getJurnalEntryByNoTransaksi(noTransaksi, 1);
      setDebitList(resDebit);
      setTotalDebit(resDebit.data.totalNominal);

      let resKredit = await service.getJurnalEntryByNoTransaksi(noTransaksi, 2);
      setKreditList(resKredit);
      setTotalKredit(resKredit.data.totalNominal);
      if (
        getPage.status === 200 &&
        resDebit.status === 200 &&
        resKredit.status === 200
      ) {
        setLoading(false);
      }
    } catch (e) {}
  };

  return loading ? (
    <LoaderInfo children={"Loading . . . "} />
  ) : (
    <TabsWrapper
      showCommandbar={false}
      baseId="jurnalUmum-jurnal-entri"
      tabs={[
        {
          label: t.translate(`words.jurnalEntri`),
          key: "jurnalUmumJurnalEntri",
          render: (
            <FormWrapper
              baseId="jurnalUmum-entri-umum"
              showCommandbar={true}
              defaultData={{
                noTransaksi: utilsService.beautifyNoTransaksi(noTransaksi),
                totalDebit: `${formatRupiah(totalDebit).toString()}`,
                totalKredit: `${formatRupiah(totalKredit).toString()}`,
                keterangan: keterangan || " - ",
              }}
              definitions={[
                {
                  inputType: FormWrapper.inputTypes.INPUT,
                  label: "Nomor Transaksi",
                  key: "noTransaksi",
                  disabled: true,
                },
                {
                  inputType: FormWrapper.inputTypes.INPUT,
                  label: "Total Debit",
                  key: "totalDebit",
                  disabled: true,
                },
                {
                  inputType: FormWrapper.inputTypes.INPUT,
                  label: "Total Kredit",
                  key: "totalKredit",
                  disabled: true,
                },
                {
                  inputType: FormWrapper.inputTypes.INPUT,
                  label: "Keterangan",
                  key: "keterangan",
                  disabled: true,
                },
              ]}
            />
          ),
        },
        {
          label: "Debit",
          key: "Debit",
          render: (
            <TableWrapper
              baseId="jurnalUmum-entri-umum"
              title="Debit"
              className={className}
              showFilter={false}
              useFilter={false}
              defaultData={[]}
              defaultSortBy="tanggalDibuat"
              columns={[
                {
                  label: "Nama Akun",
                  // searchable: true,
                  key: "name",
                  render: (item) => item.akun.kode + " - " + item.akun.nama,
                },
                {
                  label: "Nominal",
                  // searchable: true,
                  key: "nominal",
                  render: (item) => `${formatRupiah(item.nominal)}`,
                },
                {
                  label: "Posisi",
                  // searchable: true,
                  key: "posisi",
                  render: (item) =>
                    item.kategori == 1
                      ? "Debit"
                      : item.kategori == 2
                      ? "Kredit"
                      : "",
                },
              ]}
              onFetchData={async (query) => {
                return new Promise(async (resolve, reject) => {
                  try {
                    // query = {
                    //   ...search,
                    //   ...query
                    // }
                    // let getPage = await service.getPage(query)
                    let data = {
                      data: debitList.data.jurnalEntryList,
                      headers: debitList.headers,
                    };
                    data.headers["x-pagination-count"] =
                      data.headers["x-total-count"];
                    resolve(data);
                  } catch (e) {
                    resolve();
                    toast.errorRequest(e);
                  }
                });
              }}
            />
          ),
        },
        {
          label: "Kredit",
          key: "Kredit",
          render: (
            <TableWrapper
              baseId="jurnalUmum-jurnal-entri-umum"
              title="Kredit"
              className={className}
              showFilter={false}
              useFilter={false}
              defaultData={[]}
              defaultSortBy="tanggalDibuat"
              columns={[
                {
                  label: "Nama Akun",
                  // searchable: true,
                  key: "name",
                  render: (item) => item.akun.kode + " - " + item.akun.nama,
                },
                {
                  label: "Nominal",
                  // searchable: true,
                  key: "nominal",
                  render: (item) => `${formatRupiah(item.nominal)}`,
                },
                {
                  label: "Posisi",
                  // searchable: true,
                  key: "posisi",
                  render: (item) =>
                    item.kategori == 1
                      ? "Debit"
                      : item.kategori == 2
                      ? "Kredit"
                      : "",
                },
              ]}
              onFetchData={async (query) => {
                return new Promise(async (resolve, reject) => {
                  try {
                    // query = {
                    //   ...search,
                    //   ...query
                    // }
                    // let getPage = await service.getPage(query)
                    let data = {
                      data: kreditList.data.jurnalEntryList,
                      headers: kreditList.headers,
                    };
                    data.headers["x-pagination-count"] =
                      data.headers["x-total-count"];
                    resolve(data);
                  } catch (e) {
                    resolve();
                    toast.errorRequest(e);
                  }
                });
              }}
            />
          ),
        },
      ]}
    />
  );
};

export default inject(
  "envStore",
  "navigationStore"
)(observer(JurnalUmumJurnalEntri));
