import React, { useEffect, useState } from "react";
import { DataForm } from "../../libs/react-mpk/components";
import t from "counterpart";
import { FormWrapper } from "../../libs/react-mpk/wrapper";
import ErrorService from "../../services/errorService";
// import service from './Enofa.service'
import { cloneDeep } from "lodash";
import { disable, Text } from "react-md";
import service from "./Penjualan.service";

const pemotongan = [
  { name: "PPH21", value: "pph21" },
  { name: "PPH22", value: "pph22" },
  { name: "PPH23", value: "pph23" },
  { name: "PPH15", value: "pph15" },
  { name: "PPH4(2)", value: "pph4a2" },
];

const atasTr = [{ name: "Omset", value: "P0001" }];

const perhitunganPajak = [
  { value: "perhitunganPajakLain", name: "Nilai Lain" },
  { value: "perhitunganPajakBeli", name: "Nilai Beli" },
];

const pemotonganPajak = [
  { name: "Iya", value: true },
  { name: "Tidak", value: false },
];

const defaultData = () => ({
  akunId: "",
  akun: {
    id: null,
    kode: null,
    nama: null,
  },
  nilaiBeban: 0,
  nilaiTagihan: 0,
  keterangan: "",
});

const PenjualanBebanForm = ({
  visible = false,
  authStore,
  envStore,
  match,
  history,
  onRequestClose = () => {},
  ...props
}) => {
  const { inputTypes, definition } = FormWrapper;
  const [data, setData] = useState({ loading: true, content: {} });
  const [organization, setOrganization] = useState([]);
  const [showField, setShowField] = useState(false);
  const [showField2, setShowField2] = useState(true);
  let [item, setItem] = useState(null);
  let [ready, setReady] = useState(true);
  const [akun, setAkun] = useState([]);
  const [jenisPenghasilanList, setJenisPenghasilanList] = useState([]);
  const [pasalPemotonganList, setPasalPemotonganList] = useState([]);
  const [objekPajakList, setObjekPajakList] = useState([]);

  // VALIDASI
  let [disabledNpwp, setDisabledNpwp] = useState(false);
  const [inputValidasiNpwp, setInputValidasiNpwp] = useState(
    "##.###.###.#-###.###"
  );
  let [disabledNik, setDisabledNik] = useState(false);
  const [inputValidasiNik, setInputValidasiNik] = useState("################");
  let [disabledTanggalPkp, setDisabledTanggalPkp] = useState(false);
  const [produkList, setProdukList] = useState([]);

  // const onSubmit = (data, callback) => {

  // let reqData = dataSubmit;

  //ISI FIELD
  // reqData.id = data.id
  // reqData.akun = data.akun
  // reqData.nilaiBeban = data.nilaiBeban
  // reqData.total = data.nilaiTagihan
  // reqData.keterangan = data.keterangan

  // reqData.id = data.id
  // reqData.akun = data.akun
  // reqData.nilaiBeban = data.nilaiBeban
  // reqData.jenisPenghasilan = data.jenisPenghasilan
  // reqData.keterangan = data.keterangan
  // reqData.pasalPemotongan = data.pasalPemotongan
  // reqData.objekPemotongan = data.objekPemotongan
  // reqData.isTerutangPajak = data.isTerutangPajak
  // reqData.pajakSetorSendiri = data.pajakSetorSendiri
  // reqData.ptsTarifPajak = data.ptsTarifPajak
  // reqData.isNilaiPenjualan = data.isNilaiPenjualan
  // reqData.nominalHitungPajak = data.nominalHitungPajak
  // reqData.nominalPajak = data.nominalPajak
  // reqData.total = data.total
  // console.log(reqData)
  // console.log(data)

  // callback('error', true, false);
  // }

  async function pasalPemotongan() {
    let res = await service.getPasal();
    var pasal = [];
    res.data.map((d) => {
      pasal.push({
        value: d.id,
        label: d.nama,
        nama: `${d.nama}`,
      });
    });
    setPasalPemotonganList(pasal);
  }

  async function objekPajak() {
    let res = await service.getObjek();
    var objek = [];
    res.data.map((d) => {
      objek.push({
        value: d.id,
        label: `${d.kode} - ${d.nama}`,
        kode: `${d.kode}`,
        nama: `${d.nama}`,
      });
    });
    setObjekPajakList(objek);
  }

  async function jenisPenghasilan() {
    let res = await service.getJenisPenghasilan();
    var penghasilan = [];
    res.data.map((d) => {
      penghasilan.push({
        value: d.id,
        label: d.nama,
        nama: `${d.nama}`,
        kategori: `${d.kategori}`,
        kategoriWp: `${d.kategoriWp}`,
      });
    });
    setJenisPenghasilanList(penghasilan);
  }

  async function akunTransaksi() {
    let res = await service.getBeban();
    var tr = [];
    res.data.map((d) => {
      tr.push({
        value: d.id,
        label: `${d.kode} - ${d.nama}`,
        kode: `${d.kode}`,
        nama: `${d.nama}`,
        kategoriDebitKredit: `${d.kategoriAkun.debitKredit}`,
      });
    });
    setAkun(tr);
  }
  const getProdukList = async (values) => {
    try {
      setProdukList([
        {
          nama: "asd",
        },
      ]);
      return [];
    } catch (e) {
      ErrorService(e);
      return [];
    }
  };

  useEffect(() => {
    objekPajak();
    pasalPemotongan();
    akunTransaksi();
    jenisPenghasilan();
    if (visible) {
      let selectedItem = defaultData();
      if (props.item) {
        selectedItem = cloneDeep(props.item);
      }
      //VALIDASI INPUT NPWP OP
      selectedItem.npwpOpInt = selectedItem.npwpOp ? "0" : "1";
      //VALIDASI INPUT MEMILIKI NPWP
      if (selectedItem.npwp == "000000000000000") {
        selectedItem.memilikiNpwp = false;
        setDisabledNpwp(true);
      } else {
        selectedItem.memilikiNpwp = true;
        setDisabledNpwp(false);
      }
      //VALIDASI INPUT PKP
      selectedItem.pkpInt = selectedItem.pkp ? "0" : "1";
      if (selectedItem.pkpInt == "0") {
        setDisabledTanggalPkp(false);
      } else {
        setDisabledTanggalPkp(true);
      }
      setItem(selectedItem);
      getProdukList();
    } else {
      setItem(defaultData());
    }
    setReady(visible);
  }, [visible]);

  return (
    ready && (
      <DataForm
        baseId="akun-npwp-form"
        title={t.translate(`modules.penjualan.penjualanProdukForm`)}
        visible={visible}
        // data={item}
        onRequestClose={onRequestClose}
        asDialog={true}
        defaultData={{
         
          total: "0",
          
        }}
        definitions={[
          {
            inputType: inputTypes.AUTOCOMPLETE,
            label: "Nama Akun",
            key: "akunId",
            validation: "required",
            width: "48%",
            data: akun,
            labelKey: "label",
            valueKey: "label",
            style: {
              marginRight: "2%",
            },
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            label: "Nilai Beban",
            key: "nilaiBeban",
            type: "text",
            thousandSeparator: ".",
            decimalSeparator: ",",
            isNumericString: true,
            width: "48%",
            style: {
              marginLeft: "2%",
            },
            required: true,
          },
          {
            render:(
                <Text style={{width:'100%'}}>Beban/Pendapatan termasuk ?</Text>
            )
          },
          {
            inputType: inputTypes.RADIO,
            label: "Debit",
            key: "debitKredit",
            value:1
          },
          {
            inputType: inputTypes.RADIO,
            label: "Kredit",
            key: "debitKredit",
            value:2
          },
          {
            inputType: inputTypes.DIVIDER,
            label: "Jenis Penghasilan",
          },
          {
            inputType: inputTypes.AUTOCOMPLETE,
            label: "Jenis Penghasilan Pajak",
            key: "jenisPenghasilanId",
            data: jenisPenghasilanList,
            labelKey: "label",
            valueKey: "label",
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            label: "Total",
            key: "total",
            width: "50%",
            thousandSeparator: ".",
            decimalSeparator: ",",
            isNumericString: true,
            disabled: true,
          },
          {
            render: (
              <div style={{ width: "100%" }}>
                <p style={{ margin: "10px" }}>
                  <b>Informasi Tambahan</b>
                </p>
              </div>
            ),
          },
          {
            inputType: inputTypes.TEXTAREA,
            label: "Keterangan",
            key: "keterangan",
          },
        ]}
        onChange={async (formData, key, value) => {
          if (key === "akunId") {
            akun.map((d) => {
              if (d.label === value) {
                formData["akun"] = {
                  id: d.value,
                  nama: d.nama,
                  kode: d.kode,
                  kategoriAkun: { id: d.kategoriDebitKredit },
                };
              }
            });
          }
          if (key === "jenisPenghasilanId") {
            jenisPenghasilanList.map((d) => {
              if (d.label === value) {
                formData["jenisPenghasilan"] = {
                  id: d.value,
                  nama: d.nama,
                  kategori: d.kategori,
                  kategoriWp: d.kategoriWp,
                };
              }
            });
          }
          if (key === "nilaiBeban") {
            formData.total = formData.nilaiBeban;
            formData.nilaiPenjualan = formData.nilaiBeban;
          }

          // return value
        }}
        // onBeforeChange={(key, value) => {
        // 	console.log(key, value)
        // 	return value
        // }}
        onSubmit={(data, callback) => {
          localStorage.setItem("bebanForm", JSON.stringify(data));
          callback("success", false);
        }}
      />
    )
  );
};

export default PenjualanBebanForm;
