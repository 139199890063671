import React, { useEffect, useState } from "react";
import { inputTypes } from "../../libs/react-mpk/config/constant";
import { FormWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import {
  Table,
  TableBody,
  TableHeader,
  TableRow,
  TableCell,
  Button,
  FontIcon,
  Text,
  Tooltipped,
} from "react-md";
import IDBillingForm from "./IDBillingForm";
import service from "./PPh.service";
import dataAwalService from "../Migrasi/DataAwal/DataAwal.service";
import { useLocation, useParams } from "react-router";
import OmzetPPhForm from "./OmzetPPhForm";
import {
  format,
  formatRupiah,
} from "../../libs/react-mpk/services/number.service";
import utilsService from "../../services/utilsService";
import moment from "moment";

const PPhPeriodeDetail = ({
  envStore,
  history,
  match,
  navigationStore,
  showCommandbar = true,
  ...props
}) => {
  const [data, setData] = useState([]);
  const [listIdBilling, setListIdBilling] = useState([]);
  const [listPenjualan, setListPenjualan] = useState([]);
  const [dataOmzet, setDataOmzet] = useState({});
  const [load, setload] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [showFormPph, setShowFormPph] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  let npwpHeadId = sessionStorage.getItem("npwpHeadId");

  const baseName = `detail`;
  var TotalBruto = sessionStorage.getItem("totalBruto");
  var TotalPphFinal = sessionStorage.getItem("totalPphFinal");
  const { id } = useParams();
  const Pphmasa = sessionStorage.getItem("Pphmasa");
  var dataPengguna = JSON.parse(sessionStorage.getItem("dataPenggunaNpwp"));
  const { state: { tahun } = {} } = useLocation();
  const fetchData = async () => {
    try {
      let res = await service.getPPhByPeriodeId(id);
      let res2 = await dataAwalService.getDataAwal(
        tahun || sessionStorage.getItem("tahunSobatBuku")
      );
      setDataOmzet(res2.data.omzetSudahDihitung);
      setData(res.data);
      setload(false);
    } catch (error) {}
  };
  const fetchListPPH = async () => {
    try {
      let query = {
        sort: "tanggalDibuat,DESC",
        "statusPembayaran.equals": 7,
        "npwp.equals": dataPengguna.npwp,
      };
      let res = await service.getPageBilling(query);
      setListIdBilling(res.data.filter((d) => d.masaAwal == Pphmasa));
      let querys = {
        size: 9999,
        page: 0,
        tahun: tahun ? tahun : sessionStorage.getItem("tahunSobatBuku"),
      };
      let getPage = await service.getAllPenjualan(querys);
      setListPenjualan(getPage.data);
    } catch (error) {}
  };
  useEffect(() => {
    fetchData();
  }, [showFormPph, showForm]);
  useEffect(() => {
    fetchListPPH();
  }, []);

  const tanggalFunction = (datas) => {
    let list = listPenjualan.filter((dd) => dd.noInvoice === datas);
    return moment(list[0]?.tanggalInvoice).format("DD MMMM YYYY");
  };

  return (
    <>
      <FormWrapper
        submitLabel={t.translate("words.submit")}
        title={t.translate("modules.pph.detail")}
        loading={load}
        showCommandbar={true}
        baseId={`mod-form-${baseName}`}
        style={{
          maxWidth: "100%",
        }}
        defaultData={{
          peredaranBruto: TotalBruto,
          pphFinal05: TotalPphFinal,
          pajakDisetor: listIdBilling
            .map((d) => d.nominalBayar)
            .reduce((acc, total) => acc + total, 0),
        }}
        definitions={[
          // {
          //   render: (
          //     <>
          //       <div style={{ width: "100%" }}>
          //         <Text
          //           style={{
          //             fontWeight: "bold",
          //             fontSize: "18pt",
          //             textAlign: "center",
          //             fontFamily:"initial"
          //           }}
          //         >
          //           Omzet
          //         </Text>
          //       </div>
          //       <div style={{ width: "100%",marginBottom: "6vh" }}>
          //         <div style={{ textAlign: "center",fontSize:'16pt',fontFamily:"initial" }}>
          //           {formatRupiah(dataOmzet)}
          //         </div>
          //       </div>
          //     </>
          //   ),
          // },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            label: t.translate(`words.peredaranBruto`),
            className: "mpk-padding-N padding-right",
            key: "peredaranBruto",
            prefix: "Rp.",
            thousandSeparator: ".",
            decimalSeparator: ",",
            disabled: true,
            width: "33%",
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            label: t.translate(`words.pphFinal05%`),
            className: "mpk-padding-N padding-right",
            prefix: "Rp.",
            thousandSeparator: ".",
            decimalSeparator: ",",
            key: "pphFinal05",
            disabled: true,
            width: "33%",
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            label: "PPh Disetor",
            prefix: "Rp.",
            thousandSeparator: ".",
            decimalSeparator: ",",
            key: "pajakDisetor",
            disabled: true,
            width: "33%",
          },
          {
            render: (
              <div style={{ width: "100%" }}>
                <div style={{ width: "100%", textAlign: "center" }}>
                  <Button
                    themeType="contained"
                    style={{
                      margin: 15,
                      backgroundColor: "#00b894",
                      color: "white",
                    }}
                    onClick={() => setShowForm(true)}
                  >
                    <FontIcon iconClassName="mdi mdi-plus" />
                    Buat Id Billing
                  </Button>
                  <Button
                    themeType="contained"
                    style={{
                      margin: 15,
                      backgroundColor: "#0984e3",
                      color: "white",
                    }}
                    onClick={() =>
                      navigationStore.redirectTo(
                        `/internal/npwp/${npwpHeadId}/listidbilling`
                      )
                    }
                  >
                    <FontIcon iconClassName="mdi mdi-format-list-bulleted" />
                    List Id Billing
                  </Button>
                </div>
                <Table style={{ width: "100%" }}>
                  <TableHeader>
                    <TableRow>
                      <TableCell style={{ width: "5%" }}>
                        {t.translate("words.non")}
                      </TableCell>
                      <TableCell style={{ width: "5%" }}>
                        {t.translate("words.aksi")}
                      </TableCell>
                      <TableCell style={{ width: "20%" }}>
                        {t.translate("words.nama")}
                      </TableCell>
                      <TableCell style={{ width: "20%" }}>
                        Tanggal Transaksi
                      </TableCell>
                      <TableCell style={{ width: "20%" }}>
                        {t.translate("words.jumlahPhBruto")}
                      </TableCell>
                      <TableCell style={{ width: "20%" }}>
                        {t.translate("words.pphTerutang")}
                      </TableCell>
                      <TableCell style={{ width: "20%" }}>
                        {t.translate("words.nomorTransaksi")}
                      </TableCell>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {data.map((d, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>
                            <Button
                              theme="primary"
                              onClick={() => {
                                if (d.noJurnal.includes("PJ")) {
                                  navigationStore.redirectTo(
                                    `/internal/npwp/${npwpHeadId}/penjualan/${d.noJurnal}/detail/non-ppn`,
                                    sessionStorage.setItem("idPenjualan", d.id),
                                    sessionStorage.setItem(
                                      "noInvoice",
                                      d.noJurnal
                                    )
                                  );
                                } else if (d.noJurnal.includes("PB")) {
                                  navigationStore.redirectTo(
                                    `/internal/npwp/${npwpHeadId}/pembelian/${d.noJurnal}/detail/non-ppn`,
                                    sessionStorage.setItem("idPembelian", d.id),
                                    sessionStorage.setItem(
                                      "noInvoicePembelian",
                                      d.noJurnal
                                    )
                                  );
                                } else if (d.noJurnal.includes("KK")) {
                                  navigationStore.redirectTo(
                                    `/internal/npwp/${npwpHeadId}/kas-dan-bank-detail-pengeluaran-non-ppn/${d.noJurnal}`
                                  );
                                } else if (d.noJurnal.includes("PK")) {
                                  navigationStore.redirectTo(
                                    `/internal/npwp/${npwpHeadId}/kas-dan-bank-detail-penerimaan-non-ppn/${d.noJurnal}`
                                  );
                                } else if (d.noJurnal.includes("TF")) {
                                  navigationStore.redirectTo(
                                    `/internal/npwp/${npwpHeadId}/kas-dan-bank-detail-transfer-antar-bank/${d.noJurnal}`
                                  );
                                } else if (d.noJurnal.includes("BY")) {
                                  navigationStore.redirectTo(
                                    `/internal/npwp/${npwpHeadId}/biaya/${d.noJurnal}/detail/non-ppn`
                                  );
                                } else if (d.noJurnal.includes("JU")) {
                                  navigationStore.redirectTo(
                                    `/internal/npwp/${npwpHeadId}/jurnal-umum/${d.noJurnal}/jurnal-entri`
                                  );
                                }
                              }}
                            >
                              <FontIcon iconClassName="mdi mdi-import" />
                            </Button>
                          </TableCell>
                          <TableCell>{d.lawanTransaksi.nama}</TableCell>
                          <TableCell>{tanggalFunction(d.noJurnal)}</TableCell>
                          <TableCell>{formatRupiah(d.totalBruto)}</TableCell>
                          <TableCell>{formatRupiah(d.totalPph)}</TableCell>
                          <TableCell>
                            {utilsService.beautifyNoTransaksi(d.noJurnal)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
            ),
          },
          {
            render: <div style={{ margin: 10 }}></div>,
          },
        ]}
      />
      <OmzetPPhForm
        visible={showFormPph}
        onRequestClose={() => {
          setShowFormPph(false);
        }}
        item={dataOmzet}
      />
      <IDBillingForm
        visible={showForm}
        onRequestClose={() => {
          setShowForm(false);
        }}
        item={selectedItem}
      />
    </>
  );
};

export default inject(
  "envStore",
  "navigationStore"
)(observer(PPhPeriodeDetail));
