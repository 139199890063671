import React, { useEffect, useState } from "react";
import { TableWrapper, TabsWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
// import service from './pph.service'
import moment from "moment";
import "moment/locale/id";
// import PPhPeriodeForm from './pphForm'
import { DataForm } from "../../libs/react-mpk/components";
import { FormWrapper } from "../../libs/react-mpk/wrapper";
import {
  format,
  formatRupiah,
} from "../../libs/react-mpk/services/number.service";
import Modal from "../../libs/react-mpk/components/Modal";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogContent,
  DialogTitle,
  DialogFooter,
  Text,
} from "react-md";
import service from "./PPh.service";
import { inject, observer } from "mobx-react";
import { useHistory, useLocation } from "react-router-dom";
import iziToast from "izitoast";
import { toast } from "../../libs/react-mpk/services";
import numeral from "numeral";

const PPhPeriode = ({
  className = "",
  showCommandbar = true,
  modalStore,
  match,
}) => {
  const [defaultData, setDefaultData] = useState([]);
  const { state: { tutupbuku, tahun } = {} } = useLocation();
  let history = useHistory();

  const npwpHeadId = match.params.npwpHeadId;
  const backTo =
    tutupbuku == true
      ? `/internal/npwp/:npwpHeadId/tutup-buku`
      : `/internal/npwp/${npwpHeadId}/pph`;

  var item = [
    // new TableWrapper.action("Kirim ke email", "mdi mdi-email", () => {
    //   modalStore.showConfirm({
    //     title: t.translate(`words.kirimEmail`),
    //     children: "kirim penghasilan bruto PP23 via email ?",
    //     onSubmit: async (callback) => {
    //       try {
    //         let r = await service.sendEmailpp23(
    //           tahun ? tahun : sessionStorage.getItem("tahunSobatBuku")
    //         );
    //         if (r.data.message == "sukses") {
    //           iziToast.success({
    //             message: r.data.message,
    //           });
    //         } else {
    //           iziToast.info({
    //             message: r.data.message,
    //           });
    //         }
    //         document.getElementsByClassName("mdi-reload")[0].click();
    //       } catch (e) {
    //         toast.errorRequest(e);
    //       }
    //       callback();
    //     },
    //   });
    // }),
  ];

  return (
    <>
      <TableWrapper
        baseId="pph-periode-table"
        backTo={backTo}
        title={t.translate("modules.pph.monitoringMenotongPajak.periode.title")}
        className={className}
        onFetchData={(query) => {
          return new Promise(async (resolve, reject) => {
            try {
              try {
                let datas = {
                  tahun: tahun
                    ? tahun
                    : sessionStorage.getItem("tahunSobatBuku"),
                };
                let res = await service.createGetPeriodeByTahunTutupBuku(datas);
                setDefaultData(res.data);
                res.headers["x-pagination-count"] = 0;
                resolve(res);
              } catch (error) {
                resolve();
              }
            } catch (error) {
              resolve();
            }
          });
        }}
        defaultSortBy="date"
        defaultCollapse={true}
        useCriteria={false}
        selectable={false}
        useQueryHistory={false}
        actions={item}
        columns={[
          {
            label: "Masa",
            searchable: true,
            sortable: false,
            key: "masa",
            render: (item) => item.masa,
          },
          {
            label: "Tahun",
            searchable: true,
            sortable: false,
            key: "tahun",
            render: (item) => item.tahun,
          },
          {
            label: "Total Bruto",
            searchable: true,
            sortable: false,
            key: "totalBruto",
            render: (item) => `${formatRupiah(item.totalBruto)}`,
          },
          {
            label: "Total PPh Final",
            searchable: true,
            sortable: false,
            key: "totalPphFinal",
            render: (item) => `${formatRupiah(item.totalPphFinal)}`,
          },
        ]}
        itemActions={[
          new TableWrapper.action(
            `${t.translate("words.detail")}`,
            "mdi mdi-import",
            (item) => {
              history.push({
                pathname: `/internal/npwp/${npwpHeadId}/pph/${1}/periode/${
                  item.tahun
                }/${item.id}`,
                state: { tahun: item.tahun },
              });
              sessionStorage.setItem(
                "totalBruto",
                numeral(item.totalBruto)
                  .format("0,0.[00]")
                  .replace(/\,/g, "z")
                  .replace(/\./g, ",")
                  .replace(/z/g, ".")
              );
              sessionStorage.setItem(
                "totalPphFinal",
                numeral(item.totalPphFinal)
                  .format("0,0.[00]")
                  .replace(/\,/g, "z")
                  .replace(/\./g, ",")
                  .replace(/z/g, ".")
              );
              sessionStorage.setItem("Pphmasa", item.masa);
              sessionStorage.setItem("idPph", item.id);
            }
          ),
        ]}
        showCommandbar={showCommandbar}
        showFilterPeriod={false}
        useFilter={false}
      />
    </>
  );
};

export default inject(
  "navigationStore",
  "envStore",
  "modalStore"
)(observer(PPhPeriode));
