import React, { useEffect, useState } from 'react';
import { DataForm } from '../../libs/react-mpk/components';
import t from 'counterpart'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import ErrorService from '../../services/errorService'
// import service from './Enofa.service'
import { cloneDeep } from 'lodash'
import { disable } from 'react-md';
import { useParams } from 'react-router';
import service from './Biaya.service';
import { toast } from '../../libs/react-mpk/services';

const pemotongan = [
    { name: 'PPH21', value: 'pph21' },
    { name: 'PPH22', value: 'pph22' },
    { name: 'PPH23', value: 'pph23' },
    { name: 'PPH15', value: 'pph15' },
    { name: 'PPH4(2)', value: 'pph4a2' }
]

const atasTr = [
    { name: 'Omset', value: 'P0001' }
]

const perhitunganPajak = [
    { value: 'perhitunganPajakLain', name: 'Nilai Lain' },
    { value: 'perhitunganPajakBeli', name: 'Nilai Beli' }
]

const pemotonganPajak = [
    { name: 'Iya', value: 'ya' },
    { name: 'Tidak', value: 'tidak' }
]

const defaultData = () => ({
    alamat: "",
    areaId: null,
    dibuatOleh: "",
    diperbaharuiOleh: "",
    email: "",
    halonaCompanyId: null,
    id: null,
    isAkunGenerated: true,
    jumlahTanggungan: 0,
    kabupaten: "",
    kecamatan: "",
    kelurahan: "",
    kodeRegisterEfiling: "",
    kodepos: null,
    messageRegisterEfiling: "",
    namaNpwp: "",
    noKtp: "",
    noTelepon: "",
    npwp: "",
    npwpHeadId: null,
    npwpOp: true,
    npwpOpInt: "0",
    pegawai: false,
    pekerjaBebas: false,
    pemilikUsaha: false,
    penggunaId: null,
    penggunaIdIam: "",
    penggunaUsername: "",
    pengurusanEfin: 3,
    pkp: false,
    pkpInt: "0",
    propinsi: "",
    registerEfiling: false,
    statusNikah: 2,
    tahun: null,
    tanggalDibuat: null,
    memilikiNpwp: true,
})

const BiayaDetailNilaiPembelian = ({
    visible = false,
    authStore,
    envStore,
    match,
    history,
    onRequestClose = () => { },
    ...props
}) => {
    const { inputTypes, definition } = FormWrapper
    // const [data, setData] = useState({ loading: true, content: {} })
    const [organization, setOrganization] = useState([])
    const [showField, setShowField] = useState(false)
    const [showField2, setShowField2] = useState(true)
    const [data, setData] = useState([])
    const { id } = useParams()

    const fetch = async () => {
        try {
            let res = await service.getById(id)
            setData(res.data)
        } catch (error) {
            toast.errorRequest(error)
        }
    }

    useEffect(() => {
        fetch()
    }, [])

    let [item, setItem] = useState(null)
    let [ready, setReady] = useState(true)

    // VALIDASI
    let [disabledNpwp, setDisabledNpwp] = useState(false)
    const [inputValidasiNpwp, setInputValidasiNpwp] = useState('##.###.###.#-###.###')
    let [disabledNik, setDisabledNik] = useState(false)
    const [inputValidasiNik, setInputValidasiNik] = useState('################')
    let [disabledTanggalPkp, setDisabledTanggalPkp] = useState(false)
    const [produkList, setProdukList] = useState([])

    const getProdukList = async (values) => {
        try {
            // var params = {
            //   page: 0,
            //   size: 100,
            //   sort: 'createdDate,DESC'
            // }
            // if(isNaN(values)){
            //   params['name.contains'] = values
            // } else {
            //   params['npwp.contains'] = values
            // }
            // let res = await service.getOrganizationOp(params)
            // res.data.map((d)=> {
            //   d.label = d.npwp + " - " + d.name
            //   d.value = d
            // })
            // setOrganization(res.data)
            // return res.data
            setProdukList([
                {
                    nama: "asd"
                }
            ])
            return []
        } catch (e) {
            ErrorService(e)
            return []
        }
    }

    useEffect(() => {
        if (visible) {
            let selectedItem = defaultData();
            if (props.item) {
                selectedItem = cloneDeep(props.item);
            }
            //VALIDASI INPUT NPWP OP
            selectedItem.npwpOpInt = selectedItem.npwpOp ? "0" : "1";
            //VALIDASI INPUT MEMILIKI NPWP
            if (selectedItem.npwp == '000000000000000') {
                selectedItem.memilikiNpwp = false;
                setDisabledNpwp(true);
            } else {
                selectedItem.memilikiNpwp = true
                setDisabledNpwp(false);
            }
            //VALIDASI INPUT PKP
            selectedItem.pkpInt = selectedItem.pkp ? "0" : "1";
            if (selectedItem.pkpInt == "0") {
                setDisabledTanggalPkp(false);
            } else {
                setDisabledTanggalPkp(true);
            }
            setItem(selectedItem);
            getProdukList()
        } else {
            setItem(defaultData())
        }
        setReady(visible)
    }, [visible])

    return ready && (
        <DataForm
            baseId="akun-npwp-form"
            title={t.translate(`modules.penjualan.penjualanProdukForm`)}
            visible={visible}
            // data={item}
            onRequestClose={onRequestClose}
            asDialog={true}
            defaultData={data}
            definitions={[
                {
                    inputType: inputTypes.INPUT,
                    label: 'Nama Akun',
                    key: 'namaAkun',
                    width: '48%',
                    style: {
                        marginRight: "2%"
                    },
                    disabled: true
                },
                {
                    inputType: inputTypes.INPUT_MASK_NUMBER,
                    label: 'Nilai Pembelian',
                    key: 'nilaiPembelian',
                    type: 'text',
                    width: '48%',
                    style: {
                        marginLeft: "2%"
                    },
                    disabled: true
                },
                {
                    inputType: inputTypes.DIVIDER,
                    label: 'Pemotongan Pajak'
                },
                {
                    inputType: inputTypes.INPUT,
                    label: 'Terdapat Pemotongan Pajak?',
                    key: 'tdppemotonganPajak',
                    required: true,
                    disabled: true
                },
                {
                    inputType: inputTypes.INPUT,
                    label: 'Pemotongan Pajak',
                    className: 'mpk-padding-N padding-right',
                    key: 'pemotonganPajak',
                    width: '50%',
                    disabled: true
                },
                {
                    inputType: inputTypes.INPUT,
                    label: 'Atas Transaksi',
                    key: 'atasTransaksi',
                    width: '50%',
                    disabled: true,
                    required: true
                },
                {
                    inputType: inputTypes.INPUT_MASK_NUMBER,
                    className: 'mpk-padding-N padding-right',
                    label: 'Tarif Pajak (%)',
                    key: 'tarifPajak',
                    disabled: true
                },
                {
                    render: (
                        <div style={{ width: '100%' }}>
                            <p style={{ margin: "10px" }}><b>Perhitungan Pajak Berdasarkan</b></p>
                        </div>
                    ),
                    required: true
                },
                {
                    inputType: inputTypes.INPUT,
                    label: 'Perhitungan Pajak',
                    className: 'mpk-padding-N padding-right',
                    key: 'perhitunganPajak',
                    width: '50%',
                    disabled: true,
                    required: true
                },
                {
                    inputType: inputTypes.INPUT_MASK_NUMBER,
                    label: 'Nilai Lain',
                    key: 'nilaiLainField',
                    width: '50%',
                    type: 'text',
                    disabled: true
                },
                {
                    inputType: inputTypes.INPUT,
                    label: 'Pajak ditaggung',
                    key: 'pajakDitanggung',
                    disabled: true
                },
                {
                    inputType: inputTypes.INPUT_MASK_NUMBER,
                    label: 'Pajak ditaggung',
                    key: 'nominalPajakDitanggung',
                    disabled: true
                },
                {
                    inputType: inputTypes.INPUT_MASK_NUMBER,
                    label: 'Nominal Pajak',
                    className: 'mpk-padding-N padding-right',
                    key: 'nominalPajak',
                    width: '50%',
                    disabled: true
                },
                {
                    inputType: inputTypes.INPUT_MASK_NUMBER,
                    label: 'Nilai Tagihan',
                    key: 'nilaiTagihan',
                    width: '50%',
                    disabled: true
                },
                {
                    render: (
                        <div style={{ width: '100%' }}>
                            <p style={{ margin: "10px" }}><b>Informasi Tambahan</b></p>
                        </div>
                    )
                },
                {
                    inputType: inputTypes.TEXTAREA,
                    label: 'Keterangan',
                    key: 'keterangan',
                    disabled: true,
                }
            ]}
            onChange={async (formData, key, value) => {
                if (key === 'nilaiPembelian') {
                    formData.nilaiTagihan = formData.nilaiPembelian
                }

                if (formData.tdppemotonganPajak === 'ya') {
                    if (formData.perhitunganPajak == 'perhitunganPajakLain') {
                        formData.nilaiTagihan = formData.nilaiLainField
                        formData.nominalPajak = formData.nilaiLainField * (formData.tarifPajak / 100)
                    } else if (formData.perhitunganPajak == 'perhitunganPajakBeli') {
                        formData.nilaiTagihan = formData.nilaiPembelian
                        formData.nominalPajak = formData.nilaiPembelian * (formData.tarifPajak / 100)
                    }
                } else if (formData.tdppemotonganPajak === 'tidak') {
                    formData.nominalPajak = 0
                }

                if (formData.pajakDitanggung === true) {
                    formData.nominalPajakDitanggung = formData.nominalPajak
                } else if ((formData.pajakDitanggung === false)) {
                    formData.nominalPajakDitanggung = formData.nilaiTagihan - formData.nominalPajak
                }
                // return value
            }}
            onSubmit={(data, callback) => {
                //VALIDASI NPWP OP
                data.npwpOp = data.npwpOpInt == "0" ? true : false
                //VALIDASI MEMILIKI NPWP
                if (data.memilikiNpwp == "1")
                    data.npwp = "000000000000000"
                //VALIDASI PKP
                data.pkp = data.pkpInt == "0" ? true : false
                callback('success', false)
            }}
        />
    )
}

export default BiayaDetailNilaiPembelian