import React, { useEffect, useState } from "react";
import { TableWrapper, TabsWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import service from "./PPh.service";
import { inject, observer } from "mobx-react";
import {
  Button,
  Dialog,
  ExpansionList,
  ExpansionPanel,
  FontIcon,
  Text,
} from "react-md";
import {
  format,
  formatRupiah,
} from "../../libs/react-mpk/services/number.service";
import RiwayatTransaksiIDBiliing from "./RiwayatTransaksiIDBiliing";
import DetailListIdBilling from "./DetailListIdBilling";
import MpnBayar from "./MpnBayar";
import iziToast from "izitoast";
import BuktiBayarForm from "./BuktiBayarForm";
import { Commandbar } from "../../libs/react-mpk/components";
import { toast } from "../../libs/react-mpk/services";
import moment from "moment";
import "moment/locale/id";

const ListIdBilling = ({
  className = "",
  showCommandbar = true,
  match,
  navigationStore,
  modalStore,
}) => {
  const [count, setcount] = useState(0);
  const [listBelumBayar, setListBelumBayar] = useState(0);
  const [showModalMenu, setShowModalMenu] = useState(false);
  const [showModalMenuBayar, setShowModalMenuBayar] = useState(false);
  const [showModalHistory, setShowModalHistory] = useState(false);
  const [showModalDetail, setShowModalDetail] = useState(false);
  const [showModalMpn, setShowModalMpn] = useState(false);
  const [showModalBayar, setShowModalBayar] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedItemKode, setSelectedItemKode] = useState(null);
  const [panel1, setPanel1] = useState(false);
  const [panel2, setPanel2] = useState(false);
  const [judul, setJudul] = useState("");
  const tahun = sessionStorage.getItem("tahunSobatBuku");
  var dataPengguna = JSON.parse(sessionStorage.getItem("dataPenggunaNpwp"));
  const idPph = sessionStorage.getItem("idPph");
  const baseId = "listIDBilling-belumdibayar";
  const baseId2 = "listIDBilling-sudahdibayar";
  var itemActions = [
    new TableWrapper.action(
      "Cetak",
      "mdi mdi-download",
      async (item) => {
        try {
          let res = await service.download(item.id);
          toast.success("berhasil");
          document.getElementsByClassName("mdi-reload")[0].click();
        } catch (e) {
          toast.errorRequest(e);
        }
      },
      true
    ),
    new TableWrapper.action(
      "Kirim Email",
      "mdi mdi-email",
      (item) => {
        sendEmail(item);
      },
      true
    ),
    new TableWrapper.action(
      "Selengkapnya",
      "mdi mdi-import",
      (item) => {
        setSelectedItem(item);
        setShowModalDetail(true);
        setJudul("BelumBayar");
      },
      true
    ),
    new TableWrapper.action(
      "Riwayat Transaksi",
      "mdi mdi-history",
      (item) => {
        setSelectedItemId(item.id);
        setShowModalHistory(true);
      },
      true
    ),
    new TableWrapper.action(
      "Bayar",
      "mdi mdi-cash-usd",
      (item) => {
        setSelectedData(item);
        setShowModalMenu(true);
      },
      (item) =>
        item.idBilling != null && item.status != 2 && item.statusPembayaran != 0
          ? true
          : false
    ),
    new TableWrapper.action(
      "MPN Browser",
      "mdi mdi-cash-multiple",
      async (item) => {
        let res = await service.generateMpn(item.idBilling);
        window.location.replace(res.data.accessKey);
      },
      (item) =>
        item.idBilling != null && item.status != 2 && item.statusPembayaran != 0
          ? true
          : false
    ),
  ];
  var itemActions2 = [
    new TableWrapper.action(
      "Selengkapnya",
      "mdi mdi-import",
      (item) => {
        setSelectedItem(item);
        setShowModalDetail(true);
        setJudul("SudahBayar");
      },
      true
    ),
    new TableWrapper.action(
      "Cetak",
      "mdi mdi-download",
      async (item) => {
        try {
          let res = await service.download(item.id);
          toast.success("berhasil");
          document.getElementsByClassName("mdi-reload")[0].click();
        } catch (e) {
          toast.errorRequest(e);
        }
      },
      true
    ),
    new TableWrapper.action(
      "Riwayat Transaksi",
      "mdi mdi-history",
      (item) => {
        setSelectedItemId(item.id);
        setShowModalHistory(true);
      },
      true
    ),
  ];

  async function sendEmail(item) {
    modalStore.showConfirm({
      title: t.translate(`words.kirimEmail`),
      children: t.translate(`words.confirmSendEmail`),
      onSubmit: (callback) => {
        sendEmailExc(item);
        callback();
      },
    });
  }

  async function sendEmailExc(item) {
    try {
      let r = await service.sendEmail(item.id);
      iziToast.success({
        message: r.data.message,
      });
      document.getElementsByClassName("mdi-reload")[0].click();
    } catch (e) {
      toast.errorRequest(e);
    }
  }

  var item = [
    new TableWrapper.action("Kirim ke email", "mdi mdi-email", () => {
      let selected = JSON.parse(TableWrapper.getSelected(baseId));
      let selectedData = [];
      selected.map((d) => selectedData.push(listBelumBayar[d]));
      let listData = selectedData.map((d) => d.id);
      if (listData.length == 0) {
        iziToast.warning({
          message: "Harap Pilih Minimal 1 Id Billing",
        });
      } else {
        modalStore.showConfirm({
          title: t.translate(`words.kirimEmail`),
          children: t.translate(`words.confirmSendEmail`),
          onSubmit: async (callback) => {
            // let datas = {
            //   id: listData,
            // };
            try {
              let r = await service.sendEmailBulk(listData);
              iziToast.success({
                message: r.data.message,
              });
              document.getElementsByClassName("mdi-reload")[0].click();
            } catch (e) {
              toast.errorRequest(e);
            }
            callback();
          },
        });
      }
    }),
  ];

  return (
    <>
      <TabsWrapper
        title="LIST ID BILLING"
        showCommandbar={true}
        baseId="list-id-billing-tabs"
        tabs={[
          {
            label: "BELUM DIBAYAR",
            render: (
              <>
                <TableWrapper
                  baseId={baseId}
                  className={className}
                  defaultData={[]}
                  title={
                    <Commandbar
                      title={"BELUM DIBAYAR"}
                      leftCorner={
                        <Button
                          className="mpk-margin-NS margin-right"
                          buttonType="icon"
                          onClick={() => navigationStore.back()}
                        >
                          <FontIcon iconClassName="mdi mdi-arrow-left" />
                        </Button>
                      }
                    />
                  }
                  defaultSortBy="tanggalDibuat"
                  showActionColumn={true}
                  actions={item}
                  isShowFilter={false}
                  useFilter={false}
                  selectable={true}
                  defaultCollapse={false}
                  showIndex={false}
                  useCriteria={true}
                  useQueryHistory={false}
                  showFilter={false}
                  onFetchData={() => {
                    return new Promise(async (resolve, reject) => {
                      try {
                        let query = {
                          sort: "tanggalDibuat,DESC",
                          "statusPembayaran.notEquals": 7,
                          "npwp.equals": dataPengguna.npwp,
                        };
                        let getPage = await service.getPageBilling(query);
                        setListBelumBayar(getPage.data);
                        getPage.headers["x-pagination-count"] =
                          getPage.headers["x-total-count"];
                        resolve(getPage);
                      } catch (e) {
                        reject();
                        // ErrorService(e)
                      }
                    });
                  }}
                  columns={[
                    {
                      label: "ID Billing",
                      searchable: true,
                      sortable: false,
                      key: "idBilling",
                      render: (item) => (
                        <div
                          style={{
                            color:
                              item.idBilling != null &&
                              item.status != 2 &&
                              item.statusPembayaran != 0 &&
                              "#27ae60",
                          }}
                        >
                          {item.idBilling || "---"}
                        </div>
                      ),
                    },
                    {
                      label: "Nama",
                      searchable: true,
                      sortable: false,
                      key: "namaPenyetor",
                      render: (item) => item.namaPenyetor,
                    },
                    {
                      label: "Nominal Bayar",
                      searchable: true,
                      sortable: false,
                      key: "nominalBayar",
                      render: (item) => `${formatRupiah(item.nominalBayar)}`,
                    },
                    {
                      label: "Status",
                      searchable: true,
                      sortable: false,
                      key: "nominalBayar",
                      render: (item) => (
                        <TableWrapper.Status
                          type={
                            item.statusPembayaran == 1
                              ? TableWrapper.Status.types.IDLE
                              : item.statusPembayaran == 2 ||
                                (item.status == 2 &&
                                  item.statusPembayaran == 0) ||
                                (item.status == 4 &&
                                  item.statusPembayaran == 0) ||
                                (item.status == 5 &&
                                  item.statusPembayaran == 0) ||
                                item.expiredDate != null
                              ? TableWrapper.Status.types.ERROR
                              : (item.status == 1 &&
                                  item.statusPembayaran == 0) ||
                                (item.status == 3 &&
                                  item.statusPembayaran == 0) ||
                                (item.status == 7 && item.statusPembayaran == 0)
                              ? TableWrapper.Status.types.SUCCESS
                              : item.statusPembayaran == 3 ||
                                item.statusPembayaran == 4
                              ? TableWrapper.Status.types.PROGRESS
                              : TableWrapper.Status.types.PROGRESS
                          }
                        >
                          {item.statusPembayaran == 1
                            ? "ID Billing Tersedia"
                            : item.statusPembayaran == 2
                            ? "Menunggu Pembayaran"
                            : item.statusPembayaran == 3
                            ? "Pembayaran perlu Direfund"
                            : item.statusPembayaran == 4
                            ? "Pembayaran telah Direfund"
                            : item.status == 1 && item.statusPembayaran == 0
                            ? "ID Billing sedang diproses"
                            : item.status == 2 && item.statusPembayaran == 0
                            ? "ID Billing expired"
                            : item.status == 3 && item.statusPembayaran == 0
                            ? "ID Billing Tersedia"
                            : item.status == 4 && item.statusPembayaran == 0
                            ? "Permintaan ID Billing Error"
                            : item.status == 5 && item.statusPembayaran == 0
                            ? "Data Dihapus"
                            : item.status == 7 && item.statusPembayaran == 0
                            ? "sudah Dibayar"
                            : item.expiredDate != null
                            ? "ID Billing expired"
                            : ""}
                        </TableWrapper.Status>
                      ),
                    },
                  ]}
                  itemActions={itemActions}
                  tableFooter={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ fontWeight: "bold", fontSize: 18 }}>
                        Total : {formatRupiah(count)}
                      </div>

                      <Button
                        themeType="flat"
                        onClick={() => {
                          let selected = JSON.parse(
                            TableWrapper.getSelected(baseId)
                          );
                          let selectedData = [];
                          selected.map((d) =>
                            selectedData.push(listBelumBayar[d])
                          );
                          let listData = selectedData
                            .map((d) => d.nominalBayar)
                            .reduce((total, dd) => total + dd, 0);
                          setcount(listData);
                        }}
                      >
                        <FontIcon
                          iconClassName="mdi mdi-calculator"
                          style={{ fontSize: 30 }}
                        />
                      </Button>
                    </div>
                  }
                />
              </>
            ),
          },
          {
            label: "SUDAH DIBAYAR",
            render: (
              <>
                <TableWrapper
                  baseId={baseId2}
                  title={
                    <Commandbar
                      title={"SUDAH DIBAYAR"}
                      leftCorner={
                        <Button
                          className="mpk-margin-NS margin-right"
                          buttonType="icon"
                          onClick={() => navigationStore.back()}
                        >
                          <FontIcon iconClassName="mdi mdi-arrow-left" />
                        </Button>
                      }
                    />
                  }
                  className={className}
                  defaultData={[]}
                  defaultSortBy="tanggalDibuat"
                  isShowFilter={false}
                  showActionColumn={true}
                  useFilter={false}
                  defaultCollapse={true}
                  useCriteria={true}
                  selectable={false}
                  useQueryHistory={false}
                  onFetchData={() => {
                    return new Promise(async (resolve, reject) => {
                      try {
                        let query = {
                          sort: "tanggalDibuat,DESC",
                          "statusPembayaran.equals": 7,
                          "npwp.equals": dataPengguna.npwp,
                        };
                        let getPage = await service.getPageBilling(query);
                        getPage.headers["x-pagination-count"] =
                          getPage.headers["x-total-count"];
                        resolve(getPage);
                      } catch (e) {
                        resolve();
                        // ErrorService(e)
                      }
                    });
                  }}
                  columns={[
                    {
                      label: "Nama",
                      searchable: true,
                      sortable: false,
                      key: "namaPenyetor",
                      render: (item) => item.namaPenyetor || "---",
                    },
                    {
                      label: "Kode Billing",
                      searchable: false,
                      sortable: false,
                      render: (item) => item.idBilling,
                    },
                    {
                      label: "NTPN",
                      searchable: false,
                      sortable: false,
                      render: (item) => item.ntpn.match(/.{1,4}/g).join(" "),
                    },
                    {
                      label: "Tanggal Setor",
                      searchable: false,
                      sortable: false,
                      render: (item) =>
                        moment(item.tanggalDibuat)
                          .locale("id")
                          .format("DD MMMM YYYY"),
                    },
                    {
                      label: "Kode",
                      searchable: false,
                      sortable: false,
                      render: (item) =>
                        item.kodeJenisPajak + " - " + item.kodeJenisSetoran,
                    },
                    {
                      label: "Nominal Bayar",
                      searchable: false,
                      sortable: false,
                      render: (item) => `${formatRupiah(item.nominalBayar)}`,
                    },
                  ]}
                  itemActions={itemActions2}
                  showFilterPeriod={false}
                />
              </>
            ),
          },
        ]}
      />
      <RiwayatTransaksiIDBiliing
        visible={showModalHistory}
        onRequestClose={() => {
          setShowModalHistory(false);
          setSelectedItemId(null);
          document.getElementsByClassName("mdi-reload")[0].click();
        }}
        itemId={selectedItemId}
      />
      <DetailListIdBilling
        visible={showModalDetail}
        onRequestClose={() => {
          setShowModalDetail(false);
          setSelectedItem(null);
          setJudul("");
          document.getElementsByClassName("mdi-reload")[0].click();
        }}
        data={selectedItem}
        judul={judul}
      />
      <BuktiBayarForm
        visible={showModalBayar}
        onRequestClose={() => {
          setShowModalBayar(false);
          document.getElementsByClassName("mdi-reload")[0].click();
        }}
        item={selectedData}
      />
      <MpnBayar
        visible={showModalMpn}
        onRequestClose={() => {
          setSelectedItemKode(null);
          setShowModalMpn(false);
          document.getElementsByClassName("mdi-reload")[0].click();
        }}
        idBilling={selectedItemKode}
      />
      <Dialog
        id="overflow-dialog"
        visible={showModalMenu}
        onRequestClose={() => {
          setShowModalMenu(false);
        }}
        aria-labelledby="overflow-dialog-title"
        style={{ borderRadius: 30 }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Text type="headline-4"> Metode Pembayaran</Text>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Button
            themeType="contained"
            style={{
              backgroundColor: "#2980b9",
              color: "white",
              padding: 10,
              margin: 15,
            }}
            onClick={() => {
              setShowModalMpn(true);
              setSelectedItemKode(selectedData.idBilling);
              setShowModalMenu(false);
            }}
          >
            MPN Sobat Pajak VA (BRIVA)
          </Button>
          <Button
            themeType="contained"
            style={{
              backgroundColor: "#16a085",
              color: "white",
              padding: 10,
              margin: 15,
            }}
            onClick={() => {
              setShowModalMenuBayar(true);
              setShowModalMenu(false);
            }}
          >
            Metode Pembayaran Lainnya
          </Button>
        </div>
      </Dialog>
      <Dialog
        id="overflow-dialog"
        visible={showModalMenuBayar}
        onRequestClose={() => {
          setShowModalMenuBayar(false);
        }}
        aria-labelledby="overflow-dialog-title"
        style={{ width: "60%" }}
      >
        <div style={{ width: "100%" }}>
          <Text type="headline-5" style={{ textAlign: "center" }}>
            Petunjuk Pembayaran
          </Text>
        </div>
        <ExpansionList style={{ width: "100%" }}>
          <ExpansionPanel
            expanded={panel1}
            onClick={() => setPanel1(!panel1)}
            header={"Via Pos / Bank"}
          >
            <ol>
              <li>Siapkan ID Billing yang tertera pada SSP Anda.</li>
              <li>Tunjukkan ID Billing kepada teller pos / bank.</li>
              <li>Bayarkan pajak sesuai jumlah pajak yang terutang.</li>
              <li>Terima dan simpan Bukti Penerimaan Negara (BPN).</li>
            </ol>
          </ExpansionPanel>
          <ExpansionPanel
            expanded={panel2}
            onClick={() => setPanel2(!panel2)}
            header={"Via ATM"}
          >
            <ol>
              <li>Siapkan ID Billing yang tertera pada SSP Anda.</li>
              <li>Masukkan kartu ATM dan ketik PIN Anda.</li>
              <li>
                Cari dan pilih menu Penerimaan Negara (Hubungi Customer Service
                setiap bank untuk informasi lengkap).
              </li>
              <li>Masukkan ID Billing.</li>
              <li>Periksa dan konfirmasi data pembayaran.</li>
              <li>Cetak dan simpan Bukti Penerimaan Negara (BPN).</li>
            </ol>
          </ExpansionPanel>
        </ExpansionList>
        <Button
          themeType="contained"
          style={{
            backgroundColor: "#16a085",
            width: "96%",
            color: "white",
            marginLeft: "2%",
            marginRight: "2%",
            marginTop: "2%",
            marginBottom: "2%",
          }}
          onClick={() => {
            setShowModalMenuBayar(false);
            setShowModalBayar(true);
          }}
        >
          Input Data Pembayaran
        </Button>
      </Dialog>
    </>
  );
};
export default inject("modalStore", "navigationStore")(observer(ListIdBilling));
