import React, { useEffect, useState } from 'react';
import { CustomWrapper, FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { Table, TableBody, TableHeader, TableRow, TableCell, Card, Button, FontIcon, TableFooter } from 'react-md'
import service from './Pesanan.service'
import { useParams } from "react-router-dom";
import moment from 'moment';
import { toast } from '../../libs/react-mpk/services';
// import { FormWrapper } from '../../libs/react-mpk/components';

const PesananDetailProduk = ({
        visible = false, 
		title,
		modalStore,
		envStore
	}) => {
	const { id } = useParams();
	const [data, setdata] = useState(null);
	const [load, setload] = useState(true);
	const [produkList, setProdukList] = useState(null)
	let npwpHeadId = sessionStorage.getItem('npwpHeadId')

	const baseName = `pesanan`
	const basePath = `pesanan`
	var backTo = `/internal/pesanan`

	const fetchData = async () => {
		try {
			let res = await service.getProduk(id)
			setProdukList(res.data)
			setload(false)
		} catch (error) {
			toast.errorRequest(error)
		}
	}

	useEffect(() => {
		fetchData()
	}, [])

	return load ? "loading" : (
			<FormWrapper
				showCommandbar={false}
				baseId={`mod-form-${baseName}-produk`}
				style={{
					maxWidth: '100%'
				}}
                visible={visible}
                asDialog={true}
				defaultData={produkList}
				watchDefaultData={true}
				definitions={[
					{
						render: (
							<div style={{ width: '100%' }}>
								<p><b>{t.translate(`words.identity`)}</b></p>
							</div>
						)
					},
					{
						inputType: FormWrapper.inputTypes.DIVIDER,
						label: t.translate(`words.dataLawanTransaksi`),
					},
					{
						inputType: FormWrapper.inputTypes.INPUT,
						label: t.translate(`words.nama`),
						key: 'diskon',
						disabled:true
					},
					{
						inputType: FormWrapper.inputTypes.INPUT,
						label: t.translate(`words.identityNpwp`),
						key: 'npwp',
						disabled: true,
						width: '49%',
						style: {
							marginRight: "2%"
						}
					},
					{
						inputType: FormWrapper.inputTypes.INPUT,
						label: t.translate(`words.identityNik`),
						key: 'nik',
						disabled: true,
						width: '49%',
					},
					{
						inputType: FormWrapper.inputTypes.INPUT,
						label: t.translate(`words.address`),
						key: 'alamat',
						disabled: true,
						width: '100%'
					},
					{
						inputType: FormWrapper.inputTypes.DIVIDER,
						label: t.translate(`words.picPenagihan`),
					},
					{
						inputType: FormWrapper.inputTypes.INPUT,
						label: t.translate(`words.namaPic`),
						key: 'namaPic',
						disabled: true,
						width: '49%',
						style: {
							marginRight: "2%"
						}
					},
					{
						inputType: FormWrapper.inputTypes.INPUT,
						label: t.translate(`words.emailPic`),
						key: 'emailPic',
						disabled: true,
						width: '49%',
					},
					{
						inputType: FormWrapper.inputTypes.INPUT,
						label: t.translate(`words.nomorTeleponPic`),
						key: 'noTeleponPic',
						disabled: true,
						width: '49%',
						style: {
							marginRight: "2%"
						}
					},
					{
						inputType: FormWrapper.inputTypes.INPUT,
						label: t.translate(`words.alamatPic`),
						key: 'alamatPic',
						type: 'text',
						disabled: true,
						width: '49%',
					},
					//------------------------------------------------------------
					{
						render: (
							<div style={{ width: '100%' }}>
								<p><b>{t.translate(`words.transaksi`)}</b></p>
							</div>
						)
					},
					{
						inputType: FormWrapper.inputTypes.DIVIDER,
						label: t.translate(`words.dataPesanan`)
					},
					// {
					// 	inputType: inputTypes.INPUT,
					// 	label: t.translate(`words.nomorTransaksi`),
					// 	key: 'nomorTransaksi',
					// 	type: 'text',
					// 	disabled: true,
					// 	width: '49%',
					// 	style: {
					// 		marginRight: "2%"
					// 	}
					// },
					// {
					// 	inputType: inputTypes.SELECT,
					// 	label: t.translate(`words.kategoriAset`),
					// 	key: 'kategoriAset',
					// 	type: 'text',
					// 	validation: 'required',
					// 	options: [
					// 		{label:"Manual", value:"manual"}
					// 	],
					// },
					{
						inputType: FormWrapper.inputTypes.INPUT,
						label: t.translate(`words.tanggalPesanan`),
						key: 'tanggalPesan',
						disabled:true
					},
					{
						inputType: FormWrapper.inputTypes.INPUT,
						label: t.translate(`words.kurir`),
						key: 'kurirId',
						disabled:true
					},
					//----------------------------------------------------------
					{
						render: (
							<div style={{ width: '100%' }}>
								<p><b>{t.translate(`words.produk`)}</b></p>
							</div>
						)
					},
					{
						render: (
							<div style={{ margin: 10 }}></div>
						)
					},
				]}
			/>
	)
}

export default inject('envStore', 'modalStore', 'navigationStore')(observer(PesananDetailProduk))