import React, { useEffect, useState } from 'react';
import { inputTypes } from '../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { find } from 'lodash'
import { ExpansionList, ExpansionPanel, Table, TableBody, TableHeader, TableRow, TableCell, Card, Button, FontIcon } from 'react-md'
import Modal from '../../libs/react-mpk/components/Modal'

const cities = [
  {id: 1, name: 'Jakarta'},
  {id: 2, name: 'Bandung'},
  {id: 3, name: 'Semarang'},
  {id: 4, name: 'Yogyakarta'},
  {id: 5, name: 'Surabaya'}
]

const PenyesuaianPersediaanForm = ({envStore, ...props}) => {
	const [data, setData] = useState({loading: true, content: {}})
	const [panel1, setPanel1] = useState(true)
	const [panel2, setPanel2] = useState(true)
	const [dialogDokumen, setDialogDokumen] = useState(false)
	const [dialogData, setDialogData] = useState(false)
	const [dokumens, setDokumens] = useState([])

	function addItem(){
	  setDialogDokumen(true)
	  setDialogData({})
	}

	useEffect(() => {
        async function initData(){
			var penyesuaianPersediaanform = JSON.parse(localStorage.getItem("penyesuaianPersediaanform"))
			localStorage.setItem("penyesuaianPersediaanform", JSON.stringify({
				sptNpwp: "",
				sptMonth: "",
				sptYear: "",
				sptRev: "",
				signerName: "",
				signerNpwp: "",
				actAs: "",
				signerId: "",
				refs: [],
				address: "-",
				phone: "0",
				postalCode: "0",
				province: "-",
				city: "-",
				district: "-",
				subDistrict: "-",
				withoutIncome: true,
				bruto: "0",
				rates: "0",
				ratesValue: "0",
				...penyesuaianPersediaanform
			}))
			setData({ content: {}, loading: false })
            // if(match.params.id == 'new') {
            //     var penyesuaianPersediaanform = JSON.parse(localStorage.getItem("penyesuaianPersediaanform"))
            //     localStorage.setItem("penyesuaianPersediaanform", JSON.stringify({
            //         sptNpwp: spt.npwp,
            //         sptMonth: spt.month + "",
            //         sptYear: spt.year + "",
            //         sptRev: spt.rev + "",
            //         signerName: getSigner.name,
            //         signerNpwp: getSigner.npwp,
            //         actAs: getSigner.actAs + "",
            //         signerId: getSigner.identity,
            //         refs: [],
            //         address: "-",
            //         phone: "0",
            //         postalCode: "0",
            //         province: "-",
            //         city: "-",
            //         district: "-",
            //         subDistrict: "-",
            //         withoutIncome: true,
            //         bruto: "0",
            //         rates: "0",
            //         ratesValue: "0",
            //         ...penyesuaianPersediaanform
            //     }))
            //     setData({ content: {}, loading: false })
            // } else {
            //     // alamat, telepon,propinsi,kota,kecamatan,kelurahan,dan kode post 
            //     const res = await service.getOne(match.params.id)
            //     res.data.signerId = res.data.identity
            //     res.data.bruto = res.data.bruto + ""
            //     res.data.pph   = res.data.pph + ""
            //     res.data.rates = res.data.rates + ""
            //     res.data.ratesValue = res.data.rates + ""
            //     res.data.identityAs = res.data.identityAs + ""
            //     res.data.actAs = res.data.signAs + ""
            //     res.data.signAggree = res.data.signAggree + ""
            //     res.data.form4valid = true
            //     res.data.form3valid = true
            //     res.data.form2valid = true
            //     res.data.form1valid = true
            //     localStorage.setItem("penyesuaianPersediaanform", JSON.stringify(res.data))
            //     setData({ content: {}, loading: false })
            // }
        }
        initData()
      }, [])
	

	const activePanel1 = ()=> {
        setPanel1(true);
        setPanel2(false);
      }

      const activePanel2 = ()=> {
        setPanel1(false);
        setPanel2(true);
      }


	var onSubmit = async (data, callback) => {
		// data.form1valid = true
		// var bpform = JSON.parse(localStorage.getItem('bpform'))
		// bpform = Object.assign(bpform, data)
		// localStorage.setItem('bpform', JSON.stringify(bpform))
		props.activePanel()
	}

	const baseName = `penyesuaian-persediaan`
	const basePath = `penyesuaian-persediaan`
	var backTo = `/internal/penyesuaian-persediaan`

	var dokumensData = [
		{
			kode: "001",
			nama: "Laptop Acer",
			akunPersediaan: "1.1.06.0001 - Persediaan Barang",
			jumlahBarang: "10",
			subtotal: "10000000"
		}
	];

	// setDokumens(dokumensData);

	return (
		
		<FormWrapper 
			loading={data.loading}
			backTo={backTo}
			showCommandbar={true}
			baseId={`mod-form-${baseName}`}
			title={t.translate(`modules.penyesuaianPersediaan.form`)}
			style={{
				maxWidth: '100%'
			}}
			// defaultData={data.content}
			definitions={[
				{ 
					render: (
						<ExpansionList style={{ width: '100%' }}>
							<ExpansionPanel expanded={panel1} onClick={()=> activePanel1()} header={ t.translate('words.form')}>
								<FormWrapper
									submitLabel={t.translate('words.next')}
									loading={data.loading}
									showCommandbar={false}
									baseId={`mod-form-${baseName}-1`}
									style={{
									maxWidth: '100%'
									}}
									defaultData={data.content}
									onChange={(formData, key, value)=> {
									}}
									definitions={[
										{
											inputType: inputTypes.INPUT,
											label: t.translate(`words.nomorTransaksi`),
											key: 'nomorTransaksi',
											type: 'text',
											// mask: inputMasks,
											maskChar: '_',
											maskValue: 'string',
											width: '100%'
										},
										{
											inputType: inputTypes.DATEPICKER,
											label: t.translate(`words.tanggal`),
											key: 'tanggal',
											width: '100%',
											type: 'date',
											validation: 'required'
										},
										{
											inputType: inputTypes.SELECT,
											label: t.translate(`words.kategori`),
											key: 'kategori',
											type: 'text',
											validation: 'required',
											options: ["Persediaan Awal", "Pembelian", "Penjualan"],
											// disabled: disabledKapKJS
										},
									]}
									onSubmit={onSubmit}
								/>

							</ExpansionPanel>
							
							<br />

							{/* FORM PRODUK */}
							<ExpansionPanel expanded={panel2} onClick={()=> activePanel2()} header={ t.translate('words.listProduk')}>
								{/* <BpForm3 match={match} activePanel={activePanel3} /> */}
								<Modal
									id="dokumen-form"
									aria-label="dokumen-form"
									title={t.translate('words.formulirDasarPemotong')}
									// visible={dialogDokumen}
									visible={false}
									onRequestClose={_closeDialog}
									style={{
									width: 800, 
									height: 650
									}}
									disableFocusOnMount={true}
								>
									{/* <BpDokumenForm _closeDialog={_closeDialog} _setDokumens={_setDokumens} data={dialogData} /> */}
								</Modal>
								<FormWrapper
									submitLabel={t.translate('words.next')}
									loading={data.loading}
									showCommandbar={false}
									baseId={`mod-form-${baseName}`}
									style={{
									maxWidth: '100%'
									}}
									defaultData={data.content}
									definitions={[
									// {
									//   inputType: inputTypes.INPUT,
									//   label: t.translate(`words.sptNpwp`),
									//   key: 'sptNpwp',
									//   width: '50%',
									//   type: 'text',
									//   disabled: true
									// },
									// {
									//   inputType: inputTypes.INPUT,
									//   label: t.translate(`words.sptRev`),
									//   key: 'sptRev',
									//   width: '50%',
									//   type: 'text',
									//   disabled: true
									// },
									// {
									//   inputType: inputTypes.INPUT,
									//   label: t.translate(`words.sptMonth`),
									//   key: 'sptMonth',
									//   width: '50%',
									//   type: 'text',
									//   disabled: true
									// },
									// {
									//   inputType: inputTypes.INPUT,
									//   label: t.translate(`words.sptYear`),
									//   key: 'sptYear',
									//   width: '50%',
									//   type: 'text',
									//   disabled: true
									// },
									// {
									//   render: (
									//     <Divider style={{ marginBottom: 25 }}/>
									//   )
									// },
									{
										render: (
										<div style={{ width: '100%' }}>
											<Button theme="primary" themeType="contained" style={{ margin: 15 }} onClick={()=> addItem()}><FontIcon iconClassName="mdi mdi-plus"/>{t.translate("words.add")}</Button>
											<Table style={{ width: '100%' }}>
											<TableHeader>
												<TableRow>
													<TableCell>{t.translate('words.non')}</TableCell>
													<TableCell>{t.translate('words.kode')}</TableCell>
													<TableCell>{t.translate('words.nama')}</TableCell>
													<TableCell>{t.translate('words.akunpersediaan')}</TableCell>
													<TableCell>{t.translate('words.jumlahBarang')}</TableCell>
													<TableCell>{t.translate('words.subtotal')}</TableCell>
													<TableCell>{t.translate('words.actions')}</TableCell>
												</TableRow>
											</TableHeader>
											<TableBody>
												{dokumensData.map((d, index)=> {
												return (  
													<TableRow key={index}>
														<TableCell>{index + 1}</TableCell>
														<TableCell>{d.kode}</TableCell>
														<TableCell>{d.nama}</TableCell>
														<TableCell>{d.akunPersediaan}</TableCell>
														<TableCell>{d.jumlahBarang}</TableCell>
														<TableCell>{d.subtotal}</TableCell>
														<TableCell>
															<Button onClick={()=> {}}>{t.translate("words.edit")}</Button>
															<Button onClick={()=> {}}>{t.translate("words.delete")}</Button>
														</TableCell>
													</TableRow>
												)
												})}
											</TableBody>
											</Table>
										</div>
										)
									},
									{
										render: (
										<div style={{ margin: 10 }}></div>
										)
									},
									]}
									onSubmit={onSubmit}
								/>
							</ExpansionPanel>
						</ExpansionList>
					)
				},
			]}
			// onSubmit={async (data, callback) => {

			// }}
		/>
	)
	

	function _closeDialog() {
		setDialogDokumen(false)
	}

	function _setDokumens(dokumens){
		setDokumens(dokumens)
	}
}

export default inject('envStore')(observer(PenyesuaianPersediaanForm))
