import React, { useEffect, useState } from 'react';
import { DataForm } from '../../libs/react-mpk/components';
import { cloneDeep } from 'lodash'

const defaultData = () => ({
	string: '',
	number: 0,
	boolean: false,
	multiCheckbox: ['']
})

const TableSampleForm = ({
	visible = false,
	onRequestClose = () => { },
	...props
}) => {
	let [item, setItem] = useState(null)
	let [ready, setReady] = useState(true)
	// useEffect(() => {
	// 	if (visible) {
	// 		let selectedItem = defaultData();
	// 		if (props.item) {
	// 			selectedItem = cloneDeep(props.item)
	// 			selectedItem.number = String(selectedItem.number)
	// 		}
	// 		setItem(selectedItem)
	// 	} else {
	// 		setItem(defaultData())
	// 	}
	// 	setReady(visible)
	// }, [visible])

	return ready && (
		<DataForm
			baseId="table-sample-form"
			title="Table Sample Form"
			visible={visible}
			// data={item}
			onRequestClose={onRequestClose}
			asDialog={true}
			defaultData={props.item || defaultData()}
			definitions={[
				{
					inputType: DataForm.inputTypes.INPUT,
					label: 'String',
					key: 'string'
				},
				{
					inputType: DataForm.inputTypes.INPUT_MASK_NUMBER,
					label: 'Number',
					key: 'number',
					type: 'number',
					isNumericString: true
				},
				{
					inputType: DataForm.inputTypes.CHECKBOX,
					label: 'Boolean',
					key: 'boolean'
				},
				{
					inputType: DataForm.inputTypes.MULTI_CHECKBOX,
					label: 'Multi Checkbox',
					key: 'multiCheckbox',
					options: [
						{label: 'Cokelat', value: 'cokelat'},
						{label: 'Susu', value: 'susu'},
						{label: 'Keju', value: 'keju'}
					]
				},
			]}
			onBeforeChange={(key, value) => {
				return value
			}}
			onSubmit={(data, callback) => {
				callback('success', false)
			}}
		/>
	)
}

export default TableSampleForm