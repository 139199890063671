import React, { useState } from "react";
import { TableWrapper, TabsWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import service from "./KategoriUnit.service";
import KategoriForm from "./KategoriForm";
import UnitForm from "./UnitForm";
import { inject, observer } from "mobx-react";
import iziToast from "izitoast";
import { toast } from "../../libs/react-mpk/services";
import KategoriUnitTable from "./KategoriUnitTable";

const KategoriUnit = ({
  className = "",
  showCommandbar = true,
  modalStore,
}) => {
  const [showForm, setShowForm] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  let npwpHeadId = sessionStorage.getItem("npwpHeadId");
  var actions = [
    new TableWrapper.action(
      `${t.translate("words.add")}`,
      "mdi mdi-plus",
      () => {
        setSelectedItemId(null);
        setShowForm(true);
      },
      true
    ),
  ];
  var itemActionsKategori = [
    new TableWrapper.action(
      "Edit",
      "mdi mdi-pencil",
      (item) => {
        setSelectedItemId(item.id);
        setShowForm(true);
      },
      true
    ),
    new TableWrapper.action(
      "Delete",
      "mdi mdi-delete",
      (item) => {
        hapus(item);
      },
      true
    ),
  ];
  var itemActionsUnit = [
    new TableWrapper.action(
      "Edit",
      "mdi mdi-pencil",
      (item) => {
        setSelectedItemId(item.id);
        setShowForm(true);
      },
      true
    ),
    new TableWrapper.action(
      "Delete",
      "mdi mdi-delete",
      (item) => {
        hapusUnit(item);
      },
      true
    ),
  ];
  //hapus kategori
  async function hapusExc(item) {
    try {
      var r = await service.deleteKategori(item.id);

      if (r.data.message == "sukses") {
        iziToast.success({
          message: r.data.message,
        });
      } else {
        iziToast.info({
          message: r.data.message,
        });
      }
      document.getElementsByClassName("mdi-reload")[0].click();
    } catch (e) {
      // errorService(e)
      toast.errorRequest(e);
    }
  }
  async function hapus(item) {
    modalStore.showConfirm({
      title: t.translate("words.delete"),
      children: t.translate("words.confirmHapus"),
      onSubmit: (callback) => {
        hapusExc(item);
        callback();
      },
    });
  }
  //hapus unit
  async function hapusExcUnit(item) {
    try {
      var r = await service.deleteUnit(item.id);

      if (r.data.message == "sukses") {
        iziToast.success({
          message: r.data.message,
        });
      } else {
        iziToast.info({
          message: r.data.message,
        });
      }
      document.getElementsByClassName("mdi-reload")[0].click();
    } catch (e) {
      toast.errorRequest(e);
    }
  }
  async function hapusUnit(item) {
    modalStore.showConfirm({
      title: t.translate("words.delete"),
      children: t.translate("words.confirmHapus"),
      onSubmit: (callback) => {
        hapusExcUnit(item);
        callback();
      },
    });
  }
  return (
    <>
      <TabsWrapper
        showCommandbar={false}
        baseId="mod-rincian-setor-sendiri-sample"
        title={t.translate("modules.kategoriUnit.kategori.title")}
        tabs={[
          {
            label: t.translate("modules.kategoriUnit.kategori.title"),
            render: (
              <>
                <KategoriUnitTable
                  baseId={"produk-kategori"}
                  className={className}
                  title={t.translate("modules.kategoriUnit.kategori.title")}
                  onFetchData={(query) => {
                    return new Promise(async (resolve, reject) => {
                      try {
                        query = {
                          ...query,
                        };
                        let getPage = await service.getAllKategori(query);
                        getPage.headers["x-pagination-count"] =
                          getPage.headers["x-total-count"];
                        resolve(getPage);
                      } catch (e) {
                        resolve();
                      }
                    });
                  }}
                  actions={actions}
                  itemActions={itemActionsKategori}
                  showCommandbar={showCommandbar}
                  toDetail={`/internal/npwp/${npwpHeadId}/kategori-unit/detail-kategori/`}
                />
                <KategoriForm
                  visible={showForm}
                  onRequestClose={() => {
                    setShowForm(false);
                    setSelectedItemId(null);
                    document.getElementsByClassName("mdi-reload")[0].click();
                  }}
                  itemId={selectedItemId}
                />
              </>
            ),
          },
          {
            label: t.translate("modules.kategoriUnit.unit.title"),
            render: (
              <>
                <KategoriUnitTable
                  baseId={"produk-unit"}
                  className={className}
                  title={t.translate("modules.kategoriUnit.unit.title")}
                  onFetchData={(query) => {
                    return new Promise(async (resolve, reject) => {
                      try {
                        query = {
                          ...query,
                        };
                        let getPage = await service.getAllUnit(query);
                        getPage.headers["x-pagination-count"] =
                          getPage.headers["x-total-count"];
                        resolve(getPage);
                      } catch (e) {
                        resolve();
                      }
                    });
                  }}
                  actions={actions}
                  itemActions={itemActionsUnit}
                  showCommandbar={showCommandbar}
                  toDetail={`/internal/npwp/${npwpHeadId}/kategori-unit/detail-unit/`}
                />
                <UnitForm
                  visible={showForm}
                  onRequestClose={() => {
                    setShowForm(false);
                    setSelectedItemId(null);
                    document.getElementsByClassName("mdi-reload")[0].click();
                  }}
                  itemId={selectedItemId}
                />
              </>
            ),
          },
        ]}
      />
    </>
  );
};

export default inject("modalStore")(observer(KategoriUnit));
