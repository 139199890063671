import React, { useEffect, useState } from 'react';
import { DataForm } from '../../libs/react-mpk/components';
import t from 'counterpart'
import { FormWrapper, TableWrapper } from '../../libs/react-mpk/wrapper'
import service from './Pesanan.service'
import { useParams } from "react-router-dom";
import cloneDeep from 'lodash.clonedeep';

const defaultData = () => ({
	// id:null,
	status: null,
	noResi: null
})
const PesananInputResi = ({
	visible = false,
	authStore,
	envStore,
	item = null,
	onRequestClose = () => { },
	...props
}) => {
	let [ready, setReady] = useState(true)
	// let [item, setItem] = useState(null)
	let [dataSubmit, setDataSubmit] = useState({
		// id:"",
		noResi: "",
		status: 2,
	})
	
	useEffect(() => {
		async function initData(){
			if(visible){
				let selectedItem = defaultData();
				if(props.itemId){
					try {
						let res = await service.getOneById(props.itemId)
						let{data}=res
						setDataSubmit(data)
						selectedItem.status = data.status
						selectedItem.noResi = data.noResi
						
					} catch (error) {
						onRequestClose()
					}
				}
				else{

				}
				// setItem(selectedItem)
			}
			// else{
			// 	// setItem(defaultData())
			// }
			setReady(visible)
		}
		initData()
	}, [visible])

	return ready && (
		<DataForm
			baseId="pesanan-input-resi"
			title={t.translate(`modules.pesanan.inputResi`)}
			visible={visible}
			onRequestClose={onRequestClose}
			asDialog={true}
			defaultData={item}
			definitions={[
				// {
				// 	inputType: DataForm.inputTypes.INPUT_MASK_NUMBER,
				// 	label: t.translate(`words.status`),
				// 	key: 'status',
				// 	disabled: true,
				// },
				{
					inputType: DataForm.inputTypes.INPUT,
					label: t.translate(`words.inputResi`),
					key: 'noResi',
					disable: true,
					validation:'required'
				},
			]}
			onSubmit={async (data, callback) => {
				let reqData = dataSubmit
				// reqData.status  =data.id
				reqData.status = 2
				reqData.noResi = data.noResi
				try {
					const res = await service.editPengiriman(item.id, reqData);
					TableWrapper.reload("pesanan")
					// let { data, status } = res;
					callback('success', false)
				} catch (error) {
					callback(error, true, false);
				}
			}}
		/>
	)
}

export default PesananInputResi