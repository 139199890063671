import moment from "moment";
import 'moment/locale/id';
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Divider,
  FontIcon,
  List,
  ListFontIcon,
  SimpleListItem,
  Text,
  VerticalDivider,
} from "react-md";
import { DataForm } from "../../libs/react-mpk/components";
import { format, formatRupiah } from "../../libs/react-mpk/services/number.service";
import service from "./PPh.service";

const DetailListIdBilling = ({
  visible = false,
  authStore,
  envStore,
  onRequestClose = () => {},
  ...props
}) => {
  let [ready, setReady] = useState(true);

  useEffect(() => {
    async function initData() {
      setReady(visible);
    }
    initData();
  }, [visible]);

  return (
    ready && (
      <DataForm
        baseId="detail-idbilling-form"
        title="Detail "
        visible={visible}
        onRequestClose={onRequestClose}
        asDialog={true}
        defaultData={[]}
        style={{ maxWidth: 700, maxHeight: 500 }}
        definitions={[
          {
            render: (
              <>
                {props.judul == "SudahBayar" && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "100%",
                      marginBottom: 30,
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div> NTPN </div>
                      <div>
                        {" "}
                        {props.data?.ntpn.match(/.{1,4}/g).join(" ") || "---"}
                      </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Button
                        themeType="flat"
                        onClick={() => {
                          navigator.clipboard.writeText(props.data?.ntpn);
                        }}
                      >
                        <FontIcon
                          iconClassName="mdi mdi-content-copy"
                          style={{ fontSize: 30 }}
                        />
                      </Button>
                    </div>
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                    marginBottom: 30,
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div> ID Billing </div>
                    <div> {props.data?.idBilling || "---"}</div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Button
                      themeType="flat"
                      onClick={() => {
                        navigator.clipboard.writeText(props.data?.idBilling);
                      }}
                    >
                      <FontIcon
                        iconClassName="mdi mdi-content-copy"
                        style={{ fontSize: 30 }}
                      />
                    </Button>
                  </div>
                </div>
                {props.judul == "BelumBayar" && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "100%",
                      marginBottom: 30,
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div style={{ color: "#747d8c" }}>
                        {" "}
                        Berlaku Sampai Dengan{" "}
                      </div>
                      <div>
                        {" "}
                        {props.data?.expiredDate !== null
                          ? moment(props.data?.expiredDate).format(
                              "DD MMMM YYYY - hh:mm:ss"
                            )
                          : "---"}
                      </div>
                    </div>
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    marginBottom: 30,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                    }}
                  >
                    <div style={{ color: "#747d8c" }}> NPWP </div>
                    <div> {props.data?.npwp || "---"}</div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                    }}
                  >
                    <div style={{ color: "#747d8c" }}> Nama </div>
                    <div> {props.data?.namaNpwp || "---"}</div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    marginBottom: 30,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                    }}
                  >
                    <div style={{ color: "#747d8c" }}> Alamat </div>
                    <div> {props.data?.alamat || "---"}</div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    marginBottom: 30,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                    }}
                  >
                    <div style={{ color: "#747d8c" }}> Kota </div>
                    <div> {props.data?.kota || "---"}</div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    marginBottom: 30,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                    }}
                  >
                    <div style={{ color: "#747d8c" }}> Jenis Pajak </div>
                    <div> {props.data?.kodeJenisPajak || "---"}</div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                    }}
                  >
                    <div style={{ color: "#747d8c" }}> Jenis Setoran </div>
                    <div> {props.data?.kodeJenisSetoran || "---"}</div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                    }}
                  >
                    <div style={{ color: "#747d8c" }}>Masa Pajak </div>
                    <div> {props.data?.masaAwal || "---"}</div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    marginBottom: 30,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                    }}
                  >
                    <div style={{ color: "#747d8c" }}> Jumlah Setor </div>
                    <div> {formatRupiah(props.data?.nominalBayar)}</div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    marginBottom: 30,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                    }}
                  >
                    <div style={{ color: "#747d8c" }}>
                      {" "}
                      Nomor Objek Pajak (NOP){" "}
                    </div>
                    <div> {props.data?.nop || "---"}</div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                    }}
                  >
                    <div style={{ color: "#747d8c" }}> No. SK </div>
                    <div> {props.data?.noSk || "---"}</div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    marginBottom: 30,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                    }}
                  >
                    <div style={{ color: "#747d8c" }}> Uraian</div>
                    <div>{props.data?.uraian || "---"}</div>
                  </div>
                </div>
              </>
            ),
          },
        ]}
      />
    )
  );
};

export default DetailListIdBilling;
