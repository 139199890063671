import React ,{useState}from "react";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import {inject, observer} from "mobx-react";
import {useParams} from "react-router-dom"
import service from "./Biaya.service";


const PembelianKredit = ({ envStore, className = "", showCommandbar = true, title, ...props }) => {
  let [showForm, setShowForm] = useState(false);
  let [selectedItem, setSelectedItem] = useState(null)

  return (
    <>  
      <TableWrapper
        baseId="mod-table-pembelian-kredit"
        title='Kredit'
        backTo={`/${envStore.env.applicationType}/biaya`}
        className={className}
        defaultData={[]}
        defaultSortBy="createdDate"
        columns={[
          {
            label: "Nama Akun",
            // searchable: true,
            key: "name",
            render: item => (item.kredit.namaAkun),
          },
          {
            label: "Nominal",
            // searchable: true,
            key: "nominal",
            render: (item) => item.kredit.nominal,
          },
          {
            label: "Posisi",
            // searchable: true,
            key: "posisi",
            render: (item) => item.kredit.posisi,
          }
        ]}
        onFetchData={async query => {
          return service.get(query);
        }}
      />
    </>
  );
};

export default inject("modalStore", "envStore")(observer(PembelianKredit));