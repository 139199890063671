import React, { useState, useEffect } from 'react'
import { TableWrapper, TabsWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './PPN.service'
import _, { find } from 'lodash'
import PPNFakturMasukan from './PPNFakturMasukan'
import PPNFakturKeluaran from './PPNFakturKeluaran'

const basePath = `biaya`
const PPN = ({
    navigationStore,
    className = '',
    showCommandbar = true
}) => {
    return (
        <TabsWrapper
            showCommandbar={false}
            tabs={[
                {
                    label: t.translate(`words.fakturMasukan`),
                    render: (
                        <PPNFakturMasukan />
                    )
                },
                {
                    label: t.translate(`words.fakturKeluaran`),
                    render: (
                        <PPNFakturKeluaran />
                    )
                }
            ]}
        />
    )
}

export default inject("navigationStore", 'envStore')(observer(PPN))