import React, {useState} from 'react'
import { TabsWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import ReportNeracaHarta from './ReportNeraca/ReportNeracaHarta';
import ReportLabaRugiKomersial from './ReportLabaRugiKomersial/ReportLabaRugiKomersial';
import ReportNeracaSaldo from './ReportNeracaSaldo/ReportNeracaSaldo';
import ReportNeracaUtangDanModal from './ReportNeraca/ReportNeracaUtangDanModal';
import { useLocation } from 'react-router-dom';

const Report = ({
  className       = '',
  history,
  showCommandbar  = true,
  match,

  navigationStore
}) => {
  // console.log("🚀 ~ file: Report.js:19 ~ match", match)
	const { inputTypes, definition } = FormWrapper
  const [showForm, setShowForm] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
	const [inputValidasiNpwp, setInputValidasiNpwp] = useState('##.###.###.#-###.###')
  const [dialogNilaiTagihan, setDialogNilaiTagihan] = useState(false)
  const [dialogData, setDialogData] = useState(false)
  const [dokumens, setDokumens] = useState([])
  const { state: { passing,tahun } = {} } = useLocation();
  const basePath = `report`
  let npwpHeadId = sessionStorage.getItem('npwpHeadId');
  return (
    <>
    <TabsWrapper 
      showCommandbar={passing==true || tahun?true:false}
      backTo={passing == true?`/internal/npwp/${npwpHeadId}/tutup-buku`:`/internal/npwp/${npwpHeadId}/dashboard`}
      tabs={[

        // NERACA
        {
          label : t.translate('modules.report.neraca'),
          key:'neraca',
          render: (
            <>
            <TabsWrapper 
                showCommandbar={false}
                tabs={[
                    {
                        label : t.translate('modules.report.harta'),
                        key:'harta',
                        render: (
                            <>
                                <ReportNeracaHarta match={match}/>
                            </>
                        )
                    },
                    {
                        label : t.translate('modules.report.utangDanModal'),
                        key:'utangDanModal',
                        render: (
                            <>
                                <ReportNeracaUtangDanModal match={match}/>
                            </>
                        )
                    },
                ]}
            />,
        </>
        )},
        {
            label : t.translate('modules.report.labaRugiKomersial'),
            key:'labaRugiKomersial',
            render: (
                <>
                    <ReportLabaRugiKomersial match={match}/>
                </>
            )
        },
        {
            label : t.translate('modules.report.neracaSaldo'),
            key:'neracaSaldo',
            render: (
                <>
                    <ReportNeracaSaldo match={match}/>
                </>
            )
        },
        ]}
    />
    </>
  )

  function _closeDialog() {
    setDialogNilaiTagihan(false)
  }

  function _setDokumens(dokumens){
    setDokumens(dokumens)
  }
}

export default Report