import React, { useEffect, useState } from "react";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import { DataForm } from "../../libs/react-mpk/components";
import { inputTypes } from "../../libs/react-mpk/config/constant";
import service from "./PPN.service";

const defaultData = {
  sobatMelakukanPembayaranId: null,
  sobatMelakukanPembayaran: null,
  sobatMelakukanPemabayaranTgl: null,
  jenisPembelian: null,
};

const PPNPilihJenisPengeluaran = ({
  visible = false,
  navigationStore,
  onRequestClose = () => {},
}) => {
  const [item, setItem] = useState(defaultData);
  const [akunList, setAkunList] = useState([]);
  let [ready, setReady] = useState(true);

  let npwpHeadId = sessionStorage.getItem("npwpHeadId");

  useEffect(() => {
    if (visible) {
      getAkun();
    } else {
      setItem(defaultData);
    }
    setReady(visible);
  }, [visible]);

  async function getAkun() {
    let res = await service.getAkun();
    var akuns = [];
    res.data.map((d) => {
      akuns.push({
        value: d.id,
        label: `${d.kode} - ${d.nama}`,
      });
    });
    setAkunList(akuns);
  }

  return (
    ready && (
      <DataForm
        baseId="ppn-pilih-jenis-pengeluaran"
        title={t.translate(`modules.ppn.jenisPembelian`)}
        visible={visible}
        onRequestClose={onRequestClose}
        asDialog={true}
        defaultData={item}
        definitions={[
          {
            inputType: inputTypes.AUTOCOMPLETE,
            label: t.translate(`words.sobatMelakukanPembayaranAkun`),
            key: "sobatMelakukanPembayaranId",
            labelKey: "label",
            valueKey: "label",
            type: "search",
            data: akunList,
            validation: "required",
          },
          {
            inputType: inputTypes.DATEPICKER,
            label: t.translate(`words.sobatMelakukanPemabayaranTgl`),
            searchable: true,
            key: "sobatMelakukanPemabayaranTgl",
          },
          {
            inputType: inputTypes.DIVIDER,
            label: t.translate(`words.jenisPembelian`),
          },
          {
            inputType: inputTypes.SELECT,
            label: t.translate(`words.jenisPembelian`),
            key: "jenisPembelian",
            options: [
              {
                label: t.translate(`words.pembelianBarangDagang`),
                value: "pembelianBarangDagang",
              },
              { label: t.translate(`words.biaya`), value: "biaya" },
            ],
            required: true,
            validation: "required",
          },
        ]}
        onChange={async (data, key, value) => {
          if (key == "sobatMelakukanPembayaranId") {
            akunList.map((d) => {
              if (d.label == value) {
                data["sobatMelakukanPembayaran"] = d.value;
              }
            });
          }
          setItem(data);
          return data;
        }}
        onSubmit={(data, callback) => {
          if (data.jenisPembelian === "pembelianBarangDagang") {
            navigationStore.redirectTo(
              `/internal/npwp/${npwpHeadId}/pembelian`
            );
          } else if (data.jenisPembelian === "biaya") {
            navigationStore.redirectTo(`/internal/npwp/${npwpHeadId}/biaya`);
          }
          setTimeout(callback, 2000);
        }}
      />
    )
  );
};

export default inject(
  "navigationStore",
  "envStore"
)(observer(PPNPilihJenisPengeluaran));
