import React, { useEffect, useState } from "react";
import { inputTypes } from "../../libs/react-mpk/config/constant";
import { FormWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import { find } from "lodash";
import { Hint } from "../../libs/react-mpk/components";
import { Button } from "@react-md/button";
import { FontIcon } from "@react-md/icon";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableRow,
} from "@react-md/table";
import Modal from "../../libs/react-mpk/components/Modal/Modal";
import service from "./Pembelian.service";
import {
  defaultQueryParameter,
  defauultQuery,
  sort,
} from "../../libs/react-mpk/config/constant";
import { http, toast } from "../../libs/react-mpk/services";

// const defaultQuery = (query) => ({ ...defaultQueryParameter(), ...query });

const masa = [
  { value: "1", name: "1" },
  { value: "2", name: "2" },
  { value: "3", name: "3" },
  { value: "4", name: "4" },
  { value: "5", name: "5" },
  { value: "6", name: "6" },
  { value: "7", name: "7" },
  { value: "8", name: "8" },
  { value: "9", name: "9" },
  { value: "10", name: "10" },
  { value: "11", name: "11" },
  { value: "12", name: "12" },
];

const tahun = [
  { value: "2014", name: "2014" },
  { value: "2015", name: "2015" },
  { value: "2016", name: "2016" },
  { value: "2017", name: "2017" },
  { value: "2018", name: "2018" },
  { value: "2019", name: "2019" },
  { value: "2020", name: "2020" },
  { value: "2021", name: "2021" },
];

const defaultData = () => ({
  masa: null,
  tahun: null,
});

const PembelianPPN = ({ envStore, match, ...props }) => {
  const baseName = "pembelian";
  const { inputTypes, definition } = FormWrapper;
  const [data, setData] = useState(defaultData);
  const [dialogPembelianProdukForm, setDialogPembelianProdukForm] =
    useState(false);
  const [dialogBebanForm, setDialogBebanForm] = useState(false);
  const [dialogData, setDialogData] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [dataProduk, setDataProduk] = useState([]);
  const [query, setQuery] = useState({});
  const [masaForm, setMasaForm] = useState(1);
  const [tahunForm, setTahunForm] = useState(2022);
  const [loading, setLoading] = useState(true);
  let [listTahun, setListTahun] = useState([]);
  const [nomorFakturForm, setNomorFakturForm] = useState("");

  // const [query2, setQuery2] = useState(
  //     defaultQuery({
  //       masa: "",
  //       tahun: "",
  //     })
  //   );

  function _closeDialog() {
    setDialogPembelianProdukForm(false);
  }

  // var dataProduk = [
  //     {
  //         tglFaktur: "23/08/2021",
  //         masa: "08",
  //         tahun: "2021",
  //         noFaktur: '004-15.49389727',
  //         nama: 'Desi Putri',
  //         dpp: 'Rp.500.000',
  //         ppn: 'Rp.500.000',
  //         status: 'Selesai'
  //     }
  // ];

  function verifikasi(e, idx) {
    if (dataProduk[idx].status == "Belum Approve") {
      window.location.href = `new/identitas/${dataProduk[idx].nomorFaktur}`;
    } else {
      window.location.href = `${true}/identitas/${dataProduk[idx].nomorFaktur}`;
    }

    sessionStorage.setItem("dataPembelianPPN", JSON.stringify(dataProduk[idx]));
  }
  async function year() {
    let minOffset = 0,
      maxOffset = 9;
    let thisYear = new Date().getFullYear() - 1;
    let allYears = [];
    for (let x = minOffset; x <= maxOffset; x++) {
      allYears.push({ value: String(thisYear - x), label: thisYear - x });
    }
    setListTahun(allYears);
  }

  async function tarikData() {
    let tempQuery = {
      pageNum: 0,
      pageSize: 9999,
      npwpHeadId: sessionStorage.getItem("npwpHeadId"),
      masa: masaForm,
      tahun: tahunForm,
      nomorFaktur: nomorFakturForm,
    };

    try {
      let res = await service.getPrepopulated(tempQuery);
      setDataProduk(res.data);
      dataProduk = sessionStorage.setItem("dataPPN", res.data);
    } catch (error) {
      toast.errorRequest(error)
    }
    // let dataTarikan = [
    //     {
    //         tglFaktur: "10/06/2021",
    //         masa: "10",
    //         tahun: "2021",
    //         noFaktur: '0002100097694',
    //         nama: 'PT MITRA PAJAKKU',
    //         dpp: '60000000',
    //         ppn: '6000000',
    //         status: 'Selesai'
    //     },
    //     {
    //         tglFaktur: "10/06/2021",
    //         masa: "10",
    //         tahun: "2021",
    //         noFaktur: '0002101277121',
    //         nama: 'PT MITRA PAJAKKU',
    //         dpp: '1000000',
    //         ppn: '100000',
    //         status: 'Selesai'
    //     },
    //     {
    //         tglFaktur: "10/06/2021",
    //         masa: "10",
    //         tahun: "2021",
    //         noFaktur: '0002101276672',
    //         nama: 'PT MITRA PAJAKKU',
    //         dpp: '1000000',
    //         ppn: '100000',
    //         status: 'Selesai'
    //     },
    //     {
    //         tglFaktur: "10/06/2021",
    //         masa: "10",
    //         tahun: "2021",
    //         noFaktur: '0002101277136',
    //         nama: 'PT MITRA PAJAKKU',
    //         dpp: '1000000',
    //         ppn: '100000',
    //         status: 'Selesai'
    //     },
    //     {
    //         tglFaktur: "10/06/2021",
    //         masa: "10",
    //         tahun: "2021",
    //         noFaktur: '0002101277133',
    //         nama: 'PT MITRA PAJAKKU',
    //         dpp: '1000000',
    //         ppn: '100000',
    //         status: 'Selesai'
    //     }
    // ]
    // setDataProduk(dataTarikan)
  }

  function addItem() {
    setDialogPembelianProdukForm(true);
    setDialogData({});
  }

  useEffect(() => {
    async function initData() {
      var pembelianForm = JSON.parse(localStorage.getItem("pembelianForm"));
      localStorage.setItem(
        "pembelianForm",
        JSON.stringify({
          sptNpwp: "",
          sptMonth: "",
          sptYear: "",
          sptRev: "",
          signerName: "",
          signerNpwp: "",
          actAs: "",
          signerId: "",
          refs: [],
          address: "-",
          phone: "0",
          postalCode: "0",
          province: "-",
          city: "-",
          district: "-",
          subDistrict: "-",
          withoutIncome: true,
          bruto: "0",
          rates: "0",
          ratesValue: "0",
          ...pembelianForm,
        })
      );
      setLoading(false);
      // if(match.params.id == 'new') {
      //     var penjualanForm = JSON.parse(localStorage.getItem("penjualanForm"))
      //     localStorage.setItem("penjualanForm", JSON.stringify({
      //         sptNpwp: spt.npwp,
      //         sptMonth: spt.month + "",
      //         sptYear: spt.year + "",
      //         sptRev: spt.rev + "",
      //         signerName: getLawanTransaksi.name,
      //         signerNpwp: getLawanTransaksi.npwp,
      //         actAs: getLawanTransaksi.actAs + "",
      //         signerId: getLawanTransaksi.identity,
      //         refs: [],
      //         address: "-",
      //         phone: "0",
      //         postalCode: "0",
      //         province: "-",
      //         city: "-",
      //         district: "-",
      //         subDistrict: "-",
      //         withoutIncome: true,
      //         bruto: "0",
      //         rates: "0",
      //         ratesValue: "0",
      //         ...penjualanForm
      //     }))
      //     setData({ content: {}, loading: false })
      // } else {
      //     // alamat, telepon,propinsi,kota,kecamatan,kelurahan,dan kode post
      //     const res = await service.getOne(match.params.id)
      //     res.data.signerId = res.data.identity
      //     res.data.bruto = res.data.bruto + ""
      //     res.data.pph   = res.data.pph + ""
      //     res.data.rates = res.data.rates + ""
      //     res.data.ratesValue = res.data.rates + ""
      //     res.data.identityAs = res.data.identityAs + ""
      //     res.data.actAs = res.data.signAs + ""
      //     res.data.signAggree = res.data.signAggree + ""
      //     res.data.form4valid = true
      //     res.data.form3valid = true
      //     res.data.form2valid = true
      //     res.data.form1valid = true
      //     localStorage.setItem("penjualanForm", JSON.stringify(res.data))
      //     setData({ content: {}, loading: false })
      // }
    }
    initData();
    year();
  }, []);

  return (
    <>
      <FormWrapper
        submitLabel={t.translate("words.submit")}
        loading={loading}
        title="Pembelian PPn"
        showCommandbar={true}
        baseId={`mod-form-${baseName}`}
        style={{
          maxWidth: "100%",
        }}
        // defaultData={[]}
        definitions={[
          {
            render: (
              <div style={{ width: "100%" }}>
                <FormWrapper
                  loading={loading}
                  showCommandbar={false}
                  baseId={`mod-form-${baseName}`}
                  style={{
                    maxWidth: "100%",
                  }}
                  defaultData={data}
                  onChange={(data, key, value) => {
                    if (key == "masa") {
                      setMasaForm(value);
                    }
                    if (key == "tahun") {
                      setTahunForm(value);
                    }
                    if (key == "nomorFaktur") {
                      setNomorFakturForm(value);
                    }
                  }}
                  definitions={[
                    {
                      inputType: inputTypes.SELECT,
                      label: "Masa",
                      key: "masa",
                      type: "text",
                      width: "25%",
                      style: {
                        marginRight: "2%",
                      },
                      options: masa.map((d) => ({
                        children: d.name,
                        value: d.value,
                      })),
                    },
                    {
                      inputType: inputTypes.SELECT,
                      label: "Tahun",
                      key: "tahun",
                      type: "text",
                      width: "25%",
                      style: {
                        marginRight: "2%",
                      },
                      options: listTahun,
                    },
                    {
                      inputType: inputTypes.INPUT,
                      label: "Nomor Faktur Pajak",
                      key: "nomorFaktur",
                      type: "text",
                      width: "25%",
                      style: {
                        marginRight: "2%",
                      },
                    },
                    {
                      render: (
                        <Button
                          theme="primary"
                          themeType="contained"
                          onClick={tarikData}
                        >
                          Tarik Data
                        </Button>
                      ),
                    },
                  ]}
                />
                <TableContainer>
                  <Table style={{ width: "100%" }}>
                    <TableHeader>
                      <TableRow>
                        <TableCell>{t.translate("words.non")}</TableCell>
                        <TableCell>Tanggal Faktur</TableCell>
                        <TableCell>Masa Terbit</TableCell>
                        <TableCell>Tahun Terbit</TableCell>
                        <TableCell>No Faktur</TableCell>
                        <TableCell>Nama Penjual</TableCell>
                        <TableCell>DPP</TableCell>
                        <TableCell>PPN</TableCell>
                        <TableCell>Status</TableCell>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {dataProduk.map((d, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{d.tanggalFaktur}</TableCell>
                            <TableCell>{d.masaPajak}</TableCell>
                            <TableCell>{d.tahunPajak}</TableCell>
                            <TableCell>{d.nomorFaktur}</TableCell>
                            <TableCell>{d.namaPembeli}</TableCell>
                            <TableCell>{d.jumlahDpp}</TableCell>
                            <TableCell>{d.jumlahPpn}</TableCell>
                            <TableCell>{d.status}</TableCell>
                            <TableCell>
                              {/* <Button onClick={()=> console.log("Masuk")}>{t.translate("words.edit")}</Button> */}
                              <Button
                                theme="primary"
                                themeType="contained"
                                onClick={(e) => verifikasi(e, index)}
                              >
                                Verifikasi
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            ),
          },
          {
            render: <div style={{ margin: 10 }}></div>,
          },
        ]}
      />
    </>
  );
};

export default inject("envStore")(observer(PembelianPPN));
