import React from "react";
import { Button, CircularProgress, FontIcon } from "react-md";

export default function AsyncButton({
  loading = false,
  onClickDownload = () => {},
  labelButton = "",
  themeType = "",
  iconClassName = "",
  theme = "",
  LabelLoading = "",
  showIcon = false,
}) {
  return (
    <Button
      themeType={themeType ? themeType : "outline"}
      theme={theme ? theme : "secondary"}
      disabled={loading}
      onClick={onClickDownload}
    >
      {loading == true ? (
        <>
          <CircularProgress />
          &nbsp;&nbsp;{LabelLoading ? LabelLoading : "Loading . . ."}
        </>
      ) : (
        <>
          {showIcon == true && (
            <FontIcon
              iconClassName={iconClassName ? iconClassName : "mdi mdi-download"}
            />
          )}
          {labelButton}
        </>
      )}
    </Button>
  );
}
