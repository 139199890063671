import React, { useEffect, useState } from "react";
import { DataForm, Hint } from "../../libs/react-mpk/components";
import t from "counterpart";
import { FormWrapper, TableWrapper } from "../../libs/react-mpk/wrapper";
import {
  Table,
  TableBody,
  TableHeader,
  TableRow,
  TableCell,
  Button,
  FontIcon,
  TableFooter,
} from "react-md";
import service from "./AkunCoa.service";
import AkunCoaFormPemilikModalForm from "./AkunCoaFormPemilikModalForm";
import { inject, observer } from "mobx-react";
import { cloneDeep } from "lodash";
import { disable } from "react-md";
import { toast } from "../../libs/react-mpk/services";

const defaultData = () => ({
  id: null,
  kodeAkun: null,
  kategoriAkunId: null,
  namaAkun: null,
  kategoriAsetId: null,
  kategoriHutangId: null,
  defaultKoreksiFiskal: false,
  koreksiFiskalId: null,
  monitoringLawanTransaksi: false,
  inputNominatifEntertaint: false,
  inputNominatifPromosi: false,
  // saldoAwal: null,
  kategoriAkun: {
    id: null,
  },
  kategoriAkunNama: "",
  kategoriAset: {
    id: null,
  },
  kategoriAsetNama: "",
  kategoriHutang: {
    id: null,
  },
  kategoriHutangNama: "",
  koreksiFiskal: {
    id: null,
  },
  tahun: new Date().getFullYear(),
});

const AkunCoaForm = ({
  visible = false,
  authStore,
  envStore,
  modalStore,
  match,
  history,
  kasDanBank = "",
  onRequestClose = () => {},
  ...props
}) => {
  const { inputTypes } = FormWrapper;
  //JENIS KATEGORI ASET
  let AST_ASET = 1;
  let AST_HUTANG = 2;
  let AST_BEBAN = 4;
  let AST_MODAL = 5;

  const [kategoriAkun, setKategoriAkun] = useState([]);
  const [kategoriAsetList, setKategoriAsetList] = useState([]);
  const [kategoriHutangList, setKategoriHutangList] = useState([]);
  const [loadingAkun, setLoadingAkun] = useState(false);
  const [loadingAset, setLoadingAset] = useState(false);
  const [loadingHutang, setLoadingHutang] = useState(false);
  let [queryAkun, setQueryAkun] = useState("");
  let [queryAset, setQueryAset] = useState("");
  let [queryHutang, setQueryHutang] = useState("");
  const [pemilikModalList, setPemilikModalList] = useState([]);

  let [dataSubmit, setDataSubmit] = useState({
    id: null,
    kode: "",
    nama: "",
    monitoringLawanTransaksi: false,
    monitoringPersediaan: false,
    inputNominatifEntertaint: false,
    inputNominatifPromosi: false,
    defaultKoreksiFiskal: false,
    kategoriAkun: {
      id: null,
    },
    kategoriAset: {
      id: null,
    },
    kategoriHutang: {
      id: null,
    },
    npwpHead: {
      id: null,
    },
    koreksiFiskal: {
      id: null,
    },
    tahun: new Date().getFullYear(),
  });
  let [item, setItem] = useState(null);
  let [ready, setReady] = useState(true);
  const [validasiKategoriAkun, setValidasiKategoriAkun] = useState("");
  const [showFormPemilikModal, setShowFormPemilikModal] = useState(false);

  useEffect(() => {
    async function initData() {
      if (visible) {
        let selectedItem = defaultData();
        if (kasDanBank != "") {
          let res1 = await service.getListKategoriAkun(kasDanBank);
          let res = await service.generateKodeAkun(res1.data[0].id);
          selectedItem.kategoriAkunId = res1.data[0].id;
          selectedItem.kodeAkun = res.data.message;
          if(res1.data[0].kategoriAkun){

            setValidasiKategoriAkun(res1.data[0].kategoriAkun.kategoriAset)
          }
        }

        if (props.itemId) {
          try {
            let res = await service.getOneById(props.itemId);

            let { data } = res;

            setDataSubmit(data);

            selectedItem.id = data.id;
            selectedItem.kodeAkun = data.kode;
            if (data.kategoriAkun) {
              selectedItem.kategoriAkunId = data.kategoriAkun.id;
              selectedItem.kategoriAkunNama = data.kategoriAkun.nama;
              setValidasiKategoriAkun(data.kategoriAkun.kategoriAset);
            }
            selectedItem.namaAkun = data.nama;
            if (data.kategoriAset) {
              selectedItem.kategoriAsetId = data.kategoriAset.id;
              selectedItem.kategoriAsetNama = data.kategoriAset.nama;
            }
            if (data.kategoriHutang) {
              selectedItem.kategoriHutangId = data.kategoriHutang.id;
              selectedItem.kategoriHutangNama = data.kategoriHutang.nama;
            }
            selectedItem.defaultKoreksiFiskal = data.defaultKoreksiFiskal;
            if (data.koreksiFiskal) {
              selectedItem.koreksiFiskalId = `${data.koreksiFiskal.kode} - ${data.koreksiFiskal.nama}`;
              selectedItem.koreksiFiskal.id = data.koreksiFiskal.id;
            }
            selectedItem.monitoringLawanTransaksi =
              data.monitoringLawanTransaksi;
            selectedItem.inputNominatifEntertaint =
              data.inputNominatifEntertaint;
            selectedItem.inputNominatifPromosi = data.inputNominatifPromosi;
          } catch (e) {
            onRequestClose();
          }
        }
        getKategoriAkun(selectedItem.kategoriAkunNama);
        getKategoriAset(selectedItem.kategoriAsetNama);
        getKategoriHutang(selectedItem.kategoriHutangNama);
        setItem(selectedItem);
      } else {
        getKategoriAkun("");
        getKategoriAset("");
        getKategoriHutang("");
        setValidasiKategoriAkun("");
        setItem(defaultData());
      }
      setReady(visible);
    }
    initData();

    // getKoreksiFiskal()
  }, [visible]);

  useEffect(() => {
    setLoadingAkun(true);
    const timeOutId = setTimeout(() => getKategoriAkun(queryAkun), 500);
    return () => clearTimeout(timeOutId);
  }, [queryAkun]);

  useEffect(() => {
    setLoadingAset(true);
    const timeOutId = setTimeout(() => getKategoriAset(queryAset), 500);
    return () => clearTimeout(timeOutId);
  }, [queryAset]);

  useEffect(() => {
    setLoadingHutang(true);
    const timeOutId = setTimeout(() => getKategoriHutang(queryHutang), 500);
    return () => clearTimeout(timeOutId);
  }, [queryHutang]);

  async function getKategoriAkun(queryNama) {
    try {
      setLoadingAkun(true);
      let res = await service.getListKategoriAkun(queryNama);
      var kategoriAkuns = [];
      res.data.map((d) => {
        kategoriAkuns.push({
          value: d.id,
          label: `${d.kode} - ${d.nama}`,
          kategoriAset: d.kategoriAset,
        });
      });
      setKategoriAkun(kategoriAkuns);
      setLoadingAkun(false);
    } catch (error) {
      toast.errorRequest(error);
      setLoadingAkun(false);
    }
  }

  async function getKategoriAset(queryNama) {
    try {
      setLoadingAset(true);

      let res = await service.getListKategoriAset(queryNama);
      var kategoriAsets = [];
      res.data.map((d) => {
        kategoriAsets.push({
          value: d.id,
          label: `${d.kode} - ${d.nama}`,
          // rates: d.parentKategori
        });
      });
      setKategoriAsetList(kategoriAsets);
      setLoadingAset(false);
    } catch (error) {
      toast.errorRequest(error);
      setLoadingAset(false);
    }
  }

  async function getKategoriHutang(queryNama) {
    try {
      setLoadingHutang(true);
      let res = await service.getListKategoriHutang(queryNama);
      var kategoriHutangs = [];
      res.data.map((d) => {
        kategoriHutangs.push({
          value: d.id,
          label: `${d.kode} - ${d.nama}`,
          // rates: d.parentKategori
        });
      });
      setKategoriHutangList(kategoriHutangs);
      setLoadingHutang(false);
    } catch (error) {
      toast.errorRequest(error);
      setLoadingHutang(false);
    }
  }

  async function addKepemilikanModal() {
    var kepemilikanModalForm = JSON.parse(
      localStorage.getItem("kepemilikanModalForm")
    );
    if (kepemilikanModalForm) {
      var pemilikModalListNew = pemilikModalList;

      kepemilikanModalForm.jenisNpwp = kepemilikanModalForm.npwpOpInt;

      pemilikModalListNew.push(kepemilikanModalForm);

      setPemilikModalList(pemilikModalListNew);

      localStorage.removeItem("kepemilikanModalForm");
    }
  }

  async function deleteKepemilikanModal(index) {
    modalStore.showConfirm({
      title: t.translate("words.delete"),
      children: t.translate("words.confirmHapus"),
      onSubmit: (callback) => {
        var pemilikModalListNew = pemilikModalList;
        pemilikModalListNew.splice(index, 1);
        setPemilikModalList(pemilikModalListNew);
        callback();
      },
    });
  }

  const onSubmit = async (data, callback) => {
    let reqData = dataSubmit;
    reqData.id = data.id;
    reqData.kode = data.kodeAkun;
    reqData.nama = data.namaAkun;
    reqData.monitoringLawanTransaksi = data.monitoringLawanTransaksi;
    reqData.monitoringPersediaan = data.monitoringPersediaan;
    reqData.inputNominatifEntertaint = data.inputNominatifEntertaint;
    reqData.inputNominatifPromosi = data.inputNominatifPromosi;
    reqData.defaultKoreksiFiskal = data.defaultKoreksiFiskal;
    // reqData.pemilikModalList = pemilikModalList;
    reqData.pemilikModalList =[]

    reqData.kategoriAset = reqData.kategoriAset ? reqData.kategoriAset : {};
    reqData.kategoriHutang = reqData.kategoriHutang
      ? reqData.kategoriHutang
      : {};

    if (reqData.kategoriAkun)
      reqData.kategoriAkun.id = data.kategoriAkunId
        ? data.kategoriAkunId
        : null;

    if (!reqData.monitoringPersediaan) reqData.monitoringPersediaan = false;

    if (reqData.kategoriAset) {
      reqData.kategoriAset.id = data.kategoriAset.id
        ? data.kategoriAset.id
        : null;
    }

    if (reqData.kategoriHutang) {
      reqData.kategoriHutang.id = data.kategoriHutang.id
        ? data.kategoriHutang.id
        : null;
    }
    reqData.koreksiFiskal = null;
    reqData.npwpHead.id = sessionStorage.getItem("npwpHeadId");
    reqData.tahun = sessionStorage.getItem("tahunSobatBuku");

    if (reqData.id) {
      try {
        const res = await service.update(reqData);
        let { data, status } = res;
        if (status == 200) {
          TableWrapper.reload("akun-coa");
        }
        callback("success", false);
      } catch (err) {
        // console.log(err, 'err')
        callback(err, true, false);
      }
    } else {
      try {
        const res = await service.create(reqData);
        let { data, status } = res;
        if (status == 200) {
          TableWrapper.reload("akun-coa");
        }
        callback("success", false);
      } catch (err) {
        callback(err, true, false);
      }
    }
    // console.log(reqData)
  };

  return (
    ready && (
      <>
        <DataForm
          baseId="akun-coa-form"
          title="Akun Form"
          visible={visible}
          onRequestClose={onRequestClose}
          asDialog={true}
          defaultData={item}
          style={{ width: "150vh" }}
          definitions={[
            {
              inputType: DataForm.inputTypes.INPUT,
              label: t.translate(`words.namaAkun`),
              validation: "required",
              key: "namaAkun",
            },
            {
              inputType: DataForm.inputTypes.REACT_SELECT,
              isClearable: true,
              label: t.translate(`words.kategoriAkun`),
              key: "kategoriAkunId",
              validation: "required",
              tooltip:
              kasDanBank == "" &&"Jika Akun tidak ditemukan,<br> silahkan ketik nama Akun yang ingin dicari<br> pada kolom Kategori Akun",
              labelKey: "label",
              valueKey: "value",
              options: kategoriAkun,
              isLoading: loadingAkun,
              isDisabled:kasDanBank != ""?true:false,
              onInputChange: (value) => {
                if (value) setQueryAkun(value);
              },
            },
            {
              inputType: DataForm.inputTypes.INPUT,
              label: t.translate(`words.kodeAkun`),
              key: "kodeAkun",
              disabled: true,
            },
            // {
            //   inputType: DataForm.inputTypes.REACT_SELECT,
            // isClearable:true,
            //   label: t.translate(`words.kategoriAset`),
            //   key: "kategoriAsetId",
            //   labelKey: "label",
            //   valueKey: "value",
            //   options: kategoriAsetList,
            //   isLoading: loadingAset,
            //   onInputChange: (value) => {
            //     if (value) setQueryAset(value);
            //   },
            //   show: validasiKategoriAkun === AST_ASET,
            // },
            // {
            //   inputType: DataForm.inputTypes.REACT_SELECT,
            // isClearable:true,
            //   label: t.translate(`words.kategoriHutang`),
            //   key: "kategoriHutangId",
            //   labelKey: "label",
            //   valueKey: "value",
            //   options: kategoriHutangList,
            //   isLoading: loadingHutang,
            //   onInputChange: (value) => {
            //     if (value) setQueryHutang(value);
            //   },
            //   show: validasiKategoriAkun === AST_HUTANG,
            // },
            // {
            //   render: (
            //     <div>
            //       <p style={{ marginLeft: "10px", marginBottom: "-15px" }}>
            //         <b>{t.translate(`words.kategoriMonitoring`)}</b>
            //       </p>
            //       <br />
            //     </div>
            //   ),
            //   show: validasiKategoriAkun === AST_BEBAN,
            // },
            // {
            //   inputType: inputTypes.CHECKBOX,
            //   label: t.translate(`words.monitoringLawanTransaksi`),
            //   key: "monitoringLawanTransaksi",
            //   name: "monitoringLawanTransaksi",
            //   style: {
            //     width: "100%",
            //   },
            //   show: validasiKategoriAkun === AST_BEBAN,
            // },
            // {
            //   inputType: inputTypes.CHECKBOX,
            //   label: t.translate(`words.inputNominatifEntertaint`),
            //   key: "inputNominatifEntertaint",
            //   name: "inputNominatifEntertaint",
            //   style: {
            //     width: "100%",
            //   },
            //   show: validasiKategoriAkun === AST_BEBAN,
            // },
            // {
            //   inputType: inputTypes.CHECKBOX,
            //   label: t.translate(`words.inputNominatifPromosi`),
            //   key: "inputNominatifPromosi",
            //   name: "inputNominatifPromosi",
            //   style: {
            //     width: "100%",
            //   },
            //   show: validasiKategoriAkun === AST_BEBAN,
            // },
            // {
            //   render: (
            //     <div style={{ width: "100%" }}>
            //       <Button
            //         theme="primary"
            //         themeType="contained"
            //         style={{ margin: 15 }}
            //         onClick={() => {
            //           setShowFormPemilikModal(true);
            //         }}
            //       >
            //         <FontIcon iconClassName="mdi mdi-plus" />
            //         {t.translate("words.add")}
            //       </Button>
            //       <Hint
            //         className="mpk-margin-N margin-top margin-bottom"
            //         message="Komposisi Kepemilikan Modal"
            //       />
            //       <Table style={{ width: "100%" }}>
            //         <TableHeader>
            //           <TableRow>
            //             <TableCell style={{ width: "10%" }}>
            //               {t.translate("words.non")}
            //             </TableCell>
            //             <TableCell style={{ width: "25%" }}>
            //               {t.translate("words.nama")}
            //             </TableCell>
            //             <TableCell style={{ width: "25%" }}>
            //               {t.translate("words.jumlah")}
            //             </TableCell>
            //             <TableCell style={{ width: "25%" }}>
            //               {t.translate("words.persentase")}
            //             </TableCell>
            //           </TableRow>
            //         </TableHeader>
            //         <TableBody>
            //           {pemilikModalList.map((d, index) => {
            //             return (
            //               <TableRow key={index}>
            //                 <TableCell>{index + 1}</TableCell>
            //                 <TableCell>{d.nama}</TableCell>
            //                 <TableCell>{d.nominalModal}</TableCell>
            //                 <TableCell>{d.ptsModal}</TableCell>
            //                 <TableCell>
            //                   <Button
            //                     onClick={() => deleteKepemilikanModal(index)}
            //                   >
            //                     {t.translate("words.delete")}
            //                   </Button>
            //                 </TableCell>
            //               </TableRow>
            //             );
            //           })}
            //         </TableBody>
            //       </Table>
            //       <br />
            //     </div>
            //   ),
            //   show: validasiKategoriAkun === AST_MODAL,
            // },
          ]}
          onChange={async (formData, key, value) => {
            if (key == "kategoriAkunId") {
              kategoriAkun.map((d) => {
                if (d.value == value) {
                  formData["kategoriAkun"]["id"] = d.value;
                  setValidasiKategoriAkun(d.kategoriAset);
                }
              });
              if (formData["kategoriAkun"]["id"]) {
                let res = await service.generateKodeAkun(
                  formData["kategoriAkun"]["id"]
                );
                formData["kodeAkun"] = res.data.message;
              }
            }

            // if (key == "kategoriAsetId") {
            //   kategoriAsetList.map((d) => {
            //     if (d.value == value) {
            //       formData["kategoriAset"]["id"] = d.value;
            //     }
            //   });
            // }

            // if (key == "kategoriHutangId") {
            //   kategoriHutangList.map((d) => {
            //     if (d.value == value) {
            //       // console.log('sama');
            //       formData["kategoriHutang"]["id"] = d.value;
            //     }
            //   });
            // }
            setItem(formData);
            return formData;
          }}
          onSubmit={onSubmit}
        />
        <AkunCoaFormPemilikModalForm
          visible={showFormPemilikModal}
          onRequestClose={() => {
            addKepemilikanModal();
            setShowFormPemilikModal(false);
            // setSelectedItemId(null)
          }}
          // item={selectedItem}
        />
      </>
    )
  );
};

export default inject(
  "navigationStore",
  "envStore",
  "modalStore"
)(observer(AkunCoaForm));
