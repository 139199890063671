import React, { useEffect, useState } from "react";
import { inputTypes } from "../../libs/react-mpk/config/constant";
import { FormWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import { find } from "lodash";
import {
  ExpansionList,
  ExpansionPanel,
  Divider,
  Text,
  TextContainer,
} from "react-md";
import service from "./Biaya.service";
import moment from "moment";
import "moment/locale/id";
import utilsService from "../../services/utilsService";
import {
  format,
  formatRupiah,
} from "../../libs/react-mpk/services/number.service";
import { toast } from "../../libs/react-mpk/services";
const STAT_BELUM_BAYAR = 1;
const STAT_LUNAS = 3;
const statusBayar = [
  { value: STAT_LUNAS, name: "Lunas" },
  { value: STAT_BELUM_BAYAR, name: "Belum Dibayar" },
];

const defaultData = () => ({
  lawanTransaksiId: "",
  lawanTransaksiNama: "",
  lawanTransaksiNik: "",
  lawanTransaksiNpwp: "",
  lawanTransaksiAlamat: "",
  lawanTransaksi: {
    id: null,
  },
  tanggalTransaksi: null,
  pembayaranStatus: STAT_LUNAS.toString(),
  pembayaranAkunId: null,
  pembayaranAkun: {
    id: null,
  },
  keterangan: null,
  fileLampiran: null,
  daftarBiaya: [
    {
      nilaiPembelian: 0,
      isPotongPajak: false,
      ptsTarifPajak: 0,
      isNilaiPembelian: false,
      nominalHitungPajak: 0,
      isPajakDitanggung: false,
      nominalPajakDitanggung: 0,
      nominalPajak: 0,
      total: 0,
      keterangan: "",
      akun: {
        id: null,
        kode: "",
        nama: "",
      },
      pasalPemotongan: null,
      objekPajak: {
        id: 1,
        kode: "P001",
        nama: "Omset",
      },
    },
  ],
});

const BiayaFormNonPkp = ({
  envStore,
  match,
  modalStore,
  navigationStore,
  ...props
}) => {
  const [isReady, setIsReady] = useState(true);
  let [item, setItem] = useState(null);
  const [panel2, setPanel2] = useState(true);
  const [panel3, setPanel3] = useState(false);
  //Disabled Text Field
  const [loadingAll, setLoadingAll] = useState(true);
  const [loading, setLoading] = useState(true);
  const [loadingLawanTransaksi, setLoadingLawanTransaksi] = useState(false);
  const [loadingAkunKasBank, setLoadingAkunKasBank] = useState(false);
  const [loadingAkunBiayaList, setLoadingAkunBiayaList] = useState(false);
  let [queryLawanTransaksi, setQueryLawanTransaksi] = useState("");
  let [queryAkunKasBank, setQueryAkunKasBank] = useState("");
  let [queryAkunBiaya, setQueryAkunBiaya] = useState("");
  const [lawanTransaksiList, setLawanTransaksiList] = useState([]);
  const [akunKasBankList, setAkunKasBankList] = useState([]);
  const [akunBiayaList, setAkunBiayaList] = useState([]);
  const [totalBiaya, setTotalBiaya] = useState(0);

  useEffect(() => {
    setLoading(true);
    initData();
    setLoading(false);
  }, []);
  useEffect(() => {
    setLoadingAll(loading || loadingAkunBiayaList);
  }, [loading, loadingAkunBiayaList]);

  async function initData() {
    let selectedItem = defaultData();
    setItem(selectedItem);
    setIsReady(false);
  }
  useEffect(() => {
    setLoadingLawanTransaksi(true);
    const timeOutId = setTimeout(
      () => getLawanTransaksi(queryLawanTransaksi),
      queryLawanTransaksi ? 500 : 0
    );
    return () => clearTimeout(timeOutId);
  }, [queryLawanTransaksi]);

  //Akun Biaya
  useEffect(() => {
    setAkunBiayaList(true);
    const timeOutId = setTimeout(() => getListAkunBiaya(queryAkunBiaya), 500);
    return () => clearTimeout(timeOutId);
  }, [queryAkunBiaya]);
  //Akun Kas Bank
  useEffect(() => {
    setLoadingAkunKasBank(true);
    const timeOutId = setTimeout(
      () => getListAkunKasBank(queryAkunKasBank),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [queryAkunKasBank]);

  async function getLawanTransaksi(queryNama) {
    try {
      setLoadingLawanTransaksi(true);

      let res = await service.getLawanTransaksiSupplier(queryNama);
      var lawanTransaksis = [];
      res.data.map((d) => {
        lawanTransaksis.push({
          value: d.id,
          label: d.nama,
          nama: d.nama,
          nik: d.nik,
          npwp: d.npwp,
          alamat: d.alamat,
        });
      });
      if (queryNama) {
        let cektr = find(lawanTransaksis, {
          label: queryNama,
        });
        if (!cektr) {
          lawanTransaksis.push({
            value: 0,
            label: queryNama,
            nama: queryNama,
            nik: "0000000000000000",
            npwp: "000000000000000",
            alamat: "-",
          });
        }
      }
      setLawanTransaksiList(lawanTransaksis);
      setLoadingLawanTransaksi(false);
    } catch (error) {
      toast.errorRequest(error);
      setLoadingLawanTransaksi(false);
    }
  }
  async function getListAkunBiaya(queryNama) {
    try {
      setLoadingAkunBiayaList(true);
      let res = await service.getListAkunBiaya(queryNama);
      var akunBiayas = [];
      res.data.map((d) => {
        akunBiayas.push({
          value: d.id,
          label: `${d.kode} - ${d.nama}`,
          kode: `${d.kode}`,
          nama: `${d.nama}`,
          debitKredit: `${d.kategoriAkun.debitKredit}`,
        });
      });
      setAkunBiayaList(akunBiayas);
      setLoadingAkunBiayaList(false);
    } catch (error) {
      setLoadingAkunBiayaList(false);
    }
  }
  async function getListAkunKasBank() {
    setLoadingAkunKasBank(true);

    let res = await service.getListAkunKasBank();
    var akuns = [];
    res.data.map((d) => {
      akuns.push({
        value: d.id,
        label: `${d.kode} - ${d.nama}`,
      });
    });
    setAkunKasBankList(akuns);

    setLoadingAkunKasBank(false);
  }
  const activePanel2 = () => {
    setPanel2(true);
    setPanel3(false);
  };

  const activePanel3 = () => {
    setPanel2(false);
    setPanel3(true);
  };

  var onSubmitTransaksi = async (data, callback) => {
    setItem(data);
    activePanel3();
  };

  var onSubmitProduk = async (data, callback) => {
    if (data.daftarBiaya.some((d) => d.nilaiPembelian === 0)) {
      callback("Nilai Biaya tidak boleh 0", true, false);
    } else {
      let resGambar;
      if (data.fileLampiran != null) {
        resGambar = await service.upload(data.fileLampiran);
      }
      let reqData = {
        fm: {
          isPpn: false,
          kategori: 2,
          kategoriTransaksi: 1,
          tanggalTransaksi: moment(data.tanggalTransaksi).format(
            "YYYY-MM-DD hh:mm:ss"
          ),
          periodeJatuhTempo: 0,
          tanggalJatuhTempo: null,
          subtotal: totalBiaya,
          total: totalBiaya,
          totalPotongPajak: 0,
          totalPotongPajakDitanggung: 0,
          totalBeban: 0,
          keterangan: data.keterangan,
          fileLampiran:
            data.fileLampiran != null ? resGambar.data.namaFileAlias : null,
          pembayaran: {
            status: 3,
            akun: {
              id: data.pembayaranAkun.id,
            },
          },
          lawanTransaksi: data.lawanTransaksi,
          npwpHead: {
            id: sessionStorage.getItem("npwpHeadId"),
          },
          akunUtang: null,
        },
        fmAkunPembelianList: data.daftarBiaya,
        fmAkunBebanList: [],
      };
      try {
        const res = await service.createNonPkp(reqData);
        let { status } = res;
        if (status == 200) {
          callback("success", false);
          navigationStore.redirectTo(`/internal/npwp/${npwpHeadId}/biaya`);
        }
      } catch (err) {
        callback(err, true, false);
      }
    }
  };

  const baseName = `biaya`;
  var npwpHeadId = match.params.npwpHeadId;
  var backTo = `/internal/npwp/${npwpHeadId}/biaya`;

  return (
    <FormWrapper
      loading={loadingAll}
      backTo={backTo}
      showCommandbar={true}
      baseId={`mod-form-${baseName}`}
      title={t.translate(`modules.biaya.form.nonPkp.title`)}
      style={{
        maxWidth: "100%",
      }}
      definitions={[
        {
          render: (
            <ExpansionList style={{ width: "100%" }}>
              <FormWrapper
                loading={loadingAll}
                showCommandbar={false}
                baseId={`mod-form-${baseName}`}
                style={{
                  maxWidth: "100%",
                }}
                defaultData={item}
                definitions={[
                  {
                    render: (
                      <div style={{ width: "100%" }}>
                        <p style={{ margin: "10px" }}>
                          <b>{t.translate(`words.dataLawanTransaksi`)}</b>
                        </p>
                      </div>
                    ),
                  },
                  // {
                  //   inputType: inputTypes.REACT_SELECT,
                  //   isClearable: true,
                  //   label: t.translate(`words.lawanTransaksi`),
                  //   key: "lawanTransaksiId",
                  //   validation: "required",
                  //   labelKey: "label",
                  //   valueKey: "value",
                  //   options: lawanTransaksiList,
                  //   isLoading: loadingLawanTransaksi,
                  //   onInputChange: (value) => {
                  //     setQueryLawanTransaksi(value);
                  //   },
                  // },
                  {
                    inputType: FormWrapper.inputTypes.INPUT,
                    className: "mpk-padding-N padding-right",
                    label: t.translate(`words.lawanTransaksi`),
                    key: "lawanTransaksiNama",
                  },
                ]}
                onChange={(formData, key, value) => {
                  // if (key == "lawanTransaksiId") {
                  //   lawanTransaksiList.map((d) => {
                  //     if (d.value == value) {
                  //       formData["lawanTransaksiId"] = d.value;
                  //       formData["lawanTransaksi"]["id"] = d.value;
                  //       formData["lawanTransaksi"]["nama"] = d.nama;
                  //       formData["lawanTransaksiNama"] = d.nama;
                  //       formData["lawanTransaksiNik"] = d.nik;
                  //       formData["lawanTransaksiNpwp"] = d.npwp;
                  //       formData["lawanTransaksiAlamat"] = d.alamat;
                  //     }
                  //   });
                  // }
                  if (key == "lawanTransaksiNama") {
                    formData["lawanTransaksiId"] = 0;
                    formData["lawanTransaksi"]["id"] = 0;
                    formData["lawanTransaksi"]["nama"] = value;
                    formData["lawanTransaksiNama"] = value;
                    formData["lawanTransaksiNik"] = "0000000000000000";
                    formData["lawanTransaksiNpwp"] = "000000000000000";
                    formData["lawanTransaksiAlamat"] = "-";
                  }
                  setItem(formData);
                  return formData;
                }}
              />
              <ExpansionPanel
                expanded={panel2}
                onClick={() => activePanel2()}
                header={t.translate("words.transaksi")}
              >
                <FormWrapper
                  submitLabel={t.translate("words.next")}
                  loading={loadingAll}
                  showCommandbar={false}
                  baseId={`mod-form-${baseName}`}
                  style={{
                    maxWidth: "100%",
                  }}
                  defaultData={item}
                  definitions={[
                    {
                      render: (
                        <div style={{ width: "100%", marginBottom: 30 }}>
                          <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                            Data Biaya
                            <Divider />
                          </Text>
                        </div>
                      ),
                    },
                    // -----
                    {
                      inputType: inputTypes.DATEPICKER,
                      className: "mpk-padding-N padding-right",
                      label: "Tanggal Transaksi",
                      key: "tanggalTransaksi",
                      width: "50%",
                      style: { zIndex: 2 },
                      required: true,
                    },
                    {
                      inputType: inputTypes.SELECT,
                      label: "Status Pembayaran",
                      key: "pembayaranStatus",
                      width: "50%",
                      options: statusBayar.map((d) => ({
                        label: d.name,
                        value: d.value,
                      })),
                      disabled: true,
                    },
                    {
                      inputType: inputTypes.REACT_SELECT,
                      isClearable: true,
                      style: { zIndex: 1 },
                      label: t.translate(`words.transferDari`),
                      key: "pembayaranAkunId",
                      labelKey: "label",
                      valueKey: "value",
                      options: akunKasBankList,
                      isLoading: loadingAkunKasBank,
                      onInputChange: (value) => {
                        if (value) setQueryAkunKasBank(value);
                      },
                    },
                  ]}
                  onChange={(formData, key, value) => {
                    if (key == "pembayaranAkunId") {
                      akunKasBankList.map((d) => {
                        if (d.value == value) {
                          formData["pembayaranAkun"]["id"] = d.value;
                        }
                      });
                    }
                    setItem(formData);
                    return formData;
                  }}
                  onSubmit={onSubmitTransaksi}
                />
              </ExpansionPanel>

              <br />

              {/* FORM PRODUK */}
              <ExpansionPanel
                expanded={panel3}
                onClick={() => activePanel3()}
                header={t.translate("words.biaya")}
              >
                <FormWrapper
                  loading={loadingAll}
                  showCommandbar={false}
                  baseId={`mod-form-${baseName}`}
                  style={{
                    maxWidth: "100%",
                  }}
                  submitLabel={t.translate("words.submit")}
                  backTo={backTo}
                  defaultData={item}
                  definitions={[
                    {
                      render: (
                        <div style={{ width: "100%", marginBottom: 30 }}>
                          <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                            Biaya
                            <Divider />
                          </Text>
                        </div>
                      ),
                    },
                    {
                      inputType: FormWrapper.inputTypes.LIST,
                      key: "daftarBiaya",
                      labelConfirm: "Tambah Biaya",
                      style: { zIndex: 1 },
                      title: "Daftar Nilai Biaya",
                      defaultData: {
                        nilaiPembelian: 0,
                        isPotongPajak: false,
                        ptsTarifPajak: 0,
                        isNilaiPembelian: false,
                        nominalHitungPajak: 0,
                        isPajakDitanggung: false,
                        nominalPajakDitanggung: 0,
                        nominalPajak: 0,
                        debitKredit: null,
                        disabled: true,
                        total: 0,
                        keterangan: "",
                        akun: {
                          id: null,
                          kode: "",
                          nama: "",
                        },
                        pasalPemotongan: null,
                        objekPajak: {
                          id: 1,
                          kode: "P001",
                          nama: "Omset",
                        },
                        namaBiaya: "",
                      },
                      style: {
                        height: "auto",
                        // maxHeight: "30vh",
                        minHeight: "10vh",
                      },
                      definitions: [
                        {
                          inputType: inputTypes.AUTOCOMPLETE,
                          label: "Nama Biaya",
                          className: "mpk-padding-N padding-right",
                          key: "namaBiaya",
                          data: akunBiayaList,
                          labelKey: "label",
                          valueKey: "label",
                          width: "30%",
                          type: "search",
                        },
                        {
                          inputType: FormWrapper.inputTypes.INPUT_MASK_NUMBER,
                          className: "mpk-padding-N padding-right",
                          label: "Nilai Biaya",
                          thousandSeparator: ".",
                          decimalSeparator: ",",
                          isNumericString: true,

                          type: "search",
                          key: "nilaiPembelian",
                          required: true,
                          width: "30%",
                        },
                        {
                          inputType: FormWrapper.inputTypes.INPUT,
                          className: "mpk-padding-N padding-right",
                          label: "Keterangan",
                          key: "keterangan",
                          width: "30%",
                        },
                      ],
                    },
                    {
                      render: (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            marginBottom: 50,
                            marginTop: 30,
                            backgroundColor: "#ecf0f1",
                            padding: 5,
                            borderRadius: 50,
                          }}
                        >
                          <TextContainer
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              flex: 1,
                              padding: 5,
                            }}
                          >
                            <Text style={{ fontSize: 16 }}>
                              Total : <b>{formatRupiah(totalBiaya)}</b>
                            </Text>
                          </TextContainer>
                        </div>
                      ),
                    },
                    {
                      render: <div style={{ margin: 10 }}></div>,
                    },
                    {
                      render: (
                        <div style={{ width: "100%", marginBottom: 30 }}>
                          <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                            Keterangan & Lampiran
                            <Divider />
                          </Text>
                        </div>
                      ),
                    },
                    {
                      inputType: inputTypes.INPUT,
                      label: t.translate(`words.keterangan`),
                      key: "keterangan",
                      type: "text",
                      width: "100%",
                    },
                    {
                      inputType: inputTypes.FILE_INPUT,
                      label: t.translate("words.file"),
                      key: "fileLampiran",
                      type: "file",
                      accept: "application/pdf, image/*",
                    },
                    {
                      render: <div style={{ margin: 10 }}></div>,
                    },
                  ]}
                  onChange={async (formData, key, value) => {
                    if (key === "namaBiaya") {
                      akunBiayaList.map((d, i) => {
                        if (d.label == value) {
                          formData.daftarBiaya
                            .filter((dd) => d.label == dd.namaBiaya)
                            .map((dt) => {
                              dt.akun.id = d.value;
                              dt.debitKredit = d.debitKredit;
                              dt.total = dt.total;
                            });
                        }
                      });
                      let TotalDebit = formData.daftarBiaya
                        .filter((d) => d.debitKredit == "1")
                        .map((d) => d.nilaiPembelian)
                        .reduce((acc, total) => acc + total, 0);
                      let Totalkredit = formData.daftarBiaya
                        .filter((d) => d.debitKredit == "2")
                        .map((d) => d.nilaiPembelian)
                        .reduce((acc, total) => acc + total, 0);
                      setTotalBiaya(TotalDebit - Totalkredit);
                    }
                    if (key === "nilaiPembelian") {
                      formData.daftarBiaya.map((d, i) => {
                        d.total = value;
                      });
                      let TotalDebit = formData.daftarBiaya
                        .filter((d) => d.debitKredit == "1")
                        .map((d) => d.nilaiPembelian)
                        .reduce((acc, total) => acc + total, 0);
                      let Totalkredit = formData.daftarBiaya
                        .filter((d) => d.debitKredit == "2")
                        .map((d) => d.nilaiPembelian)
                        .reduce((acc, total) => acc + total, 0);
                      setTotalBiaya(TotalDebit - Totalkredit);
                    }
                    setItem(formData);
                    return formData;
                  }}
                  onSubmit={onSubmitProduk}
                />
              </ExpansionPanel>
            </ExpansionList>
          ),
        },
      ]}
    />
  );
};

export default inject(
  "envStore",
  "modalStore",
  "navigationStore"
)(observer(BiayaFormNonPkp));
