import { crud, http } from "../../libs/react-mpk/services";

let service = null;
let serviceUrl = "/fm";
let tahunSobatPajak = sessionStorage.getItem("tahunSobatBuku");
let npwpHeadId = sessionStorage.getItem("npwpHeadId");
let npwpHeadIdObject = {
  npwpHeadId: sessionStorage.getItem("npwpHeadId"),
};
let tahunSobatBuku = sessionStorage.getItem("tahunSobatBuku");
service = new crud("/fm");
service.getLawanTransaksiSupplier = async () => {
  let query = {
    page: 0,
    size: 10,
    sort: "tanggalDibuat,DESC",
    //   "kategori.equals": 2,
    npwpHeadId: npwpHeadId,
  };
  return http.request({
    method: http.methods.GET,
    url: `/lawan_transaksi`,
    query,
  });
};
service.deletePengeluaran = async (noTransaksi) => {
  return http.request({
    method: http.methods.DELETE,
    url: `/kas_bank/pengeluaran/delete/${npwpHeadId}/${noTransaksi}`,
  });
};
service.getAkunPengeluaran = async (queryNama) => {
  let query = {
    size: 10,
    "tahun.equals": tahunSobatBuku,
    npwpHead: npwpHeadId,
    "nama.contains": queryNama,
  };
  return http.request({
    method: http.methods.GET,
    url: `/kas_bank/pengeluaran/akun`,
    query,
  });
};
service.createPengeluaran = async (data) => {
  return http.request({
    method: http.methods.POST,
    url: `/kas_bank/pengeluaran`,
    data,
  });
};
service.uploadPengeluaran = async (file) => {
  let data = {
    file: file,
    npwpHead: npwpHeadId,
  };
  // console.log(data, 'data')
  return http.request({
    method: http.methods.POST,
    url: `/kas_bank/pengeluaran/upload`,
    data: http.objectToFormData(data),
    config: { headers: { "content-type": "multipart/form-data" } },
  });
};
service.getPage = async (query) => {
  query = {
    ...query,
    ...npwpHeadIdObject,
  };
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}`,
    query,
  });
};

service.getHistory = async (id) => {
  let query = {
    npwpHeadId: npwpHeadId,
    "fmId.equals": id,
  };
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/riwayat_transaksi`,
    query,
  });
};

service.getOneById = async (id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/${id}`,
  });
};

service.getOneByNoTransaksi = async (noTransaksi) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/no_transaksi/${noTransaksi}`,
  });
};

// service.create = async(data)=> {
//   return http.request({
//     method: http.methods.POST,
//     url: `${serviceUrl}`,
//     data
//   })
// }

service.createNonPkp = async (data) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/non_pkp`,
    data,
  });
};

service.createPkp = async (data) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/pkp`,
    data,
  });
};

service.update = async (data) => {
  return http.request({
    method: http.methods.PUT,
    url: `${serviceUrl}/${data.id}`,
    data,
  });
};

service.deleteById = async (id) => {
  return http.request({
    method: http.methods.DELETE,
    url: `${serviceUrl}/${id}`,
  });
};

service.deletenonpkp = async (id) => {
  return http.request({
    method: http.methods.DELETE,
    url: `${serviceUrl}/${id}/deletenonpkp`,
  });
};

service.deletepkp = async (id) => {
  return http.request({
    method: http.methods.DELETE,
    url: `${serviceUrl}/${id}/deletepkp`,
  });
};

service.getListLawanTransaksi = async (queryNama) => {
  let query = {
    page: 0,
    size: 5,
    sort: "tanggalDibuat,DESC",
    "kode.contains": "",
    npwpHeadId: sessionStorage.getItem("npwpHeadId"),
    "kategori.equals": "2",
    "nama.contains": queryNama,
  };
  return http.request({
    method: http.methods.GET,
    url: `/lawan_transaksi`,
    query,
  });
};

service.getListAkunKasBank = async (queryNama) => {
  let query = {
    page: 0,
    size: 10,
    sort: "tanggalDibuat,DESC",
    "kode.contains": "",
    "nama.contains": queryNama,
    npwpHeadId: npwpHeadId,
  };
  return http.request({
    method: http.methods.GET,
    url: `/fm/pembelian/akun_kasbank`,
    query,
  });
};

service.getListAkunUtang = async (queryNama) => {
  let query = {
    page: 0,
    size: 10,
    sort: "tanggalDibuat,DESC",
    "kode.contains": "",
    "nama.contains": queryNama,
    npwpHeadId: npwpHeadId,
  };
  return http.request({
    method: http.methods.GET,
    url: `/fm/pembelian/akun_utang`,
    query,
  });
};
service.getProduk = async (queryNama) => {
  let query = {
    ...npwpHeadIdObject,
    size: 10,
    "nama.contains": queryNama,
  };
  return http.request({
    method: http.methods.GET,
    url: `/produk`,
    query,
  });
};
service.getListAkunPembelian = async (queryNama) => {
  let query = {
    page: 0,
    size: 10,
    sort: "kode,ASC",
    "kode.contains": "",
    "nama.contains": queryNama,
    npwpHeadId: npwpHeadId,
  };
  return http.request({
    method: http.methods.GET,
    url: `/akun`,
    query,
  });
};

service.getListAkunBeban = async () => {
  let query = {
    page: 0,
    size: 9999,
    sort: "tanggalDibuat,DESC",
    "kode.contains": "",
    "nama.contains": "",
    npwpHeadId: npwpHeadId,
  };
  return http.request({
    method: http.methods.GET,
    url: `/fm/pembelian/akun_beban`,
    query,
  });
};

service.getListPasalPemotongan = async () => {
  let query = {
    page: 0,
    size: 9999,
    sort: "tanggalDibuat,DESC",
    "nama.contains": "",
  };
  return http.request({
    method: http.methods.GET,
    url: `/pasal_pemotongan`,
    query,
  });
};

service.getListObjekPajak = async () => {
  let query = {
    page: 0,
    size: 9999,
    sort: "tanggalDibuat,DESC",
    "nama.contains": "",
  };
  return http.request({
    method: http.methods.GET,
    url: `/objek_pajak`,
    query,
  });
};

service.getListAkunPembelianById = async (id) => {
  let query = {
    page: 0,
    size: 9999,
    sort: "id,DESC",
    "kodeAkun.contains": "",
    "namaAkun.contains": "",
  };
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/${id}/akun`,
    query,
  });
};

service.getListAkunBebanById = async (id) => {
  let query = {
    page: 0,
    size: 9999,
    sort: "id,DESC",
    "kodeAkun.contains": "",
    "namaAkun.contains": "",
  };
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/${id}/akun_beban`,
    query,
  });
};

service.getJurnalEntryByNoTransaksi = async (noTransaksi, debitKredit) => {
  let query = {
    page: 0,
    size: 9999,
    sort: "tanggalDibuat,DESC",
    npwpHeadId: sessionStorage.getItem("npwpHeadId"),
    noTransaksi: noTransaksi,
    debitKredit: debitKredit,
  };
  return http.request({
    method: http.methods.GET,
    url: `/jurnal_entry/get_by_no_transaksi`,
    query,
  });
};

service.batalkan = async (id) => {
  return http.request({
    method: http.methods.PUT,
    url: `${serviceUrl}/${id}/batal`,
  });
};

service.getPrepopulated = async (query) => {
  query = {
    ...query,
    pageNum: 1,
    pageSize: 20,
  };
  // let query = {
  //     "masa": 1,
  //     "tahun":2022,
  //     "pageNum":1,
  //     "pageSize":20,
  //     "nomorFaktur":"",
  //     "npwpHeadId" : sessionStorage.getItem('npwpHeadId'),
  // }
  return http.request({
    method: http.methods.GET,
    url: `/fm/pkp/prepopulated`,
    query,
  });
};

service.getTransaksi = async () => {
  return http.request({
    method: http.methods.GET,
    url: `/fm/fp/transaksi`,
  });
};

service.getKeterangan = async () => {
  return http.request({
    method: http.methods.GET,
    url: `/fm/fp/keterangan`,
  });
};
service.upload = async (file) => {
  let data = {
    file: file,
    npwpHeadId: npwpHeadId,
  };
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/pembelian/lampiran/upload`,
    data: http.objectToFormData(data),
    config: { headers: { "content-type": "multipart/form-data" } },
  });
};

service.getDownloadLampiranById = async (id) => {
  return http.download(`${serviceUrl}/pembelian/lampiran/download/${id}`);
};
service.DownloadLampiranPembelian = async (id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/pembelian/lampiran/download/${id}`,
    config: {
      responseType: "arraybuffer",
    },
  });
};
service.getDownloadLampiranPengeluaranId = async (id) => {
  return http.download(`/kas_bank/pengeluaran/download/${id}`);
};
service.DownloadLampiranPengeluaran = async (id) => {
  return http.request({
    method: http.methods.GET,
    url: `/kas_bank/pengeluaran/download/${id}`,
    config: {
      responseType: "arraybuffer",
    },
  });
};

export default service;
