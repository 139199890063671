import React, { useState, useEffect } from 'react'
import { TableWrapper, TabsWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './PPN.service'
import _, { find } from 'lodash'
import { Button, Dialog, DialogHeader, DialogContent, DialogTitle, DialogFooter, Text } from 'react-md'
import errorService from '../../services/errorService'
import moment from 'moment'
import { format } from '../../libs/react-mpk/services/number.service'
import { DataForm } from '../../libs/react-mpk/components';
import { inputTypes } from '../../libs/react-mpk/config/constant'
import iziToast from 'izitoast'
import { toast } from '../../libs/react-mpk/services'
import utilsService from '../../services/utilsService'

const basePath = `ppn`
const PPNFakturMasukan = ({
    envStore,
    match,
    navigationStore,
    modalStore,
    className = '',
    showCommandbar = true
}) => {
    let [showForm, setShowForm] = useState(false);
    const [dialogPenerimaanPpn, setDialogPenerimaanPpn] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)
    const [showUploadFakturPajak, setShowUploadFakturPajak] = useState(false)
    const [showBatalkan, setShowBatalkan] = useState(false)
    const [showSendEmail, setShowSendEmail] = useState(false)
    let [search, setSearch] = useState({})
    let npwpHeadId = sessionStorage.getItem('npwpHeadId')
    const baseId = `table-ppn-faktur-masukan`
    function addItemPenerimaan() {
        setDialogPenerimaanPpn(true)
    }
    const [data, setData] = useState([])

    //upload
    async function upload(item) {
        modalStore.showConfirm({

            title: t.translate('words.upload'),
            children: t.translate('words.confirmUploadFakturPajak'),
            onSubmit: (callback) => {
                // hapusExc(item)
                callback()
            }
        })
    }
    //batal
    async function batal(item) {
        modalStore.showConfirm({
            title: t.translate('words.cancel'),
            children: t.translate('words.confirmBatalkan'),
            onSubmit: (callback) => {
                batalkanPesanan(item)
                callback()
            }
        })
    }

    async function batalkanPesanan(item) {
        try {
            let res = await service.batalkanFm(item.id)
            let { data } = res
            data.status = 4
            if (data.status == 'sukses') {
                iziToast.success({
                    message: data.message
                })
            } else {
                iziToast.info({
                    message: data.message
                })
            }
            document.getElementsByClassName('mdi-reload')[0].click()
        } catch (error) {
            toast.errorRequest(error)
        }
    }
    // hapus
    async function deleteFM(item) {
        modalStore.showConfirm({
          title: 'Delete',
          children: 'Apakah anda yakin?',
          onSubmit: (callback) => {
            deletePesanan(item)
            callback()
          }
        })
      }
      async function deletePesanan(item) {
        try {
      
          var r = await service.deleteFm(item.id)
          r.data.status = 4
          if (r.data.message == 'sukses') {
            iziToast.success({
              message: r.data.message
            })
          } else {
            iziToast.info({
              message: r.data.message
            })
          }
          document.getElementsByClassName('mdi-reload')[0].click()
        } catch (err) {
          toast.errorRequest(err)
        
        }
      }
   
    return (
        <TableWrapper
            baseId={baseId}
            title={t.translate('modules.ppn.listPpnFm')}
            className={className}
            hintMessage={t.translate('modules.ppn.hint.message')}
            hintIconClassName="mdi mdi-information"
            hintMore={t.translate('modules.ppn.hint.more')}
            defaultData={[]}
            defaultSortBy="tanggalDibuat"
            selectable={false}
            useFilter={false}
            defaultCollapse={true}
            onFetchData={(query) => {
                return (
                    new Promise(async (resolve, reject) => {
                        try {
                            query = {
                                ...search,
                                ...query,
                            }
                            let getPage = await service.getAllFm(query)
                            getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
                            resolve(getPage)
                        } catch (e) {
                            resolve()
                            // ErrorService(e)
                        }
                    })
                )
            }}
            columns={[
                {
                    label: t.translate(`words.noTransaksi`),
                    key: 'noTransaksi',
                    type: TableWrapper.dataTypes.STRING,
                    render: item => utilsService.beautifyNoTransaksi(item.noTransaksi)
                },
                {
                    label: t.translate(`words.npwpName`),
                    key: 'namaNpwp',
                    type: TableWrapper.dataTypes.STRING,
                    render: item => (item.lawanTransaksi.npwp)
                },
                {
                    label: t.translate(`words.total`),
                    key: 'total',
                    type: TableWrapper.dataTypes.STRING,
                    render: item => ("Rp. " + format(item.total))
                },
                {
                    label: t.translate(`words.tglTransaksi`),
                    key: 'tglTransaksi',
                    type: TableWrapper.dataTypes.STRING,
                    render: item => moment(item.tanggalTransaksi).locale('id').format('ll')
                },
                {
                    label: t.translate(`words.statusPembayaran`),
                    key: 'statusPembayaran',
                    type: TableWrapper.dataTypes.STRING,
                    render: item => (
                        <TableWrapper.Status
                            type={item.pembayaran.status == "1"
                                ? TableWrapper.Status.types.IDLE
                                : item.pembayaran.status == "2"
                                    ? TableWrapper.Status.types.PROGRESS
                                    : item.pembayaran.status == "3"
                                        ? TableWrapper.Status.types.SUCCESS
                                        : item.pembayaran.status == "4"
                                            ? TableWrapper.Status.types.ERROR
                                            : ""

                            }
                        >
                            {item.pembayaran.status == "1"
                                ? 'Belum Diproses'
                                : item.pembayaran.status == "2"
                                    ? 'Diproses'
                                    : item.pembayaran.status == "3"
                                        ? "Selesai"
                                        : item.pembayaran.status == "4"
                                            ? "Batal"
                                            : ""
                            }
                        </TableWrapper.Status>
                    )
                },
                // {
                //     label: t.translate(`words.noFaktur`),

                //     key: 'noFaktur',
                //     type: TableWrapper.dataTypes.INPUT_NUMBER_MASK,
                //     render: item => (item.noFaktur),
                //     format: "Rp." + '###.###.###.###',
                //     maskChar: '_',
                //     maskValue: 'string',
                // },
                {
                    label: t.translate(`words.statusApprove`),
                    key: 'statusAcc',
                    type: TableWrapper.dataTypes.STRING,
                    render: item => (
                        <TableWrapper.Status
                            type={item.pembayaran.status == "1" || item.pembayaran.status == "2"
                                ? TableWrapper.Status.types.IDLE
                                : item.pembayaran.status == "3"
                                    ? TableWrapper.Status.types.SUCCESS
                                    : item.pembayaran.status == "4"
                                        ? TableWrapper.Status.types.ERROR
                                        : ""

                            }
                        >
                            {item.pembayaran.status == "1" || item.pembayaran.status == "2"
                                ? 'Belum di Approved'
                                : item.pembayaran.status == "3"
                                    ? 'Approval Sukses'
                                    : item.pembayaran.status == "4"
                                        ? "Batal"
                                        : ""
                            }
                        </TableWrapper.Status>
                    )
                }
            ]}
            actions={[
                new TableWrapper.action('Add', 'mdi mdi-plus', (item) => {
                    navigationStore.redirectTo(
                        `/internal/npwp/${npwpHeadId}/ppn/new/masukan`
                    )
                })
            ]}
            itemActions={
                [
                    new TableWrapper.action(
                        `${t.translate('words.selengkapnya')}`,
                        'mdi mdi-import',
                        (item) => {
                            navigationStore.redirectTo(
                                `/internal/npwp/${npwpHeadId}/pembelian/tarikdata/${item.id}/identitas`
                            )
                        }
                    ),
                    new TableWrapper.action(
                        `${t.translate('words.history')}`,
                        'mdi mdi-history',
                        (item) => {
                            navigationStore.redirectTo(
                                `/internal/npwp/${npwpHeadId}/ppn/${item.id}/history/${'1'}`
                            )
                        }
                    ),
                    new TableWrapper.action(
                        `${t.translate('words.jurnalEntri')}`,
                        'mdi mdi-application',
                        (item) => {
                            navigationStore.redirectTo(
                                `/internal/npwp/${npwpHeadId}/ppn-fm/${item.noTransaksi}/jurnal-entri`
                            )
                        }
                    ),
                    // new TableWrapper.action(
                    //     `${t.translate('words.upload')}`,
                    //     'mdi mdi-file-upload',
                    //     (item) => {
                    //         upload(item)
                    //     }
                    // ),
                    new TableWrapper.action(
                        `${t.translate('words.batalkan')}`,
                        'mdi mdi-close',
                        (item) => {
                            batal(item)
                        },
                        item => item.pembayaran.status == 1 ? true : false
                    ),
                    new TableWrapper.action(
                        'Delete',
                        'mdi mdi-delete',
                        (item) => {
                            deleteFM(item)
                        },
                        item => item.pembayaran.status == 1 ? true : false
                    ),
                ]
            }
            sideHeader={
                <DataForm
                    baseId={`${baseId}-search`}
                    defaultData={search}
                    submitIconClassName='mdi mdi-magnify'
                    submitLabel={`${t.translate('words.cari')}`}
                    onSubmit={(values, callback) => {
                        // console.log(values, 'values')
                        setSearch(values);
                        setTimeout(() => {
                            document.getElementsByClassName('mdi-reload')[0].click()
                            callback("", false, false)
                        }, 1000)
                    }}
                    definitions={[
                        {
                            inputType: inputTypes.INPUT,
                            label: t.translate(`words.noTransaksi`),
                            key: 'noTransaksi.contains',
                            type: 'text'
                        }
                    ]}
                />
            }
        />
    )
}

export default inject("navigationStore", 'envStore', 'modalStore')(observer(PPNFakturMasukan))