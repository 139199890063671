import React, { useEffect, useState } from 'react';
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { Table, TableBody, TableHeader, TableRow, TableCell, Button, FontIcon, TableFooter, Text } from 'react-md'
import { find } from 'lodash'
import KasDanBankTambahEditInputDetailAkunUangPenerimaan from './KasDanBankTambahEditInputDetailAkunUangPenerimaan';
import { Hint } from '../../../libs/react-mpk/components';
import KasDanBankTambahEditInputDetailAkunBeban from './KasDanBankTambahEditInputDetailAkunBeban';
import service from '../KasDanBank.service';
import { toast } from '../../../libs/react-mpk/services';
import KasDanBankDetailList from '../KasDanBankDetailList/KasDanBankDetailList';
import { format, formatRupiah } from '../../../libs/react-mpk/services/number.service';
import utilsService from '../../../services/utilsService';
import PreviewFile from '../../PreviewFile/PreviewFile';

const defaultData = () => ({
	akun: {
		id: null
	},
	noTransaksi: null,
	tanggalTransaksi: '',
	lawanTransaksi: {
		id: null
	},
	totalNominalPajak: 0,
	totalPajakSetorSendiri: 0,
	totalNilaiDibayar: 0,
	subtotal: 0,
	fileLampiran: '',
	fileLampiranAlias: '',
	fileLampiranInstance: '',
	keterangan: '',
	akunTo: null,
	nilaiDibayar: 0,
	nominalTransfer: 0,
	kasBankList: []
})
const KasDanBankDetailPenerimaanNonPPN = ({
	envStore,
	match
}) => {
	const { inputTypes, definition } = FormWrapper
	const [dialogDetail, setDialogDetail] = useState(false);
	const [dialogData, setDialogData] = useState(false);
	const [item, setItem] = useState(null)
	let idAkun = sessionStorage.getItem('idAkun')
	let npwpHeadId = sessionStorage.getItem('npwpHeadId')
	let [ready, setReady] = useState(false)
	const [showDialog, setDialog] = useState(false);
	let [file, setFile] = useState(null);
	let [type, setType] = useState(null);


	async function initData() {
		let selectedItem = defaultData()
		try {
			let res = await service.getPageDetailbyNoTransaksi(match.params.id)
			let { data } = res
			// console.log(data, 'RES GET DETAIL');
			selectedItem.id = data.kasBank.id
			selectedItem.fileLampiran = data.kasBank.fileLampiran
			selectedItem.fileLampiranAlias = data.kasBank.fileLampiranAlias
			selectedItem.fileLampiranInstance = data.kasBank.fileLampiranInstance
			selectedItem.keterangan = data.kasBank.keterangan
			selectedItem.tanggalTransaksi = data.kasBank.tanggalTransaksi
			selectedItem.noTransaksi = utilsService.beautifyNoTransaksi(data.kasBank.noTransaksi)
			selectedItem.lawanTransaksi = `${utilsService.parseNPWP(data.kasBank.lawanTransaksi.npwp)} - ${data.kasBank.lawanTransaksi.nama}`
			selectedItem.akun = `${data.kasBank.akun.kode} - ${data.kasBank.akun.nama}`

			if (data.kasBank.totalPajakSetorSendiri) {
				// console.log('masuk');
				selectedItem.nilaiDibayar = data.kasBank.nilaiDibayar - data.kasBank.totalPajakSetorSendiri
			} else {
				// console.log('masuk 2');
				selectedItem.nilaiDibayar = data.kasBank.nilaiDibayar
			}

			selectedItem.totalNilaiDibayar = data.kasBank.nilaiDibayar

			selectedItem.totalPajakSetorSendiri = data.kasBank.totalPajakSetorSendiri
			selectedItem.kasBankList = data.kasBankList ? data.kasBankList : []
			setItem(selectedItem)
		} catch (error) {

		}
		localStorage.setItem('itemDetail', JSON.stringify(selectedItem))
		localStorage.setItem('itemId', JSON.stringify(selectedItem.id))
		// setItem(selectedItem)
		// console.log(getItem, 'localstorage get detai');
		setReady(true)
	}

	useEffect(() => {
		initData()
	}, [])

	useEffect(() => {
		// console.log(itemFile, 'item detail');

	}, [item])


	return ready && (
		<>
			<FormWrapper
				showCommandbar={true}
				baseId="mod-kas-dan-bank-detail-penerimaan-non-ppn"
				title='Detail Penerimaan Uang'
				style={{
					maxWidth: '100%'
				}}
				submitLabel={t.translate(`words.submit`)}
				defaultData={item}
				definitions={[
					{
						inputType: inputTypes.INPUT,
						label: t.translate(`words.noTransaksi`),
						key: 'noTransaksi',
						disabled: true,
						width: '49%',
						style: {
							marginRight: "2%",
						}
					},
					{
						inputType: inputTypes.DATEPICKER,
						label: t.translate(`words.tglTransaksi`),
						key: 'tanggalTransaksi',
						type: 'text',
						width: '49%',
						disabled: true
					},
					{
						render: (
							<div style={{ width: '100%' }}>
								<p style={{ margin: "10px" }}><b>{t.translate(`words.lawanTransaksiDanSumberDana`)}</b></p>
							</div>
						)
					},
					{
						inputType: inputTypes.INPUT,
						label: t.translate(`words.lawanTransaksi`),
						key: 'lawanTransaksi',
						type: 'text',
						width: '49%',
						style: {
							marginRight: "2%"
						},
						disabled: true
					},
					{
						inputType: inputTypes.INPUT,
						label: t.translate(`words.transferke`),
						key: 'akun',
						type: 'text',
						width: '49%',
						disabled: true
					},
					{
						render: (
							<div style={{ width: '100%' }}>
								<Hint
									className="mpk-margin-N margin-top margin-bottom"
									message="Akun Subtotal"
								/>
								<Table style={{ width: '100%' }}>
									<TableHeader>
										<TableRow>
											<TableCell style={{ width: '10%' }}>{t.translate('words.non')}</TableCell>
											<TableCell style={{ width: '25%' }}>{t.translate('words.namaAkun')}</TableCell>
											<TableCell style={{ width: '25%' }}>{t.translate('words.kodeAkun')}</TableCell>
											<TableCell style={{ width: '25%' }}>{t.translate('words.nominal')}</TableCell>
										</TableRow>
									</TableHeader>
									<TableBody>
										{item && item.kasBankList.map((d, index) => {
											return (
												<TableRow key={index}>
													<TableCell>{index + 1}</TableCell>
													<TableCell>{d.kasBankAkun.akun.nama}</TableCell>
													<TableCell>{d.kasBankAkun.akun.kode}</TableCell>
													<TableCell> {formatRupiah(d.kasBankAkun.kasBank.nilaiDibayar)}</TableCell>
													<TableCell><Button
                                              style={{
                                                backgroundColor: "#34495e",
                                                color: "white",
                                              }}
                                              onClick={() => {
                                                setDialogDetail(true);
                                                setDialogData(d);
                                                // setDialogJudul(
                                                //   "Nilai Biaya (Sebelum Pajak)"
                                                // );
                                              }}
                                            >
                                              Detail
                                            </Button></TableCell>
												</TableRow>
											)
										})}
									</TableBody>
									<TableFooter>
										<TableRow>
											<TableCell><b>Total Nominal</b></TableCell>
											<TableCell></TableCell>
											<TableCell></TableCell>
											<TableCell><b> {formatRupiah(item && item.totalNilaiDibayar)}</b></TableCell>
										</TableRow>
									</TableFooter>
								</Table>
								<br />
							</div>
						)
					},
					
					{
						inputType: inputTypes.INPUT_MASK_NUMBER,
						label: t.translate(`words.pajakDisetorSendiri`),
						key: 'totalPajakSetorSendiri',
						type: 'text',
						width: '49%',
						style: {
							marginRight: "2%"
						},
						disabled: true,
						thousandSeparator: ".",
						decimalSeparator: ",",
						isNumericString: true,
						format: '0,0'
					},
					{
						inputType: inputTypes.INPUT_MASK_NUMBER,
						label: t.translate(`words.nilaiYangDibayar`),
						key: 'nilaiDibayar',
						type: 'text',
						format: '0,0',
						disabled: true,
						thousandSeparator: ".",
						decimalSeparator: ",",
						isNumericString: true,
						width: '49%',
					},
					{
						render: (
							<div style={{ width: '100%' }}>
								<p style={{ margin: "10px" }}><b>{t.translate(`words.informasiTambahan`)}</b></p>
							</div>
						),
						show:()=> {return item.keterangan != null && item.fileLampiran != null}
					},
					{
						inputType: inputTypes.INPUT,
						label: t.translate(`words.keterangan`),
						key: 'keterangan',
						type: 'text',
						disabled: true,
						show: () => {
							return item.keterangan != null;
						  },
					},
					{
						inputType: inputTypes.INPUT,
						label: t.translate(`words.namaFile`),
						key: "fileLampiran",
						type: "text",
						width: "70%",
						disabled: true,
						show: () => {
						  return item.fileLampiran != null;
						},
					  },
					  {
						render: (
						  <Button
							theme="primary"
							themeType="contained"
							style={{ margin: 10 }}
							onClick={async () => {
							  try {
								await service
								  .getLampiranPenerimaan(item.id)
								  .then((response) => {
									const type = response.headers["content-type"];
									let blob = new Blob([response.data], {
										type: type,
									  }),
									  datas = window.URL.createObjectURL(blob);
									setFile(datas);
								  });
								if (item.fileLampiran.includes(".pdf")) {
								  setType("pdf");
								} else {
								  setType("image");
								}
								setDialog(true);
							  } catch (error) {
								toast.errorRequest(error);
							  }
							}}
						  >
							Lihat File
						  </Button>
						),
						show: () => {
						  return item.fileLampiran != null;
						},
					  },
				]}
				onChange={(data, key, value) => {
					if (key === 'lawanTransaksiId') {
						// console.log(data.lawanTransaksi, 'data lawan tr');
					}
					return data
				}}			/>

			 <KasDanBankDetailList
                  visible={dialogDetail}
                  onRequestClose={() => {
                    setDialogDetail(false);
                    setDialogData(null);
                  }}
                  item={dialogData}
                />
				<PreviewFile
        visible={showDialog}
        onRequestClose={() => {
          setDialog(false);
        }}
        onClickDownload={() => {
          service.DownloadLampiranPenerimaan(item.id);
        }}
        file={file}
        type={type}
      />
		</>
	)
}

export default inject('envStore')(observer(KasDanBankDetailPenerimaanNonPPN))