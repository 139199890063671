import React, { useEffect, useState } from 'react';
import { DataForm } from '../../libs/react-mpk/components';
import t from 'counterpart'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import ErrorService from './../../services/errorService'
import service from './Pesanan.service'
import { cloneDeep, find } from 'lodash'
import { disable } from 'react-md';
import { inputTypes } from '../../libs/react-mpk/config/constant'
import { toast } from '../../libs/react-mpk/services';



const defaultData = () => ({
	nama: "",
	jumlahBarang: 0,
	subtotal: 0,
	dpp: 0,
	ppnbm: 0,
	ppn: 0,
	hargaSatuan: 0,
	ptsPpnbm: 0,
	produk: {},
	diskon: 0,
	ptsDiskon: 0,
	hargaTotal: 0,
	isPpnbm: false
})

const PesananProdukForm = ({
	visible = false,
	authStore,
	envStore,
	match,
	onRequestClose = () => { },
	...props
}) => {
	let [item, setItem] = useState(null)
	let [ready, setReady] = useState(true)

	// VALIDASI
	const [isPpnBmInt, setIsPpnBmInt] = useState(true)
	const [produkList, setProdukList] = useState([])
	let [queryProduk, setQueryProduk] = useState("");
	const [loadingProduk, setLoadingProduk] = useState(false);
	const [produkListAll, setProdukListAll] = useState([])
	var thousandSeparator = "."
	var decimalSeparator  = ","

	useEffect(() => {
		setIsPpnBmInt(true)
		initData()
	}, [visible])

	async function initData() {
		if (visible) {
			let selectedItem = defaultData();

			if (props.itemId) {
				try {
					// let res = await service.getOneById(props.itemId)

					// let { data } = res;

					// setDataSubmit(data);

				} catch (e) {
					// setMessageError(`${e}`)
					// setShowDialogError(true)
					onRequestClose()
				}
			}
			setItem(selectedItem);
		} else {
			setItem(defaultData())
		}
		setReady(visible)
	}
	useEffect(() => {
		setLoadingProduk(true);
		const timeOutId = setTimeout(
		  () => getProduk(queryProduk),
		  500
		);
		return () => clearTimeout(timeOutId);
	  }, [queryProduk]);

	async function getProduk(queryNama) {
		try{
			setLoadingProduk(true);
			let res = await service.getAllProduk(queryNama)
			var produks = []
			res.data.map((d) => {
				produks.push({
					value: d.id,
					label: `${d.kode} - ${d.nama}`,
					kode: d.kode,
					nama: d.nama
				})
			})
			setProdukList(produks)
			setProdukListAll(res.data)
			setLoadingProduk(false);
		} catch (error) {
			toast.errorRequest(error);
			setLoadingProduk(false);
		  }
	}
	return ready && (
		<DataForm
			baseId="pesanan-produk-form"
			title={t.translate(`modules.pesanan.pesananProdukForm`)}
			visible={visible}
			// data={item}
			onRequestClose={onRequestClose}
			asDialog={true}
			defaultData={item}
			definitions={[
				{
					inputType: DataForm.inputTypes.REACT_SELECT,
            isClearable:true,
					label: t.translate(`words.produk`),
					key: 'produkId',
					validation: 'required',
					labelKey: "label",
					valueKey: "value",
					options: produkList,
					isLoading: loadingProduk,
					onInputChange: (value) => {
					  if (value) setQueryProduk(value);
					},
					data: produkList
				},
				{
					inputType: inputTypes.INPUT,
					label: t.translate(`words.nama`),
					key: 'nama',
					type: 'text',
					width: '49%',
					style: {
						marginRight: "2%"
					},
					disabled: true
				},
				{
					inputType: inputTypes.INPUT_MASK_NUMBER,
					label: t.translate(`words.hargaSatuan`),
					key: 'hargaSatuan',
					thousandSeparator: thousandSeparator,
					decimalSeparator: decimalSeparator,
					type: 'text',
					width: '49%',
					validation: 'required',
				},
				{
					inputType: inputTypes.INPUT_MASK_NUMBER,
					label: t.translate(`words.jumlahBarang`),
					key: 'jumlahBarang',
					type: 'text',
					width: '49%',
					style: {
						marginRight: "2%"
					},
					validation: 'required',
				},
				{
					inputType: inputTypes.INPUT_MASK_NUMBER,
					label: t.translate(`words.hargaTotal`),
					key: 'hargaTotal',
					thousandSeparator: thousandSeparator,
					decimalSeparator: decimalSeparator,
					type: 'text',
					width: '100%',
					disabled: true,
					width: '49%',
				},
				{
					inputType: inputTypes.INPUT_MASK_NUMBER,
					label: t.translate(`words.diskon`),
					thousandSeparator: thousandSeparator,
					decimalSeparator: decimalSeparator,
					key: 'diskon',
					type: 'text',
					width: '49%',
					style: {
						marginRight: "2%"
					},
					validation: 'required',
				},
				{
					inputType: inputTypes.INPUT_MASK_NUMBER,
					label: t.translate(`words.subtotal`),
					key: 'subtotal',
					thousandSeparator: thousandSeparator,
					decimalSeparator: decimalSeparator,
					type: 'text',
					width: '49%',
					disabled: true
				},
				{
					inputType: inputTypes.INPUT_MASK_NUMBER,
					label: t.translate(`words.dpp`),
					key: 'dpp',
					thousandSeparator: thousandSeparator,
					decimalSeparator: decimalSeparator,
					type: 'text',
					width: '49%',
					style: {
						marginRight: "2%"
					},
					disabled: true
				},
				{
					inputType: inputTypes.INPUT_MASK_NUMBER,
					label: t.translate(`words.ppn`),
					key: 'ppn',
					thousandSeparator: thousandSeparator,
					decimalSeparator: decimalSeparator,
					type: 'text',
					width: '49%',
					disabled: true
				},
				// {
				// 	render: (
				// 		<div style={{ width: "100%" }}>
				// 			<p style={{ marginBottom: "-10px" }}><b>{t.translate(`words.apakahPpnBm`)}</b></p><br />
				// 		</div>
				// 	)
				// },
				// {
				// 	inputType: inputTypes.CHECKBOX,
				// 	label: t.translate(`words.ya`),
				// 	key: 'isPpnbm',
				// },
				// {
				// 	inputType: inputTypes.INPUT_MASK_NUMBER,
				// 	label: t.translate(`words.tarifPpnBm`) + " (%)",
				// 	key: 'ptsPpnbm',
				// 	type: 'text',
				// 	width: '49%',
				// 	style: {
				// 		marginRight: "2%"
				// 	},
				// 	thousandSeparator: thousandSeparator,
				// 	decimalSeparator: decimalSeparator,
				// 	show: (d) => {
				// 		return d.isPpnbm == true
				// 	}
				// },
				// {
				// 	inputType: inputTypes.INPUT_MASK_NUMBER,
				// 	label: t.translate(`words.ppnBm`),
				// 	key: 'ppnbm',
				// 	thousandSeparator: thousandSeparator,
				// 	decimalSeparator: decimalSeparator,
				// 	type: 'text',
				// 	width: '49%',
				// 	disabled: true,
				// 	show: (d) => {
				// 		return d.isPpnbm == true
				// 	}
				// },
			]}
			onChange={async (formData, key, value) => {
				if (key == 'produkId') {
					produkList.map((d) => {
						if (d.value == value) {
							formData['produk']['id'] = d.value
							formData['produk']['kode'] = d.kode
							formData['produk']['nama'] = d.nama
							let dataproduk = find(produkListAll, {id: formData.produk.id})
							formData['nama'] = dataproduk?dataproduk.nama:""
							formData['kode'] = dataproduk?dataproduk.kode:""
							// console.log(dataproduk, "dataproduk");
						}
					})
				}
				if(key == 'hargaSatuan'){
					formData.hargaTotal = formData.jumlahBarang * value 
					formData.subtotal = formData.jumlahBarang * value - formData.diskon
					formData.dpp = formData.subtotal
					formData.ppn = formData.dpp * 0.11
					if(formData.isPpnbm == true){
						formData.ppnbm = formData.ppn * (formData.ptsPpnbm/100)
					}
				}
				if (key == 'jumlahBarang') {
					formData.hargaTotal = value * formData.hargaSatuan 
					formData.subtotal = value * formData.hargaSatuan - formData.diskon
					formData.dpp = formData.subtotal
					formData.ppn = formData.dpp * 0.11
					if(formData.isPpnbm == true){
						formData.ppnbm = formData.ppn * (formData.ptsPpnbm/100)
					}
				}
				if (key == 'diskon') {
					formData.subtotal = formData.hargaTotal - (formData.diskon)
					formData.dpp = formData.subtotal
					formData.ppn = formData.dpp * 0.11
					if(formData.isPpnbm == true){
						formData.ppnbm = formData.ppn * (formData.ptsPpnbm/100)
					}
				}
				if (key == 'isPpnbm') {
					if (value)
						setIsPpnBmInt(true)
					else
						setIsPpnBmInt(false)
				}
				if (key == 'ptsPpnbm') {
					formData.ppnbm = formData.ppn * (formData.ptsPpnbm/100)
				}
				return DataForm
			}}

			onSubmit={(data, callback) => {
				if(data.hargaTotal == 0 ){
					callback('Harga Total Tidak boleh Nol',true, false)
				}else if(data.subtotal == 0 ){
					callback('Harga subTotal Tidak boleh Nol',true, false)
				}else{
					localStorage.setItem("produkForm", JSON.stringify(data))
					callback('success', false)
				}
			}}
		/>
	)
}

export default PesananProdukForm