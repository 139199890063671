import React, { useEffect, useState } from "react";
import { DataForm } from "../../libs/react-mpk/components";
import t from "counterpart";
import { FormWrapper } from "../../libs/react-mpk/wrapper";
import service from "./Pembelian.service";
import { toast } from "../../libs/react-mpk/services";
import { Text } from "react-md";
const TARIF_PPN = 0.11;

const defaultData = () => ({
  diskon: "0",
  isPpnbm: 2,
  alamat: "",
  areaId: null,
  dibuatOleh: "",
  diperbaharuiOleh: "",
  email: "",
  halonaCompanyId: null,
  id: null,
  isAkunGenerated: true,
  jumlahTanggungan: 0,
  kabupaten: "",
  kecamatan: "",
  kelurahan: "",
  kodeRegisterEfiling: "",
  kodepos: null,
  messageRegisterEfiling: "",
  namaNpwp: "",
  noKtp: "",
  noTelepon: "",
  npwp: "",
  npwpHeadId: null,
  npwpOp: true,
  npwpOpInt: "0",
  pegawai: false,
  pekerjaBebas: false,
  pemilikUsaha: false,
  penggunaId: null,
  penggunaIdIam: "",
  penggunaUsername: "",
  pengurusanEfin: 3,
  pkp: false,
  pkpInt: "0",
  propinsi: "",
  registerEfiling: false,
  statusNikah: 2,
  tahun: null,
  tanggalDibuat: null,
  memilikiNpwp: true,
  keterangan: null,
});
const dataPenggunaNpwp = JSON.parse(sessionStorage.getItem("dataPenggunaNpwp"));

const InputProdukPembelian = ({
  visible = false,
  isPpn = dataPenggunaNpwp.pkp,
  authStore,
  envStore,
  match,
  history,
  onRequestClose = () => {},
  ...props
}) => {
  const { inputTypes, definition } = FormWrapper;
  let [item, setItem] = useState(null);
  let [ready, setReady] = useState(true);
  const [listProduk, setListProduk] = useState([]);
  let [idAkun, setIdAkun] = useState(null);
  const [loadingProduk, setLoadingProduk] = useState(false);
  let [queryProduk, setQueryProduk] = useState("");
  async function getListAkunPembelian() {
    setTimeout(async () => {
      let res = await service.getListAkunPembelian("Persediaan Barang Dagang");
      res.data.map((d) => setIdAkun(d.id));
    }, 500);
  }
  async function akunProduk(queryProduk) {
    try {
      setLoadingProduk(true);
      let res = await service.getProduk(queryProduk);
      var produk = [];
      res.data.map((d) => {
        produk.push({
          value: d.id,
          label: d.nama,
          kode: d.kode,
        });
      });
      setListProduk(produk);
      setLoadingProduk(false);
    } catch (error) {
      toast.errorRequest(error);
      setLoadingProduk(false);
    }
  }

  useEffect(() => {
    if (visible) {
      akunProduk(queryProduk);
      getListAkunPembelian();
    } else {
      setItem(defaultData());
    }
    setReady(visible);
  }, [visible]);

  useEffect(() => {
    setLoadingProduk(true);
    const timeOutId = setTimeout(() => akunProduk(queryProduk), 500);
    return () => clearTimeout(timeOutId);
  }, [queryProduk]);

  return (
    ready && (
      <DataForm
        baseId="penjualan-produk-form"
        title={t.translate(`modules.penjualan.penjualanProdukForm`)}
        visible={visible}
        // data={item}
        onRequestClose={onRequestClose}
        asDialog={true}
        defaultData={{
          akun: { id: idAkun },
          isDiskon: 0,
          nilaiPembelian: 0,
          hargaTotal: 0,
          jumlah: 0,
          ppn: 0,
          isPotongPajak: false,
          terdapatPemotonganPajak: false,
          pasalPemotonganId: "PPh 4(2)",
          pasalPemotongan: {
            id: 1,
          },
          objekPajakId: "P001 - Omset",
          objekPajak: {
            id: 1,
          },
          ptsTarifPajak: 0.5,
          perhitunganPajak: "Nilai Pembelian",
          isNilaiPembelian: true,
          nominalHitungPajak: 0,
          isPajakDitanggung: false,
          nominalPajakDitanggung: 0,
          nominalPajak: 0,
          keterangan: "",
        }}
        definitions={[
          // {
          //   inputType: DataForm.inputTypes.REACT_SELECT,
           // isClearable:true,
          //   label: t.translate(`words.produk`),
          //   key: "produkId",
          //   validation: "required",
          //   labelKey: "label",
          //     valueKey: "value",
          //     options: listProduk,
          //     isLoading: loadingProduk,
          //     onInputChange: (value) => {
          //       if (value) setQueryProduk(value);
          //     },
          // },
          // {
          //   inputType: inputTypes.INPUT_MASK_NUMBER,
          //   label: t.translate(`words.hargaSatuan`),
          //   key: "hargaSatuan",
          //   thousandSeparator: ",",
          //   decimalSeparator: ".",
          //   isNumericString: true,
          //   type: "text",
          //   width: "48%",
          //   validation: "required",
          //   style: {
          //     marginRight: "2%",
          //   },
          // },
          // {
          //   inputType: inputTypes.INPUT_MASK_NUMBER,
          //   label: t.translate(`words.jumlahBarang`),
          //   key: "jumlahBarang",
          //   thousandSeparator: ",",
          //   decimalSeparator: ".",
          //   isNumericString: true,
          //   type: "text",
          //   width: "48%",
          //   validation: "required",
          //   style: {
          //     marginLeft: "2%",
          //   },
          // },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            label: t.translate(`words.hargaTotal`),
            key: "hargaTotal",
            thousandSeparator: ".",
            decimalSeparator: ",",
            isNumericString: true,
            type: "text",
            prefix: "Rp. ",
            width: "100%",
            // disabled: true,
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            label: t.translate(`words.diskon`),
            key: "isDiskon",
            thousandSeparator: ".",
            decimalSeparator: ",",
            isNumericString: true,
            type: "text",
            suffix: " %",
            width: "15%",
            validation: "required",
            className: "mpk-padding-N padding-right",
            disabled:true  //sementara
          },
          {
            inputType: inputTypes.CHECKBOX,
            label: `Transaksi PPN`,
            key: "isTransaksiPpn",
            name: "isTransaksiPpn",
            className: "mpk-padding-N padding-left",
            style: {
              width: "auto",
            },
            disabled:true  //sementara
          },
          {
            inputType: inputTypes.CHECKBOX,
            label: `Harga Termasuk PPN`,
            key: "isPpn",
            name: "termasukPpn",
            className: "mpk-padding-N padding-left",
            style: {
              width: "auto",
            },
            disabled: (item) => (item.isTransaksiPpn == true ? false : true),
          },

          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            label: "Jumlah",
            key: "jumlah",
            thousandSeparator: ".",
            decimalSeparator: ",",
            prefix: "Rp. ",
            isNumericString: true,
            type: "text",
            disabled: true,
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            label: "PPN",
            key: "ppn",
            thousandSeparator: ".",
            decimalSeparator: ",",
            prefix: "Rp. ",
            isNumericString: true,
            type: "text",
            disabled: true,
          },
          {
            inputType: inputTypes.INPUT,
            label: "Keterangan",
            key: "keterangan",
            type: "text",
          },
        ]}
        onChange={async (formData, key, value) => {
          if (key === "produkId") {
            listProduk.map((d) => {
              if (d.value === value) {
                formData["produk"] = {
                  nama: d.label,
                  kode: d.kode,
                  id: d.value,
                };
              }
            });
          }
          if (key === "hargaSatuan") {
            formData.diskon = formData.hargaTotal * (formData.isDiskon / 100);
            formData.hargaTotal = formData.hargaSatuan * formData.jumlahBarang;
            formData.jumlah = formData.hargaTotal - formData.diskon;
            formData.dpp = formData.jumlah;
            formData.subtotal = formData.hargaTotal;
            formData.total = formData.subtotal + formData.ppn - formData.diskon;
            if (formData.isTransaksiPpn === true) {
              //jika pkp
              if (formData.isPpn === true) {
                //jika harga termasuk harga
                if (formData !== 0) {
                  //dengan diskon
                  formData.ppn = Math.round(
                    ((100 / 111) * formData.hargaTotal -
                      formData.diskon * ((100 / 111) * formData.hargaTotal)) *
                      TARIF_PPN
                  ).toFixed(2);
                } else {
                  //tanpa diskon
                  formData.ppn = Math.round(
                    (100 / 111) * formData.hargaTotal * TARIF_PPN
                  ).toFixed(2);
                }
              } else {
                //jika harga tidak termasuk harga
                formData.ppn = Math.round(
                  (formData.hargaTotal - formData.diskon) * TARIF_PPN
                ).toFixed(2);
              }
            } else {
              // jika non PKP
              formData.ppn = 0;
            }
          }
          if (key === "jumlahBarang") {
            formData.diskon = formData.hargaTotal * (formData.isDiskon / 100);
            formData.hargaTotal = formData.hargaSatuan * formData.jumlahBarang;
            formData.jumlah = formData.hargaTotal - formData.diskon;
            formData.dpp = formData.jumlah;
            formData.subtotal = formData.hargaTotal;
            formData.total = formData.subtotal + formData.ppn - formData.diskon;
            if (formData.isTransaksiPpn === true) {
              //jika pkp
              if (formData.isPpn === true) {
                //jika harga termasuk harga
                if (formData !== 0) {
                  //dengan diskon
                  formData.ppn = Math.round(
                    ((100 / 111) * formData.hargaTotal -
                      formData.diskon * ((100 / 111) * formData.hargaTotal)) *
                      TARIF_PPN
                  ).toFixed(2);
                } else {
                  //tanpa diskon
                  formData.ppn = Math.round(
                    (100 / 111) * formData.hargaTotal * TARIF_PPN
                  ).toFixed(2);
                }
              } else {
                //jika harga tidak termasuk harga
                formData.ppn = Math.round(
                  (formData.hargaTotal - formData.diskon) * TARIF_PPN
                ).toFixed(2);
              }
            } else {
              // jika non PKP
              formData.ppn = 0;
            }
          }
          if (key === "hargaTotal") {
            formData.diskon = value * (formData.isDiskon / 100);
            formData.jumlah = formData.hargaTotal - formData.diskon;
            formData.dpp = formData.jumlah;
            formData.subtotal = formData.hargaTotal;
            formData.total = formData.subtotal + formData.ppn - formData.diskon;
            if (formData.isTransaksiPpn === true) {
              //jika pkp
              if (formData.isPpn === true) {
                //jika harga termasuk harga
                if (formData !== 0) {
                  //dengan diskon
                  formData.ppn = Math.round(
                    ((100 / 111) * formData.hargaTotal -
                      formData.diskon * ((100 / 111) * formData.hargaTotal)) *
                      TARIF_PPN
                  ).toFixed(2);
                } else {
                  //tanpa diskon
                  formData.ppn = Math.round(
                    (100 / 111) * formData.hargaTotal * TARIF_PPN
                  ).toFixed(2);
                }
              } else {
                //jika harga tidak termasuk harga
                formData.ppn = Math.round(
                  (formData.hargaTotal - formData.diskon) * TARIF_PPN
                ).toFixed(2);
              }
            } else {
              // jika non PKP
              formData.ppn = 0;
            }
          }
          if (key === "isDiskon") {
            formData.isDiskon = String(value).replace(/\D/g, "");
            if (value.length > 3) {
              formData.isDiskon = value.substring(0, 3);
            }
            if (value > 100) {
              formData.isDiskon = 100;
            }
            formData.diskon = formData.hargaTotal * (value / 100);
            formData.jumlah = formData.hargaTotal - formData.diskon;
            formData.dpp = formData.jumlah;
            formData.subtotal = formData.hargaTotal;
            formData.total = formData.subtotal + formData.ppn - formData.diskon;
            if (formData.isTransaksiPpn === true) {
              //jika pkp
              if (formData.isPpn === true) {
                //jika harga termasuk harga
                if (formData !== 0) {
                  //dengan diskon
                  formData.ppn = Math.round(
                    ((100 / 111) * formData.hargaTotal -
                      formData.diskon * ((100 / 111) * formData.hargaTotal)) *
                      TARIF_PPN
                  ).toFixed(2);
                } else {
                  //tanpa diskon
                  formData.ppn = Math.round(
                    (100 / 111) * formData.hargaTotal * TARIF_PPN
                  ).toFixed(2);
                }
              } else {
                //jika harga tidak termasuk harga
                formData.ppn = Math.round(
                  (formData.hargaTotal - formData.diskon) * TARIF_PPN
                ).toFixed(2);
              }
            } else {
              // jika non PKP
              formData.ppn = 0;
            }
          }
          if (key === "isPpn") {
            if (formData.isTransaksiPpn === true) {
              //jika pkp
              if (formData.isPpn === true) {
                //jika harga termasuk harga
                if (formData !== 0) {
                  //dengan diskon
                  formData.ppn = Math.round(
                    ((100 / 111) * formData.hargaTotal -
                      formData.diskon * ((100 / 111) * formData.hargaTotal)) *
                      TARIF_PPN
                  ).toFixed(2);
                } else {
                  //tanpa diskon
                  formData.ppn = Math.round(
                    (100 / 111) * formData.hargaTotal * TARIF_PPN
                  ).toFixed(2);
                }
              } else {
                //jika harga tidak termasuk harga
                formData.ppn = Math.round(
                  (formData.hargaTotal - formData.diskon) * TARIF_PPN
                ).toFixed(2);
              }
            } else {
              // jika non PKP
              formData.ppn = 0;
            }
          }
          if (key === "isTransaksiPpn") {
            if (formData.isTransaksiPpn === true) {
              //jika pkp
              if (formData.isPpn === true) {
                //jika harga termasuk harga
                if (formData !== 0) {
                  //dengan diskon
                  formData.ppn = Math.round(
                    ((100 / 111) * formData.hargaTotal -
                      formData.diskon * ((100 / 111) * formData.hargaTotal)) *
                      TARIF_PPN
                  ).toFixed(2);
                } else {
                  //tanpa diskon
                  formData.ppn = Math.round(
                    (100 / 111) * formData.hargaTotal * TARIF_PPN
                  ).toFixed(2);
                }
              } else {
                //jika harga tidak termasuk harga
                formData.ppn = Math.round(
                  (formData.hargaTotal - formData.diskon) * TARIF_PPN
                ).toFixed(2);
              }
            } else {
              // jika non PKP
              formData.ppn = 0;
              formData.isPpn = false;
            }
          }
        }}
        onSubmit={(data, callback) => {
          if (data.hargaTotal == 0) {
            callback("Harga Total Tidak Boleh 0", true, false);
          } else {
            if (data.isPpnbm == 1) {
              data.isPpnbm = true;
            } else {
              data.isPpnbm = false;
              data.ptsPpnbm = "0";
              data.ppnbm = "0";
            }
            data.nilaiPembelian = data.total;
            localStorage.setItem("produkPembelianForm", JSON.stringify(data));
            callback("success", false);
          }
        }}
      />
    )
  );
};

export default InputProdukPembelian;
