import React, { useEffect, useState } from "react";
import { FormWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import { useParams } from "react-router-dom";
import service from "./TutupBuku.service";

const TutupBukuDetail = ({ props, title }) => {
  const { id } = useParams();
  const [load, setload] = useState(true);
  const [data, setdata] = useState(null);
  let npwpHeadId = sessionStorage.getItem('npwpHeadId')
  const fetchData = async () => {
    try {
      let res = await service.getOneById(id, npwpHeadId);
      if(res.data.status == 1){
        res.data.status = "Diproses"
      }
      if(res.data.status == 2){
        res.data.status = "Selesai"
      }
      if(res.data.status == 3){
        res.data.status = "Error"
      }
      if(res.data.status == 4){
        res.data.status = "Batal"
      }
      res.data.tahun = "01/01/" + res.data.tahun + " - " + "31/12/" + res.data.tahun
      setdata(res.data);
      setload(false);
    } catch (error) {
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return load ? (
    "loading"
  ) : (
    <FormWrapper
      baseId="tutup-buku-detail"
      showCommandbar={true}
      title={t.translate('modules.tutupBuku.detail')}
      backTo={`/internal/npwp/${npwpHeadId}/tutup-buku`}
      defaultData={data}
      definitions={[
        {
          inputType: FormWrapper.inputTypes.INPUT,
          label: t.translate(`words.periodeTutupBuku`),
          key: "tahun",
          disabled:true
        },
        {
          inputType: FormWrapper.inputTypes.INPUT_MASK_NUMBER,
          label: t.translate(`words.labaKomersial`),
          key: "labaKomersial",
          thousandSeparator: ".",
          decimalSeparator: ",",
          prefix: "Rp. ",
          isNumericString: true,
          type: "text",
          disabled:true
        },
        {
          inputType: FormWrapper.inputTypes.INPUT,
          label: t.translate(`words.statusTutupBuku`),
          key: "status",
          disabled:true
        },
        {
          inputType: FormWrapper.inputTypes.INPUT,
          label: t.translate(`words.pesanError`),
          key: "pesanError",
          show:(d)=>{
            return d.pesanError!=null
          },
          disabled:true
        },
      ]}
    />
  );
};

export default TutupBukuDetail;
