import React from 'react'
import { Router, Switch, Route } from "react-router-dom"
import { PageWrapper } from '../../libs/react-mpk/wrapper'
import { sso } from '../../libs/react-mpk/hocs'
import { DashboardSample, TableSample, FormSample } from '../../modules'

const Company = ({history}) => {
  return (
    <PageWrapper>
      <Router history={history}>
        <Switch>
          <Route
            path='/product/company/:companyId/dashboard'
            exact={true}
            render={props => (
              <DashboardSample {...props}/>
            )}
          />
          <Route
            path='/product/company/:companyId/table'
            exact={true}
            render={props => (
              <TableSample {...props}/>
            )}
          />
          <Route
            path='/product/company/:companyId/form'
            exact={true}
            render={props => (
              <FormSample {...props}/>
            )}
          />
          <Route
            path='/product/company/:companyId/details/:tab'
            render={props => {
              <FormSample {...props}/>
            }}
          />
        </Switch>
      </Router>
    </PageWrapper>
  )
}

export default sso({
  basePath: '/product/company/:companyId',
  url:{
    me:'/api/sso/company/:companyId/me'
  }
})(Company)
