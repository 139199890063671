import React, { useState } from "react";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import { DataForm } from "../../libs/react-mpk/components";
import t from "counterpart";
import service from "./AkunNpwp.service";
import moment from "moment";
import "moment/locale/id";
import { inputTypes } from "../../libs/react-mpk/config/constant";
import AkunNpwpForm from "./AkunNpwpForm";
import { inject, observer } from "mobx-react";
import utilsService from "../../services/utilsService";

const AkunNpwp = ({ className = "", showCommandbar = true }) => {
  const [showForm, setShowForm] = useState(false);
  const [disabledForm, setDisabledForm] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  let [search, setSearch] = useState({});
  const baseId = "akun-npwp";
  const basePath = `/internal/npwp`;

  const pilihNpwp = async (item) => {
    try {
      var tahunSobatBuku = sessionStorage.getItem("tahunSobatBuku");
      let res = await service.getOneById(item.id, tahunSobatBuku);
      if (res.status === 200) {
        sessionStorage.setItem("dataPenggunaNpwp", JSON.stringify(item));
        sessionStorage.setItem("npwpHead", item.npwp);
        sessionStorage.setItem("npwpHeadId", item.id);
        window.location.href = `${basePath}/${item.id}/dashboard`;
      }
    } catch (e) {
      alert(`${e}`);
    }
  };

  const migrasi = async () => {
    try {
      const res = await service.migrasi();

      let { data } = res;

      // cekNpwpExist();
    } catch (err) {}
  };

  // const cekNpwpExist = async () => {
  //   try {
  //     const res = await service.cekNpwpExist();
  //     let { data } = res;
  //     // console.log(data.message, 'data.message')
  //     if (data.message === "Lanjut Dashboard") {
  //     } else if (data.message === "Create NPWP Baru") {
  //       setShowFormAkunNpwp(true);
  //     } else if (data.message === "Update NPWP") {
  //       setShowFormMigrasiDataAwal(true);
  //     } else if (data.message === "Tidak Bisa Create NPWP") {
  //       alert(
  //         `Tidak Bisa Create NPWP Di tahun ${sessionStorage.getItem(
  //           "tahunSobatBuku"
  //         )}`
  //       );
  //       sessionStorage.setItem(
  //         "tahunSobatBuku",
  //         new Date().getFullYear().toString()
  //       );
  //       window.location.reload();
  //       // cekNpwpExist()
  //     }
  //   } catch (err) {}
  // };

  var actions = [
    new TableWrapper.action(
      `${t.translate("words.add")}`,
      "mdi mdi-plus",
      () => {
        setDisabledForm(false);
        setSelectedItemId(null);
        setSelectedItem(null);
        setShowForm(true);
      },
      true
    ),
  ];

  var itemActions = [
    new TableWrapper.action(
      `${t.translate("words.pilihAkun")}`,
      "mdi mdi-import",
      (item) => {
        pilihNpwp(item);
      },
      true
    ),
    // new TableWrapper.action(
    //   `${t.translate("words.selengkapnya")}`,
    //   "mdi mdi-history",
    //   (item) => {
    //     setDisabledForm(true);
    //     setSelectedItemId(item.id);
    //     setSelectedItem(item);
    //     setShowForm(true);
    //   },
    //   true
    // ),
    // new TableWrapper.action(
    //   `${t.translate("words.edit")}`,
    //   "mdi mdi-pencil",
    //   (item) => {
    //     setDisabledForm(false);
    //     setSelectedItemId(item.id);
    //     setSelectedItem(item);
    //     setShowForm(true);
    //   },
    //   true
    // ),
  ];

  return (
    <>
      <TableWrapper
        baseId={baseId}
        title={t.translate("modules.akunNpwp.title")}
        className={className}
        hintMessage={t.translate("modules.akunNpwp.hint.message")}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate("modules.akunNpwp.hint.more")}
        defaultData={[]}
        defaultSort="ASC"
        defaultSortBy="tanggalDibuat"
        showFilter={false}
        showActionColumn={true}
        useFilter={true}
        defaultCollapse={true}
        useCriteria={true}
        selectable={false}
        useQueryHistory={false}
        onFetchData={(query) => {
          return new Promise(async (resolve, reject) => {
            try {
              query = {
                ...query,
              };
              let getPage = await service.get(query);
              getPage.headers["x-pagination-count"] =
                getPage.headers["x-total-count"];
              resolve(getPage);
            } catch (e) {
              resolve();
            }
          });
        }}
        columns={[
          {
            label: "NPWP",
            searchable: true,
            sortable: false,
            key: "npwp",
            render: (item) => utilsService.parseNPWP(item.npwp),
          },
          {
            label: "Nama NPWP",
            searchable: true,
            sortable: false,
            key: "namaNpwp",
            render: (item) => item.namaNpwp,
          },
          {
            label: "Email",
            searchable: true,
            sortable: false,
            key: "email",
            render: (item) => item.email,
          },
          {
            label: "Tipe NPWP",
            type: TableWrapper.dataTypes.BOOLEAN,
            render: (item) => (
              <TableWrapper.Status
                type={
                  item.npwpOp
                    ? TableWrapper.Status.types.SUCCESS
                    : TableWrapper.Status.types.ERROR
                }
              >
                {item.npwpOp ? "Orang Pribadi" : "Badan"}
              </TableWrapper.Status>
            ),
          },
          {
            label: "Tanggal Dibuat",
            type: TableWrapper.dataTypes.DATE,
            render: (item) =>
              moment(item.tanggalDibuat).locale("id").format("ll"),
          },
        ]}
        actions={actions}
        itemActions={itemActions}
        showCommandbar={showCommandbar}
        showFilterPeriod={false}
      />
      <AkunNpwpForm
        visible={showForm}
        onRequestClose={() => {
          setShowForm(false);
          setSelectedItemId(null);
          setSelectedItem(null);
          document.getElementsByClassName("mdi-reload")[0].click();
          // migrasi();
        }}
        itemId={selectedItemId}
        item={selectedItem}
        isDisabled={disabledForm}
      />
    </>
  );
};
export default inject(
  "navigationStore",
  "envStore",
  "modalStore"
)(observer(AkunNpwp));
