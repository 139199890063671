import React from "react";
import { TableWrapper } from "../../libs/react-mpk/wrapper";

export default function ProdukTable({
  baseId = "",
  className = "",
  title = "",
  onFetchData=()=>{},
  actions=[],
  itemActions=[],
  showCommandbar=true
}) {
  return (
    <TableWrapper
      baseId={baseId}
      title={title}
      className={className}
      defaultData={[]}
      defaultSortBy="tanggalDibuat"
      showFilter={false}
      showActionColumn={true}
      useFilter={true}
      defaultCollapse={true}
      useCriteria={true}
      selectable={false}
      useQueryHistory={false}
      onFetchData={onFetchData}
      columns={[
        {
          label: "Kode",
          searchable: true,
          sortable: false,
          key: "kode",
          render: (item) => item.kode,
        },
        {
          label: "Nama",
          searchable: true,
          sortable: false,
          key: "nama",
          render: (item) => item.nama,
        },
        {
          label: "Kategori",
          searchable: false,
          sortable: false,
          key: "kategori",
          render: (item) => item.kategori.nama,
        },
        {
          label: "Unit",
          searchable: false,
          sortable: false,
          key: "unit",
          render: (item) => item.unit.nama,
        },
      ]}
      actions={actions}
      itemActions={itemActions}
      showCommandbar={showCommandbar}
      showFilterPeriod={false}
    />
  );
}
