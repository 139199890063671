import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Text,
} from "react-md";
import { Document,pdfjs, Page } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
const options = {
  cMapUrl: "cmaps/",
  cMapPacked: true,
  standardFontDataUrl: "standard_fonts/",
};

function PreviewFile({
  visible = false,
  onRequestClose = () => {},
  onClickDownload = () => {},
  file = null,
  type = "",
}) {
  const [numPages, setNumPages] = useState(null);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  return (
    <>
      <Dialog
        id="preview-file-dialog"
        visible={visible}
        onRequestClose={onRequestClose}
      >
        <DialogContent>
          {type == "pdf" ? (
             <Document
             file={file}
             onLoadSuccess={onDocumentLoadSuccess}
             options={options}
             
           >
             {Array.from(new Array(numPages), (el, index) => (
               <Page
                 width={850}
                 key={`page_${index + 1}`}
                 pageNumber={index + 1}
               />
             ))}
           </Document>
          ) : (
            <img width={850} height={"auto"} src={file} />
          )}
        </DialogContent>
        <DialogFooter>
          <Button onClick={onRequestClose}>Kembali</Button>
          <Button
            style={{
              backgroundColor: "#34495e",
              color: "white",
            }}
            onClick={onClickDownload}
          >
            Download File
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}

export default PreviewFile;
