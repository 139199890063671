import React, { useState, useEffect } from "react";
import t from "counterpart";
import service from "./ReportNeraca.service";
import { inject, observer } from "mobx-react";
import {
  Table,
  TableBody,
  TableHeader,
  TableRow,
  TableCell,
  Button,
  FontIcon,
  TableFooter,
  CircularProgress,
} from "react-md";
import { FormWrapper, TableWrapper } from "../../../libs/react-mpk/wrapper";
import { sum } from "lodash";
import { format, formatRupiah } from "../../../libs/react-mpk/services/number.service";
import { useHistory, useLocation } from "react-router-dom";
import { Commandbar } from "../../../libs/react-mpk/components";
import { toast } from "../../../libs/react-mpk/services";
import AsyncButton from "../../../libs/react-mpk/components/AsyncButton/AsyncButton";

const ReportNeracaHarta = ({
  className = "",
  showCommandbar = true,
  navigationStore,
  match,
}) => {
  let history = useHistory();
  const { state: { tahun } = {} } = useLocation();
  let tahunSobatPajak = sessionStorage.getItem("tahunSobatBuku");
  var npwpHeadId = match.params.npwpHeadId;

  const [data, setData] = useState([]);
  const [dataTetap, setDataTetap] = useState([]);
  const [dataAll, setDataAll] = useState([]);
  const [load, setload] = useState(true);
  const [loading, setLoading] = useState(false);
  const fetchData = async () => {
    try {
      let query = {
        tahun: tahun ? tahun : tahunSobatPajak,
        bulan:1
      };
      let res = await service.getAllHartaLancarTetap(query);
      setData(res.data.hartaLancarList);

      setDataTetap(res.data.hartaTetapList);

      setDataAll(res.data);

      setload(false);
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
  }, []);

  async function DownloadLaporanHarta() {
    try {
      setLoading(true);
      await service.DownloadLaporanNeraca(1,npwpHeadId,tahun ? tahun : tahunSobatPajak);
      setLoading(false);
    } catch (e) {
      toast.errorRequest(e);
      setLoading(false);
    }
  }
  return (
    <>
      <Commandbar
        title={t.translate(`words.neracaHarta`)}
        rightCorner={[
          <AsyncButton
            loading={loading}
            labelButton={'Download Laporan'}
            showIcon={true}
            onClickDownload={() => {
              DownloadLaporanHarta();
            }}
          />
        ]}
      />
      <FormWrapper
        loading={load}
        showBackTo={false}
        baseId="mod-table-laporan-neraca-harta-lancar"
        showCommandbar={false}
        style={{
          maxWidth: "100%",
        }}
        definitions={[
          {
            render: (
              <div style={{ width: "100%" }}>
                <p style={{ margin: "10px", color: "purple" }}>
                  <b>{t.translate(`words.hartaLancar`)}</b>
                </p>
              </div>
            ),
          },
          {
            render: (
              <div style={{ width: "100%" }}>
                <Table style={{ width: "100%" }}>
                  <TableHeader>
                    <TableRow>
                      <TableCell style={{ width: "10%" }}>
                        {t.translate("words.non")}
                      </TableCell>
                      <TableCell style={{ width: "10%" }}>
                        {t.translate("words.aksi")}
                      </TableCell>
                      <TableCell style={{ width: "45%" }}>
                        {t.translate("words.kategoriAkun")}
                      </TableCell>
                      <TableCell style={{ width: "45%" }}>
                        {t.translate("words.nominal")}
                      </TableCell>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {data.map((d, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>
                            <Button
                              theme="primary"
                              // themeType="contained"
                              // style={{ margin: 15 }}
                              onClick={() => {
                                history.push({
                                  pathname: `/internal/npwp/${npwpHeadId}/report/detail-laporan-neraca/${d.kategoriAkun.id}`,
                                  state: { tahun: tahun,harta:true,utangModal:false },
                                });
                                // navigationStore.redirectTo(
                                //     `/internal/npwp/${npwpHeadId}/report/detail-laporan-neraca/${d.kategoriAkun.id}`,{tahun:d.tahun}
                                // )
                                sessionStorage.setItem(
                                  "kategoriAkunNama",
                                  d.kategoriAkun.nama
                                );
                                sessionStorage.setItem(
                                  "kategoriAkunKode",
                                  d.kategoriAkun.kode
                                );
                              }}
                            >
                              <FontIcon iconClassName="mdi mdi-import" />
                              {/* {t.translate("words.add")} */}
                            </Button>
                          </TableCell>
                          <TableCell>
                            {d.kategoriAkun.kode + " - " + d.kategoriAkun.nama}
                          </TableCell>
                          <TableCell>{formatRupiah(d.saldoAkhir)}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell>
                        <b>{t.translate(`words.totalHartaLancar`)}</b>
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell>
                        <b>{formatRupiah(dataAll.totalHartaLancar)}</b>
                      </TableCell>
                    </TableRow>
                    {/* <TableRow>
                                            <TableCell><b>{t.translate(`words.total`)}</b></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell>{Array.prototype.sum = function (prop) {
                                                var total = 0
                                                for (var i = 0, _len = this.length; i < _len; i++) {
                                                    total += this[i][prop]
                                                }
                                                return total
                                            }}<b>{data.sum("saldoAkhir")}</b></TableCell>
                                        </TableRow> */}
                  </TableFooter>
                </Table>
              </div>
            ),
          },
          {
            render: (
              <div style={{ width: "100%" }}>
                <p style={{ margin: "10px", color: "purple" }}>
                  <b>{t.translate(`words.hartaTetap`)}</b>
                </p>
              </div>
            ),
          },
          {
            render: (
              <div style={{ width: "100%" }}>
                <Table style={{ width: "100%" }}>
                  <TableHeader>
                    <TableRow>
                      <TableCell style={{ width: "10%" }}>
                        {t.translate("words.non")}
                      </TableCell>
                      <TableCell style={{ width: "10%" }}>
                        {t.translate("words.aksi")}
                      </TableCell>
                      <TableCell style={{ width: "45%" }}>
                        {t.translate("words.kategoriAkun")}
                      </TableCell>
                      <TableCell style={{ width: "45%" }}>
                        {t.translate("words.nominal")}
                      </TableCell>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {dataTetap.map((d, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>
                            <Button
                              theme="primary"
                              // themeType="contained"
                              // style={{ margin: 15 }}
                              onClick={() => {
                                history.push({
                                  pathname: `/internal/npwp/${npwpHeadId}/report/detail-laporan-neraca/${d.kategoriAkun.id}`,
                                  state: { tahun: tahun },
                                });
                                // navigationStore.redirectTo(
                                //     `/internal/npwp/${npwpHeadId}/report/detail-laporan-neraca/${d.kategoriAkun.id}`
                                // )
                                sessionStorage.setItem(
                                  "kategoriAkunNama",
                                  d.kategoriAkun.nama
                                );
                                sessionStorage.setItem(
                                  "kategoriAkunKode",
                                  d.kategoriAkun.kode
                                );
                              }}
                            >
                              <FontIcon iconClassName="mdi mdi-import" />
                              {/* {t.translate("words.add")} */}
                            </Button>
                          </TableCell>
                          <TableCell>
                            {d.kategoriAkun.kode + " - " + d.kategoriAkun.nama}
                          </TableCell>
                          <TableCell>{formatRupiah(d.saldoAkhir)}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell>
                        <b>{t.translate(`words.totalHartaTetap`)}</b>
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell>
                        <b>{formatRupiah(dataAll.totalHartaTetap)}</b>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>{t.translate(`words.totalHarta`)}</b>
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell>
                        <b>{formatRupiah(dataAll.totalHarta)}</b>
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </div>
            ),
          },
        ]}
        onChangeQuery={async (formData, key, value) => {
          if (formData === "total") {
            formData.total = sum(formData.nominal);
          }
        }}
      />
    </>
  );
};

export default inject(
  "navigationStore",
  "envStore"
)(observer(ReportNeracaHarta));
