import React, { useEffect, useState } from 'react';
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { CustomWrapper, FormWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { find } from 'lodash'
import service from './StatusPkp.service'
import { DataForm } from '../../../libs/react-mpk/components';
import { useParams } from 'react-router';
import cloneDeep from 'lodash.clonedeep';
import { toast } from '../../../libs/react-mpk/services';

const StatusPkp = ({ envStore }) => {
	const { id } = useParams()
	const { inputTypes, definition } = FormWrapper
	let tahunSobatPajak = sessionStorage.getItem('tahunSobatBuku');
	let npwpHeadId = sessionStorage.getItem('npwpHeadId');
	//VALIDASI
	const [data, setdata] = useState({});
	const [load, setload] = useState(true);
	const fetchData = async () => {
		try {
			let res = await service.getOneById(id)
			setdata(res.data);
			setload(false);
		} catch (error) {
			toast.errorRequest(error)
		}
	}
	useEffect(() => {
		fetchData();
	}, []);
	return load ? "loading" : (
			<FormWrapper
				baseId="pengaturan-status-pkp"
				title={t.translate('modules.statusPkp.title')}
				showBackTo={false}
				hintShowIcon={false}
				defaultData={data
					? cloneDeep(data) : {
						tempatPenandaTangan: '',
						jabatanPenandaTangan: '',
						namaPenandaTangan: '',
						cityName: '',
						npwp:'',
						namaNpwp:'',
						kota:'',
						alamat:'',
						kodepos:'',
						noHp:'',
						noTelepon:'',

					}}
				watchDefaultData={true}
				definitions={[
					{
						inputType: inputTypes.INPUT,
						label: t.translate(`words.passphrase`),
						key: 'passphrase'
					},
					{
						inputType: inputTypes.INPUT_MASK_NUMBER,
						label: t.translate(`words.npwp`),
						key: 'npwp',
						mask: '##.###.###.# - ###.###',
						maskChar: '_',
						maskValue:'string',
						disabled:true
					},
					{
						inputType: inputTypes.INPUT,
						label: t.translate(`words.name`),
						key: 'namaNpwp',
						disabled:true
					},
					{
						inputType: inputTypes.INPUT,
						label: t.translate(`words.city`),
						key: 'kota',
						disabled:true
					},
					{
						inputType: inputTypes.INPUT,
						label: t.translate(`words.address`),
						key: 'alamat',
						disabled:true
					},
					{
						inputType: inputTypes.INPUT_MASK_NUMBER,
						label: t.translate(`words.postalCode`),
						key: 'kodepos',
						mask: '#####',
						maskChar: '_',
						maskValue: 'string',
						disabled:true
					},
					{
						inputType: inputTypes.INPUT,
						label: t.translate(`words.mobile`),
						key: 'noHp',
						disabled:true
					},
					{
						inputType: inputTypes.INPUT,
						label: t.translate(`words.phone`),
						key: 'noTelepon',
					},
					{
						render: (
							<div>
								<center><p style={{ marginLeft: "10px" }}><b>{t.translate(`words.wpPenandatangan`)}</b></p></center>
							</div>
						)
					},
					{
						inputType: inputTypes.INPUT,
						label: t.translate(`words.namaPenandatangan`),
						key: 'namaPenandaTangan'
					},
					{
						inputType: inputTypes.INPUT,
						label: t.translate(`words.jabatan`),
						key: 'jabatanPenandaTangan'
					},
					{
						inputType: inputTypes.INPUT,
						label: t.translate(`words.tempatPenandatangan`),
						key: 'tempatPenandaTangan'
					},
					{
						inputType: inputTypes.FILE_INPUT,
						label: t.translate(`words.file`),
						key: 'file'
					}
				]}
				onChange={async(data, key, value) => {
					if (key == "noTelepon") {
						if (data.noTelepon==null)
							data.noTelepon="0"
						else
							data.noTelepon=data.noTelepon
					}
					return data
				}}
				onSubmit={async(data, callback) => {
					data.npwpHeadId = npwpHeadId
					data.tahun = tahunSobatPajak
					data.file = data.file
					try {
						let response = await service.createDataAwal(data)
						callback("Data Berhasil ditambah", false)
					} catch (error) {
						callback(error)
					}
					setTimeout(callback, 2000)
					//RELOAD PAGE
					// window.location.reload()
				}}
			/>
	)
}

export default inject('envStore')(observer(StatusPkp))
