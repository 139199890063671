import React, { useEffect, useState } from "react";
import { DataForm, Hint, LoaderInfo } from "../../libs/react-mpk/components";
import t from "counterpart";
import service from "./Produk.service";
import { inject, observer } from "mobx-react";
import { FormWrapper, TableWrapper } from "../../libs/react-mpk/wrapper";
import {
  Button,
  Card,
  MediaContainer,
  MediaOverlay,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Text,
} from "react-md";
import PreviewFile from "../PreviewFile/PreviewFile";
import { scryRenderedComponentsWithType } from "react-dom/test-utils";

const ProdukDetail = ({
  visible = false,
  authStore,
  envStore,
  modalStore,
  match,
  history,
  onRequestClose = () => {},
  ...props
}) => {
  let [isLoadingAll, setIsLoadingAll] = useState(true);
  let [isLoading, setIsLoading] = useState(true);
  let [isLoadingdata, setIsLoadingData] = useState(false);
  let [showDialog, setShowDialog] = useState(false);
  let [itemId, setItemId] = useState(null);
  let [item, setItem] = useState({});
  let [showGambar1, setShowGambar1] = useState(null);
  let [showGambar2, setShowGambar2] = useState(null);
  let [showGambar3, setShowGambar3] = useState(null);
  let [dataGambar1, setDataGambar1] = useState(null);
  let [dataGambar2, setDataGambar2] = useState(null);
  let [dataGambar3, setDataGambar3] = useState(null);
  let [type, setType] = useState(null);

  useEffect(() => {
    if (visible) {
      setIsLoading(true);
      fetchData();
      setIsLoading(false);
    } else {
      setShowGambar1(null);
      setShowGambar2(null);
      setShowGambar3(null);
      setDataGambar1(null);
      setDataGambar2(null);
      setDataGambar3(null);
    }
  }, [visible]);
  useEffect(() => {
    setIsLoadingAll(isLoading || isLoadingdata);
  }, [isLoading, isLoadingdata]);

  async function fetchData() {
    try {
      setIsLoadingData(true);
      let res = await service.getOneById(props.itemId);
      setItem(res.data.produk);
      res.data.produkGambarList.map(async (d, i) => {
        try {
          let query = {
            npwpHeadId: sessionStorage.getItem("npwpHeadId"),
            namaFile: d.nama,
          };
          i == 0 && setDataGambar1(d.nama);
          i == 1 && setDataGambar2(d.nama);
          i == 2 && setDataGambar3(d.nama);

          await service.downloadGambar(query).then((response) => {
            const type = response.headers["content-type"];
            let blob = new Blob([response.data], {
                type: type,
              }),
              datas = window.URL.createObjectURL(blob);
            i == 0 && setShowGambar1(datas);
            i == 1 && setShowGambar2(datas);
            i == 2 && setShowGambar3(datas);
          });
        } catch (error) {}
      });
      setIsLoadingData(false);
    } catch (error) {
      setIsLoadingData(false);
    }
  }
  return (
    <>
      <DataForm
        baseId="akun-coa-form"
        title={t.translate(`words.detailProduk`)}
        visible={visible}
        onRequestClose={onRequestClose}
        asDialog={true}
        defaultData={item}
        definitions={[
          {
            render: <LoaderInfo />,
            show: isLoadingAll,
          },
          {
            label: "List Gambar",
            render: (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignContent: "center",
                }}
              >
                {showGambar1 != null && (
                  <MediaContainer
                    style={{ marginRight: "2%", width: "32%" }}
                    className="img-hover-zoom"
                  >
                    <img
                      src={showGambar1}
                      onClick={() => {
                        setShowDialog(true);
                        setType("gambar1");
                      }}
                      style={{ height: "100%" }}
                    />
                  </MediaContainer>
                )}
                {showGambar2 != null && (
                  <MediaContainer
                    style={{ marginRight: "2%", width: "32%" }}
                    className="img-hover-zoom"
                  >
                    <img
                      src={showGambar2}
                      onClick={() => {
                        setShowDialog(true);
                        setType("gambar2");
                      }}
                      style={{ height: "100%" }}
                    />
                  </MediaContainer>
                )}
                {showGambar3 != null && (
                  <MediaContainer
                    style={{ width: "32%" }}
                    className="img-hover-zoom"
                  >
                    <img
                      src={showGambar3}
                      onClick={() => {
                        setShowDialog(true);
                        setType("gambar3");
                      }}
                      style={{ height: "100%" }}
                    />
                  </MediaContainer>
                )}
              </div>
            ),
            show: !isLoadingAll,
          },
          {
            inputType: DataForm.inputTypes.DIVIDER,
            label: "Deskripsi",
            show: !isLoadingAll,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            className: "mpk-padding-N padding-right",
            label: t.translate(`words.namaProduk`),
            key: "nama",
            disabled: true,
            style: {
              width: "50%",
            },
            show: !isLoadingAll,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate(`words.kodeProduk`),
            key: "kode",
            disabled: true,
            style: {
              width: "50%",
            },
            show: !isLoadingAll,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate(`words.jenisProduk`),
            value: item?.jenis == 1 ? "Barang" : "Jasa",
            disabled: true,
            show: !isLoadingAll,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            className: "mpk-padding-N padding-right",
            label: t.translate(`words.kategori`),
            value: item?.kategori?.nama,
            disabled: true,
            style: {
              width: "50%",
            },
            show: !isLoadingAll,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate(`words.unit`),
            value: item?.unit?.nama,
            disabled: true,
            style: {
              width: "50%",
            },
            show: !isLoadingAll,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate(`words.deskripsi`),
            key: "deskripsi",
            disabled: true,
            show: !isLoadingAll,
          },
        ]}
      />
      <PreviewFile
        visible={showDialog}
        onRequestClose={() => {
          setShowDialog(false);
        }}
        onClickDownload={() => {
          service.downloadGambarFile(
            sessionStorage.getItem("npwpHeadId"),
            (type == "gambar1" && dataGambar1) ||
              (type == "gambar2" && dataGambar2) ||
              (type == "gambar3" && dataGambar3)
          );
        }}
        file={
          (type == "gambar1" && showGambar1) ||
          (type == "gambar2" && showGambar2) ||
          (type == "gambar3" && showGambar3)
        }
        type={"image"}
      />
    </>
  );
};

export default inject(
  "navigationStore",
  "envStore",
  "modalStore"
)(observer(ProdukDetail));
