import { crud, http } from "../../../libs/react-mpk/services";

let service = null;
let serviceUrl = '/laporan'
let tahunSobatPajak = sessionStorage.getItem('tahunSobatBuku');
let npwpHeadId = sessionStorage.getItem('npwpHeadId')
let bulan = sessionStorage.getItem('bulanTransaksi')
let npwpHeadIdObject = {
    npwpHeadId: sessionStorage.getItem('npwpHeadId')
}
service = new crud(`/laporan`)

service.getAllNeracaSaldo = async ( query) => {
    query = {
        "page": 0,
        "size": 9999,
        "sort": "akun.kode,ASC",
        "npwpHeadId" : npwpHeadId,
...query
    }
    return http.request({
        method: http.methods.GET,
        url: `${serviceUrl}/neraca_saldo`,
        query,
    })
}
service.getTransaksiByID = async(query) => {
    return http.request({
      method: http.methods.GET,
      url: `${serviceUrl}/neraca_saldo/transaksi`,
      query
    })
}
service.getDetailTransaksiByID = async(query) => {
    return http.request({
      method: http.methods.GET,
      url: `${serviceUrl}/neraca_saldo/transaksi/detail`,
      query
    })
}
export default service;