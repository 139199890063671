import React, { useEffect, useState } from "react";
import { Router, Switch, Route } from "react-router-dom";
import { sso } from "../libs/react-mpk/hocs";
import { PageWrapper } from "../libs/react-mpk/wrapper";
import { DataForm } from "../libs/react-mpk/components";
import { inputTypes } from "../libs/react-mpk/config/constant";
import t from "counterpart";
import { AkunNpwp } from "../modules";

import PenggunaService from "../services/Pengguna.service";
import AkunNpwpService from "../modules/AkunNpwp/AkunNpwp.service";
import MigrasiDataAwal from "../modules/Migrasi/DataAwal/MigrasiDataAwal";
import AkunNpwpForm from "../modules/AkunNpwp/AkunNpwpForm";

import MenuUtama from "./MenuUtama";
import MenuAkunNpwp from "./MenuAkunNpwp";

const Internal = ({ history, ...props }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [optionTahun, setOptionTahun] = useState([]);
  const [defaultTahun, setDefaultTahun] = useState({
    tahun: new Date().getFullYear().toString(),
  });

  const [showFormAkunNpwp, setShowFormAkunNpwp] = useState(false);
  const [showFormMigrasiDataAwal, setShowFormMigrasiDataAwal] = useState(false);

  useEffect(() => {
    // migrasi();
    // getNpwpHeadId();
  }, []);

  const cekNpwpExist = async () => {
    try {
      const res = await AkunNpwpService.cekNpwpExist();
      let { data } = res;
      // console.log(data.message, 'data.message')
      if (data.message === "Lanjut Dashboard") {
      } else if (data.message === "Create NPWP Baru") {
        setShowFormAkunNpwp(true);
      } else if (data.message === "Update NPWP") {
        setShowFormMigrasiDataAwal(true);
      } else if (data.message === "Tidak Bisa Create NPWP") {
        alert(
          `Tidak Bisa Create NPWP Di tahun ${sessionStorage.getItem(
            "tahunSobatBuku"
          )}`
        );
        sessionStorage.setItem(
          "tahunSobatBuku",
          new Date().getFullYear().toString()
        );
        window.location.reload();
        // cekNpwpExist()
      }
    } catch (err) {}
  };

  const migrasi = async () => {
    try {
      const res = await AkunNpwpService.migrasi();

      let { data } = res;

      cekNpwpExist();
    } catch (err) {}
  };

  return (
    <>
      <Router history={history}>
        <Switch>
          <Route
            path="/internal/npwp/:npwpHeadId"
            render={(props) => <MenuUtama {...props} />}
          />
          <Route
            path="/internal/npwp"
            render={(props) => <MenuAkunNpwp {...props} />}
          />
        </Switch>
      </Router>
      <AkunNpwpForm
        visible={showFormAkunNpwp}
        onRequestClose={() => {
          cekNpwpExist();
          setShowFormAkunNpwp(false);
          // window.location.reload()
        }}
      />
      {/* <MigrasiDataAwal
        visible={showFormMigrasiDataAwal}
        onRequestClose={() => {
          cekNpwpExist()
          setShowFormMigrasiDataAwal(false)
        }}
      // itemId={selectedItemId}
      // isDisabled={disabledForm}
      /> */}
    </>
  );
};

export default sso({
  basePath: "/internal",
})(Internal);
