import { crud, http } from "../../../libs/react-mpk/services";

let service = null;
let serviceUrl = "/pengaturan";
let tahunSobatPajak = sessionStorage.getItem("tahunSobatBuku");
let npwpHeadId = sessionStorage.getItem("npwpHeadId");
service = new crud("/pengaturan");
let npwpHeadIdObject = {
  npwpHeadId: sessionStorage.getItem("npwpHeadId"),
};
service.getAll = async () => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/peta_akun_jurnal/npwp_head/${npwpHeadId}`,
  });
};
service.create = async (data) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/peta_akun_jurnal`,
    data,
  });
};
service.getAkun = async (QueryNama) => {
  let query = {
    page: 0,
    size: 20,
    sort: "kode,ASC",
    ...npwpHeadIdObject,
    "nama.contains": QueryNama,
  };
  return http.request({
    method: http.methods.GET,
    url: `/akun`,
    query,
  });
};
service.generateKodeAkun = async (kategoriAkunId) => {
  let query = {
    kategoriAkunId: kategoriAkunId,
    npwpHeadId: npwpHeadId,
  };
  return http.request({
    method: http.methods.GET,
    url: `/akun/generate_kode_akun`,
    query,
  });
};
export default service;
