import { crud, http } from "../../libs/react-mpk/services";

let service = null;
let serviceUrl = "/lawan_transaksi";
let tahunSobatPajakObjek = {
  tahun: sessionStorage.getItem("tahunSobatBuku"),
};
let tahunSobatPajak = sessionStorage.getItem("tahunSobatBuku");
let npwpHeadIdObject = {
  npwpHeadId: sessionStorage.getItem("npwpHeadId"),
};
let npwpHeadId = sessionStorage.getItem("npwpHeadId");
service = new crud(`/lawan_transaksi`);

service.getAll = async (query) => {
  query = {
    ...query,
    ...npwpHeadIdObject,
  };
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}`,
    query,
  });
};

service.post = async (data) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}`,
    data,
    config: {
      params: {
        npwpHeadId: npwpHeadId,
      },
    },
  });
};

service.edit = async (id, data) => {
  return http.request({
    method: http.methods.PUT,
    url: `${serviceUrl}/${id}`,
    data,
    config: {
      params: {
        npwpHeadId: npwpHeadId,
      },
    },
  });
};

service.delete = async (id) => {
  return http.request({
    method: http.methods.DELETE,
    url: `${serviceUrl}/${id}`,
  });
};

service.getById = async (id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/${id}`,
  });
};

service.getAkunTagihan = async (id) => {
  let query = {
    page: 0,
    size: 10,
    sort: "akun.kode,ASC",
    "kodeAkun.contains": "",
    "namaAkun.contains": "",
    npwpHeadId: npwpHeadId,
    tahun: tahunSobatPajak,
  };
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/${id}/tagihan`,
    query,
  });
};

service.getAkunTagihanSupplier = async (id, akunId, query) => {
  query = {
    page: 0,
    size: 1000,
    sort: "tanggalDibuat,DESC",
    "noInvoice.contains": "",
    npwpHeadId: npwpHeadId,
    tahun: tahunSobatPajak,
    ...query,
  };
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/${id}/tagihan/${akunId}/utang`,
    query,
  });
};

service.getAkunTagihanCustomer = async (id, akunId, query) => {
  query = {
    page: 0,
    size: 1000,
    sort: "tanggalDibuat,DESC",
    "noInvoice.contains": "",
    npwpHeadId: npwpHeadId,
    tahun: tahunSobatPajak,
    // ...query,
  };
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/${id}/tagihan/${akunId}/piutang`,
    query,
  });
};

service.getByNpwp = async (npwp) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/get_by_npwp/${npwp}`,
  });
};

export default service;
