import React, { useEffect, useState } from "react";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import Modal from "../../libs/react-mpk/components/Modal";
import t from "counterpart";
// import service from './DaftarSptArchive.service'
import moment from "moment";
import 'moment/locale/id';
import { toast } from "../../libs/react-mpk/services";
import { format, formatRupiah } from "../../libs/react-mpk/services/number.service";
import { Chip, ListItem, Button, FontIcon } from "react-md";
import errorService from "../../services/errorService";
import { reject } from "lodash";
import { inject, observer } from "mobx-react";
import iziToast from "izitoast";
import { inputTypes } from "../../libs/react-mpk/config/constant";
import DataForm from "../../libs/react-mpk/components/DataForm";
import service from "./Penjualan.service";
import utilsService from "../../services/utilsService";
import PreviewFile from "../PreviewFile/PreviewFile";

const PenjualanHistory = ({
  className = "",
  showCommandbar = true,
  history,
  match,
  envStore,
  modalStore,
}) => {
  var npwpHeadId = match.params.npwpHeadId;
  const [data, setData] = useState([]);
  let [dialog, setDialog] = useState(false);
  let [dialogId, setDialogId] = useState(null);
  let [file, setFile] = useState(null);
  let [type, setType] = useState(null);
  var itemActions = [
    new TableWrapper.action(
      "Delete",
      "mdi mdi-delete",
      (item) => {
        hapus(item);
      },
      true
    ),
  ];
  async function hapus(item) {
    modalStore.showConfirm({
      title: t.translate("words.delete"),
      children: t.translate("words.confirmHapus"),
      onSubmit: (callback) => {
        hapusExc(item);
        callback();
      },
    });
  }
  async function hapusExc(item) {
    try {
      var r = await service.deletePenerimaan(item.kasBankAkun.kasBank.noTransaksi);

      if (r.data.message == "sukses") {
        iziToast.success({
          message: r.data.message,
        });
      } else {
        iziToast.info({
          message: r.data.message,
        });
      }
      document.getElementsByClassName("mdi-reload")[0].click();
    } catch (e) {
      toast.errorRequest(e);
    }
  }
  return (
    <>
      <TableWrapper
        baseId="penjualan-riwayat"
        title="Riwayat Penjualan"
        className={className}
        hintMessage={t.translate("modules.penjualan.hint.message")}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate("modules.pesanan.hint.more")}
        showCommandbar={showCommandbar}
        showFilterPeriod={false}
        backTo={`/${envStore.env.applicationType}/npwp/${npwpHeadId}/penjualan`}
        isShowFilter={false}
        useFilter={false}
        defaultCollapse={true}
        useCriteria={true}
        selectable={false}
        useQueryHistory={false}
        showActionColumn={true}
        defaultSortBy="id"
        defaultData={data}
        itemActions={itemActions}
        onFetchData={() => {
          return new Promise(async (resolve) => {
            try {
              let res = await service.getHistory(match.params.id);
              res.headers["x-pagination-count"] = res.headers["x-total-count"];
              resolve(res);
            } catch (error) {
              resolve(error);toast.errorRequest(error)
            }
          });
        }}
        columns={[
          {
            label: t.translate("words.noTransaksi"),
            searchable: false,
            sortable: true,
            key: "noTransaksi",
            type: TableWrapper.dataTypes.STRING,
            render: (item) =>
              utilsService.beautifyNoTransaksi(item.kasBankAkun.kasBank.noTransaksi),
          },
          {
            label: t.translate("words.nominal"),
            searchable: true,
            sortable: true,
            key: "nominal",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => formatRupiah(item.nilaiTagihanDibayar),
          },
          {
            label: t.translate("words.keterangan"),
            searchable: true,
            sortable: true,
            key: "keterangan",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.kasBankAkun.kasBank.keterangan || "-",
          },
          {
            label: t.translate("words.lampiran"),
            searchable: true,
            sortable: true,
            key: "fileLampiran",
            type: TableWrapper.dataTypes.STRING,
            render: (item) =>
              (
                <Button id="icon-button-1" buttonType="icon" theme="primary" disabled={item.kasBankAkun.kasBank.fileLampiran != null?false:true}>
                  <FontIcon
                    iconClassName={item.kasBankAkun.kasBank.fileLampiran != null?`mdi mdi-file-find`:`mdi mdi-file-hidden`}
                    onClick={item.kasBankAkun.kasBank.fileLampiran != null? async () => {
                      setDialogId(item.kasBankAkun.kasBank.id);
                      service
                      .DownloadLampiranPenerimaan(item.kasBankAkun.kasBank.id)
                      .then((response) => {
                        const type = response.headers["content-type"];
                        let blob = new Blob([response.data], {
                          type: type,
                        }),
                        datas = window.URL.createObjectURL(blob);
                          setFile(datas);
                        });
                        if (item.kasBankAkun.kasBank.fileLampiran.includes(".pdf")) {
                          setType("pdf");
                        } else {
                          setType("image");
                        }
                        setDialog(true);
                    }:null}
                  />
                </Button>
              ) 
          },
          {
            label: t.translate("words.tglTransaksi"),
            searchable: true,
            sortable: true,
            key: "tanggalDibuat",
            type: TableWrapper.dataTypes.STRING,
            render: (item) =>
              moment(item.kasBankAkun.kasBank.tanggalTransaksi).locale('id').format("DD MMMM YYYY"),
          },
        ]}
      />
      <PreviewFile
        visible={dialog}
        onRequestClose={() => {
          setDialog(false);
        }}
        onClickDownload={() => {
          service.getDownloadLampiranPenerimaanId(dialogId);
        }}
        file={file}
        type={type}
      />
    </>
  );
};

export default inject(
  "temporaryStore",
  "modalStore",
  "envStore"
)(observer(PenjualanHistory));
