import { crud, http } from '../../libs/react-mpk/services';

let service = null
let serviceUrl = '/tutup_buku'
let tahunSobatPajak = sessionStorage.getItem('tahunSobatBuku');
let npwpHeadId = sessionStorage.getItem('npwpHeadId');
let npwpHeadIdObject = {
    npwpHeadId: sessionStorage.getItem('npwpHeadId')
}
service = new crud('/tutup_buku');

service.getPage = async() => {
    let query = {
        "page": 0,
        "size": 9999,
        "sort": "tanggalDibuat,DESC",
        // "tahun.equals" : sessionStorage.getItem('tahunSobatBuku'),
        "tahun.equals": "",
        "npwpHeadId" : sessionStorage.getItem('npwpHeadId')
    }
    return http.request({
        method: http.methods.GET,
        url: `${serviceUrl}`,
        query
    })
}

service.getOneById = async(id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/${id}`
  })
}

service.create = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}`,
    data
  })
}

service.deleteById = async (id)=>{
  return http.request({
    method: http.methods.DELETE,
    url: `${serviceUrl}/${id}/${npwpHeadId}`,
  })
}

export default service;