import numeral from "numeral";
import config from "../config/env";

export const format = (value = "0") => {
  var lang = localStorage.getItem("lang");
  return [undefined, null, ""].indexOf(value) >= 0
    ? "0"
    : config.localeFormat === "id" || lang == "id"
    ? numeral(value)
        .format("0,0.[00]")
        .replace(/\,/g, "&")
        .replace(/\./g, ",")
        .replace(/&/g, ".")
    : numeral(value).format("0,0.[00]");
};
export const formatRupiah = (value = "0") => {
  const formattedNumber = `Rp. ${numeral(value)
    .format("0,0.[00]")
    .replace(/\,/g, "z")
    .replace(/\./g, ",")
    .replace(/z/g, ".")}`;
  return formattedNumber;
};

export const clearFormat = (value = "0") => {
  return Number(String(value).replace(/\./g, "").replace(/\,/g, ""));
};

export const sumFormatted = function (...args) {
  let result = 0;
  for (let i = 0; i < args.length; i++) {
    let src = args[i];
    result += clearFormat(src);
  }
  return format(result);
};

export const roundFormatted = (value, round = true) => {
  let result = clearFormat(value);
  result = Math[round ? "round" : "floor"](result / 1000) * 1000;
  return format(result);
};

export const round = (value, round = true, c = 1) => {
  return Math[round ? "round" : "floor"](Number(value) / c) * c;
};

export const roundDecimal = (value, round = true, c = 1000) => {
  return Math[round ? "round" : "floor"](Number(value) * c) / c;
};

export const absolute = (value, min = 0) => {
  return min !== null ? (value < min ? min : value) : Math.abs(value);
};

export const clearFormatBulk = (
  data,
  keys = [],
  exclude = [],
  reverse = false
) => {
  return new Promise((resolve, reject) => {
    if ([undefined, null].indexOf(data) >= 0) {
      let error = new Error("Data to clear format bulk is not defined yet");
      error.status = 590;
      reject(error);
    } else {
      let result = {};
      for (let key of keys) {
        let objType = "single";
        if (key.includes(".#")) objType = "list";
        else if (key.includes(".")) objType = "object";

        if (objType === "list" || objType === "object") {
          let _keys = key.split(".");
          let rk = _keys[0];
          if (objType === "list") {
            result[rk] = [];
            for (let d of data[rk]) {
              if (_keys[2] == "*") {
                for (let k of Object.keys(d)) {
                  if (exclude.indexOf(k) < 0)
                    d[k] = reverse ? format(d[k]) : clearFormat(d[k]);
                }
              } else {
                for (let k of _keys) {
                  if (k !== rk && k !== "#") {
                    d[k] = reverse ? format(d[k]) : clearFormat(d[k]);
                  }
                }
              }
              result[rk].push(d);
            }
          } else {
            let sk = _keys[1];
            result[rk] = data[rk] || {};

            if (sk === "*") {
              for (let k of Object.keys(data[rk])) {
                if (exclude.indexOf(k) < 0)
                  result[rk][k] = reverse
                    ? format(data[rk][k])
                    : clearFormat(data[rk][k]);
              }
            } else {
              result[rk][sk] = reverse
                ? format(data[rk][sk])
                : clearFormat(data[rk][sk]);
            }
          }
        } else if (objType === "single") {
          if (key === "*") {
            for (let k of Object.keys(data)) {
              if (exclude.indexOf(k) < 0)
                result[k] = reverse ? format(data[k]) : clearFormat(data[k]);
            }
          } else {
            result[key] = reverse ? format(data[key]) : clearFormat(data[key]);
          }
        }
      }

      resolve(Object.assign(data, result));
    }
  });
};
