import React, { useEffect, useState } from 'react';
import { CustomWrapper, FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { Table, TableBody, TableHeader, TableRow, TableCell, Card, Button, FontIcon, TableFooter } from 'react-md'
import service from './Pesanan.service'
import { useParams } from "react-router-dom";
import moment from 'moment';
import PesananDetailProduk from './PesananDetailProduk';
import { format } from '../../libs/react-mpk/services/number.service';
import { toast } from '../../libs/react-mpk/services';

const PesananDetail = ({
	navigationStore,
	modalStore,
	envStore,
	match
	}) => {
	const { id } = useParams();
	const [data, setdata] = useState(null);
	const [load, setload] = useState(true);
	const [produkList, setProdukList] = useState([])

	let npwpHeadId = sessionStorage.getItem('npwpHeadId')

	const [showForm, setShowForm] = useState(false)
	const [selectedItem, setSelectedItem] = useState(null)

	const baseName = `pesanan`
	const basePath = `pesanan`
	var backTo = `/internal/pesanan`

	const fetchData = async () => {
		try {
			if (match.params.noPesanan == 'null') {
				let res = await service.getOneById(id);
				res.data.lawanTransaksiId = res.data.lawanTransaksi.nama
				res.data.npwp = res.data.lawanTransaksi.npwp
				res.data.nik = res.data.lawanTransaksi.nik
				res.data.alamat = res.data.lawanTransaksi.alamat
				res.data.namaPic = res.data.lawanTransaksi.namaPic
				res.data.emailPic = res.data.lawanTransaksi.emailPic
				res.data.noTeleponPic = res.data.lawanTransaksi.noTeleponPic
				res.data.alamatPic = res.data.lawanTransaksi.alamatPic
				res.data.tanggalPesan = moment(res.data.tanggalPesan).format('ll')
				res.data.kurirId = res.data.kurir.nama
				setdata(res.data)
				// console.log(res.data, "all data");
	
				let resproduk = await service.getProduk(id)
				setProdukList(resproduk.data)
				let produkSession = JSON.parse(localStorage.getItem("produkForm"))
			} else {
				let res = await service.getOneByNoPesanan(match.params.noPesanan)
				res.data.lawanTransaksiId = res.data.lawanTransaksi.nama
				res.data.npwp = res.data.lawanTransaksi.npwp
				res.data.nik = res.data.lawanTransaksi.nik
				res.data.alamat = res.data.lawanTransaksi.alamat
				res.data.namaPic = res.data.lawanTransaksi.namaPic
				res.data.emailPic = res.data.lawanTransaksi.emailPic
				res.data.noTeleponPic = res.data.lawanTransaksi.noTeleponPic
				res.data.alamatPic = res.data.lawanTransaksi.alamatPic
				res.data.tanggalPesan = moment(res.data.tanggalPesan).format('ll')
				res.data.kurirId = res.data.kurir.nama
				setdata(res.data)
				// console.log(res.data, "all data");
	
				let resproduk = await service.getProduk(id)
				setProdukList(resproduk.data)
				let produkSession = JSON.parse(localStorage.getItem("produkForm"))
			}
			// console.log(resproduk.data, "produk");
			
			setload(false)
		} catch (error) {
			toast.errorRequest(error)
		}
	}

	useEffect(() => {
		fetchData()
	}, [])

	return load ? "loading" : (
		<CustomWrapper
			title={t.translate('modules.pesanan.title')}
			backTo={`/${envStore.env.applicationType}/npwp/${npwpHeadId}/pesanan`}
		>
			<FormWrapper
				submitLabel={t.translate('words.submit')}
				showCommandbar={false}
				baseId={`mod-form-${baseName}`}
				style={{
					maxWidth: '100%'
				}}
				defaultData={data}
				watchDefaultData={true}
				definitions={[
					{
						render: (
							<div style={{ width: '100%' }}>
								<p><b>{t.translate(`words.identity`)}</b></p>
							</div>
						)
					},
					{
						inputType: FormWrapper.inputTypes.DIVIDER,
						label: t.translate(`words.dataLawanTransaksi`),
					},
					{
						inputType: FormWrapper.inputTypes.INPUT,
						label: t.translate(`words.nama`),
						key: 'lawanTransaksiId',
						disabled:true
					},
					{
						inputType: FormWrapper.inputTypes.INPUT_MASK_NUMBER,
						label: t.translate(`words.identityNpwp`),
						key: 'npwp',
						type: "text",
						mask: "##.###.###.#-###.###",
						maskChar: "_",
						maskValue: "string",
						disabled: true,
						width: '49%',
						style: {
							marginRight: "2%"
						}
					},
					{
						inputType: FormWrapper.inputTypes.INPUT,
						label: t.translate(`words.identityNik`),
						key: 'nik',
						disabled: true,
						width: '49%',
					},
					{
						inputType: FormWrapper.inputTypes.INPUT,
						label: t.translate(`words.address`),
						key: 'alamat',
						disabled: true,
						width: '100%'
					},
					{
						inputType: FormWrapper.inputTypes.DIVIDER,
						label: t.translate(`words.picPenagihan`),
					},
					{
						inputType: FormWrapper.inputTypes.INPUT,
						label: t.translate(`words.namaPic`),
						key: 'namaPic',
						disabled: true,
						width: '49%',
						style: {
							marginRight: "2%"
						}
					},
					{
						inputType: FormWrapper.inputTypes.INPUT,
						label: t.translate(`words.emailPic`),
						key: 'emailPic',
						disabled: true,
						width: '49%',
					},
					{
						inputType: FormWrapper.inputTypes.INPUT,
						label: t.translate(`words.nomorTeleponPic`),
						key: 'noTeleponPic',
						disabled: true,
						width: '49%',
						style: {
							marginRight: "2%"
						}
					},
					{
						inputType: FormWrapper.inputTypes.INPUT,
						label: t.translate(`words.alamatPic`),
						key: 'alamatPic',
						type: 'text',
						disabled: true,
						width: '49%',
					},
					//------------------------------------------------------------
					{
						render: (
							<div style={{ width: '100%' }}>
								<p><b>{t.translate(`words.transaksi`)}</b></p>
							</div>
						)
					},
					{
						inputType: FormWrapper.inputTypes.DIVIDER,
						label: t.translate(`words.dataPesanan`)
					},
					// {
					// 	inputType: inputTypes.INPUT,
					// 	label: t.translate(`words.nomorTransaksi`),
					// 	key: 'nomorTransaksi',
					// 	type: 'text',
					// 	disabled: true,
					// 	width: '49%',
					// 	style: {
					// 		marginRight: "2%"
					// 	}
					// },
					// {
					// 	inputType: inputTypes.SELECT,
					// 	label: t.translate(`words.kategoriAset`),
					// 	key: 'kategoriAset',
					// 	type: 'text',
					// 	validation: 'required',
					// 	options: [
					// 		{label:"Manual", value:"manual"}
					// 	],
					// },
					{
						inputType: FormWrapper.inputTypes.INPUT,
						label: t.translate(`words.tanggalPesanan`),
						key: 'tanggalPesan',
						disabled:true
					},
					{
						inputType: FormWrapper.inputTypes.INPUT,
						label: t.translate(`words.kurir`),
						key: 'kurirId',
						disabled:true
					},
					//----------------------------------------------------------
					{
						render: (
							<div style={{ width: '100%' }}>
								<p><b>{t.translate(`words.produk`)}</b></p>
							</div>
						)
					},
					{
						render: (
							<div style={{ width: '100%' }}>
								<Table style={{ width: '100%' }}>
									<TableHeader>
										<TableRow>
											<TableCell style={{ width: '10%' }}>{t.translate('words.non')}</TableCell>
											<TableCell style={{ width: '25%' }}>{t.translate('words.kode')}</TableCell>
											<TableCell style={{ width: '25%' }}>{t.translate('words.nama')}</TableCell>
											<TableCell style={{ width: '25%' }}>{t.translate('words.jumlah')}</TableCell>
											<TableCell style={{ width: '30%' }}>{t.translate('words.subtotal')}</TableCell>
											<TableCell style={{ width: '10%' }}>{t.translate('words.dpp')}</TableCell>
											<TableCell style={{ width: '10%' }}>{t.translate('words.ppn')}</TableCell>
											
										</TableRow>
									</TableHeader>
									<TableBody>
										{produkList.map((d, index) => {
											return (
												<TableRow key={index}>
													<TableCell>{index + 1}</TableCell>
													<TableCell
                                                        onClick={(item) => {
                                                            // setShowForm(true)
															// setSelectedItem(item)
                                                        }}
                                                        // style={{ color: 'purple', fontWeight: "bolder" }}
                                                    >{d.produk.kode}
													</TableCell>
													<TableCell>{d.produk.nama}</TableCell>
													<TableCell>{d.jumlahBarang}</TableCell>
													<TableCell>Rp. {format(d.subtotal)}</TableCell>
													<TableCell>Rp. {format(d.dpp)}</TableCell>
													<TableCell>Rp. {format(d.ppn)}</TableCell>
													
													<TableCell>
														{/* <Button onClick={()=> console.log("Masuk")}>{t.translate("words.edit")}</Button> */}
														{/* <Button onClick={() => deleteProduk(index)}>{t.translate("words.delete")}</Button> */}
													</TableCell>
												</TableRow>
											)
										})}
									</TableBody>
									<TableFooter>
										<TableRow>
											<TableCell></TableCell>
											<TableCell><b>Total Pemesanan</b></TableCell>
											<TableCell></TableCell>
											<TableCell><b>Total DPP</b></TableCell>
											<TableCell></TableCell>
											<TableCell><b>Total PPn</b></TableCell>
											<TableCell></TableCell>
											
										</TableRow>
										{/* {dataProdukSubtotal.map((d) => { */}
											{/* return ( */}
												<TableRow>
													<TableCell></TableCell>
													<TableCell><b>Rp. {format(data.totalPesanan)}</b></TableCell>
													<TableCell></TableCell>
													<TableCell><b>Rp. {format(data.totalDpp)}</b></TableCell>
													<TableCell></TableCell>
													<TableCell><b>Rp. {format(data.totalPpn)}</b></TableCell>
													<TableCell></TableCell>
													
												</TableRow>
											{/* )
										})} */}
									</TableFooter>
								</Table>
							</div>
						)
					},
					{
						render: (
							<div style={{ margin: 10 }}></div>
						)
					},
				]}
			/>
			{/* <PesananDetailProduk
				visible={showForm}
				onRequestClose={() => {
				setShowForm(false)
				setSelectedItem(null)
				}}
				item={selectedItem}
			/> */}
		</CustomWrapper>
	)
}

export default inject('envStore', 'modalStore', 'navigationStore')(observer(PesananDetail))
