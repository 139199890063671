import { crud, http } from "../../libs/react-mpk/services";

let service = null;
let serviceUrl = "/marketplace";
let tahunSobatPajak = sessionStorage.getItem("tahunSobatBuku");
let npwpHeadId = sessionStorage.getItem("npwpHeadId");
let npwpHeadIdObject = {
  npwpHeadId: sessionStorage.getItem("npwpHeadId"),
};
service = new crud("/marketplace");

//MarketPlace
service.createMarketplace = async (data) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/user/create_marketplace`,
    data,
  });
};
//USER
service.getUser = async (query) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/user/get_all`,
    query,
  });
};

service.getUserById = async (id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/user/${id}`,
  });
};

service.createUser = async (data) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/user`,
    data,
  });
};

//PRODUK
service.getProduk = async (query) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/produk`,
    query,
  });
};

service.getProdukById = async (id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/produk/${id}`,
  });
};

service.createProduk = async (data) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/produk`,
    data,
  });
};

//OAUTH ACCESS
service.getOauthByIdUser = async (id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/oauth_access/${id}`,
  });
};

service.GenerateOauthAccess  = async (id) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/oauth_access/${id}`,
  });
};

//ORDER
service.getOrder = async (query) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/order`,
    query,
  });
};

service.getOrderById = async (id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/order/${id}`,
  });
};

service.createOrder = async (data) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/order`,
    data,
  });
};

//ORDER - UPDATE STATUS
service.orderAccept = async (id) => {
  return http.request({
    method: http.methods.PUT,
    url: `${serviceUrl}/order/accept/${id}`,
  });
};
service.orderReject = async (id) => {
  return http.request({
    method: http.methods.PUT,
    url: `${serviceUrl}/order/reject/${id}`,
  });
};
service.orderFinish = async (id) => {
  return http.request({
    method: http.methods.PUT,
    url: `${serviceUrl}/order/finish/${id}`,
  });
};

export default service;
