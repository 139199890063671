import { crud, http } from '../../../libs/react-mpk/services';
import httpService from '../../../libs/react-mpk/services/http.service';

let service = null
let serviceUrl = '/pengaturan'
let tahunSobatPajak = sessionStorage.getItem('tahunSobatBuku');
let npwpHeadId = sessionStorage.getItem('npwpHeadId');
service = new crud('/pengaturan');

service.getOneById = async() => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/status_pkp/npwp_head/${npwpHeadId}/${tahunSobatPajak}`
  })
}
service.createDataAwal = async(data) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/status_pkp`,
    data: http.objectToFormData(data),
    config: {headers:{'content-type':'multipart/form-data'}}
  })
}
export default service;