import { crud, http } from "../../libs/react-mpk/services";

let service = null;
let serviceUrl = "/penjualan";
let npwpHeadIdObject = {
  npwpHeadId: sessionStorage.getItem("npwpHeadId"),
};
let npwpHeadId = sessionStorage.getItem("npwpHeadId");
let tahunSobatBuku = sessionStorage.getItem("tahunSobatBuku");
service = new crud(`/penjualan`);

service.getAll = async (query) => {
  query = {
    ...query,
    ...npwpHeadIdObject,
  };
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}`,
    query,
  });
};

service.deleteNonPkp = async (id) => {
  let query = {
    npwpHeadId: npwpHeadId,
  };
  return http.request({
    method: http.methods.DELETE,
    url: `${serviceUrl}/${id}/deletenonpkp`,
    query,
  });
};

service.deletePkp = async (id) => {
  let query = {
    npwpHeadId: npwpHeadId,
  };
  return http.request({
    method: http.methods.DELETE,
    url: `${serviceUrl}/${id}/deletepkp`,
    query,
  });
};

service.deletePenerimaan = async (noTransaksi) => {
  return http.request({
    method: http.methods.DELETE,
    url: `/kas_bank/penerimaan/delete/${npwpHeadId}/${noTransaksi}`,
  });
};
service.getHistory = async (id) => {
  let query = {
    npwpHeadId: npwpHeadId,
    "fkId.equals": id,
  };
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/riwayat_transaksi`,
    query,
  });
};

service.getById = async (id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/${id}`,
  });
};

service.postNonPkp = async (data) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/non_pkp`,
    data,
  });
};

service.postPkp = async (data) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/pkp`,
    data,
  });
};

service.uploadFkPkp = async (id) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/pkp/${id}/upload`,
  });
};

service.getLawanTr = async (queryNama) => {
  let query = {
    ...npwpHeadIdObject,
    page: 0,
    size: 5,
    sort: "tanggalDibuat,DESC",
    "kategori.equals": 1,
    "nama.contains": queryNama,
  };
  return http.request({
    method: http.methods.GET,
    url: `/lawan_transaksi`,
    query,
  });
};

service.getPiutang = async (queryNama) => {
  let query = {
    ...npwpHeadIdObject,
    size: 10,
    "nama.contains": queryNama,
  };
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/akun_piutang`,
    query,
  });
};

service.getBeban = async (query) => {
  query = {
    ...npwpHeadIdObject,
    ...query,
    page: 0,
    size: 999,
    sort: "kode,ASC",
  };
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/akun_beban`,
    query,
  });
};

service.getPenjualan = async (queryNama, queryKode) => {
  let query = {
    ...npwpHeadIdObject,
    size: 10,
    "nama.contains": queryNama,
    "kode.contains": queryKode,
  };
  return http.request({
    method: http.methods.GET,
    url: `/akun`,
    query,
  });
};

service.batalkan = async (id) => {
  return http.request({
    method: http.methods.PUT,
    url: `${serviceUrl}/${id}/batal`,
  });
};

service.batalkanPkp = async (id) => {
  return http.request({
    method: http.methods.PUT,
    url: `${serviceUrl}/${id}/batal_pkp`,
  });
};

service.getKasBank = async (queryNama) => {
  let query = {
    ...npwpHeadIdObject,
    size: 10,
    "nama.contains": queryNama,
  };
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/akun_kasbank`,
    query,
  });
};

service.getProduk = async (queryNama) => {
  let query = {
    ...npwpHeadIdObject,
    size: 10,
    "nama.contains": queryNama,
  };
  return http.request({
    method: http.methods.GET,
    url: `/produk`,
    query,
  });
};

service.getPasal = async () => {
  return http.request({
    method: http.methods.GET,
    url: `/pasal_pemotongan`,
  });
};

service.getObjek = async () => {
  return http.request({
    method: http.methods.GET,
    url: `/objek_pajak`,
  });
};

service.getJenisPenghasilan = async () => {
  return http.request({
    method: http.methods.GET,
    url: `/jenis_penghasilan`,
  });
};

service.getTransaksi = async () => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/fp/transaksi`,
  });
};

service.getKeterangan = async () => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/fp/keterangan`,
  });
};

service.postInvoiceNonPkp = async (id, data) => {
  return http.request({
    method: http.methods.POST,
    url: `/pesanan/${id}/generate_invoice/non_pkp`,
    data,
  });
};

service.upload = async (file) => {
  let data = {
    file: file,
    npwpHeadId: npwpHeadId,
  };
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/lampiran/upload`,
    data: http.objectToFormData(data),
    config: { headers: { "content-type": "multipart/form-data" } },
  });
};

service.getDownloadLampiranById = async (id) => {
  return http.download(`${serviceUrl}/lampiran/download/${id}`);
};

service.DownloadLampiranPenjualan = async (id) => {
  return http.request({
    method: http.methods.GET,
    url: `/penjualan/lampiran/download/${id}`,
    config: {
      responseType: "arraybuffer",
    },
  });
};
service.getDownloadLampiranPenerimaanId = async (id) => {
  return http.download(`/kas_bank/penerimaan/download/${id}`);
};
service.DownloadLampiranPenerimaan = async (id) => {
  return http.request({
    method: http.methods.GET,
    url: `/kas_bank/penerimaan/download/${id}`,
    config: {
      responseType: "arraybuffer",
    },
  });
};

service.downloadInvoice = async (id) => {
  return http.download(`${serviceUrl}/invoice/download/${npwpHeadId}/${id}`);
};

service.downloadInvoiceBulk = async (listFkId) => {
  return http.download(
    `${serviceUrl}/invoice/download_bulk?npwpHeadId=${npwpHeadId}&listFkId=${listFkId.toString()}`
  );
};

service.previewInvoice = async (id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/invoice/download/${npwpHeadId}/${id}`,
    config: {
      responseType: "arraybuffer",
    },
  });
};

service.getByTransactionId = async (noInvoice) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/no_transaksi/${noInvoice}`,
  });
};
service.createPenerimaan = async (data) => {
  return http.request({
    method: http.methods.POST,
    url: `/kas_bank/penerimaan`,
    data,
  });
};
service.uploadPenerimaan = async (file) => {
  let data = {
    file: file,
    npwpHead: npwpHeadId,
  };
  // console.log(data, 'data')
  return http.request({
    method: http.methods.POST,
    url: `/kas_bank/penerimaan/upload`,
    data: http.objectToFormData(data),
    config: { headers: { "content-type": "multipart/form-data" } },
  });
};
service.getListPenjualanById = async (id) => {
  let query = {
    page: 0,
    size: 9999,
    sort: "id,DESC",
    "kodeProduk.contains": "",
    "namaProduk.contains": "",
  };
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/${id}/produk`,
    query,
  });
};

service.getListAkunPenjualanById = async (id) => {
  let query = {
    page: 0,
    size: 9999,
    sort: "id,DESC",
    "kodeProduk.contains": "",
    "namaProduk.contains": "",
  };
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/${id}/akun_penjualan`,
    query,
  });
};
service.getAkunPenerimaan = async (queryNama) => {
  let query = {
    size: 10,
    "tahun.equals": tahunSobatBuku,
    npwpHead: npwpHeadId,
    "nama.contains": queryNama,
  };
  return http.request({
    method: http.methods.GET,
    url: `/kas_bank/penerimaan/akun`,
    query,
  });
};

service.getListAkunBebanById = async (id) => {
  let query = {
    page: 0,
    size: 9999,
    sort: "id,DESC",
    "kodeProduk.contains": "",
    "namaProduk.contains": "",
  };
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/${id}/akun_beban`,
    query,
  });
};

service.getJurnalEntryByNoTransaksi = async (noTransaksi, debitKredit) => {
  let query = {
    page: 0,
    size: 9999,
    sort: "tanggalDibuat,DESC",
    npwpHeadId: sessionStorage.getItem("npwpHeadId"),
    noTransaksi: noTransaksi,
    debitKredit: debitKredit,
  };
  return http.request({
    method: http.methods.GET,
    url: `/jurnal_entry/get_by_no_transaksi`,
    query,
  });
};

service.getNSFP = async (npwpHeadId, tahun) => {
  return http.request({
    method: http.methods.GET,
    url: `/enofa/current/${npwpHeadId}/${tahun}`,
  });
};
export default service;
