import React, { useState } from 'react'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import { FormWrapper, TableWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { find } from 'lodash'

const statusBayar = [
  { value: 'lunas', name: 'Lunas' },
  { value: 'belumDibayar', name: 'Belum Dibayar' }
]


const akun = [
  { value: '1.1.01.0001', name: 'Kas' },
  { value: '1.1.02.0001', name: 'Bank' }
]

const BiayaNonPPNTransaksi = ({ envStore }) => {
  const { inputTypes, definition } = FormWrapper
  const [tempoDate, setTempoDate] = useState(null)
  const [disabled, setDisabled] = useState(true)
  const [hari, setHari] = useState(null)

  return (
    <FormWrapper
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      backTo={`/${envStore.env.applicationType}/biaya`}
      baseId="mod-form-sample"
      title='Transaksi'
      hintShowIcon={false}
      defaultData={{
        noTransaksi: 'PB-2021/07/0001',
        jatuhTempo: 1,
        password: '000000',
        confirmPassword: '000000',
        cityName: ''
      }}
      definitions={[
        {
          inputType: inputTypes.DIVIDER,
          label: 'General'
        },
        {
          inputType: inputTypes.RADIO,
          label: 'Pembelian Normal',
          key: 'jnsTransaksi',
          // name: 'Pembelian Normal',
          value: 'normal',
        },
        {
          inputType: inputTypes.DIVIDER,
          label: 'Data Transaksi'
        },
        {
          inputType: inputTypes.INPUT,
          className: 'mpk-padding-N padding-right',
          label: 'Nomor Transaksi',
          key: 'noTransaksi',
          required: true,
          disabled: true,
          width: '50%',
        },
        // -----
        {
          inputType: inputTypes.DATE,
          label: 'Tanggal Transaksi',
          key: 'tanggalTransaksi',
          width: '50%',
          required: true,
          disabled: disabled,
          setTempoDate
        },
        {
          inputType: inputTypes.SELECT,
          className: 'mpk-padding-N padding-right',
          label: 'Status Pembayaran',
          key: 'statusPembayaran',
          width: '50%',
          required: true,
          disabled: disabled,
          options: statusBayar.map(d => ({
            label: d.name,
            value: d.value
          }))
        },
        {
          inputType: inputTypes.SELECT,
          label: 'Akun Utang',
          key: 'akunUtang',
          width: '50%',
          required: true,
          disabled: disabled,
          options: akun.map(d => ({
            label: d.value + ' - ' + d.name,
            value: d.value
          })),
          show: (d) => {
            return d.statusPembayaran == 'belumDibayar'
        }
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          type: TableWrapper.dataTypes.NUMBER,
            
          label: 'Periode Jatuh Tempo (Hari)',
          key: 'jatuhTempo',
          required: true,
          disabled: disabled,
          width: '50%',
          setHari,
          show: (d) => {
            return d.statusPembayaran == 'belumDibayar'
        }
        },
        {
          inputType: inputTypes.DATE,
          label: 'Tanggal Jatuh Tempo',
          key: 'tanggalTempo',
          width: '50%',
          required: true,
          disabled: true,
          show: (d) => {
            return d.statusPembayaran == 'belumDibayar'
        }
        },
        {
          inputType: inputTypes.SELECT,
          className: 'mpk-padding-N padding-right',
          label: 'Transfer Dari',
          key: 'transfer',
          width: '50%',
          required: true,
          disabled: disabled,
          options: akun.map(d => ({
            label: d.value + ' - ' + d.name,
            value: d.value
          })),
          show: (d) => {
            return d.statusPembayaran == 'lunas'
        }
        }
      ]}
      onChange={(data, key, value) => {
        // console.log(value);
        if (key === 'statusPembayaran') {
          let bayar = find(statusBayar, { name: value })
          if (bayar) data.bayarId = bayar.value
        }
        if (key === 'akunUtang' && 'transfer') {
          let utang = find(akun, { name: value })
          if (utang) data.utangId = utang.value
        }
        if (key === 'tanggalTempo') {
          let tempo = new Date()
          tempo.setDate(tempo.getDate() + 1)
        }
        if (key === 'jnsTransaksi'){
          setDisabled(false)
        }
        return data
      }}
    />
  )
}

export default inject('envStore')(observer(BiayaNonPPNTransaksi))