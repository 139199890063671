import React from "react";
import { ExpansionPanel } from "react-md";
import { format, formatRupiah } from "../../../libs/react-mpk/services/number.service";

function ReportLabaRugiKomersialExpansion({
  panel = false,
  onClick = () => {},
  children = null,
  label = "",
  value = 0,
}) {
  return (
    <ExpansionPanel
      style={{ marginBottom: 5, marginTop: 5 }}
      expanded={panel}
      onClick={onClick}
      header={
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "50%",
              fontWeight: "bold",
              textAlign: "left",
            }}
          >
            {label}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "50%",
              fontWeight: "bold",
            }}
          >
            {formatRupiah(value)}
          </div>
        </div>
      }
    >
      {children}
    </ExpansionPanel>
  );
}

export default ReportLabaRugiKomersialExpansion;
