import React, { useEffect, useState } from "react";
import { inputTypes } from "../../../libs/react-mpk/config/constant";
import { FormWrapper } from "../../../libs/react-mpk/wrapper";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import DataAwalService from "./DataAwal.service";
import { find } from "lodash";
import moment from "moment";
import "moment/locale/id";
import cloneDeep from "lodash.clonedeep";

const defaultData = () => ({
  tanggalMulai: new Date(),
  kenaPphFinal: false,
  kategoriCatat: "1",
});

const DataAwal = ({ envStore }) => {
  const { inputTypes, definition } = FormWrapper;
  let [item, setItem] = useState(null);
  let [disabledIsPencatatanPembukuan, setDisabledIsPencatatanPembukuan] =
    useState(false);

  let PBKTYPE_PBK = "1";
  let PBKTYPE_CATAT = "2";

  useEffect(() => {
    initData();
  }, []);

  async function initData() {
    let selectedItem = defaultData();
    try {
      let res = await DataAwalService.getDataAwal();
      let { data } = res;

      selectedItem.tanggalMulai = data.tanggalMulai;
      selectedItem.kenaPphFinal = data.kenaPphFinal;
      selectedItem.kategoriCatat = data.kategoriCatat.toString();
      selectedItem.id = data.id;
    } catch (e) {}
    setItem(selectedItem);
  }

  const onSubmit = async (data, callback) => {
    let reqData = data;
    reqData.tanggalMulai = moment(new Date(reqData.tanggalMulai)).format(
      "YYYY-MM-DD hh:mm:ss"
    );

    try {
      await DataAwalService.updateDataAwal(reqData.id, reqData);
      callback("success", false);
    } catch (err) {
      callback(err, true, false);
    }
  };

  return (
    <FormWrapper
      actions={[
        {
          label: "Reload",
          iconClassName: "mdi mdi-reload",
        },
      ]}
      // backTo={`/${envStore.env.applicationType}/table`}
      baseId="mod-form-sample"
      title={t.translate("modules.dataAwal.title")}
      hintMessage={t.translate("modules.dataAwal.hint.message")}
      hintShowIcon={false}
      showBackTo={false}
      defaultData={
        item
          ? cloneDeep(item)
          : {
              tanggalMulai: new Date(),
              kenaPphFinal: false,
              kategoriCatat: "1",
            }
      }
      definitions={[
        {
          inputType: inputTypes.DATEPICKER,
          label: t.translate(`words.tanggalMulaiPencatatan`),
          key: "tanggalMulai",
          width: "100%",
          type: "date",
          validation: "required",
          disabled: true,
        },
        // {
        // 	render: (
        // 		<div>
        // 		<p style={{marginLeft: "10px"}}><b>{t.translate(`words.dikenakanPphFinalPP23`)}</b></p><br/>
        // 		</div>
        // 	)
        // },
        // {
        // 	inputType: inputTypes.CHECKBOX,
        // 	label: t.translate(`words.yes`),
        // 	key: 'kenaPphFinal',
        // 	name: 'kenaPphFinal',
        // 	style: {
        // 		width: '100%'
        // 	}
        // },
        {
          render: (
            <div>
              <p style={{ marginLeft: "10px" }}>
                <b>{t.translate(`words.pembukuanPencatatan`)}</b>
              </p>
              <br />
            </div>
          ),
        },
        {
          inputType: inputTypes.RADIO,
          label: t.translate(`words.pembukuan`),
          key: "kategoriCatat",
          name: "kategoriCatat",
          value: PBKTYPE_PBK,
          style: {
            width: "100%",
          },
          disabled: disabledIsPencatatanPembukuan,
          hidden: disabledIsPencatatanPembukuan,
        },
      ]}
      onChange={(data, key, value) => {}}
      onSubmit={onSubmit}
    />
  );
};

export default inject("envStore")(observer(DataAwal));
