import React, { useState } from 'react'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import { FormWrapper, TabsWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { find } from 'lodash'
import { BiayaNonPPNProduk, BiayaNonPPNTransaksi } from '.'
import { Button } from '@react-md/button'

const lawanTr = [
    { id: '90.123.123.1-100.101', name: 'Yopie Latul', nik: '3216082606000006', adress: 'Kemanggisan' },
    { id: '90.123.123.1-100.102', name: 'Daniel Salueka', nik: '3216082310990006', adress: 'Tebet' },
    { id: '90.123.123.1-100.103', name: 'Mona Latumahina', nik: '3216081207000006', adress: 'Senayan' },
]

const BiayaNonPPNIdentitas = ({ envStore, ...props }) => {
    const { inputTypes, definition } = FormWrapper


    return (
        <TabsWrapper
            label='Biaya Non PPN'
            baseId='mod-form-biaya-non-ppn'
            tabs={[
                {
                    label: 'Identitas',
                    key: 'identitasNonPPN',
                    render: (<>
                        <FormWrapper
                            actions={[
                                {
                                    label: 'Reload',
                                    iconClassName: 'mdi mdi-reload'
                                }
                            ]}
                            title='Identitas'
                            backTo={`/${envStore.env.applicationType}/biaya`}
                            baseId="mod-form-non-ppn-identitas"
                            defaultData={{
                                name: '',
                                identity: '',
                                npwp: '',
                                alamat: '',
                                lawanTransaksi: ""
                            }}
                            definitions={[
                                {
                                    inputType: inputTypes.SELECT,
                                    label: 'Lawan Transaksi',
                                    key: 'lawanTransaksiId',
                                    options: lawanTr.map((d, i) => ({
                                        label: d.name + ' - ' + d.id,
                                        value: d.id
                                    })),
                                },
                                {
                                    inputType: inputTypes.DIVIDER,
                                    label: "Data Lawan Transaksi"
                                },
                                {
                                    inputType: inputTypes.INPUT,
                                    className: 'mpk-padding-N padding-right',
                                    label: 'Nama',
                                    key: 'name',
                                    required: true,
                                    width: '50%',
                                    disabled: true
                                },
                                {
                                    inputType: inputTypes.INPUT,
                                    label: 'NIK/Paspor',
                                    key: 'identity',
                                    required: true,
                                    width: '50%',
                                    disabled: true

                                },
                                // -----
                                {
                                    inputType: inputTypes.INPUT,
                                    className: 'mpk-padding-N padding-right',
                                    label: 'NPWP',
                                    key: 'npwp',
                                    width: '50%',
                                    required: true,
                                    disabled: true
                                },
                                {
                                    inputType: inputTypes.INPUT,
                                    label: 'Alamat',
                                    key: 'alamat',
                                    width: '50%',
                                    required: true,
                                    disabled: true
                                }
                            ]}
                            onChange={(data, key, value) => {
                                if (key === 'lawanTransaksiId') {
                                    data.lawanTransaksi = find(lawanTr, { id: data.lawanTransaksiId })
                                    data.identity = data.lawanTransaksi.nik
                                    data.npwp = data.lawanTransaksi.id
                                    data.name = data.lawanTransaksi.name
                                    data.alamat = data.lawanTransaksi.adress
                                }
                                // console.log(data);
                                // if (key === 'lawanTransaksi') {
                                //   data.identity = lawanTr.id
                                //   data.name = lawanTr.name
                                //   data.alamat = lawanTr.adress
                                // }
                                // console.log(value);
                                return data
                            }}
                        />
                    </>
                    )
                },
                {
                    label: 'Transaksi',
                    key: 'transaksiNonPPN',
                    render: (
                        <BiayaNonPPNTransaksi
                            showCommandBar={false}
                        />
                    )
                },
                {
                    label: 'Produk',
                    key: 'produkNonPPN',
                    render: (
                        <BiayaNonPPNProduk
                            showCommandBar={false}
                        />
                    )
                }
            ]}
        >



        </TabsWrapper>
    )
}

export default inject('envStore')(observer(BiayaNonPPNIdentitas))