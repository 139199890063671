import React, { useState } from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import service from './KasDanBank.service'
import moment from 'moment'
import { getOrientationType } from '@react-md/utils'
import { inject, observer } from 'mobx-react'
import { Button, Dialog, DialogHeader, DialogContent, DialogTitle, DialogFooter, Text } from 'react-md'
import { Divider } from '@react-md/divider'
import KasDanBankPenerimaanPPN from './KasDanBankTambahPenerimaanUang/KasDanBankPenerimaanPPN'
import iziToast from 'izitoast'
import KasDanBankTambahAkunBankForm from './KasDanBankTambahAkunBankForm'
import { format, formatRupiah } from '../../libs/react-mpk/services/number.service'
import AkunCoaForm from '../AkunCoa/AkunCoaForm'

const KasDanBank = ({
  modalStore,
  navigationStore,
  history,
  className       = '',
  showCommandbar  = true
}) => {
    let [showForm, setShowForm] = useState(false);
    const [dialogPenerimaanPpn, setDialogPenerimaanPpn] = useState(false)
    const [showFormAkun, setShowFormAkun] = useState(false)
    const [dataFormAkun, setDataFormAkun] = useState("")
    let [search, setSearch] = useState({})
    let npwpHeadId = sessionStorage.getItem('npwpHeadId');
    var dataAkun = JSON.parse(sessionStorage.getItem("dataPenggunaNpwp"));
    function addItemPenerimaan(){
        setDialogPenerimaanPpn(true)
    }
    return (
        <>
        <TableWrapper
        baseId="mod-table-kas-dan-bank"
        title={t.translate('modules.KasDanBank.title')}
        className={className}
        hintMessage={t.translate('modules.KasDanBank.hint.message')}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate('modules.KasDanBank.hint.more')}
        defaultData={[]}
        defaultSortBy="tanggalDibuat"
        isShowFilter={false}
        showActionColumn={true}
        useFilter={false}
        defaultCollapse={true}
        useCriteria={true}
        selectable={false}
        useQueryHistory={false}
        onFetchData={(query) => {
            return (
              new Promise(async (resolve, reject) => {
                try {
                  query = {
                    ...search,
                    ...query,
                  }
                  let getPage = await service.getPage()
                  // setData(getPage.data)
                  getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
                  resolve(getPage)
                } catch (e) {
                    resolve()
                    console.log(e);
                  // ErrorService(e)
                }
              })
            )
          }}
        columns={[
            {
                label: t.translate(`words.namaAkun`),
                searchable: true,
                key: 'NamaAkun',
                type: TableWrapper.dataTypes.STRING,
                render: item =>(item.akun.kode + " - " + item.akun.nama)
            },
            {
                label: t.translate(`words.saldoAwal`),
                searchable: true,
                key:'saldoAwal',
                type: TableWrapper.dataTypes.STRING,
                render: item =>formatRupiah(item.saldoAwal)
            },
            {
                label: t.translate(`words.saldoAkhir`),
                searchable: true,
                key:'saldoAkhir',
                type: TableWrapper.dataTypes.STRING,
                render: item =>(formatRupiah(item.saldoAkhir))
            }
        ]}
        actions={[
            new TableWrapper.action('Add', 'mdi mdi-plus', (item) => {
                setShowForm(true)
            })
        ]}
        itemActions={[
            // new TableWrapper.action('Edit', 'mdi mdi-pencil', () => console.log('request edit'), true),
            new TableWrapper.action(
                `${t.translate('words.selengkapnya')}`, 
                'mdi mdi-import', 
                (item) => {
                    
                    history.push (`kas-dan-bank-detail/${item.akun.id}/selengkapnya`)
                    sessionStorage.setItem("namaAkun", item.akun.nama)
                    sessionStorage.setItem("kodeAkun", item.akun.kode)
                    sessionStorage.setItem("saldoAwal", formatRupiah(item.saldoAwal))
                    sessionStorage.setItem("saldoAkhir", formatRupiah(item.saldoAkhir))
                    sessionStorage.setItem("idAkun", item.akun.id)
                }
            ),
        ]}
        showCommandbar={showCommandbar}
        showFilterPeriod={true}
        />

        {/* dialog menu */}
        <Dialog
            visible={showForm}
            onRequestClose={()=>{
                setShowForm(false)
            }}
            style={{width:'45%'}}
        >
            <DialogHeader>
                <DialogTitle>Pilih</DialogTitle>
            </DialogHeader>
            <DialogContent>
                <Text>{t.translate(`words.tambahTransferAntarBank`)}</Text>
                <Button 
                    id="dialog-close" 
                    onClick={()=>{
                        navigationStore.redirectTo(
                            `/internal/npwp/${npwpHeadId}/transfer-antar-bank`
                        )
                    }}
                    theme="primary" 
                    themeType="contained"
                >
                    {t.translate(`words.tambahTransferAntarBank`)}
                </Button>

                <Text>{t.translate(`words.tambahAkunBank`)}</Text>
                <Button 
                    id="dialog-close" 
                    onClick={()=>
                        {setShowFormAkun(true)
                            setDataFormAkun("bank")
                        setShowForm(false)}
                    }
                    theme="primary" 
                    themeType="contained"
                >
                    {t.translate(`words.tambahAkunBank`)}
                </Button>
                <Text>Tambah Akun Kas</Text>
                <Button 
                    id="dialog-close" 
                    onClick={()=>
                        {setShowFormAkun(true)
                            setDataFormAkun("kas")
                        setShowForm(false)}
                    }
                    theme="primary" 
                    themeType="contained"
                >
                    Tambah Akun Kas
                </Button>
            </DialogContent>
            <DialogFooter>
                <Button 
                    onClick={() => {
                        setShowFormAkun(false)
                        setShowForm(false)
                    }}
                >
                    {t.translate(`words.close`)}
                </Button>
            </DialogFooter>
        </Dialog>

        {/* dialog penerimaan ppn */}
        <KasDanBankPenerimaanPPN
            visible={dialogPenerimaanPpn}
            onRequestClose={()=>{
                setDialogPenerimaanPpn(false)
            }}
        />
        
        <AkunCoaForm
            visible={showFormAkun}
            onRequestClose={() => {
            setShowFormAkun(false)
            // setSelectedItemId(null)
            document.getElementsByClassName('mdi-reload')[0].click()
        }}
            kasDanBank={dataFormAkun}

        />
        </>
    )
}

export default inject("navigationStore",'envStore', 'modalStore')(observer(KasDanBank))