import { method } from 'lodash';
import { crud, http } from '../../libs/react-mpk/services';

let service = new crud('/enofa');
let serviceUrl = '/enofa'
let npwpHeadIdObject = {
    npwpHeadId: sessionStorage.getItem('npwpHeadId')
}
let npwpHeadId = sessionStorage.getItem('npwpHeadId')

service.getAll = async (query) => {
    return http.request({
        method: http.methods.GET,
        url: `${serviceUrl}`,
        query
    })
}

service.getById = async (id) => {
    return http.request({
        method: http.methods.GET,
        url: `${serviceUrl}/${id}`
    })
}

service.post = async (data) => {
    return http.request({
        method: http.methods.POST,
        url: `${serviceUrl}`,
        data,
        config: {
            params: {
                npwpHeadId: npwpHeadId
            }
        }
    })
}

service.delete = async (id) => {
    return http.request({
        method: http.methods.DELETE,
        url: `${serviceUrl}/${id}`
    })
}

service.edit = async (id, data) => {
    return http.request({
        method: http.methods.PUT,
        url: `${serviceUrl}/${id}`,
        data,
        config: {
            params: {
                npwpHeadId: npwpHeadId
            }
        }
    })
}

export default service;