import React, { useState, useEffect } from "react";
import { FormWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import { inputTypes } from "../../libs/react-mpk/config/constant";
import service from "./KasDanBank.service";
import { nullFormat } from "numeral";
import moment from "moment";
import "moment/locale/id";
import { Button } from "@react-md/button";
import {
  TableCell,
  TableRow,
  TableBody,
  Table,
  TableHeader,
} from "@react-md/table";
import { FontIcon } from "@react-md/icon";
import { Text } from "react-md";
import { toast } from "../../libs/react-mpk/services";

const defaultData = () => ({
  tanggalTransaksi: "",
  akunFromId: null,
  akunFrom: {
    id: null,
  },
  akunToId: null,
  akunTo: {
    id: null,
  },
  nominalTransfer: 0,
  keterangan: "",
  npwpHead: {
    id: null,
  },
  gambar: null,
});

const KasDanBankTransferAntarBank = ({
  visible = false,
  authStore,
  envStore,
  match,
  history,
  modalStore,
  navigationStore,
  onRequestClose = () => {},
  ...props
}) => {
  const { inputTypes, definition } = FormWrapper;
  let [item, setItem] = useState(null);
  let [ready, setReady] = useState(true);
  const [loadingAll, setLoadingAll] = useState(true);
  const [loading, setLoading] = useState(true);
  let [gambarList, setGambarList] = useState([]);
  let [gambarHapusList, setGambarHapusList] = useState([]);
  const [akunFromList, setAkunFromList] = useState([]);
  let [queryAkun, setQueryAkun] = useState("");
  const [loadingAkun, setLoadingAkun] = useState(false);
  const [akunFromList2, setAkunFromList2] = useState([]);
  let [queryAkun2, setQueryAkun2] = useState("");
  const [loadingAkun2, setLoadingAkun2] = useState(false);

  let npwpHeadId = sessionStorage.getItem("npwpHeadId");
  const backTo = `/internal/npwp/${npwpHeadId}/kas-dan-bank`;

  useEffect(() => {
    setLoadingAkun(true);
    const timeOutId = setTimeout(() => getListAkunFrom(queryAkun), 500);
    return () => clearTimeout(timeOutId);
  }, [queryAkun]);

  useEffect(() => {
    setLoadingAkun2(true);
    const timeOutId = setTimeout(() => getListAkunFrom2(queryAkun2), 500);
    return () => clearTimeout(timeOutId);
  }, [queryAkun2]);

  async function getListAkunFrom(queryNama) {
    try {
      setLoadingAkun(true);

      let res = await service.getAkun(queryNama);
      var akuns = [];
      res.data.map((d) => {
        akuns.push({
          value: d.id,
          label: `${d.kode} - ${d.nama}`,
        });
      });
      setAkunFromList(akuns);

      setLoadingAkun(false);
    } catch (error) {
      toast.errorRequest(error);
      setLoadingAkun(false);
    }
  }
  async function getListAkunFrom2(queryNama) {
    try {
      setLoadingAkun2(true);

      let res = await service.getAkun(queryNama);
      var akuns = [];
      res.data.map((d) => {
        akuns.push({
          value: d.id,
          label: `${d.kode} - ${d.nama}`,
        });
      });
      setAkunFromList2(akuns);
      setLoadingAkun2(false);
    } catch (error) {
      toast.errorRequest(error);
      setLoadingAkun2(false);
    }
  }
  async function deleteGambar(index) {
    modalStore.showConfirm({
      title: t.translate("words.delete"),
      children: t.translate("words.confirmHapus"),
      onSubmit: (callback) => {
        var gambarListNew = gambarList;

        //tambah di list gambar hapus
        var gambarHapusListNew = gambarHapusList;
        gambarHapusListNew.push(gambarListNew[index]);
        setGambarHapusList(gambarHapusListNew);

        //hapus di list gambar
        gambarListNew.splice(index, 1);
        setGambarList(gambarListNew);
        callback();
      },
    });
  }
  useEffect(() => {
    async function initData() {
      if (visible) {
        let selectedItem = defaultData();
        setItem(selectedItem);
      } else {
        setItem(defaultData());
      }
      setReady(visible);
    }
    setLoading(true);
    initData();
    setGambarList([]);
    setGambarHapusList([]);
    setLoading(false);
  }, [visible]);
  useEffect(() => {
    setLoadingAll( loading);
  }, [ loading]);

  return (
    <FormWrapper
      showCommandbar={true}
      baseId="mod-kas-dan-bank-transfer-antar-bank"
      title={t.translate(`modules.KasDanBank.transferAntarBank`)}
      loading={loadingAll}
      style={{
        maxWidth: "100%",
      }}
      defaultData={item}
      submitLabel={t.translate(`words.submit`)}
      definitions={[
        {
          inputType: inputTypes.FILE_INPUT,
          label: t.translate("words.gambar"),
          key: "gambar",
          type: "file",
          accept: "application/pdf, image/*",
        },
        {
          inputType: inputTypes.DATEPICKER,
          label: t.translate(`words.tanggalTransaksi`),
          key: "tanggalTransaksi",
          validation: "required",
          style:{zIndex:50}
        },
        {
          inputType: inputTypes.REACT_SELECT,
                    isClearable:true,
          label: t.translate(`words.transferDari`),
          key: "akunFromId",
          labelKey: "label",
          valueKey: "value",
          options: akunFromList,
          isLoading: loadingAkun,
          
          onInputChange: (value) => {
            if (value) setQueryAkun(value);
          },
          validation: "required",
        },
        {
          inputType: inputTypes.REACT_SELECT,
                    isClearable:true,
          label: t.translate(`words.transferke`),
          key: "akunToId",
          labelKey: "label",
          valueKey: "value",
          options: akunFromList2,
          isLoading: loadingAkun2,
          onInputChange: (value) => {
            if (value) setQueryAkun2(value);
          },
          validation: "required",
        },
        {
          render: (
            <Text style={{ fontSize: 18, marginRight: "1%", width: "5%" }}>
              Rp.
            </Text>
          ),
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          thousandSeparator: ".",
          decimalSeparator: ",",
          // isNumericString: true,
          label: t.translate(`words.nominalTagihan`),
          key: "nominalTransfer",
          type: "text",
          width: "94%",
          validation: "required",
          allowNegative: false,
        },
        {
          render: (
            <div style={{ width: "100%" }}>
              <p style={{ margin: "10px" }}>
                <b>{t.translate(`words.informasiTambahan`)}</b>
              </p>
            </div>
          ),
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.keterangan`),
          key: "keterangan",
          type: "text",
          style: {
            marginRight: "2%",
          },
        },
      ]}
      onChange={async (formData, key, value) => {
        if (key == "akunFromId") {
          akunFromList.map((d) => {
            if (d.value == value) {
              formData["akunFrom"] = d.value;
            }
          });
        }
        if (key == "akunToId") {
          akunFromList.map((d) => {
            if (d.value == value) {
              formData["akunTo"] = d.value;
            }
          });
        }
        return formData;
      }}
      onSubmit={async (data, callback) => {
        try {
          let resGambar;
          if (data.gambar != null) {
            resGambar = await service.uploadGambar(data.gambar);
          }
          const res = await service.createTransfer({
            tanggalTransaksi: moment(data.tanggalTransaksi)
              .locale("id")
              .format("yyyy-MM-DD hh:mm:ss"),
            akunFrom: {
              id: data.akunFrom,
            },
            akunTo: {
              id: data.akunTo,
            },
            nominalTransfer: data.nominalTransfer,
            keterangan: data.keterangan,
            npwpHead: {
              id: sessionStorage.getItem("npwpHeadId"),
            },
            fileLampiran: data.gambar != null ? resGambar.data.namaFile : null,
            fileLampiranAlias:
              data.gambar != null ? resGambar.data.namaFileAlias : null,
            fileLampiranInstance:
              data.gambar != null ? resGambar.data.instanceId : null,
          });
          let { status } = res;
          if (status == 200) {
            callback("success", false);
            navigationStore.redirectTo(backTo);
          }
          // TableWrapper.reload(baseId)
        } catch (error) {
          callback(error, true, false);
        }
      }}
    />
  );
};

export default inject(
  "envStore",
  "modalStore",
  "navigationStore"
)(observer(KasDanBankTransferAntarBank));
