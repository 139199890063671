import React, { useEffect, useState } from "react";
import { DataForm } from "../../libs/react-mpk/components";
import { Divider, Text } from "react-md";
import t from "counterpart";
import { FormWrapper, TableWrapper } from "../../libs/react-mpk/wrapper";
import service from "./TutupBuku.service";

import AkunCoaService from "../../modules/AkunCoa/AkunCoa.service";
import moment from "moment";
import "moment/locale/id";

const defaultData = () => ({
  // id: 0,
  tahun: 0,
  // labaKomersial: 0,
  // labaFiskal: 0,
  // pphTerutang: 0,
  // status: 0,
  // pesanError: null,
  npwpHead: {
    id: null,
  },
});

const AddTutupBuku = ({
  visible = false,
  authStore,
  envStore,
  match,
  history,
  onRequestClose = () => {},
  ...props
}) => {
  const { inputTypes, definition } = FormWrapper;
  let [item, setItem] = useState(null);
  let [ready, setReady] = useState(true);
  let [listTahun, setListTahun] = useState([]);
  let [dataSubmit, setDataSubmit] = useState({
    tahun: 0,
    npwpHead: {
      id: null,
    },
  });

  let [loadingSubmit, setLoadingSubmit] = useState(false);

  var npwpHeadId = sessionStorage.getItem("npwpHeadId");

  async function year() {
    let minOffset = 0,
      maxOffset = 9;
    let thisYear = new Date().getFullYear() - 1;
    let allYears = [];
    for (let x = minOffset; x <= maxOffset; x++) {
      allYears.push({ value: thisYear - x, label: thisYear - x });
    }
    setListTahun(allYears);
  }

  const baseId = "tutup_buku";

  useEffect(() => {
    async function initData() {
      if (visible) {
        let selectedItem = defaultData();
        year();
        setItem(selectedItem);
      } else {
        setItem(defaultData());
      }
      setReady(visible);
      setLoadingSubmit(false);
    }
    initData();
  }, [visible]);

  const generateAkun = async () => {
    alert("generate");
    try {
      let res = await AkunCoaService.generateAkun();

      let { data } = res;
    } catch (e) {}
  };

  return (
    ready && (
      <DataForm
        baseId="add-tutup-buku"
        title={t.translate(`modules.tutupBuku.form`)}
        visible={visible}
        onRequestClose={onRequestClose}
        asDialog={true}
        defaultData={item}
        definitions={[
          {
            inputType: inputTypes.DIVIDER,
            label: t.translate(`words.prosesTutupBuku`),
          },
          {
            inputType: inputTypes.SELECT,
            label: t.translate(`words.tahun`),
            key: "tahun",
            width: "50%",
            validation: "required",
            options: listTahun,
          },
          {
            render: (
              <div style={{ width: "100%", marginBottom: 30 }}>
                <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                  Tutup Buku dan Generate Akun sedang diproses ...
                  <Divider />
                </Text>
              </div>
            ),
            show: loadingSubmit,
          },
        ]}
        onChange={async (formData, key, value) => {
          if (key == "periodeAkhir") {
            formData.periodeAkhirs = formData.periodeAkhir;
          }
          return formData;
        }}
        onSubmit={async (data, callback) => {
          setLoadingSubmit(true);
          if (data.tahun == 0) {
            callback("Tahun Tidak Boleh Kosong", true, false);
          } else {
            let reqData = dataSubmit;
            reqData.npwpHead.id = npwpHeadId;
            reqData.tahun = data.tahun;
            try {
              const res = await service.create(reqData);
              setLoadingSubmit(true);
              callback("success", false);
              TableWrapper.reload(baseId);
            } catch (error) {
              setLoadingSubmit(true);
              callback(error, true, false);
            }
          }
        }}
      />
    )
  );
};

export default AddTutupBuku;
