import React, { useState, useEffect } from "react";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import { FormWrapper } from "../../../libs/react-mpk/wrapper";
import FormTanggal from "../FormTanggal";
import { useHistory, useLocation, useParams } from "react-router-dom";
import service from "./ReportNeraca.service";
import {
  Table,
  TableBody,
  TableHeader,
  TableRow,
  TableCell,
  Button,
  FontIcon,
  TableFooter,
} from "react-md";
import { format, formatRupiah } from "../../../libs/react-mpk/services/number.service";

const defaultData = () => ({
  akun: [],
  kategoriAkun: "",
  total: 0,
  kategoriAkunId: null,
});
const ReportDetailLaporanNeraca = ({ navigationStore, match }) => {
  let history = useHistory();
  const { state: { tahun, harta, utangModal } = {} } = useLocation();
  const [itemFile, setItemFile] = useState(defaultData());
  const [dataNeraca, setDataNeraca] = useState([]);
  const [ready, setReady] = useState(false);

  let npwpHeadId = match.params.npwpHeadId;
  let kategoriId = sessionStorage.getItem("kategoriAkunId");

  const fetchData = async () => {
    let selectedItem = defaultData();
    try {
      let query = {
        tahun: tahun ? tahun : sessionStorage.getItem("tahunSobatBuku"),
        kategoriAkunId: match.params.kategoriAkunId,
      };
      let res = await service.getDetailHarta(query);
      let { data } = res;

      selectedItem.akun = data ? data : [];
      selectedItem.kategoriAkun = `${sessionStorage.getItem(
        "kategoriAkunNama"
      )} - ${sessionStorage.getItem("kategoriAkunKode")}`;
      selectedItem.akun.map((d) => {
        selectedItem.total += d.saldoAkhir;
      });
      selectedItem.kategoriAkunId = match.params.kategoriAkunId;
      setItemFile(selectedItem);
    } catch (error) {}
    setReady(true);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {}, [itemFile]);
  return (
    ready && (
      <>
        <FormWrapper
          baseId="report-detail-laporan-saldo"
          showCommandbar={true}
          style={{
            maxWidth: "100%",
          }}
          backTo={
            utangModal == true && !tahun?`/internal/npwp/${npwpHeadId}/report/utangDanModal`
              : harta == true && !tahun
              ? `/internal/npwp/${npwpHeadId}/report/harta`
              : tahun && harta == false && utangModal && false
              ? `/internal/npwp/${npwpHeadId}/report-neraca`
              : null
          }
          title={t.translate(`modules.report.detailLaporanNeraca`)}
          defaultData={itemFile}
          definitions={[
            {
              inputType: FormWrapper.inputTypes.INPUT,
              label: t.translate("words.kategoriAkun"),
              key: "kategoriAkun",
              disabled: true,
              style: {
                marginLeft: "10px",
              },
            },
            {
              render: (
                <div style={{ width: "100%" }}>
                  <Table style={{ width: "100%" }}>
                    <TableHeader>
                      <TableRow>
                        <TableCell style={{ width: "10%" }}>
                          {t.translate("words.non")}
                        </TableCell>
                        <TableCell style={{ width: "10%" }}>
                          {t.translate("words.aksi")}
                        </TableCell>
                        <TableCell style={{ width: "25%" }}>
                          {t.translate("words.namaAkun")}
                        </TableCell>
                        <TableCell style={{ width: "25%" }}>
                          {t.translate("words.nominal")}
                        </TableCell>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {itemFile.akun.map((d, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>
                              <Button
                                theme="primary"
                                onClick={() => {
                                  history.push({
                                    pathname: `/internal/npwp/${npwpHeadId}/report/detail-laporan-neraca/${match.params.kategoriAkunId}/transaksi-neraca/${d.akun.id}`,
                                    state: { tahun: tahun },
                                  });
                                  // navigationStore.redirectTo(
                                  //     `/internal/npwp/${npwpHeadId}/report/detail-laporan-neraca/${match.params.kategoriAkunId}/transaksi-neraca/${d.akun.id}`
                                  // )
                                  sessionStorage.setItem(
                                    "akunNama",
                                    d.akun.nama
                                  );
                                  sessionStorage.setItem(
                                    "akunKode",
                                    d.akun.kode
                                  );
                                  sessionStorage.setItem(
                                    "saldoAwal",
                                    d.saldoAwal
                                  );
                                }}
                              >
                                <FontIcon iconClassName="mdi mdi-import" />
                                {/* {t.translate("words.add")} */}
                              </Button>
                            </TableCell>
                            <TableCell>
                              {d.akun.kode} - {d.akun.nama}
                            </TableCell>
                            <TableCell>
                              {formatRupiah(d.saldoAkhir)}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TableCell>
                          <b>{t.translate(`words.total`)}</b>
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          {
                            (Array.prototype.sum = function (prop) {
                              var total = 0;
                              for (
                                var i = 0, _len = this.length;
                                i < _len;
                                i++
                              ) {
                                total += this[i][prop];
                              }
                              return total;
                            })
                          }
                          <b>{formatRupiah(itemFile.total)}</b>
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </div>
              ),
            },
          ]}
        />
      </>
    )
  );
};

export default inject(
  "navigationStore",
  "envStore"
)(observer(ReportDetailLaporanNeraca));
