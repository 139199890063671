import React, { useState, useEffect } from "react";
import t from "counterpart";
import {
  Table,
  TableBody,
  TableHeader,
  TableRow,
  TableCell,
  Button,
  FontIcon,
  TableFooter,
} from "react-md";
import KasDanBankTambahEditTagihanPengeluaran from "./KasDanBankTambahEditTagihanPengeluaran";
import { FormWrapper } from "../../../libs/react-mpk/wrapper";
import { DataForm, Hint } from "../../../libs/react-mpk/components";
import service from "../KasDanBank.service";
import { cloneDeep, find, truncate } from "lodash";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";

const defaultData = () => ({
  pasalPemotonganId: "PPh 4(2)",
  objekPajakId: "P001 - Omset",
  isNilaiLain2: "0",
  kasBankAkun: {
    nilaiPiutangOrHutang: 0,
    isPajakSetorSendiriOrPotongPajak: false,
    isPajakDitanggung: false,
    nilaiPenjualanOrBeban: 0,
    isNilaiLain2: null,
    nominalPajak: 0,
    nilaiTagihanOrPenerimaanOrPenerimaan: 0,
    pctTarifmajak: 0,
    akun: {
      id: null,
    },
    sbkJenisPenghasilanId: {
      id: 1,
    },
    sbkPasalPemotonganId: {
      id: 1,
    },
    objekPajak: {
      id: 1,
    },
  },
  kasBankAkunFmList: [],
});

const KasDanBankTambahEditInputDetailAkunUang = ({
  visible = false,
  authStore,
  envStore,
  match,
  history,
  modalStore,
  onRequestClose = () => {},
  ...props
}) => {
  const { inputTypes, definition } = FormWrapper;
  const [ready, setReady] = useState(true);
  const [showUtangForm, setShowUtangForm] = useState(true);
  const [showBebanForm, setShowBebanForm] = useState(true);
  const [showPajak, setShowPajak] = useState(true);
  let [item, setItem] = useState({
    pasalPemotonganId: "PPh 4(2)",
    isNilaiLain2: "1",
    isPajakDitanggung: false,
    kasBankAkun: {
      nilaiPiutangOrHutang: 0,
      isPajakSetorSendiriOrPotongPajak: false,
      nilaiPenjualanOrBeban: 0,
      isNilaiLain2: 0,
      nominalPajak: 0,
      nilaiTagihanOrPenerimaanOrPenerimaan: 0,
      pctTarifmajak: 0,
      sbkAkun: {
        id: null,
        monitoringLawanTransaksi: null,
      },
      sbkJenisPenghasilanId: {
        id: 1,
      },
      sbkPasalPemotonganId: {
        id: 1,
      },
      sbkObjekPajakId: {
        id: 1,
      },
    },
    kasBankAkunFmList: [],
  });
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [dialogDaftarTagihanPengeluaran, setDialogDaftarTagihanPengeluaran] =
    useState(false);

  const [objekPajakList, setObjekPajakList] = useState([]);
  const [sbkPasalPemotonganIdList, setsbkPasalPemotonganIdList] = useState([]);
  const [akun, setAkun] = useState([]);
  const [deleteAkuTagihan, setDeleteAkuTagihan] = useState([]);
  const [dataTagihan, setDataTagihan] = useState([]);
  const [subtotal, setSubtotal] = useState([]);

  const [akunId, setAkunId] = useState(null);

  var produkData = [
    {
      nomorTransaksi: "PB-0001/01/2021",
      nominal: "20000000",
    },
  ];

  function addItem() {
    setDialogDaftarTagihanPengeluaran(true);
  }

  async function akunTransaksi() {
    let res = await service.getAkunPengeluaran();
    var tr = [];
    res.data.map((d) => {
      tr.push({
        value: d.id,
        label: `${d.kode} - ${d.nama}`,
        kode: `${d.kode}`,
        nama: `${d.nama}`,
        kategoriDebitKredit: `${d.kategoriAkun.debitKredit}`,
      });
    });
    setAkun(tr);
  }

  async function sbkPasalPemotonganId() {
    let res = await service.getPasal();
    var pasal = [];
    res.data.map((d) => {
      pasal.push({
        value: d.id,
        label: d.nama,
        nama: `${d.nama}`,
      });
    });
    setsbkPasalPemotonganIdList(pasal);
  }

  async function objekPajak() {
    let res = await service.getObjek();
    var objek = [];
    res.data.map((d) => {
      objek.push({
        value: d.id,
        label: `${d.kode} - ${d.nama}`,
        kode: `${d.kode}`,
        nama: `${d.nama}`,
      });
    });
    setObjekPajakList(objek);
  }

  async function getSubtotal() {
    let res = await service.getAkunSubtotalPengeluaran();
    var subtotals = [];

    // console.log(res)
    res.data.map((d) => {
      subtotals.push({
        value: d.id,
        label: `${d.kode} - ${d.nama}`,
        kode: `${d.kode}`,
        nama: `${d.nama}`,
        monitoringLawanTransaksi: `${d.monitoringLawanTransaksi}`,
      });
    });
    setSubtotal(subtotals);
  }

  async function akunTagihan() {
    var akunTagihanForm = JSON.parse(
      localStorage.getItem("kasBankAkunfmListForm")
    );
    var tempItem = { ...item };
    if (akunTagihanForm) {
      var dataTagihans = dataTagihan;
      dataTagihans.push(akunTagihanForm);
      // setDataTagihan(dataTagihans)
      tempItem.kasBankAkunFmList = dataTagihans;

      setItem(tempItem);
      localStorage.removeItem("kasBankAkunfmListForm");
    }
  }

  async function hapusTagiahn(index) {
    modalStore.showConfirm({
      title: t.translate("words.delete"),
      children: t.translate("words.confirmHapus"),
      onSubmit: (callback) => {
        var deleteIndex = dataTagihan;
        deleteIndex.splice(index, 1);
        setDataTagihan(deleteIndex);
        callback();
      },
    });
  }

  useEffect(() => {
    objekPajak();
    akunTagihan();
    sbkPasalPemotonganId();
    akunTransaksi();
    getSubtotal();
    if (visible) {
      let selectedItem = defaultData();
      if (props.item) {
        selectedItem = cloneDeep(props.item);
      }
      setItem(selectedItem);
    } else {
      setItem(defaultData());
    }
    // if(dataTagihan.length > 0){
    //   item.nilaiPiutangOrHutang = dataTagihan.sum("sisaTagihanAkhir");
    //   item.nilaiTagihanOrPenerimaan = dataTagihan.sum("nilaiTagihanYangTelahDibayarkan");
    // }
    setReady(visible);
  }, [visible]);

  // useEffect(() => {
  // }, [showBebanForm])

  // useEffect(() => { },[showUtangForm])

  return (
    ready && (
      <>
        <DataForm
          baseId="kas-dan-bank-input-detail-akun"
          title={t.translate(
            `modules.KasDanBank.tambahEditInputDetailAkunHutang`
          )}
          visible={visible}
          onRequestClose={onRequestClose}
          asDialog={true}
          submitLabel={t.translate(`words.submit`)}
          defaultData={item}
          definitions={[
            {
              inputType: inputTypes.AUTOCOMPLETE,
              label: "Nama Akun",
              key: "akunId",
              validation: "required",
              data: subtotal,
              labelKey: "label",
              valueKey: "label",
            },
            {
              render: (
                <div style={{ width: "100%" }}>
                  <Hint
                    className="mpk-margin-N margin-top margin-bottom"
                    message="Tagihan"
                  />
                  <Button
                    theme="primary"
                    themeType="contained"
                    style={{ margin: 15 }}
                    onClick={() => addItem()}
                  >
                    <FontIcon iconClassName="mdi mdi-plus" />
                    {t.translate("words.add")}
                  </Button>
                  <Table style={{ width: "100%" }}>
                    <TableHeader>
                      <TableRow>
                        <TableCell style={{ width: "10%" }}>
                          {t.translate("words.non")}
                        </TableCell>
                        <TableCell style={{ width: "25%" }}>
                          {t.translate("words.nomorTransaksi")}
                        </TableCell>
                        <TableCell style={{ width: "25%" }}>
                          {t.translate("words.sisaTagihanAwal")}
                        </TableCell>
                        <TableCell style={{ width: "25%" }}>
                          {t.translate("words.sisaTagihanAkhir")}
                        </TableCell>
                        <TableCell style={{ width: "25%" }}>
                          {t.translate(
                            "words.nilaiTagihanOrPenerimaanYangTelahDibayarkan"
                          )}
                        </TableCell>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {dataTagihan.map((d, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{d.nomorTransaksi}</TableCell>
                            <TableCell>{d.sisaTagihanAwal}</TableCell>
                            <TableCell>{d.sisaTagihanAkhir}</TableCell>
                            <TableCell>
                              {d.nilaiTagihanYangTelahDibayarkan}
                            </TableCell>
                            <TableCell>
                              {/* <Button onClick={() => console.log("Masuk")}>{t.translate("words.edit")}</Button> */}
                              <Button onClick={() => hapusTagiahn(index)}>
                                {t.translate("words.delete")}
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TableCell>
                          <b>{t.translate(`words.nilaiUtang`)}</b>
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          {
                            (Array.prototype.sum = function (prop) {
                              var total = 0;
                              for (
                                var i = 0, _len = this.length;
                                i < _len;
                                i++
                              ) {
                                total += this[i][prop];
                              }
                              return total;
                            })
                          }
                          <b>{dataTagihan.sum("sisaTagihanAkhir")}</b>
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                  <br />
                </div>
              ),
              show: (d)=>{return d.kasBankAkun?.akun?.monitoringLawanTransaksi =="true"},
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: t.translate(`words.nilaiUtang`),
              key: "nilaiPiutangOrHutang",
              disabled: true,
              value: dataTagihan.sum("nilaiTagihanYangTelahDibayarkan"),
              thousandSeparator: ".",
              decimalSeparator: ",",
              isNumericString: true,
              show: (d)=>{return d.kasBankAkun?.akun?.monitoringLawanTransaksi =="true"},
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: "Nilai Beban",
              key: "nilaiPiutangOrHutang",
              thousandSeparator: ".",
              decimalSeparator: ",",
              isNumericString: true,
              show: (d)=>{return d.kasBankAkun?.akun?.monitoringLawanTransaksi =="false"},
            },
            {
              render: (
                <div style={{ width: "100%", color: "purple" }}>
                  <p>
                    <b>{t.translate(`words.pemotonganPajak`)}</b>
                  </p>
                </div>
              ),
            },
            {
              inputType: inputTypes.CHECKBOX,
              label: t.translate(`words.terdapatPemotonganPajak`),
              key: "isPajakSetorSendiriOrPotongPajak",
            },
            {
              inputType: inputTypes.AUTOCOMPLETE,
              label: "Pemotongan Pajak",
              className: "mpk-padding-N padding-right",
              key: "sbkPasalPemotonganIdId",
              data: sbkPasalPemotonganIdList,
              labelKey: "label",
              valueKey: "label",
              width: "50%",
              show: (d) => {
                return d.isPajakSetorSendiriOrPotongPajak;
              },
              required: (d) => {
                return !d.isPajakSetorSendiriOrPotongPajak;
              },
            },
            {
              inputType: inputTypes.AUTOCOMPLETE,
              label: t.translate(`words.atasTransaksi`),
              key: "sbkObjekPajakId",
              data: objekPajakList,
              labelKey: "label",
              valueKey: "label",
              width: "50%",
              show: (d) => {
                return d.isPajakSetorSendiriOrPotongPajak;
              },
              required: (d) => {
                return !d.isPajakSetorSendiriOrPotongPajak;
              },
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              className: "mpk-padding-N padding-right",
              label: "Tarif Pajak (%)",
              key: "pctTarifmajak",
              value: 0.5,
              disabled: true,
              show: (d) => {
                return d.isPajakSetorSendiriOrPotongPajak;
              },
              thousandSeparator: ".",
              decimalSeparator: ",",
            },
            {
              render: (
                <div style={{ width: "100%" }}>
                  <p style={{ margin: "10px" }}>
                    <b>Perhitungan Pajak Berdasarkan</b>
                  </p>
                </div>
              ),
              show: (d) => {
                return d.isPajakSetorSendiriOrPotongPajak;
              },
            },
            {
              inputType: inputTypes.SELECT,
              label: "Nilai Lain",
              key: "isNilaiLain2",
              options: [
                { label: "Nilai Beban", value: 0 },
                { label: "Nilai Lain", value: 1 },
              ],
              show: (d) => {
                return d.isPajakSetorSendiriOrPotongPajak;
              },
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: "Nilai Lain",
              key: "nilaiLain",
              thousandSeparator: ".",
              decimalSeparator: ",",
              isNumericString: true,
              show: (d) => {
                return (
                  d.isNilaiLain2 == 1 && d.isPajakSetorSendiriOrPotongPajak
                );
              },
            },
            {
              inputType: inputTypes.CHECKBOX,
              label: t.translate(`words.PajakDitanggung`),
              key: "isPajakDitanggung",
              show: (d) => {
                return d.isPajakSetorSendiriOrPotongPajak;
              },
              value: false,
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: t.translate(`words.nominalPajak`),
              className: "mpk-padding-N padding-right",
              key: "nominalPajak",
              thousandSeparator: ".",
              decimalSeparator: ",",
              isNumericString: true,
              disabled: true,
              show: showPajak
                ? (d) => {
                    return d.isPajakDitanggung;
                  }
                : false,
              width: "48%",
            },

            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: t.translate(`words.nilaiTagihan`),
              key: "nilaiTagihanOrPenerimaan",
              // value:dataTagihan.sum("nilaiTagihanYangTelahDibayarkan"),
              disabled: true,
              thousandSeparator: ".",
              decimalSeparator: ",",
              width: "48%",
              style: {
                marginRight: "2%",
              },
            },
            {
              render: (
                <div style={{ width: "100%" }}>
                  <p style={{ margin: "10px" }}>
                    <b>Informasi Tambahan</b>
                  </p>
                </div>
              ),
            },
            {
              inputType: inputTypes.TEXTAREA,
              label: t.translate(`words.keterangan`),
              key: "keterangan",
            },
            {
              inputType:inputTypes.PRE_DATA
            }
          ]}
          onChange={(formData, key, value) => {
            let pajak1 = (formData.nilaiLain * 0.5) / 100;
            let pajak2 = (formData.nilaiPiutangOrHutang * 0.5) / 100;
            if (key === "sbkPasalPemotonganIdId") {
              sbkPasalPemotonganIdList.map((d) => {
                if (d.label === value) {
                  formData["sbkPasalPemotonganId"] = {
                    id: d.value,
                    nama: d.label,
                  };
                }
              });
            }

            if (key === "sbkObjekPajakId") {
              objekPajakList.map((d) => {
                if (d.label === value) {
                  formData["objekPemotongan"] = {
                    id: d.value,
                    kode: d.kode,
                    nama: d.nama,
                  };
                }
              });
            }

            if (key === "akunId") {
              // console.log('masuk');
              subtotal.map((d) => {
                if (d.label === value) {
                  formData.kasBankAkun.akun.id = d.value;
                  formData.kasBankAkun.akun.monitoringLawanTransaksi =
                    d.monitoringLawanTransaksi;
                  sessionStorage.setItem("akunSubtotalId", d.value);
                }
              });

              if (
                formData.kasBankAkun.akun.monitoringLawanTransaksi === "true"
              ) {
                setShowUtangForm(true);
                setShowBebanForm(false);
              } else {
                setShowUtangForm(false);
                setShowBebanForm(true);
              }
            }


            if (key == "nilaiPiutangOrHutang") {
              formData.total = formData.nilaiBeban;
              formData.nilaiPenjualan = formData.nilaiBeban;
            }

            if (formData.isPajakSetorSendiriOrPotongPajak) {
              if (formData.isPajakDitanggung) {
                if (formData.isNilaiLain2 == 1) {
                  formData.nominalPajak = pajak1;
                  formData.nilaiTagihanOrPenerimaan = formData.nilaiPiutangOrHutang  ;
                } else {
                  formData.nominalPajak = pajak2;
                  formData.nilaiTagihanOrPenerimaan = formData.nilaiPiutangOrHutang ;
                  formData.nilaiLain = formData.nilaiPiutangOrHutang
                }
              }

              if (!formData.isPajakDitanggung) {
                if (formData.isNilaiLain2 == 1) {
                  formData.nominalPajak = pajak1;
                  formData.nilaiTagihanOrPenerimaan = formData.nilaiPiutangOrHutang - formData.nominalPajak;
                } else {
                  formData.nominalPajak = pajak2;
                  formData.nilaiTagihanOrPenerimaan = formData.nilaiPiutangOrHutang - formData.nominalPajak;
                  formData.nilaiLain = formData.nilaiPiutangOrHutang
                }
              }
            } else {
              formData.nilaiTagihanOrPenerimaan = formData.nilaiPiutangOrHutang;
            }
            setItem(formData);
            return formData;
          }}
          onSubmit={(data, callback) => {
            
            data.kasBankAkunFmList = dataTagihan;
            data.tagihan = showUtangForm==true?dataTagihan.sum('sisaTagihanAkhir'):data.nilaiTagihanOrPenerimaan
            localStorage.setItem("akunPengeluaranForm", JSON.stringify(data));
            callback("success", false);
            setItem(null);
            setDataTagihan([])
          }}
        />
        <KasDanBankTambahEditTagihanPengeluaran
          visible={dialogDaftarTagihanPengeluaran}
          onRequestClose={() => {
            akunTagihan();
            setDialogDaftarTagihanPengeluaran(false);
            setSelectedItemId(null);
          }}
          itemId={sessionStorage.getItem("akunSubtotalId")}
          lawanTrId={sessionStorage.getItem("lawanTransaksiId")}
        />
      </>
    )
  );
};

export default inject("modalStore")(
  observer(KasDanBankTambahEditInputDetailAkunUang)
);
