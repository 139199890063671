import React, { useEffect, useState } from 'react'
import { FormWrapper, TabsWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { Flex } from '../../libs/react-mpk/components'
import { useParams } from "react-router-dom";
import { inject, observer } from "mobx-react";
import service from './Penjualan.service';
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import { Button } from '@react-md/button';
import utilsService from '../../services/utilsService';
import { format, formatRupiah } from '../../libs/react-mpk/services/number.service';
import { toast } from '../../libs/react-mpk/services';

const PenjualanJurnalEntri = ({ match, className = "", envStore }) => {

    const baseName = `pembelian`
    const basePath = `pembelian`
    var npwpHeadId = match.params.npwpHeadId
    const {noTransaksi} = useParams()
    // console.log(noTransaksi,"no invoice");
    var backTo = `/internal/npwp/${npwpHeadId}/penjualan`
    const [debitList, setDebitList] = useState(null)
    const [kreditList, setKreditList] = useState(null)
    const [totalDebit, setTotalDebit] = useState(0)
    const [totalKredit, setTotalKredit] = useState(0)


    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = async () => {
        try {

            let resDebit = await service.getJurnalEntryByNoTransaksi(noTransaksi, 1)
            setDebitList(resDebit)
            setTotalDebit(resDebit.data.totalNominal)

            let resKredit = await service.getJurnalEntryByNoTransaksi(noTransaksi, 2)
            setKreditList(resKredit)
            setTotalKredit(resKredit.data.totalNominal)

        } catch (e) { }
    }

    return (
        <TabsWrapper
            title={t.translate(`words.jurnalEntri`)}
            baseId="mod-dashboard-sample-id"
            tabs={[
                {
                    label: t.translate(`words.jurnalEntri`),
                    key: 'pembelianJurnalEntri',
                    render: (
                        <FormWrapper
                            baseId="mod-nested-form"
                            showCommandbar={true}
                            title="Umum"
                            backTo={backTo}
                            defaultData={{
                                noTransaksi: utilsService.beautifyNoTransaksi(noTransaksi),
                                totalDebit: formatRupiah(totalDebit).toString(),
                                totalKredit: formatRupiah(totalKredit).toString(),
                            }}
                            definitions={[
                                {
                                    inputType: FormWrapper.inputTypes.INPUT,
                                    label: "Nomor Transaksi",
                                    key: 'noTransaksi',
                                    disabled: true
                                },
                                {
                                    inputType: FormWrapper.inputTypes.INPUT,
                                    label: "Total Debit",
                                    key: 'totalDebit',
                                    disabled: true
                                },
                                {
                                    inputType: FormWrapper.inputTypes.INPUT,
                                    label: "Total Kredit",
                                    key: 'totalKredit',
                                    disabled: true
                                }
                            ]}
                        />
                    )
                },
                {
                    label: "Debit",
                    key: 'pembelianDebit',
                    render: (
                        <TableWrapper
                            baseId="mod-table-pembelian-debit"
                            title='Debit'
                            className={className}
                            backTo={backTo}
                            showFilter={false}
                            useFilter={false}
                            // hintMessage={t.translate("modules.table.hint.message")}
                            // hintIconClassName="mdi mdi-information"
                            // hintMore={t.translate("modules.table.hint.more")}
                            defaultData={[]}
                            defaultSortBy="tanggalDibuat"
                            useCriteria={false}
                            columns={[
                                {
                                    label: "Nama Akun",
                                    // searchable: true,
                                    key: "name",
                                    render: item => (item.akun.kode) + ' - ' + (item.akun.nama),
                                },
                                {
                                    label: "Nominal",
                                    // searchable: true,
                                    key: "nominal",
                                    render: (item) =>formatRupiah(item.nominal),
                                },
                                {
                                    label: "Posisi",
                                    // searchable: true,
                                    key: "posisi",
                                    render: (item) =>
                                        item.kategori == 1
                                            ? "Debit"
                                            : item.kategori == 2
                                                ? "Kredit"
                                                : ""
                                }
                            ]}
                            onFetchData={async query => {
                                return (
                                    new Promise(async (resolve, reject) => {
                                        try {
                                            // query = {
                                            //   ...search,
                                            //   ...query
                                            // }
                                            // let getPage = await service.getPage(query)
                                            let data = {
                                                data: debitList.data.jurnalEntryList,
                                                headers: debitList.headers
                                            }
                                            data.headers['x-pagination-count'] = data.headers['x-total-count']
                                            resolve(data)
                                        } catch (e) {
                                            toast.errorRequest(e)
                                            resolve()
                                            // ErrorService(e)
                                        }
                                    })
                                )
                            }}
                        />
                    )
                },
                {
                    label: 'Kredit',
                    key: 'pembelianKredit',
                    render: (
                        <TableWrapper
                            baseId="mod-table-pembelian-kredit"
                            title='Kredit'
                            backTo={backTo}
                            className={className}
                            showFilter={false}
                            useFilter={false}
                            defaultData={[]}
                            defaultSortBy="tanggalDibuat"
                            columns={[
                                {
                                    label: "Nama Akun",
                                    // searchable: true,
                                    key: "name",
                                    render: item => (item.akun.kode) + ' - ' + (item.akun.nama),
                                },
                                {
                                    label: "Nominal",
                                    // searchable: true,
                                    key: "nominal",
                                    render: (item) =>formatRupiah(item.nominal),
                                },
                                {
                                    label: "Posisi",
                                    // searchable: true,
                                    key: "posisi",
                                    render: (item) =>
                                        item.kategori == 1
                                            ? "Debit"
                                            : item.kategori == 2
                                                ? "Kredit"
                                                : "",
                                }
                            ]}
                            onFetchData={async query => {
                                return (
                                    new Promise(async (resolve, reject) => {
                                        try {
                                            // query = {
                                            //   ...search,
                                            //   ...query
                                            // }
                                            // let getPage = await service.getPage(query)
                                            let data = {
                                                data: kreditList.data.jurnalEntryList,
                                                headers: kreditList.headers
                                            }
                                            data.headers['x-pagination-count'] = data.headers['x-total-count']
                                            resolve(data)
                                        } catch (e) {
                                            toast.errorRequest(e)
                                            resolve()
                                            // ErrorService(e)
                                        }
                                    })
                                )
                            }}
                        />
                    )
                }
            ]}
        />
    );
};

export default inject("envStore")(observer(PenjualanJurnalEntri))
