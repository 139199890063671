import React, { useEffect, useState } from 'react';
import { DataForm } from '../../libs/react-mpk/components';
import { inject, observer } from 'mobx-react';
import ModalCredential from './ModalCredential';

const defaultData = () => ({
	marketplace:null,
})

const MarketForm = ({
	visible = false,
	authStore,
	envStore,
	onRequestClose = () => { },
  navigationStore,
  modalStore,
	...props
}) => {
	let [ready, setReady] = useState(true)
	let [item, setItem] = useState(null)
	let [selected, setSelected] = useState(null)
	let [showModal, setShowModal] = useState(false)
	useEffect(() => {
		async function initData(){
			if(visible){
				let selectedItem = defaultData();
				if(props.itemId){
				
				}
				setItem(selectedItem)
			}else{
				setItem(defaultData())
			}
			setReady(visible)
		}
		initData()
	}, [visible])

  const onsubmit = (data,callback) => {
    setSelected(data.marketplace)
    setShowModal(true)
    callback('', true, false)
  }

	return ready && (
    <>
		<DataForm
			baseId="marketplace-form"
			title={'Marketplace'}
			visible={visible}
			onRequestClose={onRequestClose}
			asDialog={true}
			defaultData={item}
			definitions={[
        {
          inputType: DataForm.inputTypes.SELECT,
          label: 'Pilih Marketplace',
          key: 'marketplace',
          options:[
              {label:"Sobat Marketplace", value:"Sobat Marketplace"},
          ],
          validation:'required'
      },
			]}
      
			onSubmit={onsubmit}
		/>
    <ModalCredential
        visible={showModal}
        onRequestClose={() => {
          setShowModal(false)
          setSelected(null)
          document.getElementsByClassName('mdi-reload')[0].click()
        }}
        itemId={selected}
      />
    </>
	)
}

export default inject('modalStore', 'navigationStore')(observer(MarketForm))