import React, { useState, useEffect } from 'react';
import t from 'counterpart'
import { DataForm } from '../../../libs/react-mpk/components';
import { FormWrapper } from '../../../libs/react-mpk/wrapper';
import service from '../KasDanBank.service';
import { find } from 'lodash'
import { toast } from '../../../libs/react-mpk/services';
import iziToast from 'izitoast';

const defaultData = () => ({
    nilaiTagihanDibayar: 0,
    keterangan: "",
    fm: {
        id: null
    }
})

const KasDanBankTambahEditTagihanPengeluaran = ({
    visible = false,
    authStore,
    envStore,
    match,
    history,
    onRequestClose = () => { },
    ...props
}) => {
    const { inputTypes, definition } = FormWrapper
    const [ready, setReady] = useState(true)

    const [pesanan, setPesanan] = useState([])
    const [datapesanan, setDatapesanan] = useState([])

    
    async function pesananList() {
        if (visible) {
            if (props.itemId) {
                // setTimeout(async () => {
                    let res = await service.getPembelianStatus(props.itemId, props.lawanTrId)
                var pesanans = []
                res.data.map((d) => {
                    pesanans.push({
                        value: d.id,
                        label: `${d.lawanTransaksi.nama} - ${d.noTransaksi} - Rp. ${d.pembayaran.sisaBayar}`,
                        total: `${d.total}`,
                        noTransaksi: `${d.noTransaksi}`,
                        sisaBayar: `${d.pembayaran.sisaBayar}`
                    })
                })
                setPesanan(pesanans)
                setDatapesanan(res.data)
                // }, 500)
            }
        }
        setReady(visible)
    }
    
    useEffect(() => {
        pesananList()
    }, [visible])

    useEffect(() => { },[props])
    
    return ready && (
        <>
            <DataForm
                baseId="kas-dan-bank-tambah-edit-tagihan-pengeluaran"
                title={t.translate(`modules.KasDanBank.tambahEditTagihanPengeluaran`)}
                visible={visible}
                onRequestClose={onRequestClose}
                asDialog={true}
                submitLabel={t.translate(`words.submit`)}
                defaultData={
                    {
                        // nomorTransaksi:"PB-0001/01/2021",
                        sisaTagihanAwal: 0,
                        sisaTagihanAkhir: 0,
                        nilaiTagihanDibayar: 0,
                        keterangan: "",
                        fm: {
                            id: null
                        }
                    }
                }
                definitions={[
                    {
                        inputType: inputTypes.AUTOCOMPLETE,
                        label: 'Nama pesanan',
                        key: 'fmId',
                        validation: 'required',
                        data: pesanan,
                        labelKey: 'label',
                        valueKey: 'label',
                    },
                    {
                        inputType: DataForm.inputTypes.INPUT,
                        label: t.translate(`words.nomorTransaksi`),
                        key: 'nomorTransaksi',
                        disabled: true
                    },
                    {
                        inputType: inputTypes.DIVIDER,
                        label: t.translate(`words.masukanNilaiTagihanYangAkanSobatBayarkan`)
                    },
                    {
                        inputType: inputTypes.INPUT_MASK_NUMBER,
                        label: t.translate(`words.sisaTagihanAwal`),
                        key: "sisaTagihanAwal",
                        thousandSeparator: ".",
                        decimalSeparator: ",",
                        width: "49%",
                        disabled: true,
                        style: {
                            marginRight: "2%"
                        },
                    },
                    {
                        inputType: inputTypes.INPUT_MASK_NUMBER,
                        label: t.translate(`words.nilaiTagihanYangTelahDibayarkan`),
                        key: 'nilaiTagihanYangTelahDibayarkan',
                        thousandSeparator: ".",
                        decimalSeparator: ",",
                        width: "49%",
                        validation: 'required'
                    },
                    {
                        inputType: inputTypes.INPUT_MASK_NUMBER,
                        label: t.translate(`words.sisaTagihanAkhir`),
                        key: 'sisaTagihanAkhir',
                        thousandSeparator: ".",
                        decimalSeparator: ",",
                        disabled: true,
                    },
                    {
                        inputType: inputTypes.DIVIDER,
                        label: t.translate(`words.informasiTambahan`),
                    },
                    {
                        inputType: inputTypes.TEXTAREA,
                        label: t.translate(`words.keterangan`),
                        key: 'keterangan',
                    },
                ]}
                onChange={(data, key, value) => {
                    if (key === 'fmId') {
                        pesanan.map((d) => {
                            // console.log(d, "d");
                            if (d.label === value) {
                                data.fm.id = d.value
                                data['nomorTransaksi'] = d ? d.noTransaksi : ""
                                data['sisaTagihanAwal'] = d ? d.sisaBayar : ""
                            }
                        })
                    }
                    if (key == 'nilaiTagihanYangTelahDibayarkan') {
                        data.sisaTagihanAkhir = data.sisaTagihanAwal - data.nilaiTagihanYangTelahDibayarkan
                        if (data.nilaiTagihanYangTelahDibayarkan > data.sisaTagihanAwal) {
                            iziToast.info({
                                message: "Melebihi sisa bayar"
                            })
                        }
                        else if (data.nilaiTagihanYangTelahDibayarkan < 0) {
                            iziToast.info({
                                message: "Nominal Tidak Boleh Minus"
                            })
                        }
                        data.nilaiTagihanDibayar = data.nilaiTagihanYangTelahDibayarkan
                    }
                }}
                onSubmit={(data, callback) => {
                    localStorage.setItem("kasBankAkunfmListForm", JSON.stringify(data))
                    callback('success', false)
                }}
            />
        </>
    )
}

export default KasDanBankTambahEditTagihanPengeluaran