import React, { useState, useEffect } from 'react';
import t from 'counterpart'
import { FormWrapper } from '../../../libs/react-mpk/wrapper'
import { useParams } from 'react-router';
import service from '../KasDanBank.service';

const KasDanBankDetailKepemilikanModal = (props) => {
	const { inputTypes, definition } = FormWrapper
    const [dataModal, setdata] = useState(null)
	const {id} = useParams()
    // const fetchData = async() =>{
    //     console.log(id);
    //     try {
    //       let res = await service.getById(id)
    //       setdata(res.data)
    //       console.log(res.data);
	// 	  console.log(dataModal);
    //     } catch (error) {
    //       console.log(error);
    //     }
    // }
    // useEffect(() => {
    //     fetchData()
    // },[])
	return(
        <>
		<FormWrapper
			baseId="kas-dan-bank-tambah-edit-input-akun-uang"
			title={t.translate(`modules.KasDanBank.detailKepemilikanModal`)}
            backTo='/internal/kas-dan-bank-tambah-edit-akun'
            submitLabel={t.translate(`words.submit`)}
			defaultData={{
				nama: "Anton",
				jumlah: "Rp. 10.000.000",
				presentase: "50%",
				id:'1',
				jenisUsaha:"Orang Pribadi/Perseorangan",
				npwp:"90.123.123.1-100.000",
				nikPaspor:"1375011704960001",
				alamat:"Jl. Kemanggisan Utama Raya No. J4, Jakarta Barat, DKI Jakarta",
				email:"anton@gmail.com",
				jumlahKepemilikanModal:"Rp. 10.000.000"
			}}
			showCommandbar={true}
			definitions={[
				{
					inputType: FormWrapper.inputTypes.DIVIDER,
					label: t.translate(`modules.KasDanBank.detailKepemilikanModal`),
					key: 'nama',
                },
				{
					inputType: FormWrapper.inputTypes.INPUT,
					label: t.translate(`words.name`),
					key: 'nama',
                },
				{
					inputType: FormWrapper.inputTypes.INPUT,
					label: t.translate(`words.jumlahKepemilikanModal`),
					key: 'jumlahKepemilikanModal',
                },
				{
					inputType: FormWrapper.inputTypes.INPUT,
					label: t.translate(`words.presentase`),
					key: 'presentase',
                },
				{
					inputType: FormWrapper.inputTypes.INPUT,
					label: t.translate(`words.jenisUsahaLawanTransaksi`),
					key: 'jenisUsahaLawanTransaksi',
                },
				{
					inputType: FormWrapper.inputTypes.INPUT,
					label: t.translate(`words.npwp`),
					key: 'npwp',
                },
				{
					inputType: FormWrapper.inputTypes.INPUT,
					label: t.translate(`words.nikPaspor`),
					key: 'nikPaspor',
                },
				{
					inputType: FormWrapper.inputTypes.INPUT,
					label: t.translate(`words.email`),
					key: 'email',
                },
				{
					inputType: FormWrapper.inputTypes.INPUT,
					label: t.translate(`words.alamat`),
					key: 'alamat',
                },
			]}
		/>
        </>
	)
}

export default KasDanBankDetailKepemilikanModal