import React, { useEffect, useState } from 'react'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { sum } from 'lodash'
import { FormWrapper, TableWrapper } from '../../../libs/react-mpk/wrapper'
import FormTanggal from '../FormTanggal'
import { Table, TableBody, TableHeader, TableRow, TableCell, Text, Button, FontIcon, TableFooter } from 'react-md'
import { Hint } from '../../../libs/react-mpk/components'
import service from './ReportLabaRugiKomersial.service'
import iziToast from 'izitoast'
import ReportLabaRugiKomersial from './ReportLabaRugiKomersial'
import { format } from '../../../libs/react-mpk/services/number.service'
import { useHistory, useLocation } from 'react-router-dom'

const ReportLabaRugiKomersialShowDetail = ({
    navigationStore,
    modalStore,
    showCommandbar = true,
    match
}) => {
    let history = useHistory()
  const { state: { tahun } = {} } = useLocation();
    let npwpHeadId = sessionStorage.getItem('npwpHeadId')

    const [data, setData] = useState([])
    const [load, setload] = useState(true)
    const [showDetail, setShowDetail] = useState(false)

    const fetchData = async () => {
        try {
            let query = {
                "tahun": tahun?tahun: sessionStorage.getItem('tahunSobatBuku')
            }
            
            let res = await service.getAllLabaRugiKomersialShowDetail(query);
            setData(res.data);
            setload(false);
            setShowDetail(false)
        } catch (error) {
         
        }
    };

    useEffect(() => {
        fetchData();
    }, []);
    return (
        <>
            {showDetail == false
                ?
                <>
                    <FormWrapper
                        loading={load}
                        showBackTo={false}
                        style={{
                            maxWidth: '100%'
                        }}
                        title={t.translate(`modules.report.labaRugiKomersialDetail`)}
                        showCommandbar={false}
                        baseId="mod-table-laporan-laba-rugi-komersial-show-detail"
                        definitions={[
                            // {
                            //     render: (
                            //         <div style={{ width: '100%' }}>
                            //             <FormTanggal />
                            //         </div>

                            //     )
                            // },
                            {
                                render: (
                                    <div style={{ width: '100%' }}>
                                        <Button
                                            theme="primary"
                                            // themeType="contained"
                                            style={{ margin: 15 }}
                                            onClick={() => {
                                                setShowDetail(true)
                                            }}
                                        >
                                            <Text>{"Show Detail Close"}</Text>
                                        </Button>
                                    </div>

                                )
                            },
                            {
                                render: (
                                    <div style={{ width: '100%' }}>
                                        <Table style={{ width: '100%' }}>
                                            <TableHeader>
                                                <TableRow>
                                                    <TableCell style={{ width: '6%' }}>{t.translate('words.non')}</TableCell>
                                                    <TableCell style={{ width: '6%' }}>{t.translate('words.aksi')}</TableCell>
                                                    <TableCell style={{ width: '22%' }}>{t.translate('words.akun')}</TableCell>
                                                    <TableCell style={{ width: '22%' }}>{t.translate('words.saldo')}</TableCell>
                                                </TableRow>
                                            </TableHeader>
                                            <TableBody>
                                                {data.map((d, index) => {
                                                  
                                                    return (
                                                        <TableRow key={index}>
                                                            <TableCell>{index + 1}</TableCell>
                                                            <TableCell>
                                                                <Button
                                                                    theme="primary"
                                                                    // themeType="contained"
                                                                    // style={{ margin: 15 }}
                                                                    onClick={() => {
                                                                        history.push({
                                                                            pathname: `/internal/npwp/${npwpHeadId}/report-laba-rugi/${d.akun.id}`,
                                                                            state: { tahun: tahun },
                                                                          });
                                                                          sessionStorage.setItem('namaAkun', d.akun.nama)
                                                                          sessionStorage.setItem('kodeAkun', d.akun.kode)
                                                                          sessionStorage.setItem('saldoAwallabaRugi', d.saldoAwal)
                                                                    }}
                                                                >
                                                                    <FontIcon iconClassName="mdi mdi-import" />
                                                                    {/* {t.translate("words.add")} */}
                                                                </Button>
                                                            </TableCell>
                                                            <TableCell>{d.akun.kode + ' - ' + d.akun.nama}</TableCell>
                                                            <TableCell>{'Rp. ' + format(d.saldoAkhir)}</TableCell>

                                                        </TableRow>
                                                    )

                                                })}
                                            </TableBody>
                                        </Table>
                                    </div>
                                )
                            },
                        ]}
                        actions={[]}
                        itemActions={[]}
                        // showCommandbar={showCommandbar}
                        showFilterPeriod={true}
                        useCriteria={false}
                    />
                </>
                : <ReportLabaRugiKomersial match={match} />
            }
        </>
    )
}

export default inject("navigationStore", 'envStore')(observer(ReportLabaRugiKomersialShowDetail))