export const defaultDataPenjualanDetail = {
  dibuatOleh: null,
  tanggalDibuat: null,
  diperbaharuiOleh: null,
  tanggalDiperbaharui: null,
  id: null,
  kategoriTransaksi: null,
  noInvoice: null,
  tanggalInvoice: null,
  periodeJatuhTempo: null,
  tanggalJatuhTempo: null,
  totalPenjualan: null,
  subtotal: null,
  subtotalAkun: null,
  totalPajakSetor: 0,
  totalBeban: 0,
  total: null,
  keterangan: null,
  fileLampiran: null,
  fileLampiranAlias: null,
  fileLampiranInstance: null,
  isPpn: false,
  pesanan: null,
  pembayaran: {
    id: null,
    status: null,
    ptsBayar: null,
    nominalBayar: null,
    sisaBayar: null,
    akun: {
      dibuatOleh: null,
      tanggalDibuat: null,
      diperbaharuiOleh: null,
      tanggalDiperbaharui: null,
      id: null,
      kode: null,
      nama: null,
      monitoringLawanTransaksi: false,
      monitoringPersediaan: false,
      inputNominatifEntertaint: false,
      inputNominatifPromosi: false,
      defaultKoreksiFiskal: false,
      kategoriAkun: {
        dibuatOleh: null,
        tanggalDibuat: null,
        diperbaharuiOleh: null,
        tanggalDiperbaharui: null,
        id: null,
        kode: null,
        nama: null,
        parentKategori: null,
        kategori: null,
        childKategori: null,
        kategoriAset: null,
        tambahTransferAntarBank: false,
        pemotonganPajak: false,
        jurnal: false,
        pilihAkunHutang: false,
        pilihAkunKas: false,
        pilihAkunAktiva: false,
        pengaturanAkunGaji: false,
        pengaturanAkunPajak: false,
        debitKredit: null,
        kategoriFiskal: 0,
        pilihKategoriAset: false,
        pilihKategoriHutang: false,
        monitoringLawanTransaksi: false,
        kategoriLawanTransaksi: 0,
        pembelian: false,
        penjualan: false,
        monitoringPersediaan: false,
      },
    },
    diperbaharuiOleh: null,
    tanggalDiperbaharui: null,
  },
  lawanTransaksi: {
    dibuatOleh: null,
    tanggalDibuat: null,
    diperbaharuiOleh: null,
    tanggalDiperbaharui: null,
    id: null,
    kategori: null,
    jenisUsaha: null,
    nama: null,
    npwp: null,
    nik: null,
    alamat: null,
    namaPic: null,
    emailPic: null,
    noTeleponPic: null,
    alamatSama: false,
    alamatPic: null,
    npwpHead: null,
  },
  npwpHead: {
    dibuatOleh: null,
    tanggalDibuat: null,
    diperbaharuiOleh: null,
    tanggalDiperbaharui: null,
    id: null,
    npwp: null,
    namaNpwp: null,
    noKtp: null,
    noTelepon: null,
    noTeleponB: null,
    email: null,
    alamat: null,
    npwpOp: false,
    pengurusanEfin: null,
    noEfin: null,
    pkp: false,
    tanggalPkp: null,
    registerEfiling: false,
    kodeRegisterEfiling: null,
    messageRegisterEfiling: null,
    halonaCompanyId: null,
    pengguna: null,
    area: null,
  },
  akunPiutang: null,
  fp: null,
};

export const defaultDataPembelianDetail = {
  dibuatOleh: null,
  tanggalDibuat: null,
  diperbaharuiOleh: null,
  tanggalDiperbaharui: null,
  id: null,
  kategoriTransaksi: null,
  noTransaksi: null,
  tanggalInvoice: null,
  periodeJatuhTempo: null,
  tanggalJatuhTempo: null,
  totalPenjualan: null,
  subtotal: null,
  subtotalAkun: null,
  totalPajakSetor: 0,
  totalBeban: 0,
  total: null,
  keterangan: null,
  fileLampiran: null,
  fileLampiranAlias: null,
  fileLampiranInstance: null,
  isPpn: false,
  pesanan: null,
  pembayaran: {
    id: null,
    status: null,
    ptsBayar: null,
    nominalBayar: null,
    sisaBayar: null,
    akun: {
      dibuatOleh: null,
      tanggalDibuat: null,
      diperbaharuiOleh: null,
      tanggalDiperbaharui: null,
      id: null,
      kode: null,
      nama: null,
      monitoringLawanTransaksi: false,
      monitoringPersediaan: false,
      inputNominatifEntertaint: false,
      inputNominatifPromosi: false,
      defaultKoreksiFiskal: false,
      kategoriAkun: {
        dibuatOleh: null,
        tanggalDibuat: null,
        diperbaharuiOleh: null,
        tanggalDiperbaharui: null,
        id: null,
        kode: null,
        nama: null,
        parentKategori: null,
        kategori: null,
        childKategori: null,
        kategoriAset: null,
        tambahTransferAntarBank: false,
        pemotonganPajak: false,
        jurnal: false,
        pilihAkunHutang: false,
        pilihAkunKas: false,
        pilihAkunAktiva: false,
        pengaturanAkunGaji: false,
        pengaturanAkunPajak: false,
        debitKredit: null,
        kategoriFiskal: 0,
        pilihKategoriAset: false,
        pilihKategoriHutang: false,
        monitoringLawanTransaksi: false,
        kategoriLawanTransaksi: 0,
        pembelian: false,
        penjualan: false,
        monitoringPersediaan: false,
      },
    },
    diperbaharuiOleh: null,
    tanggalDiperbaharui: null,
  },
  lawanTransaksi: {
    dibuatOleh: null,
    tanggalDibuat: null,
    diperbaharuiOleh: null,
    tanggalDiperbaharui: null,
    id: null,
    kategori: null,
    jenisUsaha: null,
    nama: null,
    npwp: null,
    nik: null,
    alamat: null,
    namaPic: null,
    emailPic: null,
    noTeleponPic: null,
    alamatSama: false,
    alamatPic: null,
    npwpHead: null,
  },
  npwpHead: {
    dibuatOleh: null,
    tanggalDibuat: null,
    diperbaharuiOleh: null,
    tanggalDiperbaharui: null,
    id: null,
    npwp: null,
    namaNpwp: null,
    noKtp: null,
    noTelepon: null,
    noTeleponB: null,
    email: null,
    alamat: null,
    npwpOp: false,
    pengurusanEfin: null,
    noEfin: null,
    pkp: false,
    tanggalPkp: null,
    registerEfiling: false,
    kodeRegisterEfiling: null,
    messageRegisterEfiling: null,
    halonaCompanyId: null,
    pengguna: null,
    area: null,
  },
  akunUtang: null,
  fp: null,
};
export const defaultDataAkunNpwp  = () => ( {
  npwpOpInt: "0",
  npwpOp: true,
  noKtp: "",
  memilikiNpwp: true,
  npwp: "",
  namaNpwp: "",
  noTelepon: "",
  email: "",
  alamat: "",
  kodepos: null,
  noEfin: "",
  kluId:null,
  klu:null,
  pegawai: false,
  pemilikUsaha: false,
  pekerjaBebas: false,
  pkp: false,
  tanggalPkp: null,
  jumlahTanggungan: "0",
  npwpPasangan: "",
  pisahHarta: false,
  statusNikah: 1,
  statusNikahStr: "Belum Menikah",
  tahun: new Date().getFullYear(),
  tanggalMulai: new Date(),
  kenaPphFinal: false,
  omzetSudahDihitung: 0
});
export const defaultDataAkunNpwpSubmit = () => ( {
  id: null,
  npwpHead: {
    npwpOp: true,
    namaNpwp: "",
    npwp: "",
    alamat: "",
    area: {
      id: null,
      jenis: "Tidak Ada",
      kabupaten: "Tidak Ada",
      kecamatan: "Tidak Ada",
      kelurahan: "Tidak Ada",
      kodepos: null,
      propinsi: "Tidak Ada",
    },
    email: "",
    pengurusanEfin: 1,
    noEfin: "",
    klu:{id:null},
    noKtp: "",
    noTelepon: "",
    noTeleponB: "",
    pkp: true,
    tanggalPkp: null,
  },
  jumlahTanggungan: "0",
  npwpPasangan: "",
  pegawai: false,
  pekerjaBebas: false,
  pemilikUsaha: false,
  pisahHarta: false,
  statusNikah: 1,
  tahun: new Date().getFullYear(),
  dataAwal: {
    tanggalMulai: new Date(),
    kenaPphFinal: false,
    omzetSudahDihitung: 0
  },
});
