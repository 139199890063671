import React, { useEffect, useState } from "react";
import { DataForm, LoaderInfo } from "../../../libs/react-mpk/components";
import t from "counterpart";
import { TableWrapper } from "../../../libs/react-mpk/wrapper";
import service from "./TambahSaldoAwal.service";
import { inject, observer } from "mobx-react";
import AkunCoaService from "../../AkunCoa/AkunCoa.service";
import { toast } from "../../../libs/react-mpk/services";
import { inputTypes } from "../../../libs/react-mpk/config/constant";

const TambahSaldoAwalForm = ({
  visible = false,
  authStore,
  envStore,
  modalStore,
  match,
  history,
  onRequestClose = () => {},
  ...props
}) => {
  var tahunSobatPajak = sessionStorage.getItem("tahunSobatBuku");
  const defaultData = () => ({
    id: null,
    akunId: null,
    akun: {
      id: null,
    },
    monitoringPersediaan: false,
    saldoAwal: 0,
    tahun: tahunSobatPajak,
  });
  let [isEdit, setIsEdit] = useState(false);
  let [isEditAkun, setIsEditAkun] = useState(false);
  let [dataSubmit, setDataSubmit] = useState({
    id: null,
    akunId: null,
    nama: "",
    akun: {
      id: null,
      kode: "",
      nama: "",
    },
    monitoringPersediaan: false,
    saldoAwal: null,
    tahun: tahunSobatPajak,
  });

  let [item, setItem] = useState(null);
  let [ready, setReady] = useState(true);
  var thousandSeparator = ".";
  var decimalSeparator = ",";
  const [akunList, setAkunList] = useState([]);  
  let [queryAkun, setQueryAkun] = useState("");
  const [loadingAkun, setLoadingAkun] = useState(false);


  useEffect(() => {
    async function initData() {
      if (visible) {
        let selectedItem = defaultData();
        if (props.itemId) {
          setIsEdit(true);
          try {
            let res = await service.getOneById(props.itemId);
            let { data } = res;
            setIsEditAkun(true);
            setDataSubmit(data);
            selectedItem.id = data.id;
            selectedItem.akunId = data.akun.id;
            selectedItem.nama = data.akun.nama;
            selectedItem.akun.id = data.akun.id;
            selectedItem.akun.kode = data.akun.kode;
            selectedItem.akun.nama = data.akun.nama;
            selectedItem.monitoringPersediaan = data.monitoringPersediaan;
            selectedItem.saldoAwal = data.saldoAwal;
            selectedItem.tahun = data.tahun;
            setQueryAkun(data.akun.nama)
          } catch (e) {
            onRequestClose();
          }
        } else {
          setQueryAkun("")
          setIsEditAkun(false);
          setIsEdit(false);
        }
        setItem(selectedItem);
      } else {
        setItem(defaultData());
      }
      setReady(visible);
    }
    initData();
  }, [visible]);
  useEffect(() => {
    setLoadingAkun(true);
    const timeOutId = setTimeout(
      () => getAkun(queryAkun),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [queryAkun]);
  async function getAkun(queryNama) {
    try {
      setLoadingAkun(true)
      let query = {
        page: 0,
        size: 10,
        sort: "kode,ASC",
        "nama.contains": queryNama,
      };

      let res = await AkunCoaService.getPage(query);
      var akuns = [];
      res.data.map((d) => {
        akuns.push({
          value: d.id,
          label: `${d.kode} - ${d.nama}`,
          kode: d.kode,
          nama: d.nama,
        });
      });
      setAkunList(akuns);
      setLoadingAkun(false)
    } catch (error) {
      toast.errorRequest(error)
      setLoadingAkun(false)
    }
  }

  const onSubmit = async (data, callback) => {
    let reqData = dataSubmit;

    reqData.id = data.id;
    reqData.akun.id = data.akun.id;
    reqData.akun.nama = data.nama;
    reqData.akun.kode = data.kode;
    reqData.monitoringPersediaan = data.monitoringPersediaan;
    reqData.saldoAwal = data.saldoAwal;

    if (isEdit == true) {
      try {
        const res = await service.update(reqData);
        let { data, status } = res;
        if (status == 200) {
          TableWrapper.updateDataItem(
            "tambah-saldo-awal",
            { id: data.id },
            data
          );
          TableWrapper.reload("tambah-saldo-awal");
        }
        callback("success", false);
      } catch (err) {
        callback(err, true, false);
      }
    } else {
      try {
        const res = await service.create(reqData);
        let { data, status } = res;
        if (status == 200) {
          TableWrapper.updateDataItem(
            "tambah-saldo-awal",
            { id: data.id },
            data
          );
          TableWrapper.reload("tambah-saldo-awal");
        }
        callback("success", false);
      } catch (err) {
        callback(err, true, false);
      }
    }
  };

  return (
    ready && (
      <>
        <DataForm
          baseId="tambah-saldo-awal-form"
          title={t.translate(`modules.tambahSaldoAwal.form`)}
          visible={visible}
          onRequestClose={onRequestClose}
          asDialog={true}
          style={{ width: "100vh" }}
          defaultData={item}
          definitions={[
            {
              inputType: inputTypes.REACT_SELECT,
                    isClearable:true,
              label: t.translate(`words.akun`),
              key: "akunId",
              type: "search",
              labelKey: "label",
              valueKey: "value",
              options: akunList,
              tooltip:'Jika Akun tidak ditemukan,<br> silahkan ketik nama Akun yang ingin<br> dicari pada kolom Akun',
              isLoading: loadingAkun,
              onInputChange: (value) => {
                if (value) setQueryAkun(value);
              },
              isDisabled: props.itemId ,
              validation: "required",
            },
            {
              inputType: DataForm.inputTypes.INPUT,
              label: t.translate(`words.name`),
              key: "nama",
              disabled: true,
            },
            {
              inputType: DataForm.inputTypes.INPUT_MASK_NUMBER,
              label: t.translate(`words.saldoAwal`),
              thousandSeparator: thousandSeparator,
              decimalSeparator: decimalSeparator,
              prefix:'Rp.',
              key: "saldoAwal",
              validation: "required",
            },
          ]}
          onChange={async (formData, key, value) => {
            if (key == "akunId") {
              akunList.map((d) => {
                if (d.value == value) {
                  formData["akun"]["id"] = d.value;
                  formData["nama"] = d.nama;
                }
              });
            }
          }}
          onSubmit={onSubmit}
        />
      </>
    )
  );
};

export default inject(
  "navigationStore",
  "envStore",
  "modalStore"
)(observer(TambahSaldoAwalForm));
