import React, { useState } from 'react';
import t from 'counterpart'
import { FormWrapper } from '../../../libs/react-mpk/wrapper';
import { DataForm } from '../../../libs/react-mpk/components';
import { inject, observer } from 'mobx-react'

const KasDanBankPenerimaanPPN = ({
	visible = false,
    navigationStore,
	authStore,
	envStore,
	match,
	history,
	onRequestClose = () => { },
	...props
}) => {
	const { inputTypes, definition } = FormWrapper
	const [ready, setReady] = useState(true)
    const katAkun =[
        {
            nama:"Kas",
            id:'1.1.01.0001'
        },
        {
            nama:"Bank",
            id:'1.1.02.0001'
        },
    ]
	return ready && (
		<DataForm
			baseId="kas-dan-bank-tambah-edit-kepemilikan-modal"
			title={t.translate(`modules.KasDanBank.pilihJenisPenerimaan`)}
			visible={visible}
			onRequestClose={onRequestClose}
			asDialog={true}
            submitLabel={t.translate(`words.next`)}
            style={{
				maxWidth: '100%'
			}}
			defaultData={
                {
                    name:"Anton",
                    sobatMenerimaPembayaranTersebutPadaTanggal:"2000/01/01",
                }
            }
			definitions={[
				{
					inputType: DataForm.inputTypes.SELECT,
					label: t.translate(`words.sobatMenerimaPembayaran`),
					key: 'name',
                    options: katAkun.map(d => ({
                        label:d.id + " - " + d.nama,
                        value: d.id
                    })),
                    // width: '49%',
                    // style: {
                    //     marginRight: "2%"
                    // }
                },
                {
                    inputType: inputTypes.DATEPICKER,
                    label: t.translate(`words.sobatMenerimaPembayaranTersebutPadaTanggal`),
                    key: 'sobatMenerimaPembayaranTersebutPadaTanggal',
                    // width: '49%'
                },
			]}
			onSubmit={(data, callback) => {
                // onClick={()=>{
                //     navigationStore.redirectTo(
                //         `/internal/kas-dan-bank-penerimaan-non-ppn`
                //     )
                // }}
                    navigationStore.redirectTo(
                        `/internal/penjualan/:id`
                    )
                // console.log(data);
                setTimeout(callback, 2000)
            }}
		/>
	)
}
export default inject("navigationStore",'envStore')(observer(KasDanBankPenerimaanPPN))