import { crud, http } from '../../libs/react-mpk/services';

let service = null;
let serviceUrl = '/pesanan'
let npwpHeadIdObject = {
    npwpHeadId: sessionStorage.getItem('npwpHeadId')
}
let npwpHeadId = sessionStorage.getItem('npwpHeadId')
let tahunSobatBuku = sessionStorage.getItem('tahunSobatBuku')
let status = 2
let statusSelesai = 3
let statusBatal = 4
service = new crud(`/pesanan`)
//PESANAN
service.getAll = async (query) => {
    query = {
        ...query,
        ... npwpHeadIdObject,
        tahun: tahunSobatBuku
    }
    return http.request({
        method: http.methods.GET,
        url: `${serviceUrl}`,
        query
    })
}
service.getOneById = async(id) => {
    return http.request({
      method: http.methods.GET,
      url: `${serviceUrl}/${id}`
    })
}
  
service.getOneByNoPesanan = async (noPesanan) => {
    let query = {
        npwpHead: npwpHeadId
    }
    return http.request({
        method: http.methods.GET,
        url: `${serviceUrl}/get_one_by_no_pesanan/${noPesanan}`,
        query
    })
}
service.post = async (data) => {
    return http.request({
        method: http.methods.POST,
        url: `${serviceUrl}`,
        data,
    })
}
service.edit = async (id, data) => {
    return http.request({
        method: http.methods.PUT,
        url: `${serviceUrl}/${id}`,
        data,
        config: {
            params: {
                npwpHeadId: npwpHeadId
            }
        }
    })
}
//PENGIRIMAN RESI
service.editPengiriman = async (id, data) => {
    return http.request({
        method: http.methods.PUT,
        url: `${serviceUrl}/${id}/pengiriman`,
        data,
        config: {
            params: {
              status: status
            }
        }
    })
}
//UPDATE STATUS PESANAN TERIMA
service.editStatusPesanan = async (id, data) => {
    return http.request({
        method: http.methods.PUT,
        url: `${serviceUrl}/${id}/status`,
        data,
        config: {
            params: {
              status: status
            }
        }
    })
}
//UPDATE STATUS PESANAN SELESAI
service.editStatusPesananSelesai = async (id, data) => {
    return http.request({
        method: http.methods.PUT,
        url: `${serviceUrl}/${id}/status`,
        data,
        config: {
            params: {
              status: statusSelesai
            }
        }
    })
}
//UPDATE STATUS PESANAN BATAL
service.editStatusPesananBatal = async (id, data) => {
    return http.request({
        method: http.methods.PUT,
        url: `${serviceUrl}/${id}/status`,
        data,
        config: {
            params: {
              status: statusBatal
            }
        }
    })
}
// PRODUK
service.getProduk = async(id) => {
    return http.request({
      method: http.methods.GET,
      url: `${serviceUrl}/${id}/produk`,
    })
}
service.getAllProduk = async(queryNama) => {
    let query = {
        ...npwpHeadIdObject,
        size: 10,
        "nama.contains": queryNama,
      };
    return http.request({
        method: http.methods.GET,
        url: `/produk`,
        query
    })
}
// LAWAN TRANSAKSI
service.getLawanTransaksi = async (queryNama) => {
   let query = {
        ... npwpHeadIdObject,
        size:10,
        "kategori.equals": 1 ,
        "nama.contains": queryNama,
    }
    return http.request({
        method: http.methods.GET,
        url: `/lawan_transaksi`,
        query
    })
}
// KURIR
service.getKurir = async (queryNama) => {
    let query = {
        ... npwpHeadIdObject,
        size:10,
        "nama.contains": queryNama,
    }
    return http.request({
        method: http.methods.GET,
        url: `${serviceUrl}/kurir`,
        query
    })
}
//HISTORY
service.getProdukHistory = async(id) => {
    return http.request({
      method: http.methods.GET,
      url: `${serviceUrl}/${id}/histori`
    })
}
//GENERATE INVOICE
service.postInvoicePkp = async (id, data) => {
    return http.request({
        method: http.methods.POST,
        url: `${serviceUrl}/${id}/pkp`,
        data,
    })
}
service.postInvoiceNonPkp = async (id, data) => {
    return http.request({
        method: http.methods.POST,
        url: `${serviceUrl}/${id}/non_pkp`,
        data,
    })
}
export default service;