import React, { useEffect, useState } from "react";
import { Router, Switch, Route } from "react-router-dom";
import { sso } from "../libs/react-mpk/hocs";
import { PageWrapper, TabsWrapper } from "../libs/react-mpk/wrapper";
import { DataForm } from "../libs/react-mpk/components";
import { inputTypes } from "../libs/react-mpk/config/constant";
import t from "counterpart";
import { List, ListItem, FontIcon } from "react-md";
import {
  TableSample,
  FormSample,
  DetailsSample,
  AkunCoa,
  Produk,
  Enofa,
  LawanTransaksi,
  DetailLawanTransaksi,
  KategoriUnit,
  PenyesuaianPersediaan,
  PenyesuaianPersediaanForm,
  DashboardHomePage,
  DataAwal,
  TambahSaldoAwal,
  InfoAkun,
  StatusPkp,
  PemetaanAkunJurnal,
  JurnalUmum,
  JurnalUmumForm,
  JurnalUmumViewDetail,
  Pesanan,
  PesananForm,
  Penjualan,
  PenjualanFormPkp,
  PenjualanFormNonPkp,
  PenjualanHistory,
  PenjualanJurnalEntri,
  Pembelian,
  PembelianFormNonPkp,
  KasDanBank,
  PPh,
  PPhPeriode,
  PPhPeriodeDetail,
  TutupBuku,
  ListIdBilling,
} from "../modules";
import {
  PembelianHistory,
  PembelianJurnalEntri,
  PembelianPPN,
  PembelianPPNIdentitas,
  PembelianPPNProduk,
  PembelianPPNTransaksi,
} from "../modules/Pembelian";
import {
  KasDanBankPenerimaanNonPPN,
  KasDanBankSelengkapnyaTable,
  KasDanBankPengeluaranPPN,
  KasDanBankDetailPenjualan,
  KasDanBankDetailInputDetailAkunPenjualan,
  KasDanBankPengeluaranNonPPN,
  KasDanBankTambahEditAkun,
} from "../modules/KasDanBank";
import KasDanBankDetailKepemilikanModal from "../modules/KasDanBank/KasDanBankTambahAkunBank/KasDanBankDetailKepemilikanModal";
import KasDanBankDetailInputDetailProduk from "../modules/KasDanBank/KasDanBankDetailInputDetailProduk";
import Report from "../modules/Report/Report";
import ReportTransaksiNeracaSaldo from "../modules/Report/ReportNeracaSaldo/ReportTransaksiNeracaSaldo";
import ReportDetailListTransaksiNeracaSaldo from "../modules/Report/ReportNeracaSaldo/ReportDetailListTransaksiNeracaSaldo";
import ReportDetailLaporanNeraca from "../modules/Report/ReportNeraca/ReportDetailLaporanNeraca";
import ReportTransaksiNeraca from "../modules/Report/ReportNeraca/ReportTransaksiNeraca";
import ReportDetailListTransaksiNeraca from "../modules/Report/ReportNeraca/ReportDetailListNeraca";
import ReportTransaksiLabaRugiKomersial from "../modules/Report/ReportLabaRugiKomersial/ReportTransaksiLabaRugiKomersial";
import ReportDetailListTransaksiLabaRugiKomersial from "../modules/Report/ReportLabaRugiKomersial/ReportDetailListTransaksiLabaRugiKomersial";
import Biaya from "../modules/Biaya/Biaya";
import PPN from "../modules/PPN";
import PPNTambahFakturMasukan from "../modules/PPN/PPNTambahFakturMasukan";
import {
  BiayaNonPPNIdentitas,
  BiayaPPN,
  BiayaPPNIdentitas,
  BiayaHistory,
  BiayaDetailIdentitas,
} from "../modules/Biaya";
import BiayaJurnalEntri from "../modules/Biaya/BiayaJurnalEntri";
import PPNHistory from "../modules/PPN/PPN.history";
import PPNFmJurnalEntri from "../modules/PPN/PPNFmJurnalEntri";
import PPNFkHistory from "../modules/PPN/PPNFk.history";
import PPNFkJurnalEntri from "../modules/PPN/PPNFkJurnalEntri";
import AkunNpwpForm from "../modules/AkunNpwp/AkunNpwpForm";
import ReportLabaRugiKomersial from "../modules/Report/ReportLabaRugiKomersial/ReportLabaRugiKomersial";
import { KreditPajak } from "../modules/TutupBuku";
import KasDanBankDetailPenerimaanNonPPN from "../modules/KasDanBank/KasDanBankTambahPenerimaanUang/KasDanBankDetailPenerimaanNonPPN";
import KasDanBankDetailPengeluaranNonPPN from "../modules/KasDanBank/KasDanBankTambahPengeluaranUang/KasDanBankDetailPengeluaranNonPPN";
import AkunCoaService from "../modules/AkunCoa/AkunCoa.service";

import MigrasiDataAwal from "../modules/Migrasi/DataAwal/MigrasiDataAwal";
import DataAwalService from "../modules/Migrasi/DataAwal/DataAwal.service";
import UnitDetail from "../modules/KategoriUnit/UnitDetail";
import KategoriDetail from "../modules/KategoriUnit/KategoriDetail";
import PesananDetail from "../modules/Pesanan/PesananDetail";
import EnofaDetail from "../modules/Enofa/EnofaDetail";
import PesananHistory from "../modules/Pesanan/PesananHistory";
import PesananDetailProduk from "../modules/Pesanan/PesananDetailProduk";
import BiayaFormNonPkp from "../modules/Biaya/BiayaFormNonPkp";
import PesananInvoiceNonPkp from "../modules/Pesanan/PesananInvoiceNonPkp";
import PesananInvoicePkp from "../modules/Pesanan/PesananInvoicePkp";
import ReportNeracaSaldo from "../modules/Report/ReportNeracaSaldo/ReportNeracaSaldo";
import PenjualanDetail from "../modules/Penjualan/PenjualanDetail";
import TutupBukuDetail from "../modules/TutupBuku/TutupBukuDetail";
import KasDanBankTransferAntarBank from "../modules/KasDanBank/KasDanBankTransferAntarBank";
import LawanTransaksiNilaiTagihanPiutang from "../modules/LawanTransaksi/LawanTransaksiNilaiTagihanPiutang";
import LawanTransaksiNilaiTagihanHutang from "../modules/LawanTransaksi/LawanTransaksiNilaiTagihanHutang";
import { JurnalUmumJurnalEntri } from "../modules/JurnalUmum";
import ReportLabaRugiKomersialShowDetail from "../modules/Report/ReportLabaRugiKomersial/ReportLabaRugiKomersialShowDetail";
import KasDanBankDetailTransferAntarBank from "../modules/KasDanBank/KasDanBankTransferAntarBank/KasDanBankDetailTransferAntarBank";
import PembelianDetail from "../modules/Pembelian/PembelianDetail";
import BiayaDetail from "../modules/Biaya/BiayaDetail";
import Marketplace from "../modules/Marketplace/Marketplace";
import ReportNeracaHarta from "../modules/Report/ReportNeraca/ReportNeracaHarta";
import ReportNeracaUtangDanModal from "../modules/Report/ReportNeraca/ReportNeracaUtangDanModal";
import KasDanBankPenerimaanPPN from "../modules/KasDanBank/KasDanBankTambahPenerimaanUang/KasDanBankPenerimaanPPN";
import MarketplaceDetail from "../modules/Marketplace/MarketplaceDetail";
import utilsService from "../services/utilsService";
const MenuUtama = ({ history, match, ...props }) => {
  var npwpHeadId = match.params.npwpHeadId;
  var npwpHead = sessionStorage.getItem("npwpHead");
  var tahunSobatBuku = sessionStorage.getItem("tahunSobatBuku");
  const [isLoading, setIsLoading] = useState(true);
  const [optionTahun, setOptionTahun] = useState([]);
  const [defaultTahun, setDefaultTahun] = useState({
    tahun: new Date().getFullYear().toString(),
  });

  const [showFormMigrasiDataAwal, setShowFormMigrasiDataAwal] = useState(false);
  var dataAkun = JSON.parse(sessionStorage.getItem("dataPenggunaNpwp"));
  useEffect(() => {
    sessionStorage.setItem("npwpHeadId", match.params.npwpHeadId);
    getOptionTahun();
    generateAkun();
    getDataAwal();
  }, []);

  const getOptionTahun = async () => {
    let listYears = [];
    let currentYear = new Date().getFullYear();

    if (tahunSobatBuku) {
      setDefaultTahun({ tahun: tahunSobatBuku });
    } else {
      sessionStorage.setItem("tahunSobatBuku", currentYear);
    }

    listYears.push(currentYear.toString());
    for (let i = 1; i < 5; i++) {
      listYears.push((currentYear - i).toString());
    }
    setOptionTahun(listYears);
  };

  const generateAkun = async () => {
    try {
      let res = await AkunCoaService.generateAkun();

      let { data } = res;
    } catch (e) {}
  };

  const getDataAwal = async () => {
    try {
      const res = await DataAwalService.getDataAwal(
        sessionStorage.getItem("tahunSobatBuku")
      );
      let { data } = res;
      if (data.message === "Harus Create Data Awal") {
        setShowFormMigrasiDataAwal(true);
      }
      // else if (data.message === 'Create NPWP Baru') {
      //   setShowFormAkunNpwp(true);
      // }
      // else if (data.message === 'Update NPWP') {
      //   // setShowFormMigrasiDataAwal(true);
      // }
      // else if (data.message === 'Tidak Bisa Create NPWP') {
      //   sessionStorage.setItem('tahunSobatBuku', new Date().getFullYear())
      //   cekNpwpExist()
      // }
    } catch (err) {
      if (err.message === "Harus Create Data Awal") {
        setShowFormMigrasiDataAwal(true);
      }
    }
  };

  // const getDetailNpwp = async () => {

  //   if (!npwpHead) {
  //     try {
  //       let res = await AkunNpwpService.getOneById(npwpHeadId)
  //       let { data } = res;
  //       npwpHead = data.npwpHead.npwp;
  //       sessionStorage.setItem('npwpHead', data.npwpHead.npwp);
  //     }
  //     catch (err) {
  //       alert(`${err} di tahun ${tahunSobatBuku}`)
  //       window.location.href = `/internal/npwp`
  //     }
  //   }

  // }

  var sidebarMenuActivePKP = [
    {
      label: "",
    },
    {
      label: "Menu Utama",
      children: [
        {
          label: "Dashboard",
          index: "dasbor, dashboard",
          resourceUri: "",
          iconClassName: "mdi mdi mdi-view-dashboard",
          path: `/internal/npwp/${npwpHeadId}/dashboard`,
          childPath: [],
        },
        {
          label: "Penjualan",
          index: "penjualan",
          resourceUri: "",
          iconClassName: "mdi mdi mdi-table",
          path: `/internal/npwp/${npwpHeadId}/penjualan`,
          childPath: [],
        },
        {
          label: "Pembelian",
          index: "pembelian",
          resourceUri: "",
          iconClassName: "mdi mdi mdi-table",
          path: `/internal/npwp/${npwpHeadId}/pembelian`,
          childPath: [],
        },
        {
          label: "Biaya",
          index: "biaya",
          resourceUri: "",
          iconClassName: "mdi mdi-table",
          path: `/internal/npwp/${npwpHeadId}/biaya`,
          childPath: [],
        },
        // {
        //   label: "Pesanan",
        //   index: "pesanan",
        //   resourceUri: "",
        //   iconClassName: "mdi mdi mdi-table",
        //   path: `/internal/npwp/${npwpHeadId}/pesanan`,
        //   childPath: [],
        // },
        {
          label: "Kas & Bank",
          index: "kasDan, kasDanBank",
          resourceUri: "",
          iconClassName: "mdi mdi mdi-table",
          path: `/internal/npwp/${npwpHeadId}/kas-dan-bank`,
          childPath: [],
        },
        {
          label: "Laporan",
          index: "report",
          resourceUri: "",
          iconClassName: "mdi mdi mdi-table",
          path: `/internal/npwp/${npwpHeadId}/report/:tab`,
          childPath: [],
        },
        {
          label: "Jurnal Umum",
          index: "jurnalUmum",
          resourceUri: "",
          iconClassName: "mdi mdi-table",
          path: `/internal/npwp/${npwpHeadId}/jurnal-umum`,
          childPath: [],
        },
        {
          label: "Tutup Buku",
          index: "tutupBuku, tutup-buku",
          resourceUri: "",
          iconClassName: "mdi mdi mdi-table",
          path: `/internal/npwp/${npwpHeadId}/tutup-buku`,
          childPath: [],
        },
        {
          label: "PPh",
          index: "pph",
          resourceUri: "",
          iconClassName: "mdi mdi mdi-table",
          path: `/internal/npwp/${npwpHeadId}/pph`,
          childPath: [],
        },
        {
          label: "Lawan Transaksi",
          index: "lawanTransaksi",
          resourceUri: "",
          iconClassName: "mdi mdi-table",
          path: `/internal/npwp/${npwpHeadId}/lawan-transaksi`,
          childPath: [],
        },
        {
          label: "Produk",
          index: "produk",
          resourceUri: "",
          iconClassName: "mdi mdi-table",
          path: `/internal/npwp/${npwpHeadId}/produk`,
          childPath: [],
        },
        {
          label: "Kategori & Unit",
          index: "kategori, unit, kategoriUnit",
          resourceUri: "",
          iconClassName: "mdi mdi-table",
          path: `/internal/npwp/${npwpHeadId}/kategori-unit`,
          childPath: [],
        },
        {
          label: "Akun COA",
          index: "akun, coa, akunCoa",
          resourceUri: "",
          iconClassName: "mdi mdi-table",
          path: `/internal/npwp/${npwpHeadId}/akun-coa`,
          childPath: [],
        },
        {
          label: "Tambah Saldo Awal",
          index: "tambahSaldoAwal",
          resourceUri: "",
          iconClassName: "mdi mdi mdi-view-dashboard",
          path: `/internal/npwp/${npwpHeadId}/tambah-saldo-awal`,
          childPath: [],
        },
        {
          label: "Enofa",
          index: "enofa",
          resourceUri: "",
          iconClassName: "mdi mdi-table",
          path: `/internal/npwp/${npwpHeadId}/enofa`,
          childPath: [],
        },
        {
          label: "PPN",
          index: "ppn",
          resourceUri: "",
          iconClassName: "mdi mdi mdi-table",
          path: `/internal/npwp/${npwpHeadId}/ppn`,
          childPath: [],
        },
        // {
        //   label: "Marketplace",
        //   index: "marketplace",
        //   resourceUri: "",
        //   iconClassName: "mdi mdi mdi-cart-plus",
        //   path: `/internal/npwp/${npwpHeadId}/marketplace`,
        //   childPath: [],
        // },
        // {
        //   "label": "Detail Lawan Transaksi",
        //   "index": "detailLawanTransaksi",
        //   "resourceUri": "",
        //   "iconClassName": "mdi mdi-table",
        //   "path": `/internal/npwp/${npwpHeadId}/detail-lawan-transaksi/:id/:tab`,
        //   "childPath": []
        // },
        // {
        //   "label": "Daftar Akun Tagihan",
        //   "index": "daftarakuntagihan",
        //   "resourceUri": "",
        //   "iconClassName": "mdi mdi-table",
        //   "path": `/internal/npwp/${npwpHeadId}/akunTagihan`,
        //   "childPath": []
        // },
        // {
        //   "label": "Penyesuaian Persediaan",
        //   "index": "penyesuaianPersediaan",
        //   "resourceUri": "",
        //   "iconClassName": "mdi mdi-table",
        //   "path": `/internal/npwp/${npwpHeadId}/penyesuaian-persediaan`,
        //   "childPath": []
        // },
        // {
        //   "label": "Penyesuaian Persediaan Form",
        //   "index": "penyesuaianPersediaanForm",
        //   "resourceUri": "",
        //   "iconClassName": "mdi mdi-table",
        //   "path": `/internal/npwp/${npwpHeadId}/penyesuaian-persediaan-form`,
        //   "childPath": []
        // },
        // {
        //   "label":"Jurnal Umum Form",
        //   "index":"jurnalUmumForm",
        //   "resourceUri":"",
        //   "iconClassName":"mdi mdi-table",
        //   "path":`/internal/npwp/${npwpHeadId}/jurnal-umum-form`,
        //   "childPath":[]
        // },
        // {
        //   "label":"Jurnal Umum View Detail",
        //   "index":"jurnalUmumViewDetail",
        //   "resourceUri":"",
        //   "iconClassName":"mdi mdi-table",
        //   "path":`/internal/npwp/${npwpHeadId}/jurnal-umum-view-detail`,
        //   "childPath":[]
        // },
        // {
        //   "label":"Pesanan Form",
        //   "index":"pesananForm",
        //   "resourceUri":"",
        //   "iconClassName":"mdi mdi mdi-table",
        //   "path":`/internal/npwp/${npwpHeadId}/pesananForm`,
        //   "childPath":[]
        // },
      ],
    },
    {
      label: "Pengaturan",
      children: [
        {
          label: "Info Akun",
          index: "infoAkun",
          resourceUri: "",
          iconClassName: "mdi mdi-table",
          path: `/internal/npwp/${npwpHeadId}/info-akun`,
          childPath: [],
        },
        // {
        //   label: "Status PKP",
        //   index: "statusPkp",
        //   resourceUri: "",
        //   iconClassName: "mdi mdi mdi-view-dashboard",
        //   path: `/internal/npwp/${npwpHeadId}/status-pkp`,
        //   childPath: [],
        // },
        {
          label: "Pemetaan Akun Jurnal",
          index: "pemetaanAkunJurnal",
          resourceUri: "",
          iconClassName: "mdi mdi mdi-view-dashboard",
          path: `/internal/npwp/${npwpHeadId}/pemetaan-akun-jurnal`,
          childPath: [],
        },
      ],
    },
    // {
    //   label: "Migrasi",
    //   children: [
    //     {
    //       label: "Data Awal",
    //       index: "dataAwal",
    //       resourceUri: "",
    //       iconClassName: "mdi mdi-table",
    //       path: `/internal/npwp/${npwpHeadId}/data-awal`,
    //       childPath: [],
    //     },
    //     {
    //       label: "Tambah Saldo Awal",
    //       index: "tambahSaldoAwal",
    //       resourceUri: "",
    //       iconClassName: "mdi mdi mdi-view-dashboard",
    //       path: `/internal/npwp/${npwpHeadId}/tambah-saldo-awal`,
    //       childPath: [],
    //     },
    //   ],
    // },
  ];
  var sidebarMenuActiveNonPKP = [
    {
      label: "",
    },
    {
      label: "Menu Utama",
      children: [
        {
          label: "Dashboard",
          index: "dasbor, dashboard",
          resourceUri: "",
          iconClassName: "mdi mdi mdi-view-dashboard",
          path: `/internal/npwp/${npwpHeadId}/dashboard`,
          childPath: [],
        },
        {
          label: "Penjualan",
          index: "penjualan",
          resourceUri: "",
          iconClassName: "mdi mdi mdi-table",
          path: `/internal/npwp/${npwpHeadId}/penjualan`,
          childPath: [],
        },
        {
          label: "Pembelian",
          index: "pembelian",
          resourceUri: "",
          iconClassName: "mdi mdi mdi-table",
          path: `/internal/npwp/${npwpHeadId}/pembelian`,
          childPath: [],
        },
        {
          label: "Biaya",
          index: "biaya",
          resourceUri: "",
          iconClassName: "mdi mdi-table",
          path: `/internal/npwp/${npwpHeadId}/biaya`,
          childPath: [],
        },
        // {
        //   label: "Pesanan",
        //   index: "pesanan",
        //   resourceUri: "",
        //   iconClassName: "mdi mdi mdi-table",
        //   path: `/internal/npwp/${npwpHeadId}/pesanan`,
        //   childPath: [],
        // },
        {
          label: "Kas & Bank",
          index: "kasDan, kasDanBank",
          resourceUri: "",
          iconClassName: "mdi mdi mdi-table",
          path: `/internal/npwp/${npwpHeadId}/kas-dan-bank`,
          childPath: [],
        },
        {
          label: "Laporan",
          index: "report",
          resourceUri: "",
          iconClassName: "mdi mdi mdi-table",
          path: `/internal/npwp/${npwpHeadId}/report/:tab`,
          childPath: [],
        },
        {
          label: "Jurnal Umum",
          index: "jurnalUmum",
          resourceUri: "",
          iconClassName: "mdi mdi-table",
          path: `/internal/npwp/${npwpHeadId}/jurnal-umum`,
          childPath: [],
        },
        {
          label: "Tutup Buku",
          index: "tutupBuku, tutup-buku",
          resourceUri: "",
          iconClassName: "mdi mdi mdi-table",
          path: `/internal/npwp/${npwpHeadId}/tutup-buku`,
          childPath: [],
        },
        {
          label: "PPh",
          index: "pph",
          resourceUri: "",
          iconClassName: "mdi mdi mdi-table",
          path: `/internal/npwp/${npwpHeadId}/pph`,
          childPath: [],
        },
        {
          label: "Lawan Transaksi",
          index: "lawanTransaksi",
          resourceUri: "",
          iconClassName: "mdi mdi-table",
          path: `/internal/npwp/${npwpHeadId}/lawan-transaksi`,
          childPath: [],
        },
        {
          label: "Produk",
          index: "produk",
          resourceUri: "",
          iconClassName: "mdi mdi-table",
          path: `/internal/npwp/${npwpHeadId}/produk`,
          childPath: [],
        },
        {
          label: "Kategori & Unit",
          index: "kategori, unit, kategoriUnit",
          resourceUri: "",
          iconClassName: "mdi mdi-table",
          path: `/internal/npwp/${npwpHeadId}/kategori-unit`,
          childPath: [],
        },
        {
          label: "Akun COA",
          index: "akun, coa, akunCoa",
          resourceUri: "",
          iconClassName: "mdi mdi-table",
          path: `/internal/npwp/${npwpHeadId}/akun-coa`,
          childPath: [],
        },
        {
          label: "Tambah Saldo Awal",
          index: "tambahSaldoAwal",
          resourceUri: "",
          iconClassName: "mdi mdi mdi-view-dashboard",
          path: `/internal/npwp/${npwpHeadId}/tambah-saldo-awal`,
          childPath: [],
        },
        // {
        //   label: "Marketplace",
        //   index: "marketplace",
        //   resourceUri: "",
        //   iconClassName: "mdi mdi mdi-cart-plus",
        //   path: `/internal/npwp/${npwpHeadId}/marketplace`,
        //   childPath: [],
        // },
        // {
        //   label: "Enofa",
        //   index: "enofa",
        //   resourceUri: "",
        //   iconClassName: "mdi mdi-table",
        //   path: `/internal/npwp/${npwpHeadId}/enofa`,
        //   childPath: [],
        // },
        // {
        //   "label": "Detail Lawan Transaksi",
        //   "index": "detailLawanTransaksi",
        //   "resourceUri": "",
        //   "iconClassName": "mdi mdi-table",
        //   "path": `/internal/npwp/${npwpHeadId}/detail-lawan-transaksi/:id/:tab`,
        //   "childPath": []
        // },
        // {
        //   "label": "Daftar Akun Tagihan",
        //   "index": "daftarakuntagihan",
        //   "resourceUri": "",
        //   "iconClassName": "mdi mdi-table",
        //   "path": `/internal/npwp/${npwpHeadId}/akunTagihan`,
        //   "childPath": []
        // },
        // {
        //   "label": "Penyesuaian Persediaan",
        //   "index": "penyesuaianPersediaan",
        //   "resourceUri": "",
        //   "iconClassName": "mdi mdi-table",
        //   "path": `/internal/npwp/${npwpHeadId}/penyesuaian-persediaan`,
        //   "childPath": []
        // },
        // {
        //   "label": "Penyesuaian Persediaan Form",
        //   "index": "penyesuaianPersediaanForm",
        //   "resourceUri": "",
        //   "iconClassName": "mdi mdi-table",
        //   "path": `/internal/npwp/${npwpHeadId}/penyesuaian-persediaan-form`,
        //   "childPath": []
        // },
        // {
        //   "label":"Jurnal Umum Form",
        //   "index":"jurnalUmumForm",
        //   "resourceUri":"",
        //   "iconClassName":"mdi mdi-table",
        //   "path":`/internal/npwp/${npwpHeadId}/jurnal-umum-form`,
        //   "childPath":[]
        // },
        // {
        //   "label":"Jurnal Umum View Detail",
        //   "index":"jurnalUmumViewDetail",
        //   "resourceUri":"",
        //   "iconClassName":"mdi mdi-table",
        //   "path":`/internal/npwp/${npwpHeadId}/jurnal-umum-view-detail`,
        //   "childPath":[]
        // },
        // {
        //   "label":"Pesanan Form",
        //   "index":"pesananForm",
        //   "resourceUri":"",
        //   "iconClassName":"mdi mdi mdi-table",
        //   "path":`/internal/npwp/${npwpHeadId}/pesananForm`,
        //   "childPath":[]
        // },
        // {
        //   label: "PPN",
        //   index: "ppn",
        //   resourceUri: "",
        //   iconClassName: "mdi mdi mdi-table",
        //   path: `/internal/npwp/${npwpHeadId}/ppn`,
        //   childPath: [],
        // },
      ],
    },
    {
      label: "Pengaturan",
      children: [
        {
          label: "Info Akun",
          index: "infoAkun",
          resourceUri: "",
          iconClassName: "mdi mdi-table",
          path: `/internal/npwp/${npwpHeadId}/info-akun`,
          childPath: [],
        },
        // {
        //   label: "Status PKP",
        //   index: "statusPkp",
        //   resourceUri: "",
        //   iconClassName: "mdi mdi mdi-view-dashboard",
        //   path: `/internal/npwp/${npwpHeadId}/status-pkp`,
        //   childPath: [],
        // },
        {
          label: "Pemetaan Akun Jurnal",
          index: "pemetaanAkunJurnal",
          resourceUri: "",
          iconClassName: "mdi mdi mdi-view-dashboard",
          path: `/internal/npwp/${npwpHeadId}/pemetaan-akun-jurnal`,
          childPath: [],
        },
      ],
    },
    // {
    //   label: "Migrasi",
    //   children: [
    //     {
    //       label: "Data Awal",
    //       index: "dataAwal",
    //       resourceUri: "",
    //       iconClassName: "mdi mdi-table",
    //       path: `/internal/npwp/${npwpHeadId}/data-awal`,
    //       childPath: [],
    //     },
    //     {
    //       label: "Tambah Saldo Awal",
    //       index: "tambahSaldoAwal",
    //       resourceUri: "",
    //       iconClassName: "mdi mdi mdi-view-dashboard",
    //       path: `/internal/npwp/${npwpHeadId}/tambah-saldo-awal`,
    //       childPath: [],
    //     },
    //   ],
    // },
  ];

  return (
    <>
      <PageWrapper
        inverseTheme={true}
        style={{ background: "#D14C21" }}
        sidebarCollapsible={true}
        sidebarDefaultCollapse={false}
        sidebarHeader={
          <>
            <List
              style={{
                width: "100%",
                background: "white",
                padding: "0px",
                height: "65px",
              }}
            >
              <ListItem
                style={{ marginTop: "-3px" }}
                secondaryText={`${utilsService.parseNPWP(npwpHead)}`}
                rightAddon={
                  <FontIcon
                    onClick={() => {
                      window.location.href = `/internal/npwp`;
                      sessionStorage.removeItem("npwpHead");
                      //history.push(`/product/company/${companyId}/daftar-spt`)
                    }}
                    iconClassName="mdi mdi-close"
                  ></FontIcon>
                }
              >
                {/* {`${spt.year} - ${spt.month}`} */}
              </ListItem>
            </List>
            <DataForm
              baseId={`tahun`}
              defaultData={defaultTahun}
              onSubmit={null}
              definitions={[
                {
                  inputType: inputTypes.SELECT,
                  label: t.translate(`words.tahun`),
                  key: "tahun",
                  type: "text",
                  validation: "required",
                  options: optionTahun,
                  // disabled: disabledKapKJS
                },
              ]}
              onChange={async (formData, key, value) => {
                sessionStorage.setItem("tahunSobatBuku", value);
                window.location.reload();
              }}
            />
          </>
        }
        sidebarMenu={
          dataAkun.pkp == true ? sidebarMenuActivePKP : sidebarMenuActiveNonPKP
        }
      >
        <Router history={history}>
          <Switch>
            <Route
              path="/internal/npwp/:npwpHeadId/akun-coa"
              render={(props) => <AkunCoa {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/marketplace"
              render={(props) => <Marketplace {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/produk"
              render={(props) => <Produk {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/marketplace/:id"
              render={(props) => <MarketplaceDetail {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/kategori-unit/detail-kategori/:id"
              render={(props) => <KategoriDetail {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/kategori-unit/detail-unit/:id"
              render={(props) => <UnitDetail {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/kategori-unit"
              render={(props) => <KategoriUnit {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/enofa/:id"
              render={(props) => <EnofaDetail {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/enofa"
              render={(props) => <Enofa {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/lawan-transaksi/:id/piutang/:akunId"
              render={(props) => (
                <LawanTransaksiNilaiTagihanPiutang {...props} />
              )}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/lawan-transaksi/:id/hutang/:akunId"
              render={(props) => (
                <LawanTransaksiNilaiTagihanHutang {...props} />
              )}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/lawan-transaksi/:id/:tab"
              render={(props) => <DetailLawanTransaksi {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/lawan-transaksi"
              render={(props) => <LawanTransaksi {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/penyesuaian-persediaan"
              render={(props) => <PenyesuaianPersediaan {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/penyesuaian-persediaan/:id"
              render={(props) => <PenyesuaianPersediaanForm {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/penyesuaian-persediaan-form"
              render={(props) => <PenyesuaianPersediaanForm {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/dashboard"
              render={(props) => <DashboardHomePage {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/pembelian/:noTransaksi/jurnal-entri"
              render={(props) => <PembelianJurnalEntri {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/pembelian/:noTransaksi/non-ppn"
              render={(props) => <PembelianFormNonPkp {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/pembelian/tarikdata/:id/identitas"
              render={(props) => <PembelianPPNIdentitas {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/pembelianPPN/transaksi"
              render={(props) => <PembelianPPNTransaksi {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/pembelianPPN/produk"
              render={(props) => <PembelianPPNProduk {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/pembelian/tarikdata/:id"
              render={(props) => <PembelianPPN {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/pembelian/:id/history"
              render={(props) => <PembelianHistory {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/pembelian/:id/detail/non-ppn"
              render={(props) => <PembelianDetail {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/pembelian"
              render={(props) => <Pembelian {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/pesanan/history/:id"
              render={(props) => <PesananHistory {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/:id/invoice-pkp"
              render={(props) => <PesananInvoicePkp {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/:id/invoice-nonpkp"
              render={(props) => <PesananInvoiceNonPkp {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/pesananForm"
              render={(props) => <PesananForm {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/pesanan/:id/produk/detail"
              render={(props) => <PesananDetailProduk {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/pesanan/:id/produk/:noPesanan"
              render={(props) => <PesananDetail {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/pesanan"
              render={(props) => <Pesanan {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/penjualan/:id/history"
              render={(props) => <PenjualanHistory {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/penjualan/:noTransaksi/jurnal-entri"
              render={(props) => <PenjualanJurnalEntri {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/penjualan/:id/ppn"
              render={(props) => <PenjualanFormPkp {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/penjualan/:id/non-ppn"
              render={(props) => <PenjualanFormNonPkp {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/penjualan/:id/detail/non-ppn"
              render={(props) => <PenjualanDetail {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/penjualan"
              render={(props) => <Penjualan {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/jurnal-umum/:noTransaksi/jurnal-entri"
              render={(props) => <JurnalUmumJurnalEntri {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/jurnal-umum/:id/detail"
              render={(props) => <JurnalUmumViewDetail {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/jurnal-umum/:noTransaksi"
              render={(props) => <JurnalUmumForm {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/jurnal-umum"
              render={(props) => <JurnalUmum {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/data-awal"
              render={(props) => <DataAwal {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/tambah-saldo-awal"
              render={(props) => <TambahSaldoAwal {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/info-akun"
              render={(props) => <InfoAkun {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/status-pkp"
              render={(props) => <StatusPkp {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/pemetaan-akun-jurnal"
              render={(props) => <PemetaanAkunJurnal {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/kas-dan-bank"
              render={(props) => <KasDanBank {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/kas-dan-bank-detail/:id/selengkapnya"
              render={(props) => <KasDanBankSelengkapnyaTable {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/kas-dan-bank-pengeluaran-non-ppn"
              render={(props) => <KasDanBankPengeluaranNonPPN {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/kas-dan-bank-detail-pengeluaran-non-ppn/:id"
              render={(props) => (
                <KasDanBankDetailPengeluaranNonPPN test={1} {...props} />
              )}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/kas-dan-bank-tambah-faktur-masukan-pengeluaran"
              render={(props) => <KasDanBankPengeluaranPPN {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/kas-dan-bank-tambah-faktur-masukan-penerimaan"
              render={(props) => <KasDanBankPenerimaanPPN {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/kas-dan-bank-penerimaan-non-ppn"
              render={(props) => <KasDanBankPenerimaanNonPPN {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/kas-dan-bank-detail-penerimaan-non-ppn/:id"
              render={(props) => (
                <KasDanBankDetailPenerimaanNonPPN {...props} />
              )}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/transfer-antar-bank"
              render={(props) => <KasDanBankTransferAntarBank {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/kas-dan-bank-detail-transfer-antar-bank/:id"
              render={(props) => (
                <KasDanBankDetailTransferAntarBank {...props} />
              )}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/kas-dan-bank-tambah-edit-akun"
              render={(props) => <KasDanBankTambahEditAkun {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/kas-dan-bank-detail-kepemilikan-modal/:id"
              render={(props) => (
                <KasDanBankDetailKepemilikanModal {...props} />
              )}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/kas-dan-bank-detail-penjualan/:id"
              render={(props) => <KasDanBankDetailPenjualan {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/kas-dan-bank-detail-input-detail-produk/:id"
              render={(props) => (
                <KasDanBankDetailInputDetailProduk {...props} />
              )}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/kas-dan-bank-detail-input-detail-akun-penjualan/:id"
              render={(props) => (
                <KasDanBankDetailInputDetailAkunPenjualan {...props} />
              )}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/pph/:pphId/periode/:tahun/:periodeId"
              render={(props) => <PPhPeriodeDetail {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/listidbilling"
              render={(props) => <ListIdBilling {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/pph/:pphId/periode/:tahun"
              render={(props) => <PPhPeriode {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/report-laba-rugi/detail/:id/:bulan"
              render={(props) => (
                <ReportDetailListTransaksiLabaRugiKomersial {...props} />
              )}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/report-laba-rugi/:id"
              render={(props) => (
                <ReportTransaksiLabaRugiKomersial {...props} />
              )}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/report-laba-rugi"
              render={(props) => <ReportLabaRugiKomersial {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/pph"
              render={(props) => <PPh {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/report/detail-laporan-neraca/:kategoriAkunId/transaksi-neraca/:akunId/detail-list-transaksi-neraca/:bulan"
              render={(props) => <ReportDetailListTransaksiNeraca {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/report/detail-laporan-neraca/:kategoriAkunId/transaksi-neraca/:akunId"
              render={(props) => <ReportTransaksiNeraca {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/report/detail-laporan-neraca/:kategoriAkunId"
              render={(props) => <ReportDetailLaporanNeraca {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/report/:tab"
              render={(props) => <Report {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/report-neraca"
              render={(props) => (
                <>
                  <TabsWrapper
                    showCommandbar={true}
                    title={"Neraca"}
                    backTo={`/internal/npwp/${npwpHeadId}/tutup-buku`}
                    tabs={[
                      {
                        label: t.translate("modules.report.harta"),
                        key: "harta",
                        render: (
                          <>
                            <ReportNeracaHarta match={match} />
                          </>
                        ),
                      },
                      {
                        label: t.translate("modules.report.utangDanModal"),
                        key: "utangDanModal",
                        render: (
                          <>
                            <ReportNeracaUtangDanModal match={match} />
                          </>
                        ),
                      },
                    ]}
                    {...props}
                  />
                  ,
                </>
              )}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/report-neraca-saldo/detail/:id/:bulan"
              render={(props) => (
                <ReportDetailListTransaksiNeracaSaldo {...props} />
              )}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/report-neraca-saldo/transaksi/:id"
              render={(props) => <ReportTransaksiNeracaSaldo {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/report-neraca-saldo"
              render={(props) => <ReportNeracaSaldo {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/biaya/:noTransaksi/jurnal-entri"
              render={(props) => <BiayaJurnalEntri {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/biaya/:noTransaksi/non-ppn"
              render={(props) => <BiayaFormNonPkp {...props} />}
            />
            {/* <Route
              path='/internal/npwp/:npwpHeadId/biaya/:id/detail'
              render={props => (
                <BiayaDetailIdentitas {...props} />
              )}
            /> */}
            <Route
              path="/internal/npwp/:npwpHeadId/biaya/new/NonPPN/identitas"
              render={(props) => <BiayaNonPPNIdentitas {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/biaya/tarikdata/:id/identitas"
              render={(props) => <BiayaPPNIdentitas {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/biaya/tarikdata/:id"
              render={(props) => <BiayaPPN {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/biaya/:id/history"
              render={(props) => <BiayaHistory {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/biaya/:id/detail/non-ppn"
              render={(props) => <BiayaDetail {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/biaya"
              render={(props) => <Biaya {...props} />}
            />

            <Route
              path="/internal/npwp/:npwpHeadId/ppn/:id/masukan"
              render={(props) => <PPNTambahFakturMasukan {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/ppn/:id/history/:kategori"
              render={(props) => <PPNHistory {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/ppn-fm/:noTransaksi/jurnal-entri"
              render={(props) => <PPNFmJurnalEntri {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/ppn/:id/history-fk"
              render={(props) => <PPNFkHistory {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/ppn-fk/:noTransaksi/jurnal-entri"
              render={(props) => <PPNFkJurnalEntri {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/ppn"
              render={(props) => <PPN {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/tutup-buku/:id/kredit"
              render={(props) => <KreditPajak {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/tutup-buku/:id"
              render={(props) => <TutupBukuDetail {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/tutup-buku"
              render={(props) => <TutupBuku {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/table"
              render={(props) => <TableSample {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/form"
              render={(props) => <FormSample {...props} />}
            />
            <Route
              path="/internal/npwp/:npwpHeadId/details/:tab"
              render={(props) => <DetailsSample {...props} />}
            />
          </Switch>
        </Router>
      </PageWrapper>
      <MigrasiDataAwal
        visible={showFormMigrasiDataAwal}
        onRequestClose={() => {
          getDataAwal();
          setShowFormMigrasiDataAwal(false);
          document.getElementsByClassName("mdi-reload")[0].click();
        }}
        baru={"baru"}
        // itemId={selectedItemId}
        // isDisabled={disabledForm}
      />
    </>
  );
};

export default sso({
  basePath: "/internal/npwp/:npwpHeadId",
})(MenuUtama);
