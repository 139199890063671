import React, { useEffect, useState } from "react";
import { Text } from "react-md";
import { DataForm } from "../../libs/react-mpk/components";
import { format } from "../../libs/react-mpk/services/number.service";

const PembelianDetailList = ({
  visible = false,
  authStore,
  envStore,
  match,
  history,
  onRequestClose = () => {},
  ...props
}) => {
  let [ready, setReady] = useState(true);

  useEffect(() => {
    // console.log(props.item);
    setReady(visible);
  }, [visible]);

  return (
    ready && (
      <DataForm
        baseId="pembelian-detail-list"
        title={props.judul}
        visible={visible}
        // data={item}
        onRequestClose={onRequestClose}
        asDialog={true}
        defaultData={[]}
        definitions={[
          {
            render: (
              <>
                {props.judul === "Nilai Pembelian (Sebelum Pajak)" && (
                  <div style={{ width: 500, height: "auto" }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "50%",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 14,
                            marginBottom: -20,
                            color: "#3c6382",
                          }}
                        >
                          Kode Akun
                        </Text>
                        <Text
                          style={{
                            fontSize: 16,
                            fontWeight: "bold",
                            color: "#0c2461",
                          }}
                        >
                          {props.item?.akun.kode}
                        </Text>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "50%",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 14,
                            marginBottom: -20,
                            color: "#3c6382",
                          }}
                        >
                          Nama Akun
                        </Text>
                        <Text
                          style={{
                            fontSize: 16,
                            fontWeight: "bold",
                            color: "#0c2461",
                          }}
                        >
                          {props.item?.akun.nama}
                        </Text>
                      </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "50%",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 14,
                            marginBottom: -20,
                            color: "#3c6382",
                          }}
                        >
                          Total
                        </Text>
                        <Text
                          style={{
                            fontSize: 16,
                            fontWeight: "bold",
                            color: "#0c2461",
                          }}
                        >
                          Rp. {format(props.item?.total)}
                        </Text>
                      </div>
                    </div>
                  </div>
                )}
                {props.judul === "Pemotongan Pajak Ditanggung" && (
                  <div style={{ width: 500, height: "auto" }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "50%",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 14,
                            marginBottom: -20,
                            color: "#3c6382",
                          }}
                        >
                          Kode Akun
                        </Text>
                        <Text
                          style={{
                            fontSize: 16,
                            fontWeight: "bold",
                            color: "#0c2461",
                          }}
                        >
                          {props.item?.akun.kode}
                        </Text>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "50%",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 14,
                            marginBottom: -20,
                            color: "#3c6382",
                          }}
                        >
                          Nama Akun
                        </Text>
                        <Text
                          style={{
                            fontSize: 16,
                            fontWeight: "bold",
                            color: "#0c2461",
                          }}
                        >
                          {props.item?.akun.nama}
                        </Text>
                      </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "50%",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 14,
                            marginBottom: -20,
                            color: "#3c6382",
                          }}
                        >
                          Total
                        </Text>
                        <Text
                          style={{
                            fontSize: 16,
                            fontWeight: "bold",
                            color: "#0c2461",
                          }}
                        >
                          Rp. {format(props.item?.nominalPajakDitanggung)}
                        </Text>
                      </div>
                    </div>
                  </div>
                )}
                {props.judul === "Pemotongan Pajak" && (
                  <div style={{ width: 500, height: "auto" }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "50%",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 14,
                            marginBottom: -20,
                            color: "#3c6382",
                          }}
                        >
                          Kode Akun
                        </Text>
                        <Text
                          style={{
                            fontSize: 16,
                            fontWeight: "bold",
                            color: "#0c2461",
                          }}
                        >
                          {props.item?.akun.kode}
                        </Text>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "50%",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 14,
                            marginBottom: -20,
                            color: "#3c6382",
                          }}
                        >
                          Nama Akun
                        </Text>
                        <Text
                          style={{
                            fontSize: 16,
                            fontWeight: "bold",
                            color: "#0c2461",
                          }}
                        >
                          {props.item?.akun.nama}
                        </Text>
                      </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "50%",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 14,
                            marginBottom: -20,
                            color: "#3c6382",
                          }}
                        >
                          Total
                        </Text>
                        <Text
                          style={{
                            fontSize: 16,
                            fontWeight: "bold",
                            color: "#0c2461",
                          }}
                        >
                          Rp. {format(props.item?.nominalPajak)}
                        </Text>
                      </div>
                    </div>
                  </div>
                )}
                {props.judul === "Beban/Pendapatan Lainnya" && (
                  <div style={{ width: 500, height: "auto" }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "50%",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 14,
                            marginBottom: -20,
                            color: "#3c6382",
                          }}
                        >
                          Akun
                        </Text>
                        <Text
                          style={{
                            fontSize: 16,
                            fontWeight: "bold",
                            color: "#0c2461",
                          }}
                        >
                          {props.item?.akun?.kode +
                            " - " +
                            props.item?.akun?.nama}
                        </Text>
                      </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "50%",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 14,
                            marginBottom: -20,
                            color: "#3c6382",
                          }}
                        >
                          Kategori Akun
                        </Text>
                        <Text
                          style={{
                            fontSize: 16,
                            fontWeight: "bold",
                            color: "#0c2461",
                          }}
                        >
                          {props.item?.debitKredit == 1 ? "Debit" : "Kredit"}
                        </Text>
                      </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "50%",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 14,
                            marginBottom: -20,
                            color: "#3c6382",
                          }}
                        >
                          Total
                        </Text>
                        <Text
                          style={{
                            fontSize: 16,
                            fontWeight: "bold",
                            color: "#0c2461",
                          }}
                        >
                          Rp. {format(props.item?.total)}
                        </Text>
                      </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "50%",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 14,
                            marginBottom: -20,
                            color: "#3c6382",
                          }}
                        >
                          Keterangan
                        </Text>
                        <Text
                          style={{
                            fontSize: 16,
                            fontWeight: "bold",
                            color: "#0c2461",
                          }}
                        >
                          {props.item?.keterangan || "-"}
                        </Text>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ),
          },
        ]}
      />
    )
  );
};

export default PembelianDetailList;
