import React, { useEffect, useState } from "react";
import { DataForm, Modal } from "../../libs/react-mpk/components";
import { inject, observer } from "mobx-react";
import { Button, Text } from "react-md";
import service from "./Marketplace.service";
import { FormWrapper } from "../../libs/react-mpk/wrapper";
import { toast } from "../../libs/react-mpk/services";
import iziToast from "izitoast";

const defaultData = () => ({
  marketplace: null,
});

const ModalCredential = ({
  visible = false,
  authStore,
  envStore,
  onRequestClose = () => {},
  navigationStore,
  modalStore,
  ...props
}) => {
  let npwpHeadId = sessionStorage.getItem("npwpHeadId");
  let [ready, setReady] = useState(true);
  let [item, setItem] = useState({
    username: "",
    password: "",
  });
  useEffect(() => {
    async function initData() {
      if (visible) {
        let selectedItem = defaultData();
        if (props.itemId) {
        }
        setItem(selectedItem);
      } else {
        setItem(defaultData());
      }
      setReady(visible);
    }
    initData();
  }, [visible]);

  return (
    ready && (
      <DataForm
        baseId="marketplace-create-form"
        title={"Marketplace"}
        visible={visible}
        onRequestClose={onRequestClose}
        asDialog={true}
        defaultData={item}
        definitions={[
          {
            render: (
              <div style={{ width: "100%", marginBottom: 20 }}>
                <div style={{ textAlign: "center" }}>
                  <Text type="headline-4">{props.itemId}</Text>
                </div>
              </div>
            ),
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: "username / Email",
            key: "username",
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: "Password",
            key: "password",
            type: "password",
          },
        ]}
        onChange={async (formData, key, value) => {
          setItem(formData);
          return formData;
        }}
        submitLabel={"Simpan"}
        showBackTo={true}
        onSubmit={(data, callback) => {
          if (data.username == null || data.username == "") {
            callback('Username harus di isi', true, false);
            
          }
         else if (data.password == null || data.password == "") {
            callback('Password harus di isi', true, false);
          } else {
            try {
              let formData = {
                username: data.username,
                password: data.password,
                npwpHeadId: npwpHeadId,
              };
               service.createMarketplace(formData);
              callback("success", false);
            } catch (error) {
              callback(error, true, false);
            }
          }
        }}
      />
    )
  );
};

export default inject(
  "modalStore",
  "navigationStore"
)(observer(ModalCredential));
