import React, { useEffect, useState } from "react";
import { FormWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import { useParams } from "react-router-dom";
import service from "./KategoriUnit.service";
import { toast } from "../../libs/react-mpk/services";

const KategoriDetail = ({ props, title }) => {
  const { id } = useParams();
  const [load, setload] = useState(true);
  const [data, setdata] = useState(null);
  let npwpHeadId = sessionStorage.getItem('npwpHeadId')
  const fetchData = async () => {
    try {
      let res = await service.getOneByIdKategori(id);
      setdata(res.data);
      setload(false);
    } catch (error) {
      toast.errorRequest(error)
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return load ? (
    "loading"
  ) : (
    <FormWrapper
      baseId="produk-kategori-detail"
      showCommandbar={true}
      title={t.translate('modules.kategoriUnit.kategori.detail')}
      backTo={`/internal/npwp/${npwpHeadId}/kategori-unit`}
      defaultData={data}
      definitions={[
        {
          inputType: FormWrapper.inputTypes.INPUT,
          label: t.translate(`words.kode`),
          key: "kode",
          disabled:true
        },
        {
          inputType: FormWrapper.inputTypes.INPUT,
          label: t.translate(`words.nama`),
          key: "nama",
          disabled:true
        },
      ]}
    />
  );
};

export default KategoriDetail;
