import React, { useEffect, useState } from "react";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import { FormWrapper } from "../../../libs/react-mpk/wrapper";
import FormTanggal from "../FormTanggal";
import {
  Table,
  TableBody,
  TableHeader,
  TableRow,
  TableCell,
  Button,
  FontIcon,
  TableFooter,
} from "react-md";
import service from "./ReportNeraca.service";
import moment from "moment";
import "moment/locale/id";
import {
  format,
  formatRupiah,
} from "../../../libs/react-mpk/services/number.service";
import utilsService from "../../../services/utilsService";
import { useHistory, useLocation } from "react-router-dom";

const defaultData = () => ({
  data: [],
  namaAkun: "",
});

const ReportDetailListTransaksiNeraca = ({ navigationStore, match }) => {
  let npwpHeadId = match.params.npwpHeadId;
  let kategoriAkunId = match.params.kategoriAkunId;
  let akunId = match.params.akunId;
  let bulan = match.params.bulan;
  let history = useHistory();
  const { state: { tahun } = {} } = useLocation();
  const [data, setData] = useState(defaultData());
  const [ready, setReady] = useState(false);

  const fetchData = async () => {
    let selectedItem = defaultData();
    try {
      let query = {
        akunId: akunId,
        tahun: tahun ? tahun : sessionStorage.getItem("tahunSobatBuku"),
        bulan: bulan,
        page: 0,
        size: 9999,
      };
      let res = await service.getDetailListTransaksi(query);
      let { data } = res;

      selectedItem.saldoAwal = Math.abs(sessionStorage.getItem("saldoAwal"));
      selectedItem.saldoAkhir = Math.abs(sessionStorage.getItem("saldoAkhir"));
      selectedItem.totalDebit = Math.abs(sessionStorage.getItem("saldoAwal"));
      selectedItem.totalKredit = 0;
      selectedItem.bulanDanTahun = `${moment()
        .month(bulan - 1)
        .format("MMMM")} ${
        tahun ? tahun : sessionStorage.getItem("tahunSobatBuku")
      }`;
      data.map((d) => {
        if (d.kategori == 1) {
          d.debit = d.nominal ? d.nominal : 0;
          selectedItem.totalDebit += d.nominal;
        } else {
          d.kredit = d.nominal ? d.nominal : 0;
          selectedItem.totalKredit += d.nominal;
        }
      });

      selectedItem.data = data ? data : [];
      selectedItem.namaAkun = `${sessionStorage.getItem(
        "akunNama"
      )} - ${sessionStorage.getItem("akunKode")}`;
      setData(selectedItem);
    } catch (error) {}
    setReady(true);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {}, [data]);

  return (
    ready && (
      <FormWrapper
        baseId="detail-list-transaksi-neraca"
        showCommandbar={true}
        style={{
          maxWidth: "100%",
        }}
        title={t.translate(`modules.report.detailListTransaksiNeraca`)}
        // backTo={`/internal/npwp/${npwpHeadId}/report/detail-laporan-neraca/${kategoriAkunId}/transaksi-neraca/${akunId}`}
        defaultData={data}
        definitions={[
          {
            inputType: FormWrapper.inputTypes.INPUT,
            label: t.translate("words.namaAkun"),
            key: "namaAkun",
            disabled: true,
            style: {
              marginLeft: "10px",
              marginRight: "2%",
            },
            width: "31%",
          },
          {
            inputType: FormWrapper.inputTypes.INPUT_MASK_NUMBER,
            label: t.translate("words.saldoAwal"),
            key: "saldoAwal",
            disabled: true,
            style: {
              marginRight: "2%",
            },
            thousandSeparator: ".",
            decimalSeparator: ",",
            isNumericString: true,
            prefix: "Rp.",
            width: "31%",
          },
          {
            inputType: FormWrapper.inputTypes.INPUT,
            label: t.translate("words.bulanDanTahun"),
            key: "bulanDanTahun",
            disabled: true,
            width: "31%",
          },
          {
            render: (
              <div style={{ width: "100%" }}>
                <Table style={{ width: "100%" }}>
                  <TableHeader>
                    <TableRow>
                      <TableCell style={{ width: "5%" }}>
                        {t.translate("words.non")}
                      </TableCell>
                      <TableCell style={{ width: "5%" }}>
                        {t.translate("words.aksi")}
                      </TableCell>
                      <TableCell style={{ width: "20%" }}>
                        {t.translate("words.kategori")}
                      </TableCell>
                      <TableCell style={{ width: "20%" }}>
                        {t.translate("words.tanggalTransaksi")}
                      </TableCell>
                      <TableCell style={{ width: "20%" }}>
                        {t.translate("words.noTransaksi")}
                      </TableCell>
                      {/* <TableCell style={{ width: '20%' }}>{t.translate('words.name')}</TableCell> */}
                      <TableCell style={{ width: "15%" }}>
                        {t.translate("words.debit")}
                      </TableCell>
                      <TableCell style={{ width: "15%" }}>
                        {t.translate("words.kredit")}
                      </TableCell>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {data.data.map((d, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          {d.referensi.includes("KK") ? (
                            <>
                              <TableCell>
                                <Button
                                  theme="primary"
                                  onClick={() => {
                                    navigationStore.redirectTo(
                                      `/internal/npwp/${npwpHeadId}/kas-dan-bank-detail-pengeluaran-non-ppn/${d.referensi}`
                                    );
                                  }}
                                >
                                  <FontIcon iconClassName="mdi mdi-import" />
                                </Button>
                              </TableCell>
                              <TableCell>Pengeluaran</TableCell>
                            </>
                          ) : d.referensi.includes("PB") ? (
                            <>
                              <TableCell>
                                <Button
                                  theme="primary"
                                  onClick={() => {
                                    sessionStorage.setItem(
                                      "noInvoicePembelian",
                                      d.referensi
                                    );
                                    navigationStore.redirectTo(
                                      `/internal/npwp/${npwpHeadId}/pembelian/${d.id}/detail/non-ppn`
                                    );
                                  }}
                                >
                                  <FontIcon iconClassName="mdi mdi-import" />
                                </Button>
                              </TableCell>
                              <TableCell>Pembelian</TableCell>
                            </>
                          ) : d.referensi.includes("PJ") ? (
                            <>
                              <TableCell>
                                <Button
                                  theme="primary"
                                  onClick={() => {
                                    navigationStore.redirectTo(
                                      `/internal/npwp/${npwpHeadId}/penjualan/${d.referensi}/detail/non-ppn`
                                    );
                                  }}
                                >
                                  <FontIcon iconClassName="mdi mdi-import" />
                                </Button>
                              </TableCell>
                              <TableCell>Penjualan</TableCell>
                            </>
                          ) : d.referensi.includes("PP") ? (
                            <>
                              <TableCell>
                                <Button
                                  theme="primary"
                                  onClick={() => {
                                    navigationStore.redirectTo(
                                      `/internal/npwp/${npwpHeadId}/pesanan/${null}/produk/${
                                        d.referensi
                                      }`
                                    );
                                  }}
                                >
                                  <FontIcon iconClassName="mdi mdi-import" />
                                </Button>
                              </TableCell>
                              ,<TableCell>Pesanan</TableCell>
                            </>
                          ) : d.referensi.includes("PK") ? (
                            <>
                              <TableCell>
                                <Button
                                  theme="primary"
                                  onClick={() => {
                                    navigationStore.redirectTo(
                                      `/internal/npwp/${npwpHeadId}/kas-dan-bank-detail-penerimaan-non-ppn/${d.referensi}`
                                    );
                                  }}
                                >
                                  <FontIcon iconClassName="mdi mdi-import" />
                                </Button>
                              </TableCell>
                              <TableCell>Penerimaan</TableCell>
                            </>
                          ) : d.referensi.includes("TF") ? (
                            <>
                              <TableCell>
                                <Button
                                  theme="primary"
                                  onClick={() => {
                                    navigationStore.redirectTo(
                                      `/internal/npwp/${npwpHeadId}/kas-dan-bank-detail-transfer-antar-bank/${d.referensi}`
                                    );
                                  }}
                                >
                                  <FontIcon iconClassName="mdi mdi-import" />
                                </Button>
                              </TableCell>
                              <TableCell>Transfer</TableCell>
                            </>
                          ) : d.referensi.includes("JU") ? (
                            <>
                              <TableCell>
                                <Button
                                  theme="primary"
                                  onClick={() => {
                                    navigationStore.redirectTo(
                                      `/internal/npwp/${npwpHeadId}/jurnal-umum/${d.referensi}/jurnal-entri`
                                    );
                                  }}
                                >
                                  <FontIcon iconClassName="mdi mdi-import" />
                                </Button>
                              </TableCell>
                              <TableCell>Jurnal Umum</TableCell>
                            </>
                          ) : (
                            <>
                              <TableCell>
                                <Button
                                  theme="primary"
                                  onClick={() => {
                                    navigationStore.redirectTo(
                                      `/internal/npwp/${npwpHeadId}/biaya/${d.referensi}/detail/non-ppn`
                                    );
                                  }}
                                >
                                  <FontIcon iconClassName="mdi mdi-import" />
                                </Button>
                              </TableCell>
                              <TableCell>Biaya</TableCell>
                            </>
                          )}

                          <TableCell>
                            {moment(d.tanggalTransaksi)
                              .locale("id")
                              .format("ll")}
                          </TableCell>
                          <TableCell>
                            {utilsService.beautifyNoTransaksi(d.referensi)}
                          </TableCell>
                          {/* <TableCell>{d.akun.nama}</TableCell> */}
                          <TableCell>
                            {d.debit ? formatRupiah(d.debit) : 0}
                          </TableCell>
                          <TableCell>
                            {d.kredit ? formatRupiah(d.kredit) : 0}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell>
                        <b>{t.translate(`words.saldoAkhir`)}</b>
                      </TableCell>
                      <TableCell>
                        {
                          (Array.prototype.sum = function (prop) {
                            var total = 0;
                            for (var i = 0, _len = this.length; i < _len; i++) {
                              total += this[i][prop];
                            }
                            return total;
                          })
                        }
                        <b>
                          {
                            // 'Rp.'+format(`${Number(data.totalDebit) - Number(data.totalKredit)}`)
                            formatRupiah(`${Number(data.saldoAkhir)}`)
                          }
                        </b>
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </div>
            ),
          },
        ]}
      />
    )
  );
};

export default inject(
  "navigationStore",
  "envStore"
)(observer(ReportDetailListTransaksiNeraca));
