import React from 'react'
import { TabsWrapper } from '../../libs/react-mpk/wrapper'
import { inject, observer } from 'mobx-react'
import TableSample from '../TableSample'
import NestedForm from './NestedForm'

const DetailsSample = ({ 
  className         = '',
  navigationStore, 
  envStore
}) => {
  return (
    <TabsWrapper
      title="Details Sample"
      baseId="mod-details-sample"
      className={className}
      tabs={[
        {
          label: 'General',
          key: 'general',
          render: (
            <TableSample 
              showCommandbar={false}
            />
          )
        },
        {
          label: 'Nested Form',
          key: 'nested-form',
          render: <NestedForm/>
        }
      ]}
      onChange={(tab, callback) => {
        navigationStore.redirectTo(`/${envStore.env.applicationType}/details/${tab.key}`)
        callback()
      }}
    />
  )
}

export default inject('navigationStore', 'envStore')(observer(DetailsSample))
