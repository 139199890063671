import React, { useEffect, useState } from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import service from './TutupBuku.service'
import moment from 'moment'
import { Dialog, DialogContent, DialogFooter, DialogHeader } from '@react-md/dialog'
import { Button } from '@react-md/button'
import { useToggle } from '@react-md/utils'
import { Text } from '@react-md/typography'
import AddTutupBuku from './AddTutupBuku'
import { List, ListItem } from '@react-md/list'
import { inject, observer } from 'mobx-react'

const KreditPajak = ({
    className = '',
    history,
    envStore,
    showCommandbar = true
}) => {
    const [showForm, setShowForm] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)
    const [data, setData] = useState([])
    const [totalBruto, setTotalBruto] = useState('')
    const [totalPPh, setTotalPPh] = useState('')
    const basePath = 'tutup-buku'

    const fetchData = async (query) => {
        let res = await service.get(query)
        setData(res.data)
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <>
            <TableWrapper
                baseId="mod-table-kredit-pajak-dan-pajak-terutang"
                title="Kredit Pajak dan Pajak Terutang"
                className={className}
                backTo={`/${envStore.env.applicationType}/tutup-buku`}
                hintIconClassName="mdi mdi-information"
                hintMore={t.translate('modules.pembelian.hint.more')}
                defaultData={[]}
                defaultSortBy="date"
                defaultCollapse={true}
                useCriteria={false}
                selectable={false}
                useQueryHistory={false}
                columns={[
                    {
                        label: 'Periode',
                        searchable: true,
                        sortable: true,
                        key: 'periodeKredit',
                        render: item => (item.periodeKredit)
                    },
                    {
                        label: 'Peredara Bruto',
                        searchable: true,
                        key: 'bruto',
                        render: item => (item.bruto)
                    }, {
                        label: 'PPh Final 0,5%',
                        searchable: true,
                        key: 'pphFinal',
                        render: item => (item.pphFinal)
                    }
                ]}
                actions={[
                    new TableWrapper.action(
                        'Add',
                        'mdi mdi-plus',
                        // () => {
                        //     setSelectedItem(null)
                        //     setShowForm(true)
                        // },
                        // true
                    )
                ]}
                itemActions={[
                    new TableWrapper.action(
                        'Outstanding Bukti Potong',
                        'mdi mdi-progress-download',
                        // (item) => {
                        //     history.push(`report`)
                        // }
                    ),
                    new TableWrapper.action(
                        'Bukti Potong',
                        'mdi mdi-cloud-download-outline',
                        // (item) => {
                        //     window.location.href = `report-laba-rugi`
                        // }
                    )
                ]
                }
                tableFooter={(
                    <List>
                        <ListItem rightAddon={<b>Total PPh : {Array.prototype.sum = function (prop) {
                            var total = 0
                            for (var i = 0, _len = this.length; i < _len; i++) {
                                total += this[i][prop]
                            }
                            return total
                        }}{data.sum('pphFinal')}</b>}><b>Total Bruto : {data.sum('bruto')}</b></ListItem>
                    </List>
                )}
                onFetchData={async query => {
                    return service.get(query)
                }}
                showCommandbar={showCommandbar}
                showFilterPeriod={true}
            />
            <AddTutupBuku
                visible={showForm}
                onRequestClose={() => {
                    setShowForm(false)
                    setSelectedItem(null)
                }}
                item={selectedItem}
            />
            <Dialog
                visible={showDeleteDialog}
            >
                <DialogContent>
                    <Text>{t.translate(`words.confirmBatalkan`)}</Text>
                    <Button
                        onClick={() => {
                            setShowDeleteDialog(false)
                        }}
                        theme="primary"
                        themeType="contained"
                        style={{ marginRight: '2%' }}
                    >
                        {t.translate(`words.yes`)}
                    </Button>
                    <Button
                        onClick={() => {
                            setShowDeleteDialog(false)
                        }}
                        theme="primary"
                        themeType="contained"
                    >
                        {t.translate(`words.cancel`)}
                    </Button>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default inject('envStore')(observer(KreditPajak))