import React, { useEffect, useState } from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import Modal from '../../libs/react-mpk/components/Modal'
import t from 'counterpart'
// import service from './DaftarSptArchive.service'
import moment from 'moment'
import { toast } from '../../libs/react-mpk/services'
import { format } from '../../libs/react-mpk/services/number.service'
import { Chip, ListItem, Button } from 'react-md'
import errorService from '../../services/errorService'
import { reject } from 'lodash'
import { inject, observer } from 'mobx-react'
import iziToast from 'izitoast'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import DataForm from '../../libs/react-mpk/components/DataForm'
import utilsService from '../../services/utilsService'

const PPNFkHistory = ({
    className       = '',
    showCommandbar  = true,
    history,
    match,
    modalStore
}) => {

  const basePath = `/internal/ppn`
  const [data, setData] = useState({loading: true, content: {}})
  const baseId = 'mod-daftar-ppn-history'
  let [search, setSearch] = useState({})
  
  useEffect(() => {
      if(localStorage.getItem(`${baseId}-saved-search`)){
          setSearch(JSON.parse((localStorage.getItem(`${baseId}-saved-search`))))
        }
      async function initData(){
        try {
            // let history = await service.history(match.params.id)
            setData({
              loading: false,
              // content: history.data
              content: [
                {
                  "id":"1",
                  "nama":"Wawan"
                }
              ]
            })
        } catch(e){
          errorService(e)
        }
      }
      initData()
  }, [])

  async function hapusExc(item) {
      try {
          // var r = await service.delete(item.id)

          // if(r.data && r.data.status == 1) {
          // window.open(r.data.data.url)
          // iziToast.success({
          //     message : r.data.message
          // })
          // } else {
          // iziToast.info({
          //     message : r.data.message
          // })
          // }
      } catch(e) {
          errorService(e)
      }
  }

  async function hapus(item) {
      modalStore.showConfirm({
          title : t.translate('words.delete'),
          children : t.translate('words.hapusIni'),
          onSubmit : (callback) => {
          hapusExc(item)
          callback()
          }
      })
  }

  return (
    <>
      <TableWrapper
        baseId="ppn-faktur-keluaran-history"
        title = "Riwayat Penerimaan Pembayaran"
        className={className}
        showCommandbar={showCommandbar}
        showFilterPeriod={false}
        useFilter={false}
        defaultCollapse={true}
        useCriteria={false}
        selectable={false}
        useQueryHistory={false}
        showActionColumn = {false}
        backTo={`/internal/ppn`}
        defaultSortBy="id"
        defaultData ={[
          {
            "id":"2",
            "noTransaksi":"PJ-2021/07/0002",
            "nominal":"30200000"
          },
          {
            "id":"1",
            "noTransaksi":"PB-2021/07/0001",
            "nominal":"30200000"
          }
        ]}
        columns = {[
          {
              label : t.translate('words.noTransaksi'),
              searchable : false,
              sortable : true,
              key : 'noTransaksi',
              type : TableWrapper.dataTypes.STRING,
              render : (item) => utilsService.beautifyNoTransaksi(item.noTransaksi)
          },
          {
              label : t.translate('words.nominal'),
              searchable : true,
              sortable : true,
              key : 'nominal',
              type : TableWrapper.dataTypes.STRING,
              render : (item) => `Rp. ${format(item.nominal)}`
          }
        ]}
        // actions={actions}
        // itemActions={[
        //   new TableWrapper.action(
        //     'Edit', 
        //     'mdi mdi-pencil', 
        //     (item) => {
        //       setSelectedItem(item)
        //       setShowForm(true)
        //     }, 
        //     true
        //   ),
        //   new TableWrapper.action('Delete', 'mdi mdi-delete', () => console.log('request delete'), true)
        // ]}
        // onFetchData={ async query => {
        //   return service.get(query)
        // }}
        actions={[
          new TableWrapper.action(`${t.translate('words.kembali')}`, 'mdi mdi-arrow-left', (item) => {
            history.push(basePath)
          }, true)
        ]}
      />
    </>
  )
}

export default inject('temporaryStore', 'modalStore')(observer(PPNFkHistory))