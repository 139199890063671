import React, { useEffect, useState } from 'react';
import { inputTypes } from '../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { find } from 'lodash'
import { ExpansionList, ExpansionPanel, Table, TableBody, TableHeader, TableRow, TableCell, Card, Button, FontIcon, TableFooter } from 'react-md'
import Modal from '../../libs/react-mpk/components/Modal'

const cities = [
  {id: 1, name: 'Jakarta'},
  {id: 2, name: 'Bandung'},
  {id: 3, name: 'Semarang'},
  {id: 4, name: 'Yogyakarta'},
  {id: 5, name: 'Surabaya'}
]

const JurnalUmumViewDetail = ({envStore, ...props}) => {
	const [data, setData] = useState({loading: true, content: {}})
	const [panel1, setPanel1] = useState(true)
	const [panel2, setPanel2] = useState(true)
	const [dialogDokumen, setDialogDokumen] = useState(false)
	const [dialogData, setDialogData] = useState(false)
	const [dokumens, setDokumens] = useState([])

	function addItem(){
	  setDialogDokumen(true)
	  setDialogData({})
	}

	useEffect(() => {
        async function initData(){
			var jurnalUmumform = JSON.parse(localStorage.getItem("jurnalUmumform"))
			localStorage.setItem("jurnalUmumform", JSON.stringify({
				sptNpwp: "",
				sptMonth: "",
				sptYear: "",
				sptRev: "",
				signerName: "",
				signerNpwp: "",
				actAs: "",
				signerId: "",
				refs: [],
				address: "-",
				phone: "0",
				postalCode: "0",
				province: "-",
				city: "-",
				district: "-",
				subDistrict: "-",
				withoutIncome: true,
				bruto: "0",
				rates: "0",
				ratesValue: "0",
				...jurnalUmumform
			}))
			setData({ content: {}, loading: false })
            // if(match.params.id == 'new') {
            //     var jurnalUmumform = JSON.parse(localStorage.getItem("jurnalUmumform"))
            //     localStorage.setItem("jurnalUmumform", JSON.stringify({
            //         sptNpwp: spt.npwp,
            //         sptMonth: spt.month + "",
            //         sptYear: spt.year + "",
            //         sptRev: spt.rev + "",
            //         signerName: getSigner.name,
            //         signerNpwp: getSigner.npwp,
            //         actAs: getSigner.actAs + "",
            //         signerId: getSigner.identity,
            //         refs: [],
            //         address: "-",
            //         phone: "0",
            //         postalCode: "0",
            //         province: "-",
            //         city: "-",
            //         district: "-",
            //         subDistrict: "-",
            //         withoutIncome: true,
            //         bruto: "0",
            //         rates: "0",
            //         ratesValue: "0",
            //         ...jurnalUmumform
            //     }))
            //     setData({ content: {}, loading: false })
            // } else {
            //     // alamat, telepon,propinsi,kota,kecamatan,kelurahan,dan kode post 
            //     const res = await service.getOne(match.params.id)
            //     res.data.signerId = res.data.identity
            //     res.data.bruto = res.data.bruto + ""
            //     res.data.pph   = res.data.pph + ""
            //     res.data.rates = res.data.rates + ""
            //     res.data.ratesValue = res.data.rates + ""
            //     res.data.identityAs = res.data.identityAs + ""
            //     res.data.actAs = res.data.signAs + ""
            //     res.data.signAggree = res.data.signAggree + ""
            //     res.data.form4valid = true
            //     res.data.form3valid = true
            //     res.data.form2valid = true
            //     res.data.form1valid = true
            //     localStorage.setItem("jurnalUmumform", JSON.stringify(res.data))
            //     setData({ content: {}, loading: false })
            // }
        }
        initData()
      }, [])
	

	const activePanel1 = ()=> {
        setPanel1(true);
        setPanel2(false);
      }

      const activePanel2 = ()=> {
        setPanel1(false);
        setPanel2(true);
      }


	var onSubmit = async (data, callback) => {
		// data.form1valid = true
		// var bpform = JSON.parse(localStorage.getItem('bpform'))
		// bpform = Object.assign(bpform, data)
		// localStorage.setItem('bpform', JSON.stringify(bpform))
		props.activePanel()
	}

	const baseName = `penyesuaian-persediaan`
	const basePath = `penyesuaian-persediaan`
	var backTo = `/internal/penyesuaian-persediaan`

	var debitData = [
		{
			kode: "6.1.109",
			nama: "Beban Jasa",
			nominal: "10000000"
		}
	];

	var kreditData = [
		{
			kode: "6.1.109",
			nama: "Beban Jasa",
			nominal: "10000000"
		}
	];

	// setDokumens(dokumensData);

	return (
		
		<FormWrapper 
			loading={data.loading}
			backTo={backTo}
			showCommandbar={true}
			baseId={`mod-form-${baseName}`}
			title={t.translate(`modules.jurnalUmum.form`)}
			style={{
				maxWidth: '100%'
			}}
			// defaultData={data.content}
			definitions={[
				{ 
					render: (
						<ExpansionList style={{ width: '100%' }}>
							{/* FORM KREDIT */}
							<ExpansionPanel expanded={panel2} onClick={()=> activePanel2()} header={ t.translate('words.listDebit')}>
								{/* <BpForm3 match={match} activePanel={activePanel3} /> */}
								<FormWrapper
									submitLabel={t.translate('words.next')}
									loading={data.loading}
									showCommandbar={false}
									baseId={`mod-form-${baseName}`}
									style={{
									maxWidth: '100%'
									}}
									defaultData={data.content}
									definitions={[
									{
										render: (
										<div style={{ width: '100%' }}>
											<Table style={{ width: '100%' }}>
											<TableHeader>
												<TableRow>
													<TableCell style={{width: '10%'}}>{t.translate('words.non')}</TableCell>
													<TableCell style={{width: '25%'}}>{t.translate('words.kode')}</TableCell>
													<TableCell style={{width: '25%'}}>{t.translate('words.nama')}</TableCell>
													<TableCell style={{width: '30%'}}>{t.translate('words.nominal')}</TableCell>
													<TableCell style={{width: '10%'}}>{t.translate('words.actions')}</TableCell>
												</TableRow>
											</TableHeader>
											<TableBody>
												{debitData.map((d, index)=> {
												return (  
													<TableRow key={index}>
														<TableCell>{index + 1}</TableCell>
														<TableCell>{d.kode}</TableCell>
														<TableCell>{d.nama}</TableCell>
														<TableCell>{d.nominal}</TableCell>
														<TableCell>
															<Button onClick={()=> {}}>{t.translate("words.delete")}</Button>
														</TableCell>
													</TableRow>
												)
												})}
											</TableBody>
											<TableFooter>
												<TableRow>
													<TableCell></TableCell>
													<TableCell></TableCell>
													<TableCell><b>Total</b></TableCell>
													<TableCell><b>Rp. 10.000.000</b></TableCell>
													<TableCell></TableCell>
												</TableRow>
											</TableFooter>
											</Table>
										</div>
										)
									},
									{
										render: (
										<div style={{ margin: 10 }}></div>
										)
									},
									]}
								/>
							</ExpansionPanel>
							
							<br />

							{/* FORM PRODUK */}
							<ExpansionPanel expanded={panel2} onClick={()=> activePanel2()} header={ t.translate('words.listKredit')}>
								{/* <BpForm3 match={match} activePanel={activePanel3} /> */}
								<FormWrapper
									submitLabel={t.translate('words.next')}
									loading={data.loading}
									showCommandbar={false}
									baseId={`mod-form-${baseName}`}
									style={{
									maxWidth: '100%'
									}}
									defaultData={data.content}
									definitions={[
									{
										render: (
										<div style={{ width: '100%' }}>
											<Table style={{ width: '100%' }}>
											<TableHeader>
												<TableRow>
													<TableCell style={{width: '10%'}}>{t.translate('words.non')}</TableCell>
													<TableCell style={{width: '25%'}}>{t.translate('words.kode')}</TableCell>
													<TableCell style={{width: '25%'}}>{t.translate('words.nama')}</TableCell>
													<TableCell style={{width: '30%'}}>{t.translate('words.nominal')}</TableCell>
													<TableCell style={{width: '10%'}}>{t.translate('words.actions')}</TableCell>
												</TableRow>
											</TableHeader>
											<TableBody>
												{debitData.map((d, index)=> {
												return (  
													<TableRow key={index}>
														<TableCell>{index + 1}</TableCell>
														<TableCell>{d.kode}</TableCell>
														<TableCell>{d.nama}</TableCell>
														<TableCell>{d.nominal}</TableCell>
														<TableCell>
															<Button onClick={()=> {}}>{t.translate("words.delete")}</Button>
														</TableCell>
													</TableRow>
												)
												})}
											</TableBody>
											<TableFooter>
												<TableRow>
													<TableCell></TableCell>
													<TableCell></TableCell>
													<TableCell><b>Total</b></TableCell>
													<TableCell><b>Rp. 10.000.000</b></TableCell>
													<TableCell></TableCell>
												</TableRow>
											</TableFooter>
											</Table>
										</div>
										)
									},
									{
										render: (
										<div style={{ margin: 10 }}></div>
										)
									},
									]}
								/>
							</ExpansionPanel>
						</ExpansionList>
					)
				},
			]}
			// onSubmit={async (data, callback) => {

			// }}
		/>
	)
	

	function _closeDialog() {
		setDialogDokumen(false)
	}

	function _setDokumens(dokumens){
		setDokumens(dokumens)
	}
}

export default inject('envStore')(observer(JurnalUmumViewDetail))
