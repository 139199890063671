import { crud, http } from "../../libs/react-mpk/services";

let service = null;
let serviceUrl = "/npwp";
let tahunSobatPajak = sessionStorage.getItem("tahunSobatBuku")
  ? sessionStorage.getItem("tahunSobatBuku")
  : new Date().getFullYear();
let npwpHeadId = sessionStorage.getItem("npwpHeadId");
service = new crud("/npwp");

// service.getPage = async(query) => {
//   return http.request({
//     method: http.methods.GET,
//     url: `${serviceUrl}`,
//     query
//   })
// }
service.getKlu = async (queryNama) => {
  let query = {
    page: 0,
    size: 10,
    sort: "id,ASC",
    "nama.contains": queryNama,
  };
  return http.request({
    method: http.methods.GET,
    url: `/klu`,
    query,
  });
};
service.getOneById = async (npwpHeadId, tahunSobatBuku) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/${npwpHeadId}/tahun/${tahunSobatBuku}`,
  });
};

service.cekNpwpExist = async () => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/cek_npwp_exist/${tahunSobatPajak}`,
  });
};

service.create = async (data) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}`,
    data,
  });
};

service.migrasi = async (data) => {
  console.log("data", data);
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/migrasi`,
    data,
  });
};

service.update = async (data) => {
  return http.request({
    method: http.methods.PUT,
    url: `${serviceUrl}`,
    data,
  });
};

service.deleteById = async (id) => {
  return http.request({
    method: http.methods.DELETE,
    url: `${serviceUrl}/${id}`,
  });
};

export default service;
