import React, { useEffect, useState } from "react";
import { DataForm } from "../../libs/react-mpk/components";
import t from "counterpart";
import { FormWrapper } from "../../libs/react-mpk/wrapper";
import ErrorService from "../../services/errorService";
// import service from './Enofa.service'
import { cloneDeep } from "lodash";
import { disable } from "react-md";
import service from "./Penjualan.service";
import { toast } from "../../libs/react-mpk/services";

const pemotongan = [
  { name: "PPH21", value: "pph21" },
  { name: "PPH22", value: "pph22" },
  { name: "PPH23", value: "pph23" },
  { name: "PPH15", value: "pph15" },
  { name: "PPH4(2)", value: "pph4a2" },
];

const atasTr = [{ name: "Omset", value: "P0001" }];

const perhitunganPajak = [
  { value: "perhitunganPajakLain", name: "Nilai Lain" },
  { value: "perhitunganPajakBeli", name: "Nilai Beli" },
];

const pemotonganPajak = [
  { name: "Iya", value: true },
  { name: "Tidak", value: false },
];

const defaultData = () => ({
  alamat: "",
  areaId: null,
  dibuatOleh: "",
  diperbaharuiOleh: "",
  email: "",
  halonaCompanyId: null,
  id: null,
  isAkunGenerated: true,
  nominalHitungPajak: 0,
  jumlahTanggungan: 0,
  kabupaten: "",
  kecamatan: "",
  kelurahan: "",
  kodeRegisterEfiling: "",
  kodepos: null,
  messageRegisterEfiling: "",
  namaNpwp: "",
  noKtp: "",
  noTelepon: "",
  npwp: "",
  npwpHeadId: null,
  npwpOp: true,
  npwpOpInt: "0",
  pegawai: false,
  pekerjaBebas: false,
  pemilikUsaha: false,
  penggunaId: null,
  penggunaIdIam: "",
  penggunaUsername: "",
  pengurusanEfin: 3,
  pkp: false,
  pkpInt: "0",
  propinsi: "",
  registerEfiling: false,
  statusNikah: 2,
  tahun: null,
  tanggalDibuat: null,
  memilikiNpwp: true,
});

const PenjualanNilaiPenjualanForm = ({
  visible = false,
  authStore,
  envStore,
  match,
  history,
  onRequestClose = () => {},
  ...props
}) => {
  const { inputTypes, definition } = FormWrapper;
  let [item, setItem] = useState(null);
  let [ready, setReady] = useState(true);
  const [akun, setAkun] = useState([]);
  const [loadingAkun, setLoadingAkun] = useState(false);
  let [queryAkun, setQueryAkun] = useState("");
  const [jenisPenghasilanList, setJenisPenghasilanList] = useState([]);
  const [pasalPemotonganList, setPasalPemotonganList] = useState([]);
  const [objekPajakList, setObjekPajakList] = useState([]);


  async function pasalPemotongan() {
    let res = await service.getPasal();
    var pasal = [];
    res.data.map((d) => {
      pasal.push({
        value: d.id,
        label: d.nama,
        nama: `${d.nama}`,
      });
    });
    setPasalPemotonganList(pasal);
  }

  async function objekPajak() {
    let res = await service.getObjek();
    var objek = [];
    res.data.map((d) => {
      objek.push({
        value: d.id,
        label: `${d.kode} - ${d.nama}`,
        kode: `${d.kode}`,
        nama: `${d.nama}`,
      });
    });
    setObjekPajakList(objek);
  }

  async function jenisPenghasilan() {
    let res = await service.getJenisPenghasilan();
    var penghasilan = [];
    res.data.map((d) => {
      penghasilan.push({
        value: d.id,
        label: d.nama,
        nama: `${d.nama}`,
        kategori: `${d.kategori}`,
        kategoriWp: `${d.kategoriWp}`,
      });
    });
    setJenisPenghasilanList(penghasilan);
  }

  async function akunTransaksi(queryAkun) {
    try {
        setLoadingAkun(true)
        let res = await service.getPenjualan(queryAkun);
        var tr = [];
      res.data.map((d) => {
        tr.push({
          value: d.id,
          label: `${d.kode} - ${d.nama}`,
          kode: `${d.kode}`,
          nama: `${d.nama}`,
          kategori: {
              debitKredit: `${d.kategoriAkun.debitKredit}`,
          },
        });
    });
    setAkun(tr);
    setLoadingAkun(false)
} catch (error) {
    toast.errorRequest(error)
    setLoadingAkun(false)
    }
  }

  useEffect(() => {
    setLoadingAkun(true);
    const timeOutId = setTimeout(() => akunTransaksi(queryAkun), 500);
    return () => clearTimeout(timeOutId);
  }, [queryAkun]);

  useEffect(() => {
    if (visible) {
      let selectedItem = defaultData();
      if (props.item) {
        selectedItem = cloneDeep(props.item);
      }
      objekPajak();
      pasalPemotongan();
      jenisPenghasilan();
      akunTransaksi(queryAkun)
      setItem(selectedItem);
    } else {
        akunTransaksi("")
        setItem(defaultData());
    }
    setReady(visible);
  }, [visible]);

  return (
    ready && (
      <DataForm
        baseId="akun-npwp-form"
        title={t.translate(`modules.penjualan.inputDetailAkunPenjualan`)}
        visible={visible}
        // data={item}
        onRequestClose={onRequestClose}
        asDialog={true}
        defaultData={{
          ptsTarifPajak: 0.5,
          nominalPajak: "0",
          total: "0",
          nominalHitungPajak: "0",
          isNilaiPenjualan: true,
          isTerutangPajak: false,
          perhitunganPajak: "",
          pajakSetorSendiri: false,
          pasalPemotonganId: "PPh 4(2)",
          objekPajakId: "P001 - Omset",
          pasalPemotongan: {
            id: 1,
            nama: "PPh 4(2)",
            kode: "",
          },
          objekPajak: {
            id: 1,
            nama: "P001 - Omset",
            kode: "",
          },
        }}
        definitions={[
          {
            inputType: inputTypes.REACT_SELECT,
                    isClearable:true,
            label: "Nama Akun",
            key: "akunId",
            validation: "required",
            width: "48%",
            labelKey: "label",
            valueKey: "value",
            options: akun,
            isLoading: loadingAkun,
            onInputChange: (value) => {
              if (value) setQueryAkun(value);
            },
            style: {
              marginRight: "2%",
            },
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            label: "Nilai Penjualan",
            key: "nilaiPenjualan",
            type: "text",
            thousandSeparator: ".",
            decimalSeparator: ",",
            isNumericString: true,
            width: "48%",
            style: {
              marginTop: "2.5%",
            },
            required: true,
          },
          {
            inputType: inputTypes.DIVIDER,
            label: "Jenis Penghasilan",
            show: false,
          },
          {
            inputType: inputTypes.AUTOCOMPLETE,
            label: "Jenis Penghasilan Pajak",
            key: "jenisPenghasilanId",
            data: jenisPenghasilanList,
            labelKey: "label",
            valueKey: "label",
            required: false,
            show: false,
          },
          {
            inputType: inputTypes.CHECKBOX,
            label: "Transaksi Terutang Pajak",
            key: "isTerutangPajak",
          },
          {
            inputType: inputTypes.DIVIDER,
            label: "Pajak Disetor Sendiri",
            show: (d) => {
              return d.isTerutangPajak;
            },
          },
          {
            inputType: inputTypes.CHECKBOX,
            label:
              "Apakah Sobat Menyetorkan Sendiri Pajak Atas Penghasilan Ini",
            key: "pajakSetorSendiri",
            show: (d) => {
              return d.isTerutangPajak;
            },
          },
          {
            inputType: inputTypes.AUTOCOMPLETE,
            label: "Pemotongan Pajak",
            className: "mpk-padding-N padding-right",
            key: "pasalPemotonganId",
            data: pasalPemotonganList,
            labelKey: "label",
            valueKey: "label",
            width: "50%",
            disabled: true,
            show: (d) => {
              return d.isTerutangPajak;
            },
            required: (d) => {
              return !d.isTerutangPajak;
            },
          },
          {
            inputType: inputTypes.AUTOCOMPLETE,
            label: "Atas Transaksi",
            key: "objekPajakId",
            data: objekPajakList,
            labelKey: "label",
            valueKey: "label",
            width: "50%",
            disabled: true,
            show: (d) => {
              return d.isTerutangPajak;
            },
            required: (d) => {
              return !d.isTerutangPajak;
            },
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            className: "mpk-padding-N padding-right",
            label: "Tarif Pajak (%)",
            key: "ptsTarifPajak",
            value: 0.5,
            disabled: true,
            show: (d) => {
              return d.isTerutangPajak;
            },
          },
          {
            render: (
              <div style={{ width: "100%" }}>
                <p style={{ margin: "10px" }}>
                  <b>Perhitungan Pajak Berdasarkan</b>
                </p>
              </div>
            ),
            show: (d) => {
              return d.isTerutangPajak;
            },
            required: (d) => {
              return !d.isTerutangPajak;
            },
          },
          {
            inputType: inputTypes.CHECKBOX,
            label: "Nilai Penjualan",
            key: "isNilaiPenjualan",
            show: (d) => {
              return d.isTerutangPajak;
            },
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            label: "Nilai Lain",
            key: "nominalHitungPajak",
            type: "text",
            thousandSeparator: ".",
            decimalSeparator: ",",
            isNumericString: true,
            show: (d) => {
              return !d.isNilaiPenjualan && d.isTerutangPajak;
            },
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            label: "Nominal Pajak",
            className: "mpk-padding-N padding-right",
            key: "nominalPajak",
            thousandSeparator: ".",
            decimalSeparator: ",",
            isNumericString: true,
            width: "50%",
            disabled: true,
            show: (d) => {
              return d.isTerutangPajak;
            },
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            label: "Total",
            key: "total",
            width: "50%",
            thousandSeparator: ".",
            decimalSeparator: ",",
            isNumericString: true,
            disabled: true,
          },
          {
            render: (
              <div style={{ width: "100%" }}>
                <p style={{ margin: "10px" }}>
                  <b>Informasi Tambahan</b>
                </p>
              </div>
            ),
          },
          {
            inputType: inputTypes.TEXTAREA,
            label: "Keterangan",
            key: "keterangan",
          },
        ]}
        onChange={async (formData, key, value) => {
          if (key === "pasalPemotonganId") {
            pasalPemotonganList.map((d) => {
              if (d.label === value) {
                formData["pasalPemotongan"] = {
                  id: d.value,
                  nama: d.label,
                };
              }
            });
          }

          if (key === "objekPajakId") {
            objekPajakList.map((d) => {
              if (d.label === value) {
                formData["objekPajak"] = {
                  id: d.value,
                  kode: d.kode,
                  nama: d.nama,
                };
              }
            });
          }

          if (key === "akunId") {
            akun.map((d) => {
              if (d.value === value) {
                formData["akun"] = {
                  id: d.value,
                  nama: d.nama,
                  kode: d.kode,
                  kategoriAkunDebitKredit: d.kategori.debitKredit,
                };
              }
            });
          }
          if (key === "jenisPenghasilanId") {
            jenisPenghasilanList.map((d) => {
              if (d.label === value) {
                formData["jenisPenghasilan"] = {
                  id: d.value,
                  nama: d.nama,
                  kategori: d.kategori,
                  kategoriWp: d.kategoriWp,
                };
              }
            });
          }
          if (key === "nilaiPenjualan") {
            formData.total = formData.nilaiPenjualan;
          }
          if (formData.isTerutangPajak) {
            if (formData.isNilaiPenjualan == false) {
              formData.nominalPajak =
                formData.nominalHitungPajak * (formData.ptsTarifPajak / 100);
              formData.total = formData.nilaiPenjualan - formData.nominalPajak;
              if (formData.pajakSetorSendiri) {
                formData.total = formData.nilaiPenjualan;
              }
            } else if (formData.isNilaiPenjualan == true) {
              formData.nominalHitungPajak = formData.nilaiPenjualan;
              formData.nominalPajak =
                formData.nilaiPenjualan * (formData.ptsTarifPajak / 100);
              formData.total = formData.nilaiPenjualan - formData.nominalPajak;
              if (formData.pajakSetorSendiri) {
                formData.total = formData.nilaiPenjualan;
              }
            }
          } else if (!formData.isTerutangPajak) {
            formData.nominalPajak = "0";
            formData.total = formData.nilaiPenjualan;
          }
          // return value
        }}
        // onBeforeChange={(key, value) => {
        // 	console.log(key, value)
        // 	return value
        // }}
        onSubmit={(data, callback) => {
          localStorage.setItem("nilaiPenjualanForm", JSON.stringify(data));
          callback("success", false);
        }}
      />
    )
  );
};

export default PenjualanNilaiPenjualanForm;
