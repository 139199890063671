import React, { useEffect, useState } from "react";
import { DataForm } from "../../libs/react-mpk/components";
import t from "counterpart";
import { FormWrapper, TableWrapper } from "../../libs/react-mpk/wrapper";
import service from "./PPh.service";
import { cloneDeep } from "lodash";
import { disable } from "react-md";
import { formatTypes, inputTypes } from "../../libs/react-mpk/config/constant";

const OmzetPPhForm = ({
  visible = false,
  authStore,
  envStore,
  match,
  history,
  onRequestClose = () => {},
  ...props
}) => {
  let [item, setItem] = useState({
    omzetSudahDihitung: 0,
  });

  let [dataSubmit, setDataSubmit] = useState({
    npwpHeadId: null,
    tahun: new Date().getFullYear(),
    omzetSudahDihitung: 0,
  });
  let [ready, setReady] = useState(true);

  useEffect(() => {
    if (visible) {
      let selectedItem = item;
      selectedItem.omzetSudahDihitung = props.item
      setItem(selectedItem);
    } else {
      setItem(item);
    }
    setReady(visible);
  }, [visible]);

  return (
    ready && (
      <DataForm
        baseId="omzet-pph-form"
        title={"Form Omzet PPh"}
        visible={visible}
        // data={item}
        onRequestClose={onRequestClose}
        asDialog={true}
        defaultData={item}
        definitions={[
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            label: "Omzet",
            thousandSeparator: ".",
            decimalSeparator: ",",
            key: "omzetSudahDihitung",
            validation: "required",
          },
        ]}
        onChange={async (formData, key, value) => {}}
        onSubmit={async (data, callback) => {
          let reqData = dataSubmit;
          reqData.npwpHeadId = sessionStorage.getItem("npwpHeadId");
          reqData.tahun = sessionStorage.getItem("tahunSobatBuku");
          reqData.omzetSudahDihitung = data.omzetSudahDihitung;
          try {
            const res = await service.createOmzetPPh(reqData);
            callback("success", false);
            TableWrapper.reload("mod-form-detail");
          } catch (error) {
            callback(error, true, false);
          }
        }}
      />
    )
  );
};

export default OmzetPPhForm;
