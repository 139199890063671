import React, {useState} from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import moment from 'moment'
import PenyesuaianPersediaanForm from './PenyesuaianPersediaanForm'
import { format as formatNumber } from './../../libs/react-mpk/services/number.service'

const PenyesuaianPersediaan = ({
  className       = '',
  history,
  showCommandbar  = true
}) => {
  const [showForm, setShowForm] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)

  const basePath = `penyesuaian-persediaan`  

  var actions = [
    new TableWrapper.action(
      `${t.translate('words.add')}`,
      'mdi mdi-plus',
      () => {
        history.push(`${basePath}/new`)
      },
      true)
  ]
  
  return (
    <>
      <TableWrapper
        baseId="mod-table-sample"
        title={t.translate('modules.penyesuaianPersediaan.title')}
        className={className}
        hintMessage={t.translate('modules.penyesuaianPersediaan.hint.message')}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate('modules.penyesuaianPersediaan.hint.more')}
        defaultData={[
          {
            "date": "1627274906",
            "kode": "PS-00001/01/2020",
            "nama": "Persediaan Awal",
            "nominal": "50000000"
          },
          {
            "date": "1627274906",
            "kode": "PS-00002/01/2020",
            "nama": "Pembelian",
            "nominal": "50000000"
          },
          {
            "date": "1627274906",
            "kode": "PS-00003/01/2020",
            "nama": "Pembelian",
            "nominal": "50000000"
          },
        ]}
        defaultSortBy="date"
        defaultCollapse={true}
        useCriteria={false}
        selectable={false}
        useQueryHistory={false}
        // extraFilter={<div>Test</div>}
        columns={[
          {
            label: 'Tanggal Penyesuaian',
            type: TableWrapper.dataTypes.DATE,
            render: item => (moment(item.date).locale('id').format('ll'))
          },
          {
            label: 'Kode',
            searchable: true,
            sortable: false,
            key:'kode',
            render: item => (item.kode)
          },
          {
            label: 'Nama',
            searchable: true,
            sortable: false,
            key:'nama',
            render: item => (item.nama)
          },
          {
            label: 'Nominal',
            searchable: true,
            sortable: false,
            key:'nominal',
            render: item => ("Rp. "+formatNumber(item.nominal))
          }
        ]}
        actions={actions}
        itemActions={[
          new TableWrapper.action(
            'Edit', 
            'mdi mdi-pencil', 
            (item) => {
              setSelectedItem(item)
              setShowForm(true)
            }, 
            true
          ),
          new TableWrapper.action('Delete', 'mdi mdi-delete', () => {}, true)
        ]}
        // onFetchData={ async query => {
        //   return service.get(query)
        // }}
        showCommandbar={showCommandbar}
        showFilterPeriod={false}
        useFilter={false}
      />
    </>
  )
}

export default PenyesuaianPersediaan
