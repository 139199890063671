import React, { useState } from "react";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import moment from "moment";
import iziToast from "izitoast";
import { inject, observer } from "mobx-react";
import service from "./AkunCoa.service";
import AkunCoaForm from "./AkunCoaForm";
import { toast } from "../../libs/react-mpk/services";

const AkunCoa = ({
  className = "",
  showCommandbar = true,
  modalStore,
  match,
}) => {
  var npwpHeadId = match.params.npwpHeadId;
  const [showForm, setShowForm] = useState(false);
  const [disabledForm, setDisabledForm] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  let [search, setSearch] = useState({});
  const baseId = "akun-coa";
  const basePath = `/internal/npwp/${npwpHeadId}/akun-coa`;

  var actions = [
    new TableWrapper.action(
      `${t.translate("words.add")}`,
      "mdi mdi-plus",
      () => {
        setDisabledForm(false);
        setSelectedItemId(null);
        setShowForm(true);
      },
      true
    ),
  ];

  var itemActions = [
    new TableWrapper.action(
      "Edit",
      "mdi mdi-pencil",
      (item) => {
        setSelectedItemId(item.id);
        setShowForm(true);
      },
      true
    ),
    new TableWrapper.action(
      "Delete",
      "mdi mdi-delete",
      (item) => {
        hapus(item);
      },
      true
    ),
  ];

  async function hapusExc(item) {
    try {
      var r = await service.deleteById(item.id);

      if (r.data.message == "sukses") {
        iziToast.success({
          message: r.data.message,
        });
      } else {
        iziToast.info({
          message: r.data.message,
        });
      }
      document.getElementsByClassName("mdi-reload")[0].click();
    } catch (e) {
      toast.errorRequest(e);
    }
  }

  async function hapus(item) {
    modalStore.showConfirm({
      title: t.translate("words.delete"),
      children: t.translate("words.confirmHapus"),
      onSubmit: (callback) => {
        hapusExc(item);
        callback();
      },
    });
  }

  return (
    <>
      <TableWrapper
        baseId={baseId}
        title={t.translate("modules.akunCoa.title")}
        className={className}
        hintMessage={t.translate("modules.akunCoa.hint.message")}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate("modules.akunCoa.hint.more")}
        defaultData={[]}
        defaultSort="ASC"
        defaultSortBy="kode"
        showFilter={false}
        showActionColumn={true}
        useFilter={true}
        defaultCollapse={true}
        useCriteria={true}
        selectable={false}
        useQueryHistory={false}
        onFetchData={(query) => {
          return new Promise(async (resolve, reject) => {
            try {
              query = {
                ...query,
              };
              let getPage = await service.getPage(query);
              getPage.headers["x-pagination-count"] =
                getPage.headers["x-total-count"];
              resolve(getPage);
            } catch (e) {
              resolve();
              // ErrorService(e)
            }
          });
        }}
        columns={[
          {
            label: "Kode Akun",
            searchable: true,
            sortable: false,
            key: "kode",
            render: (item) => item.kode,
          },
          {
            label: "Nama Akun",
            searchable: true,
            sortable: false,
            key: "nama",
            render: (item) => item.nama,
          },
          {
            label: "Tanggal Dibuat",
            type: TableWrapper.dataTypes.DATE,
            render: (item) => moment(item.tanggalDibuat).locale('id').format("ll"),
          },
        ]}
        actions={actions}
        itemActions={itemActions}
        showCommandbar={showCommandbar}
        showFilterPeriod={false}
      />
      <AkunCoaForm
        visible={showForm}
        onRequestClose={() => {
          setShowForm(false);
          setSelectedItemId(null);
          document.getElementsByClassName("mdi-reload")[0].click();
        }}
        itemId={selectedItemId}
      />
    </>
  );
};

export default inject("modalStore")(observer(AkunCoa));
