import React, { useEffect, useState } from 'react';
import { DataForm } from '../../libs/react-mpk/components';
import t from 'counterpart'
import { FormWrapper, TableWrapper } from '../../libs/react-mpk/wrapper'
import service from './Enofa.service'
import { cloneDeep } from 'lodash'
import { disable } from 'react-md';
import moment from 'moment';

const EnofaForm = ({
	visible = false,
	authStore,
	envStore,
	match,
	item = null,
	history,
	onRequestClose = () => { },
	...props
}) => {
	const { inputTypes, definition } = FormWrapper
	const [data, setData] = useState({ loading: true, content: {} })
	const [organization, setOrganization] = useState([])
	// let [item, setItem] = useState(null)
	let [ready, setReady] = useState(true)

	// VALIDASI
	let [disabledNpwp, setDisabledNpwp] = useState(false)
	const [inputValidasiNpwp, setInputValidasiNpwp] = useState('##.###.###.#-###.###')
	let [disabledNik, setDisabledNik] = useState(false)
	const [inputValidasiNik, setInputValidasiNik] = useState('################')
	let [disabledTanggalPkp, setDisabledTanggalPkp] = useState(false)


	return ready && (
		<DataForm
			baseId="akun-npwp-form"
			title={t.translate(`modules.enofa.form`)}
			visible={visible}
			// data={item}
			onRequestClose={onRequestClose}
			asDialog={true}
			defaultData={item || {
				nsfpDari: '',
				nsfpSampai: '',
				tanggalNsfp: ''
			}}
			definitions={[
				{
					inputType: DataForm.inputTypes.INPUT_MASK_NUMBER,
					label: t.translate(`words.dimulai`),
					key: 'nsfpDari',
					type: 'text',
					mask: '###-##.########',
					maskChar: '_',
					maskValue: 'string'
					// hidden:true,
				},
				{
					inputType: DataForm.inputTypes.INPUT_MASK_NUMBER,
					label: t.translate(`words.berakhir`),
					key: 'nsfpSampai',
					type: 'text',
					mask: '###-##.########',
					maskChar: '_',
					maskValue: 'string'
					// hidden:true,
				},
				{
					inputType: DataForm.inputTypes.DATEPICKER,
					label: t.translate(`words.tanggalFaktur`),
					key: 'tanggalNsfp',
					width: '100%',
					type: 'date',
					validation: 'required'
				},
			]}
			onChange={async (formData, key, value) => {
				//VALIDASI NPWP OP
				if (key == "npwpOpInt") {
					if (value == "0")
						setDisabledNik(false)
					else
						setDisabledNik(true)
				}
				//VALIDASI MEMILIKI NPWP
				if (key == "memilikiNpwp") {
					if (value)
						setDisabledNpwp(false)
					else
						setDisabledNpwp(true)
				}
				//VALIDASI TANGGAL PKP
				if (key == "pkp") {
					if (value == "0")
						setDisabledTanggalPkp(false)
					else
						setDisabledTanggalPkp(true)
				}
			}}
			// onBeforeChange={(key, value) => {
			// 	console.log(key, value)
			// 	return value
			// }}
			onSubmit={async (data, callback) => {
				// console.log(data, 'data');
				try {
					data.tanggalNsfp = moment(data.tanggalNsfp).format('yyyy-MM-DD hh:mm:ss')
					data.npwpHead = { id: sessionStorage.getItem('npwpHeadId') }
					let res = !item ? await service.post(data) : await service.put(item.id, data)
					TableWrapper.updateDataItem('efona-table', { id: res.data.id }, res.data)
					callback('success', false)
				} catch (error) {
					callback(error, true, false)
				}
			}}
		/>
	)
}

export default EnofaForm