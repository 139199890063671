import React, { useEffect, useState } from "react";
import { FormWrapper } from "../../../libs/react-mpk/wrapper";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import { inputTypes } from "../../../libs/react-mpk/config/constant";
import service from "../KasDanBank.service";
import { useParams } from "react-router-dom";
import { toast } from "../../../libs/react-mpk/services";
import { Button } from "react-md";
import PreviewFile from "../../PreviewFile/PreviewFile";

const defaultData = () => ({
  noTransaksi: null,
  tanggalTransaksi: "",
  akunFrom: {
    id: null,
  },
  akunTo: {
    id: null,
  },
  nominalTransfer: 0,
  keterangan: "",
  fileLampiran: "",
});

const KasDanBankDetailTransferAntarBank = ({ envStore }) => {
  const [dataDetail, setDataDetail] = useState({});
  const { id } = useParams();
  const npwpHeadId = sessionStorage.getItem("npwpHeadId");
  const idAkun = sessionStorage.getItem("idAkun");
  const [showDialog, setDialog] = useState(false);
  let [file, setFile] = useState(null);
  let [type, setType] = useState(null);
  async function initData() {
    let selectedItem = defaultData();
    try {
      let res = await service.getDetailTransferbyNoTransaksi(id);
      let { data } = res;

      selectedItem.id = data.id;
      selectedItem.akunFrom = `${data.akunFrom.kode} - ${data.akunFrom.nama}`;
      selectedItem.akunTo = `${data.akunTo.kode} - ${data.akunTo.nama}`;
      selectedItem.fileLampiran = data.fileLampiran;
      selectedItem.keterangan = data.keterangan;
      selectedItem.noTransaksi = data.noTransaksi;
      selectedItem.nominalTransfer = data.nominalTransfer;
      selectedItem.tanggalTransaksi = data.tanggalTransaksi;
      setDataDetail(selectedItem);
    } catch (error) {}
  }

  useEffect(() => {
    initData();
  }, []);
  return (
    <>
      <FormWrapper
        showCommandbar={true}
        baseId="mod-kas-dan-bank-detail-transfer-antar-bank"
        title={t.translate(`modules.KasDanBank.detailTransferAntarBank`)}
        style={{
          maxWidth: "100%",
        }}
        submitLabel={t.translate(`words.submit`)}
        defaultData={dataDetail}
        definitions={[
          {
            inputType: inputTypes.INPUT,
            label: t.translate(`words.noTransaksi`),
            key: "noTransaksi",
            style: {
              marginRight: "2%",
            },
            disabled: true,
          },
          {
            render: (
              <div style={{ width: "100%" }}>
                <p style={{ margin: "10px" }}>
                  <b>{t.translate(`words.lawanTransaksiDanSumberDana`)}</b>
                </p>
              </div>
            ),
          },

          {
            inputType: inputTypes.DATEPICKER,
            label: t.translate(`words.tglTransaksi`),
            key: "tanggalTransaksi",
            width: "49%",
            style: {
              marginRight: "2%",
            },
            disabled: true,
          },
          {
            inputType: inputTypes.INPUT,
            label: t.translate(`words.transferDari`),
            key: "akunFrom",
            type: "text",
            width: "49%",
            disabled: true,
          },
          {
            inputType: inputTypes.INPUT,
            label: t.translate(`words.transferke`),
            key: "akunTo",
            type: "text",
            width: "49%",
            style: {
              marginRight: "2%",
            },
            disabled: true,
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            label: t.translate(`words.nominalTagihan`),
            key: "nominalTransfer",
            type: "text",
            width: "49%",
            thousandSeparator: ".",
            decimalSeparator: ",",
            isNumericString: true,
            disabled: true,
          },
          {
            render: (
              <div style={{ width: "100%" }}>
                <p style={{ margin: "10px" }}>
                  <b>{t.translate(`words.informasiTambahan`)}</b>
                </p>
              </div>
            ),
            show: () => {
              return (
                dataDetail.keterangan != null || dataDetail.fileLampiran != null
              );
            },
          },
          {
            inputType: inputTypes.INPUT,
            label: t.translate(`words.keterangan`),
            key: "keterangan",
            type: "text",
            style: {
              marginRight: "2%",
            },
            disabled: true,
            show: () => {
              return dataDetail.keterangan != null;
            },
          },
          {
            inputType: inputTypes.INPUT,
            label: t.translate(`words.namaFile`),
            key: "fileLampiran",
            type: "text",
            width: "70%",
            disabled: true,
            show: () => {
              return dataDetail.fileLampiran != null;
            },
          },
          {
            render: (
              <Button
                theme="primary"
                themeType="contained"
                style={{ margin: 10 }}
                onClick={async () => {
                  try {
                    await service
                      .getLampiranTransfer(dataDetail.id)
                      .then((response) => {
                        const type = response.headers["content-type"];
                        let blob = new Blob([response.data], {
                            type: type,
                          }),
                          datas = window.URL.createObjectURL(blob);
                        setFile(datas);
                      });
                    if (dataDetail.fileLampiran.includes(".pdf")) {
                      setType("pdf");
                    } else {
                      setType("image");
                    }
                    setDialog(true);
                  } catch (error) {
                    toast.errorRequest(error);
                  }
                }}
              >
                Lihat File
              </Button>
            ),
            show: () => {
              return dataDetail.fileLampiran != null;
            },
          },
        ]}
      />
      <PreviewFile
        visible={showDialog}
        onRequestClose={() => {
          setDialog(false);
        }}
        onClickDownload={() => {
          service.DownloadLampiranTransfer(dataDetail.id);
        }}
        file={file}
        type={type}
      />
    </>
  );
};

export default inject("envStore")(observer(KasDanBankDetailTransferAntarBank));
