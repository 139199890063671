import React, { useEffect, useState } from "react";
import { inputTypes } from "../../libs/react-mpk/config/constant";
import { FormWrapper, TableWrapper } from "../../libs/react-mpk/wrapper";
import { Hint } from "../../libs/react-mpk/components";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import { find } from "lodash";
import {
  ExpansionList,
  ExpansionPanel,
  Table,
  TableBody,
  TableHeader,
  TableRow,
  TableCell,
  Card,
  Button,
  FontIcon,
  TableFooter,
  Text,
  Divider,
  TextContainer,
  TableContainer,
} from "react-md";
import Modal from "../../libs/react-mpk/components/Modal";
import PenjualanProdukForm from "./PenjualanProdukForm";
import PenjualanBebanForm from "./PenjualanBebanForm";
import PenjualanNilaiPenjualanForm from "./PenjualanNilaiPenjualanForm";
import service from "./Penjualan.service";
import moment from "moment";
import 'moment/locale/id';
import servicePPN from "../PPN/PPN.service";
import LawanTransaksiForm from "../LawanTransaksi/LawanTransaksiForm";
import { format } from "../../libs/react-mpk/services/number.service";
import { toast } from "../../libs/react-mpk/services";

const KATEGORI_TRANSAKSI_NORMAL = "1";
const STAT_BELUM_BAYAR = 1;
const STAT_LUNAS = 3;
const DEBIT = "1";
const KREDIT = "2";
const defaultData = () => ({
  lawanTransaksiId: "",
  lawanTransaksiNama: "",
  lawanTransaksiNik: "",
  lawanTransaksiNpwp: "",
  lawanTransaksiAlamat: "",
  lawanTransaksiNamaPic: "",
  lawanTransaksiEmailPic: "",
  lawanTransaksiNoTeleponPic: "",
  lawanTransaksiAlamatPic: "",
  lawanTransaksi: {
    id: null,
  },
  kategoriTransaksi: KATEGORI_TRANSAKSI_NORMAL,
  tanggalInvoice: "",
  statusPembayaran: STAT_BELUM_BAYAR,
  akunPiutangId: "",
  akunPiutang: {
    id: null,
  },
  periodeJatuhTempo: "",
  tanggalJatuhTempo: "",
  subtotal: 0,
  subtotalAkun: 0,
  total: 0,
  totalPajakSetor: 0,
  totalBeban: 0,
  totalPenjualan: 0,
  fileLampiran: null,
  lampiran: {
    fileLampiranInstance: null,
    fileLampiran: null,
    fileLampiranAlias: null,
  },
  keterangan: null,
  jenisFakturKode: "0",
  jenisFakturKeterangan: "1 - Faktur Pajak Normal",
});
const PenjualanFormPkp = ({
  envStore,
  modalStore,
  match,
  navigationStore,
  ...props
}) => {
  const [data, setData] = useState({ loading: true, content: {} });
  const [panel1, setPanel1] = useState(false);
  const [panel2, setPanel2] = useState(false);
  const [panel3, setPanel3] = useState(false);
  const [dialogPenjualanBebanForm, setDialogPenjualanBebanForm] =
    useState(false);
  const [dialogPenjualanProdukForm, setDialogPenjualanProdukForm] =
    useState(false);
  const [dialogSetorSendiriForm, setDialogSetorSendiriForm] = useState(false);
  const [dialogData, setDialogData] = useState(false);
  const [dokumens, setDokumens] = useState([]);

  const [listAkunPiutang, setListAkunPiutang] = useState([]);
  const [listTransaksi, setListTransaksi] = useState([]);
  const [listKeterangan, setListKeterangan] = useState([]);
  const [listAkunKasBank, setListAkunKasBank] = useState([]);
  const [showForm, setShowForm] = useState(false);

  const [lawanTr, setLawanTr] = useState([]);
  const [dataTr, setDataTr] = useState([]);

  let [item, setItem] = useState(null);
  let nsfp = sessionStorage.getItem("nsfp");
  const [statusLunas, setStatusLunas] = useState(false);

  // VALIDASI
  const [inputValidasiNpwp, setInputValidasiNpwp] = useState(
    "##.###.###.#-###.###"
  );
  const [inputValidasiNik, setInputValidasiNik] = useState("################");

  const [disabledField, setDisabledField] = useState(true);

  const NILAI_PENJUALAN = false;
  const NILAI_PENJUALAN_SETOR_SENDIRI = true;

  const [dataBeban, setDataBeban] = useState([]);
  const [dataPenjualan, setDataPenjualan] = useState([]);
  const [dataSetorSendiri, setDataSetorSendiri] = useState([]);
  const [disabled, setDisabled] = useState(false);

  const [deleteAkunBeban, setDeleteAkunBeban] = useState([]);
  const [deleteAkunPenjualan, setDeleteAkunPenjualan] = useState([]);
  const [deleteProduk, setDeleteProduk] = useState([]);

  const [dataSubmit, setDataSubmit] = useState({
    fk: {
      kategoriTransaksi: KATEGORI_TRANSAKSI_NORMAL,
      tanggalInvoice: "",
      periodeJatuhTempo: "",
      tanggalJatuhTempo: "",
      totalPenjualan: 0,
      subtotal: 0,
      subtotalAkun: 0,
      totalPajakSetor: 0,
      totalBeban: 0,
      total: 0,
      pembayaran: {
        status: "1",
        akun: null,
      },
      lawanTransaksi: {
        id: null,
      },
      npwpHead: {
        id: sessionStorage.getItem("npwpHeadId"),
      },
      akunPiutang: {
        id: null,
      },
      fileLampiranInstance: null,
      fileLampiran: null,
      fileLampiranAlias: null,
	  keterangan: null,
    keteranganId:null,
      fp: {
        transaksi: {
          id: null,
        },
        keterangan: {
          id: null,
        },
        jenisFakturKode: "0",
        jenisFakturKeterangan: "1 - Faktur Pajak Normal",
        tanggalFp: "",
        masa: 0,
        tahun: 0,
      },
      isPpn: false,
    },
    kategoriAkunDebitKredit: "",
    fkProdukList: [],
    fkAkunPenjualanList: [],
    akunBebanList: [],
    fkProdukHapusList: [],
    fkAkunPenjualanHapusList: [],
    akunBebanHapusList: [],
  });
  const statusBayar = [
    { value: STAT_LUNAS, name: "Lunas" },
    { value: STAT_BELUM_BAYAR, name: "Belum Dibayarkan" },
  ];

  async function lawanTransaksi() {
    setTimeout(async () => {
      let res = await service.getLawanTr();
      var tr = [];
      res.data.map((d) => {
        tr.push({
          value: d.id,
          label: d.nama,
        });
      });
      setLawanTr(tr);
      setDataTr(res.data);
    }, 500);
  }

  async function transaksiFakturPajak() {
    let res = await service.getTransaksi();
    var tr = [];
    res.data.map((d) => {
      tr.push({
        value: d.id,
        label: `${d.keterangan}`,
        kode: d.kode,
      });
    });
    setListTransaksi(tr);
  }

  async function keteranganFakturPajak() {
    let res = await service.getKeterangan();
    var ket = [];
    res.data.map((d) => {
      ket.push({
        value: d.id,
        label: `${d.keterangan}`,
      });
    });
    setListKeterangan(ket);
  }

  async function akunPiutang() {
    let res = await service.getPiutang();
    var piutang = [];
    res.data.map((d) => {
      piutang.push({
        value: d.id,
        label: d.nama,
      });
    });
    setListAkunPiutang(piutang);
  }

  async function akunKasBank() {
    let res = await service.getKasBank();
    var kasBank = [];
    res.data.map((d) => {
      kasBank.push({
        value: d.id,
        label: d.nama,
      });
    });
    setListAkunKasBank(kasBank);
  }
  async function initData() {
    var penjualanForm = JSON.parse(localStorage.getItem("penjualanForm"));
    localStorage.setItem(
      "penjualanForm",
      JSON.stringify({
        ...penjualanForm,
      })
    );
    setData({ content: {}, loading: false });
    let selectedItem = defaultData();
    if (match.params.id != "new") {
      setDisabled(true);
      try {
        let res = await servicePPN.getFkById(match.params.id);
        let { data } = res;
        selectedItem.id = data.fk.id;
        selectedItem.tanggalInvoice = data.fk.tanggalInvoice;
        selectedItem.tanggalJatuhTempo = data.fk.tanggalJatuhTempo;
        if (data.fk.akunPiutang) {
          selectedItem.akunPiutangId = `${data.fk.akunPiutang.kode} - ${data.fk.akunPiutang.nama}`;
          selectedItem.akunPiutang.id = data.fk.akunPiutang.id;
          selectedItem.tanggalJatuhTempo = data.fk.tanggalJatuhTempo;
        }
        if (data.fk.fp) {
          selectedItem.transaksiId = data.fk.fp.transaksi.keterangan;
          selectedItem.keteranganId = data.fk.fp.keterangan.keterangan;
          selectedItem.jenisFakturKode = data.fk.fp.jenisFakturKode;
          selectedItem.masa = data.fk.fp.masa;
          selectedItem.tahun = data.fk.fp.tahun;
          selectedItem.tanggalFp = data.fk.fp.tanggalFp;
        }
        if (data.fk.lawanTransaksi) {
          selectedItem.lawanTransaksiId = `${data.fk.lawanTransaksi.npwp} - ${data.fk.lawanTransaksi.nama}`;
          selectedItem.npwp = data.fk.lawanTransaksi.npwp;
          selectedItem.nik = data.fk.lawanTransaksi.nik;
          selectedItem.alamat = data.fk.lawanTransaksi.alamat;
          selectedItem.namaPic = data.fk.lawanTransaksi.namaPic;
          selectedItem.emailPic = data.fk.lawanTransaksi.emailPic;
          selectedItem.noTeleponPic = data.fk.lawanTransaksi.noTeleponPic;
          selectedItem.alamatPic = data.fk.lawanTransaksi.alamatPic;
        }

        selectedItem.total = data.fk.total;
        setDataPenjualan(data.fkProdukList);
        setDataSetorSendiri(data.fkAkunPenjualanList);
        setDataBeban(data.fkAkunBebanList);
        // setDataSubmit(data)
        // selectedItem.id = data.id
        // selectedItem.kategoriAset = data.kategoriAset
        // selectedItem.kategoriHutang = data.kategoriHutang
        // selectedItem.koreksiFiskal = data.koreksiFiskal
        // setDataBeban(data.defaultKoreksiFiskal)
        // setDataPenjualan(data.defaultKoreksiFiskal)
      } catch (error) {
        toast.errorRequest(error)
      }
    } else {
      setDisabled(false);
    }
    setItem(selectedItem);
    akunKasBank();
    akunPiutang();
    lawanTransaksi();
    transaksiFakturPajak();
    keteranganFakturPajak();
  }

   function setorSendiri() {
    var penjualanSetorSendiri = JSON.parse(
      localStorage.getItem("nilaiPenjualanForm")
    );
    if (penjualanSetorSendiri) {
      var dataSetorSendiriNew = dataSetorSendiri;
      dataSetorSendiriNew.push(penjualanSetorSendiri);
      setDataSetorSendiri(dataSetorSendiriNew);
      localStorage.removeItem("nilaiPenjualanForm");
    }
  }

  async function akunBeban() {
    var akunBebanForm = JSON.parse(localStorage.getItem("bebanForm"));
    if (akunBebanForm) {
      var dataBebanNew = dataBeban;
      dataBebanNew.push(akunBebanForm);
      setDataBeban(dataBebanNew);
      localStorage.removeItem("bebanForm");
    }
  }

  function akunPenjualan() {
    var akunPenjualanForm = JSON.parse(localStorage.getItem("produkForm"));
    if (akunPenjualanForm) {
      var dataPenjualanNew = dataPenjualan;
      dataPenjualanNew.push(akunPenjualanForm);
      setDataPenjualan(dataPenjualanNew);
      localStorage.removeItem("produkForm");
    }
  }

  async function hapusPenjualan(index) {
    modalStore.showConfirm({
      title: t.translate("words.delete"),
      children: t.translate("words.confirmHapus"),
      onSubmit: (callback) => {
        var deleteIndex = dataPenjualan;
        deleteIndex.splice(index, 1);
        setDataPenjualan(deleteIndex);
        callback();
      },
    });
  }

  async function hapusBeban(index) {
    modalStore.showConfirm({
      title: t.translate("words.delete"),
      children: t.translate("words.confirmHapus"),
      onSubmit: (callback) => {
        var deleteIndex = dataBeban;
        deleteIndex.splice(index, 1);
        setDataBeban(deleteIndex);
        callback();
      },
    });
  }

  async function hapusNilaiPenjualan(index) {
    modalStore.showConfirm({
      title: t.translate("words.delete"),
      children: t.translate("words.confirmHapus"),
      onSubmit: (callback) => {
        var deleteIndex = dataSetorSendiri;
        deleteIndex.splice(index, 1);
        setDataSetorSendiri(deleteIndex);
        callback();
      },
    });
  }

  function addItem() {
    setDialogPenjualanProdukForm(true);
    setDialogData({});
  }

  function addBeban() {
    setDialogPenjualanBebanForm(true);
    setDialogData({});
  }

  function addNilaiPenjualan() {
    setDialogSetorSendiriForm(true);
    setDialogData({});
  }

  useEffect(() => {
    initData();
  }, []);

  const activePanel1 = () => {
    setPanel1(true);
    setPanel2(false);
    setPanel3(false);
  };

  const activePanel2 = () => {
    setPanel1(false);
    setPanel2(true);
    setPanel3(false);
  };

  const activePanel3 = () => {
    setPanel1(false);
    setPanel2(false);
    setPanel3(true);
  };

  var onSubmitIdentitas = async (data, callback) => {
    setItem(data);
    activePanel2();
  };

  var onSubmitTransaksi = async (data, callback) => {
    setItem(data);
    activePanel3();
  };

  var onSubmitPkp = async (data, callback) => {
	let resGambar;
    if (data.fileLampiran != null) {
      resGambar = await service.upload(data.fileLampiran);
    }
    let reqData = dataSubmit;

    //ISI FIELD
    reqData.fk.id = data.id;
    if (match.params.isPpn === "true") {
      reqData.fk.isPpn = true;
    } else {
      reqData.fk.isPpn = false;
    }
    reqData.fk.lawanTransaksi = data.lawanTransaksi;
    reqData.fk.kategoriTransaksi = data.kategoriTransaksi;

	reqData.fk.fileLampiran =
	data.fileLampiran != null ? resGambar.data.namaFileAlias : null;
  reqData.fk.fileLampiranAlias =
	data.fileLampiran != null ? resGambar.data.namaFileAlias : null;
  reqData.fk.fileLampiranInstance =
	data.fileLampiran != null ? resGambar.data.instanceId : null;
	reqData.fk.keterangan = data.keteranganid;

    reqData.fk.fp.transaksi = data.transaksi;
    reqData.fk.fp.keterangan = data.keterangan;
    reqData.fk.fp.masa = data.masa;
    reqData.fk.fp.tahun = data.tahun;
    reqData.fk.fp.tanggalFp = data.tanggalFp;
    reqData.fk.fp.jenisFakturKode = data.jenisFakturKode;
    reqData.fk.fp.jenisFakturKeterangan = data.jenisFakturKeterangan;
    reqData.fk.tanggalInvoice = moment(data.tanggalInvoice).format(
      "yyyy-MM-DD hh:mm:ss"
    );
    if (data.statusPembayaran == STAT_BELUM_BAYAR) {
      reqData.fk.akunPiutang = data.akunPiutang;

      reqData.fk.pembayaran.status = STAT_BELUM_BAYAR;
      reqData.fk.pembayaran.akun = null;

      reqData.fk.periodeJatuhTempo = data.periodeJatuhTempo;
      reqData.fk.tanggalJatuhTempo = moment(data.tanggalJatuhTempo).format(
        "yyyy-MM-DD hh:mm:ss"
      );
    } else {
      reqData.fk.akunPiutang = null;

      reqData.fk.pembayaran.status = STAT_LUNAS;
      reqData.fk.pembayaran.akun = data.pembayaran;

      reqData.fk.periodeJatuhTempo = 0;
      reqData.fk.tanggalJatuhTempo = null;
    }

    reqData.fk.subtotal = 0;
    reqData.fk.total = 0;
    reqData.fk.subtotalAkun = 0;
    reqData.fk.totalPajakSetor = 0;
    reqData.fk.totalBeban = 0;
    reqData.fk.totalPenjualan = 0;

    dataPenjualan.map((d, index) => {
      reqData.fk.totalPenjualan += d.subtotal;
    });
    dataSetorSendiri.map((d, index) => {
      if (d.kategoriAkunDebitKredit == DEBIT) {
        reqData.fk.subtotal -= d.nilaiPenjualan;
        reqData.fk.subtotalAkun -= d.total;
      } else {
        reqData.fk.subtotal += d.nilaiPenjualan;
        reqData.fk.subtotalAkun += d.total;
      }

      if (d.pajakSetorSendiri) {
        reqData.fk.totalPajakSetor += d.nominalPajak;
      }
    });

    dataBeban.map((d, index) => {
      reqData.fk.totalBeban += d.total;
    });

    reqData.fk.total = reqData.fk.subtotal;

    reqData.fk.npwpHead.id = sessionStorage.getItem("npwpHeadId");

    reqData.akunBebanList = dataBeban;
    reqData.fkProdukList = dataPenjualan;
    reqData.fkAkunPenjualanList = dataSetorSendiri;
    reqData.akunBebanHapusList = deleteAkunBeban;
    reqData.fkAkunPenjualanHapusList = deleteAkunPenjualan;
    reqData.fkProdukHapusList = deleteProduk;

    try {
      const res = await service.postPkp(reqData);
      let { data, status } = res;
      if (status == 200) {
        if (reqData.fk.isPpn) {
          callback("success", false);
          navigationStore.redirectTo(`/internal/npwp/${npwpHeadId}/ppn`);
        } else {
          callback("success", false);
          navigationStore.redirectTo(backTo);
        }
        // window.location.href = `${basePath}/${item.id}/history`
      }
    } catch (err) {
      callback(err, true, false);
    }

    // if (reqData.fk.id) {

    // 	try {
    // 		if (match.params.isPpn == 'false') {
    // 			navigationStore.redirectTo(backTo)
    // 		} else {
    // 			navigationStore.redirectTo(`/internal/npwp/${npwpHeadId}/ppn`)
    // 		}
    // 	}
    // 	catch (err) {
    // 		callback(err, true, false);
    // 	}

    // }
    // else {
    // 	if (match.params.pesananId != 'nonPesanan') {
    // 		try {
    // 			const res = await service.postInvoicePkp(match.params.pesananId, reqData);
    // 			let { data, status } = res;
    // 			if (status == 200) {
    // 				callback('success', false)
    // 				navigationStore.redirectTo(backTo)
    // 			}
    // 			console.log(res, 'res')
    // 		} catch (err) {
    // 			callback(err, true, false);
    // 		}

    // 	}
    // 	else {
    // 		try {
    // 			const res = await service.postPkp(reqData);
    // 			let { data, status } = res;
    // 			if (status == 200) {
    // 				if (reqData.fk.isPpn) {
    // 					callback('success', false)
    // 					navigationStore.redirectTo(`/internal/npwp/${npwpHeadId}/ppn`)
    // 				} else {
    // 					callback('success', false)
    // 					navigationStore.redirectTo(backTo)
    // 				}
    // 				// window.location.href = `${basePath}/${item.id}/history`
    // 			}
    // 			console.log(res, 'res')
    // 		}
    // 		catch (err) {
    // 			callback(err, true, false);
    // 		}
    // 	}
    // }
  };

  var onSubmit = async (data, callback) => {
    // data.form1valid = true
    // var bpform = JSON.parse(localStorage.getItem('bpform'))
    // bpform = Object.assign(bpform, data)
    // localStorage.setItem('bpform', JSON.stringify(bpform))
    props.activePanel();
  };

  const baseName = `form-penjualan`;
  const basePath = `penjualan`;
  var npwpHeadId = sessionStorage.getItem("npwpHeadId");
  var backTo = `/internal/npwp/${npwpHeadId}/penjualan`;
  var produkData = [
    {
      kode: "6.1.109",
      nama: "Beban Jasa",
      jumlahBarang: "10",
      nominal: "10000000",
      dpp: "10000000",
      ppn: "10000000",
      ppnBm: "10000000",
    },
  ];

  var penjualanSebelumPajak = [
    {
      namaAkun: "Penjualan",
      kodeAkun: "4.1.01.0001",
      nominal: "30200000",
    },
  ];

  var disetorSendiri = [
    {
      namaAkun: "Penjualan",
      kodeAkun: "4.1.01.0001",
      nominal: "30200000",
    },
    {
      namaAkun: "Penjualan",
      kodeAkun: "4.1.01.0001",
      nominal: "30200000",
    },
  ];

  // setDokumens(dokumensData);

  return (
    <FormWrapper
      loading={data.loading}
      backTo={backTo}
      showCommandbar={true}
      baseId={`${baseName}`}
      title={t.translate(`modules.penjualan.form.pkp.title`)}
      style={{
        maxWidth: "100%",
      }}
      // defaultData={data.content}
      definitions={[
        {
          render: (
            <ExpansionList style={{ width: "100%" }}>
              {/* FORM IDENTITAS */}
              <ExpansionPanel
                expanded={panel1}
                onClick={() => activePanel1()}
                header={t.translate("words.identity")}
              >
                <FormWrapper
                  submitLabel={t.translate("words.next")}
                  loading={data.loading}
                  showCommandbar={false}
                  baseId={`mod-form-${baseName}`}
                  style={{
                    maxWidth: "100%",
                  }}
                  defaultData={item}
                  definitions={[
                    {
                      render: (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginBottom: 20,
                          }}
                        >
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <b style={{ marginTop: "5%" }}>
                              {t.translate(`words.dataLawanTransaksi`)}
                            </b>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Button
                              themeType="outline"
                              style={{
                                borderWidth: 2,
                                borderColor: "#218c74",
                                color: "#218c74",
                                fontWeight: "bold",
                              }}
                              onClick={() => {
                                setShowForm(true);
                              }}
                            >
                              <FontIcon iconClassName="mdi mdi-plus" />
                              {t.translate("words.add")} Lawan Transaksi
                            </Button>
                          </div>
                        </div>
                      ),
                    },
                    {
                      inputType: inputTypes.AUTOCOMPLETE,
                      label: t.translate(`words.nama`),
                      key: "lawanTransaksiId",
                      validation: "required",
                      labelKey: "label",
                      valueKey: "label",
                      data: lawanTr,
                      disabled: disabled,
                    },
                    {
                      inputType: inputTypes.INPUT_MASK_NUMBER,
                      label: t.translate(`words.identityNpwp`),
                      key: "npwp",
                      type: "text",
                      mask: inputValidasiNpwp,
                      maskChar: "_",
                      maskValue: "string",
                      disabled: true,
                      width: "48%",
                      style: {
                        marginRight: "2%",
                      },
                    },
                    {
                      inputType: inputTypes.INPUT_MASK_NUMBER,
                      label: t.translate(`words.identityNik`),
                      key: "nik",
                      type: "text",
                      mask: inputValidasiNik,
                      maskChar: "_",
                      maskValue: "string",
                      disabled: true,
                      width: "48%",
                      style: {
                        marginLeft: "2%",
                      },
                    },
                    {
                      inputType: inputTypes.INPUT,
                      label: t.translate(`words.address`),
                      key: "alamat",
                      disabled: true,
                      type: "text",
                      width: "100%",
                    },
                    {
                      render: (
                        <div style={{ width: "100%" }}>
                          <p style={{ margin: "10px" }}>
                            <b>{t.translate(`words.picPenagihan`)}</b>
                          </p>
                        </div>
                      ),
                    },
                    {
                      inputType: inputTypes.INPUT,
                      label: t.translate(`words.namaPic`),
                      key: "namaPic",
                      type: "text",
                      disabled: true,
                      width: "48%",
                      style: {
                        marginRight: "2%",
                      },
                    },
                    {
                      inputType: inputTypes.INPUT,
                      label: t.translate(`words.emailPic`),
                      key: "emailPic",
                      type: "text",
                      disabled: true,
                      width: "48%",
                      style: {
                        marginLeft: "2%",
                      },
                    },
                    {
                      inputType: inputTypes.INPUT_MASK_NUMBER,
                      label: t.translate(`words.nomorTeleponPic`),
                      key: "noTeleponPic",
                      type: "text",
                      disabled: true,
                      width: "48%",
                      style: {
                        marginRight: "2%",
                      },
                    },
                    {
                      inputType: inputTypes.INPUT,
                      label: t.translate(`words.alamatPic`),
                      key: "alamatPic",
                      type: "text",
                      disabled: true,
                      width: "48%",
                      style: {
                        marginLeft: "2%",
                      },
                    },
                  ]}
                  onChange={(data, key, value) => {
                    if (key === "lawanTransaksiId") {
                      lawanTr.map((d) => {
                        if (d.label === value) {
                          data.lawanTransaksi.id = d.value;
                          data.lawanTransaksiNama = d.nama;
                          data.lawanTransaksiNik = d.nik;
                          data.lawanTransaksiNpwp = d.npwp;
                          data.lawanTransaksiAlamat = d.alamat;
                          data.lawanTransaksiNamaPic = d.namaPic;
                          data.lawanTransaksiNoTeleponPic = d.noTeleponPic;
                          data.lawanTransaksiEmailPic = d.emailPic;
                          data.lawanTransaksiAlamatPic = d.alamatPic;
                          let dataLawanTransaksi = find(dataTr, {
                            id: data.lawanTransaksi.id,
                          });
                          data["npwp"] = dataLawanTransaksi
                            ? dataLawanTransaksi.npwp
                            : "";
                          data["nik"] = dataLawanTransaksi
                            ? dataLawanTransaksi.nik
                            : "";
                          data["alamat"] = dataLawanTransaksi
                            ? dataLawanTransaksi.alamat
                            : "";
                          data["namaPic"] = dataLawanTransaksi
                            ? dataLawanTransaksi.namaPic
                            : "";
                          data["emailPic"] = dataLawanTransaksi
                            ? dataLawanTransaksi.emailPic
                            : "";
                          data["noTeleponPic"] = dataLawanTransaksi
                            ? dataLawanTransaksi.noTeleponPic
                            : "";
                          data["alamatPic"] = dataLawanTransaksi
                            ? dataLawanTransaksi.alamatPic
                            : "";
                        }
                      });
                    }
                  }}
                  onSubmit={onSubmitIdentitas}
                />
                <LawanTransaksiForm
                  visible={showForm}
                  onRequestClose={() => {
                    setShowForm(false);
                    lawanTransaksi();
                  }}
                  item={null}
                />
              </ExpansionPanel>

              <br />

              {/* FORM TRANSAKSI */}
              <ExpansionPanel
                expanded={panel2}
                onClick={() => activePanel2()}
                header={t.translate("words.transaksi")}
              >
                <FormWrapper
                  submitLabel={t.translate("words.next")}
                  loading={data.loading}
                  showCommandbar={false}
                  baseId={`mod-form-${baseName}`}
                  style={{
                    maxWidth: "100%",
                  }}
                  defaultData={item}
                  definitions={[
                    {
                      render: (
                        <div style={{ width: "100%", marginBottom: 30 }}>
                          <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                            {t.translate(`column.general`)}
                            <Divider />
                          </Text>
                        </div>
                      ),
                    },
                    {
                      inputType: inputTypes.RADIO,
                      label: t.translate(`words.penjualanNormal`),
                      key: "kategoriTransaksi",
                      name: "kategoriTransaksi",
                      value: KATEGORI_TRANSAKSI_NORMAL,
                      style: {
                        width: "100%",
                      },
                      disabled: disabled,
                    },
                    {
                      render: (
                        <div style={{ width: "100%", marginBottom: 30 }}>
                          <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                            {t.translate(`words.dataPenjualan`)}
                            <Divider />
                          </Text>
                        </div>
                      ),
                    },
                    {
                      inputType: inputTypes.DATEPICKER,
                      className: "mpk-padding-N padding-right",
                      label: t.translate(`words.tglTransaksi`),
                      key: "tanggalInvoice",
                      type: "date",
                      width: "50%",
                      validation: "required",
                      disabled: disabled,
                      maxDate: new Date(),
                    },
                    {
                      inputType: inputTypes.SELECT,
                      label: "Status Pembayaran",
                      key: "statusPembayaran",
                      width: "50%",
                      required: true,
                      options: statusBayar.map((d) => ({
                        label: d.name,
                        value: d.value,
                      })),
                      disabled: disabled,
                    },
                    {
                      inputType: inputTypes.AUTOCOMPLETE,
                      label: t.translate(`words.akunPiutang`),
                      key: "akunPiutangId",
                      data: listAkunPiutang,
                      labelKey: "label",
                      valueKey: "label",
                      show: !statusLunas,
                      disabled: disabled,
                    },
                    {
                      inputType: inputTypes.INPUT_MASK_NUMBER,
                      label: `Periode Jatuh Tempo (Hari)`,
                      thousandSeparator: ".",
                      decimalSeparator: ",",
                      isNumericString: true,
                      key: "periodeJatuhTempo",
                      width: "50%",
                      className: "mpk-padding-N padding-right",
                      show: !statusLunas,
                      disabled: disabled,
                    },
                    {
                      inputType: inputTypes.DATEPICKER,
                      label: t.translate(`words.tanggalJatuhTempo`),
                      key: "tanggalJatuhTempo",
                      type: "date",
                      disable: true,
                      width: "50%",

                      show: !statusLunas,
                      disabled: true,
                    },
                    {
                      inputType: inputTypes.AUTOCOMPLETE,
                      label: t.translate(`words.terimaDari`),
                      key: "terimaDariId",
                      data: listAkunKasBank,
                      labelKey: "label",
                      valueKey: "label",
                      width: "48%",
                      style: {
                        marginRight: "2%",
                      },
                      show: statusLunas,
                      disabled: disabled,
                    },
                    {
                      render: (
                        <div style={{ width: "100%", marginBottom: 30 }}>
                          <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                            Data Faktur Pajak
                            <Divider />
                          </Text>
                        </div>
                      ),
                    },
                    {
                      inputType: inputTypes.INPUT,
                      // className: 'mpk-padding-N padding-right',
                      label: "Nomor Seri Faktur Pajak",
                      key: "NSFP",
                      value: nsfp,
                      // width: '12%',
                      disabled: true,
                    },
                    {
                      inputType: inputTypes.AUTOCOMPLETE,
                      className: "mpk-padding-N padding-right",
                      label: "Detail Transaksi",
                      key: "transaksiId",
                      data: listTransaksi,
                      labelKey: "label",
                      valueKey: "label",
                      width: "50%",
                      required: true,
                      disabled: disabled,
                    },
                    {
                      inputType: inputTypes.AUTOCOMPLETE,
                      label: "Keterangan Tambahan",
                      key: "keteranganId",
                      width: "50%",
                      required: true,
                      data: listKeterangan,
                      labelKey: "label",
                      valueKey: "label",
                      disabled: disabled,
                    },
                    {
                      // inputType: inputTypes.INPUT,
                      // className: 'mpk-padding-N padding-right',
                      // label: '',
                      // key: 'jenisFakturKode',
                      // value: '0',
                      // width: '12%',
                      // disabled: true
                    },
                    {
                      inputType: inputTypes.INPUT,
                      className: "mpk-padding-N padding-right",
                      label: "Jenis Faktur Pajak",
                      key: "jenisFakturKeterangan",
                      width: "50%",
                      value: "1 - Faktur Pajak Normal",
                      disabled: true,
                    },
                    {
                      inputType: inputTypes.DATEPICKER,
                      label: "Tanggal Faktur Pajak",
                      key: "tanggalFp",
                      width: "50%",
                      disabled: true,
                    },
                    {
                      inputType: inputTypes.INPUT_MASK_NUMBER,
                      label: "Masa",
                      key: "masa",
                      className: "mpk-padding-N padding-right",
                      width: "50%",
                      disabled: true,
                    },
                    {
                      inputType: inputTypes.INPUT_MASK_NUMBER,
                      label: "Tahun",
                      key: "tahun",
                      width: "50%",
                      disabled: true,
                    },
                  ]}
                  onChange={(data, key, value) => {
                    if (key === "tanggalInvoice") {
                      let tanggalFp = new Date(data.tanggalInvoice);
                      tanggalFp = moment(tanggalFp).format(
                        "yyyy-MM-DD hh:mm:ss"
                      );
                      data.tanggalFp = tanggalFp;

                      let yearFp = new Date(data.tanggalInvoice).getFullYear();
                      let monthFp =
                        new Date(data.tanggalInvoice).getMonth() + 1;
                      data.masa = monthFp;
                      data.tahun = yearFp;
                    }

                    if (key === "transaksiId") {
                      listTransaksi.map((d) => {
                        if (d.label == value) {
                          data.transaksi = {
                            id: d.value,
                            kode: d.kode,
                            keterangan: d.label,
                          };
                        }
                      });
                    }

                    if (key === "keteranganId") {
                      listKeterangan.map((d) => {
                        if (d.label == value) {
                          data.keterangan = {
                            id: d.value,
                            keterangan: d.label,
                          };
                        }
                      });
                    }

                    if (data.kategoriTransaksi) {
                      setDisabledField(false);
                    }

                    if (key === "statusPembayaran") {
                      if (value === "1") {
                        setStatusLunas(false);
                      } else {
                        setStatusLunas(true);
                      }
                    }

                    if (key === "akunPiutangId") {
                      listAkunPiutang.map((d) => {
                        if (d.label == value) {
                          data["akunPiutang"] = {
                            id: d.value,
                          };
                        }
                      });
                    }

                    if (key === "terimaDariId") {
                      listAkunKasBank.map((d) => {
                        if (d.label == value) {
                          data["pembayaran"] = {
                            id: d.value,
                          };
                        }
                      });
                    }

                    if (key == "tanggalInvoice") {
                      let tanggalInvoice = new Date(data["tanggalInvoice"]);
                      tanggalInvoice.setDate(
                        tanggalInvoice.getDate() + data["periodeJatuhTempo"]
                      );
                      data["tanggalJatuhTempo"] = tanggalInvoice;
                    }

                    if (key == "periodeJatuhTempo") {
                      let tanggalInvoice = new Date(data.tanggalInvoice);
                      tanggalInvoice.setDate(tanggalInvoice.getDate() + value);
                      data.tanggalJatuhTempo = tanggalInvoice;
                    }
                  }}
                  onSubmit={onSubmitTransaksi}
                />
              </ExpansionPanel>

              <br />

              {/* FORM PRODUK */}
              <ExpansionPanel
                expanded={panel3}
                onClick={() => activePanel3()}
                header={t.translate("words.produk")}
              >
                <PenjualanProdukForm
                  visible={dialogPenjualanProdukForm}
                  onRequestClose={() => {
                    akunPenjualan();
                    setDialogPenjualanProdukForm(false);
                    setDialogData(null);
                  }}
                  item={dialogData}
                />
                <PenjualanBebanForm
                  visible={dialogPenjualanBebanForm}
                  onRequestClose={() => {
                    akunBeban();
                    setDialogPenjualanBebanForm(false);
                    setDialogData(null);
                  }}
                  item={dialogData}
                />
                <PenjualanNilaiPenjualanForm
                  visible={dialogSetorSendiriForm}
                  onRequestClose={() => {
                    setorSendiri();
                    setDialogSetorSendiriForm(false);
                    setDialogData(null);
                  }}
                />
                <FormWrapper
                  loading={data.loading}
                  showCommandbar={false}
                  baseId={`mod-form-${baseName}`}
                  style={{
                    maxWidth: "100%",
                  }}
                  defaultData={item}
                  definitions={[
                    {
                      render: (
                        <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                          Keterangan & Lampiran
                          <Divider />
                        </Text>
                      ),
                    },
                    {
                      inputType: inputTypes.FILE_INPUT,
                      label: t.translate("words.file"),
                      key: "fileLampiran",
                      type: "file",
                      // validation: 'required',
                      // multiple: true,
                      // multiline: true,
                      // accept: '.jpg,.jpeg,.png',
                      disabled: disabled,
                    },
                    {
                      inputType: inputTypes.INPUT,
                      label: t.translate(`words.keterangan`),
                      key: "keteranganid",
                      type: "text",
                      value: "",
                      width: "100%",
                      disabled: disabled,
                    },
                    {
                      render: <div style={{ margin: 10 }}></div>,
                    },
                    {
                      render: (
                        <div style={{ width: "100%" }}>
                          <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                            Produk Penjualan
                            <Button
                              theme="primary"
                              themeType="contained"
                              style={{ marginBottom: 5, float: "right" }}
                              onClick={() => addItem()}
                              disabled={disabled}
                            >
                              <FontIcon iconClassName="mdi mdi-plus" />
                              {t.translate("words.add")}
                            </Button>
                            <Divider />
                          </Text>
                          {dataPenjualan.length > 0 ? (
                            <>
                              <TableContainer>
                                <Table style={{ width: "100%" }}>
                                  <TableHeader>
                                    <TableRow>
                                      <TableCell style={{ width: "10%" }}>
                                        {t.translate("words.non")}
                                      </TableCell>
                                      <TableCell style={{ width: "25%" }}>
                                        {t.translate("words.kode")}
                                      </TableCell>
                                      <TableCell style={{ width: "25%" }}>
                                        {t.translate("words.nama")}
                                      </TableCell>
                                      <TableCell style={{ width: "25%" }}>
                                        Harga Total
                                      </TableCell>
                                      <TableCell style={{ width: "10%" }}>
                                        {t.translate("words.dpp")}
                                      </TableCell>
                                      <TableCell style={{ width: "10%" }}>
                                        {t.translate("words.ppn")}
                                      </TableCell>
                                      <TableCell style={{ width: "10%" }}>
                                        {t.translate("words.ppnBm")}
                                      </TableCell>
                                    </TableRow>
                                  </TableHeader>
                                  <TableBody>
                                    {dataPenjualan.map((d, index) => {
                                      return (
                                        <TableRow key={index}>
                                          <TableCell>{index + 1}</TableCell>
                                          <TableCell>{d.produk.kode}</TableCell>
                                          <TableCell>{d.produk.nama}</TableCell>
                                          <TableCell>Rp. {format(d.subtotal)}</TableCell>
                                          <TableCell>Rp. {format(d.dpp)}</TableCell>
                                          <TableCell>Rp. {format(d.ppn)}</TableCell>
                                          <TableCell>
                                            {d.ppnbm ? format(d.ppnbm) : "-"}
                                          </TableCell>
                                          <TableCell>
                                            <Button
                                              style={{
                                                backgroundColor: "#34495e",
                                                color: "white",
                                              }}
                                              disabled={disabled}
                                              onClick={() =>
                                                hapusPenjualan(index)
                                              }
                                            >
                                              {t.translate("words.delete")}
                                            </Button>
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  width: "100%",
                                  marginBottom: 50,
                                  marginTop: 30,
                                  backgroundColor: "#ecf0f1",
                                  padding: 5,
                                  borderRadius: 50,
                                }}
                              >
                                <TextContainer
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    flex: 1,
                                    padding: 5,
                                  }}
                                >
                                  <Text style={{ fontSize: 16 }}>
                                    Total :{" "}
                                    <b>
                                      Rp.
                                      {format(
                                        dataPenjualan
                                          .map((d) => d.subtotal)
                                          .reduce((subtotal, datas) => {
                                            return subtotal + datas;
                                          }, 0)
                                      )}
                                    </b>
                                  </Text>
                                </TextContainer>
                              </div>
                            </>
                          ) : (
                            <TextContainer style={{ textAlign: "center" }}>
                              <Text
                                type="headline-6"
                                style={{ color: "#7f8c8d" }}
                              >
                                Tidak ada data
                              </Text>
                            </TextContainer>
                          )}
                          <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                            Penjualan Sebelum Pajak
                            <Button
                              theme="primary"
                              themeType="contained"
                              style={{ marginBottom: 5, float: "right" }}
                              onClick={() => addNilaiPenjualan()}
                              disabled={disabled}
                            >
                              <FontIcon iconClassName="mdi mdi-plus" />
                              {t.translate("words.add")}
                            </Button>
                            <Divider />
                          </Text>
                          {dataSetorSendiri.length > 0 ? (
                            <>
                              <Table style={{ width: "100%" }}>
                                <TableHeader>
                                  <TableRow>
                                    <TableCell style={{ width: "10%" }}>
                                      {t.translate("words.non")}
                                    </TableCell>
                                    <TableCell style={{ width: "20%" }}>
                                      {t.translate("words.namaAkun")}
                                    </TableCell>
                                    <TableCell style={{ width: "20%" }}>
                                      {t.translate("words.kodeAkun")}
                                    </TableCell>
                                    <TableCell style={{ width: "20%" }}>
                                      {t.translate("words.nominal")}
                                    </TableCell>
                                  </TableRow>
                                </TableHeader>
                                <TableBody>
                                  {dataSetorSendiri.map((d, index) => {
                                    return (
                                      <TableRow key={index}>
                                        {/* <TableCell>{console.log(d,"data pajak setor sendiri")}</TableCell> */}
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{d.akun.nama}</TableCell>
                                        <TableCell>{d.akun.kode}</TableCell>
                                        <TableCell>
                                          Rp. {format(d.nilaiPenjualan)}
                                        </TableCell>
                                        <TableCell>
                                          {/* <Button onClick={()=> console.log("Masuk")}>{t.translate("words.edit")}</Button> */}
                                          <Button
                                            style={{
                                              backgroundColor: "#34495e",
                                              color: "white",
                                            }}
                                            disabled={disabled}
                                            onClick={() =>
                                              hapusNilaiPenjualan(index)
                                            }
                                          >
                                            {t.translate("words.delete")}
                                          </Button>
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                                </TableBody>
                              </Table>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  width: "100%",
                                  marginBottom: 50,
                                  marginTop: 30,
                                  backgroundColor: "#ecf0f1",
                                  padding: 5,
                                  borderRadius: 50,
                                }}
                              >
                                <TextContainer
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    flex: 1,
                                    padding: 5,
                                  }}
                                >
                                  <Text style={{ fontSize: 16 }}>
                                    Total :{" "}
                                    <b>
                                      Rp.
                                      {format(
                                        dataSetorSendiri
                                          .filter(
                                            (d) =>
                                              d.akun.kategoriAkunDebitKredit ==
                                              KREDIT
                                          )
                                          .map((d) => d.nilaiPenjualan)
                                          .reduce((total, datas) => {
                                            return total + datas;
                                          }, 0) -
                                          dataSetorSendiri
                                            .filter(
                                              (d) =>
                                                d.akun
                                                  .kategoriAkunDebitKredit ==
                                                DEBIT
                                            )
                                            .map((d) => d.nilaiPenjualan)
                                            .reduce((total, datas) => {
                                              return total + datas;
                                            }, 0)
                                      )}
                                    </b>
                                  </Text>
                                </TextContainer>
                              </div>
                            </>
                          ) : (
                            <TextContainer style={{ textAlign: "center" }}>
                              <Text
                                type="headline-6"
                                style={{ color: "#7f8c8d" }}
                              >
                                Tidak ada data
                              </Text>
                            </TextContainer>
                          )}
                          <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                            Pajak Disetor Sendiri
                            <Divider />
                          </Text>
                          {dataSetorSendiri.length > 0 ? (
                            <>
                              <Table style={{ width: "100%" }}>
                                <TableHeader>
                                  <TableRow>
                                    <TableCell style={{ width: "20%" }}>
                                      {t.translate("words.namaAkun")}
                                    </TableCell>
                                    <TableCell style={{ width: "20%" }}>
                                      {t.translate("words.kodeAkun")}
                                    </TableCell>
                                    <TableCell style={{ width: "20%" }}>
                                      {t.translate("words.nominal")}
                                    </TableCell>
                                  </TableRow>
                                </TableHeader>
                                <TableBody>
                                  {dataSetorSendiri.map((d, index) => {
                                    if (
                                      d.pajakSetorSendiri ==
                                      NILAI_PENJUALAN_SETOR_SENDIRI
                                    ) {
                                      return (
                                        <TableRow key={index}>
                                          <TableCell>{d.akun.nama}</TableCell>
                                          <TableCell>{d.akun.kode}</TableCell>
                                          <TableCell>
                                            Rp. {format(d.nominalPajak)}
                                          </TableCell>
                                          <TableCell>
                                            {/* <Button onClick={()=> console.log("Masuk")}>{t.translate("words.edit")}</Button> */}
                                            <Button
                                              style={{
                                                backgroundColor: "#34495e",
                                                color: "white",
                                              }}
                                              disabled={disabled}
                                              onClick={() =>
                                                hapusNilaiPenjualan(index)
                                              }
                                            >
                                              {t.translate("words.delete")}
                                            </Button>
                                          </TableCell>
                                        </TableRow>
                                      );
                                    }
                                  })}
                                </TableBody>
                              </Table>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  width: "100%",
                                  marginBottom: 50,
                                  marginTop: 30,
                                  backgroundColor: "#ecf0f1",
                                  padding: 5,
                                  borderRadius: 50,
                                }}
                              >
                                <TextContainer
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    flex: 1,
                                    padding: 5,
                                  }}
                                >
                                  <Text style={{ fontSize: 16 }}>
                                    Total Pajak :{" "}
                                    <b>
                                      Rp.{" "}
                                      {format(
                                        dataSetorSendiri
                                          .filter(
                                            (d) =>
                                              d.pajakSetorSendiri ==
                                                NILAI_PENJUALAN_SETOR_SENDIRI &&
                                              d.akun.kategoriAkunDebitKredit ==
                                                KREDIT
                                          )
                                          .map((d) => d.nominalPajak)
                                          .reduce((total, datas) => {
                                            return total + datas;
                                          }, 0) -
                                          dataSetorSendiri
                                            .filter(
                                              (d) =>
                                                d.pajakSetorSendiri ==
                                                  NILAI_PENJUALAN_SETOR_SENDIRI &&
                                                d.akun
                                                  .kategoriAkunDebitKredit ==
                                                  DEBIT
                                            )
                                            .map((d) => d.nominalPajak)
                                            .reduce((total, datas) => {
                                              return total + datas;
                                            }, 0)
                                      )}
                                    </b>
                                  </Text>
                                </TextContainer>
                              </div>
                            </>
                          ) : (
                            <TextContainer style={{ textAlign: "center" }}>
                              <Text
                                type="headline-6"
                                style={{ color: "#7f8c8d" }}
                              >
                                Tidak ada data
                              </Text>
                            </TextContainer>
                          )}
                          <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                            Beban/Pendapatan Lainnya
                            <Button
                              theme="primary"
                              themeType="contained"
                              style={{ marginBottom: 5, float: "right" }}
                              onClick={() => addBeban()}
                              disabled={disabled}
                            >
                              <FontIcon iconClassName="mdi mdi-plus" />
                              {t.translate("words.add")}
                            </Button>
                            <Divider />
                          </Text>
                          {dataBeban.length > 0 ? (
                            <>
                              <Table style={{ width: "100%" }}>
                                <TableHeader>
                                  <TableRow>
                                    <TableCell style={{ width: "10%" }}>
                                      {t.translate("words.non")}
                                    </TableCell>
                                    <TableCell style={{ width: "20%" }}>
                                      Kategori Akun
                                    </TableCell>
                                    <TableCell style={{ width: "20%" }}>
                                      {t.translate("words.namaAkun")}
                                    </TableCell>
                                    <TableCell style={{ width: "20%" }}>
                                      {t.translate("words.nominal")}
                                    </TableCell>
                                    {/* <TableCell style={{ width: '20%' }}>{t.translate('words.nominal')}</TableCell> */}
                                  </TableRow>
                                </TableHeader>
                                <TableBody>
                                  {dataBeban.map((d, index) => {
                                    return (
                                      <TableRow key={index}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>
                                          {d.debitKredit == 1
                                            ? "Debit"
                                            : "Kredit"}
                                        </TableCell>
                                        <TableCell>{d.akun.nama}</TableCell>
                                        <TableCell>
                                          Rp. {format(d.total)}
                                        </TableCell>
                                        <TableCell>
                                          <Button
                                            disabled={disabled}
                                            style={{
                                              backgroundColor: "#34495e",
                                              color: "white",
                                            }}
                                            onClick={() => hapusBeban(index)}
                                          >
                                            {t.translate("words.delete")}
                                          </Button>
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                                </TableBody>
                              </Table>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  width: "100%",
                                  marginBottom: 50,
                                  marginTop: 30,
                                  backgroundColor: "#ecf0f1",
                                  padding: 5,
                                  borderRadius: 50,
                                }}
                              >
                                <TextContainer
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    flex: 1,
                                    padding: 5,
                                  }}
                                >
                                  <Text style={{ fontSize: 16 }}>
                                    Total Debit :{" "}
                                    <b>
                                      Rp.{" "}
                                      {format(
                                        dataBeban
                                          .filter((d) => d.debitKredit == DEBIT)
                                          .map((d) => d.total)
                                          .reduce((total, datas) => {
                                            return total + datas;
                                          }, 0)
                                      )}
                                    </b>
                                  </Text>
                                </TextContainer>
                                <TextContainer
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    flex: 1,
                                    padding: 5,
                                  }}
                                >
                                  <Text style={{ fontSize: 16 }}>
                                    Total Kredit :{" "}
                                    <b>
                                      Rp.{" "}
                                      {format(
                                        dataBeban
                                          .filter(
                                            (d) => d.debitKredit == KREDIT
                                          )
                                          .map((d) => d.total)
                                          .reduce((total, datas) => {
                                            return total + datas;
                                          }, 0)
                                      )}
                                    </b>
                                  </Text>
                                </TextContainer>
                              </div>
                            </>
                          ) : (
                            <TextContainer style={{ textAlign: "center" }}>
                              <Text
                                type="headline-6"
                                style={{ color: "#7f8c8d" }}
                              >
                                Tidak ada data
                              </Text>
                            </TextContainer>
                          )}
                        </div>
                      ),
                    },
                  ]}
                  submitLabel={t.translate("words.submit")}
                  onSubmit={onSubmitPkp}
                />
              </ExpansionPanel>
            </ExpansionList>
          ),
        },
      ]}
      // onSubmit={async (data, callback) => {

      // }}
    />
  );

  function _closeDialog() {
    setDialogPenjualanProdukForm(false);
  }

  function _setDokumens(dokumens) {
    setDokumens(dokumens);
  }
};

export default inject(
  "envStore",
  "modalStore",
  "navigationStore"
)(observer(PenjualanFormPkp));
