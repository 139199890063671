import React, { useEffect, useState } from "react";
import { DataForm } from "../../libs/react-mpk/components";
import t from "counterpart";
import { FormWrapper } from "../../libs/react-mpk/wrapper";
import service from "./Penjualan.service";
import { toast } from "../../libs/react-mpk/services";
import { Text } from "react-md";
const TARIF_PPN = 0.11;
const dataPenggunaNpwp = JSON.parse(sessionStorage.getItem("dataPenggunaNpwp"));

const PenjualanProdukForm = ({
  visible = false,
  authStore,
  envStore,
  match,
  history,
  onRequestClose = () => {},
  ...props
}) => {
  const defaultData = {
    isDiskon: 0,
    hargaSatuan: 0,
    jumlahBarang: 0,
    hargaTotal: 0,
    ppn: 0,
    jumlah: 0,
    isPpn: false,
  };
  const { inputTypes, definition } = FormWrapper;
  let [item, setItem] = useState(defaultData);
  let [ready, setReady] = useState(true);
  const [listProduk, setListProduk] = useState([]);

  const [loadingProduk, setLoadingProduk] = useState(false);
  let [queryProduk, setQueryProduk] = useState("");

  async function akunProduk(queryProduk) {
    try {
      setLoadingProduk(true);
      let res = await service.getProduk(queryProduk);
      var produk = [];
      res.data.map((d) => {
        produk.push({
          value: d.id,
          label: d.nama,
          kode: d.kode,
        });
      });
      setListProduk(produk);
      setLoadingProduk(false);
    } catch (error) {
      toast.errorRequest(error);
      setLoadingProduk(false);
    }
  }

  useEffect(() => {
    if (visible) {
      akunProduk(queryProduk);
    } else {
      setItem(defaultData);
    }
    setReady(visible);
  }, [visible]);

  useEffect(() => {
    setLoadingProduk(true);
    const timeOutId = setTimeout(() => akunProduk(queryProduk), 500);
    return () => clearTimeout(timeOutId);
  }, [queryProduk]);

  return (
    ready && (
      <DataForm
        baseId="penjualan-produk-form"
        title={t.translate(`modules.penjualan.penjualanProdukForm`)}
        visible={visible}
        // data={item}
        onRequestClose={onRequestClose}
        asDialog={true}
        defaultData={item}
        definitions={[
          {
            inputType: DataForm.inputTypes.REACT_SELECT,
            isClearable: true,
            label: t.translate(`words.produk`),
            key: "produkId",
            validation: "required",
            labelKey: "label",
            valueKey: "value",
            options: listProduk,
            isLoading: loadingProduk,
            onInputChange: (value) => {
              if (value) setQueryProduk(value);
            },
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            label: t.translate(`words.hargaSatuan`),
            key: "hargaSatuan",
            thousandSeparator: ".",
            decimalSeparator: ",",
            isNumericString: true,
            type: "text",
            width: "48%",
            prefix: "Rp. ",
            style: {
              marginRight: "2%",
            },
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            label: t.translate(`words.jumlahBarang`),
            key: "jumlahBarang",
            thousandSeparator: ".",
            decimalSeparator: ",",
            isNumericString: true,
            type: "text",
            width: "48%",

            style: {
              marginLeft: "2%",
            },
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            label: t.translate(`words.hargaTotal`),
            key: "hargaTotal",
            thousandSeparator: ".",
            decimalSeparator: ",",
            isNumericString: true,

            type: "text",
            prefix: "Rp. ",
            width: "100%",
            disabled: true,
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            label: t.translate(`words.diskon`),
            key: "isDiskon",
            thousandSeparator: ".",
            decimalSeparator: ",",
            isNumericString: true,
            type: "text",
            suffix: " %",
            width: "12%",
            validation: "required",
          },
          {
            inputType: inputTypes.CHECKBOX,
            label: `Harga Termasuk PPn`,
            key: "isPpn",
            name: "isPpn",
            style: {
              width: "60%",
              marginLeft: "28%",
            },
            disabled: dataPenggunaNpwp.pkp === false ? true : false,
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            label: "Jumlah",
            key: "jumlah",
            thousandSeparator: ".",
            decimalSeparator: ",",
            isNumericString: true,
            type: "text",
            prefix: "Rp. ",
            disabled: true,
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            label: "PPN",
            key: "ppn",
            thousandSeparator: ".",
            decimalSeparator: ",",
            isNumericString: true,
            type: "text",
            prefix: "Rp. ",
            disabled: true,
          },
          {
            inputType: inputTypes.INPUT,
            label: "Keterangan",
            key: "keterangan",
            type: "text",
          },
        ]}
        onChange={async (formData, key, value) => {
          // console.log(formData)
          if (key === "produkId") {
            listProduk.map((d) => {
              if (d.value === value) {
                formData["produk"] = {
                  nama: d.label,
                  kode: d.kode,
                  id: d.value,
                };
              }
            });
          }
          if (key === "hargaSatuan") {
            formData.hargaTotal =
              formData.hargaSatuan * formData.jumlahBarang.toFixed(2);
            formData.jumlah =
              formData.hargaTotal -
              (formData.hargaTotal * (formData.isDiskon / 100)).toFixed(2);
            if (formData.isPpn === true) {
              //jika harga termasuk ppn
              formData.diskon = (
                (formData.isDiskon / 100) *
                ((100 / 111) * formData.hargaTotal)
              ).toFixed(2);
              if (formData !== 0) {
                //dengan diskon
                formData.ppn =
                  dataPenggunaNpwp.pkp === false
                    ? 0
                    : (
                        ((100 / 111) * formData.hargaTotal -
                          (formData.isDiskon / 100) *
                            ((100 / 111) * formData.hargaTotal)) *
                        TARIF_PPN
                      ).toFixed(2);
              } else {
                //tanpa diskon
                formData.ppn =
                  dataPenggunaNpwp.pkp === false
                    ? 0
                    : ((100 / 111) * formData.hargaTotal * TARIF_PPN).toFixed(
                        2
                      );
              }
              formData.subtotal = (100 / 111) * formData.hargaTotal.toFixed(2);
            } else {
              //jika harga tidak termasuk ppn
              formData.diskon =
                formData.hargaTotal * (formData.isDiskon / 100).toFixed(2);
              formData.ppn =
                dataPenggunaNpwp.pkp === false
                  ? 0
                  : (
                      (formData.hargaTotal - formData.diskon) *
                      TARIF_PPN
                    ).toFixed(2);
              formData.subtotal = formData.jumlah;
            }
          }
          if (key === "jumlahBarang") {
            formData.hargaTotal =
              formData.hargaSatuan * formData.jumlahBarang.toFixed(2);
            formData.jumlah =
              formData.hargaTotal -
              (formData.hargaTotal * (formData.isDiskon / 100)).toFixed(2);
            formData.dpp = formData.jumlah.toFixed(2);

            if (formData.isPpn === true) {
              //jika harga termasuk ppn
              formData.diskon = (
                (formData.isDiskon / 100) *
                ((100 / 111) * formData.hargaTotal)
              ).toFixed(2);
              if (formData !== 0) {
                //dengan diskon
                formData.ppn =
                  dataPenggunaNpwp.pkp === false
                    ? 0
                    : (
                        ((100 / 111) * formData.hargaTotal -
                          (formData.isDiskon / 100) *
                            ((100 / 111) * formData.hargaTotal)) *
                        TARIF_PPN
                      ).toFixed(2);
              } else {
                //tanpa diskon
                formData.ppn =
                  dataPenggunaNpwp.pkp === false
                    ? 0
                    : ((100 / 111) * formData.hargaTotal * TARIF_PPN).toFixed(
                        2
                      );
              }
              formData.subtotal = (100 / 111) * formData.hargaTotal;
            } else {
              //jika harga tidak termasuk ppn
              formData.diskon =
                formData.hargaTotal * (formData.isDiskon / 100).toFixed(2);
              formData.ppn =
                dataPenggunaNpwp.pkp === false
                  ? 0
                  : (
                      (formData.hargaTotal - formData.diskon) *
                      TARIF_PPN
                    ).toFixed(2);
              formData.subtotal = formData.jumlah;
            }
          }
          if (key === "isDiskon") {
            formData.isDiskon = String(value).replace(/\D/g, "");
            if (value.length > 3) {
              formData.isDiskon = value.substring(0, 3);
            }
            if (value > 100) {
              formData.isDiskon = 100;
            }
            formData.jumlah =
              formData.hargaTotal -
              (formData.hargaTotal * (formData.isDiskon / 100)).toFixed(2);
            formData.dpp = formData.jumlah;
            if (formData.isPpn === true) {
              //jika harga termasuk ppn
              formData.diskon = (
                (formData.isDiskon / 100) *
                ((100 / 111) * formData.hargaTotal)
              ).toFixed(2);
              if (formData !== 0) {
                //dengan diskon
                formData.ppn =
                  dataPenggunaNpwp.pkp === false
                    ? 0
                    : (
                        ((100 / 111) * formData.hargaTotal -
                          (formData.isDiskon / 100) *
                            ((100 / 111) * formData.hargaTotal)) *
                        TARIF_PPN
                      ).toFixed(2);
              } else {
                //tanpa diskon
                formData.ppn =
                  dataPenggunaNpwp.pkp === false
                    ? 0
                    : ((100 / 111) * formData.hargaTotal * TARIF_PPN).toFixed(
                        2
                      );
              }
              formData.subtotal = (100 / 111) * formData.hargaTotal.toFixed(2);
            } else {
              //jika harga tidak termasuk ppn
              formData.diskon =
                formData.hargaTotal * (formData.isDiskon / 100).toFixed(2);
              formData.ppn =
                dataPenggunaNpwp.pkp === false
                  ? 0
                  : (
                      (formData.hargaTotal - formData.diskon) *
                      TARIF_PPN
                    ).toFixed(2);
              formData.subtotal = formData.jumlah;
            }
          }

          if (key === "isPpn") {
            formData.hargaTotal = formData.hargaSatuan * formData.jumlahBarang;
            formData.jumlah =
              formData.hargaTotal -
              (formData.hargaTotal * (formData.isDiskon / 100)).toFixed(2);
            if (formData.isPpn === true) {
              //jika harga termasuk ppn
              formData.diskon = (
                (formData.isDiskon / 100) *
                ((100 / 111) * formData.hargaTotal)
              ).toFixed(2);
              if (formData !== 0) {
                //dengan diskon
                formData.ppn =
                  dataPenggunaNpwp.pkp === false
                    ? 0
                    : (
                        ((100 / 111) * formData.hargaTotal -
                          (formData.isDiskon / 100) *
                            ((100 / 111) * formData.hargaTotal)) *
                        TARIF_PPN
                      ).toFixed(2);
              } else {
                //tanpa diskon
                formData.ppn =
                  dataPenggunaNpwp.pkp === false
                    ? 0
                    : ((100 / 111) * formData.hargaTotal * TARIF_PPN).toFixed(
                        2
                      );
              }
              formData.subtotal = ((100 / 111) * formData.hargaTotal).toFixed(
                2
              );
            } else {
              //jika harga tidak termasuk ppn
              formData.diskon =
                formData.hargaTotal * (formData.isDiskon / 100).toFixed(2);
              formData.ppn =
                dataPenggunaNpwp.pkp === false
                  ? 0
                  : (
                      (formData.hargaTotal - formData.diskon) *
                      TARIF_PPN
                    ).toFixed(2);
              formData.subtotal = formData.jumlah;
            }
          }
          setItem(formData);
          return formData;
        }}
        onSubmit={(data, callback) => {
          if (data.hargaSatuan == 0) {
            callback("Harga Satuan Tidak Boleh 0", true, false);
          } else if (data.jumlahBarang == 0) {
            callback("Jumlah Barang Tidak Boleh 0", true, false);
          } else {
            if (data.isPpnbm == 1) {
              data.isPpnbm = true;
            } else {
              data.isPpnbm = false;
              data.ptsPpnbm = "0";
              data.ppnbm = "0";
            }
            // console.log(data)
            localStorage.setItem("produkForm", JSON.stringify(data));
            callback("success", false);
          }
        }}
      />
    )
  );
};

export default PenjualanProdukForm;
