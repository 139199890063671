import React, { useState, useEffect } from "react";
import t from "counterpart";
import {
  Table,
  TableBody,
  TableHeader,
  TableRow,
  TableCell,
  Button,
  FontIcon,
  TableFooter,
} from "react-md";
import KasDanBankTambahEditTagihanPenerimaan from "./KasDanBankTambahEditTagihanPenerimaan";
import { FormWrapper } from "../../../libs/react-mpk/wrapper";
import { DataForm, Hint } from "../../../libs/react-mpk/components";
import service from "../KasDanBank.service";
import { cloneDeep, find } from "lodash";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import utilsService from "../../../services/utilsService";
import { format } from "../../../libs/react-mpk/services/number.service";

const defaultData = () => ({
  pasalPemotonganId: "PPh 4(2)",
  objekPajakId: "P001 - Omset",
  isNilaiLain2: "0",
  kasBankAkun: {
    nilaiPiutangOrHutang: 0,
    isPajakSetorSendiriOrPotongPajak: false,
    isPajakDitanggung: false,
    nilaiLain: 0,
    isNilaiLain2: null,
    nominalPajak: 0,
    nilaiTagihanOrPenerimaan: 0,
    pctTarifPajak: 0,
    akun: {
      id: null,
      monitoringLawanTransaksi: null,
    },
    jenisPenghasilan: {
      id: 1,
    },
    pasalPemotongan: {
      id: 1,
    },
    objekPajak: {
      id: 1,
    },
  },
});

const KasDanBankTambahEditInputDetailAkunUangPenerimaan = ({
  visible = false,
  authStore,
  envStore,
  match,
  history,
  modalStore,
  onRequestClose = () => {},
  ...props
}) => {
  const { inputTypes, definition } = FormWrapper;
  const [ready, setReady] = useState(true);
  let [item, setItem] = useState({
    pasalPemotonganId: "PPh 4(2)",
    isNilaiLain2: "1",
    kasBankAkun: {
      nilaiPiutangOrHutang: 0,
      isPajakSetorSendiriOrPotongPajak: false,
      nilaiLain: 0,
      isPajakDitanggung: false,
      isNilaiLain2: 0,
      nominalPajak: 0,
      nilaiTagihanOrPenerimaan: 0,
      pctTarifPajak: 0,
      akun: {
        id: null,
      },
      jenisPenghasilan: {
        id: 1,
      },
      pasalPemotongan: {
        id: 1,
      },
      objekPajak: {
        id: 1,
      },
    },
    kasBankAkunFkList: [],
  });

  const [dialogDaftarTagihanPenerimaan, setDialogDaftarTagihanPenerimaan] =
    useState(false);

  const [objekPajakList, setObjekPajakList] = useState([]);
  const [pasalPemotonganList, setPasalPemotonganList] = useState([]);
  const [akun, setAkun] = useState([]);
  const [tempAkun, setTempAkun] = useState([]);

  const [deleteAkuTagihan, setDeleteAkuTagihan] = useState([]);
  const [dataTagihan, setDataTagihan] = useState([]);
  const [subtotal, setSubtotal] = useState([]);

  const [showUtangForm, setShowUtangForm] = useState(false);
  const [showBebanForm, setShowBebanForm] = useState(true);

  var produkData = [
    {
      nomorTransaksi: "PB-0001/01/2021",
      nominal: "20000000",
    },
  ];

  function addItem() {
    setDialogDaftarTagihanPenerimaan(true);
  }

  async function akunTransaksi() {
    let res = await service.getAkunPenerimaan();
    var tr = [];
    res.data.map((d) => {
      tr.push({
        value: d.id,
        label: `${d.kode} - ${d.nama}`,
        kode: `${d.kode}`,
        nama: `${d.nama}`,
        kategoriDebitKredit: `${d.kategoriAkun.debitKredit}`,
      });
    });
    setAkun(tr);
    setTempAkun(res.data);
  }

  async function pasalPemotongan() {
    let res = await service.getPasal();
    var pasal = [];
    res.data.map((d) => {
      pasal.push({
        value: d.id,
        label: d.nama,
        nama: `${d.nama}`,
      });
    });
    setPasalPemotonganList(pasal);
  }

  async function objekPajak() {
    let res = await service.getObjek();
    var objek = [];
    res.data.map((d) => {
      objek.push({
        value: d.id,
        label: `${d.kode} - ${d.nama}`,
        kode: `${d.kode}`,
        nama: `${d.nama}`,
      });
    });
    setObjekPajakList(objek);
  }

  async function getSubtotal() {
    setTimeout(async () => {
      let res = await service.getAkunSubtotal();
      var subtotals = [];
      res.data.map((d) => {
        subtotals.push({
          value: d.id,
          label: `${d.kode} - ${d.nama}`,
          kode: `${d.kode}`,
          nama: `${d.nama}`,
          monitoringLawanTransaksi: `${d.monitoringLawanTransaksi}`,
        });
      });
      setSubtotal(subtotals);
    }, 500);
  }

  async function akunTagihan() {
    var akunTagihanForm = JSON.parse(
      localStorage.getItem("kasBankAkunFkListForm")
    );
    var tempItem = { ...item };
    if (akunTagihanForm) {
      var dataTagihans = dataTagihan;
      dataTagihans.push(akunTagihanForm);
      tempItem.kasBankAkunFkList = dataTagihans;
      setItem(tempItem);
      localStorage.removeItem("kasBankAkunFkListForm");
    }
  }

  async function hapusTagiahn(index) {
    modalStore.showConfirm({
      title: t.translate("words.delete"),
      children: t.translate("words.confirmHapus"),
      onSubmit: (callback) => {
        var deleteIndex = dataTagihan;
        deleteIndex.splice(index, 1);
        setDataTagihan(deleteIndex);
        callback();
      },
    });
  }

  useEffect(() => {
    objekPajak();
    akunTagihan();
    pasalPemotongan();
    akunTransaksi();
    getSubtotal();
    if (visible) {
      let selectedItem = defaultData();
      if (props.item) {
        selectedItem = cloneDeep(props.item);
      }
      // if (dataTagihan.length > 0) {
      //   selectedItem.nilaiPiutangOrHutang = dataTagihan.sum("sisaTagihanAkhir");
      //   selectedItem.nilaiTagihanOrPenerimaan = dataTagihan.sum(
      //     "nilaiTagihanYangTelahDibayarkan"
      //   );
      // }
      setItem(selectedItem);
    } else {
      setItem(defaultData());
    }
    setReady(visible);
  }, [visible]);

  return (
    ready && (
      <>
        <DataForm
          baseId="kas-dan-bank-input-detail-akun"
          title={t.translate(
            `modules.KasDanBank.tambahEditInputDetailAkunPiutang`
          )}
          visible={visible}
          onRequestClose={onRequestClose}
          asDialog={true}
          submitLabel={t.translate(`words.submit`)}
          defaultData={item}
          definitions={[
            {
              inputType: inputTypes.AUTOCOMPLETE,
              label: "Nama Akun",
              key: "akunId",
              validation: "required",
              data: subtotal,
              labelKey: "label",
              valueKey: "label",
            },
            {
              render: (
                <div style={{ width: "100%" }}>
                  <Hint
                    className="mpk-margin-N margin-top margin-bottom"
                    message="Tagihan"
                  />
                  <Button
                    theme="primary"
                    themeType="contained"
                    style={{ margin: 15 }}
                    onClick={() => addItem()}
                  >
                    <FontIcon iconClassName="mdi mdi-plus" />
                    {t.translate("words.add")}
                  </Button>
                  <Table style={{ width: "100%" }}>
                    <TableHeader>
                      <TableRow>
                        <TableCell style={{ width: "10%" }}>
                          {t.translate("words.non")}
                        </TableCell>
                        <TableCell style={{ width: "25%" }}>
                          {t.translate("words.nomorTransaksi")}
                        </TableCell>
                        <TableCell style={{ width: "25%" }}>
                          {t.translate("words.sisaTagihanAwal")}
                        </TableCell>
                        <TableCell style={{ width: "25%" }}>
                          {t.translate("words.sisaTagihanAwal")}
                        </TableCell>
                        <TableCell style={{ width: "25%" }}>
                          {t.translate(
                            "words.nilaiTagihanOrPenerimaanYangTelahDibayarkan"
                          )}
                        </TableCell>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {dataTagihan.map((d, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>
                              {utilsService.beautifyNoTransaksi(
                                d.nomorTransaksi
                              )}
                            </TableCell>
                            <TableCell>
                              Rp. {format(d.sisaTagihanAwal)}
                            </TableCell>
                            <TableCell>
                              Rp. {format(d.sisaTagihanAkhir)}
                            </TableCell>
                            <TableCell>
                              Rp. {format(d.nilaiTagihanYangTelahDibayarkan)}
                            </TableCell>
                            <TableCell>
                              <Button onClick={() => hapusTagiahn(index)}>
                                {t.translate("words.delete")}
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TableCell>
                          <b>{t.translate(`words.nilaiUtang`)}</b>
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          {
                            (Array.prototype.sum = function (prop) {
                              var total = 0;
                              for (
                                var i = 0, _len = this.length;
                                i < _len;
                                i++
                              ) {
                                total += this[i][prop];
                              }
                              return total;
                            })
                          }
                          <b>
                            Rp. {format(dataTagihan.sum("sisaTagihanAkhir"))}
                          </b>
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                  <br />
                </div>
              ),
              show: showUtangForm,
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: t.translate(`words.nilaiUtang`),
              key: "nilaiPiutangOrHutang",
              disabled: true,
              value: dataTagihan.sum("nilaiTagihanYangTelahDibayarkan"),
              thousandSeparator: ".",
              decimalSeparator: ",",
              isNumericString: true,
              show: (d)=>{return d.kasBankAkun?.akun?.monitoringLawanTransaksi =="true"},
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: "Nilai Beban",
              key: "nilaiPiutangOrHutang",
              thousandSeparator: ".",
              decimalSeparator: ",",
              isNumericString: true,
              show: (d)=>{return d.kasBankAkun?.akun?.monitoringLawanTransaksi =="false"},
            },
            {
              render: (
                <div style={{ width: "100%" }}>
                  <p>
                    <b>{t.translate(`words.pajakDisetorSendiri`)}</b>
                  </p>
                </div>
              ),
            },
            {
              inputType: inputTypes.CHECKBOX,
              label: t.translate(`words.transaksiTerutangPajak`),
              key: "isPajakDitanggung",
            },
            {
              inputType: inputTypes.DIVIDER,
              label: t.translate(`words.transaksiTerutangPajak`),
              show: (d) => {
                return d.isPajakDitanggung;
              },
            },
            {
              inputType: inputTypes.CHECKBOX,
              label: t.translate(`words.apakahSobatMenyetorkanSendiri`),
              key: "isPajakSetorSendiriOrPotongPajak",
              show: (d) => {
                return d.isPajakDitanggung;
              },
            },
            {
              inputType: inputTypes.AUTOCOMPLETE,
              label: "Pemotongan Pajak",
              className: "mpk-padding-N padding-right",
              key: "pasalPemotonganId",
              data: pasalPemotonganList,
              labelKey: "label",
              valueKey: "label",
              width: "50%",
              show: (d) => {
                return d.isPajakDitanggung;
              },
              disabled: true,
            },
            {
              inputType: inputTypes.AUTOCOMPLETE,
              label: t.translate(`words.atasTransaksi`),
              key: "objekPajakId",
              data: objekPajakList,
              labelKey: "label",
              valueKey: "label",
              width: "50%",
              show: (d) => {
                return d.isPajakDitanggung;
              },
              disabled: true,
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              className: "mpk-padding-N padding-right",
              label: "Tarif Pajak (%)",
              key: "pctTarifPajak",
              value: 0.5,
              disabled: true,
              show: (d) => {
                return d.isPajakDitanggung;
              },
              thousandSeparator: ".",
              decimalSeparator: ",",
            },
            {
              inputType: inputTypes.SELECT,
              label: "Perhitungan Pajak Berdasarkan",
              key: "isNilaiLain2",
              options: [
                {
                  value: 0,
                  label: "Nilai Piutang",
                },
                {
                  value: 1,
                  label: "Nilai Lain",
                },
              ],
              show: (d) => {
                return d.isPajakDitanggung;
              },
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: "Nilai Lain",
              key: "nilaiLain",
              thousandSeparator: ".",
              decimalSeparator: ",",
              isNumericString: true,
              show: (d) => {
                return d.isNilaiLain2 == 1 && d.isPajakDitanggung;
              },
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: t.translate(`words.nominalPajak`),
              className: "mpk-padding-N padding-right",
              key: "nominalPajak",
              thousandSeparator: ".",
              decimalSeparator: ",",
              isNumericString: true,
              disabled: true,
              show: (d) => {
                return d.isPajakDitanggung;
              },
              width: "48%",
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: t.translate(`words.nilaiTagihan`),
              key: "nilaiTagihanOrPenerimaan",
              disabled: true,
              thousandSeparator: ".",
              decimalSeparator: ",",
              width: "48%",
              style: {
                marginRight: "2%",
              },
            },
            {
              render: (
                <div style={{ width: "100%" }}>
                  <p style={{ margin: "10px" }}>
                    <b>Informasi Tambahan</b>
                  </p>
                </div>
              ),
            },
            {
              inputType: inputTypes.TEXTAREA,
              label: t.translate(`words.keterangan`),
              key: "keterangan",
            },
          ]}
          onChange={async (formData, key, value) => {
            let pajak1 = (formData.nilaiLain * 0.5) / 100;
            let pajak2 = (formData.nilaiPiutangOrHutang * 0.5) / 100;
            if (key === "pasalPemotonganId") {
              pasalPemotonganList.map((d) => {
                if (d.label === value) {
                  formData["pasalPemotongan"] = {
                    id: d.value,
                    nama: d.label,
                  };
                }
              });
            }

            if (key === "objekPajakId") {
              objekPajakList.map((d) => {
                if (d.label === value) {
                  formData["objekPajak"] = {
                    id: d.value,
                    kode: d.kode,
                    nama: d.nama,
                  };
                }
              });
            }

            if (key === "akunId") {
              subtotal.map((d) => {
                if (d.label === value) {
                  formData.kasBankAkun.akun.id = d.value;
                  formData.kasBankAkun.akun.monitoringLawanTransaksi =
                    d.monitoringLawanTransaksi;
                  sessionStorage.setItem("akunSubtotalIdPenerimaan", d.value);
                }
              });

              if (
                formData.kasBankAkun.akun.monitoringLawanTransaksi === "true"
              ) {
                setShowUtangForm(true);
                setShowBebanForm(false);
              } else {
                setShowUtangForm(false);
                setShowBebanForm(true);
              }
            }

            if (key == "nilaiPiutangOrHutang") {
              formData.total = formData.nilaiBeban;
              formData.nilaiPenjualan = formData.nilaiBeban;
            }

            if (formData.isPajakDitanggung) {
              if (formData.isPajakSetorSendiriOrPotongPajak) {
                if (formData.isNilaiLain2 == 1) {
                  formData.nominalPajak = pajak1;
                  formData.nilaiTagihanOrPenerimaan = formData.nilaiPiutangOrHutang  ;
                } else {
                  formData.nominalPajak = pajak2;
                  formData.nilaiTagihanOrPenerimaan = formData.nilaiPiutangOrHutang ;
                  formData.nilaiLain = formData.nilaiPiutangOrHutang
                }
              }

              if (!formData.isPajakSetorSendiriOrPotongPajak) {
                if (formData.isNilaiLain2 == 1) {
                  formData.nominalPajak = pajak1;
                  formData.nilaiTagihanOrPenerimaan = formData.nilaiPiutangOrHutang - formData.nominalPajak;
                } else {
                  formData.nominalPajak = pajak2;
                  formData.nilaiTagihanOrPenerimaan = formData.nilaiPiutangOrHutang - formData.nominalPajak;
                  formData.nilaiLain = formData.nilaiPiutangOrHutang
                }
              }
            } else {
              formData.nilaiTagihanOrPenerimaan = formData.nilaiPiutangOrHutang;
            }
            setItem(formData);
            return formData;
          }}
          onSubmit={async (data, callback) => {
            data.kasBankAkunFkList = dataTagihan;
            data.kasBankAkun.isNilaiLain2 = parseInt(data.isNilaiLain2);
            localStorage.setItem("akunPenerimaanForm", JSON.stringify(data));
            localStorage.removeItem("kasBankAkunFkListForm");
            setDataTagihan([]);
            callback("success", false);
          }}
        />
        <KasDanBankTambahEditTagihanPenerimaan
          visible={dialogDaftarTagihanPenerimaan}
          onRequestClose={() => {
            akunTagihan();
            setDialogDaftarTagihanPenerimaan(false);
          }}
          itemId={sessionStorage.getItem("akunSubtotalIdPenerimaan")}
          lawanTr={sessionStorage.getItem("lawanTransaksiIdPenerimaan")}
        />
      </>
    )
  );
};

export default inject("modalStore")(
  observer(KasDanBankTambahEditInputDetailAkunUangPenerimaan)
);
