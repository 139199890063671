import React, { useEffect, useState } from "react";
import { FormWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import { useParams } from "react-router-dom";
import service from "./Marketplace.service";
import { toast } from "../../libs/react-mpk/services";

const MarketplaceDetail = ({}) => {
  const { id } = useParams();
  const [data, setdata] = useState(null);

  const fetchData = async () => {
    try {
      let res = await service.getOneByIdKategori(id);
      setdata(res.data);
    } catch (error) {
      toast.errorRequest(error)
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <FormWrapper
      baseId="market-place-detail"
      showCommandbar={true}
      title={"Detail Marketplace"}
      defaultData={data}
      definitions={[
        {
          inputType: FormWrapper.inputTypes.INPUT,
          label: t.translate(`words.kode`),
          key: "kode",
          disabled: true,
        },
      ]}
    />
  );
};

export default MarketplaceDetail;
