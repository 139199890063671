import React, { useEffect, useState } from "react";
import { inputTypes } from "../../../libs/react-mpk/config/constant";
import { FormWrapper } from "../../../libs/react-mpk/wrapper";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import {
  Table,
  TableBody,
  TableHeader,
  TableRow,
  TableCell,
  Button,
  FontIcon,
  TableFooter,
} from "react-md";
import { find } from "lodash";
import KasDanBankTambahEditInputDetailAkunUangPenerimaan from "./KasDanBankTambahEditInputDetailAkunUangPenerimaan";
import { DataForm, Hint } from "../../../libs/react-mpk/components";
import KasDanBankTambahEditInputDetailAkunBeban from "./KasDanBankTambahEditInputDetailAkunBeban";
import service from "../KasDanBank.service";
import moment from "moment";
import 'moment/locale/id';
import { format, formatRupiah } from "../../../libs/react-mpk/services/number.service";
let npwpHeadId = sessionStorage.getItem("npwpHeadId");

const defaultData = () => ({
  tanggalTransaksi: "",
  lawanTransaksiId: null,
  lawanTransaksi: {
    id: null,
  },
  akunId: null,
  akun: {
    id: null,
  },
  subTotal: 0,
  totalPajakSetorSendiri: "0",
  nilaiDibayar: "0",
  keterangan: "",
  npwpHead: {
    id: null,
  },
  kasBankList: [],
  fileLampiran: null,
  lampiran: {
    fileLampiranInstance: null,
    fileLampiran: null,
    fileLampiranAlias: null,
  },
});

const KasDanBankPenerimaanNonPPN = ({
  visible = false,
  authStore,
  envStore,
  match,
  history,
  modalStore,
  navigationStore,
  onRequestClose = () => {},
  ...props
}) => {
  const { inputTypes, definition } = FormWrapper;
  const [dialogInputDetailAkun, setDialogInputDetailAkun] = useState(false);
  const [dialogInputDetailAkunBeban, setDialoginputDetailBeban] =
    useState(false);
  const [lawanTransaksiList, setLawanTransaksiList] = useState([]);
  const [akunList, setAkunList] = useState([]);
  const [dataAkunSubtotal, setDataAkunSubtotal] = useState([]);

  const backTo = `/internal/npwp/${npwpHeadId}/kas-dan-bank`;
  const [allLampiran, setAllLampiran] = useState({});

  let [dataSubmit, setDataSubmit] = useState({
    kasBank: {
      tanggalTransaksi: "",
      lawanTransaksi: {
        id: null,
      },
      akun: {
        id: null,
      },
      subTotal: 0,
      totalPajakSetorSendiri: "0",
      nilaiDibayar: "0",
      keterangan: "",
      npwpHead: {
        id: npwpHeadId,
      },
      fileLampiranInstance: null,
      fileLampiran: null,
      fileLampiranAlias: null,
    },
    kasBankList: [],
  });
  let [item, setItem] = useState({});
  let [ready, setReady] = useState(true);

  useEffect(() => {
    async function initData() {
      if (visible) {
        let selectedItem = defaultData();
        setItem(selectedItem);
      } else {
        setItem(defaultData());
      }
      setReady(visible);
    }

    initData();
    getLawanTransaksiList();
    getAkun();
  }, [visible]);

  async function getLawanTransaksiList() {
    setTimeout(async () => {
      let res = await service.getLawanTransaksi();
      var lawantransaksis = [];
      res.data.map((d) => {
        lawantransaksis.push({
          value: d.id,
          label: `${d.npwp} - ${d.nama}`,
        });
      });
      setLawanTransaksiList(lawantransaksis);
    }, 500);
  }

  async function getAkun() {
    setTimeout(async () => {
      let res = await service.getAkunPenerimaan();
      var akuns = [];
      res.data.map((d) => {
        akuns.push({
          value: d.id,
          label: `${d.kode} - ${d.nama}`,
        });
      });
      setAkunList(akuns);
    }, 500);
  }

  async function akunSubtotal() {
    var akunSubTotalData = JSON.parse(
      localStorage.getItem("akunPenerimaanForm")
    );

    if (akunSubTotalData) {
      var dataAkunSubtotals = dataAkunSubtotal;
      dataAkunSubtotals.push(akunSubTotalData);
      setDataAkunSubtotal(dataAkunSubtotals);
      localStorage.removeItem("akunPenerimaanForm");
    }
  }

  async function hapusAkunSubTotal(index) {
    modalStore.showConfirm({
      title: t.translate("words.delete"),
      children: t.translate("words.confirmHapus"),
      onSubmit: (callback) => {
        var deleteIndex = dataAkunSubtotal;
        deleteIndex.splice(index, 1);
        setDataAkunSubtotal(deleteIndex);
        callback();
      },
    });
  }

  const [dialogPenjualanBebanForm, setDialogPenjualanBebanForm] =
    useState(false);
  const [dialogPenjualanProdukForm, setDialogPenjualanProdukForm] =
    useState(false);
  const [dialogSetorSendiriForm, setDialogSetorSendiriForm] = useState(false);
  const [dialogData, setDialogData] = useState(false);
  var produkData = [
    {
      namaAkun: "Utang Usaha",
      kodeAkun: "2.1.01.0001",
      nominal: "30000000",
    },
    {
      namaAkun: "Beban Jasa",
      kodeAkun: "6.1.01.0009",
      nominal: "20000000",
    },
  ];
  function addItem() {
    setDialogInputDetailAkun(true);
    setDialogData({});
  }

  function addItemBeban() {
    setDialogPenjualanBebanForm(true);
    setDialogData({});
  }
  return (
    <>
      <FormWrapper
        backTo={backTo}
        showCommandbar={true}
        baseId="mod-kas-dan-bank-penerimaan-non-ppn"
        title={t.translate(`modules.KasDanBank.PenerimaanNonPPN`)}
        style={{
          maxWidth: "100%",
        }}
        submitLabel={t.translate(`words.submit`)}
        visible={visible}
        defaultData={item}
        definitions={[
          {
            inputType: inputTypes.DATEPICKER,
            label: t.translate(`words.tglTransaksi`),
            key: "tanggalTransaksi",
          },
          {
            render: (
              <div style={{ width: "100%" }}>
                <p style={{ margin: "10px" }}>
                  <b>{t.translate(`words.lawanTransaksiDanSumberDana`)}</b>
                </p>
              </div>
            ),
          },
          {
            inputType: inputTypes.AUTOCOMPLETE,
            label: t.translate(`words.lawanTransaksi`),
            key: "lawanTransaksiId",
            labelKey: "label",
            valueKey: "label",
            type: "search",
            data: lawanTransaksiList,
            validation: "required",
          },
          {
            inputType: inputTypes.AUTOCOMPLETE,
            label: t.translate(`words.transferke`),
            key: "akunId",
            labelKey: "label",
            valueKey: "label",
            type: "search",
            data: akunList,
            validation: "required",
          },
          {
            render: (
              <div style={{ width: "100%" }}>
                <p style={{ margin: "10px" }}>
                  <b>{t.translate(`words.informasiTambahan`)}</b>
                </p>
              </div>
            ),
          },

          {
            inputType: inputTypes.INPUT,
            label: t.translate(`words.keterangan`),
            key: "keterangan",
            type: "text",
            style: {
              marginRight: "2%",
            },
          },
          {
            inputType: inputTypes.FILE_INPUT,
            label: t.translate("words.file"),
            key: "fileLampiran",
            type: "file",
            label: "Lampiran",
          },
          {
            render: (
              <div style={{ width: "100%" }}>
                <Hint
                  className="mpk-margin-N margin-top margin-bottom"
                  message="Akun Subtotal"
                />
                <Button
                  theme="primary"
                  themeType="contained"
                  style={{ margin: 15 }}
                  onClick={() => addItem()}
                >
                  <FontIcon iconClassName="mdi mdi-plus" />
                  {t.translate("words.add")}
                </Button>
                <Table style={{ width: "100%" }}>
                  <TableHeader>
                    <TableRow>
                      <TableCell style={{ width: "10%" }}>
                        {t.translate("words.non")}
                      </TableCell>
                      <TableCell style={{ width: "25%" }}>
                        {t.translate("words.namaAkun")}
                      </TableCell>
                      {/* <TableCell style={{ width: '25%' }}>{t.translate('words.kodeAkun')}</TableCell> */}
                      <TableCell style={{ width: "25%" }}>
                        {t.translate("words.nominal")}
                      </TableCell>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {dataAkunSubtotal.map((d, index) => {
                      // console.log(dataAkunSubtotal);
                      return (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{d.akunId}</TableCell>
                          <TableCell>
                            {formatRupiah(d.nilaiTagihanOrPenerimaan)}
                          </TableCell>
                          <TableCell>
                            <Button onClick={() => hapusAkunSubTotal(index)}>
                              {t.translate("words.delete")}
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell>
                        <b>{t.translate(`words.nilaiUtang`)}</b>
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell>
                        {
                          (Array.prototype.sum = function (prop) {
                            var total = 0;
                            for (var i = 0, _len = this.length; i < _len; i++) {
                              total += this[i][prop];
                            }
                            return total;
                          })
                        }
                        <b>
                          {formatRupiah(
                            dataAkunSubtotal.sum("nilaiTagihanOrPenerimaan")
                          )}
                        </b>
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
                <br />
              </div>
            ),
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            label: t.translate(`words.pajakDisetorSendiri`),
            key: "totalPajakSetorSendiri",
            // width: '49%',
            value: dataAkunSubtotal
              .filter((d) => d.isPajakSetorSendiriOrPotongPajak == true)
              .sum("nominalPajak"),
            thousandSeparator: ".",
            decimalSeparator: ",",
            isNumericString: true,
            validation: "required",
            disabled: true,
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            label: t.translate(`words.nilaiYangDibayar`),
            key: "nilaiDibayar",
            value: dataAkunSubtotal.sum("nilaiTagihanOrPenerimaan"),
            type: "text",
            disabled: true,
            thousandSeparator: ".",
            decimalSeparator: ",",
            isNumericString: true,
          },
        ]}
        onChange={async (formData, key, value) => {
          if (key == "lawanTransaksiId") {
            lawanTransaksiList.map((d) => {
              if (d.label == value) {
                formData["lawanTransaksi"]["id"] = d.value;
                sessionStorage.setItem("lawanTransaksiIdPenerimaan", d.value);
              }
            });
          }
          if (key == "akunId") {
            akunList.map((d) => {
              if (d.label == value) {
                formData["akun"]["id"] = d.value;
              }
            });
          }
          if (dataAkunSubtotal) {
            formData.nilaiDibayar = dataAkunSubtotal.sum(
              "nilaiTagihanOrPenerimaan"
            );
          }
          setItem(formData);
          return formData;
        }}
        onSubmit={async (data, callback) => {
          let resGambar;
          if (data.fileLampiran != null) {
            resGambar = await service.upload(data.fileLampiran);
          }
          let reqData = dataSubmit;
          reqData.kasBank.tanggalTransaksi = moment(
            data.tanggalTransaksi
          ).format("YYYY-MM-DD hh:mm:ss");
          reqData.kasBank.lawanTransaksi = data.lawanTransaksi;
          reqData.kasBank.akun = data.akun;
          reqData.kasBank.subTotal = dataAkunSubtotal.sum(
            "nilaiTagihanOrPenerimaan"
          );
          reqData.kasBank.totalPajakSetorSendiri = dataAkunSubtotal
            .filter((d) => d.isPajakDitanggung == true)
            .sum("nominalPajak") || 0;
          reqData.kasBank.totalNominalPajak = dataAkunSubtotal
            .map((d) => d.nominalPajak)
            .reduce((acc, total) => acc + total, 0) || 0;
          reqData.kasBank.nilaiDibayar = data.nilaiDibayar;
          reqData.kasBank.keterangan = data.keterangan;
          reqData.kasBank.subTotal = data.subTotal;
          reqData.kasBank.fileLampiran = data.fileLampiran != null? resGambar.data.namaFileAlias:null;
          reqData.kasBank.fileLampiranAlias = data.fileLampiran != null? resGambar.data.namaFileAlias:null;
          reqData.kasBank.fileLampiranInstance = data.fileLampiran != null?resGambar.data.instanceId :null;
          reqData.kasBankList = dataAkunSubtotal;

          try {
            let res = await service.createPenerimaan(reqData);
            let { data, status } = res;

            if (status == 200) {
              callback("success", false);
              navigationStore.redirectTo(backTo);
            }
          } catch (error) {
            callback(error, true, false);
          }
        }}
      />
      <KasDanBankTambahEditInputDetailAkunUangPenerimaan
        visible={dialogInputDetailAkun}
        onRequestClose={() => {
          akunSubtotal();
          setDialogInputDetailAkun(false);
        }}
      />
      <KasDanBankTambahEditInputDetailAkunBeban
        visible={dialogInputDetailAkunBeban}
        onRequestClose={() => {
          setDialoginputDetailBeban(false);
        }}
      />
    </>
  );
};

export default inject(
  "envStore",
  "navigationStore",
  "modalStore"
)(observer(KasDanBankPenerimaanNonPPN));
