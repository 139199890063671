import React, { useEffect, useState } from "react";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import service from "./TutupBuku.service";
import moment from "moment";
import 'moment/locale/id';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "@react-md/dialog";
import { Button } from "@react-md/button";
import { useToggle } from "@react-md/utils";
import { Text } from "@react-md/typography";
import AddTutupBuku from "./AddTutupBuku";
import { inject, observer } from "mobx-react";
import iziToast from "izitoast";
import errorService from "../../services/errorService";
import { toast } from "../../libs/react-mpk/services";
import { useHistory } from "react-router-dom";
import { format, formatRupiah } from "../../libs/react-mpk/services/number.service";

const TutupBuku = ({
  className = "",
  history,
  modalStore,
  navigationStore,
  showCommandbar = true,
}) => {
  const [showForm, setShowForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  let [search, setSearch] = useState({});
  let npwpHeadId = sessionStorage.getItem("npwpHeadId");
  let pphId = 1;
  const baseId = "tutup_buku";

  async function hapusExc(item) {
    try {
      var r = await service.deleteById(item.id);
      // if(r.data && r.data.status == 1) {
      // window.open(r.data.data.url)
      // iziToast.success({
      //     message : r.data.message
      // })
      // } else {
      // iziToast.info({
      //     message : r.data.message
      // })
      // }
      iziToast.success({ message: "Berhasil Dihapus" });
      TableWrapper.reload(baseId);
      // document.getElementsByClassName('mdi-reload')[0].click()
    } catch (e) {
      toast.errorRequest(e);
    }
  }
  async function hapus(item) {
    modalStore.showConfirm({
      title: t.translate("words.delete"),
      children: t.translate("words.confirmHapus"),
      onSubmit: (callback) => {
        hapusExc(item);
        callback();
      },
    });
  }

  return (
    <>
      <TableWrapper
        baseId={baseId}
        title={t.translate(`modules.tutupBuku.title`)}
        className={className}
        hintMessage={t.translate("modules.tutupBuku.hint.message")}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate("modules.pembelian.hint.more")}
        defaultData={[]}
        defaultSortBy="tanggalDibuat"
        isShowFilter={false}
        showActionColumn={true}
        useFilter={false}
        defaultCollapse={true}
        useCriteria={true}
        selectable={false}
        useQueryHistory={false}
        onFetchData={(query) => {
          return new Promise(async (resolve, reject) => {
            try {
              query = {
                ...search,
                ...query,
              };
              let getPage = await service.getPage();
              // setData(getPage.data)
              getPage.headers["x-pagination-count"] =
                getPage.headers["x-total-count"];
              resolve(getPage);
            } catch (e) {
              resolve();
              // ErrorService(e)
            }
          });
        }}
        columns={[
          {
            label: t.translate(`words.periodeTutupBuku`),
            searchable: true,
            sortable: true,
            key: "periodeAwal",
            render: (item) =>
              "01/01/" + item.tahun + " - " + "31/12/" + item.tahun,
          },
          {
            label: t.translate(`words.labaKomersial`),
            searchable: true,
            key: "labaKomersial",
            render: (item) => ` ${formatRupiah(item.labaKomersial)}`,
          },
          {
            label: t.translate(`words.statusTutupBuku`),
            type: TableWrapper.dataTypes.BOOLEAN,
            render: (item) => (
              <TableWrapper.Status
                type={
                  item.status == 1
                    ? TableWrapper.Status.types.IDLE
                    : item.status == 2
                    ? TableWrapper.Status.types.SUCCESS
                    : item.status == 3
                    ? TableWrapper.Status.types.ERROR
                    : item.status == 4
                    ? TableWrapper.Status.types.ERROR
                    : ""
                }
              >
                {item.status == 1
                  ? "Diproses"
                  : item.status == 2
                  ? "Selesai"
                  : item.status == 3
                  ? "Error"
                  : item.status == 4
                  ? "Batal"
                  : ""}
              </TableWrapper.Status>
            ),
          },
        ]}
        actions={[
          new TableWrapper.action(
            "Add",
            "mdi mdi-plus",
            () => {
              setSelectedItem(null);
              setShowForm(true);
            },
            true
          ),
        ]}
        itemActions={[
          new TableWrapper.action(
            `${t.translate("words.selengkapnya")}`,
            "mdi mdi-import",
            (item) => {
              navigationStore.redirectTo(
                `/internal/npwp/${npwpHeadId}/tutup-buku/${item.id}`
              );
            },
            true
          ),
          new TableWrapper.action(
            "Neraca",
            "mdi mdi-application-export",
            (item) => {
              history.push({
                pathname: `/internal/npwp/${npwpHeadId}/report-neraca`,
                state: { passing: true,tahun:item.tahun,harta:false,utangModal:false },
              });
            }
          ),
          new TableWrapper.action(
            "Laporan Laba Rugi Komersial",
            "mdi mdi-file-chart-outline",
            (item) => {
              //   window.location.href = `report-laba-rugi`;
              history.push({
                pathname: `/internal/npwp/${npwpHeadId}/report-laba-rugi`,
                state: {tahun:item.tahun },
              });
            }
          ),
          new TableWrapper.action(
            "Neraca Saldo",
            "mdi mdi-chart-line",
            (item) => {
              //   window.location.href = `report-laba-rugi`;
              history.push({
                pathname: `/internal/npwp/${npwpHeadId}/report-neraca-saldo`,
                state: {tahun:item.tahun },
              });
            }
          ),
          new TableWrapper.action(
            "Kredit Pajak dan Pajak Terutang",
            "mdi mdi-credit-card-check-outline",
            (item) => {
              history.push({
                pathname: `/internal/npwp/${npwpHeadId}/pph/${pphId}/periode/${item.tahun}`,
                state: { tutupbuku: true,tahun:item.tahun },
              });

              sessionStorage.setItem("tahunTutupBuku", item.tahun);
            }
          ),
          new TableWrapper.action(
            "Hapus Periode Tutup Buku",
            "mdi mdi-delete",
            (item) => {
              hapus(item);
            }
          ),
        ]}
        showCommandbar={showCommandbar}
        showFilterPeriod={true}
      />
      <AddTutupBuku
        visible={showForm}
        onRequestClose={() => {
          setShowForm(false);
          setSelectedItem(null);
        }}
        item={selectedItem}
      />
    </>
  );
};

export default inject("modalStore", "navigationStore")(observer(TutupBuku));
