import React, { useEffect, useState } from "react";
import { inputTypes } from "../../../libs/react-mpk/config/constant";
import { FormWrapper } from "../../../libs/react-mpk/wrapper";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import {
  Table,
  TableBody,
  TableHeader,
  TableRow,
  TableCell,
  Button,
  FontIcon,
  TableFooter,
} from "react-md";
import { find } from "lodash";
import KasDanBankTambahEditInputDetailAkunUang from "./KasDanBankTambahEditInputDetailAkunUang";
import { DataForm, Hint } from "../../../libs/react-mpk/components";
import KasDanBankTambahEditInputDetailAkunBeban from "./KasDanBankTambahEditInputDetailAkunBeban";
import service from "../KasDanBank.service";
import moment from "moment";
import 'moment/locale/id';
import { format } from "../../../libs/react-mpk/services/number.service";
let npwpHeadId = sessionStorage.getItem("npwpHeadId");

const defaultData = () => ({
  tanggalTransaksi: "",
  lawanTransaksiId: null,
  lawanTransaksi: {
    id: null,
  },
  akunId: null,
  akun: {
    id: null,
  },
  subTotal: 0,
  totalNominalPajakDitanggung: null,
  totalNominalPajak: null,
  nilaiDibayar: 0,
  keterangan: "",
  npwpHead: {
    id: null,
  },
  kasBankList: [],
  lampiran: {
    fileLampiranInstance: null,
    fileLampiran: null,
    fileLampiranAlias: null,
  },
});

const KasDanBankPengeluaranNonPPN = ({
  visible = false,
  authStore,
  envStore,
  match,
  history,
  modalStore,
  navigationStore,
  onRequestClose = () => {},
  ...props
}) => {
  const { inputTypes, definition } = FormWrapper;
  const [dialogInputDetailAkun, setDialogInputDetailAkun] = useState(false);
  const [dialogInputDetailAkunBeban, setDialoginputDetailBeban] =
    useState(false);
  const [lawanTransaksiList, setLawanTransaksiList] = useState([]);
  const [disabledLawanTransaksi, setDisabledLawanTransaksi] = useState(true);
  const [disabledAkunDari, setDisabledAkunDari] = useState(true);
  const [akunList, setAkunList] = useState([]);
  const [dataAkunSubtotal, setDataAkunSubtotal] = useState([]);
  const [selectedItemId, setSelectedItemId] = useState(null);

  const backTo = `/internal/npwp/${npwpHeadId}/kas-dan-bank`;
  const [allLampiran, setAllLampiran] = useState({});

  let [dataSubmit, setDataSubmit] = useState({
    kasBank: {
      tanggalTransaksi: "",
      lawanTransaksi: {
        id: null,
      },
      akun: {
        id: null,
      },
      subTotal: 0,
      // totalPajakSetorSendiri: 0,
      totalNominalPajak: 0,
      nilaiDibayar: 0,
      keterangan: "",
      npwpHead: {
        id: npwpHeadId,
      },

      fileLampiranInstance: null,
      fileLampiran: null,
      fileLampiranAlias: null,
    },
    kasBankList: [],
  });
  let [item, setItem] = useState({});
  let [ready, setReady] = useState(true);
  let [tempValue, setTempValue] = useState(0);
  let [tempVal, setTempVal] = useState(0);

  useEffect(() => {
    async function initData() {
      // if (visible) {
      let selectedItem = defaultData();
      setItem(selectedItem);

      // } else {
      // 	setItem(defaultData())
      // }
    }

    initData();
    getLawanTransaksiList();
    getAkun();
  }, []);

  async function getLawanTransaksiList() {
    setDisabledLawanTransaksi(true)
      let res = await service.getLawanTransaksiSupplier();
      var lawantransaksis = [];
      res.data.map((d) => {
        lawantransaksis.push({
          value: d.id,
          label: `${d.npwp} - ${d.nama}`,
        });
      });
      setLawanTransaksiList(lawantransaksis);
      setDisabledLawanTransaksi(false)
  }

  async function getAkun() {
    setDisabledAkunDari(true)
      let res = await service.getAkunPengeluaran();
      var akuns = [];
      res.data.map((d) => {
        akuns.push({
          value: d.id,
          label: `${d.kode} - ${d.nama}`,
        });
      });
      setAkunList(akuns);
      setDisabledAkunDari(false)
  }

  async function akunSubtotal() {
    var akunSubTotalData = JSON.parse(
      localStorage.getItem("akunPengeluaranForm")
    );
    if (akunSubTotalData) {
      var dataAkunSubtotals = dataAkunSubtotal;

      dataAkunSubtotals.push(akunSubTotalData);
      setDataAkunSubtotal(dataAkunSubtotals);

      let temp = 0;
      let temp2 = 0;
      let tempPajak = 0;
      let tempPajak2 = 0;
      dataAkunSubtotal.map((d) => {
        if (d.isPajakDitanggung) {
          temp += d.tagihan;
          tempPajak += d.nominalPajak;
        } else {
          temp2 += d.tagihan;
          tempPajak2 += d.nominalPajak;
        }
        setTempVal(tempVal + d.tagihan);
        setTempValue(tempValue + d.nominalPajak);
      });
      localStorage.removeItem("akunPengeluaranForm");
    }
  }

  async function hapusAkunSubTotal(index) {
    modalStore.showConfirm({
      title: t.translate("words.delete"),
      children: t.translate("words.confirmHapus"),
      onSubmit: (callback) => {
        var deleteIndex = dataAkunSubtotal;

        setTempVal(tempVal - deleteIndex[index].nilaiTagihanOrPenerimaan);
        setTempValue(tempValue - deleteIndex[index].nominalPajak);
        deleteIndex.splice(index, 1);
        setDataAkunSubtotal(deleteIndex);
        callback();
      },
    });
  }

  const [dialogPenjualanBebanForm, setDialogPenjualanBebanForm] =
    useState(false);
  const [dialogPenjualanProdukForm, setDialogPenjualanProdukForm] =
    useState(false);
  const [dialogSetorSendiriForm, setDialogSetorSendiriForm] = useState(false);
  const [dialogData, setDialogData] = useState(false);
  var produkData = [
    {
      namaAkun: "Utang Usaha",
      kodeAkun: "2.1.01.0001",
      nominal: "30000000",
    },
    {
      namaAkun: "Beban Jasa",
      kodeAkun: "6.1.01.0009",
      nominal: "20000000",
    },
  ];
  function addItem() {
    setDialogInputDetailAkun(true);
    setDialogData({});
  }

  function addItemBeban() {
    setDialogPenjualanBebanForm(true);
    setDialogData({});
  }
  var onSubmitData = async (data, callback) => {
    let resGambar;
    if (data.fileLampiran != null) {
      resGambar = await service.uploadPengeluaran(data.fileLampiran);
    }
    let reqData = dataSubmit;
    reqData.kasBank.akun = data.akun;
    reqData.kasBank.lawanTransaksi = data.lawanTransaksi;
    reqData.kasBank.keterangan = data.keterangan;
    reqData.kasBankList = dataAkunSubtotal;
    reqData.kasBank.nilaiDibayar = data.nilaiDibayar;
    reqData.kasBank.subTotal = dataAkunSubtotal.sum("tagihan");
    reqData.kasBank.totalNominalPajak = dataAkunSubtotal.sum("nominalPajak")||0
    reqData.kasBank.totalPajakSetorSendiri = dataAkunSubtotal.filter((d) => d.isPajakDitanggung == true).sum("nominalPajak")||0
    reqData.kasBank.tanggalTransaksi = moment(data.tanggalTransaksi).locale('id').format(
      "yyyy-MM-DD hh:mm:ss"
    );

    reqData.kasBank.fileLampiran =
      data.fileLampiran != null ? resGambar.data.namaFile : null;
    reqData.kasBank.fileLampiranAlias =
      data.fileLampiran != null ? resGambar.data.namaFileAlias : null;
    reqData.kasBank.fileLampiranInstance =
      data.fileLampiran != null ? resGambar.data.instanceId : null;
    try {
      let res = await service.createPengeluaran(reqData);
      let { data, status } = res;
      if (status == 200) {
        callback("success", false);
        navigationStore.redirectTo(backTo);
      }
    } catch (error) {
      callback(error, true, false);
    }
  };
  return (
    <>
      <FormWrapper
        backTo={backTo}
        showCommandbar={true}
        baseId="mod-kas-dan-bank-pengeluaran-non-ppn"
        title={t.translate(`modules.KasDanBank.PengeluaranNonPPN`)}
        style={{
          maxWidth: "100%",
        }}
        submitLabel={t.translate(`words.submit`)}
        visible={visible}
        defaultData={item}
        definitions={[
          {
            inputType: inputTypes.DATEPICKER,
            label: t.translate(`words.tglTransaksi`),
            key: "tanggalTransaksi",
          },
          {
            render: (
              <div style={{ width: "100%" }}>
                <p style={{ margin: "10px" }}>
                  <b>{t.translate(`words.lawanTransaksiDanSumberDana`)}</b>
                </p>
              </div>
            ),
          },
          {
            inputType: inputTypes.AUTOCOMPLETE,
            label: t.translate(`words.lawanTransaksi`),
            key: "lawanTransaksiId",
            labelKey: "label",
            valueKey: "label",
            data: lawanTransaksiList,
            validation: "required",
            disabled:disabledLawanTransaksi
          },
          {
            inputType: inputTypes.AUTOCOMPLETE,
            label: t.translate(`words.transferDari`),
            key: "akunId",
            labelKey: "label",
            valueKey: "label",
            type: "search",
            data: akunList,
            validation: "required",
            disabled:disabledAkunDari
          },
          {
            render: (
              <div style={{ width: "100%" }}>
                <p style={{ margin: "10px" }}>
                  <b>{t.translate(`words.informasiTambahan`)}</b>
                </p>
              </div>
            ),
          },
          {
            inputType: inputTypes.INPUT,
            label: t.translate(`words.keterangan`),
            key: "keterangan",
            type: "text",
            style: {
              marginRight: "2%",
            },
          },
          {
            inputType: inputTypes.FILE_INPUT,
            label: t.translate("words.file"),
            key: "fileLampiran",
            type: "file",
            // validation: 'required',
            // multiple: true,
            // multiline: true,
            accept: ".jpg,.jpeg,.png",
          },
          {
            render: (
              <div style={{ width: "100%" }}>
                <Hint
                  className="mpk-margin-N margin-top margin-bottom"
                  message="Akun Subtotal"
                />
                <Button
                  theme="primary"
                  themeType="contained"
                  style={{ margin: 15 }}
                  onClick={() => addItem()}
                >
                  <FontIcon iconClassName="mdi mdi-plus" />
                  {t.translate("words.add")}
                </Button>
                <Table style={{ width: "100%" }}>
                  <TableHeader>
                    <TableRow>
                      <TableCell style={{ width: "10%" }}>
                        {t.translate("words.non")}
                      </TableCell>
                      <TableCell style={{ width: "25%" }}>
                        {t.translate("words.namaAkun")}
                      </TableCell>
                      {/* <TableCell style={{ width: '25%' }}>{t.translate('words.kodeAkun')}</TableCell> */}
                      <TableCell style={{ width: "25%" }}>
                        {t.translate("words.nominal")}
                      </TableCell>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {dataAkunSubtotal.map((d, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{d.akunId}</TableCell>
                          <TableCell>{d.tagihan}</TableCell>
                          {/* <TableCell>{d.nominal}</TableCell> */}
                          <TableCell>
                            {/* <Button onClick={() => console.log("Masuk")}>{t.translate("words.edit")}</Button> */}
                            <Button onClick={() => hapusAkunSubTotal(index)}>
                              {t.translate("words.delete")}
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell>
                        <b>
                          {dataAkunSubtotal.nilaiPiutangOrHutang
                            ? t.translate(`words.nilaiTagihanOrPenerimaan`)
                            : "Nilai Beban"}
                        </b>
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell>
                        {
                          (Array.prototype.sum = function (prop) {
                            var total = 0;
                            for (var i = 0, _len = this.length; i < _len; i++) {
                              total += this[i][prop];
                            }
                            return total;
                          })
                        }
                        <b>{dataAkunSubtotal.sum("nilaiTagihanOrPenerimaan")}</b>
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
                <br />
              </div>
            ),
          },
          // {
          // 	inputType: inputTypes.INPUT_MASK_NUMBER,
          // 	label: t.translate(`words.pajakDisetorSendiri`),
          // 	key: 'totalPajakSetorSendiri',
          // 	width: '49%',
          // 	style: {
          // 		marginRight: "2%"
          // 	},
          // 	thousandSeparator: ".",
          // 	decimalSeparator: ",",
          // 	isNumericString: true,
          // 	validation: 'required'
          // },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            label: t.translate(`words.pemotonganPajakDitanggung`),
            key: "totalNominalPajakDitanggung",
            width: "49%",
            style: {
              marginRight: "2%",
            },
            value: dataAkunSubtotal
            .filter((d) => d.isPajakDitanggung == true)
            .sum("nominalPajak"),
            thousandSeparator: ".",
            decimalSeparator: ",",
            isNumericString: true,
            disabled: true,
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            label: t.translate(`words.totalNominalPajak`),
            key: "totalNominalPajak",
            width: "49%",
            value: dataAkunSubtotal
            .sum("nominalPajak"),
            thousandSeparator: ".",
            decimalSeparator: ",",
            isNumericString: true,
            disabled: true,
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            label: t.translate(`words.nilaiYangDibayar`),
            key: "nilaiDibayar",
            value: `${dataAkunSubtotal.sum("nilaiTagihanOrPenerimaan")}`,
            disabled: true,
            thousandSeparator: ".",
            decimalSeparator: ",",
            isNumericString: true,
          },
        ]}
        onChange={async (formData, key, value) => {
          // console.log(formData)
          if (key == "lawanTransaksiId") {
            lawanTransaksiList.map((d) => {
              if (d.label == value) {
                // console.log(formData, "label");
                formData["lawanTransaksi"]["id"] = d.value;
                sessionStorage.setItem("lawanTransaksiId", d.value);
                // console.log(formData['akunFrom']['id'])
                // console.log(formData);
              }
            });
          }
          if (key == "akunId") {
            akunList.map((d) => {
              if (d.label == value) {
                formData["akun"]["id"] = d.value;
              }
            });
          }
          if (dataAkunSubtotal) {
            if (dataAkunSubtotal.isPajakDitanggung == true) {
              formData.totalNominalPajakDitanggung =
                dataAkunSubtotal.sum("nominalPajak");
              formData.totalNominalPajak = dataAkunSubtotal.sum("nominalPajak");
              formData.nilaiDibayar = dataAkunSubtotal.sum(
                "nilaiTagihanOrPenerimaan"
              );
            } else if (dataAkunSubtotal.isPajakDitanggung == false) {
              formData.totalNominalPajakDitanggung =
                dataAkunSubtotal.sum("nominalPajak");
              formData.totalNominalPajak = dataAkunSubtotal.sum("nominalPajak");
              formData.nilaiDibayar = dataAkunSubtotal.sum(
                "nilaiTagihanOrPenerimaan"
              );
            }
          }
          // if (key == 'totalNominalPajak') {
          // 	formData.nilaiDibayar = (formData.totalNominalPajak + formData.totalNominalPajakDitanggung)
          // }
          // if (key === "fileLampiran") {
          //   let res = await service.uploadPengeluaran(value);
          //   let { data } = res;
          //   formData.lampiran.fileLampiran = data.namaFile;
          //   formData.lampiran.fileLampiranAlias = data.namaFileAlias;
          //   formData.lampiran.fileLampiranInstance = data.instanceId;
          // }
          setItem(formData);
          return formData;
        }}
        onSubmit={onSubmitData}
      />
      <KasDanBankTambahEditInputDetailAkunUang
        visible={dialogInputDetailAkun}
        onRequestClose={() => {
          akunSubtotal();
          setDialogInputDetailAkun(false);
          setSelectedItemId(null);
        }}
        itemId={selectedItemId}
      />
      <KasDanBankTambahEditInputDetailAkunBeban
        visible={dialogInputDetailAkunBeban}
        onRequestClose={() => {
          setDialoginputDetailBeban(false);
        }}
      />
    </>
  );
};

export default inject(
  "envStore",
  "navigationStore",
  "modalStore"
)(observer(KasDanBankPengeluaranNonPPN));
