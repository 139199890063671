import React, { useEffect, useState } from 'react';
import { DataForm } from '../../libs/react-mpk/components';
import t from 'counterpart'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import ErrorService from '../../services/errorService'
// import service from './Enofa.service'
import { cloneDeep } from 'lodash'
import { disable, Text } from 'react-md';
import service from './Pembelian.service';

const defaultData = () => ({
	akunId: "",
	akun: {
		id: null,
		kode: null,
		nama: null
	},
	debitKredit:0,
	nilaiBeban: 0,
	total: 0,
	keterangan: "",
	isPotongPajak:false
})

const InputDetailAkunBeban = ({
	visible = false,
	authStore,
	envStore,
	match,
	history,
	onRequestClose = () => { },
	...props
}) => {
	const { inputTypes, definition } = FormWrapper
	
	let [dataSubmit, setDataSubmit] = useState({
		id: null,
		akun: {
			id: null,
			kode: null,
			nama: null
		},
		nilaiBeban: 0,
		isPotongPajak: false,
		ptsTarifPajak: 0,
		isNilaiPembelian: false,
		nominalHitungPajak: 0,
		isPajakDitanggung: false,
		nominalPajakDitanggung: 0,
		nominalPajak: 0,
		total: 0,
		keterangan: "",
		pasalPemotongan: null,
		objekPajak: null,
		debitkredit:0
		
	})
	let [item, setItem] = useState(null)
	let [ready, setReady] = useState(true)
	var thousandSeparator = "."
	var decimalSeparator  = ","
	const [akunBebanList, setAkunBebanList] = useState([])

	useEffect(() => {
		initData()
	}, [])

	async function initData(){
		let selectedItem = defaultData();

			// if(match.params.id != 'new'){
			// 	try {

			// 		// let res = await service.getOneById(match.params.id)
					
			// 		// let { data } = res;
					
			// 		// setDataSubmit(data);

			// 		// selectedItem.id = data.id
					

			// 	} catch(e){}
			// }
			// else{
				
			// }
		setItem(selectedItem);
		getListAkunBeban();
		setReady(true);
	}

	async function getListAkunBeban(){
	  setTimeout(async ()=> {
		let res = await service.getListAkunBeban()
		var akunBebans = []
		res.data.map((d)=> {
			akunBebans.push({
				value: d.id,
				label: `${d.kode} - ${d.nama}`,
				kode: `${d.kode}`,
				nama: `${d.nama}`,
				kategoriDebitKredit: `${d.kategoriAkun.debitKredit}`
			})
		})
		setAkunBebanList(akunBebans)
	  }, 500)
	}

	const onSubmit = async (data, callback) => {
		
		let reqData = dataSubmit;
		
		//ISI FIELD
		reqData.akun = data.akun
		reqData.nilaiBeban = data.nilaiBeban
		reqData.total = data.total
		reqData.keterangan = data.keterangan
		reqData.isPotongPajak = false
		reqData.ptsTarifPajak= 0
		reqData.isNilaiPembelian= false
		reqData.nominalHitungPajak= 0
		reqData.isPajakDitanggung= false
		reqData.nominalPajakDitanggung= 0
		reqData.nominalPajak= 0
		reqData.pasalPemotongan= null
		reqData.objekPajak= null

		localStorage.setItem("akunBebanForm", JSON.stringify(data))
		callback('success', false)
		// callback('error', true, false);
	}

	return ready && (
		<DataForm
			baseId="akun-npwp-form"
			title={t.translate(`modules.pembelian.inputAkunBeban`)}
			visible={visible}
			// data={item}
			onRequestClose={onRequestClose}
			asDialog={true}
			defaultData={item ? cloneDeep(item) : defaultData()}
			definitions={[
				{
					inputType: inputTypes.AUTOCOMPLETE,
					label: t.translate(`words.akun`),
					key: 'akunId',
					validation: 'required',
					labelKey: 'label',
					valueKey: 'label',
					type : 'search',
					data: akunBebanList,
					required: true
				},
				{
					inputType: inputTypes.INPUT_MASK_NUMBER,
					label: 'Nilai Beban',
					key: 'nilaiBeban',
					thousandSeparator: thousandSeparator,
					decimalSeparator: decimalSeparator,
					type: 'text',
					width: '48%',
					style: {
						marginRight: "2%"
                    },
                    required: true
                },
                {
					inputType: inputTypes.INPUT_MASK_NUMBER,
					label: 'Nilai Tagihan',
					key: 'total',
					thousandSeparator: thousandSeparator,
					decimalSeparator: decimalSeparator,
					type: 'text',
					width: '48%',
					style: {
						marginLeft: "2%"
                    },
                    disabled: true
				},
				{
					render:(
						<Text style={{width:'100%'}}>Beban/Pendapat termasuk ?</Text>
					)
				  },
				  {
					inputType: inputTypes.RADIO,
					label: "Debit",
					key: "debitKredit",
					value:1
				  },
				  {
					inputType: inputTypes.RADIO,
					label: "Kredit",
					key: "debitKredit",
					value:2
				  },
				{
					render: (
						<div style={{ width: '100%' }}>
							<p style={{ margin: "10px" }}><b>Informasi Tambahan</b></p>
						</div>
					)
				},
				{
					inputType: inputTypes.TEXTAREA,
					label: 'Keterangan',
					key: 'keterangan'
				}
			]}
			onChange={async (formData, key, value) => {
										
				if(key == 'akunId'){
					akunBebanList.map((d)=> {
						if(d.label == value){
							formData['akun']['id'] = d.value
							formData['akun']['kode'] = d.kode
							formData['akun']['nama'] = d.nama
							// formData['akun']['kategoriAkun']['debitKredit'] = d.kategoriDebitKredit
						}
					})
				}
				
				if(key == 'nilaiBeban'){
					formData['total'] = value
				}
				// return value
			}}
			// onBeforeChange={(key, value) => {
			// 	console.log(key, value)
			// 	return value
			// }}
			onSubmit={onSubmit}
		/>
	)
}

export default InputDetailAkunBeban