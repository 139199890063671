import React, { useEffect, useState } from 'react';
import { DataForm } from '../../libs/react-mpk/components';
import t from 'counterpart'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import service from './KategoriUnit.service'

const defaultData = () => ({
	id: null,
	kode: null,
	nama: null
})

const KategoriForm = ({
	visible = false,
	authStore,
	envStore,
	onRequestClose = () => { },
	...props
}) => {
	let [ready, setReady] = useState(true)
	let [generateKode, setGenerateKode] = useState([])
	let [item, setItem] = useState(null)
	let [messageError, setMessageError] = useState(false)
	let [showDialogError, setShowDialogError] = useState(false)
	let [dataSubmit, setDataSubmit] = useState({
		id: null,
		kode: "",
		nama: "",
	})
	useEffect(() => {
		async function initData(){
			if(visible){
				let selectedItem = defaultData();
				if(props.itemId){
					try {
						let res = await service.getOneByIdKategori(props.itemId)
						let{data}=res
						setDataSubmit(data)
						selectedItem.id = data.id
						selectedItem.kode = data.kode
						selectedItem.nama = data.nama
						
					} catch (error) {
						setMessageError(`${error}`)
						setShowDialogError(true)
						onRequestClose()
					}
				}
				else{
					let ress = await service.getKategoriKode()
					setGenerateKode(ress.data)
					if(ress.status == 200){
						selectedItem.kode = ress.data.message
					}
				}
				setItem(selectedItem)
			}else{
				setItem(defaultData())
			}
			setReady(visible)
		}
		initData()
	}, [visible])

	return ready && (
		<DataForm
			baseId="produk-kategori-form"
			title={t.translate(`modules.kategoriUnit.kategori.form`)}
			visible={visible}
			onRequestClose={onRequestClose}
			asDialog={true}
			defaultData={item}
			definitions={[
				{
					inputType: DataForm.inputTypes.INPUT,
					label: t.translate(`words.kode`),
					key: 'kode',
					disabled: true,
				},
				{
					inputType: DataForm.inputTypes.INPUT,
					label: t.translate(`words.namaKategori`),
					key: 'nama',
					validation: 'required',
				},
			]}
			onSubmit={async(data, callback) => {
				let reqData = dataSubmit
				reqData.id = data.id
				reqData.kode = data.kode
				reqData.nama = data.nama
				if(reqData.id){

					try{
						const res = await service.editKategori(reqData.id, reqData);
						let { data, status } = res;
						callback('success', false)
					}
					catch (err){
						// console.log(err, 'err')
						callback(err, true, false);
					}
					
				}
				else{
		
					try{
						const res = await service.postKategori(reqData);
						callback('success', false)
					}
					catch (err){
						callback(err, true, false);
					}
			  
				}
			}}
		/>
	)
}

export default KategoriForm