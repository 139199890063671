import { crud, http } from "../../libs/react-mpk/services";

let service = null;
let serviceUrl = `/pph`;
let serviceEbilling = `/e_billing`;
let npwpHeadIdObject = {
  npwpHeadId: sessionStorage.getItem("npwpHeadId"),
};
let npwpHeadId = sessionStorage.getItem("npwpHeadId");
let tahunSobatPajak = sessionStorage.getItem("tahunSobatBuku");
service = new crud(`/pph`);

service.getPPhByPeriodeId = async () => {
  let query = {
    page: 0,
    size: 999,
    sort: "tanggalDibuat,DESC",
    "noJurnal.contains": "",
    "periodePphId.equals": sessionStorage.getItem("idPph"),
    npwpHeadId: npwpHeadId,
  };
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}`,
    query,
  });
};
service.createGetPeriode = async () => {
  let data = {
    npwpHeadId: npwpHeadId,
    objekPajakId: 1,
    tahun: tahunSobatPajak,
  };
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/periode/create_and_get`,
    data,
  });
};
service.createGetPeriodeByTahunTutupBuku = async (data) => {
  data = {
    npwpHeadId: npwpHeadId,
    objekPajakId: 1,
    ...data,
  };
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/periode/create_and_get`,
    data,
  });
};
service.createOmzetPPh = async (data) => {
  return http.request({
    method: http.methods.PUT,
    url: `/pengaturan/data_awal/omzet`,
    data,
  });
};

service.getAllLawanTransaksi = async (query) => {
  query = {
    ...query,
    ...npwpHeadIdObject,
  };
  return http.request({
    method: http.methods.GET,
    url: `/lawan_transaksi`,
    query,
  });
};
service.getAllPenjualan = async (query) => {
  query = {
    ...query,
    ...npwpHeadIdObject,
  };
  return http.request({
    method: http.methods.GET,
    url: `/penjualan`,
    query,
  });
};

service.getObjekPajak = async (query) => {
  return http.request({
    method: http.methods.GET,
    url: `/objek_pajak`,
    query,
  });
};
service.getJenisPajak = async (queryNama) => {
  let query = {
    page: 0,
    size: 15,
    sort:'kode,ASC',
    "kode.contains": queryNama,
  };
  return http.request({
    method: http.methods.GET,
    url: `/jenis_pajak`,
    query,
  });
};
service.getJenisSetoran = async (id) => {
  let query = {
    page: 0,
    size: 9999,
    "kodeJenisPajak.equals": id,
  };
  return http.request({
    method: http.methods.GET,
    url: `/jenis_setoran`,
    query,
  });
};
service.createIdBilling = async (data) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceEbilling}`,
    data,
  });
};
service.generateMpn = async (idbilling) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceEbilling}/generate_from_kode_billing/${idbilling}`,
  });
};
service.getPageBilling = async (query) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceEbilling}`,
    query,
  });
};
service.getPageHistory = async (query) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceEbilling}/histori_transaksi`,
    query,
  });
};
service.LembagaPersepsi = async (query) => {
  return http.request({
    method: http.methods.GET,
    url: `/lembaga_persepsi`,
    query,
  });
};
service.createBuktiBayar = async (data) => {
  return http.request({
    method: http.methods.POST,
    url: `/bukti_bayar`,
    data,
  });
};
service.sendEmail = async (id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/setoran_pajak/${id}`,
  });
};
service.download = async (eBillingId) => {
  return http.download(
    `${serviceUrl}/donwload_eBilling?eBillingId=${eBillingId}`
  );
};
service.sendEmailBulk = async (listData) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/setoran_pajak_multiple?id=${listData}`
  });
};
service.sendEmailpp23 = async (tahun) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/penghasilan_bruto_pp23?npwpHeadId=${npwpHeadId}&tahun=${tahun}`,
  });
};

export default service;
