import React, { useEffect, useState } from 'react';
import { inputTypes } from '../../libs/react-mpk/config/constant'
import { FormWrapper, TableWrapper } from '../../libs/react-mpk/wrapper'
import { Hint } from '../../libs/react-mpk/components'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { find } from 'lodash'
import { ExpansionList, ExpansionPanel, Table, TableBody, TableHeader, TableRow, TableCell, Card, Button, FontIcon, TableFooter, Text, Divider, TextContainer } from 'react-md'
import Modal from '../../libs/react-mpk/components/Modal'
import service from './Pembelian.service';
import moment from 'moment';
import servicePPN from "../PPN/PPN.service";
import InputDetailAkunPembelian from "./InputDetailAkunPembelian";
import InputDetailAkunBeban from "./InputDetailAkunBeban";
import lawanTransaksiService from '../LawanTransaksi/LawanTransaksi.service'
import { format } from '../../libs/react-mpk/services/number.service';
import PembelianCocokkanData from './PembelianCocokkanData';
import { toast } from '../../libs/react-mpk/services';

const KATEGORI_TRANSAKSI_NORMAL = "1";
const KATEGORI_TRANSAKSI_UANG_MUKA = "2";
const KATEGORI_TRANSAKSI_PELUNASAN = "3";

const KATEGORI_PEMBELIAN = "1";
const KATEGORI_BIAYA = "2";

const kredit = [
    { value: 'ya', label: 'Iya' },
    { value: 'tidak', label: 'Tidak' }
]



const STAT_BELUM_BAYAR = 1;
const STAT_BAYAR_SEBAGIAN = 2;
const STAT_LUNAS = 3;
const STAT_BATAL = 4;

const DAPAT_KREDIT = '1';
const TIDAK_DAPAT_KREDIT = '2';

const DEBIT = '1'
const KREDIT = '2'

const defaultData = () => ({
    lawanTransaksiId: '',
    lawanTransaksiNama: '',
    lawanTransaksiNik: '',
    lawanTransaksiNpwp: '',
    lawanTransaksiAlamat: '',
    lawanTransaksiNamaPic: '',
    lawanTransaksiEmailPic: '',
    lawanTransaksiNoTeleponPic: '',
    lawanTransaksiAlamatPic: '',
    lawanTransaksi: {
        id: null
    },
    kategoriTransaksi: KATEGORI_TRANSAKSI_NORMAL,
    tanggalTransaksi: '',
    statusPembayaran: STAT_LUNAS,
    akunUtangId: '',
    akunUtang: {
        id: null
    },
    periodeJatuhTempo: '',
    tanggalJatuhTempo: '',
    subtotal: 0,
    keterangan:'',
    subtotalAkun: 0,
    total: 0,
    totalPajakSetor: 0,
    totalBeban: 0,
    totalPembelian: 0,
    fileLampiranInstance: null,
    fileLampiran: null,
    fileLampiranAlias: null,
    jenisFakturKode: '0',
    jenisFakturKeterangan: '1 - Faktur Pajak Normal',
})
const PembelianPPNIdentitas = ({
    envStore,
    modalStore,
    match,
    navigationStore,
    ...props }) => {
    const [data, setData] = useState({ loading: true, content: {} })
    const [panel1, setPanel1] = useState(false)
    const [panel2, setPanel2] = useState(false)
    const [panel3, setPanel3] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [isHidden, setIsHidden] = useState(false)
    const [dialogPembelianBebanForm, setDialogPembelianBebanForm] = useState(false)
    const [dialogPembelianProdukForm, setDialogPembelianProdukForm] = useState(false)
    const [dialogSetorSendiriForm, setDialogSetorSendiriForm] = useState(false)
    const [dialogData, setDialogData] = useState(false)
    const [dokumens, setDokumens] = useState([])
    const [dataLawanTransaksi, setDataLawanTransaksi] = useState([])
    const [potonganPajakList, setPotongPajakList] = useState([])
    const [showDialogCocokkanData, setShowDialogCocokkanData] = useState(false);
    const [cocokkanLawanTransaksi, setCocokkanLawanTransaksi] = useState([]);
    const [listakunUtang, setListakunUtang] = useState([])
    const [listTransaksi, setListTransaksi] = useState([])
    const [listKeterangan, setListKeterangan] = useState([])
    const [listAkunKasBank, setListAkunKasBank] = useState([])

    const [lawanTr, setLawanTr] = useState([])
    const [dataTr, setDataTr] = useState([])

    let [item, setItem] = useState(null)

    const [totalProduk, setTotalProduk] = useState([])

    const [statusLunas, setStatusLunas] = useState(false)

    // VALIDASI
    const [inputValidasiNpwp, setInputValidasiNpwp] = useState('##.###.###.#-###.###')
    const [inputValidasiNik, setInputValidasiNik] = useState('################')

    const [disabledField, setDisabledField] = useState(true)

    const NILAI_Pembelian = false;
    const NILAI_Pembelian_SETOR_SENDIRI = true;



    const [dataBeban, setDataBeban] = useState([])
    const [dataPembelian, setDataPembelian] = useState([])
    const [dataSetorSendiri, setDataSetorSendiri] = useState([])
    const [disabled, setDisabled] = useState(false)

    const [deleteAkunBeban, setDeleteAkunBeban] = useState([])
    const [deleteAkunPembelian, setDeleteAkunPembelian] = useState([])
    const [deleteProduk, setDeleteProduk] = useState([])

    const [dataSubmit, setDataSubmit] = useState({
        fm: {
            kategori:KATEGORI_PEMBELIAN,
            kategoriTransaksi: KATEGORI_TRANSAKSI_NORMAL,
            tanggalTransaksi: '',
            periodeJatuhTempo: '',
            tanggalJatuhTempo: '',
            totalPembelian: 0,
            subtotal: 0,
            subtotalAkun: 0,
            totalPajakSetor: 0,
            totalBeban: 0,
            total: 0,
            pembayaran: {
                status: '1',
                akun: null
            },
            lawanTransaksi: {
                id: null
            },
            npwpHead: {
                id: sessionStorage.getItem('npwpHeadId')
            },
            akunUtang: {
                id: null
            },
            fileLampiranInstance: null,
            fileLampiran: null,
            fileLampiranAlias: null,
            fp: {
                transaksi: {
                    id: null
                },
                keterangan: {
                    id: null
                },
                tahun:0,
                jenisFakturKode: '0',
                jenisFakturKeterangan: '1 - Faktur Pajak Normal',
                tanggalFp: '',
                masaFm: 0,
                tahunFm: 0,
                masaFp:0,
                tahunFp:0,
                dpp:0,
                ppn:0,
                ppnbm:0
            },
            isPpn: false
        },
        kategoriAkunDebitKredit: '',
        fmAkunPembelianList: [],
        fmAkunBebanList: [],
        fmProdukHapusList: [],
        fmAkunPembelianHapusList: [],
        fmAkunBebanHapusList:[],
        akunBebanHapusList: []
    })
    const statusBayar = [
        { value: STAT_LUNAS, name: "Lunas" },
        { value: STAT_BELUM_BAYAR, name: "Belum bayar" },
      ];

    async function lawanTransaksi() {
        setTimeout(async () => {
            let res = await service.getListLawanTransaksi()
            var tr = []
            res.data.map((d) => {
                tr.push({
                    value: d.id,
                    label: d.nama
                })
            })
            setLawanTr(tr)
            setDataTr(res.data)
        }, 500);
    }

    async function transaksiFakturPajak() {
        let res = await service.getTransaksi()
        var tr = []
        res.data.map((d) => {
            tr.push({
                value: d.id,
                label: `${d.keterangan}`,
                kode: d.kode
            })
        })
        setListTransaksi(tr)
    }

    async function keteranganFakturPajak() {
        let res = await service.getKeterangan()
        var ket = []
        res.data.map((d) => {
            ket.push({
                value: d.id,
                label: `${d.keterangan}`
            })
        })
        setListKeterangan(ket)
    }

    async function akunUtang() {
        let res = await service.getListAkunUtang()
        var piutang = []
        res.data.map((d) => {
            piutang.push({
                value: d.id,
                label: d.nama
            })
        })
        setListakunUtang(piutang)
    }

    async function akunKasBank() {
        let res = await service.getListAkunKasBank()
        var kasBank = []
        res.data.map((d) => {
            kasBank.push({
                value: d.id,
                label: d.nama
            })
        })
        setListAkunKasBank(kasBank)
    }
    async function initData() {
        var PembelianForm = JSON.parse(localStorage.getItem("PembelianForm"))
        let dataProduk = JSON.parse(sessionStorage.getItem("dataPembelianPPN"))
        localStorage.setItem("PembelianForm", JSON.stringify({
            ...PembelianForm
        }))
        setData({ content: {}, loading: false })
        let selectedItem = defaultData()
        if (match.params.id != 'new') {
            setDisabled(true)
            try {
                let res = await servicePPN.getFmById(match.params.id)
                let { data } = res
                selectedItem.id = data.fm.id

                selectedItem.tanggalTransaksi = data.fm.tanggalTransaksi
                selectedItem.tanggalJatuhTempo = data.fm.tanggalJatuhTempo
                selectedItem.periodeJatuhTempo = data.fm.periodeJatuhTempo
                if (data.fm.akunUtang) {
                    selectedItem.akunUtangId = `${data.fm.akunUtang.kode} - ${data.fm.akunUtang.nama}`
                    selectedItem.akunUtang.id = data.fm.akunUtang.id
                    selectedItem.tanggalJatuhTempo = data.fm.tanggalJatuhTempo
                }
                if (data.fm.fp) {
                    selectedItem.transaksiId = data.fm.fp.transaksi.keterangan
                    selectedItem.keteranganId = data.fm.fp.keterangan.keterangan
                    selectedItem.jenisFakturKode = data.fm.fp.jenisFakturKode
                    selectedItem.masa = data.fm.fp.masa
                    selectedItem.tahun = data.fm.fp.tahun
                    selectedItem.tanggalFp = data.fm.fp.tanggalFp
                }
                if (data.fm.lawanTransaksi) {
                    selectedItem.lawanTransaksiId = `${data.fm.lawanTransaksi.npwp} - ${data.fm.lawanTransaksi.nama}`
                    selectedItem.npwp = data.fm.lawanTransaksi.npwp
                    selectedItem.nik = data.fm.lawanTransaksi.nik
                    selectedItem.alamat = data.fm.lawanTransaksi.alamat
                    selectedItem.namaPic = data.fm.lawanTransaksi.namaPic
                    selectedItem.emailPic = data.fm.lawanTransaksi.emailPic
                    selectedItem.noTeleponPic = data.fm.lawanTransaksi.noTeleponPic
                    selectedItem.alamatPic = data.fm.lawanTransaksi.alamatPic
                }

                selectedItem.total = data.fm.total
                setDataPembelian(data.fmAkunPembelianList)
                // setDataSetorSendiri(data.fmAkunPembelianList)
                setDataBeban(data.fmAkunBebanList)
            } catch (error) {
              toast.errorRequest(error)
            }
        } else {
            try{
                let res = await lawanTransaksiService.getByNpwp(dataProduk.npwpPenjual)
                let {data,status} = res 
                    setDataLawanTransaksi(data)
                    selectedItem.lawanTransaksi.id = data.id;
                    selectedItem.lawanTransaksiId = `${data.npwp} - ${data.nama}`;
                    selectedItem.npwp = data.npwp;
                    selectedItem.nik = data.nik;
                    selectedItem.alamat = data.alamat;
                    selectedItem.namaPic = data.namaPic;
                    selectedItem.emailPic = data.emailPic;
                    selectedItem.noTeleponPic = data.noTeleponPic;
                    selectedItem.alamatPic = data.alamatPic;
                
                setDisabled(false)
            } catch (error){
              toast.errorRequest(error)
                selectedItem.lawanTransaksiId = dataProduk.namaPenjual
                    selectedItem.npwp = dataProduk.npwpPenjual
                    selectedItem.nik = '-'
                    selectedItem.alamat = '-'
                    selectedItem.namaPic = dataProduk.namaPenjual
                    selectedItem.emailPic = '-'
                    selectedItem.noTeleponPic = '-'
                    selectedItem.alamatPic ='-'
            }


            selectedItem.dpp = dataProduk.jumlahDpp;
                selectedItem.ppn = dataProduk.jumlahPpn;
                selectedItem.ppnbm = dataProduk.jumlahPpnbm;
                selectedItem.tanggalFp = dataProduk.tanggalFaktur;
                selectedItem.masa = dataProduk.masaPajak;
                selectedItem.tahun = dataProduk.tahunPajak;
            
            // let {data,status} = res
            // console.log(res);
            // if(status == 200){
            //     setDataLawanTransaksi(data)
            //     selectedItem.lawanTransaksiId = dataLawanTransaksi.npwp
            //     selectedItem.npwp = dataLawanTransaksi.npwp
            //     selectedItem.nik = dataLawanTransaksi.nik
            //     selectedItem.alamat = dataLawanTransaksi.alamat
            //     selectedItem.namaPic = dataLawanTransaksi.namaPic
            //     selectedItem.emailPic = dataLawanTransaksi.emailPic
            //     selectedItem.noTeleponPic = dataLawanTransaksi.noTeleponPic
            //     selectedItem.alamatPic = dataLawanTransaksi.alamatPic
            // } else {
            //     setDisabled(false)
            // }
            
            // selectedItem.lawanTransaksiId = dataProduk.namaPembeli;
            // selectedItem.npwp = dataProduk.npwpPembeli;
            
        }
        setItem(selectedItem)
        akunKasBank()
        akunUtang()
        lawanTransaksi()
        // transaksiFakturPajak()
        // keteranganFakturPajak()
    }

    async function setorSendiri() {
        var PembelianSetorSendiri = JSON.parse(localStorage.getItem('akunPembelianForm'))
        if (PembelianSetorSendiri) {
            var dataSetorSendiriNew = dataSetorSendiri
            dataSetorSendiriNew.push(PembelianSetorSendiri)
            setDataSetorSendiri(dataSetorSendiriNew)
            localStorage.removeItem('akunPembelianForm')
        }
    }

    async function akunBeban() {
        var akunBebanForm = JSON.parse(localStorage.getItem('akunBebanForm'))
        if (akunBebanForm) {
            var dataBebanNew = dataBeban
            dataBebanNew.push(akunBebanForm)
            setDataBeban(dataBebanNew)
            localStorage.removeItem('akunBebanForm')
        }
    }

    function akunPembelian() {
        var akunPembelianForm = JSON.parse(localStorage.getItem('akunPembelianForm'))
        if (akunPembelianForm) {
            var dataPembelianNew = dataPembelian
            dataPembelianNew.push(akunPembelianForm)
            setDataPembelian(dataPembelianNew)
            setDataPajakDitanggungList()
            setDataPotongPajakList()
            localStorage.removeItem('akunPembelianForm')
        }
    }

    async function setDataPotongPajakList() {
        var pemotonganPajakList = []
        // console.log('masuk 1');

        dataPembelian.map((d, index) => {
            // console.log('masuk 2');
            if (d.isPotongPajak) {
                pemotonganPajakList.push(d)
            }
        })

        setPotongPajakList(pemotonganPajakList);
    }

    async function setDataPajakDitanggungList() {
        var pemotonganPajakDitanggungList = []
        // console.log('masuk 4');

        dataPembelian.map((d, index) => {
            // console.log('masuk 5');
            if (d.isPajakDitanggung) {
                pemotonganPajakDitanggungList.push(d)
            }
        })

        setDataSetorSendiri(pemotonganPajakDitanggungList);
    }

    async function hapusPembelian(index) {
        modalStore.showConfirm({
            title: t.translate('words.delete'),
            children: t.translate('words.confirmHapus'),
            onSubmit: (callback) => {
                var deleteIndex = dataPembelian
                deleteIndex.splice(index, 1)
                setDataPembelian(deleteIndex)
                callback()
            }
        })
    }

    async function hapusBeban(index) {
        modalStore.showConfirm({
            title: t.translate('words.delete'),
            children: t.translate('words.confirmHapus'),
            onSubmit: (callback) => {
                var deleteIndex = dataBeban
                deleteIndex.splice(index, 1)
                setDataBeban(deleteIndex)
                callback()
            }
        })
    }

    async function hapusNilaiPembelian(index) {
        modalStore.showConfirm({
            title: t.translate('words.delete'),
            children: t.translate('words.confirmHapus'),
            onSubmit: (callback) => {
                var deleteIndex = dataSetorSendiri
                deleteIndex.splice(index, 1)
                setDataSetorSendiri(deleteIndex)
                callback()
            }
        })
    }

    function addItem() {
        setDialogPembelianProdukForm(true)
        setDialogData({})
    }

    function addBeban() {
        setDialogPembelianBebanForm(true)
        setDialogData({})
    }

    function addNilaiPembelian() {
        setDialogSetorSendiriForm(true)
        setDialogData({})
    }

    useEffect(() => {
        initData()
        transaksiFakturPajak()
        keteranganFakturPajak()
    }, [])


    const activePanel1 = () => {
        setPanel1(true);
        setPanel2(false);
        setPanel3(false);
    }

    const activePanel2 = () => {
        setPanel1(false);
        setPanel2(true);
        setPanel3(false);
    }

    const activePanel3 = () => {
        setPanel1(false);
        setPanel2(false);
        setPanel3(true);
    }
    useEffect(() => {
      cocokdataLawanTransaksi();
      // console.log(item,'item cocokkan data');
    }, [showDialogCocokkanData]);

    var cocokdataLawanTransaksi = async () => {
      let dataProduk = JSON.parse(sessionStorage.getItem("dataPembelianPPN"));
      let selectedItem = defaultData();
      selectedItem.lawanTransaksi.id = cocokkanLawanTransaksi? cocokkanLawanTransaksi.id: dataLawanTransaksi.id;
      selectedItem.lawanTransaksiId = cocokkanLawanTransaksi? `${cocokkanLawanTransaksi.npwp} - ${cocokkanLawanTransaksi.nama}`: `${dataLawanTransaksi.npwp} - ${dataLawanTransaksi.nama}`;
      selectedItem.npwp = cocokkanLawanTransaksi? cocokkanLawanTransaksi.npwp: dataLawanTransaksi.npwp;
      selectedItem.nik = cocokkanLawanTransaksi ? cocokkanLawanTransaksi.nik : dataLawanTransaksi.nik;
      selectedItem.alamat = cocokkanLawanTransaksi? cocokkanLawanTransaksi.alamat: dataLawanTransaksi.alamat;
      selectedItem.dpp = dataProduk.jumlahDpp;
      selectedItem.ppn = dataProduk.jumlahPpn;
      selectedItem.ppnbm = dataProduk.jumlahPpnbm;
      selectedItem.tanggalFp = dataProduk.tanggalFaktur;
      selectedItem.masa = dataProduk.masaPajak;
      selectedItem.tahun = dataProduk.tahunPajak;
      setItem(selectedItem);
    }; 
    
    var onSubmitIdentitas = async (data, callback) => {
        setItem(data)
        activePanel2()
    }


    var onSubmitTransaksi = async (data, callback) => {
        setItem(data)
        activePanel3()
    }


    var onSubmitProduk = async (data, callback) => {
        let resGambar;
        if (data.fileLampiran != null) {
          resGambar = await service.upload(data.fileLampiran);
        }
        let debitPenjualan = dataPembelian
          .filter((d) => d.akun.kategoriAkunDebitKredit == 1)
          .map((d) => d.total)
          .reduce((acc, total) => acc + total, 0);
        let kreditPenjualan = dataPembelian
          .filter((d) => d.akun.kategoriAkunDebitKredit == 2)
          .map((d) => d.total)
          .reduce((acc, total) => acc + total, 0);
        let debitBeban = dataBeban
          .filter((d) => d.debitKredit == 1)
          .map((d) => d.total)
          .reduce((total, datas) => {
            return total + datas;
          }, 0);
        let kreditBeban = dataBeban
          .filter((d) => d.debitKredit == 2)
          .map((d) => d.total)
          .reduce((total, datas) => {
            return total + datas;
          }, 0);
        let pemotonganPajakDitanggung = dataSetorSendiri
          .filter((dd) => dd.isPajakDitanggung === true)
          .map((d) => d.nominalPajak)
          .reduce((acc, nominalPajak, index) => acc + parseInt(nominalPajak), 0);
        let NominalPajak = potonganPajakList
          .filter((dd) => dd.isPajakDitanggung === false)
          .map((d) => d.nominalPajak)
          .reduce((acc, nominalPajak, index) => acc + parseInt(nominalPajak), 0);
    
        let pemotonganPajak = dataPembelian
          .map((d) => d.nominalPajak)
          .reduce((acc, nominalPajak, index) => acc + parseInt(nominalPajak), 0);
        let reqData = dataSubmit;

        //ISI FIELD
        reqData.fm.id = data.id
        if (match.params.isPpn === 'true') {
            reqData.fm.isPpn = true
        } else {
            reqData.fm.isPpn = false
        }
        reqData.fm.lawanTransaksi = data.lawanTransaksi
        reqData.fm.kategori = 1
        reqData.fm.kategoriTransaksi = data.kategoriTransaksi


        reqData.fm.fileLampiran = data.fileLampiran != null ? resGambar.data.namaFileAlias : null
        reqData.fm.fileLampiranAlias =data.fileLampiran != null ? resGambar.data.namaFileAlias : null;
        reqData.fm.fileLampiranInstance = data.fileLampiran != null ? resGambar.data.instanceId : null
        reqData.fm.keterangan = data.keterangans;

        reqData.fm.fp.transaksi = data.transaksi
        reqData.fm.fp.keterangan = data.keterangan
        reqData.fm.fp.masa = data.masa
        reqData.fm.fp.tahun = data.tahun
        reqData.fm.fp.tanggalFp = data.tanggalFp
        reqData.fm.fp.jenisFakturKode = data.jenisFakturKode
        reqData.fm.fp.jenisFakturKeterangan = data.jenisFakturKeterangan
        reqData.fm.fp.dpp = data.dpp
        reqData.fm.fp.ppn = data.ppn
        reqData.fm.fp.ppnbm = data.ppnbm
        reqData.fm.fp.masaFp = data.masa
        reqData.fm.fp.tahunFp = data.tahun
        reqData.fm.fp.masaFm = data.masa
        reqData.fm.fp.tahunFm = data.tahun

        reqData.fm.tanggalTransaksi = moment(data.tanggalTransaksi).format('yyyy-MM-DD hh:mm:ss')
        if (data.statusPembayaran == STAT_BELUM_BAYAR) {
            reqData.fm.akunUtang = data.akunUtang

            reqData.fm.pembayaran.status = STAT_BELUM_BAYAR
            reqData.fm.pembayaran.akun = null

            reqData.fm.periodeJatuhTempo = data.periodeJatuhTempo
            reqData.fm.tanggalJatuhTempo = moment(data.tanggalJatuhTempo).format('yyyy-MM-DD hh:mm:ss')

        }
        else {
            reqData.fm.akunUtang = data.akunUtang

            reqData.fm.pembayaran.status = STAT_LUNAS
            reqData.fm.pembayaran.akun = data.akunUtang

            reqData.fm.periodeJatuhTempo = 0
            reqData.fm.tanggalJatuhTempo = null
        }
        reqData.fm.jumlahDpp = data.dpp
        reqData.fm.jumlahPpn = data.ppn
        reqData.fm.jumlahPpnbm = data.ppnbm

        reqData.fm.totalPembelian = debitPenjualan - kreditPenjualan;
        reqData.fm.totalPotongPajakDitanggung = pemotonganPajakDitanggung;
        reqData.fm.totalPotongPajak = pemotonganPajak;
    
        reqData.fm.subtotal = debitPenjualan - kreditPenjualan + NominalPajak;
        reqData.fm.subtotalAkun = reqData.fm.subtotal;
        reqData.fm.totalPajakSetor = pemotonganPajakDitanggung;
        reqData.fm.totalBeban = debitBeban - kreditBeban;
        reqData.fm.total = debitPenjualan - kreditPenjualan;

        reqData.fm.npwpHead.id = sessionStorage.getItem('npwpHeadId')

        reqData.fmAkunBebanList = dataBeban
        reqData.fmAkunPembelianList = dataPembelian
        reqData.fmAkunBebanHapusList = deleteAkunBeban
        reqData.fmAkunPembelianHapusList = deleteAkunPembelian

      

        if (reqData.fm.id) {

            try {
                if (match.params.isPpn == 'false') {
                    navigationStore.redirectTo(backTo)
                } else {
                    navigationStore.redirectTo(`/internal/npwp/${npwpHeadId}/ppn`)
                }
            }
            catch (err) {
                callback(err, true, false);
            }

        }
        else {
            try {
                const res = await service.createPkp(reqData);
                let { data, status } = res;
                if (status == 200) {
                    if (reqData.fm.isPpn) {
                        callback('success', false)
                        navigationStore.redirectTo(`/internal/npwp/${npwpHeadId}/ppn`)
                    } else {
                        callback('success', false)
                        navigationStore.redirectTo(backTo)
                    }
                    // window.location.href = `${basePath}/${item.id}/history`
                }
              
            }
            catch (err) {
                callback(err, true, false);
            }
        }
    }

    var onSubmit = async (data, callback) => {
        // data.form1valid = true
        // var bpform = JSON.parse(localStorage.getItem('bpform'))
        // bpform = Object.assign(bpform, data)
        // localStorage.setItem('bpform', JSON.stringify(bpform))
        props.activePanel()
    }

    const baseName = `form-Pembelian`
    const basePath = `Pembelian`
    var npwpHeadId = sessionStorage.getItem('npwpHeadId')
    var backTo = `/internal/npwp/${npwpHeadId}/Pembelian`
    var produkData = [
        {
            kode: "6.1.109",
            nama: "Beban Jasa",
            jumlahBarang: "10",
            nominal: "10000000",
            dpp: "10000000",
            ppn: "10000000",
            ppnBm: "10000000"
        }
    ];

    var PembelianSebelumPajak = [
        {
            namaAkun: "Pembelian",
            kodeAkun: "4.1.01.0001",
            nominal: "30200000",
        }
    ];

    var disetorSendiri = [
        {
            namaAkun: "Pembelian",
            kodeAkun: "4.1.01.0001",
            nominal: "30200000",
        },
        {
            namaAkun: "Pembelian",
            kodeAkun: "4.1.01.0001",
            nominal: "30200000",
        }
    ];

    // setDokumens(dokumensData);

    return (

        <FormWrapper
            loading={data.loading}
            // backTo={backTo}
            showCommandbar={true}
            baseId={`${baseName}`}
            title={t.translate(`modules.pembelian.form.pkp.title`)}
            style={{
                maxWidth: '100%'
            }}
            // defaultData={data.content}
            definitions={[
                {
                    render: (
                        <ExpansionList style={{ width: '100%' }}>
                            {/* FORM IDENTITAS */}
                            <ExpansionPanel expanded={panel1} onClick={() => activePanel1()} header={t.translate('words.identity')}>
                                {/* <Modal
									disableFocusOnMount={true}
									id="lt-form"
									aria-label="lt-form"
									visible={showModal}
									onRequestClose={_closeDialog}
									style={{
										width: 1024,
										height: 600
									}}
								>
								</Modal> */}
                                {/* <LawanTransaksiTable match={match} onSearch={true} setShowModal={setShowModal} setData={setData} data={data} /> */}
                                {/* <Button theme="primary" themeType="contained" style={{ margin: 15, width: '25%' }} onClick={()=> setShowModal(true)} hidden={isHidden}><FontIcon iconClassName="mdi mdi-magnify" />
									{t.translate('words.cariLawanTransaksi')}
								</Button> */}
                                <FormWrapper
                                    submitLabel={t.translate('words.next')}
                                    loading={data.loading}
                                    showCommandbar={false}  
                                    baseId={`mod-form-${baseName}`}
                                    style={{
                                        maxWidth: '100%'
                                    }}
                                    defaultData={item}
                                    definitions={[
                                        {
                                            render: (
                                                <div style={{ width: '100%' }}>
                                                    <p style={{ margin: "10px" }}><b>{t.translate(`words.dataLawanTransaksi`)}</b></p>
                                                </div>
                                            )
                                        },
                                        {
                                            inputType: inputTypes.INPUT,
                                            label: t.translate(`words.nama`),
                                            key: 'lawanTransaksiId',
                                            validation: 'required',
                                            labelKey: 'label',
                                            valueKey: 'label',
                                            // data: lawanTr,
                                            disabled: true
                                        },
                                        {
                                            inputType: inputTypes.INPUT_MASK_NUMBER,
                                            label: t.translate(`words.identityNpwp`),
                                            key: 'npwp',
                                            type: 'text',
                                            mask: inputValidasiNpwp,
                                            maskChar: '_',
                                            maskValue: 'string',
                                            disabled: true,
                                            width: '48%',
                                            style: {
                                                marginRight: "2%"
                                            }
                                        },
                                        {
                                            inputType: inputTypes.INPUT_MASK_NUMBER,
                                            label: t.translate(`words.identityNik`),
                                            key: 'nik',
                                            type: 'text',
                                            mask: inputValidasiNik,
                                            maskChar: '_',
                                            maskValue: 'string',
                                            disabled: true,
                                            width: '48%',
                                            style: {
                                                marginLeft: "2%"
                                            }
                                        },
                                        {
                                            inputType: inputTypes.INPUT,
                                            label: t.translate(`words.address`),
                                            key: 'alamat',
                                            disabled: true,
                                            type: 'text',
                                            width: '100%'
                                        },
                                        {
                                          render: (
                                            <div style={{ width: "100%" }}>
                                              <Button
                                                themeType="outline"
                                                style={{
                                                  borderWidth: 2,
                                                  borderColor: "#218c74",
                                                  color: "#218c74",
                                                  fontWeight: "bold",
                                                }}
                                                onClick={() => {
                                                  setShowDialogCocokkanData(true);
                                                }}
                                              >
                                                Cocokkan Data
                                              </Button>
                                            </div>
                                          ),
                                        },
                                    ]}
                                    onChange={(data, key, value) => {
                                        if (key === 'lawanTransaksiId') {
                                            lawanTr.map((d) => {
                                                if (d.label === value) {
                                                    data.lawanTransaksi.id = d.value
                                                    data.lawanTransaksiNama = d.nama
                                                    data.lawanTransaksiNik = d.nik
                                                    data.lawanTransaksiNpwp = d.npwp
                                                    data.lawanTransaksiAlamat = d.alamat
                                                    data.lawanTransaksiNamaPic = d.namaPic
                                                    data.lawanTransaksiNoTeleponPic = d.noTeleponPic
                                                    data.lawanTransaksiEmailPic = d.emailPic
                                                    data.lawanTransaksiAlamatPic = d.alamatPic
                                                    let dataLawanTransaksi = find(dataTr, { id: data.lawanTransaksi.id })
                                                    data['npwp'] = dataLawanTransaksi ? dataLawanTransaksi.npwp : ""
                                                    data['nik'] = dataLawanTransaksi ? dataLawanTransaksi.nik : ""
                                                    data['alamat'] = dataLawanTransaksi ? dataLawanTransaksi.alamat : ""
                                                    data['namaPic'] = dataLawanTransaksi ? dataLawanTransaksi.namaPic : ""
                                                    data['emailPic'] = dataLawanTransaksi ? dataLawanTransaksi.emailPic : ""
                                                    data['noTeleponPic'] = dataLawanTransaksi ? dataLawanTransaksi.noTeleponPic : ""
                                                    data['alamatPic'] = dataLawanTransaksi ? dataLawanTransaksi.alamatPic : ""
                                                }
                                            })
                                        }
                                    }}
                                    onSubmit={onSubmitIdentitas}
                                />
                                  <PembelianCocokkanData
        visible={showDialogCocokkanData}
        onRequestClose={(d) => {
          setShowDialogCocokkanData(false);
          setCocokkanLawanTransaksi(d);
        }}
      />

                            </ExpansionPanel>

                            <br />

                            {/* FORM TRANSAKSI */}
                            <ExpansionPanel expanded={panel2} onClick={() => activePanel2()} header={t.translate('words.transaksi')}>
                                <FormWrapper
                                    submitLabel={t.translate('words.next')}
                                    loading={data.loading}
                                    showCommandbar={false}
                                    baseId={`mod-form-${baseName}`}
                                    style={{
                                        maxWidth: '100%',
                                        
                                    }}
                                    defaultData={item}
                                    definitions={[
                                        {
                                            render: (
                                              <div style={{ width: "100%", marginBottom: 30 }}>
                                                <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                                                  {t.translate(`column.general`)}
                                                  <Divider />
                                                </Text>
                                              </div>
                                            ),
                                          },
                                        {
                                            inputType: inputTypes.RADIO,
                                            label: t.translate(`words.PembelianNormal`),
                                            key: 'kategoriTransaksi',
                                            name: 'kategoriTransaksi',
                                            value: KATEGORI_TRANSAKSI_NORMAL,
                                            style: {
                                                width: '100%'
                                            },
                                            disabled: disabled
                                        },
                                        {
                                            render: (
                                              <div style={{ width: "100%", marginBottom: 30 }}>
                                                <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                                                  {t.translate(`words.dataTransaksi`)}
                                                  <Divider />
                                                </Text>
                                              </div>
                                            ),
                                          },
                                        {
                                            inputType: inputTypes.DATEPICKER,
                                            className: "mpk-padding-N padding-right",
                                            label: 'Tanggal Transaksi',
                                            key: 'tanggalTransaksi',
                                            width: "50%",
                                            type: 'date',
                                            validation: 'required',
                                            disabled: disabled
                                        },
                                        {
                                            inputType: inputTypes.SELECT,
                                            label: "Status Pembayaran",
                                            key: "statusPembayaran",
                                            width: "50%",
                                            required: true,
                                            options: statusBayar.map((d) => ({
                                              label: d.name,
                                              value: d.value,
                                            })),
                                            disabled: disabled,
                                          },
                                        {
                                            inputType: inputTypes.AUTOCOMPLETE,
                                            label: 'Akun Utang',
                                            key: 'akunUtangId',
                                            data: listakunUtang,
                                            labelKey: 'label',
                                            valueKey: 'label',
                                            show: statusLunas,
                                            disabled: disabled

                                        },
                                        {
                                            inputType: inputTypes.INPUT_MASK_NUMBER,
                                            label: t.translate(`words.jatuhTempo`),
                                            className: "mpk-padding-N padding-right",
                                            thousandSeparator: ".",
                                            decimalSeparator: ",",
                                            isNumericString: true,
                                            key: 'periodeJatuhTempo',
                                            width: '48%',
                                            show: statusLunas,
                                            disabled: disabled

                                        },
                                        {
                                            inputType: inputTypes.DATEPICKER,
                                            label: t.translate(`words.tanggalJatuhTempo`),
                                            key: 'tanggalJatuhTempo',
                                            type: 'date',
                                            disable: true,
                                            width: '50%',
                                            show: statusLunas,
                                            disabled: disabled

                                        },
                                        {
                                            inputType: inputTypes.AUTOCOMPLETE,
                                            label: t.translate(`words.terimaDari`),
                                            key: 'terimaDariId',
                                            data: listAkunKasBank,
                                            labelKey: 'label',
                                            valueKey: 'label',
                                            show: !statusLunas,
                                            disabled: disabled

                                        },
                                        {
                                            render: (
                                              <div style={{ width: "100%" }}>
                                                <p style={{ margin: "10px" }}>
                                                  <b>Data Faktur Pajak</b>
                                                </p>
                                              </div>
                                            ),
                                          },
                                        {
                                            inputType: inputTypes.AUTOCOMPLETE,
                                            className: 'mpk-padding-N padding-right',
                                            label: 'Detail Transaksi',
                                            key: 'transaksiId',
                                            data: listTransaksi,
                                            labelKey: 'label',
                                            valueKey: 'label',
                                            width: '50%',
                                            required: true,
                                            disabled: disabled
                                        },
                                        {
                                            inputType: inputTypes.AUTOCOMPLETE,
                                            label: 'Keterangan Tambahan',
                                            key: 'keteranganId',
                                            width: '50%',
                                            required: true,
                                            data: listKeterangan,
                                            labelKey: 'label',
                                            valueKey: 'label',
                                            disabled: disabled
                                        },
                                        {
                                            inputType: inputTypes.INPUT,
                                            className: 'mpk-padding-N padding-right',
                                            label: '',
                                            key: 'jenisFakturKode',
                                            value: '0',
                                            width: '12%',
                                            disabled: true,
                                            disabled: true
                                        },
                                        {
                                            inputType: inputTypes.INPUT,
                                            className: 'mpk-padding-N padding-right',
                                            label: 'Jenis Faktur Pajak',
                                            key: 'jenisFakturKeterangan',
                                            width: '38%',
                                            value: '1 - Faktur Pajak Normal',
                                            disabled: true,
                                            disabled: true
                                        },
                                        {
                                            inputType: inputTypes.DATEPICKER,
                                            label: 'Tanggal Faktur Pajak',
                                            key: 'tanggalFp',
                                            width: '50%',
                                            disabled: true,
                                            disabled: true
                                        },
                                        {
                                            inputType: inputTypes.INPUT_MASK_NUMBER,
                                            label: 'Masa',
                                            key: 'masa',
                                            className: 'mpk-padding-N padding-right',
                                            width: '50%',
                                            disabled: true,
                                            disabled: true
                                        },
                                        {
                                            inputType: inputTypes.INPUT_MASK_NUMBER,
                                            label: 'Tahun',
                                            key: 'tahun',
                                            width: '50%',
                                            disabled: true,
                                            disabled: true
                                        },
                                        {
                                            render: (
                                              <div style={{ width: "100%" }}>
                                                <p style={{ margin: "10px" }}>
                                                  <b>Pengkreditan</b>
                                                </p>
                                              </div>
                                            ),
                                          },
                                        {
                                            inputType: inputTypes.SELECT,
                                            className: 'mpk-padding-N padding-right',
                                            label: 'Dapat Dikreditkan ?',
                                            key: 'pengkreditan',
                                            width: '50%',
                                            required: true,
                                            disabled: disabled,
                                            options: kredit.map(d => ({
                                                label: d.label,
                                                value: d.value
                                            }))
                                        },

                                        
                                        
                                    ]}
                                    onChange={(data, key, value) => {
                                        if (key === 'tanggalTransaksi') {
                                            let tanggalFp = new Date(data.tanggalTransaksi)
                                            tanggalFp = moment(tanggalFp).format('yyyy-MM-DD hh:mm:ss')
                                            data.tanggalFp = tanggalFp

                                            let yearFp = new Date(data.tanggalTransaksi).getFullYear()
                                            let monthFp = new Date(data.tanggalTransaksi).getMonth() + 1
                                            data.masa = monthFp
                                            data.tahun = yearFp
                                        }

                                        if (key === 'transaksiId') {
                                            listTransaksi.map((d) => {
                                                if (d.label == value) {
                                                    data.transaksi = {
                                                        id: d.value,
                                                        kode: d.kode,
                                                        keterangan: d.label
                                                    }
                                                }
                                            })
                                        }

                                        if (key === 'keteranganId') {
                                            listKeterangan.map((d) => {
                                                if (d.label == value) {
                                                    data.keterangan = {
                                                        id: d.value,
                                                        keterangan: d.label
                                                    }
                                                }
                                            })
                                        }

                                        if (data.kategoriTransaksi) {
                                            setDisabledField(false)
                                        }

                                        if (key === 'statusPembayaran') {
                                            if (value === "1") {
                                                setStatusLunas(true)
                                            } else {
                                                setStatusLunas(false)
                                            }
                                        }

                                        // if(data.statusPembayaran == STAT_LUNAS ) {
                                        //     setStatusLunas(false)
                                        // } else{
                                        //     setStatusLunas(true)
                                        // }

                                        if (key === 'akunUtangId') {
                                            listakunUtang.map((d) => {
                                                if (d.label == value) {
                                                    data['akunUtang'] = {
                                                        id: d.value
                                                    }
                                                }
                                            })
                                        }

                                        if (key === 'terimaDariId') {
                                            listAkunKasBank.map((d) => {
                                                if (d.label == value) {
                                                    data['akunUtang'] = {
                                                        id: d.value
                                                    }
                                                }
                                            })
                                        }

                                        if (key == 'tanggalTransaksi') {
                                            let tanggalTransaksi = new Date(data['tanggalTransaksi'])
                                            tanggalTransaksi.setDate(tanggalTransaksi.getDate() + data['periodeJatuhTempo'])
                                            data['tanggalJatuhTempo'] = tanggalTransaksi

                                        }

                                        if (key == 'periodeJatuhTempo') {
                                            let tanggalTransaksi = new Date(data.tanggalTransaksi)
                                            tanggalTransaksi.setDate(tanggalTransaksi.getDate() + value)
                                            data.tanggalJatuhTempo = tanggalTransaksi

                                        }
                                    }}
                                    onSubmit={onSubmitTransaksi}
                                />
                            </ExpansionPanel>

                            <br />

                            {/* FORM PRODUK */}
                            <ExpansionPanel expanded={panel3} onClick={() => activePanel3()} header={t.translate('words.produk')}>
                                <InputDetailAkunPembelian
                                    visible={dialogPembelianProdukForm}
                                    onRequestClose={() => {
                                        akunPembelian()
                                        setDialogPembelianProdukForm(false)
                                        setDialogData(null)
                                    }}
                                    item={dialogData}
                                />
                                <InputDetailAkunBeban
                                    visible={dialogPembelianBebanForm}
                                    onRequestClose={() => {
                                        setDialogPembelianBebanForm(false)
                                        setDialogData(null)
                                        akunBeban()
                                    }}
                                />
                                <FormWrapper
                                    loading={data.loading}
                                    showCommandbar={false}
                                    baseId={`mod-form-${baseName}`}
                                    style={{
                                        maxWidth: '100%'
                                    }}
                                    defaultData={item}
                                    definitions={[
                                        {
                                            inputType: inputTypes.INPUT_MASK_NUMBER,
                                            label: 'DPP',
                                            key: 'dpp',
                                            type: 'text',
                                            disabled: true,
                                            width: '48%',
                                            style: {
                                                marginRight: "2%"
                                            }
                                        },
                                        {
                                            inputType: inputTypes.INPUT_MASK_NUMBER,
                                            label: 'PPN',
                                            key: 'ppn',
                                            type: 'text',
                                            disabled: true,
                                            width: '48%',
                                            style: {
                                                marginLeft: "2%"
                                            }
                                        }, {
                                            inputType: inputTypes.INPUT_MASK_NUMBER,
                                            label: 'PPNBM',
                                            key: 'ppnbm',
                                            type: 'text',
                                            disabled: true,
                                            width: '48%',
                                            style: {
                                                marginRight: "2%"
                                            }
                                        },
                                        {
                                            render:(
                                              <Text style={{ fontSize: 20, fontWeight: "bold" ,width:'100%'}}>
                                                  Keterangan & Lampiran
                                                  <Divider />
                                                </Text>
                                            )
                                          },
                                          {
                                            inputType: inputTypes.INPUT,
                                            label: t.translate(`words.keterangan`),
                                            key: "keterangans",
                                            type: "text",
                                            width: "100%",
                                          },
                                          {
                                            inputType: inputTypes.FILE_INPUT,
                                            label: t.translate("words.file"),
                                            key: "fileLampiran",
                                            type: "file",
                                            accept: ".jpg,.jpeg,.png",
                                          },
                                          {
                                            render: <div style={{ margin: 10 }}></div>,
                                          },
                                          {
                                            render: (
                                              <div style={{ width: "100%" }}>
                                                <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                                                  Daftar Nilai Pembelian
                                                  <Button
                                                    theme="primary"
                                                    themeType="contained"
                                                    style={{ marginBottom: 5, float: "right" }}
                                                    onClick={() => {
                                                        addItem()
                                                    }}
                                                    disabled={disabled}
                                                  >
                                                    <FontIcon iconClassName="mdi mdi-plus" />
                                                    {t.translate("words.add")}
                                                  </Button>
                                                  <Divider />
                                                </Text>
                                                {dataPembelian.length > 0 ? (
                                                  <>
                                                    <Table style={{ width: "100%" }}>
                                                      <TableHeader>
                                                        <TableRow>
                                                          <TableCell style={{ width: "10%" }}>
                                                            {t.translate("words.non")}
                                                          </TableCell>
                                                          <TableCell style={{ width: "25%" }}>
                                                            {t.translate("words.kode")}
                                                          </TableCell>
                                                          <TableCell style={{ width: "25%" }}>
                                                            {t.translate("words.nama")}
                                                          </TableCell>
                                                          <TableCell style={{ width: "25%" }}>
                                                            {t.translate("words.nominal")}
                                                          </TableCell>
                                                        </TableRow>
                                                      </TableHeader>
                                                      <TableBody>
                                                        {dataPembelian.map((d, index) => {
                                                          return (
                                                            <TableRow key={index}>
                                                              <TableCell>{index + 1}</TableCell>
                                                              <TableCell>{d.akun.kode}</TableCell>
                                                              <TableCell>{d.akun.nama}</TableCell>
                                                              <TableCell>Rp. {format(d.total)}</TableCell>
                                                              <TableCell>
                                                                <Button
                                                                  style={{
                                                                    backgroundColor: "#34495e",
                                                                    color: "white",
                                                                  }}
                                                                  onClick={() =>
                                                                    hapusPembelian(index)
                                                                  }
                                                                  disabled={disabled}
                                                                >
                                                                  {t.translate("words.delete")}
                                                                </Button>
                                                              </TableCell>
                                                            </TableRow>
                                                          );
                                                        })}
                                                      </TableBody>
                                                    </Table>
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        width: "100%",
                                                        marginBottom: 50,
                                                        marginTop: 30,
                                                        backgroundColor: "#ecf0f1",
                                                        padding: 5,
                                                        borderRadius: 50,
                                                      }}
                                                    >
                                                      <TextContainer
                                                        style={{
                                                          display: "flex",
                                                          flexDirection: "column",
                                                          flex: 1,
                                                          padding: 5,
                                                        }}
                                                      >
                                                        <Text style={{ fontSize: 16 }}>
                                                          Total :{" "}
                                                          <b>
                                                            Rp.
                                                            {format(
                                                              dataPembelian
                                                                .filter(
                                                                  (d) =>
                                                                    d.akun.kategoriAkunDebitKredit ==
                                                                    1
                                                                )
                                                                .map((d) => d.total)
                                                                .reduce((total, datas) => {
                                                                  return total + datas;
                                                                }, 0) -
                                                                dataPembelian
                                                                  .filter(
                                                                    (d) =>
                                                                      d.akun
                                                                        .kategoriAkunDebitKredit == 2
                                                                  )
                                                                  .map((d) => d.total)
                                                                  .reduce((total, datas) => {
                                                                    return total + datas;
                                                                  }, 0)
                                                            )}
                                                          </b>
                                                        </Text>
                                                      </TextContainer>
                                                    </div>
                                                  </>
                                                ) : (
                                                  <TextContainer style={{ textAlign: "center" }}>
                                                    <Text
                                                      type="headline-6"
                                                      style={{ color: "#7f8c8d" }}
                                                    >
                                                      Tidak ada data
                                                    </Text>
                                                  </TextContainer>
                                                )}
                                                <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                                                  Daftar Pemotongan Pajak Ditanggung
                                                  <Divider />
                                                </Text>
                                                {dataSetorSendiri.length > 0 ? (
                                                  <>
                                                    <Table style={{ width: "100%" }}>
                                                      <TableHeader>
                                                        <TableRow>
                                                          <TableCell style={{ width: "10%" }}>
                                                            {t.translate("words.non")}
                                                          </TableCell>
                                                          <TableCell style={{ width: "25%" }}>
                                                            {t.translate("words.kode")}
                                                          </TableCell>
                                                          <TableCell style={{ width: "25%" }}>
                                                            {t.translate("words.nama")}
                                                          </TableCell>
                                                          <TableCell style={{ width: "25%" }}>
                                                            {t.translate("words.nominal")}
                                                          </TableCell>
                                                        </TableRow>
                                                      </TableHeader>
                                                      <TableBody>
                                                        {dataSetorSendiri.filter((d)=>d.isPajakDitanggung == true).map(
                                                          (d, index) => {
                                                            return (
                                                              <TableRow key={index}>
                                                                <TableCell>{index + 1}</TableCell>
                                                                <TableCell>{d.akun.kode}</TableCell>
                                                                <TableCell>{d.akun.nama}</TableCell>
                                                                <TableCell>Rp. {format(d.nominalPajakDitanggung)}</TableCell>
                                                              </TableRow>
                                                            );
                                                          }
                                                        )}
                                                      </TableBody>
                                                    </Table>
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        width: "100%",
                                                        marginBottom: 50,
                                                        marginTop: 30,
                                                        backgroundColor: "#ecf0f1",
                                                        padding: 5,
                                                        borderRadius: 50,
                                                      }}
                                                    >
                                                      <TextContainer
                                                        style={{
                                                          display: "flex",
                                                          flexDirection: "column",
                                                          flex: 1,
                                                          padding: 5,
                                                        }}
                                                      >
                                                        <Text style={{ fontSize: 16 }}>
                                                          Total :{" "}
                                                          <b>
                                                            Rp.
                                                            {format(
                                                              dataSetorSendiri
                                                                .filter(
                                                                  (d) => d.isPajakDitanggung == true
                                                                )
                                                                .map((d) => d.nominalPajakDitanggung)
                                                                .reduce((total, datas) => {
                                                                  return total + datas;
                                                                }, 0)
                                                            )}
                                                          </b>
                                                        </Text>
                                                      </TextContainer>
                                                    </div>
                                                  </>
                                                ) : (
                                                  <TextContainer style={{ textAlign: "center" }}>
                                                    <Text
                                                      type="headline-6"
                                                      style={{ color: "#7f8c8d" }}
                                                    >
                                                      Tidak ada data
                                                    </Text>
                                                  </TextContainer>
                                                )}
                                                <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                                                  Daftar Pemotongan Pajak
                                                  <Divider />
                                                </Text>
                                                {potonganPajakList.length > 0 ? (
                                                  <>
                                                    <Table style={{ width: "100%" }}>
                                                      <TableHeader>
                                                        <TableRow>
                                                          <TableCell style={{ width: "10%" }}>
                                                            {t.translate("words.non")}
                                                          </TableCell>
                                                          <TableCell style={{ width: "25%" }}>
                                                            {t.translate("words.kode")}
                                                          </TableCell>
                                                          <TableCell style={{ width: "25%" }}>
                                                            {t.translate("words.nama")}
                                                          </TableCell>
                                                          <TableCell style={{ width: "25%" }}>
                                                            {t.translate("words.nominal")}
                                                          </TableCell>
                                                        </TableRow>
                                                      </TableHeader>
                                                      <TableBody>
                                                        {potonganPajakList.map((d, index) => {
                                                          return (
                                                            <TableRow key={index}>
                                                              <TableCell>{index + 1}</TableCell>
                                                              <TableCell>{d.akun.kode}</TableCell>
                                                              <TableCell>{d.akun.nama}</TableCell>
                                                              <TableCell>Rp. {format(d.nominalPajak)}</TableCell>
                                                            </TableRow>
                                                          );
                                                        })}
                                                      </TableBody>
                                                    </Table>
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        width: "100%",
                                                        marginBottom: 50,
                                                        marginTop: 30,
                                                        backgroundColor: "#ecf0f1",
                                                        padding: 5,
                                                        borderRadius: 50,
                                                      }}
                                                    >
                                                      <TextContainer
                                                        style={{
                                                          display: "flex",
                                                          flexDirection: "column",
                                                          flex: 1,
                                                          padding: 5,
                                                        }}
                                                      >
                                                        <Text style={{ fontSize: 16 }}>
                                                          Total :{" "}
                                                          <b>
                                                            Rp.
                                                            {format(
                                                              potonganPajakList
                                                                .map((d) => d.nominalPajak)
                                                                .reduce((nominalPajak, datas) => {
                                                                  return nominalPajak + datas;
                                                                }, 0)
                                                            )}
                                                          </b>
                                                        </Text>
                                                      </TextContainer>
                                                    </div>
                                                  </>
                                                ) : (
                                                  <TextContainer style={{ textAlign: "center" }}>
                                                    <Text
                                                      type="headline-6"
                                                      style={{ color: "#7f8c8d" }}
                                                    >
                                                      Tidak ada data
                                                    </Text>
                                                  </TextContainer>
                                                )}
                                                <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                                                  Daftar Beban/Pendapatan Lainnya
                                                  <Button
                                                    theme="primary"
                                                    themeType="contained"
                                                    style={{ marginBottom: 5, float: "right" }}
                                                    onClick={() => addBeban()}
                                                    disabled={disabled}
                                                  >
                                                    <FontIcon iconClassName="mdi mdi-plus" />
                                                    {t.translate("words.add")}
                                                  </Button>
                                                  <Divider />
                                                </Text>
                                                {dataBeban.length > 0 ? (
                                                  <>
                                                    <Table style={{ width: "100%" }}>
                                                      <TableHeader>
                                                        <TableRow>
                                                          <TableCell style={{ width: "10%" }}>
                                                            {t.translate("words.non")}
                                                          </TableCell>
                                                          <TableCell style={{ width: "25%" }}>
                                                            Kategori Akun
                                                          </TableCell>
                                                          <TableCell style={{ width: "25%" }}>
                                                            {t.translate("words.nama")}
                                                          </TableCell>
                                                          <TableCell style={{ width: "25%" }}>
                                                            {t.translate("words.nominal")}
                                                          </TableCell>
                                                        </TableRow>
                                                      </TableHeader>
                                                      <TableBody>
                                                        {dataBeban.map((d, index) => {
                                                          return (
                                                            <TableRow key={index}>
                                                              <TableCell>{index + 1}</TableCell>
                                                              <TableCell>
                                                                {d.debitKredit == 1
                                                                  ? "Debit"
                                                                  : "Kredit"}
                                                              </TableCell>
                                                              <TableCell>{d.akun.nama}</TableCell>
                                                              <TableCell>Rp. {format(d.total)}</TableCell>
                                                              <TableCell>
                                                                <Button
                                                                  style={{
                                                                    backgroundColor: "#34495e",
                                                                    color: "white",
                                                                  }}
                                                                  onClick={() =>
                                                                    hapusBeban(index)
                                                                  }
                                                                  disabled={disabled}
                                                                >
                                                                  {t.translate("words.delete")}
                                                                </Button>
                                                              </TableCell>
                                                            </TableRow>
                                                          );
                                                        })}
                                                      </TableBody>
                                                    </Table>
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        width: "100%",
                                                        marginBottom: 50,
                                                        marginTop: 30,
                                                        backgroundColor: "#ecf0f1",
                                                        padding: 5,
                                                        borderRadius: 50,
                                                      }}
                                                    >
                                                      <TextContainer
                                                        style={{
                                                          display: "flex",
                                                          flexDirection: "column",
                                                          flex: 1,
                                                          padding: 5,
                                                        }}
                                                      >
                                                        <Text style={{ fontSize: 16 }}>
                                                          Total Debit :{" "}
                                                          <b>
                                                            Rp.{" "}
                                                            {format(
                                                              dataBeban
                                                                .filter((d) => d.debitKredit == 1)
                                                                .map((d) => d.total)
                                                                .reduce((total, datas) => {
                                                                  return total + datas;
                                                                }, 0)
                                                            )}
                                                          </b>
                                                        </Text>
                                                      </TextContainer>
                                                      <TextContainer
                                                        style={{
                                                          display: "flex",
                                                          flexDirection: "column",
                                                          flex: 1,
                                                          padding: 5,
                                                        }}
                                                      >
                                                        <Text style={{ fontSize: 16 }}>
                                                          Total Kredit :{" "}
                                                          <b>
                                                            Rp.{" "}
                                                            {format(
                                                              dataBeban
                                                                .filter((d) => d.debitKredit == 2)
                                                                .map((d) => d.total)
                                                                .reduce((total, datas) => {
                                                                  return total + datas;
                                                                }, 0)
                                                            )}
                                                          </b>
                                                        </Text>
                                                      </TextContainer>
                                                    </div>
                                                  </>
                                                ) : (
                                                  <TextContainer style={{ textAlign: "center" }}>
                                                    <Text
                                                      type="headline-6"
                                                      style={{ color: "#7f8c8d" }}
                                                    >
                                                      Tidak ada data
                                                    </Text>
                                                  </TextContainer>
                                                )}
                                              </div>
                                            ),
                                          },
                                     
                                    ]}
                                  
                                    submitLabel={t.translate('words.submit')}
                                    onSubmit={onSubmitProduk}
                                />
                            </ExpansionPanel>
                        </ExpansionList>
                    )
                },
            ]}
        // onSubmit={async (data, callback) => {

        // }}
        />
    )


    function _closeDialog() {
        setDialogPembelianProdukForm(false)
    }

    function _setDokumens(dokumens) {
        setDokumens(dokumens)
    }
}

export default inject('envStore', 'modalStore', 'navigationStore')(observer(PembelianPPNIdentitas))
