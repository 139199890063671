import React, { useState } from "react";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import service from "./Biaya.service";
import moment from "moment";
import 'moment/locale/id';
import {
  Button,
  Dialog,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "react-md";
import { inject, observer } from "mobx-react";
import utilsService from "../../services/utilsService";
import { format, formatRupiah } from "../../libs/react-mpk/services/number.service";
import { useLocation } from "react-router";

const STAT_BELUM_BAYAR = 1;
const STAT_BAYAR_SEBAGIAN = 2;
const STAT_LUNAS = 3;

const Biaya = ({
  className = "",
  history,
  match,
  showCommandbar = true,
  navigationStore,
}) => {
  const { state: { tahun } = {} } = useLocation();
  var tahunSobatBuku = sessionStorage.getItem("tahunSobatBuku");
  var npwpHeadId = match.params.npwpHeadId;
  var dataAkun = JSON.parse(sessionStorage.getItem("dataPenggunaNpwp"));
  const [showDialog, setDialog] = useState(false);
  const baseId = "biaya";
  const basePath = `/internal/npwp/${npwpHeadId}/biaya`;
  let KATEGORI_BIAYA = 2;
  return (
    <>
      <TableWrapper
        baseId={baseId}
        title={t.translate("modules.biaya.title")}
        className={className}
        hintMessage={t.translate("modules.biaya.hint.message")}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate("modules.biaya.hint.more")}
        defaultData={[]}
        defaultSortBy="tanggalDibuat"
        showFilter={false}
        showActionColumn={true}
        backTo={tahun&&`/internal/npwp/${npwpHeadId}/dashboard`}
        useFilter={true}
        defaultCollapse={true}
        useCriteria={true}
        selectable={false}
        useQueryHistory={false}
        onFetchData={(query) => {
          return new Promise(async (resolve, reject) => {
            try {
              query = {
                ...query,
                "kategori.equals": KATEGORI_BIAYA,
                tahun:tahun?tahun:tahunSobatBuku
              };
              let getPage = await service.getPage(query);
              getPage.headers["x-pagination-count"] =
                getPage.headers["x-total-count"];
              resolve(getPage);
            } catch (e) {
              resolve();
            }
          });
        }}
        columns={[
          {
            label: "Nomor Transaksi",
            searchable: true,
            sortable: false,
            key: "noTransaksi",
            render: (item) =>
              utilsService.beautifyNoTransaksi(item.noTransaksi),
          },
          {
            label: "Nama NPWP",
            searchable: true,
            sortable: false,
            key: "namaLawanTransaksi",
            render: (item) => item.lawanTransaksi.nama,
          },
          {
            label: "Nominal",
            searchable: false,
            sortable: false,
            key: "total",
            render: (item) => `${formatRupiah(item.total)}`,
          },
          {
            label: "Tanggal Transaksi",
            type: TableWrapper.dataTypes.DATE,
            render: (item) =>
              moment(item.tanggalTransaksi).locale('id').format("DD MMMM YYYY"),
          },
          {
            label: "Status Pembayaran",
            type: TableWrapper.dataTypes.BOOLEAN,
            render: (item) => (
              <TableWrapper.Status
                type={
                  item.pembayaran.status == STAT_LUNAS
                    ? TableWrapper.Status.types.SUCCESS
                    : item.pembayaran.status == STAT_BAYAR_SEBAGIAN
                    ? TableWrapper.Status.types.PROGRESS
                    : item.pembayaran.status == STAT_BELUM_BAYAR
                    ? TableWrapper.Status.types.IDLE
                    : TableWrapper.Status.types.ERROR
                }
              >
                {item.pembayaran.status == 1
                  ? "Belum Bayar"
                  : item.pembayaran.status == 2
                  ? "Bayar Sebagian"
                  : item.pembayaran.status == 3
                  ? "Lunas"
                  : "Batal"}
              </TableWrapper.Status>
            ),
          },
          {
            label: "Tanggal Dibuat",
            type: TableWrapper.dataTypes.DATE,
            render: (item) =>
              moment(item.tanggalDibuat).locale('id').format("DD MMMM YYYY, HH:mm:ss"),
          },
        ]}
        actions={[
          new TableWrapper.action(
            dataAkun.pkp == true ? "Biaya PPN" : "Biaya Non PPN",
            "mdi mdi-plus",
            (item) => {
              navigationStore.redirectTo(
                `/internal/npwp/${npwpHeadId}/biaya/new/non-ppn`
              );
              sessionStorage.setItem("id", item.id);
            },
            true
          ),
        ]}
        itemActions={[
          // new TableWrapper.action("Riwayat", "mdi mdi-history", (item) => {
          //   history.push(`biaya/${item.id}/history`);
          // }),
          new TableWrapper.action("Selengkapnya", "mdi mdi-import", (item) => {
            localStorage.setItem("biayaDetail", JSON.stringify(item));
            history.push(`biaya/${item.id}/detail/non-ppn`);
            sessionStorage.setItem("noInvoiceBiaya", item.noTransaksi);
          }),
          new TableWrapper.action(
            `${t.translate("words.jurnalEntri")}`,
            "mdi mdi-application",
            (item) => {
              localStorage.setItem("biayaJurnalEntry", JSON.stringify(item));
              history.push(`${basePath}/${item.noTransaksi}/jurnal-entri`);
            }
          ),
        ]}
        showCommandbar={showCommandbar}
        showFilterPeriod={true}
      />
      <Dialog
        id="add-data-dialog"
        visible={showDialog}
        onRequestClose={() => {
          setDialog(false);
        }}
      >
        <DialogHeader>
          <DialogTitle>{t.translate(`words.pilih`)}</DialogTitle>
        </DialogHeader>
        <DialogFooter>
          <Button
            onClick={(item) => {
              window.location.href = `biaya/new/non-ppn`;
              sessionStorage.setItem("id", item.id);
            }}
          >
            Biaya Non PPN
          </Button>
          {dataAkun.pkp == true ? (
            <Button
              onClick={(item) => {
                window.location.href = `${basePath}/tarikdata/new`;
                sessionStorage.setItem("id", item.id);
              }}
            >
              Biaya PPN
            </Button>
          ) : null}
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default inject("modalStore", "navigationStore")(observer(Biaya));
