import React, { useEffect, useState } from 'react'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import { FormWrapper, TableWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { find } from 'lodash'
import { useParams } from 'react-router'
import service from './Biaya.service'
import { toast } from '../../libs/react-mpk/services'

const statusBayar = [
    { value: 'lunas', name: 'Lunas' },
    { value: 'belumDibayar', name: 'Belum Dibayar' }
]


const akun = [
    { value: '1.1.01.0001', name: 'Kas' },
    { value: '1.1.02.0001', name: 'Bank' }
]

const BiayaDetailTransaksi = ({ envStore }) => {
    const { inputTypes, definition } = FormWrapper
    const [tempoDate, setTempoDate] = useState(null)
    const [disabled, setDisabled] = useState(true)
    const [hari, setHari] = useState(null)
    const [data, setData] = useState([])
    const { id } = useParams()

    const fetch = async () => {
        try {
            let res = await service.getById(id)
            setData(res.data)
        } catch (error) {
            toast.errorRequest(error)
        }
    }

    useEffect(() => {
        fetch()
    }, [])

    return (
        <FormWrapper
            actions={[
                {
                    label: 'Reload',
                    iconClassName: 'mdi mdi-reload'
                }
            ]}
            backTo={`/${envStore.env.applicationType}/pembelian`}
            baseId="mod-form-sample"
            title='Transaksi'
            hintShowIcon={false}
            defaultData={data}
            definitions={[
                {
                    inputType: inputTypes.DIVIDER,
                    label: 'General'
                },
                {
                    inputType: inputTypes.INPUT,
                    label: 'Sobat ingin membuat Faktur atas Transaksi:',
                    key: 'jnsTransaksi',
                    // name: 'Pembelian Normal',
                    disabled: true,
                },
                {
                    inputType: inputTypes.DIVIDER,
                    label: 'Data Transaksi'
                },
                {
                    inputType: inputTypes.INPUT,
                    className: 'mpk-padding-N padding-right',
                    label: 'Nomor Transaksi',
                    key: 'noTransaksi',
                    required: true,
                    disabled: true,
                    width: '50%',
                },
                // -----
                {
                    inputType: inputTypes.INPUT,
                    label: 'Tanggal Transaksi',
                    key: 'tanggalTransaksi',
                    width: '50%',
                    required: true,
                    disabled: true,
                },
                {
                    inputType: inputTypes.INPUT,
                    className: 'mpk-padding-N padding-right',
                    label: 'Status Pembayaran',
                    key: 'statusPembayaran',
                    width: '50%',
                    required: true,
                    disabled: true
                },
                {
                    inputType: inputTypes.INPUT,
                    label: 'Akun Utang',
                    key: 'akunUtang',
                    width: '50%',
                    required: true,
                    disabled: true
                },
                {
                    inputType: inputTypes.INPUT,
                    className: 'mpk-padding-N padding-right',
                    label: 'Periode Jatuh Tempo (Hari)',
                    key: 'jatuhTempo',
                    required: true,
                    disabled: true,
                    width: '50%',
                },
                {
                    inputType: inputTypes.INPUT,
                    label: 'Tanggal Jatuh Tempo',
                    key: 'tanggalTempo',
                    width: '50%',
                    required: true,
                    disabled: true
                },
                {
                    inputType: inputTypes.INPUT,
                    className: 'mpk-padding-N padding-right',
                    label: 'Transfer Dari',
                    key: 'transfer',
                    width: '50%',
                    required: true,
                    disabled: true
                }
            ]}
            onChange={(data, key, value) => {
                if (key === 'statusPembayaran') {
                    let bayar = find(statusBayar, { name: value })
                    if (bayar) data.bayarId = bayar.value
                }
                if (key === 'akunUtang' && 'transfer') {
                    let utang = find(akun, { name: value })
                    if (utang) data.utangId = utang.value
                }
                if (key === 'tanggalTempo') {
                    let tempo = new Date()
                    tempo.setDate(tempo.getDate() + 1)
                }
                if (key === 'jnsTransaksi') {
                    setDisabled(false)
                }
                return data
            }}
        />
    )
}

export default inject('envStore')(observer(BiayaDetailTransaksi))
