import React, { useEffect, useState } from "react";
import { TableWrapper, TabsWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import moment from "moment";
import "moment/locale/id";
// import DetailLawanTransaksiForm from './DetailLawanTransaksiForm'
import { DataForm } from "../../libs/react-mpk/components";
import { FormWrapper } from "../../libs/react-mpk/wrapper";
import {
  format,
  formatRupiah,
} from "./../../libs/react-mpk/services/number.service";
import Modal from "../../libs/react-mpk/components/Modal";
// import LawanTransaksiNilaiTagihan from './LawanTransaksiNilaiTagihan';
import { inject, observer } from "mobx-react";
import service from "./LawanTransaksi.service";
import { useParams } from "react-router";
import { CircularProgress } from "react-md";

const DetailLawanTransaksi = ({
  className = "",
  envStore,
  navigationStore,
  showCommandbar = true,
}) => {
  const { inputTypes, definition } = FormWrapper;
  const [inputValidasiNpwp, setInputValidasiNpwp] = useState(
    "##.###.###.#-###.###"
  );
  const [dialogNilaiTagihan, setDialogNilaiTagihan] = useState(false);
  const [dialogData, setDialogData] = useState(false);
  const [dokumens, setDokumens] = useState([]);
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dataTagihan, setDataTagihan] = useState([]);

  const basePath = `lawan-transaksi`;
  const npwpHeadId = sessionStorage.getItem("npwpHeadId");

  function showDialogNilaiTagihan() {
    setDialogNilaiTagihan(true);
    setDialogData({});
  }

  const fetchData = async () => {
    setIsLoading(true);
    let res = await service.getById(id);
    setData(res.data);
    setIsLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, []);

  var actions = [
    // new TableWrapper.action(
    //   `${t.translate('words.add')}`,
    //   'mdi mdi-plus',
    //   () => {
    //     setSelectedItem(null)
    //     setShowForm(true)
    //   },
    //   true)
  ];

  return (
    <>
      <Modal
        id="dokumen-form"
        aria-label="dokumen-form"
        // title={t.translate('words.formulirDasarPemotong')}
        visible={dialogNilaiTagihan}
        onRequestClose={_closeDialog}
        style={{
          width: 800,
          height: 650,
        }}
        disableFocusOnMount={true}
      ></Modal>
      <TabsWrapper
        showCommandbar={false}
        baseId="mod-rincian-setor-sendiri-sample"
        title={`Pelaku Transaksi`}
        tabs={[
          {
            label: `Pelaku Transaksi`,
            key: "lawanTransaksi",
            render: (
              <>
                {isLoading ? (
                  <CircularProgress />
                ) : (
                  <FormWrapper
                    actions={[
                      {
                        label: "Reload",
                        iconClassName: "mdi mdi-reload",
                      },
                    ]}
                    backTo={`/${envStore.env.applicationType}/npwp/${npwpHeadId}/lawan-transaksi`}
                    baseId="mod-form-sample"
                    title={`Data Pelaku Transaksi`}
                    hintMessage={`Pelaku Transaksi`}
                    hintShowIcon={false}
                    defaultData={data}
                    definitions={[
                      {
                        inputType: inputTypes.DIVIDER,
                        label: "Pelaku Transaksi",
                      },
                      {
                        inputType: inputTypes.INPUT,
                        label: `Pelaku Transaksi`,
                        key: "kategori",
                        disabled: true,
                        value: data.kategori === 1 ? "Customer" : "Supplier",
                      },
                      {
                        inputType: inputTypes.INPUT,
                        label: t.translate("words.name"),
                        key: "nama",
                        type: "text",
                        validation: "required",
                        disabled: true,
                      },
                      {
                        inputType: inputTypes.INPUT,
                        label: "Jenis Pelaku Transaksi",
                        key: "jenisUsaha",
                        disabled: true,
                        value:
                          data.jenisUsaha === 1 ? "Badan" : "Orang Pribadi",
                      },
                      {
                        inputType: inputTypes.DIVIDER,
                        label: `Data Pelaku Transaksi`,
                      },
                      {
                        inputType: inputTypes.INPUT_MASK_NUMBER,
                        label: t.translate(`words.identityNpwp`),
                        key: "npwp",
                        type: "text",
                        mask: inputValidasiNpwp,
                        maskChar: "_",
                        maskValue: "string",
                        disabled: true,
                      },
                      {
                        inputType: inputTypes.INPUT,
                        label: t.translate("words.nikPaspor"),
                        key: "nik",
                        disabled: true,
                      },
                      {
                        inputType: inputTypes.INPUT,
                        label: t.translate("words.address"),
                        key: "alamat",
                        disabled: true,
                      },
                      {
                        inputType: inputTypes.INPUT,
                        label: t.translate("words.phone"),
                        key: "noTelepon",
                        disabled: true,
                      },
                      {
                        inputType: inputTypes.DIVIDER,
                        label: t.translate("words.picPenagihan"),
                      },
                      {
                        inputType: inputTypes.INPUT,
                        label: t.translate("words.namaPenanggungJawab"),
                        key: "namaPic",
                        disabled: true,
                      },
                      {
                        inputType: inputTypes.INPUT,
                        label: t.translate("words.email"),
                        key: "emailPic",
                        disabled: true,
                      },
                      {
                        inputType: inputTypes.INPUT,
                        label: t.translate("words.phone"),
                        key: "noTeleponPic",
                        disabled: true,
                      },
                      {
                        inputType: inputTypes.CHECKBOX,
                        label: t.translate(
                          `words.alamatSamaDenganDataLawanTransaksi`
                        ),
                        key: "alamatSama",
                        name: "alamatSama",
                        style: {
                          width: "100%",
                        },
                        disabled: true,
                      },
                      {
                        inputType: inputTypes.INPUT,
                        label: t.translate("words.address"),
                        key: "alamatPic",
                        disabled: true,
                      },
                    ]}
                    onChange={(data, tab, callback) => {}}
                  />
                )}
              </>
            ),
          },
          {
            label: t.translate(
              "modules.lawanTransaksi.detail.daftarAkunTagihan.title"
            ),
            key: "akunTagihan",
            render: (
              <>
                <TableWrapper
                  baseId="mod-table-sample"
                  onFetchData={(query) => {
                    return new Promise(async (resolve, reject) => {
                      try {
                        // var kategori = { 'kategori.equals': 2 }
                        query = {
                          // ...search,
                          ...query,
                          // ...kategori
                        };
                        // let getPage = await service.get(query)
                        let getPage = await service.getAkunTagihan(id, query);
                        setDataTagihan(getPage.data);
                        // console.log(getPage.data, "getPage.data");
                        getPage.headers["x-pagination-count"] =
                          getPage.headers["x-total-count"];
                        resolve(getPage);
                      } catch (e) {
                        resolve();
                        // ErrorService(e)
                      }
                    });
                  }}
                  backTo={`/${envStore.env.applicationType}/npwp/${npwpHeadId}/lawan-transaksi`}
                  title={t.translate(
                    "modules.lawanTransaksi.detail.daftarAkunTagihan.title"
                  )}
                  className={className}
                  hintMessage={t.translate(
                    "modules.lawanTransaksi.detail.daftarAkunTagihan.hint.message"
                  )}
                  hintIconClassName="mdi mdi-information"
                  hintMore={t.translate(
                    "modules.lawanTransaksi.detail.daftarAkunTagihan.hint.more"
                  )}
                  defaultData={[]}
                  defaultSortBy="date"
                  defaultCollapse={true}
                  useCriteria={false}
                  selectable={false}
                  useQueryHistory={false}
                  // extraFilter={<div>Test</div>}
                  columns={[
                    {
                      label: "Kode",
                      searchable: true,
                      sortable: false,
                      key: "kode",
                      render: (item) => item.akun.kode,
                    },
                    {
                      label: "Nama",
                      searchable: true,
                      sortable: false,
                      key: "nama",
                      render: (item) => item.akun.nama,
                    },
                    {
                      label: "Sisa Tagihan",
                      searchable: true,
                      sortable: false,
                      key: "nominal",
                      render: (item) => formatRupiah(item.saldoAkhir),
                    },
                  ]}
                  actions={actions}
                  itemActions={[
                    new TableWrapper.action(
                      "Detail",
                      "mdi mdi-import",
                      (item) => {
                        if (data.kategori == 1) {
                          navigationStore.redirectTo(
                            `/internal/npwp/${data.npwpHead.id}/lawan-transaksi/${id}/piutang/${item.akun.id}`
                          );
                        } else if (data.kategori == 2) {
                          navigationStore.redirectTo(
                            `/internal/npwp/${data.npwpHead.id}/lawan-transaksi/${id}/hutang/${item.akun.id}`
                          );
                        }
                      },
                      true
                    ),
                  ]}
                  // onFetchData={ async query => {
                  //   return service.get(query)
                  // }}
                  showCommandbar={showCommandbar}
                  showFilterPeriod={false}
                  useFilter={false}
                />
              </>
            ),
          },
        ]}
        // onChange={(tab, callback) => {
        //   navigationStore.redirectTo(
        //     `/internal/npwp/${data.npwpHead.id}/lawan-transaksi/${data.id}/${tab.key}`
        //   )
        //   callback()
        // }}
      />
    </>
  );

  function _closeDialog() {
    setDialogNilaiTagihan(false);
  }

  function _setDokumens(dokumens) {
    setDokumens(dokumens);
  }
};

export default inject(
  "envStore",
  "navigationStore"
)(observer(DetailLawanTransaksi));
