import React, { useEffect, useState } from "react";
import { DataForm, Hint } from "../../libs/react-mpk/components";
import t from "counterpart";
import { FormWrapper } from "../../libs/react-mpk/wrapper";
import {
  Table,
  TableBody,
  TableHeader,
  TableRow,
  TableCell,
  Button,
  FontIcon,
} from "react-md";
import service from "./KasDanBank.service";
import KasDanBankPemilikModalForm from "./KasDanBankPemilikModalForm";
import { inject, observer } from "mobx-react";
import { toast } from "../../libs/react-mpk/services";

const defaultData = () => ({
  id: null,
  kodeAkun: null,
  kategoriAkunId: null,
  namaAkun: null,
  kategoriAsetId: null,
  kategoriHutangId: null,
  defaultKoreksiFiskal: false,
  koreksiFiskalId: null,
  monitoringLawanTransaksi: false,
  inputNominatifEntertaint: false,
  inputNominatifPromosi: false,
  kategoriAkun: {
    id: null,
  },
  kategoriAset: {
    id: null,
  },
  kategoriHutang: {
    id: null,
  },
  koreksiFiskal: {
    id: null,
  },
  tahun: new Date().getFullYear(),
});

const KasDanBankTambahAkunBankForm = ({
  visible,
  authStore,
  envStore,
  modalStore,
  match,
  history,
  onRequestClose = () => {},
  ...props
}) => {
  const { inputTypes, definition } = FormWrapper;
  //JENIS KATEGORI ASET
  let AST_ASET = 1;
  let AST_HUTANG = 2;
  let AST_PENJUALAN = 3;
  let AST_BEBAN = 4;
  let AST_MODAL = 5;

  const [kategoriAkunList, setKategoriAkunList] = useState([]);
  const [kategoriAsetList, setKategoriAsetList] = useState([]);
  const [kategoriHutangList, setKategoriHutangList] = useState([]);
  const [pemilikModalList, setPemilikModalList] = useState([]);

  const [kodeAkun, setKodeAkun] = useState("");

  let [dataSubmit, setDataSubmit] = useState({
    id: null,
    kode: "",
    nama: "",
    monitoringLawanTransaksi: false,
    monitoringPersediaan: false,
    inputNominatifEntertaint: false,
    inputNominatifPromosi: false,
    defaultKoreksiFiskal: false,
    kategoriAkun: {
      id: null,
    },
    kategoriAset: {
      id: null,
    },
    kategoriHutang: {
      id: null,
    },
    npwpHead: {
      id: null,
    },
    koreksiFiskal: {
      id: null,
    },
    tahun: new Date().getFullYear(),
  });
  let [item, setItem] = useState(null);
  let [ready, setReady] = useState(true);
  let [isLoading, setIsLoading] = useState(false);
  const [validasiKategoriAkun, setValidasiKategoriAkun] = useState(null);
  const [showFormPemilikModal, setShowFormPemilikModal] = useState(false);

  useEffect(() => {
    async function initData() {
      if (visible) {
        let selectedItem = defaultData();
        if (props.itemId) {
          try {
            let res = await service.getOneAkunById(props.itemId);

            let { data } = res;

            setDataSubmit(data);

            selectedItem.id = data.id;
            selectedItem.kodeAkun = data.kode;
            if (data.kategoriAkun) {
              selectedItem.kategoriAkunId = `${data.kategoriAkun.kode} - ${data.kategoriAkun.nama}`;
              selectedItem.kategoriAkun.id = data.kategoriAkun.id;
              setValidasiKategoriAkun(data.kategoriAkun.kategoriAset);
            }
            selectedItem.namaAkun = data.nama;
            if (data.kategoriAset) {
              selectedItem.kategoriAsetId = `${data.kategoriAset.kode} - ${data.kategoriAset.nama}`;
              selectedItem.kategoriAset.id = data.kategoriAset.id;
            }
            if (data.kategoriHutang) {
              selectedItem.kategoriHutangId = `${data.kategoriHutang.kode} - ${data.kategoriHutang.nama}`;
              selectedItem.kategoriHutang.id = data.kategoriHutang.id;
            }
            selectedItem.defaultKoreksiFiskal = data.defaultKoreksiFiskal;
            if (data.koreksiFiskal) {
              selectedItem.koreksiFiskalId = `${data.koreksiFiskal.kode} - ${data.koreksiFiskal.nama}`;
              selectedItem.koreksiFiskal.id = data.koreksiFiskal.id;
            }
            selectedItem.monitoringLawanTransaksi =
              data.monitoringLawanTransaksi;
            selectedItem.inputNominatifEntertaint =
              data.inputNominatifEntertaint;
            selectedItem.inputNominatifPromosi = data.inputNominatifPromosi;
          } catch (e) {
            toast.errorRequest(e);
            onRequestClose();
          }
        } else {
        }
        setItem(selectedItem);
      } else {
        setItem({});
        getKategoriAkun();
        getKategoriAset();
        getKategoriHutang();
      }
      setReady(visible);
    }
    initData();
    // getKoreksiFiskal()
  }, [visible]);

  async function getKategoriAkun() {
    setIsLoading(true);
    let query = {
      page: 0,
      size: 9999,
      sort: "kode,ASC",
    };
    let res = await service.getListKategoriAkun(query);
    var kategoriAkuns = [];
    res.data.map((d) => {
      kategoriAkuns.push({
        value: d.id,
        label: `${d.kode} - ${d.nama}`,
        kategoriAset: d.kategoriAset,
      });
    });
    setKategoriAkunList(kategoriAkuns);
    setIsLoading(false);
  }

  async function getKategoriAset() {
    let res = await service.getListKategoriAset();
    var kategoriAsets = [];
    res.data.map((d) => {
      kategoriAsets.push({
        value: d.id,
        label: `${d.kode} - ${d.nama}`,
        // rates: d.parentKategori
      });
    });
    setKategoriAsetList(kategoriAsets);
  }

  async function getKategoriHutang() {
    let res = await service.getListKategoriHutang();
    var kategoriHutangs = [];
    res.data.map((d) => {
      kategoriHutangs.push({
        value: d.id,
        label: `${d.kode} - ${d.nama}`,
        // rates: d.parentKategori
      });
    });
    setKategoriHutangList(kategoriHutangs);
  }

  async function generateKodeAkun(formData) {
    service
      .generateKodeAkun(formData.kategoriAkun.id)
      .then((res) => setKodeAkun(res.data.message));
  }

  async function addKepemilikanModal() {
    var kepemilikanModalForm = JSON.parse(
      localStorage.getItem("kepemilikanModalForm")
    );
    if (kepemilikanModalForm) {
      var pemilikModalListNew = pemilikModalList;

      pemilikModalListNew.push(kepemilikanModalForm);

      setPemilikModalList(pemilikModalListNew);

      localStorage.removeItem("kepemilikanModalForm");
    }
  }

  async function deleteKepemilikanModal(index) {
    modalStore.showConfirm({
      title: t.translate("words.delete"),
      children: t.translate("words.confirmHapus"),
      onSubmit: (callback) => {
        var pemilikModalListNew = pemilikModalList;
        pemilikModalListNew.splice(index, 1);
        setPemilikModalList(pemilikModalListNew);
        callback();
      },
    });
  }

  const onSubmit = async (data, callback) => {
    let reqData = dataSubmit;

    //ISI FIELD
    reqData.id = data.id;
    reqData.kode = data.kodeAkun == null ? kodeAkun : data.kodeAkun;
    reqData.nama = data.namaAkun;
    reqData.monitoringLawanTransaksi = data.monitoringLawanTransaksi;
    reqData.monitoringPersediaan = data.monitoringPersediaan;
    reqData.inputNominatifEntertaint = data.inputNominatifEntertaint;
    reqData.inputNominatifPromosi = data.inputNominatifPromosi;
    reqData.defaultKoreksiFiskal = data.defaultKoreksiFiskal;

    if (reqData.kategoriAkun) {
      reqData.kategoriAkun.id = data.kategoriAkun.id
        ? data.kategoriAkun.id
        : null;
    }

    // reqData.kategoriAset = null
    if (reqData.kategoriAset) {
      reqData.kategoriAset.id = data.kategoriAset.id
        ? data.kategoriAset.id
        : null;
    }

    // reqData.kategoriHutang = null
    if (reqData.kategoriHutang) {
      reqData.kategoriHutang.id = data.kategoriHutang.id
        ? data.kategoriHutang.id
        : null;
    }
    if (!reqData.monitoringPersediaan) reqData.monitoringPersediaan = false;

    // if(reqData.koreksiFiskal)
    // 	reqData.koreksiFiskal.id = data.koreksiFiskal.id?data.koreksiFiskal.id:null
    reqData.koreksiFiskal = null;

    reqData.npwpHead.id = sessionStorage.getItem("npwpHeadId");

    // console.log(data);
    // console.log(reqData);
    // console.log(npwpHead);

    if (reqData.id) {
      try {
        const res = await service.updateAKun(reqData);
        let { data, status } = res;
        callback("success", false);
      } catch (err) {
        callback(err, true, false);
      }
    } else {
      try {
        const res = await service.createAkun(reqData);
        callback("success", false);
      } catch (err) {
        callback(err, true, false);
      }
    }
  };

  return (
    ready && (
      <>
      
        <DataForm
          baseId="tambah-akun-bank-form"
          title={t.translate(`modules.KasDanBank.form`)}
          visible={visible}
          onRequestClose={onRequestClose}
          asDialog={true}
          defaultData={item}
          definitions={[
            {
              inputType: FormWrapper.inputTypes.INPUT,
              label: t.translate(`words.namaAkun`),
              key: "namaAkun",
            },
            {
              inputType: DataForm.inputTypes.AUTOCOMPLETE,
              label: t.translate(`words.kategoriAkun`),
              key: "kategoriAkunId",
              validation: "required",
              labelKey: "label",
              valueKey: "label",
              data: kategoriAkunList,
              disabled:isLoading
            },
            {
              inputType: DataForm.inputTypes.INPUT,
              label: t.translate(`words.kodeAkun`),
              key: "kodeAkun",
              disabled: true,
              value: kodeAkun,
            },
            {
              inputType: DataForm.inputTypes.AUTOCOMPLETE,
              label: t.translate(`words.kategoriAset`),
              key: "kategoriAsetId",
              labelKey: "label",
              valueKey: "label",
              data: kategoriAsetList,
              show: validasiKategoriAkun === AST_ASET,
            },
            {
              inputType: DataForm.inputTypes.AUTOCOMPLETE,
              label: t.translate(`words.kategoriHutang`),
              key: "kategoriHutangId",
              labelKey: "label",
              valueKey: "label",
              type: "search",
              data: kategoriHutangList,
              show: validasiKategoriAkun === AST_HUTANG,
            },
            {
              render: (
                <div>
                  <p style={{ marginLeft: "10px", marginBottom: "-15px" }}>
                    <b>{t.translate(`words.kategoriMonitoring`)}</b>
                  </p>
                  <br />
                </div>
              ),
              show: validasiKategoriAkun === AST_BEBAN,
            },
            {
              inputType: inputTypes.CHECKBOX,
              label: t.translate(`words.monitoringLawanTransaksi`),
              key: "monitoringLawanTransaksi",
              name: "monitoringLawanTransaksi",
              style: {
                width: "100%",
              },
              show: validasiKategoriAkun === AST_BEBAN,
            },
            {
              inputType: inputTypes.CHECKBOX,
              label: t.translate(`words.inputNominatifEntertaint`),
              key: "inputNominatifEntertaint",
              name: "inputNominatifEntertaint",
              style: {
                width: "100%",
              },
              show: validasiKategoriAkun === AST_BEBAN,
            },
            {
              inputType: inputTypes.CHECKBOX,
              label: t.translate(`words.inputNominatifPromosi`),
              key: "inputNominatifPromosi",
              name: "inputNominatifPromosi",
              style: {
                width: "100%",
              },
              show: validasiKategoriAkun === AST_BEBAN,
            },
            {
              render: (
                <div style={{ width: "100%" }}>
                  <Button
                    theme="primary"
                    themeType="contained"
                    style={{ margin: 15 }}
                    onClick={() => {
                      setShowFormPemilikModal(true);
                    }}
                  >
                    <FontIcon iconClassName="mdi mdi-plus" />
                    {t.translate("words.add")}
                  </Button>
                  <Hint
                    className="mpk-margin-N margin-top margin-bottom"
                    message="Komposisi Kepemilikan Modal"
                  />
                  <Table style={{ width: "100%" }}>
                    <TableHeader>
                      <TableRow>
                        <TableCell style={{ width: "10%" }}>
                          {t.translate("words.non")}
                        </TableCell>
                        <TableCell style={{ width: "25%" }}>
                          {t.translate("words.nama")}
                        </TableCell>
                        <TableCell style={{ width: "25%" }}>
                          {t.translate("words.jumlah")}
                        </TableCell>
                        <TableCell style={{ width: "25%" }}>
                          {t.translate("words.persentase")}
                        </TableCell>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {pemilikModalList.map((d, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{d.nama}</TableCell>
                            <TableCell>{d.nominalModal}</TableCell>
                            <TableCell>{d.ptsModal}</TableCell>
                            <TableCell>
                              <Button
                                onClick={() => deleteKepemilikanModal(index)}
                              >
                                {t.translate("words.delete")}
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                  <br />
                </div>
              ),
              show: validasiKategoriAkun === AST_MODAL,
            },
          ]}
          onChange={async (formData, key, value) => {
            if (key == "kategoriAkunId") {
              kategoriAkunList.map((d) => {
                if (d.label == value) {
                  formData["kategoriAkun"]["id"] = d.value;
                  setValidasiKategoriAkun(d.kategoriAset);
                }
              });
              if (formData["kategoriAkun"]["id"] != null) {
                await generateKodeAkun(formData);
              }
            }
            if (key == "kategoriAsetId") {
              kategoriAsetList.map((d) => {
                if (d.label == value) {
                  formData["kategoriAset"]["id"] = d.value;
                }
              });
            }
          }}
          onSubmit={onSubmit}
        />

        <KasDanBankPemilikModalForm
          visible={showFormPemilikModal}
          onRequestClose={() => {
            addKepemilikanModal();
            setShowFormPemilikModal(false);
          }}
        />
      </>
    )
  );
};

export default inject(
  "navigationStore",
  "envStore",
  "modalStore"
)(observer(KasDanBankTambahAkunBankForm));
