import React, { useEffect, useState } from "react";
import { DataForm } from "../../../libs/react-mpk/components";
import t from "counterpart";
import { FormWrapper } from "../../../libs/react-mpk/wrapper";
import DataAwalService from "./DataAwal.service";
import { cloneDeep } from "lodash";
import moment from "moment";
import "moment/locale/id";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogContent,
  DialogTitle,
  DialogFooter,
  Text,
} from "react-md";
import { toast } from "../../../libs/react-mpk/services";

const defaultData = () => ({
  tanggalMulai: null,
  kenaPphFinal: false,
  kategoriCatat: "1",
});

const MigrasiDataAwal = ({
  visible = false,
  authStore,
  envStore,
  match,
  history,
  baru = "",
  onRequestClose = () => {},
  ...props
}) => {
  const { inputTypes, definition } = FormWrapper;

  // const [data, setData] = useState({loading: true, content: {}})
  const [organization, setOrganization] = useState([]);
  let [data, setData] = useState(null);
  let [item, setItem] = useState(null);
  let [ready, setReady] = useState(true);
  let [disabledTanggalPkp, setDisabledTanggalPkp] = useState(false);
  let [disabledIsPencatatanPembukuan, setDisabledIsPencatatanPembukuan] =
    useState(false);

  let PBKTYPE_PBK = "1";
  let PBKTYPE_CATAT = "2";

  useEffect(() => {
    async function initData() {
      if (visible) {
        let selectedItem = defaultData();

        // if(props.itemId){
        // 	try {
        // 		let res = await service.getOneById(props.itemId)

        // 		let { data } = res;
        // 	} catch(e){
        // 	}
        // }
        setItem(selectedItem);
      } else {
        setItem(defaultData());
      }
      setReady(visible);
    }
    initData();
  }, [visible]);

  const onSubmit = async (data, callback) => {
    let reqData = data;

    reqData.tanggalMulai = moment(new Date(reqData.tanggalMulai))
      .locale("id")
      .format("YYYY-MM-DD hh:mm:ss");

    try {
      if (reqData.id) {
        const res = await DataAwalService.updateDataAwal(reqData.id, reqData);
      } else {
        const res = await DataAwalService.createDataAwal(reqData);
      }
      callback("success", false);
    } catch (err) {
      toast.errorRequest(err);
      callback(err, true, false);
    }
  };

  return (
    ready && (
      <div>
        <DataForm
          baseId="migrasi-data-awal-form"
          title={`Migrasi Data Awal`}
          visible={visible}
          // data={item}
          onRequestClose={onRequestClose}
          asDialog={true}
          defaultData={item}
          definitions={[
            {
              inputType: inputTypes.DATEPICKER,
              label: t.translate(`words.tanggalMulaiPencatatan`),
              key: "tanggalMulai",
              width: "100%",
              type: "date",
              validation: "required",
              // disabled: disabledTanggalPkp,
              // hidden: disabledTanggalPkp,
            },
            // {
            // 	render: (
            // 		<div>
            // 		<p style={{marginLeft: "10px"}}><b>{t.translate(`words.dikenakanPphFinalPP23`)}</b></p><br/>
            // 		</div>
            // 	)
            // },
            // {
            // 	inputType: inputTypes.CHECKBOX,
            // 	label: t.translate(`words.yes`),
            // 	key: 'kenaPphFinal',
            // 	name: 'kenaPphFinal',
            // 	style: {
            // 		width: '100%'
            // 	}
            // },
            {
              render: (
                <div>
                  <p style={{ marginLeft: "10px" }}>
                    <b>{t.translate(`words.pembukuanPencatatan`)}</b>
                  </p>
                  <br />
                </div>
              ),
            },
            {
              inputType: inputTypes.RADIO,
              label: t.translate(`words.pembukuan`),
              key: "kategoriCatat",
              name: "kategoriCatat",
              value: PBKTYPE_PBK,
              style: {
                width: "100%",
              },
              disabled: disabledIsPencatatanPembukuan,
              hidden: disabledIsPencatatanPembukuan,
            },
          ]}
          onChange={async (formData, key, value) => {}}
          // onBeforeChange={(key, value) => {
          // 	console.log(key, value)
          // 	return value
          // }}
          onSubmit={onSubmit}
        />
      </div>
    )
  );
};

export default MigrasiDataAwal;
