import React, { useEffect, useState } from 'react';
import { inputTypes } from '../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import { Hint } from '../../libs/react-mpk/components'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { find } from 'lodash'
import { ExpansionList, ExpansionPanel, Table, TableBody, TableHeader, TableRow, TableCell, Card, Button, FontIcon, TableFooter } from 'react-md'
import Modal from '../../libs/react-mpk/components/Modal'

const cities = [
  {id: 1, name: 'Jakarta'},
  {id: 2, name: 'Bandung'},
  {id: 3, name: 'Semarang'},
  {id: 4, name: 'Yogyakarta'},
  {id: 5, name: 'Surabaya'}
]

const KasDanBankDetailPenjualan = ({
	envStore,
    navigationStore,
	match,
	...props}) => {
	const [data, setData] = useState({loading: true, content: {}})
	const [panel1, setPanel1] = useState(true)
	const [panel2, setPanel2] = useState(true)
	const [panel3, setPanel3] = useState(true)
	const [showModal, setShowModal] = useState(false)
	const [isHidden, setIsHidden] = useState(false)
	const [dialogPenjualanProdukForm, setDialogPenjualanProdukForm] = useState(false)
	const [dialogData, setDialogData] = useState(false)
	const [dokumens, setDokumens] = useState([])

	// VALIDASI
	const [inputValidasiNpwp, setInputValidasiNpwp] = useState('##.###.###.#-###.###')
	const [inputValidasiNik, setInputValidasiNik] = useState('################')

	function addItem(){
	  setDialogPenjualanProdukForm(true)
	  setDialogData({})
	}

	useEffect(() => {
        async function initData(){
			var penjualanForm = JSON.parse(localStorage.getItem("penjualanForm"))
			localStorage.setItem("penjualanForm", JSON.stringify({
				sptNpwp: "",
				sptMonth: "",
				sptYear: "",
				sptRev: "",
				signerName: "",
				signerNpwp: "",
				actAs: "",
				signerId: "",
				refs: [],
				address: "-",
				phone: "0",
				postalCode: "0",
				province: "-",
				city: "-",
				district: "-",
				subDistrict: "-",
				withoutIncome: true,
				bruto: "0",
				rates: "0",
				ratesValue: "0",
				...penjualanForm
			}))
			setData({ content: {}, loading: false })
        }
        initData()
      }, [])
	

	const activePanel1 = ()=> {
        setPanel1(true);
        setPanel2(false);
        setPanel3(false);
      }

      const activePanel2 = ()=> {
        setPanel1(false);
        setPanel2(true);
        setPanel3(false);
      }

      const activePanel3 = ()=> {
        setPanel1(false);
        setPanel2(false);
        setPanel3(true);
      }


	var onSubmit = async (data, callback) => {
		// data.form1valid = true
		// var bpform = JSON.parse(localStorage.getItem('bpform'))
		// bpform = Object.assign(bpform, data)
		// localStorage.setItem('bpform', JSON.stringify(bpform))
		props.activePanel()
	}

	const baseName = `kas-dan-bank-detail/:id/selengkapnya`
	const basePath = `penjualan`
	var backTo = `/internal/kas-dan-bank-detail/:id/selengkapnya`

	var produkData = [
		{
			kode: "6.1.109",
			nama: "Beban Jasa",
			jumlahBarang: "10",
			nominal: "10000000",
			dpp: "10000000",
			ppn: "10000000",
			ppnBm: "10000000",
            id:'1'
		}
	];

	var penjualanSebelumPajak = [
		{
			namaAkun: "Penjualan",
			kodeAkun: "4.1.01.0001",
			nominal: "30200000",
            id:'1'
		}
	];

	var disetorSendiri = [
		{
			namaAkun: "Penjualan",
			kodeAkun: "4.1.01.0001",
			nominal: "30200000",
		},
		{
			namaAkun: "Penjualan",
			kodeAkun: "4.1.01.0001",
			nominal: "30200000",
		}
	];

	// setDokumens(dokumensData);

	return (
		
		<FormWrapper 
			loading={data.loading}
			backTo={backTo}
			showCommandbar={true}
			baseId={`mod-form-${baseName}`}
			title={t.translate(`modules.KasDanBank.detailPenjualan`)}
			style={{
				maxWidth: '100%'
			}}
			// defaultData={data.content}
			definitions={[
				{ 
					render: (
						<ExpansionList style={{ width: '100%' }}>
							{/* FORM IDENTITAS */}
							<ExpansionPanel expanded={panel1} onClick={()=> activePanel1()} header={ t.translate('words.identity')}>
								<FormWrapper
									submitLabel={t.translate('words.next')}
									loading={data.loading}
									showCommandbar={false}
									baseId={`mod-form-${baseName}`}
									style={{
									maxWidth: '100%'
									}}
									defaultData={data.content}
									definitions={[
										{
											render: (
												<div style={{ width: '100%'}}>
													<p style={{margin: "10px"}}><b>{t.translate(`words.dataLawanTransaksi`)}</b></p>
												</div>
											)
										},
										{
											inputType: inputTypes.REACT_SELECT,
                    isClearable:true,
											label: t.translate(`words.nama`),
											key: 'nama',
											validation: 'required',
											labelKey: 'label', 
											valueKey: 'label',
											// data: organization,
											// options: organization,
											async: true,
											// defaultOptions: organization,
											loadOptions: async (inputValues)=> {
											//   if(AppMode.onPremise){
											// 	if(inputValues.length % 2){
											// 	  var options = await getOrganizationsOp(inputValues)
											// 	  return options
											// 	} else {
											// 	  return organization
											// 	}
											//   } else {
											// 	var filter = organization.filter((org)=> {
											// 	  return org.label.indexOf(inputValues) != -1
											// 	})
											// 	return filter
											//   }
											}
										},
										{
											inputType: inputTypes.INPUT_MASK_NUMBER,
											label: t.translate(`words.identityNpwp`),
											key: 'npwp',
											type: 'text',
											mask: inputValidasiNpwp,
											maskChar: '_',
											maskValue: 'string',
											disable: true,
											width: '49%',
											style: {
												marginRight: "2%"
											}
										},
										{
											inputType: inputTypes.INPUT_MASK_NUMBER,
											label: t.translate(`words.identityNik`),
											key: 'nik',
											type: 'text',
											mask: inputValidasiNik,
											maskChar: '_',
											maskValue: 'string',
											disable: true,
											width: '49%',
										},
										{
											inputType: inputTypes.INPUT,
											label: t.translate(`words.address`),
											key: 'alamat',
											disable: true,
											type: 'text',
											width: '100%'
										},
										{
											render: (
												<div style={{ width: '100%'}}>
													<p style={{margin: "10px"}}><b>{t.translate(`words.picPenagihan`)}</b></p>
												</div>
											)
										},
										{
											inputType: inputTypes.INPUT,
											label: t.translate(`words.namaPic`),
											key: 'namaPic',
											type: 'text',
											disable: true,
											width: '49%',
											style: {
												marginRight: "2%"
											}
										},
										{
											inputType: inputTypes.INPUT,
											label: t.translate(`words.emailPic`),
											key: 'emailPic',
											type: 'text',
											disable: true,
											width: '49%',
										},
										{
											inputType: inputTypes.INPUT_MASK_NUMBER,
											label: t.translate(`words.nomorTeleponPic`),
											key: 'nomorTeleponPic',
											type: 'text',
											disable: true,
											width: '49%',
											style: {
												marginRight: "2%"
											}
										},
										{
											inputType: inputTypes.INPUT,
											label: t.translate(`words.alamatPic`),
											key: 'alamatPic',
											type: 'text',
											disable: true,
											width: '49%',
										},
									]}
									onSubmit={onSubmit}
								/>

							</ExpansionPanel>
							
							<br />

							{/* FORM TRANSAKSI */}
							<ExpansionPanel expanded={panel2} onClick={()=> activePanel2()} header={ t.translate('words.transaksi')}>
								<FormWrapper
									submitLabel={t.translate('words.next')}
									loading={data.loading}
									showCommandbar={false}
									baseId={`mod-form-${baseName}`}
									style={{
									maxWidth: '100%'
									}}
									defaultData={
                                        {
                                            sobatInginMembuatFakturAtasTransaksi:"Penjualan Normal",
                                            nomorTransaksi:"PJ-2021/07/0001",
                                            statusPembayaranInt:"Belum Dibayar",
                                            akunPiutang:"1.1.04.0001 - Piutang Usaha",
                                            jatuhTempo:"3",
                                            tanggalJatuhTempo:"2021/27/29",
                                            terimaDari:"1.1.01.0001 - Kas",
                                            detailTransaksi:"Penyerahan yang PPN nya tidak diinput",
                                            keteranganTambahan:"Kawasan Bebas",
                                            jenisFaktur:"Faktur Pajak Normal",
                                            nomorSeriFakturPajak:"004-15.49389727",
                                            tanggalFaktur:"27/07/2021",
                                            masa:"12",
                                            tahun:"2021"
                                        }
                                    }
									definitions={[
										{
											render: (
											  <div>
												<p style={{marginLeft: "10px"}}><b>{t.translate(`column.general`)}</b></p><br/>
											  </div>
											)
										},
										{
											inputType: inputTypes.INPUT,
											label: t.translate(`words.sobatInginMembuatFakturAtasTransaksi`),
											key: 'sobatInginMembuatFakturAtasTransaksi',
											name: 'sobatInginMembuatFakturAtasTransaksi',
                                            disabled: true,
										},
										{
											render: (
												<div style={{ width: '100%'}}>
													<p style={{margin: "10px"}}><b>{t.translate(`words.dataInvoice`)}</b></p>
												</div>
											)
										},
										{
											inputType: inputTypes.INPUT,
											label: t.translate(`words.nomorTransaksi`),
											key: 'nomorTransaksi',
											type: 'text',
											disabled: true,
											width: '49%',
											style: {
												marginRight: "2%"
											}
										},
										{
											inputType: inputTypes.DATEPICKER,
											label: t.translate(`words.tanggalInvoice`),
											key: 'tanggalInvoice',
											type: 'date',
											validation: 'required',
                                            width: '49%',
                                            disabled: true,
										},
										{
											render: (
											  <div>
												<p style={{marginLeft: "10px"}}><b>{t.translate(`words.statusPembayaran`)}</b></p><br/>
											  </div>
											)
										},
										{
											inputType: inputTypes.INPUT,
											label: t.translate(`words.belumBayar`),
											key: 'statusPembayaranInt',
                                            disabled: true,
										},
										{
											inputType: inputTypes.INPUT,
											label: t.translate(`words.akunPiutang`),
											key: 'akunPiutang',
											validation: 'required',
											width: '49%',
                                            disabled: true,
                                            style: {
												marginRight: "2%"
											},
										},
										{
											inputType: inputTypes.INPUT,
											label: t.translate(`words.jatuhTempo`),
											key: 'jatuhTempo',
											validation: 'required',
											width: '49%',
                                            disabled: true,
										},
										{
											inputType: inputTypes.INPUT,
											label: t.translate(`words.tanggalJatuhTempo`),
											key: 'tanggalJatuhTempo',
											disabled: true,
											width: '49%',
                                            style: {
												marginRight: "2%"
											},
										},
										{
											inputType: inputTypes.INPUT,
											label: t.translate(`words.terimaDari`),
											key: 'terimaDari',
											validation: 'required',
                                            disabled: true,
                                            width: '49%',
										},
                                        {
											render: (
											  <div>
												<p style={{marginLeft: "10px"}}><b>{t.translate(`words.detailFakturPajak`)}</b></p><br/>
											  </div>
											)
										},
                                        {
											inputType: inputTypes.INPUT,
											label: t.translate(`words.detailTransaksi`),
											key: 'detailTransaksi',
											validation: 'required',
                                            disabled: true,
										},
                                        {
											inputType: inputTypes.INPUT,
											label: t.translate(`words.keteranganTambahan`),
											key: 'keteranganTambahan',
											validation: 'required',
                                            width: '49%',
                                            style: {
												marginRight: "2%"
											},
                                            disabled: true,
										},
                                        {
											inputType: inputTypes.INPUT,
											label: t.translate(`words.jenisFaktur`),
											key: 'jenisFaktur',
											validation: 'required',
                                            width: '49%',
                                            disabled: true,
										},
                                        {
											inputType: inputTypes.INPUT,
											label: t.translate(`words.nomorSeriFakturPajak`),
											key: 'nomorSeriFakturPajak',
											validation: 'required',
                                            disabled: true,
										},
                                        {
											inputType: inputTypes.DATEPICKER,
											label: t.translate(`words.tanggalFaktur`),
											key: 'tanggalFaktur',
											validation: 'required',
                                            width: '49%',
                                            style: {
												marginRight: "2%"
											},
                                            disabled: true,
										},
                                        {
											inputType: inputTypes.INPUT,
											label: t.translate(`words.masa`),
											key: 'masa',
											validation: 'required',
                                            width: '24%',
                                            style: {
												marginRight: "1%"
											},
                                            disabled: true,
										},
                                        {
											inputType: inputTypes.INPUT,
											label: t.translate(`words.tahun`),
											key: 'tahun',
											validation: 'required',
                                            width: '24%',
                                            disabled: true,
										},
									]}
									onSubmit={onSubmit}
								/>
							</ExpansionPanel>
							
							<br />

							{/* FORM PRODUK */}
							<ExpansionPanel expanded={panel3} onClick={()=> activePanel3()} header={ t.translate('words.produk')}>
								<FormWrapper
									submitLabel={t.translate('words.submit')}
									loading={data.loading}
									showCommandbar={false}
									baseId={`mod-form-${baseName}`}
									style={{
									maxWidth: '100%'
									}}
									defaultData={data.content}
									definitions={[
									{
										render: (
										<div style={{ width: '100%' }}>
											<Hint
												className="mpk-margin-N margin-top margin-bottom"
												message="Total Penjualan"
											/>
											<Table style={{ width: '100%' }}>
												<TableHeader>
													<TableRow>
														<TableCell style={{width: '10%'}}>{t.translate('words.non')}</TableCell>
														<TableCell style={{width: '25%'}}>{t.translate('words.kode')}</TableCell>
														<TableCell style={{width: '25%'}}>{t.translate('words.nama')}</TableCell>
														<TableCell style={{width: '25%'}}>{t.translate('words.jumlah')}</TableCell>
														<TableCell style={{width: '30%'}}>{t.translate('words.subtotal')}</TableCell>
														<TableCell style={{width: '10%'}}>{t.translate('words.dpp')}</TableCell>
														<TableCell style={{width: '10%'}}>{t.translate('words.ppn')}</TableCell>
														<TableCell style={{width: '10%'}}>{t.translate('words.ppnBm')}</TableCell>
													</TableRow>
												</TableHeader>
												<TableBody>
													{produkData.map((d, index)=> {
														return (
															<TableRow key={index}>
																<TableCell>{index + 1}</TableCell>
																<TableCell
                                                                    onClick={()=>{
                                                                        navigationStore.redirectTo(
                                                                            `/internal/kas-dan-bank-detail-input-detail-produk/${d.id}`
                                                                        )
                                                                    }}
                                                                    style={{color:'purple', fontWeight:"bolder"}}
                                                                >{d.kode}</TableCell>
																<TableCell>{d.nama}</TableCell>
																<TableCell>{d.jumlah}</TableCell>
																<TableCell>{d.subtotal}</TableCell>
																<TableCell>{d.dpp}</TableCell>
																<TableCell>{d.ppn}</TableCell>
																<TableCell>{d.ppnBm}</TableCell>
																<TableCell>
																	<Button onClick={()=> {}}>{t.translate("words.delete")}</Button>
																</TableCell>
															</TableRow>
														)
													})}
												</TableBody>
											</Table>
											<FormWrapper
												loading={data.loading}
												showCommandbar={false}
												baseId={`mod-form-${baseName}`}
												style={{
												maxWidth: '100%'
												}}
												defaultData={data.content}
												definitions={[
													{
														inputType: inputTypes.INPUT,
														label: t.translate(`words.dpp`),
														key: 'dpp',
														type: 'text',
														disabled: true,
														width: '30%',
														value:"3000000",
														style: {
															marginRight: "3%"
														}
													},
													{
														inputType: inputTypes.INPUT,
														label: t.translate(`words.ppn`),
														key: 'ppn',
														type: 'text',
														disabled: true,
														width: '30%',
														value:"3000000",
														style: {
															marginRight: "3%"
														}
													},
													{
														inputType: inputTypes.INPUT,
														label: t.translate(`words.ppnBm`),
														key: 'ppnBm',
														type: 'text',
														disabled: true,
														width: '30%',
														value:"3000000",
														style: {
															marginRight: "3%"
														}
													},
												]}
											/>
											
											<Hint
												className="mpk-margin-N margin-top margin-bottom"
												message="Nilai Penjualan (Sebelum Pajak)"
											/>
											<Table style={{ width: '100%' }}>
												<TableHeader>
													<TableRow>
														<TableCell style={{width: '10%'}}>{t.translate('words.non')}</TableCell>
														<TableCell style={{width: '20%'}}>{t.translate('words.namaAkun')}</TableCell>
														<TableCell style={{width: '20%'}}>{t.translate('words.kodeAkun')}</TableCell>
														<TableCell style={{width: '20%'}}>{t.translate('words.nominal')}</TableCell>
													</TableRow>
												</TableHeader>
												<TableBody>
													{penjualanSebelumPajak.map((d, index)=> {
														return (
															<TableRow key={index}>
																<TableCell>{index + 1}</TableCell>
																<TableCell
                                                                    onClick={()=>{
                                                                        navigationStore.redirectTo(
                                                                            `/internal/kas-dan-bank-detail-input-detail-akun-penjualan/${d.id}`
                                                                        )
                                                                    }}
                                                                    style={{color:'purple', fontWeight:"bolder"}}
                                                                >{d.namaAkun}</TableCell>
																<TableCell>{d.kodeAkun}</TableCell>
																<TableCell>{d.nominal}</TableCell>
																<TableCell>
																	<Button onClick={()=> {}}>{t.translate("words.delete")}</Button>
																</TableCell>
															</TableRow>
														)
													})}
												</TableBody>
											</Table>
											<FormWrapper
												loading={data.loading}
												showCommandbar={false}
												baseId={`mod-form-${baseName}`}
												style={{
												maxWidth: '100%'
												}}
												defaultData={data.content}
												definitions={[
													{
														inputType: inputTypes.INPUT,
														label: t.translate(`words.nilaiPenjualan`),
														key: 'nilaiPenjualan',
														type: 'text',
														disabled: true,
														width: '100%',
														value:"3000000",
													},
												]}
											/>
											
											<Hint
												className="mpk-margin-N margin-top margin-bottom"
												message="Pajak Disetor Sendiri"
											/>
											<Table style={{ width: '100%' }}>
												<TableHeader>
													<TableRow>
														<TableCell style={{width: '10%'}}>{t.translate('words.non')}</TableCell>
														<TableCell style={{width: '20%'}}>{t.translate('words.namaAkun')}</TableCell>
														<TableCell style={{width: '20%'}}>{t.translate('words.kodeAkun')}</TableCell>
														<TableCell style={{width: '20%'}}>{t.translate('words.nominal')}</TableCell>
													</TableRow>
												</TableHeader>
												<TableBody>
													{penjualanSebelumPajak.map((d, index)=> {
														return (
															<TableRow key={index}>
																<TableCell>{index + 1}</TableCell>
																<TableCell>{d.namaAkun}</TableCell>
																<TableCell>{d.kodeAkun}</TableCell>
																<TableCell>{d.nominal}</TableCell>
																<TableCell>
																	<Button onClick={()=> {}}>{t.translate("words.delete")}</Button>
																</TableCell>
															</TableRow>
														)
													})}
												</TableBody>
											</Table>
											<FormWrapper
												loading={data.loading}
												showCommandbar={false}
												baseId={`mod-form-${baseName}`}
												style={{
												maxWidth: '100%'
												}}
												defaultData={data.content}
												definitions={[
													{
														inputType: inputTypes.INPUT,
														label: t.translate(`words.pajakDisetorSendiri`),
														key: 'pajakDisetorSendiri',
														type: 'text',
														disabled: true,
														width: '100%',
														value:"3000000",
													},
												]}
											/>
											
											<Hint
												className="mpk-margin-N margin-top margin-bottom"
												message="Beban/Pendapatan Lainnya"
											/>
											<Table style={{ width: '100%' }}>
												<TableHeader>
													<TableRow>
														<TableCell style={{width: '10%'}}>{t.translate('words.non')}</TableCell>
														<TableCell style={{width: '20%'}}>{t.translate('words.namaAkun')}</TableCell>
														<TableCell style={{width: '20%'}}>{t.translate('words.kodeAkun')}</TableCell>
														<TableCell style={{width: '20%'}}>{t.translate('words.nominal')}</TableCell>
													</TableRow>
												</TableHeader>
												<TableBody>
													{penjualanSebelumPajak.map((d, index)=> {
														return (
															<TableRow key={index}>
																<TableCell>{index + 1}</TableCell>
																<TableCell
                                                                    onClick={()=>{
                                                                        navigationStore.redirectTo(
                                                                            `/internal/kas-dan-bank-detail-input-detail-produk/${d.id}`
                                                                        )
                                                                    }}
                                                                    style={{color:'purple', fontWeight:"bolder"}}
                                                                >{d.namaAkun}</TableCell>
																<TableCell>{d.kodeAkun}</TableCell>
																<TableCell>{d.nominal}</TableCell>
																<TableCell>
																	<Button onClick={()=> {}}>{t.translate("words.delete")}</Button>
																</TableCell>
															</TableRow>
														)
													})}
												</TableBody>
											</Table>
											<FormWrapper
												loading={data.loading}
												showCommandbar={false}
												baseId={`mod-form-${baseName}`}
												style={{
												maxWidth: '100%'
												}}
												defaultData={data.content}
												definitions={[
													{
														inputType: inputTypes.INPUT,
														label: t.translate(`words.bebanPendapatanLain`),
														key: 'bebanPendapatanLain',
														type: 'text',
														disabled: true,
														width: '100%',
														value:"3000000",
													},
                                                    {
														inputType: inputTypes.INPUT,
														label: t.translate(`words.total`),
														key: 'total',
														type: 'text',
														disabled: true,
														width: '100%',
														value:"38520000",
													},
												]}
											/>
											
											<Hint
												className="mpk-margin-N margin-top margin-bottom"
												message="Keterangan & Lampiran"
											/>
											<FormWrapper
												loading={data.loading}
												showCommandbar={false}
												baseId={`mod-form-${baseName}`}
												style={{
												maxWidth: '100%'
												}}
												defaultData={data.content}
												definitions={[
													{
													  inputType : inputTypes.FILE_INPUT,
													  label : t.translate('words.file'),
													  key : 'file',
													  type : 'file',
													  validation: 'required',
													  multiple: true,
													  multiline: true,
													  accept: '.pdf'
													},
													{
														inputType: inputTypes.INPUT,
														label: t.translate(`words.keterangan`),
														key: 'keterangan',
														type: 'text',
														value:"xxx",
														width: '100%'
													},
												]}
											/>
										</div>
										)
									},
									{
										render: (
										<div style={{ margin: 10 }}></div>
										)
									},
									]}
									onSubmit={onSubmit}
								/>
							</ExpansionPanel>
						</ExpansionList>
					)
				},
			]}
			// onSubmit={async (data, callback) => {

			// }}
		/>
	)
	

	function _closeDialog() {
		setDialogPenjualanProdukForm(false)
	}

	function _setDokumens(dokumens){
		setDokumens(dokumens)
	}
}

export default inject('navigationStore','envStore')(observer(KasDanBankDetailPenjualan))