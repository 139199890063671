import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { sum } from "lodash";
import { FormWrapper, TableWrapper } from "../../../libs/react-mpk/wrapper";
import {
  Table,
  TableBody,
  TableHeader,
  TableRow,
  TableCell,
  Text,
  Button,
  FontIcon,
} from "react-md";
import FormTanggal from "../FormTanggal";
import t from "counterpart";
import iziToast from "izitoast";
import service from "./ReportNeracaSaldo.service";
import { useHistory, useLocation, useParams } from "react-router";
import { cloneDeep } from "lodash";
import { format, formatRupiah } from "../../../libs/react-mpk/services/number.service";
import utilsService from "../../../services/utilsService";

const ReportTransaksiNeracaSaldo = ({ navigationStore, modalStore, match }) => {
  let npwpHeadId = sessionStorage.getItem("npwpHeadId");
  let namaAkun = sessionStorage.getItem("namaAkun");
  let kodeAkun = sessionStorage.getItem("kodeAkun");
  let saldoAwal = sessionStorage.getItem("saldoAwal");
  let saldoAkhir = sessionStorage.getItem("saldoAkhir");

  let history = useHistory()
  const { state: { tahun } = {} } = useLocation();

  const [data, setData] = useState([]);
  const [dataNeracaSaldo, setDataNeracaSaldo] = useState([]);
  const [load, setload] = useState(true);
  const { id } = useParams();
  const fetchData = async () => {
    try {
      let query ={
        "tahun":tahun?tahun:sessionStorage.getItem('tahunSobatBuku'),
        "akunId":id
      }
      let res = await service.getTransaksiByID(query);
      setData(res.data);
      setload(false);
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <FormWrapper
        loading={load}
        baseId="report-transaksi-neraca-saldo"
        showCommandbar={true}
        style={{
          maxWidth: "100%",
        }}
        backTo={tahun?null:`/internal/npwp/${npwpHeadId}/report/neracaSaldo`}
        title={t.translate(`modules.report.transaksiNeracaSaldo`)}
        defaultData={{
          namaAkun: kodeAkun + " " + namaAkun,
          saldoAwal: formatRupiah(Math.abs(saldoAwal)),
          saldoAkhir: formatRupiah(Math.abs(saldoAkhir)),
        }}
        definitions={[
          {
            inputType: FormWrapper.inputTypes.INPUT,
            label: t.translate("words.namaAkun"),
            key: "namaAkun",
            disabled: true,
            style: {
              marginLeft: "10px",
              marginRight: "2%",
            },
            width: "48%",
          },
          {
            inputType: FormWrapper.inputTypes.INPUT,
            label: t.translate("words.saldoAkhir"),
            key: "saldoAkhir",
            disabled: true,
            width: "48%",
          },
          {
            render: (
              <div style={{ width: "100%" }}>
                <Table style={{ width: "100%" }}>
                  <TableHeader>
                    <TableRow>
                      <TableCell style={{ width: "10%" }}>
                        {t.translate("words.non")}
                      </TableCell>
                      <TableCell style={{ width: "10%" }}>
                        {t.translate("words.aksi")}
                      </TableCell>
                      <TableCell style={{ width: "18%" }}>
                        {t.translate("words.bulanDanTahun")}
                      </TableCell>
                      <TableCell style={{ width: "18%" }}>
                        {t.translate("words.debit")}
                      </TableCell>
                      <TableCell style={{ width: "18%" }}>
                        {t.translate("words.kredit")}
                      </TableCell>
                      <TableCell style={{ width: "18%" }}>
                        {t.translate("words.saldoAwal")}
                      </TableCell>
                      <TableCell style={{ width: "18%" }}>
                        {t.translate("words.saldoAkhir")}
                      </TableCell>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {data.map((d, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>
                            <Button
                              theme="primary"
                              // themeType="contained"
                              // style={{ margin: 15 }}
                              onClick={() =>{
                                history.push({
                                  pathname: `/internal/npwp/${npwpHeadId}/report-neraca-saldo/detail/${d.akunId}/${d.bulan}`,
                                  state: { tahun: tahun },
                                });
                                // navigationStore.redirectTo(
                                //   `/internal/npwp/${npwpHeadId}/report-neraca-saldo/detail/${d.akunId}/${d.bulan}`,
                                  sessionStorage.setItem(
                                    "namaAkunTransaksi",
                                    d.namaAkun
                                  )
                                  sessionStorage.setItem(
                                    "kodeAkunTransaksi",
                                    d.kodeAkun
                                  )
                                  sessionStorage.setItem(
                                    "saldoAwalTransaksi",
                                    d.saldoAwal
                                  )
                                  sessionStorage.setItem(
                                    "bulanTransaksi",
                                    d.bulan
                                  )
                                  sessionStorage.setItem(
                                    "tahunTransaksi",
                                    d.tahun
                                  )

                              }
                                
                              }
                            >
                              <FontIcon iconClassName="mdi mdi-import" />
                              {/* {t.translate("words.add")} */}
                            </Button>
                          </TableCell>
                          <TableCell>
                            {utilsService.beautifyBulan(d.bulan)}
                            {" " + d.tahun}
                          </TableCell>
                          <TableCell>{formatRupiah(Math.abs(d.totalDebit))}</TableCell>
                          <TableCell>{formatRupiah(Math.abs(d.totalKredit))}</TableCell>
                          <TableCell>{formatRupiah(Math.abs(d.saldoAwal))}</TableCell>
                          <TableCell>{formatRupiah(Math.abs(d.saldoAkhir))}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
            ),
          },
        ]}
      />
    </>
  );
};

export default inject(
  "navigationStore",
  "envStore",
  "modalStore"
)(observer(ReportTransaksiNeracaSaldo));
