import React, { useEffect, useState } from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import Modal from '../../libs/react-mpk/components/Modal'
import t from 'counterpart'
// import service from './DaftarSptArchive.service'
import moment from 'moment'
import { toast } from '../../libs/react-mpk/services'
import { format } from '../../libs/react-mpk/services/number.service'
import { Chip, ListItem, Button } from 'react-md'
import errorService from '../../services/errorService'
import { reject } from 'lodash'
import { inject, observer } from 'mobx-react'
import iziToast from 'izitoast'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import DataForm from '../../libs/react-mpk/components/DataForm'
import service from './PPN.service'
import utilsService from '../../services/utilsService'

const PPNHistory = ({
    className       = '',
    showCommandbar  = true,
    history,
  match,
    envStore,
    modalStore
}) => {
	var npwpHeadId = match.params.npwpHeadId
  const [data, setData] = useState([])

  return (
    <>
      <TableWrapper
        baseId="mod-table-sample"
        title = "Riwayat Pengeluaran Pembayaran"
        className={className}
        hintMessage={t.translate('modules.pesanan.hint.message')}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate('modules.pesanan.hint.more')}
        showCommandbar={showCommandbar}
        showFilterPeriod={false}
        backTo={`/${envStore.env.applicationType}/npwp/${npwpHeadId}/ppn`}
        isShowFilter={false}
        useFilter={false}
        defaultCollapse={true}
        useCriteria={true}
        selectable={false}
        useQueryHistory={false}
        showActionColumn = {true}
        defaultSortBy="id"
        defaultData={data}
        onFetchData={() => {
          return (
            new Promise(async (resolve) => {
              try {
                if (match.params.kategori === '1') {
                  let res = await service.getHistoryFm(match.params.id)
                  res.headers['x-pagination-count'] = res.headers['x-total-count']
                  resolve(res)
                } else {
                  let res = await service.getHistoryFk(match.params.id)
                  res.headers['x-pagination-count'] = res.headers['x-total-count']
                  resolve(res)
                }
              } catch (error) {
                resolve(error)
              }
            })
          )
        }}
        columns = {[
          {
              label : t.translate('words.noTransaksi'),
              searchable : false,
              sortable : true,
              key : 'noTransaksi',
              type : TableWrapper.dataTypes.STRING,
              render : (item) => utilsService.beautifyNoTransaksi(item.fm ? item.fm.noTransaksi : item.fk.noInvoice)
          },
          {
              label : t.translate('words.nominal'),
              searchable : true,
              sortable : true,
              key : 'nominal',
              type : TableWrapper.dataTypes.STRING,
              render : (item) => `Rp. ${format(item.nilaiTagihanDibayar)}`
          }
        ]}
      />
    </>
  )
}

export default inject('temporaryStore', 'modalStore', 'envStore')(observer(PPNHistory))