import React, { useState } from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import t from 'counterpart'
import service from './PPN.service'
import moment from 'moment'
import { format } from './../../libs/react-mpk/services/number.service'
import { inject, observer } from 'mobx-react'
import { DataForm } from '../../libs/react-mpk/components';
import { Table } from '@react-md/table'
import iziToast from 'izitoast'
import { toast } from '../../libs/react-mpk/services'
import { DEFAULT_LAYOUT_MAIN_CLASSNAMES, LayoutAppBar } from '@react-md/layout'
import utilsService from '../../services/utilsService'
import PPNPilihJenisPengeluaran from './PPNPilihJenisPengeluaran'

const PPNFakturKeluaran = ({
  className = '',
  showCommandbar = true,
  navigationStore,
  modalStore,
  match
}) => {
  const baseId = 'ppnfm'
  const [showForm, setShowForm] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [showHistory, setShowHistory] = useState(false)
  const [showBatalkan, setShowBatalkan] = useState(false)
  const [dialogPilihJenisPengeluaran, setDialogPilihJenisPengeluaran] =useState(false);
  let [search, setSearch] = useState({})
  let npwpHeadId = sessionStorage.getItem('npwpHeadId')
  const [data, setData] = useState([])
  var backTo = `/internal/npwp/${npwpHeadId}/ppn`

  var actions = [
    new TableWrapper.action(
      `${t.translate('words.add')}`,
      'mdi mdi-plus',
      (item) => {
        setDialogPilihJenisPengeluaran(true)
      },
      true),
  ]

  //UPATE STATUS PESANAN
  async function updateStatusExc(item) {
    try {
      //   var r = await service.editStatusPesanan(item.id)
      //   r.data.status = 2
      //   if (r.data.message == 'sukses') {
      //     iziToast.success({
      //       message: r.data.message
      //     })
      //   } else {
      //     iziToast.info({
      //       message: r.data.message
      //     })
      //   }
      //   document.getElementsByClassName('mdi-reload')[0].click()
    } catch (err) {
      toast.errorRequest(err)
    }
  }

  async function updateStatus(item) {
    modalStore.showConfirm({
      title: t.translate('words.terimaPesanan'),
      children: t.translate('words.confirmTerima'),
      onSubmit: (callback) => {
        updateStatusExc(item)
        callback()
      }
    })
  }

  //BATAL PESANAN
  async function batalkanPesanan(item) {
    try {
      var r = await service.batalkanFk(item.id)
      r.data.status = 4
      if (r.data.message == 'sukses') {
        iziToast.success({
          message: r.data.message
        })
      } else {
        iziToast.info({
          message: r.data.message
        })
      }
      document.getElementsByClassName('mdi-reload')[0].click()
    } catch (err) {
      toast.errorRequest(err)
    }
  }

  async function deletePesanan(item) {
    try {
      var r = await service.deletePkp(item.id)
      r.data.status = 4
      if (r.data.message == 'sukses') {
        iziToast.success({
          message: r.data.message
        })
      } else {
        iziToast.info({
          message: r.data.message
        })
      }
      document.getElementsByClassName('mdi-reload')[0].click()
    } catch (err) {
      toast.errorRequest(err)
    
    }
  }

  async function updateStatusBatal(item) {
    modalStore.showConfirm({
      title: t.translate('words.batalkan'),
      children: t.translate('words.confirmBatalkan'),
      onSubmit: (callback) => {
        batalkanPesanan(item)
        callback()
      }
    })
  }

  async function deletePPN(item) {
    modalStore.showConfirm({
      title: 'Delete',
      children: 'Apakah anda yakin?',
      onSubmit: (callback) => {
        deletePesanan(item)
        callback()
      }
    })
  }



  //PESANAN SELESAI
  async function pesananSelesai(item) {
    try {
      //   var r = await service.editStatusPesananSelesai(item.id)
      //   r.data.status = 3
      //   if (r.data.message == 'sukses') {
      //     iziToast.success({
      //       message: r.data.message
      //     })
      //   } else {
      //     iziToast.info({
      //       message : r.data.message
      //     })
      //   }
      document.getElementsByClassName('mdi-reload')[0].click()
    } catch (err) {
      toast.errorRequest(err)
    }
  }

  async function updateStatusSelesai(item) {
    modalStore.showConfirm({
      title: t.translate('words.selesai'),
      children: t.translate('words.confirmSelesai'),
      onSubmit: (callback) => {
        pesananSelesai(item)
        callback()
      }
    })
  }
  return (
    <>
      <TableWrapper
        baseId="ppn-faktur-keluaran"
        title={t.translate('modules.ppn.listPpnFk')}
        className={className}
        hintMessage={t.translate('modules.ppn.hint.message')}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate('modules.ppn.hint.more')}
        defaultData={[]}
        defaultSortBy="tanggalDibuat"
        isShowFilter={false}
        showActionColumn={true}
        useFilter={false}
        defaultCollapse={true}
        useCriteria={true}
        selectable={false}
        useQueryHistory={false}
        onFetchData={(query) => {
          return (
            new Promise(async (resolve, reject) => {
              try {
                query = {
                  ...search,
                  ...query,

                }
                let getPage = await service.getAllFk(query)
                setData(getPage.data)
                getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
                resolve(getPage)
              } catch (e) {
                resolve()
                // ErrorService(e)
              }
            })
          )
        }}
        columns={[
          {
            label: t.translate(`words.noTransaksi`),
            sortable: false,
            key: 'noInvoice',
            render: item => utilsService.beautifyNoTransaksi(item.noInvoice)
          },
          {
            label: t.translate(`words.npwpName`),
            key: 'namaNpwp',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.lawanTransaksi.npwp)
          },
          {
            label: t.translate(`words.namaLawanTransaksi`),
            key: 'namaLawanTransaksi',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.lawanTransaksi.nama)
          },
          {
            label: t.translate(`words.total`),
            sortable: false,
            key: 'total',
            render: item => ("Rp. " + format(item.total))
          },
          {
            label: t.translate(`words.tglTransaksi`),
            key: 'tglTransaksi',
            type: TableWrapper.dataTypes.STRING,
            render: item => moment(item.tanggalTransaksi).locale('id').format('ll')
          },
          {
            label: t.translate(`words.statusPembayaran`),
            type: TableWrapper.dataTypes.STRING,
            key: 'statusPembayaran',
            render: item => (
              <TableWrapper.Status
                type={item.pembayaran.status == "1"
                  ? TableWrapper.Status.types.IDLE
                  : item.pembayaran.status == "2"
                    ? TableWrapper.Status.types.PROGRESS
                    : item.pembayaran.status == "3"
                      ? TableWrapper.Status.types.SUCCESS
                      : item.pembayaran.status == "4"
                        ? TableWrapper.Status.types.ERROR
                        : ""

                }
              >
                {item.pembayaran.status == "1"
                  ? 'Belum Diproses'
                  : item.pembayaran.status == "2"
                    ? 'Diproses'
                    : item.pembayaran.status == "3"
                      ? "Selesai"
                      : item.pembayaran.status == "4"
                        ? "Batal"
                        : ""
                }
              </TableWrapper.Status>
            )
          },
          {
            label: t.translate(`words.statusApprove`),
            key: 'statusAcc',
            type: TableWrapper.dataTypes.STRING,
            render: item => (
              <TableWrapper.Status
                type={item.pembayaran.status == "1" || item.pembayaran.status == "2"
                  ? TableWrapper.Status.types.IDLE
                  : item.pembayaran.status == "3"
                    ? TableWrapper.Status.types.SUCCESS
                    : item.pembayaran.status == "4"
                      ? TableWrapper.Status.types.ERROR
                      : ""

                }
              >
                {item.pembayaran.status == "1" || item.pembayaran.status == "2"
                  ? 'Belum di Approved'
                  : item.pembayaran.status == "3"
                    ? 'Approval Sukses'
                    : item.pembayaran.status == "4"
                      ? "Batal"
                      : ""
                }
              </TableWrapper.Status>
            )
          }
        ]}
        actions={actions}
        itemActions={[
          new TableWrapper.action(
            'Selengkapnya',
            'mdi mdi-import',
            (item) => {
              navigationStore.redirectTo(
                `/internal/npwp/${npwpHeadId}/penjualan/${item.id}/detail/non-ppn`
              )
            }
          ),
          new TableWrapper.action(
            `${t.translate('words.history')}`,
            'mdi mdi-history',
            (item) => {
              navigationStore.redirectTo(
                `/internal/npwp/${npwpHeadId}/ppn/${item.id}/history/${'2'}`
              )
            },
            true
          ),
          new TableWrapper.action(
            `${t.translate('words.jurnalEntri')}`,
            'mdi mdi-application',
            (item) => {
              navigationStore.redirectTo(
                `/internal/npwp/${npwpHeadId}/ppn-fk/${item.noInvoice}/jurnal-entri`
              )
            }
          ),
          // new TableWrapper.action(
          //   `${t.translate('words.terimaPesanan')}`,

          //   'mdi mdi-import',
          //   (item) => {
          //     updateStatus(item)
          //   },
          //   true
          // ),
          // new TableWrapper.action(
          //   `${t.translate('words.inputResi')}`,
          //   'mdi mdi-email-send-outline',
          //   (item) => {
          //     setSelectedItem(item)
          //     setShowHistory(true)
          //   },

          //   true
          // ),
          // new TableWrapper.action(
          //   `${t.translate('words.selesaiKirim')}`,
          //   'mdi mdi-file-upload',
          //   (item) => {
          //     updateStatusSelesai(item)
          //   },
          //   true
          // ),
          new TableWrapper.action(
            `${t.translate('words.batalKirim')}`,
            'mdi mdi-close',
            (item) => {
              updateStatusBatal(item)
            },
            item => item.pembayaran.status == 1 ? true : false
          ),
          new TableWrapper.action(
            'Delete',
            'mdi mdi-delete',
            (item) => {
              deletePPN(item)
            },
            item => item.pembayaran.status == 1 ? true : false
          ),
          // new TableWrapper.action(
          //   `${t.translate('words.generateInvoice')}`,
          //   'mdi mdi-file',
          //   (item) => {
          //     // setSelectedItem(item)
          //     setShowHistory(true)
          //   },
          //   true
          // ),
        ]}
        showCommandbar={showCommandbar}
        // showFilterPeriod={false}
        sideHeader={
          <DataForm
            baseId={`${baseId}-search`}
            defaultData={search}
            submitIconClassName='mdi mdi-magnify'
            submitLabel={`${t.translate('words.cari')}`}
            onSubmit={(values, callback) => {
              // console.log(values, 'values')
              setSearch(values);
              setTimeout(() => {
                document.getElementsByClassName('mdi-reload')[0].click()
                callback("", false, false)
              }, 1000)
            }}
            definitions={[
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.noTransaksi`),
                key: 'noInvoice.contains',
                type: 'text'
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.namaLawanTransaksi`),
                key: 'namaLawanTransaksi.contains',
                type: 'text'
              }
            ]}
          />
        }
      />
       <PPNPilihJenisPengeluaran
          visible={dialogPilihJenisPengeluaran}
          onRequestClose={() => {
            setDialogPilihJenisPengeluaran(false);
          }}
        />
    </>
  )
}

export default inject("navigationStore", "modalStore")(observer(PPNFakturKeluaran))