import React, { useState } from "react";
import { FormWrapper, TableWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import service from "./KasDanBank.service";
import { inject, observer } from "mobx-react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogContent,
  DialogTitle,
  DialogFooter,
  Text,
  Table,
  TableBody,
  TableHeader,
  TableRow,
  TableCell,
  FontIcon,
  TableFooter,
} from "react-md";
import KasDanBankPenerimaanPPN from "./KasDanBankTambahPenerimaanUang/KasDanBankPenerimaanPPN";
import { List, ListItem } from "@react-md/list";
import iziToast from "izitoast";
import { useParams } from "react-router";
import { format, formatRupiah } from "../../libs/react-mpk/services/number.service";
import KasDanBankTambahAkunBankForm from "./KasDanBankTambahAkunBankForm";
import utilsService from "../../services/utilsService";
import moment from "moment";
import { toast } from "../../libs/react-mpk/services";
import AkunCoaForm from "../AkunCoa/AkunCoaForm";

const KasDanBankSelengkapnyatable = ({
  modalStore,
  navigationStore,
  className = "",
  showCommandbar = true,
  item = null,
}) => {
  let [showForm, setShowForm] = useState(false);
  const [showFormAkun, setShowFormAkun] = useState(false);
  const [dialogPenerimaanPpn, setDialogPenerimaanPpn] = useState(false);
  let npwpHeadId = sessionStorage.getItem("npwpHeadId");
  let NamaAkun = sessionStorage.getItem("namaAkun");
  let KodeAkun = sessionStorage.getItem("kodeAkun");
  let SaldoAkhir = sessionStorage.getItem("saldoAkhir");
  let SaldoAwal = sessionStorage.getItem("saldoAwal");
  let [search, setSearch] = useState({});
  let AkunId = sessionStorage.getItem("idAkun");
  const { id, bulan, noTransaksi } = useParams();
  function addItemPenerimaan() {
    setDialogPenerimaanPpn(true);
  }
  async function hapus(item) {
    modalStore.showConfirm({
      title: t.translate("words.delete"),
      children: t.translate("words.confirmHapus"),
      onSubmit: (callback) => {
        hapusExc(item);
        callback();
      },
    });
  }
  async function hapusExc(item) {
    try {
      let res = item.referensi.includes("PK")
        ? await service.deletePenerimaan(item.referensi)
        : await service.deletePengeluaran(item.referensi);
      if (res.data.message == "sukses") {
        toast.success("berhasil hapus data");
        document.getElementsByClassName("mdi-reload")[0].click();
      }
    } catch (e) {
      toast.errorRequest(e);
    }
  }
  return (
    <>
      <TableWrapper
        baseId="mod-table-kas-dan-bank-selengkapnya-table"
        className={className}
        title={t.translate(`modules.KasDanBank.selengkapnya`)}
        backTo={`/internal/npwp/${npwpHeadId}/kas-dan-bank`}
        hintMessage={t.translate("modules.KasDanBank.hint.message")}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate("modules.KasDanBank.hint.more")}
        defaultSortBy="tanggalDibuat"
        isShowFilter={false}
        showActionColumn={true}
        useFilter={false}
        defaultCollapse={true}
        useCriteria={true}
        selectable={false}
        useQueryHistory={false}
        
        style={{
          marginTop: "50%",
        }}
        onFetchData={(query) => {
          return new Promise(async (resolve, reject) => {
            try {
              query = {
                ...search,
                ...query,
              };
              let getPage = await service.getPageDetail(AkunId, query);
              resolve(getPage);
            } catch (e) {
              resolve();
              // ErrorService(e)
            }
          });
        }}
        tableHeader={
          <List
            style={{
              width: "100%",
              backgroundColor: "lightgrey",
              textAlign: "center",
            }}
          >
            <ListItem>
              <b>{t.translate("words.namaAkun")} </b>:{" "}
              {KodeAkun + " - " + NamaAkun}
              <b style={{ marginLeft: "5%" }}>
                {t.translate("words.saldoAwal")}{" "}
              </b>
              : {SaldoAwal}
              <b style={{ marginLeft: "5%" }}>
                {t.translate("words.saldoAkhir")}{" "}
              </b>
              : {SaldoAkhir}
            </ListItem>
          </List>
        }
        columns={[
          {
            label: t.translate(`words.noTransaksi`),
            searchable: true,
            key: "referensi",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => utilsService.beautifyNoTransaksi(item.referensi),
          },
          {
            label: "Terima",
            searchable: true,
            key: "nominal",
            type: TableWrapper.dataTypes.STRING,
            render: (item) =>
              `${item.kategori === 1 ? formatRupiah(item.nominal) : "0"}`,
          },
          {
            label: "Kirim",
            searchable: true,
            key: "nominal",
            type: TableWrapper.dataTypes.STRING,
            render: (item) =>
              ` ${item.kategori === 2 ? formatRupiah(item.nominal) : "0"}`,
          },
          {
            label: t.translate(`words.tanggalTransaksi`),
            searchable: true,
            key: "tanggalTransaksi",
            type: TableWrapper.dataTypes.STRING,
            render: (item) =>
              moment(item.tanggalTransaksi).locale("id").format("DD MMMM YYYY"),
          },
        ]}
        actions={[
          new TableWrapper.action("Add", "mdi mdi-plus", (item) => {
            setShowForm(true);
          }),
        ]}
        itemActions={[
          new TableWrapper.action(`Detail`, "mdi mdi-import", (item) => {
            if (item.referensi.includes("PJ")) {
              navigationStore.redirectTo(
                `/internal/npwp/${npwpHeadId}/penjualan/${item.referensi}/detail/non-ppn`
              );
            } else if (item.referensi.includes("PB")) {
              navigationStore.redirectTo(
                `/internal/npwp/${npwpHeadId}/pembelian/${item.referensi}/detail/non-ppn`,
                sessionStorage.setItem("idPembelian", item.id),
                sessionStorage.setItem("noInvoicePembelian", item.referensi)
              );
            } else if (item.referensi.includes("KK")) {
              navigationStore.redirectTo(
                `/internal/npwp/${npwpHeadId}/kas-dan-bank-detail-pengeluaran-non-ppn/${item.referensi}`
              );
            } else if (item.referensi.includes("PK")) {
              navigationStore.redirectTo(
                `/internal/npwp/${npwpHeadId}/kas-dan-bank-detail-penerimaan-non-ppn/${item.referensi}`
              );
            } else if (item.referensi.includes("TF")) {
              navigationStore.redirectTo(
                `/internal/npwp/${npwpHeadId}/kas-dan-bank-detail-transfer-antar-bank/${item.referensi}`
              );
            } else if (item.referensi.includes("BY")) {
              navigationStore.redirectTo(
                `/internal/npwp/${npwpHeadId}/biaya/${item.referensi}/detail/non-ppn`
              );
            } else if (item.referensi.includes("JU")) {
              navigationStore.redirectTo(
                `/internal/npwp/${npwpHeadId}/jurnal-umum/${item.referensi}/jurnal-entri`
              );
            }
          }),
          new TableWrapper.action(
            "Hapus",
            "mdi mdi-delete",
            (item) => {
              hapus(item);
            },
            (item) =>
              item.referensi.includes("KK") || item.referensi.includes("PK")
                ? true
                : false
          ),
        ]}
        showCommandbar={showCommandbar}
        showFilterPeriod={true}
        // useCriteria={false}
      />
      {/* dialog menu */}
      <Dialog
        visible={showForm}
        onRequestClose={() => {
          setShowForm(false);
        }}
        style={{ width: "45%" }}
      >
        <DialogHeader>
          <DialogTitle>Pilih</DialogTitle>
        </DialogHeader>
        <DialogContent>
          <Text>{t.translate(`words.tambahTransferAntarBank`)}</Text>
          <Button
            id="dialog-close"
            onClick={() => {
              navigationStore.redirectTo(
                `/internal/npwp/${npwpHeadId}/transfer-antar-bank`
              );
            }}
            theme="primary"
            themeType="contained"
          >
            {t.translate(`words.tambahTransferAntarBank`)}
          </Button>

          <Text>{t.translate(`words.tambahAkunBank`)}</Text>
          <Button
            id="dialog-close"
            onClick={() => {
              setShowFormAkun(true);
              setShowForm(false);
            }}
            theme="primary"
            themeType="contained"
          >
            {t.translate(`words.tambahAkunBank`)}
          </Button>
        </DialogContent>
        <DialogFooter>
          <Button
            onClick={() => {
              setShowFormAkun(false);
              setShowForm(false);
            }}
          >
            {t.translate(`words.close`)}
          </Button>
        </DialogFooter>
      </Dialog>

      {/* dialog penerimaan ppn */}
      <KasDanBankPenerimaanPPN
        visible={dialogPenerimaanPpn}
        onRequestClose={() => {
          setDialogPenerimaanPpn(false);
        }}
      />
      <AkunCoaForm
        visible={showFormAkun}
        onRequestClose={() => {
          setShowFormAkun(false);
          // setSelectedItemId(null)
          document.getElementsByClassName("mdi-reload")[0].click();
        }}
      />
    </>
  );
};

export default inject(
  "temporaryStore",
  "modalStore",
  "navigationStore"
)(observer(KasDanBankSelengkapnyatable));
