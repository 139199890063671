import { crud, http } from "../../../libs/react-mpk/services";

let service = null;
let serviceUrl = "/laporan";
let tahunSobatPajak = sessionStorage.getItem("tahunSobatBuku");
let npwpHeadId = sessionStorage.getItem("npwpHeadId");
let npwpHeadIdObject = {
  npwpHeadId: sessionStorage.getItem("npwpHeadId"),
};
let KATEGORI_HARTA_LANCAR_TETAP = 1;
let KATEGORI_UTANG_MODAL = 2;
service = new crud(`/laporan`);

service.getAllLabaRugiKomersial = async (query) => {
  query = {
    tahun: tahunSobatPajak,
    npwpHeadId: npwpHeadId,
    ...query,
  };
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/labarugi`,
    query,
  });
};
service.getAllLabaRugiKomersialShowDetail = async (query) => {
  query = {
    npwpHeadId: npwpHeadId,
    ...query,
  };
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/labarugi/detail`,
    query,
  });
};
service.getAllLabaRugiKomersialTransaksi = async (query) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/labarugi/transaksi`,
    query,
  });
};
service.getDetailLabaRugiKomersialTransaksi = async (query) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/labarugi/transaksi/detail`,
    query,
  });
};
service.DownloadLaporanLabaRugi = async (tahun) => {
    return http.download(
        `${serviceUrl}/labarugi/download_report_laba_rugi?npwpHeadId=${npwpHeadId}&tahun=${tahun}`,
  );
};


export default service;
